// React
import React, { useState } from 'react';

// Material UI
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";

// Assets
import help_icon from 'assets/icons/help-resume-icon.svg';

// Styles
import './InscriptionsFeeComponent.styles.css';

// React device detect
import { isMobile } from 'react-device-detect';

//i18
import { withTranslation } from 'react-i18next';

///Components
import WompiPaymentFormComponent from '../wompi-payment-form/WompiPaymentFormComponent';
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import FormComponent from 'shared/components/form/FormComponent';
import CurrencyFormat from 'react-currency-format';


function InscriptionsFeeComponent ( props ) {

    const [ value, setValue ] = useState(0);
    const [ subTotalValue, setSubTotalValue ] = useState( props.registration_price_per_team );
    const [ serviceFeeValue, setServiceFeeValue ] = useState(0);
    const [ serviceFeeTeam, setServiceFeeTeam ] = useState(0);
    const [ serviceFeePlayer, setServiceFeePlayer ] = useState(0);
    const [ totalValue, setTotalValue ] = useState((props.user_pays_processing_rate && props.user_pays_processing_rate_price_team)? props.user_pays_processing_rate_price_team : props.registration_price_per_team);

    const useStyles = makeStyles((theme) => ({
        customMessage: {
            backgroundColor: 'var(--white-color)',
            color: 'var(--primary-dark-color)',
            fontSize: 'var(--space-half-quarter)',
            borderRadius: 'var(--space-quarter)',
            fontFamily: 'var(--secondary-font)',
            boxShadow: '0px 1px 4px rgba(150, 150, 150, 0.25)',
            padding: 'var(--space)',
        },
    }));
    const classes = useStyles();

    React.useEffect(() => {
        if(props.user_pays_processing_rate){
            let feeTeam = 0;
            let feePlayer = 0;
            if( props.user_pays_processing_rate_price_team){
                feeTeam = Number(props.user_pays_processing_rate_price_team) -  Number(props.registration_price_per_team);
                setServiceFeeTeam(feeTeam);
            }
            if( props.user_pays_processing_rate_price_player){
                feePlayer = Number(props.user_pays_processing_rate_price_player) -  Number(props.registration_price_per_player);
                setServiceFeePlayer(feePlayer);
            }
            setServiceFeeValue(feeTeam);
        } 
    }, [props.user_pays_processing_rate, props.user_pays_processing_rate_price_team, props.user_pays_processing_rate_price_player]); // eslint-disable-line

    const handleIncreaseValue = () => {
        let increased = value + 1;
        setValue( increased );
        props.setPlayersQuantity(increased);

        let players_value = Number(props.registration_price_per_player) * increased;
        let players_fee = 0;
        setSubTotalValue(players_value + Number(props.registration_price_per_team));
        if(props.user_pays_processing_rate && props.user_pays_processing_rate_price_player){
            players_value = Number(props.user_pays_processing_rate_price_player) * increased;
            players_fee = serviceFeePlayer * increased;
            setServiceFeeValue(players_fee + serviceFeeTeam);
        }
        setTotalValue( Number(props.registration_price_per_team) + players_value + serviceFeeTeam);
    }

    const handleDecreaseValue = () => {
        let decreased = value - 1;
        if(value > 0){
            setValue( decreased );
            props.setPlayersQuantity(decreased);
            let players_value = Number(props.registration_price_per_player) * decreased;
            let players_fee = 0;
            setSubTotalValue(players_value + Number(props.registration_price_per_team));
            if(props.user_pays_processing_rate && props.user_pays_processing_rate_price_player){
                players_value = Number(props.user_pays_processing_rate_price_player) * decreased;
                players_fee = serviceFeePlayer * decreased;
                setServiceFeeValue(players_fee + serviceFeeTeam);
            }
            setTotalValue( Number(props.registration_price_per_team) + players_value + serviceFeeTeam);
        }
    }

    const initialValues ={
            cupon: ''
        }
    
    const [ coupon, setCoupon] = React.useState(null);

    const checkValue=(v)=>{
        if(props.discount_coupon && v.length <= 0){
            props.resetCoupon();
            setCoupon(null);
        }
    }

    const deleteValidCoupon = () => {
        props.resetCoupon();
        setCoupon(null);
    }

    return(
        <div className='inscription-fee__container' >
            <div className='inscription-fee__team-player' >
                <div className='inscription-fee__team'>
                    <div className='left-col' >
                        <span className='icon-team' />
                        {!isMobile && <div className='baloa-table' >{props.t('inscriptionModal.title')}</div>}
                        {isMobile && 
                        <div className='right-col'>
                            <div className='baloa-table' >{props.t('inscriptionModal.title')}</div>
                            <div className='baloa-username'>
                                <CurrencyFormat value={props.registration_price_per_team} 
                                    displayType={'text'} 
                                    thousandSeparator={','} 
                                    decimalSeparator={"."} 
                                    prefix={'$ '} suffix={' ' + (props.currency ?? '')}
                                />
                            </div>
                        </div>
                    }
                    </div>
                    {!isMobile &&
                        <div className='right-col'>
                            <div className='baloa-username'>
                                <CurrencyFormat value={props.registration_price_per_team} 
                                    displayType={'text'} 
                                    thousandSeparator={','} 
                                    decimalSeparator={"."} 
                                    prefix={'$ '} suffix={' ' + (props.currency ?? '')}
                                />
                            </div>
                        </div>
                    }
                </div>
                {(props.tournament_registration_type === 'Player_paid' || props.tournament_registration_type ===  'Paid' ) && 
                    <div className='inscription-fee__player' >
                        <div className='left-col' >
                            <span className='icon-player' />
                            <div className='baloa-table' >{props.t('inscriptionModal.inscriptionPerPlayer')}</div>
                            <Tooltip 
                                title={props.t('inscriptionModal.tooltip')}
                                arrow
                                placement="right-end"
                                classes={{ tooltip: classes.customMessage }}>
                                <img src={help_icon} alt="" />
                            </Tooltip>
                        </div>
                        {!isMobile && 
                            <div className='right-col' >
                                <div className='baloa-username'><CurrencyFormat value={props.registration_price_per_player} displayType={'text'} thousandSeparator={','} decimalSeparator={"."} prefix={'$ '} suffix={' ' + (props.currency ?? '')}/></div>
                                <div className='inscription-fee__increase-decrease__value'>
                                    <div className={`btn ${props.discount_coupon?.coupon_is_valid? 'disabled' : ''}`} onClick={ () => {if(props.tournament_registration_type === 'Player_paid' || props.tournament_registration_type === 'Paid'){handleDecreaseValue()}}} >-</div>    
                                    <input type='number' value={value} />
                                    <div className={`btn ${props.discount_coupon?.coupon_is_valid? 'disabled' : ''}`} onClick={ () => {if(props.tournament_registration_type === 'Player_paid' || props.tournament_registration_type === 'Paid'){handleIncreaseValue()}}} >+</div>
                                </div>
                            </div>
                        }
                </div>
                }
                {isMobile && 
                        <div className='right-col_isMobile' >
                            <div className='baloa-username'>
                                <CurrencyFormat value={props.registration_price_per_player} 
                                    displayType={'text'} 
                                    thousandSeparator={','} 
                                    decimalSeparator={"."} 
                                    prefix={'$ '} 
                                    suffix={' ' + (props.currency ?? '')}
                                />
                            </div>
                            <div className='inscription-fee__increase-decrease__value'>
                                <div className='btn' onClick={ () => {if(props.tournament_registration_type === 'Player_paid' || props.tournament_registration_type === 'Paid'){handleDecreaseValue()}}} >-</div>    
                                <input type='number' value={value} />
                                <div className='btn' onClick={ () => {if(props.tournament_registration_type === 'Player_paid' || props.tournament_registration_type === 'Paid'){handleIncreaseValue()}}} >+</div>
                            </div>
                        </div>
                        }
            </div>
            {props.currency === 'USD' &&
                <div className='inscription-fee__coupon'>
                    <div className='baloa-table-column'>{props.t('inscriptionModal.couponCodeLbl')}</div>
                    {(!props.discount_coupon || !props.discount_coupon?.coupon_is_valid) &&
                        <FormComponent
                            formInitialValues = {initialValues }
                            onSubmit = { ( values, actions ) => { 
                                    props.handleOnValidateCupon( props.tournament_team_id, values.cupon );
                                    setCoupon(values.cupon);
                                }
                            }
                            className = "inscription-fee__form"
                        >
                            <div className='inscription-fee__form-coupon'>
                                <InputFieldComponent
                                    //label={props.t('inscriptionModal.couponCodeLbl')}
                                    input_id=""
                                    field_name="cupon"
                                    validationFunction={()=>{}}
                                    disabled={false}
                                    input_type="text"
                                    resetError={() => { }}
                                    error={{'cupon': ''}}
                                    message=""
                                    onChange={(v) => { checkValue(v) }}
                                    className={`${(props.discount_coupon && !props.discount_coupon?.coupon_is_valid)? 'error' : ''}`}          
                                >
                                </InputFieldComponent>
                                <SecondaryButtonComponent
                                    onClick={()=>{}}
                                    disabled={ false }
                                >
                                    <span>{props.t('inscriptionModal.applyBtnLbl')}</span>
                                </SecondaryButtonComponent>
                            </div>
                            
                            {props.discount_coupon && !props.discount_coupon?.coupon_is_valid &&
                                <div className='baloa-mini error'>{props.discount_coupon?.coupon_response}</div>
                            }
                        </FormComponent>
                    }
                    {props.discount_coupon?.coupon_is_valid &&
                        <div className='inscription-fee__coupon-valid'>
                            <span className='hubicon-check_circle'/>
                            <div className=''>
                                <div className='baloa-table-column'><strong>{coupon}</strong>&nbsp;{props.t('inscriptionModal.appliedLbl')}</div>
                                <CurrencyFormat
                                    value={props.discount_coupon?.discount_price} 
                                    displayType={'text'} 
                                    thousandSeparator={'.'} 
                                    decimalSeparator={","} 
                                    prefix={'-$'} 
                                    suffix={' ' + props.discount_coupon?.currency}
                                    decimalScale={2}
                                    //onValueChange ={}
                                    renderText={value => <div className='baloa-username'>{`${value} (${props.discount_coupon?.percent_off}% ${props.t('inscriptionModal.discountLbl')})`}</div>}
                                />
                            </div>
                            <div className='baloa-table-column delete-button' onClick={()=>{ deleteValidCoupon() }}>{props.t('inscriptionModal.removeBtnLbl')}</div>
                        </div>
                    }
                </div>
            }
            <div className='baloa-play-match-payment-confirmation-modal__coupon-discount'>
                <CurrencyFormat
                    value={subTotalValue} 
                    displayType={'text'} 
                    thousandSeparator={'.'} 
                    decimalSeparator={","} 
                    prefix={'$'} 
                    suffix={' ' + props.currency}
                    decimalScale={2}
                    //onValueChange ={}
                    renderText={value => <div className='baloa-table'>{`${props.t('inscriptionModal.subTotalLbl')}: ${value}`}</div>}
                />
            </div>
            
            {props.discount_coupon?.coupon_is_valid &&
                <div className='baloa-play-match-payment-confirmation-modal__coupon-discount'>
                    <CurrencyFormat
                        value={props.discount_coupon?.discount_price} 
                        displayType={'text'} 
                        thousandSeparator={'.'} 
                        decimalSeparator={","} 
                        prefix={'-$'} 
                        suffix={' ' + props.discount_coupon?.currency}
                        decimalScale={2}
                        //onValueChange ={}
                        renderText={value => <div className='baloa-table'>{`${props.t('inscriptionModal.couponCodeLbl')}: ${value}`}</div>}
                    />
                </div>
            }
            {props.user_pays_processing_rate &&
                <div className='baloa-play-match-payment-confirmation-modal__coupon-discount'>
                    <CurrencyFormat
                        value={serviceFeeValue}
                        displayType={'text'} 
                        thousandSeparator={'.'} 
                        decimalSeparator={","} 
                        prefix={'$'} 
                        suffix={' ' + props.currency}
                        decimalScale={2}
                        //onValueChange ={}
                        renderText={value => <div className='baloa-table'>{`${props.t('inscriptionModal.serviceFeeLbl')}: ${value}`}</div>}
                    />
                </div>
            }
            <div className='inscription-fee__overall-fee' >
                <div className='baloa-normal'>{props.t('inscriptionModal.totalLabel')}&nbsp;</div>
                <div className='baloa-subtitle-2'><CurrencyFormat value={props.discount_coupon?.coupon_is_valid? props.discount_coupon?.amount_total : totalValue} displayType={'text'} thousandSeparator={','} decimalSeparator={"."} prefix={'$'} suffix={' ' + (props.currency ?? '')} fixedDecimalScale={true} decimalScale={2}/></div>
            </div>
            {props.currency === 'COP' &&
                <WompiPaymentFormComponent
                    currency={props.currency}
                    totalValue={totalValue}
                    profile_id={props.profile_id}
                    paymentCustomer={props.tournament_team_id}
                    countryCodePrefix="+57"
                    countryIataCode="CO"
                    city={'Bogota'}
                    region={'Bogota'}
                    playersQuantity={value}
                    submitWompiForm={props.submitWompiForm}
                    resetPaymentButton={props.resetPaymentButton}
                    type_redirect={props.type_redirect}
                    profile_phone={props.profile_phone}
                    metadata={`{"tournament_team_id":"${props.tournament_team_id}","tournament_player_id":null,"payment_type":"TEAM_REGISTRATION","tournament_id":"${props.tournament_id}","total_codes":"${value}"}`}
                />
            }
        </div>
    )
}

export default withTranslation('tournament')(InscriptionsFeeComponent);
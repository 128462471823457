/*eslint eqeqeq: "off"*/
// React
import React from 'react';
import { Link } from 'react-router-dom';

// Styles
import './BaloaPlayBlockVideoModalComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

//Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';


function BaloaPlayBlockVideoModalComponent( props ) {

        return(
            <ModalComponent
                isOpen={props.isOpen}
                onClose={props.onClose}
                className="baloa-play-block-video-modal"
            >
                <span className='hubicon-info'/>
                <div className='baloa-normal-medium'>{props.t('blockVideoModal.title')}</div>
                <div className='baloa-names message'>{props.t('blockVideoModal.message1')}</div>
                <div className="baloa-names message" dangerouslySetInnerHTML={{
                    __html: props.t('blockVideoModal.message2')
                }}></div>
                <div className='baloa-names'>{props.t('blockVideoModal.call2Action')}</div>

                <PrimaryButtonComponent
                    type={'button'}
                    onClick={ ()=>{ props.handleOnDownload()} }
                >
                    <span className=''>{props.t('blockVideoModal.downloadBtnLbl')}</span>
                </PrimaryButtonComponent>
            </ModalComponent>
    )
}

export default withTranslation('baloaplay')(BaloaPlayBlockVideoModalComponent);

export const changeSignUpStatus = ( data ) => {
    return { type: "SIGNUP:CHANGE_STATUS", data }
}

export const changeSignUpPhone = ( data ) => {
    return { type: "SIGNUP:CHANGE_PHONE", data }
}

export const changeSignUpLoading = ( data ) => {
    return { type: "SIGNUP:CHANGE_LOADING", data }
}

export const changeSignUpLoginModal = ( data ) => {
    return { type: "SIGNUP:CHANGE_LOGIN_MODAL", data }
}

export const changeSignUpWelcomeModal = ( data ) => {
    return { type: "SIGNUP:CHANGE_WELCOME_MODAL", data }
}

export const changeSignUpLoggedIn = ( data ) => {
    return { type: "SIGNUP:CHANGE_LOGGED_IN", data }
}

export const changeSignUpError = ( data ) => {
    return { type: "SIGNUP:CHANGE_ERROR", data }
}

export const changeIsLogin = ( data ) =>{
    return{ type : "SIGNUP:CHANGE_LOGGED_IN", data }
}

export const countrySignUpLogin = ( data ) =>{
    return{ type : "SIGNUP:LOAD_COUNTRY_IN_LOGIN", data }
}

export const changeTermsModal = ( data ) =>{
    return{ type : "SIGNUP:CHANGE_TERMS_MODAL", data }
}

export const loadTermsAndConditionsText = ( data ) =>{
    return{ type : "SIGNUP:LOAD_TERMS_CONDITIONS_TEXT", data }
}

export const changeSignUpEmail = ( data ) => {
    return { type: "SIGNUP:CHANGE_EMAIL", data }
}

export const getCountries = ( data ) => {
    return { type: "SIGNUP:GET_COUNTRIES", data }
}

export const searchCityByCountry = ( data ) => {
    return { type: "SIGNUP:GET_CITIES_BY_COUNTRY", data }
}

export const changeRecoveryPasswordCode = ( data ) => {
    return { type: "SIGNUP:CHANGE_RECOVERY__PASSWORD_CODE", data}
}

export const getTeams = (data) => {
    return { type: "SIGNUP:GET_TEAMS", data }
}

export const changeClickType = (data) => {
    return { type: "SIGNUP:CHANGE_CLICK_TYPE", data }
}

export const setUrlRedirect = (data) => {
    return { type: "SIGNUP:SET_URL_REDIRECT", data }
}

export const changeCompleteUserInfoModalStateAction = ( new_state ) => {
    return { type: "SIGNUP:CHANGE_COMPLETE_USER_INFO_MODAL_STATE", new_state }
}

export const loadProfessionTypesAction = ( data ) => {
    return { type: "SIGNUP:LOAD_PROFESSION_TYPES", data }
}

export const loadPreRegisteredMembersAction = ( data ) => {
    return { type: "SIGNUP:LOAD_PRE_REGISTERED_MEMBERS", data }
}

export const setClaimedAccountProfileAction = ( data ) => {
    return { type: "SIGNUP:SET_CLAIMED_ACCOUNT_PROFILE", data }
}

export const cleanPreRegisteredMembersAction = ( data ) => {
    return { type: "SIGNUP:CLEAN_PRE_REGISTERED_MEMBERS", data }
}

export const cleanClaimedAccountProfileAction = ( data ) => {
    return { type: "SIGNUP:CLEAN_CLAIMED_ACCOUNT_PROFILE", data }
}

export const registerDocumentVerificationAction  = ( new_state ) => {
    return { type: "SIGNUP:REGISTER_DOCUMENT_VERIFICATION", new_state }
}

export const changeSignUpBaloaProModalStateAction = ( new_state ) => {
    return { type: "SIGNUP:CHANGE_BALOA_PRO_MODAL_STATE", new_state }
}

export const changeSignUpSessionLimitModalStateAction = ( new_state ) => {
    return { type: "SIGNUP:CHANGE_SESSION_LIMIT_MODAL_STATE", new_state }
}

export const changeSignUpInvalidSessionModalStateAction = ( new_state ) => {
    return { type: "SIGNUP:CHANGE_INVALID_SESSION_MODAL_STATE", new_state }
}
// React
import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import { connect } from 'react-redux';
// Redux
import { compose } from 'redux';

// Routes
import RouteWithSubRoutes from 'config/routes/RouteWithSubRoutes';

///Modals
import LoginModalComponent from 'app/authentication/components/login-modal/LoginModalComponent';
//import CreatePostModalComponent from 'app/posts/components/create-post-modal/CreatePostModalComponent';
import CreateFormationModalComponent from 'app/posts/components/create-formation-modal/CreateFormationModalComponent';

import WelcomeModalComponent from 'app/authentication/components/welcome-modal/WelcomeModalComponent';
import PageTypeModalComponent from 'app/pages/components/page-type-modal/PageTypeModalComponent';
import PagePrivacyModalComponent from 'app/pages/components/page-privacy-modal/PagePrivacyModalComponent';
import PageInformationModalComponent from 'app/pages/components/page-information-modal/PageInformationModalComponent';
import ErrorMessageComponent from 'shared/components/error-message/ErrorMessageComponent';
import UserMessageComponent from 'shared/components/user-message/UserMessageComponent';
import PageCategoryModalComponent from 'app/pages/components/page-category-modal/PageCategoryModalComponent';
import PageTeamCreateModalComponent from 'app/pages/components/page-team-create-modal/PageTeamCreateModalComponent';
import LeagueTeamMemberRolModalComponent from 'app/leagues/components/league-team-member-rol-modal/LeagueTeamMemberRolModalComponent';
import ProfileTeamsModalComponent from "app/profiles/components/profile-teams-modal/ProfileTeamsModalComponent";
import CompleteUserInfoModalComponent from 'app/authentication/components/complete-user-info-modal/CompleteUserInfoModalComponent';
import BaloaProModalComponent from 'app/authentication/components/baloa-pro-modal/BaloaProModalComponent';
import InvalidSessionModalComponent from 'app/authentication/components/invalid-session-modal/InvalidSessionModalComponent';

// Actions
import { changeSignUpLoginModal, changeSignUpWelcomeModal, changeCompleteUserInfoModalStateAction, changeSignUpError, changeSignUpBaloaProModalStateAction, changeSignUpInvalidSessionModalStateAction } from 'app/authentication/actions/LoginActionsCreators';
import { getUserMeAction, updateUserDataAction, userLogout, loadProfessionTypes, sendNoRegisterUserDataAction } from 'app/authentication/actions/LoginActions';
import {
  changeHomeNewPostModal,
  changePostNewFormationModal,
  changeErrorMessageSnackBar
} from 'app/home/actions/HomeActionsCreators';
import {
  changePageNewPageTypeModalStateAction,
  changePageNewPagePrivacyModalStateAction,
  changePageNewPageInformationModalStateAction,
  changePageNewPageCategoryModalStateAction,
  loadPageErrorsAction,
  changePageNewTeamModalStateAction,
  changePageJoinToTeamStateAction,
  changeSelectTeamInscriptionModalStateAction,
} from 'app/pages/actions/PageActionsCreators';
import { createPage } from 'app/pages/actions/PageActions';
import { loadLineUpsConstants, loadAlignments } from 'app/generallineups/actions/LineUpActions';
import { changeUserMessageSnackBar } from 'app/user-settings/actions/UserSettingsCreators';
import { changeNewGroupPage } from 'app/pages/actions/PageGroupsActionsCreators';
import { changeNewLeaguePage } from 'app/leagues/actions/LeagueActionsCreators';
import {
  addNewTeam,
  searchMembersWithValidation,
  deletePlayerFromTeam,
  loadPageTeams,
  addMemberWithRolToTeam
} from 'app/leagues/actions/LeagueTeamsActions';
import {
  changeNewTeamError,
  changeTeamMembersRolModalStateAction,
  cleanSearchedMembersAction,
} from 'app/leagues/actions/LeagueTeamsActionsCreators';
import { changeTeamMembersModalStateAction } from 'app/leagues/actions/LeagueActionsCreators';
import {
  loadProfile,
  addProfileTeamByCode,
  deleteProfileTeam
} from 'app/profiles/actions/ProfileActions';
import { loadAttributesConstants } from 'app/generalattributes/actions/AttributeActions';
import { loadItemsForTournamentType } from 'app/leagues/actions/LeagueTournamentActions';
import { withTranslation } from 'react-i18next';


class RoutesLayout extends Component {

  constructor(props) {
    super(props);
    //this.props.getUserMeAction(this.props.state.user?.me?.username, this.props.location.search?? this.props.location.search );
    //this.props.loadLineUpsConstants();
    this.props.loadAlignments();
    //this.props.loadAttributesConstants();
    //this.props.loadItemsForTournamentType();
    this.props.loadProfessionTypes();
    this.preLoadData();
  }

  state = {
    new_page_type: null,
    new_page_privacy: null,
    new_page_information: null,
    selected_category: null,
    selected_category_list: [],
    uploadProgress: 0,
    playerSelected: '',
    new_team: false,
    member: '',
    roles_members: [],
    positions_members: [],
    page_data: {},
    profession_specialties : null,
  };

  componentDidUpdate(prevProps) { 
    if(prevProps.state.user?.me.username_asigned !== this.props.state.user?.me.username_asigned && !this.props.state.user?.me.username_asigned && this.props.location.pathname !== '/cuenta/ingresar'){
      this.props.changeCompleteUserInfoModalStateAction(true);
    }

    if(this.props.state.signup?.professional_types?.length > 0 && this.state.profession_specialties === null){
      const specialties =this.props.state.signup?.professional_types?.map(specialty =>{
          let tspec = {};
          tspec["content"] = this.props.t('profile:professional_types.'+specialty.caption);
          tspec["value"] = specialty.caption;
          return tspec;
      });
      this.setState({profession_specialties: specialties});
    }
  }

  preLoadData(){
    if(localStorage.getItem('token')){//Apis que requieren token y no son necesarias en unath view
      this.props.getUserMeAction(this.props.state.user?.me?.username, this.props.location.search?? this.props.location.search );
      this.props.loadLineUpsConstants();
      this.props.loadAttributesConstants();
      this.props.loadItemsForTournamentType();
    }
  }

  new_team_categories = [
    { value: 'Free', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.Free") },
    { value: 'sub-23', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-23") },
    { value: 'sub-22', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-22") },
    { value: 'sub-21', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-21") },
    { value: 'sub-20', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-20") },
    { value: 'sub-19', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-19") },
    { value: 'sub-18', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-18") },
    { value: 'sub-17', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-17") },
    { value: 'sub-16', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-16") },
    { value: 'sub-15', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-15") },
    { value: 'sub-14', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-14") },
    { value: 'sub-13', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-13") },
    { value: 'sub-12', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-12") },
    { value: 'sub-11', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-11") },
    { value: 'sub-10', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-10") },
    { value: 'sub-9', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-9") },
    { value: 'sub-8', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-8") },
    { value: 'sub-7', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-7") }
  ];

  new_team_gender = [
    { value: 'Male', content: this.props.t("team:newTeam.forms.basicInfo.genderMaleBtnLabel") },
    { value: 'Female', content: this.props.t("team:newTeam.forms.basicInfo.genderFemaleBtnLabel") },
    { value: 'Free', content: this.props.t("team:newTeam.forms.basicInfo.genderFreeBtnLabel") },
  ];

  onNewPageType = (new_page_type) => {
    /*if( new_page_type === 'league'){
      this.props.changePageNewPageInformationModalStateAction( true );
      this.props.changePageNewPageTypeModalStateAction( false );
      this.setState( { new_page_privacy: false } );
    }else{
      this.props.changePageNewPagePrivacyModalStateAction( true );
      this.props.changePageNewPageTypeModalStateAction( false );
    }*/
    //Se define por defecto que todas los tipos de páinas que pasan por aca son públicas
    this.props.changePageNewPageInformationModalStateAction(true);
    this.props.changePageNewPageTypeModalStateAction(false);
    this.setState({ new_page_privacy: false });
    this.setState({ new_page_type: new_page_type });
  }

  onNewPagePrivacy = (new_page_privacy) => {
    this.props.changePageNewPageInformationModalStateAction(false);
    this.props.changePageNewPagePrivacyModalStateAction(false);
    this.setState({ new_page_privacy: new_page_privacy.privacy });

    if (this.props.state.pageGroups.new_group_page && new_page_privacy.page_type === 'group') {
      this.props.changeNewGroupPage(false);
      this.setState({ new_page_type: "group" });
      this.onNewPageInformation(new_page_privacy);
    }
  }

  onNewPageInformation = async (new_page_information) => {
    // this.props.changePageNewPageInformationModalStateAction( false );
    this.setState({ new_page_information: new_page_information });
    if (this.props.state.leagues.new_league_page) {
      this.setState({ new_page_type: "team" });
      this.setState({ new_page_privacy: false });
      this.props.changeNewLeaguePage(false);
    }
    const { is_private, page_type } = new_page_information

    const page_creation_data = {
      ...new_page_information,
      is_private: this.state.new_page_privacy || is_private,
      page_type: this.state.new_page_type || page_type,
    }
    this.setState({ page_data: page_creation_data });
    await this.props.createPage(page_creation_data);

  }

  loadProfileData = () => {
    this.props.loadProfile(this.props.state.user.me.username);
  }

  selectCategory = (category) => {
    if(category && this.state.selected_category !== category){
      this.setState({ selected_category: category, selected_category_list: this.props.state.page.page_categories[category] });
    }

    // this.props.changePageNewPageCategoryModalStateAction( false ); 
    // this.props.changePageNewPageTypeModalStateAction( true ); 
  }

  resetCategory(){
    this.setState({ selected_category: null, selected_category_list: [] });
  }

  onUploadProgress = (progressEvent) => {
    this.setState({ uploadProgress: Math.round(progressEvent.loaded / progressEvent.total * 100) });
  }

  handleCreateNewTeam = async (team_data, imgb64) => {
    const new_team_data = Object.keys(team_data).reduce((object, key) => {
      if (key !== 'category' && key !== 'pagename' && key !== 'pageName') {
        object[key] = team_data[key]
      }
      object.team_tag = [{ extra: { atype: "team-tags", value: team_data.category } }];
      object.photo = imgb64;
      return object
    }, {});
    await this.props.addNewTeam(new_team_data, this.onUploadProgress, team_data.pageName);
  };

  handleSearchMember = (search_member) => {
    this.props.searchMembersWithValidation(search_member, this.props.state.teams.team.team_players);
  };

  handleDeletePlayer = (player) => {
    this.props.deletePlayerFromTeam(this.props.state.teams.team.teamname, player.username);
  };

  handleModalAddPlayerRol = (player) => {
    this.props.changeTeamMembersRolModalStateAction(true);
    this.setState({ playerSelected: player });
  };

  handleCleanMemberSearch = () => {
    this.props.cleanSearchedMembersAction({});
  };

  handleOnSaveButton = () => {
    this.props.loadPageTeams(this.pagename);
    this.setState({ new_team: false });
  };

  handleOnMemberActionClick = (member) => {
    this.setState({ member: member });
    this.props.changeTeamMembersModalState(true);
  };

  handleHomeShortCuts = (shortCut) => {
    this.props.changeSignUpWelcomeModal(false);
    if(shortCut === 'create-team') {
      this.props.changePageNewTeamModalStateAction(true);
    }
    if(shortCut === 'join-team') {
      this.props.changePageJoinToTeamStateAction(true);
    }
  }

  async handleNoRegisterUserDataAction(formData, uploadProgress){
    await this.props.sendNoRegisterUserDataAction(formData, uploadProgress);
    this.props.changeCompleteUserInfoModalStateAction(false);
  }

  handleOnLogin(){
    this.props.userLogout();
    this.props.changeSignUpInvalidSessionModalStateAction(false);
    this.props.history.push('/cuenta/ingresar');
  }

  render() {

    return (

      <React.Fragment>
        <LoginModalComponent
          onClick={() => { }}
          isOpen={this.props.state.signup.show_login_modal}
          onClose={() => { this.props.changeSignUpLoginModal(false); this.props.changeSelectTeamInscriptionModalStateAction(false) }}
          click_type={this.props.state.signup.click_type}
          redirect_url={ this.props.state.signup.redirect_url }
        />
        <WelcomeModalComponent
          onClick={() => { }}
          isOpen={this.props.state.signup.show_welcome_modal}
          onClose={() => { this.props.changeSignUpWelcomeModal(false) }}
          handleHomeShortCuts={(shortCut) => {this.handleHomeShortCuts(shortCut)}}
          username={this.props.state.user.me.username}
        />
        {/* <CreatePostModalComponent 
          onClick={() => {}} 
          isOpen={ this.props.state.home.modals.new_post_is_open } 
          onClose={() => { this.props.changeHomeNewPostModal( false ) }} 
        /> */}
        <CreateFormationModalComponent
          onClick={() => { }}
          isOpen={this.props.state.home.modals.new_formation_is_open}
          onClose={() => { this.props.changePostNewFormationModal(false) }}


        />
        <PageTeamCreateModalComponent
          onClick={() => { }}
          isOpen={this.props.state.page.modals?.new_page_team_is_open}
          onClose={() => {
            this.props.changePageNewTeamModalStateAction(false);
            this.setState({ new_page_type: null });
            this.props.changeTeamMembersModalState(false)
          }
          }
          team={this.props.state.teams.team}
          page_data={this.state.page_data}
          profiles={this.props.state.teams.searched_members}
          gender={this.new_team_gender}
          category={this.new_team_categories}
          pages={this.props.state.profile.loggedin?.pages?.filter(p => p.page_type != 'group')}
          errors={this.props.state.page.errors.creation}
          errors_newTeam={this.props.state.teams.errors.new_team}
          handleCreateNewTeam={(team_data, teamimage, imgb64) => { this.handleCreateNewTeam(team_data, teamimage, imgb64) }}
          onNewData={(new_page_information) => this.onNewPageInformation(new_page_information)}
          onResetError={(new_errors) => this.props.loadPageErrorsAction({ ...this.props.state.page.errors.creation, ...new_errors }, 'creation')}
          handleSearchMember={(v) => this.handleSearchMember(v)}
          onDeletePlayerButton={(player) => { this.handleDeletePlayer(player) }}
          onSelectTeamPlayer={(player) => { this.handleModalAddPlayerRol(player) }}
          handleCleanMemberSearch={() => { this.handleCleanMemberSearch() }}
          handleOnSaveButton={() => { this.handleOnSaveButton() }}
          handleOnMemberActionClick={(member) => { this.handleOnMemberActionClick(member) }}
          showCopyPostMessage={(copymessage) => { this.props.showCopyPostMessage(copymessage) }}
        />

        <ProfileTeamsModalComponent
          onClick={() => { }}
          isOpen={this.props.state.page.modals?.join_to_team_is_open}
          onClose={() => {
            this.props.changePageJoinToTeamStateAction(false);
          }}
          model={this.props.state.profile.profile}
          onNewData={(teamcode) => {
            this.props.addProfileTeamByCode(this.props.state.user?.me?.username, teamcode);
          }}
          onDelete={(teamname) => {
            this.props.deleteProfileTeam(this.props.state.user?.me?.username, teamname)
          }}
        />

        <LeagueTeamMemberRolModalComponent
          isOpen={this.props.state.teams.modals.is_open_member_team_rol_modal}
          title={this.props.t("team:newTeam.title")}
          onClose={() => this.props.changeTeamMembersRolModalStateAction(false)}
          onSelectRolPlayer={(player) => { this.props.addMemberWithRolToTeam(this.props.state.teams.team.teamname, player) }}
          playerSelected={this.state.playerSelected}
          roles={[
            { value: 'player', content: this.props.t("team:team.tabs.members.player") },
            { value: 'coach', content: this.props.t("team:team.tabs.members.coach") },
            { value: 'staff', content: this.props.t("team:team.tabs.members.staff") }
          ]}
          positions={[
            { value: 'goalkeeper', content: this.props.t("team:team.tabs.members.goalkeeper") },
            { value: 'defense', content: this.props.t("team:team.tabs.members.defense") },
            { value: 'midfield', content: this.props.t("team:team.tabs.members.midfield") },
            { value: 'forward', content: this.props.t("team:team.tabs.members.forward") }
          ]}
          errors={this.props.state.teams.errors.add_member_roles}
          onResetError={(data) => { this.props.changeNewTeamError(data) }}
        />

        <PageInformationModalComponent
          onClick={() => { }}
          isOpen={this.props.state.page.modals?.new_page_information_is_open}
          onClose={() => {
            this.props.changePageNewPageInformationModalStateAction(false);
            this.props.changeNewGroupPage(false);
            this.props.changeNewLeaguePage(false);
            this.setState({ new_page_type: null });
          }
          }
          page_type={this.state.new_page_type}
          onNewData={(new_page_information) => this.onNewPageInformation(new_page_information)}
          onSelectCategory={(category) => { this.selectCategory(category) }}
          selectedCategoryList={this.state.selected_category_list}
          selectedCategory={this.state.selected_category}
          errors={this.props.state.page.errors.creation}
          onResetError={(new_errors) => this.props.loadPageErrorsAction({ ...this.props.state.page.errors.creation, ...new_errors }, 'creation')}
          loadProfile={() => { this.loadProfileData() }}
          resetCategory={()=>{ this.resetCategory() }}
        />

        <PagePrivacyModalComponent
          onClick={() => { }}
          isOpen={this.props.state.page.modals?.new_page_privacy_is_open}
          onClose={() => {
            this.props.changePageNewPageInformationModalStateAction(false);
            this.props.changePageNewPagePrivacyModalStateAction(false);
            this.props.changeNewGroupPage(false);
            this.props.changeNewLeaguePage(false);
            this.setState({ new_page_type: null });
          }
          }
          onNewData={(new_page_privacy) => this.onNewPagePrivacy(new_page_privacy)}
          errors={this.props.state.page.errors.creation}
          onResetError={(new_errors) => this.props.loadPageErrorsAction({ ...this.props.state.page.errors.creation, ...new_errors }, 'creation')}

        />


        <PageCategoryModalComponent
          isOpen={this.props.state.page.modals?.new_page_category_is_open}
          onClose={() => { this.props.changePageNewPageCategoryModalStateAction(false) }}
          onSelectCategory={(category) => { this.selectCategory(category) }}
          selectedCategory={this.state.selected_category}
        />

        <PageTypeModalComponent
          onClick={() => { }}
          isOpen={this.props.state.page.modals?.new_page_type_is_open}
          onClose={() => { this.props.changePageNewPageTypeModalStateAction(false) }}
          onNewData={(new_page_type) => this.onNewPageType(new_page_type)}
          selectedCategoryList={this.state.selected_category_list}
        />

        <CompleteUserInfoModalComponent 
          isOpen={this.props.state.signup.complete_user_info_modal}
          onClose={() => { this.props.changeCompleteUserInfoModalStateAction(false) }}
          user_name={this.props.state.user.me?.username}
          user_phone={this.props.state.user.me?.phone}
          user_email={this.props.state.user.me?.email}
          user_birthday={this.props.state.user.me?.birthday}
          user_first_name={this.props.state.user.me?.first_name }
          user_last_name={this.props.state.user.me?.last_name }
          user_username={(this.props.state.user.me?.username && this.props.state.user.me?.username_asigned)? this.props.state.user.me?.username : null }
          errors={this.props.state.signup_error.error}
          updateUserDataAction={(user_data) => {this.props.updateUserDataAction(user_data)}}
          handleLogOut={() => {this.props.userLogout()}}
          changeSignUpError={(values) => {this.props.changeSignUpError(values)}}
          profession_specialties = {this.state.profession_specialties ?? []}
          pre_registered_user={this.props.state.user?.me?.pre_registered_user}
          pre_registered_user_update={this.props.state.user?.me?.pre_registered_user_update}
          not_registered={this.props.state.user?.me?.not_registered}
          handleNoRegisterUserDataAction={ (formData, uploadProgress)=>{ this.handleNoRegisterUserDataAction(formData, uploadProgress)} }
        />
        <BaloaProModalComponent
          isOpen={this.props.state.signup.show_baloa_pro_modal}
          onClose={() => { this.props.changeSignUpBaloaProModalStateAction(false) }}
          expiration_date={this.props.state.baloapro.stripe_user_account?.expiration_date}
        />

        <InvalidSessionModalComponent
            isOpen={ this.props.state.signup.invalid_session_modal }
            onClose={() => { /* this.props.changeSignUpInvalidSessionModalStateAction(false) */ }}
            handleOnLogin={() => { this.handleOnLogin() }}
        />

        <Switch>
          {this.props.routes.map(route => (
            <RouteWithSubRoutes  {...route} key={route.key}/>
          ))}
        </Switch>
        <ErrorMessageComponent
          isOpen={this.props.state.home.error_message.error_message_is_open}
          message={this.props.state.home.error_message.message}
          onClose={() => {
            this.props.changeErrorMessageSnackBar({
              message: '',
              error_message_is_open: false
            })
          }}
        />
        <UserMessageComponent
          isOpen={this.props.state.userSettings.user_message.user_message_is_open}
          message={this.props.state.userSettings.user_message.message}
          onClose={() => {
            this.props.changeUserMessageSnackBar({
              message: '',
              user_message_is_open: false
            })
          }}
        />
      </React.Fragment>

    );

  }
}

// Redux connection
const mapStateToProps = state => {

  return {
    state
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changeSignUpLoginModal: (new_modal_state) => {
      return dispatch(changeSignUpLoginModal(new_modal_state))
    },
    getUserMeAction: (value, redirect_url) => {
      dispatch(getUserMeAction(value, redirect_url));      
    },
    changeHomeNewPostModal: (new_modal_state) => {
      return dispatch(changeHomeNewPostModal(new_modal_state))
    },
    changePostNewFormationModal: (new_modal_state) => {
      return dispatch(changePostNewFormationModal(new_modal_state))
    },
    changeSignUpWelcomeModal: (new_modal_state) => {
      return dispatch(changeSignUpWelcomeModal(new_modal_state))
    },
    changePageNewPageTypeModalStateAction: (new_modal_state) => {
      return dispatch(changePageNewPageTypeModalStateAction(new_modal_state))
    },
    changePageNewPageCategoryModalStateAction: (new_modal_state) => {
      return dispatch(changePageNewPageCategoryModalStateAction(new_modal_state))
    },
    changePageNewPagePrivacyModalStateAction: (new_modal_state) => {
      return dispatch(changePageNewPagePrivacyModalStateAction(new_modal_state))
    },
    changePageNewPageInformationModalStateAction: (new_modal_state) => {
      return dispatch(changePageNewPageInformationModalStateAction(new_modal_state))
    },
    createPage: async (page_creation_data) => {
      await dispatch(createPage(page_creation_data))
    },
    loadPageErrorsAction: (errors, section) => {
      return dispatch(loadPageErrorsAction(errors, section))
    },
    changeErrorMessageSnackBar: (new_error_message_state) => {
      return dispatch(changeErrorMessageSnackBar(new_error_message_state))
    },
    loadLineUpsConstants: () => {
      dispatch(loadLineUpsConstants())
    },
    changeUserMessageSnackBar: (new_user_message_state) => {
      return dispatch(changeUserMessageSnackBar(new_user_message_state))
    },
    changeNewGroupPage: (new_state) => {
      return dispatch(changeNewGroupPage(new_state))
    },
    loadProfile: (username) => {
      dispatch(loadProfile(username))
    },
    changeNewLeaguePage: (new_state) => {
      return dispatch(changeNewLeaguePage(new_state))
    },
    loadAlignments: () => {
      dispatch(loadAlignments())
    },
    changePageNewTeamModalStateAction: (new_modal_team) => {
      dispatch(changePageNewTeamModalStateAction(new_modal_team))
    },
    addNewTeam: async (new_team_data, uploadProgress, pagename) => {
      await dispatch(addNewTeam(new_team_data, uploadProgress, pagename));
    },
    searchMembersWithValidation: (member_username, actual_members) => {
      dispatch(searchMembersWithValidation(member_username, actual_members));
    },
    deletePlayerFromTeam: (teamname, username) => {
      dispatch(deletePlayerFromTeam(teamname, username));
    },
    changeTeamMembersRolModalStateAction: (isOpen) => {
      dispatch(changeTeamMembersRolModalStateAction(isOpen))
    },
    cleanSearchedMembersAction: (data) => {
      dispatch(cleanSearchedMembersAction(data));
    },
    loadPageTeams: (page_name) => {
      dispatch(loadPageTeams(page_name));
    },
    changeTeamMembersModalState: (isOpen) => {
      dispatch(changeTeamMembersModalStateAction(isOpen))
    },
    changeNewTeamError: (data) => {
      dispatch(changeNewTeamError(data));
    },
    addMemberWithRolToTeam: (teamname, member) => {
      dispatch(addMemberWithRolToTeam(teamname, member));
    },
    showCopyPostMessage: (copymessage) => {
      return dispatch(changeErrorMessageSnackBar({
        message: copymessage,
        error_message_is_open: true
      }));
    },
    changePageJoinToTeamStateAction: (new_modal_team) => {
      return dispatch(changePageJoinToTeamStateAction(new_modal_team))
    },
    addProfileTeamByCode: (username, teamcode) => {
      dispatch(addProfileTeamByCode(username, teamcode));
    },
    deleteProfileTeam: (username, teamname) => {
      dispatch(deleteProfileTeam(username, teamname));
    },
    changeCompleteUserInfoModalStateAction: ( new_state ) => {
      dispatch(changeCompleteUserInfoModalStateAction( new_state ));
    },
    updateUserDataAction: ( user_data ) => {
      dispatch( updateUserDataAction( user_data ));
    },
    userLogout: () => {
      dispatch(userLogout());
    },
    changeSignUpError : (values)=>{
      return dispatch(changeSignUpError(values))
    },
    changeSelectTeamInscriptionModalStateAction: ( new_state ) => {
      return dispatch( changeSelectTeamInscriptionModalStateAction( new_state ) )
    },
    loadAttributesConstants: () => {
      dispatch( loadAttributesConstants() )
    },
    loadItemsForTournamentType:()=>{
      return dispatch(loadItemsForTournamentType())
    },
    loadProfessionTypes: () => {
      return dispatch( loadProfessionTypes())
    },
    sendNoRegisterUserDataAction: async ( new_data, upProgres ) =>{
      await dispatch( sendNoRegisterUserDataAction( new_data, upProgres ) );
    },
    changeSignUpBaloaProModalStateAction: ( new_state ) => {
      return dispatch( changeSignUpBaloaProModalStateAction( new_state ) )
    },
    changeSignUpInvalidSessionModalStateAction: ( new_state ) => {
      return dispatch( changeSignUpInvalidSessionModalStateAction( new_state ) )
    },
  }
}
export default compose(withTranslation(['team', 'league']), connect(mapStateToProps, mapDispatchToProps))(RoutesLayout);
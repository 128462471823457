/*eslint eqeqeq: "off"*/
// React
import React, { useState } from 'react';

// Components
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import FormComponent from 'shared/components/form/FormComponent';

import LeagueMatchCardsComponent from 'app/leagues/components/league-match-cards/LeagueMatchCardsComponent';
import TournamentEndMatchStepsComponent from '../tournament-end-match-steps/TournamentEndMatchStepsComponent';

// Styles
import './LeagueMatchCardsFormComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';


function LeagueMatchCardsFormComponent( props ) {

    const [initialValues, setInitialValues] = useState({});
    /* eslint-disable */
    const [localPlayer, setLocalPlayer ]= React.useState([]);
    const [localMinute, setLocalMinute ]= React.useState([]);
    const [localYellowCard, setLocalYellowCard ]= React.useState([]);
    const [localDoubleYellowCard, setLocalDoubleYellowCard ]= React.useState([]);
    const [localRedCard, setLocalRedCard ]= React.useState([]);
    const [localBlueCard, setLocalBlueCard ]= React.useState([]);
    const [visitorPlayer, setVisitorPlayer ]= React.useState([]);
    const [visitorMinute, setVisitorMinute ]= React.useState([]);
    const [visitorYellowCard, setVisitorYellowCard ]= React.useState([]);
    const [visitorDoubleYellowCard, setVisitorDoubleYellowCard ]= React.useState([]);
    const [visitorRedCard, setVisitorRedCard ]= React.useState([]);
    const [visitorBlueCard, setVisitorBlueCard ]= React.useState([]);
    const formRef = React.createRef();

    React.useEffect( () => {

        if(props.endGameState.cards){
            let fval = setFormValues(props.endGameState.cards);
            setInitialValues(fval);
        }

    }, [ props.endGameState ] )
    /* eslint-enable */
    

    React.useEffect(() => {
        if (formRef.current) {
            formRef.current.validateForm();
        }
    }, [formRef])

    function reValidateForm(){
        if (formRef.current) {
            formRef.current.validateForm();
        }
    }

    function removeFormValue(val,ind){
        if(val == 'LOCAL'){
            formRef.current.values.local_cards_dy && formRef.current.values.local_cards_dy.splice(ind,1);
            formRef.current.values.local_cards_minute && formRef.current.values.local_cards_minute.splice(ind,1);
            formRef.current.values.local_cards_red && formRef.current.values.local_cards_red.splice(ind,1);
            formRef.current.values.local_cards_blue && formRef.current.values.local_cards_blue.splice(ind,1);
            formRef.current.values.local_cards_y && formRef.current.values.local_cards_y.splice(ind,1);
            formRef.current.values.local_player && formRef.current.values.local_player.splice(ind,1);
        }
        if(val == 'VISITOR'){
            formRef.current.values.visitor_cards_dy && formRef.current.values.visitor_cards_dy.splice(ind,1);
            formRef.current.values.visitor_cards_minute && formRef.current.values.visitor_cards_minute.splice(ind,1);
            formRef.current.values.visitor_cards_red && formRef.current.values.visitor_cards_red.splice(ind,1);
            formRef.current.values.visitor_cards_blue && formRef.current.values.visitor_cards_blue.splice(ind,1);
            formRef.current.values.visitor_cards_y && formRef.current.values.visitor_cards_y.splice(ind,1);
            formRef.current.values.visitor_player && formRef.current.values.visitor_player.splice(ind,1);
        }
        formRef.current.validateForm();
    }
    const { children, index, ...other } = props; // eslint-disable-line

    const cards = {
        local: [],
        visitant: [],
    }

    const cardsfull = {
        local: [],
        visitant: [],
    }

    function checkSelectedLocalCard( player, cards_array ) {

        let selcard = undefined;
        if(cards_array.local_cards_y != undefined && cards_array.local_cards_y[player] && (cards_array.local_cards_y[player] == true || cards_array.local_cards_y[player][0] === 'on')){

            selcard = 'Yellow_Card';

        }else if(cards_array.local_cards_dy != undefined  && cards_array.local_cards_dy[player] && (cards_array.local_cards_dy[player] == true || cards_array.local_cards_dy[player][0] === 'on')){

            selcard = 'Double_Yellow_Card';

        }else if(cards_array.local_cards_red != undefined && cards_array.local_cards_red[player] && (cards_array.local_cards_red[player] == true || cards_array.local_cards_red[player][0] === 'on')){

            selcard = 'Red_Card';
       
        }else if(cards_array.local_cards_blue != undefined && cards_array.local_cards_blue[player] && (cards_array.local_cards_blue[player] == true || cards_array.local_cards_blue[player][0] === 'on')){

            selcard = 'Blue_Card';
       
        }

        return selcard;
    }
    
    function checkSelectedVisitorCard( player, cards_array ) {

        let selcard = undefined;
        if(cards_array.visitor_cards_y != undefined && cards_array.visitor_cards_y[player] && (cards_array.visitor_cards_y[player] == true || cards_array.visitor_cards_y[player][0] === 'on')){

            selcard = 'Yellow_Card';
        
        }else if(cards_array.visitor_cards_dy != undefined && cards_array.visitor_cards_dy[player] && (cards_array.visitor_cards_dy[player] == true || cards_array.visitor_cards_dy[player][0] === 'on')){

            selcard = 'Double_Yellow_Card';

        }else if(cards_array.visitor_cards_red != undefined && cards_array.visitor_cards_red[player] && (cards_array.visitor_cards_red[player] == true || cards_array.visitor_cards_red[player][0] === 'on')){

            selcard = 'Red_Card';
         
        }else if(cards_array.visitor_cards_blue != undefined && cards_array.visitor_cards_blue[player] && (cards_array.visitor_cards_blue[player] == true || cards_array.visitor_cards_blue[player][0] === 'on')){

            selcard = 'Blue_Card';
         
        }

        return selcard;
    }
    /* eslint-disable */
    const prepareData = (input_values) => {

        if(input_values.local_player){
            input_values.local_player?.map( ( player, index)  => {
                let localevent = checkSelectedLocalCard(index, input_values);
                cards.local.push({ 
                    profile: player.username, 
                    event: localevent, 
                    minute: input_values.local_cards_minute ? input_values.local_cards_minute[index] ? input_values.local_cards_minute[index] : null : null
                })
                cardsfull.local.push({ 
                    profile: player, 
                    event: localevent, 
                    minute: input_values.local_cards_minute ? input_values.local_cards_minute[index] ? input_values.local_cards_minute[index] : null : null 
                })
            })
        }

        if(input_values.visitor_player){
            input_values.visitor_player?.map( ( player, index)  => {
                let visitorevent = checkSelectedVisitorCard(index, input_values);
                cards.visitant.push({ 
                    profile: player.username, 
                    event: visitorevent, 
                    minute: input_values.visitor_cards_minute ? input_values.visitor_cards_minute[index] ? input_values.visitor_cards_minute[index] : null : null
                })
                cardsfull.visitant.push({ 
                    profile: player, 
                    event: visitorevent, 
                    minute: input_values.visitor_cards_minute ? input_values.visitor_cards_minute[index] ? input_values.visitor_cards_minute[index] : null : null 
                })
            })
        }
    }
    function setFormValues ( form_data ){        
        if(form_data.local.length >0){
            form_data.local?.map((lscore, lindex) =>{
                const localPlayerFound = props.local_team_members?.find(player => player.username === lscore.profile.username);
                if(lscore.profile != null){
                    localPlayer[lindex]={
                        id: localPlayerFound.id ?? localPlayerFound.player_id,
                        is_assigned : localPlayerFound.is_assigned,
                        number: localPlayerFound.number,
                        photo: localPlayerFound.photo,
                        player_id: localPlayerFound.id ?? localPlayerFound.player_id,
                        player_name:localPlayerFound.player_name,
                        player_position: localPlayerFound.player_position,
                        rol_in_match:localPlayerFound.rol_in_match,
                        username: lscore.profile.username,
                    };
                }               
                if(lscore.event == "Yellow_Card"){
                    localYellowCard[lindex] = true;
                }else if(lscore.event == "Double_Yellow_Card"){
                    localDoubleYellowCard[lindex] = true;
                }else if(lscore.event == "Red_Card"){
                    localRedCard[lindex] = true;
                }else if(lscore.event == "Blue_Card"){
                    localBlueCard[lindex] = true;
                }
                localMinute[lindex] = lscore.minute;
            })                                
        }
        if(form_data.visitant.length >0){
            form_data.visitant?.map((vscore, vindex) =>{      
                const visitorPlayerFound = props.visitor_team_members?.find(player => player.username === vscore.profile.username);
                if(vscore.profile != null){
                    visitorPlayer[vindex]= {
                        id: visitorPlayerFound.id ?? visitorPlayerFound.player_id,
                        is_assigned : visitorPlayerFound.is_assigned,
                        number: visitorPlayerFound.number,
                        photo: visitorPlayerFound.photo,
                        player_id: visitorPlayerFound.id ?? visitorPlayerFound.player_id,
                        player_name:visitorPlayerFound.player_name,
                        player_position: visitorPlayerFound.player_position,
                        rol_in_match:visitorPlayerFound.rol_in_match,
                        username: vscore.profile.username,
                    }
                }
                if(vscore.event == "Yellow_Card"){
                    visitorYellowCard[vindex] = true;
                }else if(vscore.event == "Double_Yellow_Card"){
                    visitorDoubleYellowCard[vindex] = true;
                }else if(vscore.event == "Red_Card"){
                    visitorRedCard[vindex] = true;
                }else if(vscore.event == "Blue_Card"){
                    visitorBlueCard[vindex] = true;
                } 
                visitorMinute[vindex] = vscore.minute;
            })                                
        }

        let formValues = {
            local_player: localPlayer,
            local_cards_minute: localMinute,
            local_cards_y: localYellowCard,
            local_cards_dy: localDoubleYellowCard,
            local_cards_red: localRedCard,
            local_cards_blue: localBlueCard,
            visitor_player: visitorPlayer,
            visitor_cards_minute: visitorMinute,
            visitor_cards_y: visitorYellowCard,
            visitor_cards_dy: visitorDoubleYellowCard,
            visitor_cards_red: visitorRedCard,
            visitor_cards_blue: visitorBlueCard
        }
        return formValues;

    }
    /* eslint-enable */
    
    
    return (
        
        <FormComponent 
            formInitialValues={ initialValues } 
            enableReinitialize= {true}
            onSubmit={( values, actions ) => {   
                props.stepCompleted(5);                 
                prepareData( values );
                props.setCardsGameState( cardsfull );
                props.onNewData( cards );
                props.setEndGameStepState(true);
            }}
            className="league-match-scorer-form__form form scroll-parent"
            autocomplete="off"
            innerRef={formRef}
        >
            <TournamentEndMatchStepsComponent
                step = { props.step }
                stepCompleted = {(i)=>props.stepCompleted(i)}
                endGameStepState = { props.endGameStepState }
            />
            <LeagueMatchCardsComponent
                local_score= { props.local_score }
                local_team = {props.local_team }
                local_team_members={props.local_team_members }
                visitor_score= { props.visitor_score }
                visitor_team={props.visitor_team }
                visitor_team_members={ props.visitor_team_members }
                endGameState = { props.endGameState }
                initialValues = { initialValues }
                reValidateForm = {reValidateForm}
                removeFormValue= {(val,ind)=>{removeFormValue(val,ind)}}
            />
            <div className="league-match-cards-form__form__actions">
            {/* <input type='button'  onClick={()=>formRef.current.validateForm()}  />  */}
                <SecondaryButtonComponent 
                    disabled={false} 
                    onClick={()=>{props.stepCompleted(3);}} 
                    className="league-match-scorer-form__actions__back-btn"
                >
                    <span>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.labelButtonBack')}</span>
                </SecondaryButtonComponent>
                <PrimaryButtonComponent 
                    type="submit"
                    disabled={false} 
                    onClick={()=>{}} 
                    className="league-match-scorer-form__actions__submit"
                >
                    <span>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.labelButtonContinue')}</span>
                </PrimaryButtonComponent>
            </div>
        </FormComponent>

    )
}

export default withTranslation('league')(LeagueMatchCardsFormComponent);
// React
import React from 'react';
import { Link } from 'react-router-dom';

// React device detect
import {isMobile} from 'react-device-detect';

//i18
import { useTranslation } from 'react-i18next';

// Styles
import './LeagueMatchReportComponent.styles.css';

// Components
import LeagueMatchResultComponent from '../league-match-result-component/LeagueMatchResultComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import LeagueMatchRefereesPaymentModal from '../league-match-referees-payment-modal/LeagueMatchRefereesPaymentModal';
import LeagueMatchRefereesPaymentResumeModalComponent from '../league-match-referees-payment-resume-modal/LeagueMatchRefereesPaymentResumeModalComponent';
import LeagueMatchRefereesCrewConfirmationPaymentModal from '../league-match-referees-crew-confirmation-payment-modal/LeagueMatchRefereesCrewConfirmationPaymentModal';
import LeagueMatchRefereeAssignmentModal from '../league-match-referee-assignment-modal/LeagueMatchRefereeAssignmentModal';
import LeagueMatchPlayersAssistantModalComponent from '../league-match-player-assistant-modal/LeagueMatchPlayersAssistantModalComponent';
import LeagueMatchPlayerShowAssistantModalComponent from '../league-match-player-show-assistant-modal/LeagueMatchPlayerShowAssistantModalComponent';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

function LeagueMatchReportComponent (props) {

    const { t } = useTranslation('league');

    const [ teamRefereePayment, setTeamRefereePayment ] = React.useState(false);
    const [ teamAdminAssitance, setTeamAdminAssitance ] = React.useState(false);
    const [ showPlayerTeamsAssitance, setShowPlayerTeamsAssitance ] = React.useState(false);
    const [ resumeTeamRefereePayment, setResumeTeamRefereePayment ] = React.useState(false);
    const [ teamInfoPayment, setTeamInfoPayment] = React.useState();
    const [ refereeCrewPayment, setRefereeCrewPayment ] = React.useState(false);
    const [ refereeAssignmentModal, setRefereeAssignmentModal ] = React.useState(false);

    /* eslint-disable */

    React.useEffect(() => {
        if(props.isRefereesPaymentResponse) {
            setTeamRefereePayment(true);
        }
    }, [props.isRefereesPaymentResponse])

    const scorersList = () => {
        return props.data.events.map((row, index) => {
            if(row.event === 'Goal' && props.data.local_team === row.team) {
                return(
                    <div key={index} className='league-match-report__local-team__container league-match-report__local-scorer'>
                        <div className='league-match-report__local-team'>
                            <Link to={`/profile/${row.player}/`} className='league-match-report__local-team__info username' >
                                <div className="league-match-report__username">
                                    <div className="baloa-names">{row.player_full_name}</div>
                                    {/* <div className="baloa-username">{`@${row.player}`}</div> */}
                                    {row.assistant_full_name && <div className="baloa-username">{`${t('options.tournaments.pages.matchInfo.labelAssistant')}  ${row.assistant_full_name}`}</div> }
                                </div>
                                { row.player_photo && <img src={ row.player_photo } alt={`${row.player_full_name} img`}/>}
                                { !row.player_photo && <span className="icon-ball" color={getRandonIconBallColor()}/>}
                            </Link>
                            <span className={`icon-ball league-match-report__icon-ball ${row.own_goal? 'league-match-report__own-goal': ''}`}/>
                            <div class="league-match-report__local-team__info">
                                { row.own_goal && row.minute && <div className='baloa-body-2'>OG</div>}
                                { row.minute && <div className='baloa-table-column '>{`${row.minute}´`}</div>}
                            </div>
                        </div>
                        <div className='league-match-report__vertical-line' />
                    </div>
                )
            } else if(row.event === 'Goal' && props.data.visitant_team === row.team){
                return(
                    <div key={index} className='league-match-report__visitant-team__container league-match-report__visitant-scorer'>
                        <div className='league-match-report__visitant-team'>
                            <div className="league-match-report__visitant-team__info">
                                { row.minute && <div className='baloa-table-column'>{`${row.minute}´`}</div>}
                                { row.own_goal && row.minute && <div className='baloa-body-2'>OG</div>}
                            </div>
                            <span className={`icon-ball league-match-report__icon-ball ${row.own_goal? 'league-match-report__own-goal': ''}`}/>
                            <Link to={`/profile/${row.player}/`} className="league-match-report__visitant-team__info username">
                                { row.player_photo && <img src={ row.player_photo } alt={`${row.player_full_name} img`}/>}
                                { !row.player_photo && <span className="icon-ball" color={getRandonIconBallColor()}/>}
                                <div className="league-match-report__username">
                                    <div className="baloa-names">{row.player_full_name}</div>
                                    {/* <div className="baloa-username">{`@${row.player}`}</div> */}
                                    {row.assistant_full_name && <div className="baloa-username">{`${t('options.tournaments.pages.matchInfo.labelAssistant')} ${row.assistant_full_name}`}</div> }
                                </div>
                            </Link>                        
                        </div>
                        <div className='league-match-report__vertical-line' />
                    </div>
                )
            }
        });
    }

    const changesList = () => {
        return props.data.events.map((row, index) => {
            if(row.event === 'Player_Change' && props.data.local_team === row.team) {
                return(
                    <div key={index} className="league-match-report__changes-list__container league-match-report__local-change">
                        <div className='league-match-report__changes-list'>
                            <div className='league-match-report__changes-list__info username'>
                                <Link to={`/profile/${row.player}/`} className='league-match-report__changes-list__local'>
                                    <div className="league-match-report__changes__player-info__names">
                                        <div className="baloa-names">{row.player_full_name}</div>
                                        <div className="baloa-username">{`@${row.player}`}</div>
                                    </div>
                                    { row.player_photo && <img src={ row.player_photo } alt={`${row.player_full_name} img`}/>}
                                    { !row.player_photo && <span className="icon-ball" color={getRandonIconBallColor()}/>}
                                </Link>
                                <Link to={`/profile/${row.player_two}/`} className='league-match-report__changes-list__local'>
                                    <div className="league-match-report__changes__player-info__names">
                                        <div className="baloa-names">{row.player_two_full_name}</div>
                                        <div className="baloa-username">{`@${row.player_two}`}</div>
                                    </div>
                                    { row.player_two_photo && <img src={ row.player_two_photo } alt={`${row.player_full_name} img`}/>}
                                    { !row.player_two_photo && <span className="icon-ball" color={getRandonIconBallColor()}/>}
                                </Link>
                            </div>
                            <div className='league-match-report__changes-list__arrows'>
                                <span className="icon-arrow-right" />
                                <span className="icon-arrow-left" />
                            </div>
                            <div class="baloa-table-column league-match-report__changes-list__info">{`${row.minute? row.minute+'´': ''}`}</div>
                        </div>
                        <div className='league-match-report__vertical-line' />
                    </div>
                )
            } else if(row.event === 'Player_Change' && props.data.visitant_team === row.team){
                return(
                    <div key={index} className="league-match-report__changes-list__container league-match-report__visitant-change">
                         <div className="league-match-report__changes-list">
                            <div class="baloa-table-column league-match-report__changes-list__info visitant">{`${row.minute? row.minute+'´': ''}`}</div>
                            <div className='league-match-report__changes-list__arrows'>
                                <span className="icon-arrow-right" />
                                <span className="icon-arrow-left" />
                            </div>
                            <div className='league-match-report__changes-list__info username'>
                                <Link to={`/profile/${row.player}/`} className='league-match-report__changes-list__visitant'>
                                    { row.player_photo && <img src={ row.player_photo } alt={`${row.player_full_name} img`}/>}
                                    { !row.player_photo && <span className="icon-ball" color={getRandonIconBallColor()}/>}
                                    <div className="league-match-report__changes__player-info__names">
                                        <div className="baloa-names">{row.player_full_name}</div>
                                        <div className="baloa-username">{`@${row.player}`}</div>
                                    </div>
                                    
                                </Link>
                                <Link to={`/profile/${row.player_two}/`} className='league-match-report__changes-list__visitant'>
                                    { row.player_two_photo && <img src={ row.player_two_photo } alt={`${row.player_full_name} img`}/>}
                                    { !row.player_two_photo && <span className="icon-ball" color={getRandonIconBallColor()}/>}
                                    <div className="league-match-report__changes__player-info__names">
                                        <div className="baloa-names">{row.player_two_full_name}</div>
                                        <div className="baloa-username">{`@${row.player_two}`}</div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className='league-match-report__vertical-line' />
                    </div>
                )
            }
        });
    }

    const penalizedList = () => {
        return props.data.events.map((row, index) => {
            if(row.event !== 'Goal' && row.event !== 'Player_Change' && props.data.local_team === row.team) {
                return(                    
                    <div key={index} className='league-match-report__local-penalty__container league-match-report__local-penalty'>
                        <div className='league-match-report__local-team'>
                            <Link to={`/profile/${row.player}/`} className='league-match-report__local-team__info username' >
                                <div className="league-match-report__username">
                                    <div className="baloa-names">{row.player_full_name}</div>
                                    {/* <div className="baloa-username">{`@${row.player}`}</div> */}
                                </div>
                                { row.player_photo && <img src={ row.player_photo } alt={`${row.player_full_name} img`}/>}
                                { !row.player_photo && <span className="icon-ball" color={getRandonIconBallColor()}/>}
                            </Link>
                            <span className={`penalty-card ${row.event}`}/>
                            <div class="league-match-report__local-team__info">
                                { row.minute && <div className='baloa-table-column '>{`${row.minute}´`}</div>}
                            </div>
                        </div>
                        <div className='league-match-report__vertical-line' />
                    </div>
                )
            } else if(row.event !== 'Goal' && row.event !== 'Player_Change' && props.data.visitant_team === row.team){
                return(
                    <div key={index} className='league-match-report__visitant-penalty__container league-match-report__visitant-penalty'>
                        <div className='league-match-report__visitant-team'>
                            <div className="league-match-report__visitant-team__info">
                                { row.minute && <div className='baloa-table-column'>{`${row.minute}´`}</div>}
                            </div>
                            <span className={`penalty-card ${row.event}`}/>
                            <Link to={`/profile/${row.player}/`} className="league-match-report__visitant-team__info username">
                                { row.player_photo && <img src={ row.player_photo } alt={`${row.player_full_name} img`}/>}
                                { !row.player_photo && <span className="icon-ball" color={getRandonIconBallColor()}/>}
                                <div className="league-match-report__username">
                                    <div className="baloa-names">{row.player_full_name}</div>
                                    {/* <div className="baloa-username">{`@${row.player}`}</div> */}
                                </div>
                            </Link>
                        </div>
                        <div className='league-match-report__vertical-line' />
                    </div>
                )
            }
        });
    }

    /* eslint-enable */

    const handleBackButton = () => {                        
        props.handleBackButton();
    }

    const handleOnRefereeTeamPayment = (team_fee) => {
        setTeamInfoPayment(team_fee)
        setResumeTeamRefereePayment(true);
    }

    const handleOnAcceptCrewPayment = () => {
        props.handleOnAcceptCrewPayment(props.data.id);
        setRefereeCrewPayment(false);
    }

    const handleUpdateRefereesMatch = (match_id, referees_match_data, date_id) => {
        props.handleUpdateRefereesMatch(match_id, referees_match_data, date_id);
        setRefereeAssignmentModal(false);
    }

    return(
        <React.Fragment>
            {/* Modals component */}
            <LeagueMatchRefereesPaymentModal 
                isOpen={teamRefereePayment}
                onClose={ () => {setTeamRefereePayment(false)} }
                handleOnRefereeTeamPayment={ (team_fee) => {handleOnRefereeTeamPayment(team_fee)} }
                match_data={props.data}
                tournament_currency={props.tournament.currency_payment}
                handleOnConfirmCrewPayment={() => {setRefereeCrewPayment(true)}}
                handleOnRefereesAssignmentBtn={ () => {setRefereeAssignmentModal(true)} }
            />
            <LeagueMatchRefereesPaymentResumeModalComponent 
                isOpen={resumeTeamRefereePayment}
                onClose={ () => {setResumeTeamRefereePayment(false)} }
                match_data={props.data}
                team_payment={teamInfoPayment}
                tournament={props.tournament}
                handleOnLoadRefereeingPaymentUrl={props.handleOnLoadRefereeingPaymentUrl}
            />
            <LeagueMatchRefereesCrewConfirmationPaymentModal 
                isOpen={refereeCrewPayment}
                onClose={ () => {setRefereeCrewPayment(false)} }
                handleOnAcceptCrewPayment={() => {handleOnAcceptCrewPayment()}}                
            />
            <LeagueMatchRefereeAssignmentModal 
                isOpen = { refereeAssignmentModal }                                        
                onClose={() => { setRefereeAssignmentModal(false) }} 
                match_data={props.data}
                tournament_currency={props.tournament.currency_payment}
                resetError={()=>{}}                
                errors={props.errors}
                onResetError={props.onResetError}               
                tournament_staff={props.tournament_staff}
                handleUpdateRefereesMatch={(match_id, referees_match_data, date_id) => {handleUpdateRefereesMatch(match_id, referees_match_data, date_id)}} 
                referee_payments={props.tournament.referee_payments}
            />
            
            <LeagueMatchPlayersAssistantModalComponent
                isOpen = { teamAdminAssitance }
                onClose={() => { setTeamAdminAssitance(false) }}
                teams_match_assistance = {props.teams_match_assistance}
                updatePlayersAssistanceAligment={props.updatePlayersAssistanceAligment}
                match_data={props.data}
            />

            <LeagueMatchPlayerShowAssistantModalComponent
                isOpen = { showPlayerTeamsAssitance }
                onClose={() => { setShowPlayerTeamsAssitance(false) }}
                teams_match_assistance = {props.teams_match_assistance}
            />


            <div className="league-match-report__header">
                <span className="icon-arrow-left" onClick={() => { handleBackButton() } } />   
                <div className="baloa-headline-2">{props.backButtonTitle}</div>
                {(props.data.state === "Finalized" || props.data.state === "Scheduled") && !isMobile &&
                    <SecondaryButtonComponent 
                        onClick={ () => { props.handleOnShareMatchLinkClick() } }
                        className="league-match-report__header__share-button small" 
                    >
                        <span>{t('options.tournaments.pages.matchInfo.labelShare')}</span>
                        <span className='hubicon-share_v2'></span>
                    </SecondaryButtonComponent>
                }
                {isMobile &&
                    <span className='hubicon-share_v2' onClick={ () => { props.handleOnShareMatchLinkClick() } }></span>
                }                
            </div>
            <div className="league-match-report__container">
                <div className="league-match-report">
                    <div className="container-match-report">
                        <LeagueMatchResultComponent 
                            data_result = { props.data }
                            is_referee_payment={props.tournament.referee_payments}
                            handleOnRefereesAssignmentBtn={ () => {setRefereeAssignmentModal(true)} }
                        />
                        {isMobile && props.data.state === "Finalized" &&
                            <div className='league-match-report__match-player-container'>
                                <div className="baloa-subtitle-2 league-match-report__match-player-title">{t('options.tournaments.pages.matchInfo.labelMatchPlayer')}</div>
                                <div className='league-match-report__match-player__info'>                                           
                                    { props.data.player_match_photo && <img src={ props.data.player_match_photo } alt={`${props.data.player_match_name} img`}/>}
                                    { !props.data.player_match_photo && <span className="icon-ball" color={getRandonIconBallColor()}/>}
                                    <div>
                                        <div className='baloa-normal-medium'>{`${props.data.player_match_number? props.data.player_match_number : ''}. ${props.data.player_match_name}`}</div>
                                        <div className='baloa-table'>{`@${props.data.player_match}`}</div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className='league-match-report__results'>
                            {props.data.state === 'Finalized' && props.data.events?.length > 0 &&
                                <div className='league-match-report__results-container'>
                                    <div class="league-match-report__scorers-container">
                                        {scorersList()}
                                    </div>
                                    { props.data.events.some(el => el.event === 'Player_Change') && <div className='league-match-report__vertical-line__container'><div className='league-match-report__vertical-line' /></div>}
                                    <div class="league-match-report__changes-container">
                                        <div className='league-match-report__changes-column'>
                                            {changesList()}
                                        </div>
                                    </div>
                                    { props.data.events.some(el => (el.event === 'Double_Yellow_Card' || el.event === 'Yellow_Card' || el.event === 'Red_Card' || el.event === 'Blue_Card' )) && <div className='league-match-report__vertical-line__container'><div className='league-match-report__vertical-line' /></div>}
                                    <div class="league-match-report__penalized-container">
                                        <div className='league-match-report__penalized-column'>
                                            {penalizedList()}                            
                                        </div>
                                    </div>
                                </div>
                            }
                            {props.data.state === 'Finalized' && !isMobile &&
                                <div className='league-match-report__payments-comments__container'>
                                    {props.data.state === 'Finalized' &&
                                        <div className='league-match-report__assistance__container'>
                                            <div className='league-match-report__assistance__container_inf'>
                                                <div className='baloa-subtitle-2'>
                                                    {t('options.tournaments.pages.matchInfo.labelAssistanceAndAligment')}
                                                </div>
                                                <SecondaryButtonComponent onClick={ () => setShowPlayerTeamsAssitance(true)} >
                                                    {t('options.tournaments.pages.matchInfo.labelButtonAssistanceAndAligment')}
                                                </SecondaryButtonComponent>
                                            </div>
                                        </div>
                                    }
                                    {!isMobile && props.data.payment_enabled && props.tournament.referee_payments &&
                                        <div className='league-match-report__match-payments'>
                                            <div className='baloa-subtitle-2'>{t('options.tournaments.pages.matchRefereesPayment.titleMatchPayments')}</div>
                                            <SecondaryButtonComponent onClick={ () => { setTeamRefereePayment(true) } } >{t('options.tournaments.pages.matchRefereesPayment.payRefereesBtn')}</SecondaryButtonComponent>
                                        </div>
                                    }
                                    <div className='league-match-report__comments-container'>
                                        <div className="baloa-subtitle-2 league-match-report__comments-title">{t('options.tournaments.pages.matchInfo.labelComments')}</div>
                                        { props.data.comments
                                            ?
                                                <div className="baloa-table">{props.data.comments}</div>
                                            :
                                                <div className='baloa-normal'>{t('options.tournaments.pages.matchInfo.labelNoComments')}</div>
                                        }
                                    </div>
                                    {props.data.player_match &&
                                        <div className='league-match-report__match-player-container'>
                                            <div className="baloa-subtitle-2 league-match-report__match-player-title">{t('options.tournaments.pages.matchInfo.labelMatchPlayer')}</div>
                                            <div className='league-match-report__match-player__info'>                                           
                                                { props.data.player_match_photo && <img src={ props.data.player_match_photo } alt={`${props.data.player_match_name} img`}/>}
                                                { !props.data.player_match_photo && <span className="icon-ball" color={getRandonIconBallColor()}/>}
                                                <div>
                                                    <div className='baloa-normal-medium'>{`${props.data.player_match_number? props.data.player_match_number : ''}. ${props.data.player_match_name}`}</div>
                                                    <div className='baloa-table'>{`@${props.data.player_match}`}</div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                        
                        { !isMobile && props.data.state === 'Scheduled' && props.tournament.referee_payments && props.data.payment_enabled &&
                            <div className='league-match-report__match-payments'>
                                <div className='baloa-subtitle-2'>{t('options.tournaments.pages.matchRefereesPayment.titleMatchPayments')}</div>
                                <SecondaryButtonComponent onClick={ () => { setTeamRefereePayment(true) } } >{t('options.tournaments.pages.matchRefereesPayment.payRefereesBtn')}</SecondaryButtonComponent>
                            </div>
                        }
                        { !isMobile && props.data.state === 'Scheduled' && (props.data?.is_team_local_admin || props.data?.is_team_visitant_admin) &&
                            <div className='league-match-report__match-assistance'>
                                <div className='baloa-subtitle-2'>{t('options.tournaments.pages.matchInfo.labelAssistanceAndAligment')}</div>
                                <SecondaryButtonComponent onClick={ () => setTeamAdminAssitance(true)} >
                                    {t('options.tournaments.pages.matchInfo.labelTitleAsisstanceModal')}
                                </SecondaryButtonComponent>
                            </div>
                        }
                        { isMobile && props.data.state === "Finalized" &&
                            <React.Fragment>
                            <div className='league-match-report__comments-container'>
                                <div className="baloa-subtitle-2 league-match-report__comments-title">{t('options.tournaments.pages.matchInfo.labelComments')}</div>
                                { props.data.comments
                                    ?
                                        <div className="baloa-table">{props.data.comments}</div>
                                    :
                                        <div className='baloa-normal'>{t('options.tournaments.pages.matchInfo.labelNoComments')}</div>
                                }
                            </div>                            
                            </React.Fragment>
                        }
                    </div>
                </div>            
            </div>
        </React.Fragment>
    )
}

export default LeagueMatchReportComponent;

// React
import React from 'react';

// Components
import ModalComponent from 'shared/components/modal/ModalComponent';

// Styles
import './InvalidSessionModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';


function InvalidSessionModalComponent( props ) {

    return (
        <ModalComponent 
            isOpen={props.isOpen}
            //onClose={props.onClose}
            className={'invalid-session-modal'}
        >
            <div className='invalid-session-modal__container'>
                <span className='hubicon-info'/>
                <div className='baloa-subtitle-2'>{props.t('invalidSessionModal.title')}</div>
                <div className="baloa-names">{props.t('invalidSessionModal.message')}</div>
                <PrimaryButtonComponent
                    input_type={'button'}
                    onClick={ ()=>{ props.handleOnLogin() }}
                >
                    <span>{props.t('invalidSessionModal.startSessionBtnLbl')}</span>
                </PrimaryButtonComponent>
            </div>            
        </ModalComponent>
    )
}
    
export default withTranslation('signUp')(InvalidSessionModalComponent);
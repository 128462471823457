// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.phases-view-more-sanctions-modal .modal2__content {
    padding: 24px 53px 24px 68px;
}


.phases-view-more-sanctions-modal .modal2__content .infinite-scroll-component {
    padding-right: 15px;
}

@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */ 

    .phases-view-more-sanctions-modal {
        background: var(--new-background-color);
    }

    .phases-view-more-sanctions-modal.modal2{
        align-items: flex-start;
    }

    .phases-view-more-sanctions-modal .modal2__header{
        padding: 12px 16px;
    }

    .phases-view-more-sanctions-modal .modal2__container {
        margin: 0;
        max-height: 100%;
        border-radius: 0;
    }

    .phases-view-more-sanctions-modal .modal2__content {
        padding: 0;
        margin-top: 10px;
        background: transparent;
    }

    .phases-view-more-sanctions-modal .tournament-fair-play-penalties-list__list-types-menu {
        border-top: 1px solid var(--new-gray-background-color);
        background: var(--white-color);
        padding: 8px 16px;
    }

    .phases-view-more-sanctions-modal .infinite-scroll-component__outerdiv {
        background: var(--white-color);
        padding: 0 16px;
    }
}`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/tournament-phases-view-more-sanctions-modal/TournamentPhasesViewMoreSanctionsModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;AAChC;;;AAGA;IACI,mBAAmB;AACvB;;AAEA,yCAAyC,4BAA4B;;IAEjE;QACI,uCAAuC;IAC3C;;IAEA;QACI,uBAAuB;IAC3B;;IAEA;QACI,kBAAkB;IACtB;;IAEA;QACI,SAAS;QACT,gBAAgB;QAChB,gBAAgB;IACpB;;IAEA;QACI,UAAU;QACV,gBAAgB;QAChB,uBAAuB;IAC3B;;IAEA;QACI,sDAAsD;QACtD,8BAA8B;QAC9B,iBAAiB;IACrB;;IAEA;QACI,8BAA8B;QAC9B,eAAe;IACnB;AACJ","sourcesContent":[".phases-view-more-sanctions-modal .modal2__content {\n    padding: 24px 53px 24px 68px;\n}\n\n\n.phases-view-more-sanctions-modal .modal2__content .infinite-scroll-component {\n    padding-right: 15px;\n}\n\n@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */ \n\n    .phases-view-more-sanctions-modal {\n        background: var(--new-background-color);\n    }\n\n    .phases-view-more-sanctions-modal.modal2{\n        align-items: flex-start;\n    }\n\n    .phases-view-more-sanctions-modal .modal2__header{\n        padding: 12px 16px;\n    }\n\n    .phases-view-more-sanctions-modal .modal2__container {\n        margin: 0;\n        max-height: 100%;\n        border-radius: 0;\n    }\n\n    .phases-view-more-sanctions-modal .modal2__content {\n        padding: 0;\n        margin-top: 10px;\n        background: transparent;\n    }\n\n    .phases-view-more-sanctions-modal .tournament-fair-play-penalties-list__list-types-menu {\n        border-top: 1px solid var(--new-gray-background-color);\n        background: var(--white-color);\n        padding: 8px 16px;\n    }\n\n    .phases-view-more-sanctions-modal .infinite-scroll-component__outerdiv {\n        background: var(--white-color);\n        padding: 0 16px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

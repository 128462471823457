import React, { useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ListItemText from '@mui/material/ListItemText';


// Styles
import './TournamentPhasesFairPlayDropDownComponent.styles.css'

//i18
import { withTranslation } from 'react-i18next';


function TournamentPhasesFairPlayDropDownComponent(props) {
    const [nameSelectedPhase, setNameSelectedPhase] = useState('');

    useEffect(() => {
        if (props.tournament_phases?.length > 0) {
            let aux_phases = props.tournament_phases?.filter(phase => phase.is_last_active_phase === true);
            if (aux_phases.length === 0) {
                props.handleOnClickPhaseFairlPlay(props.tournament_phases[props.tournament_phases.length - 1]);
                setNameSelectedPhase(props.tournament_phases[props.tournament_phases.length - 1]?.id);
            } else {
                props.handleOnClickPhaseFairlPlay(aux_phases[0]);
                setNameSelectedPhase(aux_phases[0]?.id);
            }
            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.tournament_phases]);



    return (
        // <div className={`league-tournament-report__phases__list tournament-phases ${props.showEndPhaseMenu ? 'end-phase' : ''}`}>
        <div className='tournament-phases-fair_play-drop-down__container'>
            <FormControl >
                <Select
                    value={nameSelectedPhase}
                    //displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    variant='standard'
                    autoWidth={true}
                    name='tournament-phases-fair_play-drop-down__select'
                >
                    {props.tournament_phases?.filter(phase => phase.phase_type !== null).map((phase, index) => (
                        <MenuItem
                            key={`Phase ${index + 1}`}
                            value={phase.id}
                            onClick={() => {
                                setNameSelectedPhase(phase.id);
                                props.handleOnClickPhaseFairlPlay(phase);
                            }}
                        >

                            <ListItemText
                                primary={
                                    <div>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelPhase')}
                                        {index + 1} - {props.t(`options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.${phase.phase_name}`)}
                                    </div>
                                }
                                secondary={phase.is_last_active_phase? props.t('options.tournaments.pages.newTournament.tabs.tabPunctuationAndFairPlay.currentPhaseLbl') : props.t(`options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.${phase.phase_name}`) }
                            />
                        </MenuItem>
                    )
                    )
                    }
                </Select>
            </FormControl>
            
        </div>
    )
}

export default withTranslation('league')(TournamentPhasesFairPlayDropDownComponent);
/*eslint eqeqeq: "off"*/
// Baloa Pro State
const baloaplay_state = {
    loading_games: false,
    subsections: [],
    subsections_data: [],
    search_match_videos_loading: false,
    search_match_videos: [],
    selected_match: undefined,
    is_match_hidden: true,
    match_clips: [],
    match_clips_next_url: null,
    match_payment_url: undefined,
    clip_payment_url: undefined,
    loading_clips: false,
    my_clips: [],
    my_clips_next_url: null,
    selected_clip: undefined,
    claim_clips_loading: false,
    post_to_delete_id: null,
    modals: {
        full_video_is_open: false,
        payment_message_is_open: false,
        payment_options_is_open: false,
        clips_is_open: false,
        clips_video_is_open: false,
        //payment_clips_options_is_open: false,
        claim_clips_options_is_open: false,
        my_clips_video_is_open: false,
        update_clip_is_open: false,
        add_to_post_is_open: false,
        baloa_pro_welcome_is_open: false,
        create_clip_welcome_is_open: false,
        create_clip_is_open: false,
        create_clip_confirmation_is_open: false,
        max_clips_info_is_open: false,
        match_payment_confirmation_is_open: false,
        share_purchase_math_link_is_open: false,
        accept_shared_math_link_is_open: false,
        invalid_link_is_open: false,
        max_download_clips_info_is_open: false,
        block_video_is_open: false,
        match_payment_personalize_plan_is_open: false,
        match_pay_extra_clips_is_open: false,
    },
    shwow_create_clip_welcome: true,
    match_plans: undefined,
    shared_link_match_info: undefined,
    download_clip_url: null,
    match_payment_coupon: undefined,
}

export default function BaloaPlayReducer ( state = baloaplay_state, action ) { 
    switch( action.type ) {

        case "BALOAPLAY:CHANGE_LOADING_SUB_SECTIONS": {
            return { ...state, loading_games: action.new_state }
        }

        case "BALOAPLAY:LOAD_SUB_SECTIONS": {
            return { ...state, subsections: action.data.results,  subsections_data: []}
        }

        case "BALOAPLAY:LOAD_SUB_SECTIONS_ARRAY": {
            let subsection_index = state.subsections_data.findIndex(el => el.filter_type === action.filter_type);
            let new_data_array = null;

            if(subsection_index > -1){
                new_data_array = state.subsections_data.map(el => 
                    (el.filter_type === action.filter_type && el.next_url !== action.data.next)?
                    {...el, filter_type: action.filter_type, matches: el.matches.concat(action.data.results) , next_url: action.data.next}/* console.log('elemento por cambiar', el) */ : el /* console.log('elemento anterior', el)      */              
                )
            }else{
                new_data_array = state.subsections_data.concat( {filter_type: action.filter_type, matches: action.data.results, next_url: action.data.next} );
            }
            return { ...state, subsections_data: new_data_array }
        }

        case "BALOAPLAY:CHANGE_SEARCH_MATCH_VIDEOS_LOADING": {
            return { ...state, search_match_videos_loading: action.new_state }
        }

        case "BALOAPLAY:LOAD_SEARCH_MATCH_VIDEOS": {
            return { ...state, search_match_videos: action.data.results}
        }

        case "BALOAPLAY:LOAD_BALOA_PLAY_MATCH": {
            return { ...state, selected_match: action.data, is_match_hidden: action.visibility}
        }

        case "BALOAPLAY:CHANGE_FULL_VIDEO_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], full_video_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "BALOAPLAY:LOAD_BALOA_PLAY_MATCH_CLIPS": {
            let new_match_clips = null;
            if(action.data.previous && state.match_clips_next_url !== action.data.next ){
                new_match_clips = state.match_clips.concat( action.data.results );
            }else{
                new_match_clips = action.data.results;
            }           
            return { ...state, match_clips: new_match_clips, match_clips_next_url: action.data.next }
        }

        case "BALOAPLAY:CHANGE_PAYMENT_MESSAGE_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], payment_message_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "BALOAPLAY:CHANGE_PAYMENT_OPTIONS_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], payment_options_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "BALOAPLAY:SET_MATCH_PAYMENT_URL": {
            return { ...state, match_payment_url: action.data.url }
        }

        case "BALOAPLAY:CHANGE_CLIPS_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], clips_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "BALOAPLAY:CHANGE_CLIPS_VIDEO_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], clips_video_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        /* case "BALOAPLAY:CHANGE_PAYMENT_CLIPS_OPTIONS_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], payment_clips_options_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        } */

        case "BALOAPLAY:SET_CLIP_PAYMENT_URL": {
            return { ...state, clip_payment_url: action.data.url }
        }

        case "BALOAPLAY:CHANGE_LOADING_CLIPS": {
            return { ...state, loading_clips: action.new_state }
        }

        case "BALOAPLAY:LOAD_MY_CLIPS": {
            let new_my_clips = null;
            if(action.data.previous && state.my_clips_next_url !== action.data.next ){
                new_my_clips = state.my_clips.concat( action.data.results );
            }else{
                new_my_clips = action.data.results;
            }           
            return { ...state, my_clips: new_my_clips, my_clips_next_url: action.data.next }
        }

        case "BALOAPLAY:LOAD_CLIP": {
            return { ...state, selected_clip: action.data}
        }

        case "BALOAPLAY:CHANGE_CLAIM_CLIPS_OPTIONS_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], claim_clips_options_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "BALOAPLAY:CHANGE_LOADING_CLAIM_CLIPS": {
            return { ...state, claim_clips_loading: action.new_state }
        }

        case "BALOAPLAY:CHANGE_MY_CLIPS_VIDEO_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], my_clips_video_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "BALOAPLAY:CHANGE_UPDATE_CLIP_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], update_clip_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "BALOAPLAY:CHANGE_ADD_TO_POST_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], add_to_post_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "BALOAPLAY:CHANGE_DELETE_PRESENTATION_VIDEO_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], delete_post_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "BALOAPLAY:SET_POST_TO_DELETE": {
            return { ...state, post_to_delete_id: action.data }
        }

        case "BALOAPLAY:CHANGE_BALOA_PRO_WELCOME_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], baloa_pro_welcome_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "BALOAPLAY:CHANGE_UNAUTH_PLAY_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], baloa_play_unauth_play_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "BALOAPLAY:CHANGE_CREATE_CLIP_WELCOME_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], create_clip_welcome_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "BALOAPLAY:CHANGE_CREATE_CLIP_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], create_clip_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "BALOAPLAY:CHANGE_CREATE_CLIP_CONFIRMATION_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], create_clip_confirmation_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "BALOAPLAY:CHANGE_MAX_CLIPS_INFO_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], max_clips_info_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "BALOAPLAY:CHANGE_SHOW_CREATE_CLIP_WELCOME_STATE": {
            return { ...state, shwow_create_clip_welcome: action.new_state }
        }

        case "BALOAPLAY:CHANGE_MATCH_PAYMENT_CONFIRMATION_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], match_payment_confirmation_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "BALOAPLAY:LOAD_MATCH_PLANS": {
            return { ...state, match_plans: action.data }
        }

        case "BALOAPLAY:CHANGE_SHARE_PURCHASE_MATCH_LINK_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], share_purchase_math_link_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "BALOAPLAY:CHANGE_ACCEPT_SHARED_MATCH_LINK_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], accept_shared_math_link_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "BALOAPLAY:SHARED_LINK_MATCH_INFO": {
            return { ...state, shared_link_match_info: action.data }
        }

        case "BALOAPLAY:CHANGE_INVALID_LINK_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], invalid_link_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "BALOAPLAY:CHANGE_MAX_DOWNLOAD_CLIPS_INFO_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], max_download_clips_info_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "BALOAPLAY:LOAD_CLIP_URL": {
            return { ...state, download_clip_url: action.data.post_video }
        }

        case "BALOAPLAY:CHANGE_BLOCK_VIDEO_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], block_video_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "BALOAPLAY:CHANGE_MATCH_PAYMENT_PERSONALIZE_PLAN_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], match_payment_personalize_plan_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "BALOAPLAY:SET_MATCH_PAYMENT_COUPON": {
            return { ...state, match_payment_coupon: action.data }
        }

        case "BALOAPLAY:RESET_PAYMENT_COUPON": {
            return { ...state, match_payment_coupon: undefined }
        }
        
        case "BALOAPLAY:CHANGE_PAY_EXTRA_CLIPS_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], match_pay_extra_clips_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "BALOAPLAY:SET_PAYMENT_EXTRA_CLIPS": {
            return { ...state, extra_clips_payment_url: action.data.url }
        }

        //no-default
    }
    return state;
}
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delete-extra-sanction-confirmation-modal .modal__container {
    width: 375px;
    padding: 0;
    text-align: center;
}

.delete-extra-sanction-confirmation-modal .baloa-names {
    margin-top: 26px;
    color: var(--primary-very-dark-color);
    padding: 0 16px;
}

.delete-extra-sanction-confirmation-modal .baloa-table {
    margin-top: 10px;
    color: var(--primary-dark-color);
    padding: 0 16px;
}


.delete-extra-sanction-confirmation-modal .btn-delete {
    color: var(--error-color);
    border-top: 1px solid var(--new-gray-background-color);
    text-align: center;
    padding: 16px;
    cursor: pointer;
}

.delete-extra-sanction-confirmation-modal .btn-cancel {
    color: var(--primary-very-dark-color);
    font-weight: var(--font-regular);
    border-top: 1px solid var(--new-gray-background-color);
    text-align: center;
    padding: 16px;
    margin-top: 0;
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/tournament-phase-delete-extra-sanction-confirm-modal/TournamentPhaseDeleteExtraSanctionConfirmationModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,UAAU;IACV,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,qCAAqC;IACrC,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,gCAAgC;IAChC,eAAe;AACnB;;;AAGA;IACI,yBAAyB;IACzB,sDAAsD;IACtD,kBAAkB;IAClB,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,qCAAqC;IACrC,gCAAgC;IAChC,sDAAsD;IACtD,kBAAkB;IAClB,aAAa;IACb,aAAa;IACb,eAAe;AACnB","sourcesContent":[".delete-extra-sanction-confirmation-modal .modal__container {\n    width: 375px;\n    padding: 0;\n    text-align: center;\n}\n\n.delete-extra-sanction-confirmation-modal .baloa-names {\n    margin-top: 26px;\n    color: var(--primary-very-dark-color);\n    padding: 0 16px;\n}\n\n.delete-extra-sanction-confirmation-modal .baloa-table {\n    margin-top: 10px;\n    color: var(--primary-dark-color);\n    padding: 0 16px;\n}\n\n\n.delete-extra-sanction-confirmation-modal .btn-delete {\n    color: var(--error-color);\n    border-top: 1px solid var(--new-gray-background-color);\n    text-align: center;\n    padding: 16px;\n    cursor: pointer;\n}\n\n.delete-extra-sanction-confirmation-modal .btn-cancel {\n    color: var(--primary-very-dark-color);\n    font-weight: var(--font-regular);\n    border-top: 1px solid var(--new-gray-background-color);\n    text-align: center;\n    padding: 16px;\n    margin-top: 0;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import InscriptionsFeeComponent from 'app/tournaments/components/inscriptions-fee/InscriptionsFeeComponent';
import InscriptionsFeePlayerComponent from '../inscriptions-fee-player/InscriptionsFeePlayerComponent';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// Styles
import './HubPaymentsModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function HubPaymentsModalComponent ( props ) {

    const closeModal=()=>{
        props.onClose();
    }

    /* const submitCode=( data )=>{
        props.handleOnCodeButton(data.inscode);
    } */

    return(
        <Modal2Component
            isOpen={props.isOpen} 
            onClose={closeModal}
            title={
                props.profile_type === 'player'
                    ? props.t('inscriptionModal.labelPlayerPayment')
                    : props.t('inscriptionModal.title')
                }
            className="inscriptions_payment_modal_container"
        >
            <div className="inscriptions_payment_modal scroll-parent">
                {/** Player data*/}
                {props.profile_type === 'player' &&
                    <div className='inscriptions_payment_modal__team-info'>
                        { props.selected_admin_player?.profile_photo
                            ?
                            <img src={props.selected_admin_player?.profile_photo} alt={`${props.selected_admin_player?.username} img`} />
                            :
                            <span className={"icon-ball"} color={getRandonIconBallColor()} ></span>
                        }
                        <div className='inscriptions_payment_modal__team-info__name-username'>
                            <div className='baloa-names'>{props.selected_admin_player?.full_name}</div>
                            <div className='baloa-username'>@{props.selected_admin_player?.username}</div>
                        </div>
                    </div>
                }
                {/* Payment form  */}
                <div /* className='inscriptions_payment_modal' */>
                    {props.profile_type === 'player'?
                        <React.Fragment>
                            <InscriptionsFeePlayerComponent                                 
                                registration_price_per_player={props.tournament_registration_price_per_player}
                                user_pays_processing_rate={props.user_pays_processing_rate}
                                user_pays_processing_rate_price_player={props.user_pays_processing_rate_price_player}
                                currency={props.currency}
                                //submitCode={(data)=>{submitCode(data)}}
                                handleOnCodeButton={props.handleOnCodeButton}
                                payment_player_code={props.payment_player_code}

                                profile_id={props.profile_id}
                                tournament_player_id={props.tournament_player_id}
                                resetPaymentButton={props.resetPaymentButton}
                                submitWompiForm={props.submitWompiForm}
                                type_redirect={props.type_redirect} //Llega 'teams' pago player por team admin 
                                profile_phone={props.profile_phone}
                                tournament_id={props.tournament_id}
                                resetPlayerCoupon={ props.resetPlayerCoupon } 
                            />                                
                        </React.Fragment>
                    :
                        <React.Fragment>
                            <InscriptionsFeeComponent 
                                registration_price_per_team={props.tournament_registration_price_per_team}
                                registration_price_per_player={props.tournament_registration_price_per_player}
                                user_pays_processing_rate={props.user_pays_processing_rate}
                                user_pays_processing_rate_price_team={props.user_pays_processing_rate_price_team}
                                user_pays_processing_rate_price_player={props.user_pays_processing_rate_price_player}
                                currency={props.currency}
                                setPlayersQuantity={ props.setPlayersQuantity }
                                profile_type={props.profile_type}

                                profile_id={props.profile_id}
                                tournament_team_id={props.tournament_team_id}
                                resetPaymentButton={props.resetPaymentButton}
                                submitWompiForm={props.submitWompiForm}
                                type_redirect={props.profile_type} //Llega 'teams'
                                tournament_registration_type={props.tournament_registration_type}
                                profile_phone={props.profile_phone}
                                tournament_id={props.tournament_id}
                                handleOnValidateCupon={ props.handleOnValidateCupon }
                                resetCoupon= {props.resetCoupon }
                                discount_coupon={ props.discount_coupon }
                            />
                        </React.Fragment>
                    }
                    
                </div>
                

                <div className='inscriptions_payment_modal__buttons'>
                    <PrimaryButtonComponent
                        type={'button'}
                        onClick={ ()=>{                           
                            /* if(props.payment_player_code !== 'player_code'){
                                props.handleOnPayment(props.discount_coupon?.coupon_is_valid? props.discount_coupon?.coupon : null);
                            }else{
                                props.handleCuponCodePayment();
                            } */
                            props.handleOnPayment(props.discount_coupon?.coupon_is_valid? props.discount_coupon?.coupon : props.payment_player_code?.coupon_is_valid? props.payment_player_code?.coupon : null);
                        }}
                        disabled={false} 
                        className='small'
                    >
                        <span>{props.t('inscriptionModal.buttons.finalize')}</span>
                    </PrimaryButtonComponent>    
                </div>
            </div>
        </Modal2Component>
    )

}

export default withTranslation('tournament')(HubPaymentsModalComponent);
// React
import React from 'react';
import PropTypes from 'prop-types';

// Formik
import { Field } from 'formik';

// Styles
import './InputFieldMultilineComponent.styles.css';


class InputFieldMultilineComponent extends React.Component {
    
    state = { 
        caption: null,
    };

    sendCaption = event => {
        this.props.setCaption(event.target.value);
    }

    render() {
        
        const field_name = this.props.field_name;
        
        return (

            <Field name={ field_name } validate={ this.props.validationFunction } >
                { ( data ) => {
                    
                    if ( this.props.error[ field_name ] !== "" ) {
                        data.form.errors[ field_name ] = this.props.error[ field_name ];
                    }

                    return (
                        <div className= { "input-field-multiline " + ( this.props.className ? this.props.className : '' )  } >
                            { this.props.label && <label className="input-field-multiline-label baloa-names" htmlFor={ this.props.input_id }>{ this.props.label }</label> }
                            <textarea
                                id={ this.props.input_id }
                                disabled={ this.props.disabled }
                                onClick={ this.props.onClick } 
                                onChange={ this.props.onChange ? this.props.onChange(data.field.value) : undefined }                               
                                className={ 
                                    'input-field-multiline-input baloa-table ' + ( ( data.form.errors[ field_name ] && data.form.touched[ field_name ] ) ? 'error' : '' )
                                }
                                { ...data.field }
                                onKeyDown={ () => { this.props.resetError() } }
                                placeholder={ ( this.props.placeholder ? this.props.placeholder : '' ) }
                                maxLength={ this.props.maxLength ? this.props.maxLength : undefined }  
                                rows ={ this.props.rows ? this.props.rows : undefined }
                                defaultValue= { this.props.defaultValue? this.props.defaultValue : '' }
                            />                            
                            <p className="input-field-multiline__message">{ this.props.message }</p>
                            <p className="input-field-multiline__message error">{ data.form.errors[ field_name ] }</p>
                        </div>
                    )

                } }
            </Field>
            
        );
    }
}

// Types
InputFieldMultilineComponent.propTypes = {
    validationFunction: PropTypes.func.isRequired,
    resetError: PropTypes.func.isRequired,
}
export default InputFieldMultilineComponent;

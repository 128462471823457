/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Styles
import './PlayerColumnComponent.styles.css';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

export default function PlayerColumnComponent(props) {
    console.log('row',props)
    return (
        <React.Fragment>
            <div class="player-column">
                <div class="player-column__image">
                    {props.row.photo
                        ?
                        <img src={ props.row.photo } alt="player-img" />
                        :
                        <span className="icon-ball" color={getRandonIconBallColor()}/>
                    }
                </div>
                <div class="player-column__data">                    
                    <div className='baloa-names'>
                        {props.row.full_name}
                        {props.row?.subscription_plan !== 'FREE' &&
                            <span className='hubicon-award_star'/>
                        }
                    </div>
                    <div className='baloa-username'>@{props.row?.profile? props.row.profile : props.row?.username}</div>
                </div>                       
            </div>
        </React.Fragment>
    )
    
}
/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Components
import { Field } from 'formik';
import Autocomplete  from '@mui/material/Autocomplete';

import TextField from '@mui/material/TextField';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';

//Assets
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';
// Styles
import './InputFieldAutocompleteComponent.styles.css';
import { withStyles } from '@mui/styles';

//Assets
import baloa_user_icon from 'assets/icons/baloa-user.svg';
import team_shield_icon from 'assets/icons/team-shield.svg';

function InputFieldAutocompleteComponent( props ) {
    const AutocompleteTextField = withStyles({
        root: {
            '& label.Mui-focused': {
                color: 'var(--basic-gray-color)',
            },          
            '& .MuiOutlinedInput-root': {
                '& fieldset': {             
                    border: 'var(--new-input-background-color)',
                },      
            },
        },
    })(TextField);

    // const local_value = props.value ? props.options.find(opt => opt[props.optionSelectedField] == props.value[props.optionSelectedField]): '';

    const handleClearMemberTeam = () => {
        //props.formRef.current?.setFieldValue(props.name, null);
        props.onChange( null );
    };

    return(
            <Field name={ props.name } id={ props.id }>
            {
                ({
                    form: { setFieldValue }, 
                }) => (
                <Autocomplete                    
                    //key={ props.key }
                    //name={ props.name }
                    disabled={ props.disabled }
                    //component={Autocomplete}
                    freeSolo={props.freeSolo ? props.freeSolo : false}
                    options={props.options}
                    noOptionsText= { props.noOptionsText }
                    getOptionLabel={(option) => {
                        if(props.optionLabelField == "players"){
                            return (option.first_name !== undefined && (option.username !== undefined || option.name !== undefined) ?`${option.first_name} ${option.last_name} @${option.username !== undefined ? option.username: option.name}`: '')
                        }else if(props.optionLabelField == "members"){
                            return (option.full_name? `${option.full_name}` : '')
                        }else if(props.optionLabelField == "teams_tournament"){
                            return (option.team ? `${option.team}` : '')
                        }else if(props.optionLabelField == "teams"){
                            return (option.team ? `${option.team}` : '')
                        }else if(option[props.optionLabelField]){
                            return (`${option[props.optionLabelField]}`)
                        }else if(props.optionLabelField == "staff"){
                            return option.first_name? option.first_name : option.referee_name;
                        }else if(props.optionLabelField === "revelationPlayer"){
                            return (option?.player_name !== undefined 
                                ? `${option.number ? `${option.number}.` : ''} ${option.player_name}`
                                : '')
                        }
                        else{
                            return '';
                        }
                    }}
                    getOptionKey={option => option[props.optionSelectedField] }
                    disablePortal={true}
                    isOptionEqualToValue={( option, value ) => {
                        if(value){
                            return option[props.optionSelectedField] === value[props.optionSelectedField] 
                        }else if(!option){
                            return null
                        }else{
                            return null
                        }
                        
                    }}
                    defaultValue={props.defaultValue? props.defaultValue : null}
                    //value={ local_value || "" }
                    value={ props.value }
                    onChange={(e, value) => {
                        if(props.onChange ){
                            props.onChange( value )
                        }                    
                        //setLocalValue( value )
                        setFieldValue(props.name, value )
                    }}
                    renderOption={(comprops, option) => {
                        //display value in Popper elements
                        if(props.optionLabelField === "players" || props.optionLabelField === "staff"){
                            return <ListItem
                                        {...comprops}
                                        key={option.id}
                                        className="input-field-autocomplete__list"
                                    >
                                        {option.first_name === undefined 
                                        ? <ListItemText primary={ option.message }  className="input-field-autocomplete__listitem" />
                                        :   <React.Fragment>
                                                <ListItemIcon className="input-field-autocomplete__list__icon" >
                                                    <img className="input-field-autocomplete__list__icon__img" src={ option.photo ? option.photo : baloa_user_icon} alt=""/>
                                                </ListItemIcon>
                                                <ListItemText primary={ option.first_name + ' ' + option.last_name} secondary={ '@'+(option.username !== undefined ? option.username: option.profile ) } className="input-field-autocomplete__listitem" />
                                            </React.Fragment>
                                        }
                                    </ListItem>
                        }else if(props.optionLabelField === "members"){
                            return <ListItem
                                        {...comprops}
                                        key={option.id}
                                        className="input-field-autocomplete__list"
                                    >
                                        <ListItemIcon className="input-field-autocomplete__list__icon" >
                                            <img className="input-field-autocomplete__list__icon__img" src={ option.photo ? option.photo : baloa_user_icon} alt=""/>
                                        </ListItemIcon>
                                        <ListItemText primary={ option.full_name } secondary={ '@'+(option.username ) } className="input-field-autocomplete__listitem" />
                                    </ListItem>
                        }else if(props.optionLabelField == "teams_tournament"){
                            return <ListItem
                                        {...comprops}
                                        key={option.id}
                                        button
                                        className="input-field-autocomplete__list"
                                        //selected={selectedIndex === option.id}
                                        //onClick={(event) => handleListItemClick(event, item)}
                                    >
                                        <ListItemIcon className="input-field-autocomplete__list__icon" >
                                            <img className="input-field-autocomplete__list__icon__img" src={ option.photo ? option.photo : team_shield_icon} alt=""/>
                                        </ListItemIcon>
                                        <ListItemText primary={ option.team } className="input-field-autocomplete__listitem" />
                                    </ListItem>
                        }else if(props.optionLabelField == "teams"){
                            return <ListItem
                                        {...comprops}
                                        key={option.id}
                                        button
                                        className="input-field-autocomplete__list"
                                        //selected={selectedIndex === option.id}
                                        //onClick={(event) => handleListItemClick(event, item)}
                                    >
                                        <ListItemIcon className="input-field-autocomplete__list__icon" >
                                            <img className="input-field-autocomplete__list__icon__img" src={ option.photo ? option.photo : team_shield_icon} alt=""/>
                                        </ListItemIcon>
                                        <ListItemText primary={ option.name }  secondary={ '@'+(option.team ) } className="input-field-autocomplete__listitem" />
                                    </ListItem>
                        }else if( props.optionLabelField === "revelationPlayer"){
                            return <ListItem
                                    {...comprops}
                                    key={option.player_id}
                                    className="input-field-autocomplete__list"
                                    >
                                    {option.player_name === undefined 
                                        ? <ListItemText primary={ option.message }  className="input-field-autocomplete__listitem" />
                                        :   <React.Fragment>
                                                <ListItemIcon className="input-field-autocomplete__list__icon" >
                                                    <img className="input-field-autocomplete__list__icon__img" src={ option.photo ? option.photo : baloa_user_icon} alt=""/>
                                                </ListItemIcon>
                                            <ListItemText primary={`${option.number ? `${option.number}.` : '' } ${option.player_name}`} 
                                                secondary={ '@'+(option.username !== undefined ? option.username: option.profile ) } 
                                                className="input-field-autocomplete__listitem" />
                                        </React.Fragment>
                                    }
                                    </ListItem>
                        }else{
                            return <ListItem
                                        {...comprops}
                                        key={option.player_id}
                                        className="input-field-autocomplete__list"
                                    >
                                        {option[props.optionLabelField]}
                                    </ListItem>
                        }
                    }}               
                    renderInput={(params) => (                        
                        <div className={`search_members_staff_edit_content ${props.className}`} >
                            {(props.value && props.optionLabelField === "staff") 
                            ?  
                            <ListItem className='add-staff-modal__search-selected' key={props.id} {...params.inputProps}>                            
                                <ListItemIcon className="league-search-profile__icon" >
                                    {(props.value.photo || props.value.referee_photo) &&
                                        <img className="league-search-profile__icon__icon__img"
                                            src={props.value.photo ?? props.value.referee_photo}
                                            alt={`${props.value.profile} img`} />
                                    }
                                    {(!props.value.photo && !props.value.referee_photo) &&
                                        <span className={"icon-ball " + getRandonIconBallColor()}
                                            color={getRandonIconBallColor()} />
                                    }
                                </ListItemIcon>
                                <ListItemText
                                    className="league-search-profile__listitem league-search-profile__profile"
                                    primary={`${props.value.first_name ?? props.value.referee_name} ${props.value.last_name ?? ''}`}
                                    secondary={`@ ${props.value.username ?? ''}${props.value.profile ?? ''}${props.value.referee_username ?? ''}`}
                                />
                                {!props.disabled && 
                                    <ListItemSecondaryAction onClick={() => { handleClearMemberTeam() }}>
                                        <span className='hubicon-clear_option' />
                                    </ListItemSecondaryAction>
                                }
                            </ListItem>
                            :
                            (props.value && props.optionLabelField === "members")?
                                <ListItem
                                    {...params.inputProps}
                                    key={props.id}
                                    className="input-field-autocomplete__list"
                                >
                                    <ListItemIcon className="input-field-autocomplete__list__icon" >
                                        <img className="input-field-autocomplete__list__icon__img" src={ props.value.photo ? props.value.photo : baloa_user_icon} alt=""/>
                                    </ListItemIcon>
                                    <ListItemText primary={ props.value.full_name } secondary={ '@'+(props.value.username ) } className="input-field-autocomplete__listitem" />
                                    {!props.disabled && 
                                        <ListItemSecondaryAction onClick={() => { handleClearMemberTeam() }}>
                                            <span className='hubicon-clear_option' />
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            :
                            (props.value && props.optionLabelField === "teams")?
                                <ListItem
                                    {...params.inputProps}
                                    key={props.id}
                                    className="input-field-autocomplete__list"
                                >
                                    <ListItemIcon className="input-field-autocomplete__list__icon" >
                                        <img className="input-field-autocomplete__list__icon__img" src={ props.value.photo ? props.value.photo : team_shield_icon} alt=""/>
                                    </ListItemIcon>
                                    <ListItemText  primary={ props.value.name }  secondary={ '@'+(props.value.team ) } className="input-field-autocomplete__listitem" />
                                    {!props.disabled && 
                                        <ListItemSecondaryAction onClick={() => { handleClearMemberTeam() }}>
                                            <span className='hubicon-clear_option' />
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            :
                            <AutocompleteTextField
                                {...params}
                                className={ props.className + " input-field-autocomplete"}
                                label={ props.label }
                                variant="outlined"
                                margin="normal"
                                placeholder={ props.placeholder }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            }
                        </div>
                    )}                    
                />
                )
            }
            </Field>
    )
}

export default InputFieldAutocompleteComponent;

/*eslint eqeqeq: "off"*/
// React
import React from "react";
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

// Material
import Popover from '@mui/material/Popover';
import Badge from '@mui/material/Badge';

// Components
import SearchInputComponent from 'app/search/components/search-input/SearchInputComponent';
import NotificationsPopoverComponent from 'app/notifications/components/notifications-popover/NotificationsPopoverComponent';
import SecondaryButtonComponent from "../secondary-button/SecondaryButtonComponent";

// Actions
import { changeHomeNewPostModal, changeHomeEditPostModal, changeNewHomePostAction, changeMobileMenuStateAction } from 'app/home/actions/HomeActionsCreators';
import { userLogout } from 'app/authentication/actions/LoginActions';
import { loadUserNotifications, loadUserNotificationsNext, setReadNotification } from 'app/notifications/actions/NotificationsActions';
import { switchProfileToPage } from 'app/profiles/actions/ProfileActions';
import { changeUserMessageSnackBar } from 'app/user-settings/actions/UserSettingsCreators';
import { changeCardIdExitValidationModalStateAction } from 'app/leagues/actions/LeagueTournamentActionsCreators';

// Actions
import { loadSearchBox } from 'app/search/actions/SearchActions';
import { changeSearchBoxStateAction, loadSearchErrorsAction, cleanSearchKeyword, changeSearchOnStateAction } from 'app/search/actions/SearchActionsCreators';

// Assets
import baloa_logo from 'assets/logos/baloa-logo.svg';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// Styles
import './HeaderAuthenticatedComponent.styles.css';

// i18next
import { withTranslation } from 'react-i18next';

// Redux
import {compose} from 'redux';

// React device detect
import { isMobile } from 'react-device-detect';

class HeaderAuthenticatedComponent extends React.Component {

    constructor( props ){
        super( props );
        this.state = {
                        searchfield: "",
                        keyword: ""
                    }
        //this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);

    }

    state = {
        user_photo_element: null,
        user_menu_is_open: false,
        whistle_element: null,
        notifications_is_open: false,
        show_sbox: this.props.state.search.show_sbox? this.props.state.search.show_sbox : false,
    }


    componentDidMount = () => {
        this.props.loadUserNotifications();
    }

    handleOnUserClick = ( event ) => {
        this.setState(
            {
                user_menu_is_open: true,
                user_photo_element: event.currentTarget,
            }
        );
    };

    handleUserMenuClose = () => {
        this.setState({ user_menu_is_open: false });
    };

    handleOnNotificationsClick = ( event ) => {
        this.setState(
            {
                notifications_is_open: true,
                whistle_element: event.currentTarget,
            }
        );
    };

    handleNotificationsClose = () => {
        this.setState({ notifications_is_open: false });
    };

    nextPath = (path) => {
        this.props.history.push(path);
        this.props.history.go();
    }

    handleChange = ( value ) => {        
        let valueWord = value.replace('#','%23').trim();

        if( value.length > 3){
            if( valueWord !== this.props.state.search.keyword ){ //TODO: validar si es necesario que se abra aca ya que se esta abriendo con el focus
                //this.props.changeSearchBoxStateAction(true, this.props.state.search.show_sbox );
                //this.props.changeSearchBoxStateAction(true);
            }
            this.props.loadSearchBox( valueWord, this.props.state.search.keyword );
        }else{
            //this.props.changeSearchBoxStateAction(false, this.props.state.search.show_sbox ); //No se cierra con menos de tres letras
            this.props.cleanSearchKeyword('', this.props.state.search.keyword);
        }
    }

    onSearch = ( search ) => {
        let valueWord = search.searchfield.replace('#','%23').trim();
        this.props.history.push(`/search/search=${valueWord}`);
        this.props.changeSearchOnStateAction(false);
    }

    handleBlur = () => {
        setTimeout(() => {
            this.props.cleanSearchKeyword('', this.props.state.search.keyword);
            //this.props.changeSearchBoxStateAction(false, this.props.state.search.show_sbox ); // count is 0 here
            this.props.changeSearchBoxStateAction(false ); // count is 0 here            
          }, 500);
    }

    handleOnProfileClick = ( username ) => {
        this.props.history.push(`/profile/${username}`);
        this.handleNotificationsClose();
    }

    returnPageToProfile = ( username ) => {
        this.props.switchProfileToPage( undefined );
        this.props.showMessage( this.props.t('snackBar.msgNav') + username );
        this.props.loadUserNotifications();
    }

    // handleOnProfilePageClick = ( page ) => {
    //     this.props.switchProfileToPage( page );
    //     this.props.showMessage( this.props.t('snackBar.msgNav') + page.pagename );
    //     this.props.loadUserNotifications();
    // }

    handleSetReadNotification = async (id_notification) => {
        await this.props.setReadNotification(id_notification);
        this.props.loadUserNotifications();
    }

    handleSearchLinkTo = ( section) => {
        switch (section){
            case 'players':
                this.props.history.push(`/players`);
                break;        
            case 'pages':
                this.props.history.push(`/pages`);
                break;  
            case 'tournaments':
                this.props.history.push(`/tournament/hub`);
                break;
            // no default
        }        
    }

    handleBaloaHome = ( e ) => {
        e.preventDefault();
        if(this.props.state.leagueTournaments.start_cards_id_component){
            this.props.changeCardIdExitValidationModalStateAction(true);
        }else{
            window.location.href = '/';
        }
    }

    handleOpenBaloaLanding = () => {
        const urlToOpen = 'https://info.baloa.com/';
        window.open(urlToOpen, '_blank');
    }

    render() {

        return(

            // Authenticated Header
            <React.Fragment>
                { !isMobile &&                    
                    <div className="header-auth">
                        {/* Baloa Logo */}
                        <div className="header-authenticated__left-col">
                            <Link to="/" onClick={this.handleBaloaHome} className="header-auth-link">
                                <img className="header-auth__image" src={baloa_logo} alt="" />
                            </Link>
                            <div className="header-auth__search">
                                <SearchInputComponent
                                    onChange={ (value) =>{ this.handleChange(value)} }
                                    onSearch = {(value) => this.onSearch(value)}
                                    onBlur={  this.handleBlur }
                                    errors = {this.props.state.search.errors.searchfield}
                                    onResetError = { ( new_errors ) => this.props.loadSearchErrorsAction( { ...this.props.state.search.errors.searchfield.searchfield, ...new_errors }, 'searchfield' ) }

                                    show_sbox= {this.props.state.search.show_sbox}
                                    results = {this.props.state.search.box_search_results.results? this.props.state.search.box_search_results.results : []}
                                    keyword = {this.props.state.search.keyword}
                                    onFocus = { ()=>{ this.props.changeSearchBoxStateAction( true ) } }
                                    handleSearchLinkTo = { (section)=>{ this.handleSearchLinkTo( section ) } }
                                    handleMobileSearch={()=>{}}
                                />
                            </div>
                        </div>
                        <SecondaryButtonComponent 
                            type="button" 
                            onClick={ () => this.handleOpenBaloaLanding() }
                            className="header-authenticated__baloa-landing"
                        >
                            {this.props.t('menuLabels.landingBaloa')}<span className="hubicon-open_in_new" /> 
                        </SecondaryButtonComponent>

                        {/* User menu */}
                        <div className="header-auth__actions header-auth-link">

                            {/* Home */}
                            <Link to={'/'} className={"header-auth__actions__home " + (this.props.location.pathname === '/' ? 'active': '')}>
                                <span className={"icon-home"} ></span>
                                <div className={"baloa-username " + (this.props.location.pathname === '/' ? 'active': '')}>{this.props.t('menuLabels.home')}</div>
                            </Link>

                            {/* Baloa play */}
                            <Link to={'/BaloaPlay'} className={"header-auth__actions__home " + (this.props.location.pathname.includes('/BaloaPlay')  ? 'active': '')}>
                                {this.props.location.pathname.includes('/BaloaPlay') ? <span className={"hubicon-live_tv-1"} /> : <span className={"hubicon-live_tv1"} /> }
                                <div className={"baloa-username " + (this.props.location.pathname.includes('/BaloaPlay') ? 'active': '')}>{this.props.t('menuLabels.baloaPlay')}</div>
                            </Link>

                            {/* Tournament Hub */}
                            <Link to={'/tournament/hub'} className={"header-auth__actions__home " + (this.props.location.pathname === '/tournament/hub' ? 'active': '')}>
                                <span className={"icon-trophy"} ></span>
                                <div className={"baloa-username " + (this.props.location.pathname === '/tournament/hub' ? 'active': '')}>{this.props.t('menuLabels.tournaments')}</div>
                            </Link>

                            {/* Notifications */}
                            <span
                            className="header-auth__actions__notifications"
                            onClick={ this.handleOnNotificationsClick }>
                                <Badge badgeContent={ this.props.state.notifications.unreaded_notifications_count } color="error">
                                    <span className={"icon-bell-59"} ></span>
                                </Badge>
                                <div className={"baloa-username"}>{this.props.t('menuLabels.notifications')}</div>
                            </span>

                            {/* New Post */}
                            <div onClick={ () => {  this.props.changeHomeEditPostModal( false, null ); this.props.changeHomeNewPostModal( true ); this.props.changeNewHomePostAction( true ); } } className="header-auth__actions__new-post">
                                <span className={"icon-plus"} ></span>
                            </div>

                            {/* User options */}
                            <span
                                className="header-auth__actions__me"
                                onClick={ this.handleOnUserClick }
                            >
                                {/* {this.props.state.profile.page_active?
                                <img src={ this.props.state.profile.page_active.photo ? this.props.state.profile.page_active.photo : baloa_user_icon } alt=""/> */}
                                {this.props.state.user.me.photo &&
                                    <img src={ this.props.state.user.me.photo} alt="userPhoto"/>
                                }
                                {!this.props.state.user.me.photo &&
                                    <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                                }


                                {/* } */}
                                
                            </span>

                            {/* User options popover */}
                            <Popover
                                open={this.state.user_menu_is_open}
                                anchorEl={this.state.user_photo_element}
                                onClose={this.handleUserMenuClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                className="header-auth__actions__popover_user"
                            >
                                <div
                                    className="header-auth__actions__me__popover"
                                >
                                    {/* se hace comentario la lista, ya no se quiere mostrar */}
                                    {/* {this.props.state.profile?.loggedin?.pages?.map( page =>
                                        <Link to={"/page/" + page.pagename}>
                                            <TextButtonComponent
                                                onClick={ ()=>{
                                                    this.handleUserMenuClose();
                                                    //this.nextPath(`/page/${page.pagename}/`);
                                                    this.handleOnProfilePageClick( page );
                                                } }
                                                className="header-auth__actions__me__popover__action"
                                            >
                                                <span>{'@' + page.pagename }</span>
                                            </TextButtonComponent>
                                        </Link>
                                    )
                                    } */}
                                    <Link 
                                        to={"/profile/" + this.props.state.user.me.username} 
                                        onClick={ ()=>{ 
                                            this.handleUserMenuClose();
                                            this.returnPageToProfile( this.props.state.user.me.username );
                                            //this.nextPath(`/profile/${ this.props.state.user.me.username }/`);
                                        } }
                                    >
                                        <div className="header-auth__actions__me__popover__item">
                                            {this.props.state.user.me.photo &&
                                                <img src={ this.props.state.user.me.photo} alt=""/>
                                            }
                                            {!this.props.state.user.me.photo &&
                                                <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                                            }
                                            <span className="baloa-names">{this.props.t('dropDownMenu.titleProfile')}</span>
                                        </div>
                                    </Link>
                                    <div className="header-auth__separator"></div>
                                    <Link
                                        to={"/user/" + this.props.state.user.me.username}
                                        onClick={ ()=>{
                                            this.handleUserMenuClose();
                                        } }
                                    >
                                        <div className="header-auth__actions__me__popover__item">
                                            <span className={"iconb2-settings"}></span>                                    
                                            <span className="baloa-names">{this.props.t('dropDownMenu.titleSettings')}</span>
                                        </div>
                                    </Link>
                                    <Link
                                        to={"/"}
                                        onClick={ () => {
                                            this.props.userLogout();
                                        } }
                                    >
                                        <div className="header-auth__actions__me__popover__item">
                                            <span className={"iconb2-exit"}></span>                                    
                                            <span className="baloa-names">{this.props.t('settings.closeSession')}</span>
                                        </div>
                                    </Link>
                                </div>
                            </Popover>

                            {/* Notifications popover */}
                            <Popover
                                open={this.state.notifications_is_open}
                                anchorEl={this.state.whistle_element}
                                onClose={this.handleNotificationsClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                className="header-auth__actions__popover_notificaciones"
                            >
                                <div
                                    className="header-auth__actions__notifications__popover"
                                >
                                    <div
                                    className="header-auth__actions__notifications__popover"
                                    >
                                        <NotificationsPopoverComponent
                                        notifications={ this.props.state.notifications.notifications }
                                        handleOnProfileClick={(username) => this.handleOnProfileClick(username)}
                                        setReadNotification = {(id_notification) => this.handleSetReadNotification(id_notification)}
                                        me={this.props.state.user.me.username}
                                        />
                                    </div>
                                </div>
                            </Popover>
                        </div>
                    </div>
                }
                { isMobile && 
                    <div>
                        {this.props.state.search.is_search_on 
                        ?
                        <div className="header-auth__mobile__search-box">
                            {/* <span className="icon-arrow-left" onClick={() => {this.setState({is_search_on: false})}}/> */}
                            <SearchInputComponent
                                onChange={ (value) => this.handleChange(value) }
                                onSearch = {(value) => this.onSearch(value)}
                                onBlur={  this.handleBlur }
                                errors = {this.props.state.search.errors.searchfield}
                                onResetError = { ( new_errors ) => this.props.loadSearchErrorsAction( { ...this.props.state.search.errors.searchfield.searchfield, ...new_errors }, 'searchfield' ) }
                                onFocus = { ()=>{ this.props.changeSearchBoxStateAction( true ) } }
                                show_sbox= {this.props.state.search.show_sbox}
                                results = {this.props.state.search.box_search_results.results? this.props.state.search.box_search_results.results : []}
                                keyword = {this.props.state.search.keyword}
                                handleSearchLinkTo = { (section)=>{ this.props.changeSearchOnStateAction(false); this.handleSearchLinkTo( section ) } }
                                handleMobileSearch={()=>{this.props.changeSearchOnStateAction(false)}}
                            />
                        </div>
                        :
                        <div className="header-auth__mobile">
                            <div className="header-auth__mobile-menu">
                                { this.props.location.pathname === '/' && <span className="hubicon-menu" onClick={() => {this.props.changeMobileMenuStateAction(true)}}/>}
                                <span className="icon-search" onClick={() => {this.props.changeSearchOnStateAction(true)}}/>
                            </div>

                            {/* User menu */}
                            <div className="header-auth__mobile-actions">

                                {/* Home */}
                                <Link to={'/'} className={"header-auth__actions__mobile-home " + (this.props.location.pathname === '/' ? 'active': '')}>
                                    <span className={"icon-home"} ></span>
                                </Link>

                                {/* Baloa Play */}
                                <Link to={'/BaloaPlay'} className={"header-auth__actions__mobile-home " + (this.props.location.pathname.includes('/BaloaPlay') ? 'active': '')}>
                                    {this.props.location.pathname.includes('/BaloaPlay') ? <span className={"hubicon-live_tv-1"} /> : <span className={"hubicon-live_tv1"} /> }
                                </Link>

                                {/* Tournament Hub */}
                                <Link to={'/tournament/hub'} className={"header-auth__actions__mobile-home " + (this.props.location.pathname === '/tournament/hub' ? 'active': '')}>
                                    <span className={"icon-trophy"} ></span>
                                </Link>

                                {/* Notifications */}
                                <span
                                className="header-auth__actions__mobile-notifications"
                                onClick={ this.handleOnNotificationsClick }>
                                    <Badge badgeContent={ this.props.state.notifications.unreaded_notifications_count } color="error">
                                        <span className={"icon-bell-59"} ></span>
                                    </Badge>
                                </span>

                                {/* New Post */}
                                <div onClick={ () => {  this.props.changeHomeEditPostModal( false, null ); this.props.changeHomeNewPostModal( true ); this.props.changeNewHomePostAction( true ); } } className="header-auth__actions__mobile-new-post">
                                    <span className={"icon-plus"} ></span>
                                </div>

                                {/* User options */}
                                <span
                                    className="header-auth__actions__mobile-me"
                                    onClick={ this.handleOnUserClick }
                                >
                                    {/* {this.props.state.profile.page_active?
                                    <img src={ this.props.state.profile.page_active.photo ? this.props.state.profile.page_active.photo : baloa_user_icon } alt=""/> */}
                                    {this.props.state.user.me.photo &&
                                        <img src={ this.props.state.user.me.photo} alt=""/>
                                    }
                                    {!this.props.state.user.me.photo &&
                                        <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                                    }


                                    {/* } */}
                                    
                                </span>

                                {/* User options popover */}
                                <Popover
                                    open={this.state.user_menu_is_open}
                                    anchorEl={this.state.user_photo_element}
                                    onClose={this.handleUserMenuClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    className="header-auth__actions__popover_user"
                                >
                                    <div
                                        className="header-auth__actions__me__popover"
                                    >
                                        {/* se hace comentario la lista, ya no se quiere mostrar */}
                                        {/* {this.props.state.profile?.loggedin?.pages?.map( page =>
                                            <Link to={"/page/" + page.pagename}>
                                                <TextButtonComponent
                                                    onClick={ ()=>{
                                                        this.handleUserMenuClose();
                                                        //this.nextPath(`/page/${page.pagename}/`);
                                                        this.handleOnProfilePageClick( page );
                                                    } }
                                                    className="header-auth__actions__me__popover__action"
                                                >
                                                    <span>{'@' + page.pagename }</span>
                                                </TextButtonComponent>
                                            </Link>
                                        )
                                        } */}
                                        <Link 
                                            to={"/profile/" + this.props.state.user.me.username} 
                                            onClick={ ()=>{ 
                                                this.handleUserMenuClose();
                                                this.returnPageToProfile( this.props.state.user.me.username );
                                                //this.nextPath(`/profile/${ this.props.state.user.me.username }/`);
                                            } }
                                        >
                                            <div className="header-auth__actions__me__popover__item">
                                                {this.props.state.user.me.photo &&
                                                    <img src={ this.props.state.user.me.photo} alt=""/>
                                                }
                                                {!this.props.state.user.me.photo &&
                                                    <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                                                }
                                                <span className="baloa-names">{this.props.t('dropDownMenu.titleProfile')}</span>
                                            </div>
                                        </Link>
                                        <div className="header-auth__separator"></div>
                                        <Link
                                            to={"/user/" + this.props.state.user.me.username}
                                            onClick={ ()=>{
                                                this.handleUserMenuClose();
                                            } }
                                        >
                                            <div className="header-auth__actions__me__popover__item">
                                                <span className={"iconb2-settings"}></span>                                    
                                                <span className="baloa-names">{this.props.t('dropDownMenu.titleSettings')}</span>
                                            </div>
                                        </Link>
                                        <Link
                                            to={"/"}
                                            onClick={ () => {
                                                this.props.userLogout();
                                            } }
                                        >
                                            <div className="header-auth__actions__me__popover__item">
                                                <span className={"iconb2-exit"}></span>                                    
                                                <span className="baloa-names">{this.props.t('settings.closeSession')}</span>
                                            </div>
                                        </Link>
                                    </div>
                                </Popover>

                                {/* Notifications popover */}
                                <Popover
                                    open={this.state.notifications_is_open}
                                    anchorEl={this.state.whistle_element}
                                    onClose={this.handleNotificationsClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    className="header-auth__actions__popover_notificaciones"
                                >
                                    <div
                                        className="header-auth__actions__notifications__popover"
                                    >
                                        <div className="header-auth__actions__notifications__popover">
                                            <NotificationsPopoverComponent
                                                notifications={ this.props.state.notifications.notifications }
                                                handleOnProfileClick={(username) => this.handleOnProfileClick(username)}
                                                setReadNotification = {(id_notification) => this.handleSetReadNotification(id_notification)}
                                                me={this.props.state.user.me.username}
                                            />
                                        </div>
                                    </div>
                                </Popover>
                            </div>
                        </div>}
                    </div>
                }
            </React.Fragment>                    
        );
    }
}

const mapStateToProps = state => {
    return {
        state
    }
}
const mapDispatchToProps = dispatch => {
    return {
        changeHomeNewPostModal: ( new_modal_state ) => {
            return dispatch( changeHomeNewPostModal( new_modal_state ) );
        },
        changeHomeEditPostModal: ( new_state, post ) => {
            return dispatch( changeHomeEditPostModal( new_state, post ) );
        },
        userLogout: () => {
            return dispatch(userLogout());
        },
        loadUserNotifications: () => {
            return dispatch( loadUserNotifications() )
        },
        loadSearchBox: ( keyword, oldkeyword ) => {
            if(keyword !== oldkeyword){
                return dispatch( loadSearchBox( keyword ) )
            }
        },
        /* changeSearchBoxStateAction: (new_sate, sbox_state) => {
            if(new_sate != sbox_state){
                return dispatch( changeSearchBoxStateAction(new_sate) )
            }
        }, */
        changeSearchBoxStateAction: (new_sate) => {            
            return dispatch( changeSearchBoxStateAction(new_sate) )            
        },
        cleanSearchKeyword: (new_value, keyword) => {
            if(new_value !== keyword){
                return dispatch( cleanSearchKeyword(new_value) )
            }
        },
        loadSearchErrorsAction: (errors, section) => {
            return dispatch( loadSearchErrorsAction(errors, section) )
        },
        switchProfileToPage: ( page ) => {
            return dispatch( switchProfileToPage( page ) )
        },
        showMessage: ( message ) => {
            dispatch( changeUserMessageSnackBar( {
                message: message,
                user_message_is_open: true
            } ) )
        },
        loadUserNotificationsNext:(next_page) => {
            dispatch( loadUserNotificationsNext(next_page))
        },
        setReadNotification:(id_notification) => {
            dispatch( setReadNotification(id_notification))
        },
        changeNewHomePostAction: ( new_state ) => {
            dispatch( changeNewHomePostAction( new_state ) );
        },
        changeMobileMenuStateAction: ( new_state ) => {
            dispatch( changeMobileMenuStateAction( new_state ) );
        },
        changeSearchOnStateAction: ( new_state ) => {
            dispatch( changeSearchOnStateAction( new_state ) );
        },
        changeCardIdExitValidationModalStateAction:( new_state ) => {
            dispatch( changeCardIdExitValidationModalStateAction( new_state ))
        },
    }
}

//export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderAuthenticatedComponent));

export default compose(
    withRouter,
    withTranslation("profile"),
    connect(mapStateToProps, mapDispatchToProps)
)(HeaderAuthenticatedComponent);


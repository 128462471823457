// React
import React from 'react';

import CurrencyFormat from 'react-currency-format';


// Styles
import './InscriptionsFeePlayerComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

//Components
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import FormComponent from 'shared/components/form/FormComponent';
import WompiPaymentFormComponent from '../wompi-payment-form/WompiPaymentFormComponent';

function InscriptionsFeePlayerComponent ( props ) {
    
    const [totalPay, setTotalPay] = React.useState((props.user_pays_processing_rate && props.user_pays_processing_rate_price_player)? props.user_pays_processing_rate_price_player : props.registration_price_per_player);
    const [ subTotalValue, setSubTotalValue ] = React.useState( props.registration_price_per_player );
    const [ serviceFeeValue, setServiceFeeValue ] = React.useState(0);

    /* React.useEffect(() => {
        if(props.payment_player_code === "player_code"){
            setTotalPay(0);
        }
	}, [props.payment_player_code]); // eslint-disable-line */

    React.useEffect(() => {
        if(props.user_pays_processing_rate){
            let feePlayer = 0;
            if( props.user_pays_processing_rate_price_player){
                feePlayer = Number(props.user_pays_processing_rate_price_player) -  Number(props.registration_price_per_player);
            }
            setServiceFeeValue(feePlayer);
        } 
    }, [props.user_pays_processing_rate, props.user_pays_processing_rate_price_player]); // eslint-disable-line


    const initialValues ={
                cupon: ''
            }
        
    const [ coupon, setCoupon] = React.useState(null);

    const checkValue=(v)=>{
        if(props.discount_coupon && v.length <= 0){
            props.resetPlayerCoupon();
            setCoupon(null);
        }
    }

    const deleteValidCoupon = () => {
        props.resetPlayerCoupon();
        setCoupon(null);
    }

    return(
        <div className='inscription-fee-player__container' >
            <div className='inscription-fee-player__team-player' >                
                <div className='inscription-fee-player__player' >
                    <div className='left-col' >
                        <span className='icon-player' />
                        <div className='baloa-normal' >{props.t('inscriptionModal.inscriptionPerPlayer')}</div>
                        
                    </div>
                    <div className='right-col' >
                        <div className='baloa-subtitle-2'><CurrencyFormat value={props.registration_price_per_player} displayType={'text'} thousandSeparator={','} decimalSeparator={"."} prefix={'$ '} suffix={' ' + (props.currency ?? '')}/></div>                        
                    </div>
                </div>
            </div>
            {/*!props.payment_player_code &&
                <div className='inscription-fee-player__cupon-code' >

                    <FormComponent
                        formInitialValues={{inscode:''}} 
                        onSubmit={( values, actions ) => { props.submitCode(values) }}
                        className="inscription-fee-player__cupon-code__form"
                        functionDisabled={()=>{}}
                    >
                        <InputFieldComponent
                            label={props.t("inscriptionModal.paymentCodeLbl")}
                            input_id="inscode"
                            field_name="inscode"
                            //validationFunction={RequiredValidator}
                            disabled={false}
                            className=""
                            input_type="text"
                            resetError={() => {}}
                            error={{inscode:''}}
                        />
                        <SecondaryButtonComponent
                            type={"submit"}
                            onClick={()=>{}}
                            className={"xs"}
                        >
                            <span>{props.t('inscriptionModal.paymentCodeBtnLbl')}</span>
                        </SecondaryButtonComponent>
                    </FormComponent>
             
            </div> */
            }

            <div className='inscription-fee-player__coupon'>
                <div className='baloa-table-column'>{props.t('inscriptionModal.couponCodeLbl2')}</div>
                {(!props.payment_player_code || !props.payment_player_code?.coupon_is_valid) &&
                    <FormComponent
                        formInitialValues = {initialValues }
                        onSubmit = { ( values, actions ) => { 
                                props.handleOnCodeButton( values.cupon );
                                setCoupon(values.cupon);
                            }
                        }
                        className = "inscription-fee-player__form"
                    >
                        <div className='inscription-fee-player__form-coupon'>
                            <InputFieldComponent
                                //label={props.t('inscriptionModal.couponCodeLbl2')}
                                input_id=""
                                field_name="cupon"
                                validationFunction={()=>{}}
                                disabled={false}
                                input_type="text"
                                resetError={() => { }}
                                error={{'cupon': ''}}
                                message=""
                                onChange={(v) => { checkValue(v) }}
                                className={`${(props.payment_player_code && !props.payment_player_code?.coupon_is_valid)? 'error' : ''}`}          
                            >
                            </InputFieldComponent>
                            <SecondaryButtonComponent
                                onClick={()=>{}}
                                disabled={ false }
                            >
                                <span>{props.t('inscriptionModal.applyBtnLbl')}</span>
                            </SecondaryButtonComponent>
                        </div>
                        
                        {props.payment_player_code && !props.payment_player_code?.coupon_is_valid &&
                            <div className='baloa-mini error'>{props.payment_player_code?.coupon_response}</div>
                        }
                    </FormComponent>
                }
                {props.payment_player_code?.coupon_is_valid &&
                    <div className='inscription-fee-player__coupon-valid'>
                        <span className='hubicon-check_circle'/>
                        <div className=''>
                            <div className='baloa-table-column'><strong>{coupon}</strong>&nbsp;{props.t('inscriptionModal.appliedLbl')}</div>
                            <CurrencyFormat
                                value={props.payment_player_code?.discount_price} 
                                displayType={'text'} 
                                thousandSeparator={'.'} 
                                decimalSeparator={","} 
                                prefix={'-$'} 
                                suffix={' ' + props.payment_player_code?.currency}
                                decimalScale={2}
                                //onValueChange ={}
                                renderText={value => <div className='baloa-username'>{`${value} (${props.payment_player_code?.percent_off}% ${props.t('inscriptionModal.discountLbl')})`}</div>}
                            />
                        </div>
                        <div className='baloa-table-column delete-button' onClick={()=>{ deleteValidCoupon() }}>{props.t('inscriptionModal.removeBtnLbl')}</div>
                    </div>
                }
            </div>

            <div className='baloa-play-match-payment-confirmation-modal__coupon-discount'>
                <CurrencyFormat
                    value={subTotalValue} 
                    displayType={'text'} 
                    thousandSeparator={'.'} 
                    decimalSeparator={","} 
                    prefix={'$'} 
                    suffix={' ' + props.currency}
                    decimalScale={2}
                    //onValueChange ={}
                    renderText={value => <div className='baloa-table'>{`${props.t('inscriptionModal.subTotalLbl')}: ${value}`}</div>}
                />
            </div>
            
            {props.payment_player_code?.coupon_is_valid &&
                <div className='baloa-play-match-payment-confirmation-modal__coupon-discount'>
                    <CurrencyFormat
                        value={props.payment_player_code?.discount_price} 
                        displayType={'text'} 
                        thousandSeparator={'.'} 
                        decimalSeparator={","} 
                        prefix={'-$'} 
                        suffix={' ' + props.payment_player_code?.currency}
                        decimalScale={2}
                        //onValueChange ={}
                        renderText={value => <div className='baloa-table'>{`${props.t('inscriptionModal.couponCodeLbl')}: ${value}`}</div>}
                    />
                </div>
            }

            {props.user_pays_processing_rate &&
                <div className='baloa-play-match-payment-confirmation-modal__coupon-discount'>
                    <CurrencyFormat
                        value={serviceFeeValue}
                        displayType={'text'} 
                        thousandSeparator={'.'} 
                        decimalSeparator={","} 
                        prefix={'$'} 
                        suffix={' ' + props.currency}
                        decimalScale={2}
                        //onValueChange ={}
                        renderText={value => <div className='baloa-table'>{`${props.t('inscriptionModal.serviceFeeLbl')}: ${value}`}</div>}
                    />
                </div>
            }
            <div className='inscription-fee-player__overall-fee' >
                <div className='baloa-normal'>{props.t('inscriptionModal.totalLabel')}&nbsp;</div>
                <div className='baloa-subtitle-2'><CurrencyFormat value={ props.payment_player_code?.coupon_is_valid? props.payment_player_code?.amount_total : totalPay} displayType={'text'} thousandSeparator={','} decimalSeparator={"."} prefix={'$ '} suffix={' ' + (props.currency ?? '')} fixedDecimalScale={true} decimalScale={2}/></div>
            </div>
            {props.currency === 'COP' &&
                <WompiPaymentFormComponent
                    currency={props.currency}
                    totalValue={totalPay}
                    profile_id={props.profile_id}
                    paymentCustomer={props.tournament_player_id}
                    countryCodePrefix="+57"
                    countryIataCode="CO"
                    city={'Bogota'}
                    region={'Bogota'}
                    playersQuantity={'0'}
                    submitWompiForm={props.submitWompiForm}
                    resetPaymentButton={props.resetPaymentButton}
                    type_redirect={props.type_redirect}
                    profile_phone={props.profile_phone}
                    metadata={`{"tournament_team_id":null,"tournament_player_id":"${props.tournament_player_id}","payment_type":"PLAYER_REGISTRATION","tournament_id":"${props.tournament_id}","total_codes":"0"}`}
                />
            }
        </div>
    )
}

export default withTranslation('tournament')(InscriptionsFeePlayerComponent);
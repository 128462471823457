import React from 'react';

//Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import TournamentPhasesFairPlayDropDownComponent from '../tournament-phases-fair-play-drop-down/TournamentPhasesFairPlayDropDownComponent';
import InfiniteScroll from 'react-infinite-scroll-component';

//Material
import { Skeleton } from '@mui/material';

//Styles
import './TournamentPhasesViewMoreSanctionsModalComponent.styles.css';

// Moment
import * as moment from 'moment';

// Locale
import 'moment/locale/en-au';
import 'moment/locale/es';

//i18
import { withTranslation } from 'react-i18next';

// React device detect
import {isMobile} from 'react-device-detect';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

function TournamentPhasesViewMoreSanctionsModalComponent(props) {

    const [loadMore, setLoadMore] = React.useState(false);
    const [showMenu, setShowMenu ] = React.useState([]);
    const [ignored, forceUpdate] = React.useReducer(x => x + 1, 0); //eslint-disable-line
        
    React.useEffect(() => {
        if(props.next_phase_sanctions){
            setLoadMore(true);
        } else {
            setLoadMore(false);
        }
    }, [props.next_phase_sanctions]); // eslint-disable-line

    const handleShowMenu = (i) => {
        let changeMenu = showMenu;
        changeMenu[i] = !showMenu[i];
        setShowMenu(changeMenu);
        forceUpdate();
    }

    return (
        <Modal2Component
            isOpen={props.isOpen}
            onClose={props.onClose}
            title={props.t('options.tournaments.pages.newTournament.tabs.tabPunctuationAndFairPlay.penaltiesList.title')}
            className='phases-view-more-sanctions-modal'
        >
            <TournamentPhasesFairPlayDropDownComponent
                selected_tournament = { props.selected_tournament }
                tournament_phases={ props.tournament_phases }
                tournament_active_phase={ props.tournament_active_phase }
                handleOnClickPhaseFairlPlay={ props.handleOnClickPhaseFairlPlay }
            />

            <div className='tournament-fair-play-penalties-list__list-types-menu'>
                <div className={`tournament-fair-play-penalties-list__list-type baloa-table ${ props.filter_penalty_type === 'Sport'? 'active' : '' }`} onClick={()=>{ props.handleOnFilterPenaltys('Sport')}}>{props.t('options.tournaments.pages.newTournament.tabs.tabPunctuationAndFairPlay.penaltiesList.filters.Sport')}</div>
                <div className={`tournament-fair-play-penalties-list__list-type baloa-table ${ props.filter_penalty_type === 'Extra_Sport'? 'active' : '' }`} onClick={()=>{ props.handleOnFilterPenaltys('Extra_Sport')}}>{props.t('options.tournaments.pages.newTournament.tabs.tabPunctuationAndFairPlay.penaltiesList.filters.Extra_Sport')}</div>
            </div>
            
            <InfiniteScroll
                dataLength={ props.penlatyList?.length }
                next={ ()=>{ props.loadMoreSanctions( props.next_phase_sanctions ) }}
                hasMore={loadMore}
                loader={
                    <div className='tournament-fair-play-penalties-list__list-item'>
                        <div className='tournament-fair-play-penalties-list__list-item__skeleton-header'>
                            <Skeleton  component={'div'} animation="wave" variant="circular" width='35px' height='35px' sx={{  }} />
                            <Skeleton  component={'div'} animation="wave" variant="rounded" width='170px' height='12px' sx={{  }} />
                        </div>
                        <Skeleton  component={'div'} animation="wave" variant="rounded" width='417px' height='12px' sx={{  }} />
                    </div>
                    }
                height={isMobile? 'calc(100vh - 190px)' :'calc(100vh - 100px)'}
                endMessage={''}
                className=''
            >     
                {props.penlatyList?.map( (penality, index) =>
                    <div className='tournament-fair-play-penalties-list__list-item'>
                        <div className='tournament-fair-play-penalties-list__list-item__header' >
                            {penality.sanction_type === 'Team_Sanction' &&
                                <div className='tournament-fair-play-penalties-list__list-item__header__name'>
                                    {penality.team_logo?
                                        <img src={penality.team_logo} alt={penality.team_name}/>
                                        :
                                        <span className='icon-ball' color={getRandonIconBallColor()}/>
                                    }                                                            
                                    <div className='baloa-table'>{ penality.team_name }</div>
                                    
                                </div>
                            }
                            {(penality.sanction_type === 'Member_Sanction' || penality.sanction_type === 'Player_Sanction') && 
                                <div className='tournament-fair-play-penalties-list__list-item__header__name'>
                                    {penality.member_photo?
                                        <img src={penality.member_photo} alt={penality.member_name}/>
                                        :
                                        <span className='icon-ball' color={getRandonIconBallColor()}/>
                                    }                                                            
                                    <div className='baloa-table'>{penality.member_name}</div>
                                </div>
                            }
                            {penality.sanction_name === "Extra_Sport" && !props.hide_buttons &&
                                <div className='tournament-fair-play-penalties-list__list-item__menu'>
                                    <span className={`icon-options ${props.tournament_active_phase?.is_last_active_phase? '' : 'disabled'}`} onClick={()=>{ handleShowMenu(index) }}/>                                                                       
                                    <div className={`tournament-fair-play-penalties-list__header-menu-options ${showMenu[index]? 'show_menu': ''}`}>
                                        <div className='tournament-fair-play-penalties-list__header-menu-options__option baloa-names' onClick={()=>{props.handleOnEditPenalty( penality ); handleShowMenu(index)}}>
                                            <span className='hubicon-edit1 icon'/>
                                            {props.t('options.tournaments.pages.newTournament.tabs.tabPunctuationAndFairPlay.penaltiesList.editPenaltyLbl')}
                                        </div>
                                        <div className='tournament-fair-play-penalties-list__header-menu-options__option baloa-names' onClick={()=>{props.handleOnDeletePenalty( penality ); handleShowMenu(index)}}>
                                            <span className='hubicon-deletev2 icon'/>
                                            {props.t('options.tournaments.pages.newTournament.tabs.tabPunctuationAndFairPlay.penaltiesList.deletePenaltyLbl')}
                                        </div>
                                    </div>                                    
                                </div>
                            }
                        </div>
                        <div className={`tournament-fair-play-penalties-list__list-item__sanction-type baloa-username ${penality.sanction_name}`}>{props.t(`options.tournaments.pages.newTournament.tabs.tabPunctuationAndFairPlay.penaltiesList.${penality.sanction_name}`)}</div>
                        <div className="tournament-fair-play-penalties-list__list-item__sanction baloa-table" dangerouslySetInnerHTML={{
                            __html:
                                (penality.sanction_name === "Extra_Sport" && penality.sanction_type === 'Team_Sanction' && penality.associated_tournament_match_id)?
                                    props.t('options.tournaments.pages.newTournament.tabs.tabPunctuationAndFairPlay.penaltiesList.extraSportSanctionDescriptionTeam',{_date: moment(penality.sanction_date).format('LL'), _sanction_points: penality.sanction_points, _description: penality.description, _match: penality.associated_tournament_match})
                                    :(penality.sanction_name === "Extra_Sport" && penality.sanction_type === 'Team_Sanction' && !penality.associated_tournament_match_id)?
                                        props.t('options.tournaments.pages.newTournament.tabs.tabPunctuationAndFairPlay.penaltiesList.extraSportSanctionDescriptionTeam2',{_date: moment(penality.sanction_date).format('LL'), _sanction_points: penality.sanction_points, _description: penality.description})
                                        :(penality.sanction_name === "Extra_Sport" && penality.sanction_type === 'Member_Sanction' && penality.associated_tournament_match_id)?
                                            props.t('options.tournaments.pages.newTournament.tabs.tabPunctuationAndFairPlay.penaltiesList.extraSportSanctionDescriptionMember',{_rol: props.t(`options.tournaments.pages.newTournament.tabs.tabPunctuationAndFairPlay.penaltiesList.roles.player`), _team: penality.team_name, _date: moment(penality.sanction_date).format('LL'), _sanction_matches: penality.sanction_matches, _description: penality.description, _match: penality.associated_tournament_match})
                                            :(penality.sanction_name === "Extra_Sport" && penality.sanction_type === 'Member_Sanction' && !penality.associated_tournament_match_id)?
                                                props.t('options.tournaments.pages.newTournament.tabs.tabPunctuationAndFairPlay.penaltiesList.extraSportSanctionDescriptionMember2',{_rol: props.t(`options.tournaments.pages.newTournament.tabs.tabPunctuationAndFairPlay.penaltiesList.roles.player`), _team: penality.team_name, _date: moment(penality.sanction_date).format('LL'), _sanction_matches: penality.sanction_matches, _description: penality.description})
                                                :
                                                props.t('options.tournaments.pages.newTournament.tabs.tabPunctuationAndFairPlay.penaltiesList.sportSanctionDescription',{_rol: props.t(`options.tournaments.pages.newTournament.tabs.tabPunctuationAndFairPlay.penaltiesList.roles.player`), _team: penality.team_name, _date: moment(penality.sanction_date).format('LL'), _sanction_matches: penality.sanction_matches, _sanction_cards: penality.sanction_cards, _sanction_card_type: props.t(`options.tournaments.pages.newTournament.tabs.tabPunctuationAndFairPlay.penaltiesList.cards.${penality.sanction_card_type}`), _match: penality.associated_tournament_match})
                        }}></div>
                        
                    </div>                    
                )}
             </InfiniteScroll>   
            

        </Modal2Component>
    )
}
export default withTranslation('league')(TournamentPhasesViewMoreSanctionsModalComponent)


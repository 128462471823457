// React
import React from 'react'

// Components
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import ProfileMatchesComponent from 'app/profiles/components/profile-matches/ProfileMatchesComponent';

// Styles
import './ProfileTournamentsMatchesComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

function ProfileTournamentsMatchesComponent( props ) {

    return (
        <div className="profile-tournaments-matches__container">
            <div className="profile-tournaments-matches__title">
                <div className="baloa-subtitle-2">{props.t('tournamentSummary.labelMatchesSummary')}</div>
                {props.profile_matches?.length > 0?
                    <div className='profile-tournaments-matches__view-all baloa-normal-medium' onClick={() => {props.handleOnViewAllMatches(true)}}>{props.t('tournamentSummary.labelViewAll')}</div>
                    : ''
                }
            </div>
            <div className="profile-tournaments-matches__sub-container">
                    <ProfileMatchesComponent 
                        profile_matches={props.profile_matches}
                        has_unbloqued_tournaments={ props.has_unbloqued_tournaments }
                        has_pending_tournaments={ props.has_pending_tournaments}
                        handleProfilePostModal={ props.handleProfilePostModal }
                        handleOnVideoChange={ props.handleOnVideoChange }
                        setClipMatch={props.setClipMatch}
                        is_owner={props.is_owner}
                        handleOnMatch={ props.handleOnMatch }
                        username={ props.username }
                        handleModalUnlockData={ props.handleModalUnlockData }
                    />
                {props.profile_matches?.length > 0? 
                    props.has_pending_tournaments &&
                        <div className='profile-tournaments-matches__unlock-values__action'>
                            <div className='profile-tournaments-matches__unlock-values__text'>
                                
                                {props.is_owner && 
                                    <div className='baloa-normal-medium'>
                                        <span className='hubicon-award_star'/>
                                        {/* !props.has_unbloqued_tournaments 
                                            ? props.t('tournamentSummary.tournamentsBlocked') 
                                            : props.t('tournamentSummary.modalMatchClip.matchesBlocked') */ 
                                        }
                                        {props.t('tournamentSummary.subscribeLbl')} 
                                    </div> 
                                }
                                {!props.is_owner && 
                                    <div className='baloa-normal-medium'>
                                        <span className='hubicon-info' />
                                        {!props.has_unbloqued_tournaments 
                                            ? props.t('tournamentSummary.visitantBlockedText', {user_name: props.username}) 
                                            : !props.user_me
                                                ? props.t('tournamentSummary.unAuthAlternativeblockedText') 
                                                : ''
                                        }
                                    </div> 
                                }
                            </div>
                            { props.is_owner && <PrimaryButtonComponent onClick={() => {props.handleModalUnlockData()}} >{props.t('tournamentSummary.subscribeBtnLbl')}</PrimaryButtonComponent> }
                        </div>
                    :
                    <div className='profile-matches__tournaments-blocked-message baloa-normal-medium'>
                        <span className='hubicon-info'></span>
                        {props.is_owner 
                            ? props.t('tournamentSummary.blockedEmptyDataGamesPlayed')
                            : props.t('tournamentSummary.visitantEmptyDataTournament', {user_name: props.username})
                        }
                    </div>
                }
                
            </div>
        </div>
    )
}

export default withTranslation('profile')(ProfileTournamentsMatchesComponent);


/*eslint eqeqeq: "off"*/
// Teams state
const tournament_state = {
    tournaments:[],
    loading_tournaments: false,
    selected_tournament: undefined,
    selected_tournament_team: undefined,
    selected_share_tournament: undefined,
    selected_tournament_my_teams: [],
    tournament_teams: [],
    tournament_teams_next: null,
    show_hub: false,
    show_inscriptions: false,
    show_my_tournaments: false,
    show_invitations: false,
    show_tournament_detail: false,
    player_tournaments: [],
    teams_tournaments: [],
    all_admin_tournaments: [],
    profile_admin_teams: [],
    team_players: [],
    total_team_players: 0,
    enrolled_players: 0,
    next_team_players: null,
    prev_team_players: null,
    payment_url_redirect: undefined,
    payment_tournament_team_id: undefined,
    payment_player_code: undefined,
    hub_tournament_next_page: null,
    inscription_form_template: undefined,
    player_form_template: undefined,
    modals: {
        inscription_modal_is_open: false,
        forms_modal_is_open: false,
        payments_modal_is_open: false,
        responsive_hub_modal_is_open: false,
        reject_player_insc_modal: false,
        flexi_incription_modal_is_open: false,
        share_incription_link_modal_is_open: false,
        verify_form_payment_is_open: false,
        share_iframe_link_modal_is_open: false,
        staff_invitation_is_open: false,
        staff_accept_invitation_is_open: false,
        inscription_deadline_is_open: false,
        unlock_stats_is_open: false,
        members_without_account_is_open: false,
        card_id_pdf_generator_modal: false,
        add_member_to_league_is_open: false,
        answer_multiple_forms_is_open: false,
        view_more_teams_is_open: false,
    },
    hub_tournaments_sections: [],
    is_share_link: false,
    hub_tournament_subsection: [],
    following_hub_tournaments_subsection: [],
    hub_tournament_matches: undefined,
    hub_tournament_next_matches: [],
    hub_tournament_matches_dates: [],
    team_manager_and_staff: [],
    next_team_staff: null,
    prev_team_staff: null,
    days_calendar_view: [],
    loading_team_players: false,
    loading_team_staff: false,
    loading_forms: false,
    calendar_matchdays: [],
    calendar_group_matches: [],
    calendar_group_matches_next: null,
    calendar_group_matches_count: 0,
    loading_phase_calendar: false,
    loading_phase_date_groups: false,
    phase_date_groups: [],
    phase_date_group_matches: [],
    phase_date_group_matches_next: null,
    loading_tournament_teams: false,
    selected_podium_tournament_team: undefined,
    payment_object: undefined,
}

export default function TournamentReducer( state = tournament_state, action ) {

    switch ( action.type ) {

        case "TOURNAMENT:CHANGE_LOADING_HUB_TOURNAMENTS": {
            return { ...state, loading_tournaments: action.data }

        }

        case "TOURNAMENT:LOAD_HUB_TOURNAMENTS": {

            let new_hub_tournaments = null;
            if(state.hub_tournament_next_page !== action.data.next || state.tournaments.length === 0){
                new_hub_tournaments = state.tournaments.concat( action.data.results );
            }else{
                new_hub_tournaments = state.tournaments;
            }           

            return { ...state, tournaments: new_hub_tournaments, hub_tournament_next_page: action.data.next}

        }

        case "TOURNAMENT:LOAD_SELECTED_TOURNAMENT": {

            return { ...state, selected_tournament: action.data.data }

        }

        case "TOURNAMENT:LOAD_HUB_TOURNAMENT_TEAMS": {
            let new_tournament_teams = null;
            if((action.data.data.previous !== null && state.tournament_teams_next !== action.data.data.next) || action.data.data.previous){
                new_tournament_teams = state.tournament_teams.concat( action.data.data.results );
            }else if(!action.data.data.previous){
                new_tournament_teams = action.data.data.results;
            }else{
                new_tournament_teams = state.tournament_teams;
            } 
            return { ...state, tournament_teams: new_tournament_teams, tournament_teams_next: action.data.data.next}
        }

        case "TOURNAMENT:SET_TOURNAMENTS_HUB": {
            return { ...state, show_hub: action.new_state, show_inscriptions: false, show_my_tournaments: false, show_invitations: false, show_tournament_detail: false }
        }

        case "TOURNAMENT:SET_TOURNAMENTS_INSCRIPTIONS": {
            return { ...state, show_inscriptions: action.new_state, show_hub: false, show_my_tournaments: false, show_invitations: false, show_tournament_detail: false }
        }

        case "TOURNAMENT:SET_TOURNAMENTS_MY_TOURNAMENTS": {
            return { ...state, show_my_tournaments: action.new_state, show_hub: false, show_inscriptions: false, show_invitations: false, show_tournament_detail: false }
        }

        case "TOURNAMENT:SET_TOURNAMENTS_INVITATIONS": {
            return { ...state, show_invitations: action.new_state, show_hub: false, show_inscriptions: false, show_my_tournaments: false, show_tournament_detail: false }
        }

        case "TOURNAMENT:SHOW_TOURNAMENT_DETAIL": {
            if(action.new_state){
                return { ...state, show_tournament_detail: action.new_state, show_hub: false, show_inscriptions: false, show_my_tournaments: false, show_invitations: false }
            }else{
                return { ...state, show_tournament_detail: action.new_state }
            }
            
        }

        case "TOURNAMENT:LOAD_HUB_TEAMS_TOURNAMENTS": {

            return { ...state, teams_tournaments: action.data.data.results }

        }

        case "TOURNAMENT:LOAD_HUB_PLAYER_TOURNAMENTS": {

            return { ...state, player_tournaments: action.data.data.results }

        }
        case "TOURNAMENT:CHANGE_INSCRIPTIONS_MODAL_STATE": {

            let new_modals_state = { ...state['modals'], inscription_modal_is_open: action.new_state }
            return { ...state, modals: new_modals_state }

        }

        case "TOURNAMENT:LOAD_HUB_ADMIN_TOURNAMENTS":{
            
            return { ...state, all_admin_tournaments: action.data.data.results }

        }

        case "TOURNAMENT:LOAD_PROFILE_ADMIN_TEAMS": {

            return { ...state, profile_admin_teams: action.data.data.results }
            
        }

        case "TOURNAMENT:CLEAN_PROFILE_ADMIN_TEAMS": {

            return { ...state, profile_admin_teams: [], payment_tournament_team_id: undefined }
            
        }

        case "TOURNAMENT:LOAD_TEAM_PLAYERS": {

            let new_team_players = null;
            if((action.data.data.previous !== null && state.next_team_players !== action.data.data.next) || action.data.data.previous){
                new_team_players = state.team_players.concat( action.data.data.results );
            }else if(!action.data.data.previous){
                new_team_players = action.data.data.results;
            }else{
                new_team_players = state.team_players;
            } 

            return { ...state, team_players: new_team_players, total_team_players: action.data.data.total_count, enrolled_players: action.data.data.enrolled_count, next_team_players: action.data.data.next, prev_team_players: action.data.data.previous}
        }

        case "TOURNAMENT:CLEAN_TEAM_PLAYERS": {
  
            return { ...state, team_players: [], total_team_players: 0, enrolled_players: 0, next_team_players: null, prev_team_players: null}

        }
        
        case "TOURNAMENT:LOAD_PAYMENT_URL": {

            return { ...state, payment_url_redirect: action.data.data.url }
            
        }

        case "TOURNAMENT:LOAD_PAYMENT_OBJECT": {

            return { ...state, payment_object: action.data }
            
        }

        case "TOURNAMENT:RESET_PAYMENT_OBJECT": {

            return { ...state, payment_object: undefined }
            
        }

        case "TOURNAMENT:LOAD_PAYMENT_TOURNAMENT_TEAM_ID": {

            return { ...state, payment_tournament_team_id: action.data.data.tournament_team_id }
            
        }

        case "TOURNAMENT:LOAD_PLAYER_CODE_PAYMENT": {
  
            return { ...state, payment_player_code: action.data}

        }

        case "TOURNAMENT:CLEAN_PLAYER_TEAM_TOURNAMETS": {
  
            return { ...state, player_tournaments: [], teams_tournaments: [], }

        }

        case "TOURNAMENT:REMOVE_TEAM_FROM_TOURNAMENT": {
            let new_teams_tournament = state.teams_tournaments.filter(team => team.id !== action.data.data.tournament_team_id);
            return { ...state, teams_tournaments: new_teams_tournament }
        }

        case "TOURNAMENT:LOAD_FILTERED_HUB_TOURNAMENTS": {

            return { ...state, tournaments: action.data.results, hub_tournament_next_page: action.data.next}

        }

        case "TOURNAMENT:REMOVE_PLAYER_FROM_TOURNAMENT": {
            let new_player_tournaments = state.player_tournaments.filter(player => player.id !== action.data.data.tournament_player_id);
            return { ...state, player_tournaments: new_player_tournaments }
        }

        case "TOURNAMENT:CLEAN_PLAYER_CODE_PAYMENT": {
            
            return { ...state, payment_player_code: undefined }

        }
        
        case "TOURNAMENT:LOAD_TOURNAMENT_INSCRPTION_FORM_TEMPLATE": {

            return { ...state, inscription_form_template: action.data.data }

        }

        case "TOURNAMENT:CHANGE_FORMS_MODAL_STATE": {

            let new_modals_state = { ...state['modals'], forms_modal_is_open: action.new_state }
            return { ...state, modals: new_modals_state }

        }

        case "TOURNAMENT:LOAD_PLAYER_TOURNAMENT_FORM_TEMPLATE": {

            return { ...state, player_form_template: action.data.data }

        }

        case "TOURNAMENT:CHANGE_PAYMENTS_MODAL_STATE": {

            let new_modals_state = { ...state['modals'], payments_modal_is_open: action.new_state }
            return { ...state, modals: new_modals_state }

        }

        case "TOURNAMENT:CHANGE_RESPONSIVE_HUB_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], responsive_hub_modal_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        /* case "TOURNAMENT:CHANGE_REJECT_PLAYER_INSCRIPTION_MODAL_STATE": {

            let new_modals_state = { ...state['modals'], reject_player_insc_modal: action.new_state }
            return { ...state, modals: new_modals_state }

        } */

        case "TOURNAMENT:CHANGE_FLEXI_INSCRIPTION_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], flexi_incription_modal_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "TOURNAMENT:CHANGE_SHARE_INSCRIPTION_LINK_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], share_incription_link_modal_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "TOURNAMENT:CHANGE_IS_SHARE_LINK_STATE": {
            return { ...state, is_share_link: action.new_state }
        }

        case "TOURNAMENT:CHANGE_VERIFY_FORM_PAYMENT_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], verify_form_payment_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "TOURNAMENT:CHANGE_SHARE_IFRAME_LINK_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], share_iframe_link_modal_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }
        
        case "TOURNAMENT:LOAD_SELECTED_TOURNAMENT_TEAMS": {
            
            return { ...state, selected_tournament_my_teams: action.data.data.results, selected_tournament: action.data.data.tournament, selected_tournament_team: action.data.data.results[0]}

        }

        case "TOURNAMENT:LOAD_SELECTED_TOURNAMENT_TEAMS_PAY": {
            
            return { ...state, selected_tournament_my_teams: action.data.data.results, selected_tournament: action.data.data.tournament, selected_tournament_team: action.data.data.results.find(team => team.id === action.tournament_team_id)}

        }

        case "TOURNAMENT:LOAD_SELECTED_TOURNAMENT_TEAMS_ONLY": {

            return { ...state, selected_tournament_my_teams: action.data.data.results, selected_tournament: action.data.data.tournament }

        }        

        case "TOURNAMENT:SET_SELECTED_TOURNAMENT_TEAM": {

            return { ...state, selected_tournament_team: action.team}

        }
        
        case "TOURNAMENT:LOAD_HUB_TOURNAMENTS_SECTIONS": {
            return { ...state, hub_tournaments_sections: action.data}
        }
        
        case "TOURNAMENT:LOAD_HUB_TOURNAMENT_SUBSECTION": {
            let new_hub_tournaments = null;
            if(state.hub_tournament_next_page !== action.data.next || state.hub_tournament_subsection.length === 0){
                new_hub_tournaments = state.hub_tournament_subsection.concat( action.data.results );
            }else{
                new_hub_tournaments = state.hub_tournament_subsection;
            }
            return { ...state, hub_tournament_subsection: new_hub_tournaments, hub_tournament_next_page: action.data.next}
        }

        case "TOURNAMENT:CLEAR_HUB_TOURNAMENT_SUBSECTION": {
            return { ...state, hub_tournament_subsection: action.data, hub_tournament_next_page: null }
        }

        case "TOURNAMENT:LOAD_FOLLOWING_HUB_TOURNAMENTS_SUBSECTION": {
            return { ...state, following_hub_tournaments_subsection: action.data.results }
        }

        case "TOURNAMENT:LOAD_INFORMATIVE_TOURNAMENT": {

            return { ...state, selected_tournament: action.data.data}
            
        }

        case "TOURNAMENT:CHANGE_STAFF_INVITATION_MODAL_STATE":{
            let new_modals_state = { ...state['modals'], staff_invitation_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "TOURNAMENT:CHANGE_STAFF_ACCEPT_INVITATION_MODAL_STATE":{
            let new_modals_state = { ...state['modals'], staff_accept_invitation_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "TOURNAMENT:CHANGE_INSCRIPTION_DEADLINE_MODAL_STATE":{
            let new_modals_state = { ...state['modals'], inscription_deadline_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "TOURNAMENT:LOAD_HUB_TOURNAMENT_MATCHES": {
            return { ...state, hub_tournament_matches: action.data.results? action.data.results : action.data }
        }

        case "TOURNAMENT:LOAD_HUB_NEXT_MATCHES": {
            return { ...state, hub_tournament_next_matches: action.data.results? action.data.results : action.data }
        }

        case "TOURNAMENT:LOAD_HUB_TOURNAMENTS_MATCHES_DATES": {
            return { ...state, hub_tournament_matches_dates: action.data.results? action.data.results : action.data }
        }

        case "TOURNAMENT:CHANGE_UNLOCK_STATS_MODAL_STATE":{
            let new_modals_state = { ...state['modals'], unlock_stats_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }
        
        case "TOURNAMENT:TEAM_MANAGER_AND_STAFF": {

            let new_team_staff = null;
            if((action.data.next !== null && state.next_team_staff !== action.data.next) || action.data.previous){
                new_team_staff = state.team_manager_and_staff.concat( action.data.results );
            }else if(!action.data.previous){
                new_team_staff = action.data.results;
            }else{
                new_team_staff = state.team_manager_and_staff;
            } 

            return { ...state, team_manager_and_staff: new_team_staff, next_team_staff: action.data.next, prev_team_staff: action.data.previous}
        }
        
        case "TOURNAMENT:CHANGE_ADD_MEMBERS_WITHOUT_ACCOUNT_MODAL_STATE":{
            let new_modals_state = { ...state['modals'], members_without_account_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }
        

        case "TOURNAMENT:CHANGE_TOURNAMENT_CARD_ID_GENERATOR_MODAL_STATE":{
            let new_modals_state = { ...state['modals'], card_id_pdf_generator_modal: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "TOURNAMENT:CHANGE_LEAGUES_ADD_MEMBER_TO_LEAGUE_MODAL_STATE":{

            let new_modals_state = { ...state['modals'], add_member_to_league_is_open: action.new_state }
            return { ...state, modals: new_modals_state }

        }

        case "TOURNAMENT:CHANGE_ANSWER_MULTIPLE_FORMS_MODAL_STATE":{

            let new_modals_state = { ...state['modals'], answer_multiple_forms_is_open: action.new_state }
            return { ...state, modals: new_modals_state }

        }
        
        case "TOURNAMENT:LOAD_PHASE_CALENDAR": { 
            return { ...state, days_calendar_view: action.data.data }
        }

        case "TOURNAMENT:LOAD_PHASE_CALENDAR_MATCHDAYS": { 
            return { ...state, calendar_matchdays: action.data.data }
        }

        case "TOURNAMENT:LOAD_PHASE_CALENDAR_GROUP_MATCHES": {
            let new_group_matches = null;
            if((action.data.previous !== null && state.calendar_group_matches_next !== action.data.next) || action.data.previous){
                new_group_matches = state.calendar_group_matches.concat( action.data.results );
            }else if(!action.data.previous){
                new_group_matches = action.data.results;
            }else{
                new_group_matches = state.calendar_group_matches;
            } 

            return { ...state, calendar_group_matches: new_group_matches, calendar_group_matches_count: action.data.count, calendar_group_matches_next: action.data.next}
        }

        case "TOURNAMENT:CHANGE_TEAM_PLAYERS_LOADING_STATE": {
            return { ...state, loading_team_players: action.new_state }
        }

        case "TOURNAMENT:CHANGE_TEAM_STAFF_LOADING_STATE": {
            return { ...state, loading_team_staff: action.new_state }
        }

        case "TOURNAMENT:CHANGE_FORMS_LOADING_STATE": {
            return { ...state, loading_forms: action.new_state }
        }

        case "TOURNAMENT:LOAD_SELECTED_SHARE_TOURNAMENT": {
            return { ...state, selected_share_tournament: action.data.data }
        }

        case "TOURNAMENT:SET_SELECTED_INVITED_TOURNAMENT_TEAM": {
            return { ...state, selected_tournament_team: action.team }
        }

        case "TOURNAMENT:CHANGE_PHASE_CALENDAR_LOADING_STATE": {
            return { ...state, loading_phase_calendar: action.new_state }
        }

        case "TOURNAMENT:RESET_CALENDAR_GROUP_MATCHES": {
            return { ...state, calendar_group_matches: [], calendar_group_matches_count: 0, calendar_group_matches_next: null}
        }

        case "TOURNAMENT:CHANGE_PHASE_DATE_GROUPS_LOADING_STATE": {
            return { ...state, loading_phase_date_groups: action.new_state }
        }

        case "TOURNAMENT:LOAD_PHASE_DATE_GROUPS": {
            return { ...state, phase_date_groups: action.data.results }
        }

        case "TOURNAMENT:LOAD_PHASE_DATE_GROUP_MATCHES": {
            let new_date_group_matches = null;
            if((action.data.previous !== null && state.phase_date_group_matches_next !== action.data.next) || action.data.previous){
                new_date_group_matches = state.phase_date_group_matches.concat( action.data.results );
            }else if(!action.data.previous){
                new_date_group_matches = action.data.results;
            }else{
                new_date_group_matches = state.phase_date_group_matches;
            } 

            return { ...state, phase_date_group_matches: new_date_group_matches, phase_date_group_matches_next: action.data.next}
        }

        case "TOURNAMENT:RESET_PHASE_DATE_MATCHES": {
            return { ...state, phase_date_group_matches: [], phase_date_group_matches_next: null}
        }

        case "TOURNAMENT:CHANGE_VIEW_MORE_TEAMS_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], view_more_teams_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "TOURNAMENT:CHANGE_TOURNAMENT_TEAMS_LOADING_STATE": {
            return { ...state, loading_tournament_teams: action.new_state }
        }

        case "TOURNAMENT:LOAD_FRONT_TOURNAMENT_TEAM": {
            return { ...state, selected_podium_tournament_team: action.data }
        }

        /* case "TOURNAMENT:LOAD_TOURNAMENT_FAIR_PLAY_TABLE": { 
            let new_fair_play = null;
            if(action.data.previous && state.next_tournament_fair_play !== action.data.next ){
                new_fair_play = state.tournament_fair_play.concat( action.data.results );
            }else if(!action.data.previous){
                new_fair_play = action.data.results;
            }else{
                new_fair_play = state.tournament_fair_play;
            } 
            
            return { ...state, tournament_fair_play: new_fair_play, next_tournament_fair_play: action.data.next }
        }

        case "TOURNAMENT:CHANGE_LOADING_TOURNAMENT_FAIR_PLAY_TABLE_STATE": {
            return { ...state, loading_tournament_fair_play: action.new_state }
        } */

        // no default
    }
    return state;
}

// React
import React from 'react';

// Styles
import './TournamentStatisticsSummaryComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

//isMobile
import { isMobile } from 'react-device-detect';

//Components
import TournamentStatisticsSkeletonComponent from 'app/leagues/components/tournament-statistics-skeleton/TournamentStatisticsSkeletonComponent';

function TournamentStatisticsSummaryComponent(props){ 
    return(
        <div className='tournament-statistics-summary__container'>
            <div className='tournament-statistics-summary__title'>
                <div className='baloa-subtitle-2'>{ props.title }</div>
                <div className='baloa-subtitle-2'>{ props.column_title }</div> 
            </div>
            {props.loading_summary && 
                [...Array(Number(isMobile? 4 : 5))].map((e, i) => 
                    <TournamentStatisticsSkeletonComponent summary={true}/>
                )
            }
            {(!props.loading_summary && props.tournament_summary)
                ?
                    <React.Fragment>
                        <div className='tournament-statistics-summary__row' key={'goals'}>
                            <span className='hubicon-sports_and_outdoors icon'/>
                            <div className='tournament-statistics-summary__row_label baloa-normal-medium'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.summaryTable.goalLbl')}</div>
                            <div className='tournament-statistics-summary__row_data baloa-normal-medium'>{props.tournament_summary.total_goals}</div>
                        </div>
                        <div className='tournament-statistics-summary__row' key={'assists'}>
                            <span className='hubicon-arrow_outward icon'/>
                            <div className='tournament-statistics-summary__row_label baloa-normal-medium'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.summaryTable.assistancesLbl')}</div>
                            <div className='tournament-statistics-summary__row_data baloa-normal-medium'>{props.tournament_summary.total_assists}</div>
                        </div>
                        <div className='tournament-statistics-summary__row' key={'yellow-cards'}>
                            <div className='yellow-card'/>
                            <div className='tournament-statistics-summary__row_label baloa-normal-medium'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.summaryTable.yellowCardLbl')}</div>
                            <div className='tournament-statistics-summary__row_data baloa-normal-medium'>{props.tournament_summary.total_yellow_cards}</div>
                        </div>
                        <div className='tournament-statistics-summary__row' key={'red-cards'}>
                            <div className='red-card'/>
                            <div className='tournament-statistics-summary__row_label baloa-normal-medium'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.summaryTable.redCardLbl')}</div>
                            <div className='tournament-statistics-summary__row_data baloa-normal-medium data'>{props.tournament_summary.total_red_cards}</div>
                        </div>
                        <div className='tournament-statistics-summary__row' key={'blue-cards'}>
                            <div className='blue-card'/>
                            <div className='tournament-statistics-summary__row_label baloa-normal-medium'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.summaryTable.blueCardLbl')}</div>
                            <div className='tournament-statistics-summary__row_data baloa-normal-medium data'>{props.tournament_summary.total_blue_cards}</div>
                        </div>
                        <div className='tournament-statistics-summary__row' key={'played-games'}>
                            <span className='icon-field icon'/>
                            <div className='tournament-statistics-summary__row_label baloa-normal-medium'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.summaryTable.gamesPlayedLbl')}</div>
                            <div className='tournament-statistics-summary__row_data baloa-normal-medium'>{props.tournament_summary.total_play_matches}</div>
                        </div>
                    </React.Fragment>
                   
                :
                    <div className='tournament-statistics-summary__no-data'>
                        <div className='baloa-normal'>{ props.empty_summary_lbl }</div>
                    </div>
            }
        </div>
    )
}
    
export default withTranslation('league')(TournamentStatisticsSummaryComponent);
            
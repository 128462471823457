// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.baloa-play-invalid-link-modal .modal__container {
    width: 440px;
    padding: 24px;
}   

.baloa-play-invalid-link-modal__container {
    text-align: center;
    margin-top: 10px;
}

.baloa-play-invalid-link-modal__container .hubicon-link_off::before {
    font-size: 40px;
    color: var(--error-color);
}

.baloa-play-invalid-link-modal__container .baloa-normal-medium {
    color: var(--primary-very-dark-color);
    margin-top: 24px;
}

.baloa-play-invalid-link-modal__container .baloa-names {
    color: var(--primary-very-dark-color);
    font-weight: var(--font-regular);
    margin: 9px;
}

.baloa-play-invalid-link-modal__container .button {
    margin-top: 24px;
    width: 100%;
    font-size: 18px;
    padding: 10px 43px;
}

.baloa-play-invalid-link-modal__container .button.text-button {
    margin-top: 4px;
    color: var(--primary-very-dark-color);
}

@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */ 

    .baloa-play-invalid-link-modal .modal__container {
        padding: 24px 16px;
    }

    .baloa-play-invalid-link-modal__container .hubicon-link_off::before {
        font-size: 30px;
    }

    .baloa-play-invalid-link-modal__container .button {
        font-size: 15px;
    }

}`, "",{"version":3,"sources":["webpack://./src/app/baloa-play/components/baloa-play-invalid-link-modal/BaloaPlayInvalidLinkModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,yBAAyB;AAC7B;;AAEA;IACI,qCAAqC;IACrC,gBAAgB;AACpB;;AAEA;IACI,qCAAqC;IACrC,gCAAgC;IAChC,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,qCAAqC;AACzC;;AAEA,yCAAyC,4BAA4B;;IAEjE;QACI,kBAAkB;IACtB;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,eAAe;IACnB;;AAEJ","sourcesContent":[".baloa-play-invalid-link-modal .modal__container {\n    width: 440px;\n    padding: 24px;\n}   \n\n.baloa-play-invalid-link-modal__container {\n    text-align: center;\n    margin-top: 10px;\n}\n\n.baloa-play-invalid-link-modal__container .hubicon-link_off::before {\n    font-size: 40px;\n    color: var(--error-color);\n}\n\n.baloa-play-invalid-link-modal__container .baloa-normal-medium {\n    color: var(--primary-very-dark-color);\n    margin-top: 24px;\n}\n\n.baloa-play-invalid-link-modal__container .baloa-names {\n    color: var(--primary-very-dark-color);\n    font-weight: var(--font-regular);\n    margin: 9px;\n}\n\n.baloa-play-invalid-link-modal__container .button {\n    margin-top: 24px;\n    width: 100%;\n    font-size: 18px;\n    padding: 10px 43px;\n}\n\n.baloa-play-invalid-link-modal__container .button.text-button {\n    margin-top: 4px;\n    color: var(--primary-very-dark-color);\n}\n\n@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */ \n\n    .baloa-play-invalid-link-modal .modal__container {\n        padding: 24px 16px;\n    }\n\n    .baloa-play-invalid-link-modal__container .hubicon-link_off::before {\n        font-size: 30px;\n    }\n\n    .baloa-play-invalid-link-modal__container .button {\n        font-size: 15px;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

/*eslint eqeqeq: "off"*/
// Google Analytics
import ReactGA from 'react-ga4';
import history from 'config/history';

// Action creators
import { 
    changeSignUpLoading,
    changeSignUpPhone,
    changeSignUpStatus,
    changeSignUpLoggedIn,
    changeSignUpError,
    changeIsLogin, 
    changeSignUpWelcomeModal,
    countrySignUpLogin,
    loadTermsAndConditionsText,
    changeSignUpEmail,
    getCountries,
    searchCityByCountry,
    changeRecoveryPasswordCode,
    getTeams,
    changeCompleteUserInfoModalStateAction,
    loadProfessionTypesAction,
    loadPreRegisteredMembersAction,
    setClaimedAccountProfileAction,
    changeSignUpBaloaProModalStateAction,
    changeSignUpSessionLimitModalStateAction,
    changeSignUpInvalidSessionModalStateAction,
} from './LoginActionsCreators';

import { changeAdminResponsiveLockModalStateAction } from 'app/pages/actions/PageActionsCreators';

import { loadUserMe } from './UserActionsCreators';
import { changeErrorMessageSnackBar } from 'app/home/actions/HomeActionsCreators';
import { loadLogguedinProfile } from 'app/profiles/actions/ProfileActions';
import { setUserIdAction, addEventAction } from 'app/clickstream/actions/ClickstreamActionsCreators';

import { loadStripeUserAccount } from 'app/baloa-pro/actions/BaloaProActions'

// Apis
import { 
    GetCountriesLoginApi, 
    SendOtpApi, 
    SignInWithOtpApi, 
    SignInWithSocialApi, 
    SendOtpEmailApi, 
    SignUpEmailApi, 
    LoginWithEmailOtp, 
    SignUpResendEmailVerificationApi, 
    SignUpSendRecoverPasswordApi, 
    SignInVerifyRecoveryPasswordCodeApi, 
    SignInSendNewEmailPasswordApi,
    GetProfessionTypesApi,
    SearchPreRegisteredMembersApi,
    PatchPreRegisterEmailPhoneApi,
    getClaimedAccountProfileApi,
    DeleteBackendSessionApi,
} from 'app/authentication/apis/SignUpApi';
import { GetUserMeApi, PatchUserApi, GetTermsApi, PatchUserDataWithPhotoApi, updateNoRegisterUserDataActionApi } from 'app/authentication/apis/UserApi';
import { GetCountriesApi, searchCityByCountryApi, } from 'app/leagues/apis/LeagueTournamentApi';
import { UpdateProfileDataApi, CreateAttributeOnProfileApi, DeleteProfileTeamApi, AddProfileTeamByCodeApi, GetProfileApi } from 'app/profiles/apis/ProfileApi';

// Constants
import { SIGNUP_STATUS, SIGNUP_LOADING_STATES } from 'app/authentication/reducers/SignUpReducer';

// i18next 
import i18n from 'i18next';
import {setAplicationLanguage} from 'app/user-settings/actions/UserSettingsActions';

export const sendPhoneAction = (phone, token) => {   
    return (dispatch) => {
        dispatch(changeSignUpLoading(SIGNUP_LOADING_STATES['PHONE']));
        dispatch(changeSignUpPhone(phone.phone));

        SendOtpApi(phone, token)
            .then(response => {
                dispatch(changeSignUpLoading(SIGNUP_LOADING_STATES['NONE']));
                dispatch(changeSignUpStatus(SIGNUP_STATUS['OTP']));
            })
            .catch(error => {
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('signUp:code.error4'),
                    error_message_is_open: true
                } ) ); 
                dispatch(changeSignUpLoading(SIGNUP_LOADING_STATES['NONE']));
                //console.log(error.response)
            });
    }
}



export const signUpWithSocialAction = (data, social_network) => (dispatch) => {
    //dispatch(changeSignUpLoading(SIGNUP_LOADING_STATES['SOCIAL_FACEBOOK']));
    SignInWithSocialApi(data, social_network)
        .then(response => {
            localStorage.setItem('token', 'Bearer ' + response.data.access_token);
            dispatch(addEventAction({Datetime: new Date().toISOString(),EventData: {EventType: 'login'}}));  //set Clickstream Login event
            dispatch(getUserMeAction());
        })
        .catch(error => {
            //dispatch(changeSignUpLoading(SIGNUP_LOADING_STATES['NONE']));
            console.log(error)
        });
}


export const getUserMeAction = ( username, url_redirect = null ) => {
    return (dispatch) => {
    
        GetUserMeApi()       
            .then(response => {
                dispatch(changeSignUpLoading(SIGNUP_LOADING_STATES['NONE']));
                dispatch(loadUserMe(response.data));
                dispatch(setUserIdAction(response.data.id)); //set Clickstream UserId            
                ReactGA.set({
                    userId: response.data.id,
                })
                dispatch( loadLogguedinProfile( response.data.username ) )
                //if ( response.data.birthday === null || response.data.birthday === "" ) {
                if ( response.data.username_asigned === false ) {
                    dispatch(changeSignUpStatus(SIGNUP_STATUS['FORM']));
                // } else if ( response.data.username_asigned === false ) {
                //     dispatch(changeSignUpStatus(SIGNUP_STATUS['USERNAME_ASSIGNMENT']));
                // } else if ( response.data.username != username ) {
                //     dispatch(changeSignUpStatus(SIGNUP_STATUS['PROFILE_TYPE']));
                    if(url_redirect && url_redirect !== '/cuenta/ingresar' ) {
                        dispatch( changeCompleteUserInfoModalStateAction(true) );
                    }
                }else if(response.data.pre_registered_user && !response.data.pre_registered_user_update){//Usuario creado no registrado - sin cargar documento ni actualizar datos
                    dispatch(changeSignUpStatus(SIGNUP_STATUS['FORM']));
                    if(response.data.username_asigned == true && url_redirect !== '/cuenta/ingresar' ){
                        dispatch( changeCompleteUserInfoModalStateAction(true) );
                    }                    
                } else if(response.data.username_asigned == true && (response.data.email_is_verified == true || response.data.phone_is_verified == true) ) {
                    if ( ( localStorage.getItem('ae') == false ) || localStorage.getItem('ae') == null ){
                        localStorage.setItem('ae', true);
                        const path = history.location.search.split('/');
                        //-share hub de torneos- || -share inscription team staff / tournaments- || -share inscripcion equipos pagina-
                        if(path[1] === 'hub' || path[0] === '?tournament'  || path[0] === '?page') {
                            dispatch( changeSignUpWelcomeModal(false) );
                        } else {
                            //dispatch( changeSignUpWelcomeModal(true) );
                        }                        
                        dispatch( changeAdminResponsiveLockModalStateAction(false) );
                    }
                    dispatch(changeSignUpLoggedIn(true));
                    if(username){//Solo redirecciona al home cuando viene del registro/login
                        if(url_redirect){                          
                            history.push(url_redirect.replace('?','/'));//Redirecciona segun url compartida
                        }else{
                            history.push('/');
                        }                        
                    }

                    //muestra una unica vez el modal de cambio de condiciones para Baloa Pro
                    if( response.data.subscription_plan !== 'FREE' && response.data.is_baloa_plan_old && (localStorage.getItem('sign_up_modal') === false || localStorage.getItem('sign_up_modal') === null) ){//TODO: eliminar cuando no hayan mas suscripciones antiguas
                        dispatch( changeSignUpBaloaProModalStateAction(true) );
                        dispatch( loadStripeUserAccount( response.data.username ));
                        localStorage.setItem('sign_up_modal', true);
                    }
                } 
                if(response.data.language != null){
                    dispatch(setAplicationLanguage(response.data.language.language_code))
                }else{
                    dispatch(setAplicationLanguage('en'))
                }
            })
            .catch(error => {
                if(error.response.status === 401){//sesion invalida 
                    dispatch( changeSignUpInvalidSessionModalStateAction(true) )
                }else {
                    if(localStorage.getItem('token') != null){
                        dispatch( changeErrorMessageSnackBar( {
                            message: i18n.t('signUp:code.error3'),
                            error_message_is_open: true
                        } ) )
                    }
                }
                dispatch(changeSignUpLoading(SIGNUP_LOADING_STATES['NONE']));
                dispatch(changeIsLogin(false));
            });
    }
}

export const signUpWithOtpAction = (data, actions, locationUrl) => {
    return (dispatch) => {
        dispatch(changeSignUpLoading(SIGNUP_LOADING_STATES['OTP']));

        SignInWithOtpApi(data.phone, data.code)
            .then(response => {
                if(response.max_session_limit){//Dispara modal por numero maximo de sessiones
                    dispatch( changeSignUpSessionLimitModalStateAction(true) );
                }else {
                    localStorage.setItem('token', 'Token ' + response.data.token);
                    dispatch(changeSignUpLoading(SIGNUP_LOADING_STATES['NONE']));
                    dispatch(addEventAction({Datetime: new Date().toISOString(),EventData: {EventType: 'login'}}));  //set Clickstream Login event
                    dispatch(getUserMeAction(response.data.user.username, locationUrl));
                }
            })
            .catch(error => {                
                dispatch(changeSignUpLoading(SIGNUP_LOADING_STATES['NONE']));
                const data = {
                    'field': 'code',
                    'error': i18n.t('signUp:code.error'),//'El código es incorrecto',
                }
                dispatch(changeSignUpError(data))
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('signUp:code.error'), //'El código es incorrecto'
                    error_message_is_open: true
                } ) )
            });
    }
}

export const sendUserDataAction = (user_data, actions, username) => {
    return (dispatch) => {
        dispatch(changeSignUpLoading(SIGNUP_LOADING_STATES['FORM']));

        PatchUserApi(user_data)
            .then(response => {
                dispatch(changeSignUpLoading(SIGNUP_LOADING_STATES['NONE']));
                dispatch(loadUserMe(response.data));
                dispatch( getUserMeAction(username));
                // if (!response.data.username_asigned) {
                //     dispatch(changeSignUpStatus(SIGNUP_STATUS['USERNAME_ASSIGNMENT']));
                // } 
            })
            .catch(error => {
                dispatch(changeSignUpLoading(SIGNUP_LOADING_STATES['NONE']));
                let error_field = Object.keys(error.response.data)[0];
                const data = {
                    'field': error_field,
                    'error': error.response.data[error_field][0],
                }
                dispatch(changeSignUpError(data))
                dispatch( changeErrorMessageSnackBar( {
                    message: handleUpdateMsgError(error.response),
                    error_message_is_open: true
                } ) )
            });
    }
}

export const ChangeStateIsLogin = (IsLogin) => {
    return (dispatch) => {
        dispatch(changeIsLogin(IsLogin));
        // console.log("Estado cambiado, Nuevo estado = " + IsLogin);
    }
}

/* export const userLogout = () => {
    return async (dispatch) => {
        await dispatch(deleteBackendSession());
        addEventAction({Datetime: new Date().toISOString(),EventData: {EventType: 'logout'}});  //set Clickstream Login event    
        localStorage.removeItem('token');
        localStorage.removeItem('ae');
        history.push('/');
        window.location.reload();
    }
} */

export const loadCountryInLogin = () => {

    return (dispatch) =>{
        GetCountriesLoginApi()
        .then(response =>{
            dispatch(countrySignUpLogin(response.data.countries));
        })
        .catch(error =>{
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('signUp:code.error3'),
                    error_message_is_open: true
                } ) )
            }
        })
        
    }
}

export const getTermsAndConditionsAction = ( ) => {

    return (dispatch) => {
    
        GetTermsApi()
            .then(response => {
                // console.log('r',response);
                dispatch( loadTermsAndConditionsText(response.data));
            })
            .catch(error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('signUp:code.error3'),
                        error_message_is_open: true
                    } ) )
                }
                dispatch(changeSignUpLoading(SIGNUP_LOADING_STATES['NONE']));
                dispatch(changeIsLogin(false));
            });

    }
}

export const signUpEmailAction = (email_credentials) => { 
    return (dispatch) => {

        function handleMsgError (error) {
            if(error.data.baloa_error_code === "A005"){
                dispatch(changeSignUpStatus(SIGNUP_STATUS['LOGIN_EMAIL']));
            }
            if(error.data.email ) {
                return error.data.email[0];    
            } else if(error.data.password ) {
                return error.data.password[0];
            } else if(error.data.detail){
                return error.data.detail;
            }
        }

        dispatch(changeSignUpLoading(SIGNUP_LOADING_STATES['EMAIL']));
        dispatch(changeSignUpEmail(email_credentials.email));

        SignUpEmailApi(email_credentials)
            .then(response => {
                localStorage.setItem('token', 'Token ' + response.data.token);
                dispatch(changeSignUpLoading(SIGNUP_LOADING_STATES['NONE'])); 
                dispatch(changeSignUpStatus(SIGNUP_STATUS['EMAIL_VERIFICATION']));
            })
            .catch(error => {                
                dispatch( changeErrorMessageSnackBar( {
                    message: handleMsgError(error.response),
                    error_message_is_open: true
                } ) );                 
            });
    }
}

export const logInEmailAction = (email) => { 
    return (dispatch) => {

        // function handleMsgError (error) {
        //     if(error.data.baloa_error_code === "A006"){
        //         SignUpResendEmailVerificationApi(email)
        //             .then(response => {
        //                 dispatch(changeSignUpStatus(SIGNUP_STATUS['EMAIL_VERIFICATION']));
        //             })
        //             .catch(error => {                
        //                 dispatch(changeSignUpLoading(SIGNUP_LOADING_STATES['NONE']));
        //                 const data = {
        //                     'field': 'code',
        //                     'error': i18n.t('signUp:code.error'),
        //                 }
        //                 dispatch(changeSignUpError(data))
        //                 dispatch( changeErrorMessageSnackBar( {
        //                     message: i18n.t('signUp:code.error'),
        //                     error_message_is_open: true
        //                 } ) )
        //             });

        //             return error.data.detail;
        //     }

        //     if(error.data.email ) {
        //         return error.data.email[0];    
        //     } else if(error.data.password ) {
        //         return error.data.password[0];
        //     } else if(error.data.detail){
        //         return error.data.detail;
        //     }
        // }

        dispatch(changeSignUpLoading(SIGNUP_LOADING_STATES['LOGIN_EMAIL']));
        dispatch(changeSignUpEmail(email));
        SendOtpEmailApi(email)
            .then(response => {
                //localStorage.setItem('token', 'Token ' + response.data.token);
                dispatch(changeSignUpLoading(SIGNUP_LOADING_STATES['NONE']));
                //dispatch(addEventAction({Datetime: new Date().toISOString(),EventData: {EventType: 'login'}}));  //set Clickstream Login event
                //dispatch(getUserMeAction(response.data.user.username));
                dispatch(changeSignUpStatus(SIGNUP_STATUS['EMAIL_VERIFICATION']));
            })
            .catch(error => {
                
                dispatch( changeErrorMessageSnackBar( {
                    //message: handleMsgError(error.response),
                    message: i18n.t('signUp:code.error'),
                    error_message_is_open: true
                } ) ); 
                dispatch(changeSignUpLoading(SIGNUP_LOADING_STATES['NONE']));                
            });
    }
}

export const signUpEmailVerificationAction = (data, actions, locationUrl) => {
    return (dispatch) => {
        dispatch(changeSignUpLoading(SIGNUP_LOADING_STATES['EMAIL_VERIFICATION']));
        LoginWithEmailOtp(data.email, data.code)
            .then(response => {
                localStorage.setItem('token', 'Token ' + response.data.token);
                dispatch(addEventAction({Datetime: new Date().toISOString(),EventData: {EventType: 'login'}}));  //set Clickstream Login event
                dispatch(changeSignUpLoading(SIGNUP_LOADING_STATES['NONE']));
                //dispatch(changeSignUpStatus(SIGNUP_STATUS['FORM']));
                dispatch(getUserMeAction(null,locationUrl));                
            })
            .catch(error => {                
                dispatch(changeSignUpLoading(SIGNUP_LOADING_STATES['NONE']));
                const data = {
                    'field': 'code',
                    'error': i18n.t('signUp:code.error'),
                }
                dispatch(changeSignUpError(data))
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('signUp:code.error'), 
                    error_message_is_open: true
                } ) )
            });
    }
}

export const signUpResendEmailVerificationAction = (data, actions) => {
    return (dispatch) => {
        SignUpResendEmailVerificationApi(data.email)
            .then(response => {
                dispatch( changeErrorMessageSnackBar( {
                    message: response.data.message,
                    error_message_is_open: true
                } ) )

            })
            .catch(error => {                
                dispatch(changeSignUpLoading(SIGNUP_LOADING_STATES['NONE']));
                const data = {
                    'field': 'code',
                    'error': i18n.t('signUp:code.error'),
                }
                dispatch(changeSignUpError(data))
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('signUp:code.error'), 
                    error_message_is_open: true
                } ) )
            });
    }
}

export const signUpSendEmailRecoverPasswordAction = (data, actions) => {
    return (dispatch) => {

        function handleMsgError (error) {
            
            if(error.data.email ) {
                return error.data.email[0];    
            } else if(error.data.password ) {
                return error.data.password[0];
            } else if(error.data.detail){
                return error.data.detail;
            }
        }

        dispatch(changeSignUpLoading(SIGNUP_LOADING_STATES['RECOVER_PASSWORD']));
        SignUpSendRecoverPasswordApi(data)
            .then(response => {

                dispatch( changeErrorMessageSnackBar( {
                    message: response.data.message, 
                    error_message_is_open: true
                } ) )
                
                dispatch(changeSignUpEmail(data));
                dispatch(changeSignUpLoading(SIGNUP_LOADING_STATES['NONE']));
                dispatch(changeSignUpStatus(SIGNUP_STATUS['RECOVER_PASSWORD_VERIFICATION']));
                
            })
            .catch(error => {                
                dispatch(changeSignUpLoading(SIGNUP_LOADING_STATES['NONE']));
                const data = {
                    'field': 'code',
                    'error': i18n.t('signUp:code.error'),
                }
                dispatch(changeSignUpError(data))
                dispatch( changeErrorMessageSnackBar( {
                    message: handleMsgError(error.response),
                    error_message_is_open: true
                } ) )
            });
    }
}

export const signInVerifyRecoveryPasswordCodeAction = (data) => {
    return (dispatch) => {
        dispatch(changeSignUpLoading(SIGNUP_LOADING_STATES['RECOVER_PASSWORD_VERIFICATION']));
        SignInVerifyRecoveryPasswordCodeApi(data)
            .then(response => {
                dispatch(changeRecoveryPasswordCode(data.code));
                dispatch(changeSignUpLoading(SIGNUP_LOADING_STATES['NONE']));
                dispatch(changeSignUpStatus(SIGNUP_STATUS['NEW_PASSWORD_EMAIL']));
            })
            .catch(error => {                
                dispatch(changeSignUpLoading(SIGNUP_LOADING_STATES['NONE']));
                const data = {
                    'field': 'code',
                    'error': i18n.t('signUp:code.error'),
                }
                dispatch(changeSignUpError(data))
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('signUp:code.error'), 
                    error_message_is_open: true
                } ) )
            });
    }
}

export const signInSendNewEmailPasswordAction = (data) => {

    return (dispatch) => {
        dispatch(changeSignUpLoading(SIGNUP_LOADING_STATES['NEW_PASSWORD_EMAIL']));
        SignInSendNewEmailPasswordApi(data)
            .then(response => {
                dispatch(changeRecoveryPasswordCode(''));
                dispatch(changeSignUpLoading(SIGNUP_LOADING_STATES['NONE']));
                dispatch(changeSignUpStatus(SIGNUP_STATUS['LOGIN_EMAIL']));
            })
            .catch(error => {                
                dispatch(changeSignUpLoading(SIGNUP_LOADING_STATES['NONE']));
                const data = {
                    'field': 'code',
                    'error': i18n.t('signUp:code.error'),
                }
                dispatch(changeSignUpError(data))
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('signUp:code.error'),
                    error_message_is_open: true
                } ) )
            });
    }
}

export const getCountriesAction = (country) =>  {
    return (dispatch) => {
        GetCountriesApi(country)
            .then( response => {
                dispatch(getCountries(response) );               
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message:  i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const searchCityByCountryAction = (countryId, searched_city) => {
    return(dispatch)=> {
        searchCityByCountryApi(countryId, searched_city)
            .then(response => {
                dispatch(searchCityByCountry(response));
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message:  i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
        })
    }

}

export const createNewProfileTypeAction = ( profile, new_profile_type ) => {
    return (dispatch) => {        
        UpdateProfileDataApi( profile, new_profile_type )
            .then(response => {                
                if(new_profile_type !== 'professional') {
                    dispatch(changeSignUpStatus(SIGNUP_STATUS['TEAM_PANEL']));
                }                
            })
            .catch(error => {
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('signUp:code.error3'),
                    error_message_is_open: true
                } ) ); 
                dispatch(changeSignUpLoading(SIGNUP_LOADING_STATES['NONE']));
            });
    }
}

export const createAttributeOnProfileAction = ( profile, new_attribute_data ) => {
    return (dispatch) => {
        CreateAttributeOnProfileApi( profile, new_attribute_data )
            .then(response => {                
                dispatch(changeSignUpStatus(SIGNUP_STATUS['TEAM_PANEL']));
            })
            .catch(error => {
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('signUp:code.error3'),
                    error_message_is_open: true
                } ) ); 
                dispatch(changeSignUpLoading(SIGNUP_LOADING_STATES['NONE']));
            });
    }
}


export const createNewUsernameWithPhotoAction = (obj, username) => {
    return (dispatch) => {
        dispatch(changeSignUpLoading(SIGNUP_LOADING_STATES['USERNAME_ASSIGNMENT']));
        PatchUserDataWithPhotoApi(obj)
            .then(response => {
                dispatch(changeSignUpLoading(SIGNUP_LOADING_STATES['NONE']));
                dispatch(loadUserMe(response.data));
                dispatch(getUserMeAction(username));
            })
            .catch(error => {
                dispatch(changeSignUpLoading(SIGNUP_LOADING_STATES['NONE']));
                const data = {
                    'field': 'username',
                    'error': i18n.t('signUp:code.error2'),
                }
                dispatch(changeSignUpError(data))
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('signUp:code.error2'),
                    error_message_is_open: true
                } ) )
            });
    }
}

export const getTeamsAction = ( username ) => {

    return ( dispatch ) => {
        GetProfileApi( username )
            .then( response => {
                //console.log(response.data.teams);
                dispatch( getTeams( response.data.teams ) );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('profile:postError'), //'Tenemos un problema!',
                        error_message_is_open: true
                    } ) )
                }
                //console.log(err);
            })
    }
}

export const endAddTeamOnSignupAction = ( profile ) => {
    return (dispatch) => {
        dispatch(getUserMeAction(profile.user.username));                
    };
}

export const addSignupTeamByCode = ( username, teamCode ) => {
    return ( dispatch ) => {
        AddProfileTeamByCodeApi( username, teamCode )
            .then( response => {
                dispatch(getTeamsAction(username));
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('profile:postError'), //'Tenemos un problema!',
                        error_message_is_open: true
                    } ) )
                }
            });
    }
}

export const deleteSignupTeam = ( username, teamname ) => {
    return ( dispatch ) => {
        DeleteProfileTeamApi( username,teamname )
            .then( () => {
                dispatch(getTeamsAction(username));
            })
            .catch( error => {
                //console.log(error);
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('profile:postError'), //'Tenemos un problema!',
                        error_message_is_open: true
                    } ) )
                }
            });
    }
}

export const updateUserDataAction = (user_data) => {    
    return (dispatch) => {        
        PatchUserApi(user_data)
            .then(response => {
                dispatch(loadUserMe(response.data));
                dispatch( changeCompleteUserInfoModalStateAction(false) );
            })
            .catch(error => {
                dispatch(changeSignUpLoading(SIGNUP_LOADING_STATES['NONE']));
                const data = {
                    'field': 'username',
                    'error': i18n.t('signUp:code.error2'),
                }
                dispatch(changeSignUpError(data))
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('signUp:code.error2'),//'El usuario ya existe, crea uno diferente',
                    error_message_is_open: true
                } ) )
            });
    }
}

export const loadProfessionTypes = () => {

    return (dispatch) =>{
        GetProfessionTypesApi()
            .then(response =>{
                dispatch(loadProfessionTypesAction(response));
            })
            .catch(error =>{
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('signUp:code.error3'),
                        error_message_is_open: true
                    } ) )
                }
            })
        
    }
}

export const searchPreRegisteredMembers = ( member ) => {

    return (dispatch) =>{
        SearchPreRegisteredMembersApi( member )
            .then(response =>{
                dispatch(loadPreRegisteredMembersAction(response));
            })
            .catch(error =>{
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('signUp:code.error3'),
                        error_message_is_open: true
                    } ) )
                }
            })
        
    }
}

export const updatePreRegisterEmailPhone = ( new_data, user_id, method ) => {

    return async( dispatch ) => {
        await PatchPreRegisterEmailPhoneApi( new_data, user_id )
            .then( response => {
                if(method === 'phone'){
                    dispatch( sendPhoneAction(new_data) );
                }
                if(method === 'email'){
                    dispatch( logInEmailAction( response.data.email ) );
                }
            })
            .catch(err => {
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('signUp:code.error4'),
                    error_message_is_open: true
                } ) );             
                //console.log('Error', err);
            })
    }
}

function handleUpdateMsgError (error) {
    if(error.data.email ) {
        return error.data.email[0];    
    } else if(error.data.password ) {
        return error.data.password[0];
    } else if(error.data.birthday){
        return error.data.birthday[0];
    }else if(error.data.photo){
        return error.data.photo[0];
    } else if(error.data.document_file_data){
        return error.data.document_file_data[0];
    }
}

export const sendNoRegisterUserDataAction = ( user_data, configUpload, loginUrl ) => {
    
    return async ( dispatch ) =>  {
        dispatch(changeSignUpLoading(SIGNUP_LOADING_STATES['FORM']));
        await updateNoRegisterUserDataActionApi( user_data, configUpload )
             .then( response => {
                dispatch(changeSignUpLoading(SIGNUP_LOADING_STATES['NONE']));
                dispatch(loadUserMe(response.data));
                dispatch( getUserMeAction(response.data.username, loginUrl) )              
             })
             .catch(err => {
                dispatch(changeSignUpLoading(SIGNUP_LOADING_STATES['NONE']));
                let error_field = Object.keys(err.response.data)[0];
                const data = {
                    'field': error_field,
                    'error': err.response.data[error_field][0],
                }
                dispatch(changeSignUpError(data))
                dispatch( changeErrorMessageSnackBar( {
                    message: handleUpdateMsgError(err.response),
                    error_message_is_open: true
                } ) )
             })
     }
}

export const loadClaimedAccountProfile = ( user_id ) => {

    return async( dispatch ) => {
        await getClaimedAccountProfileApi( user_id )
            .then( response => {
                    dispatch( setClaimedAccountProfileAction( response.data ) );
            })
            .catch(err => {
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('signUp:code.error8'),
                    error_message_is_open: true
                } ) );             
                //console.log('Error', err);
            })
    }
}

export const userLogout = () => {
    return async( dispatch ) => {
        await DeleteBackendSessionApi()
            .then( () => {
                addEventAction({Datetime: new Date().toISOString(),EventData: {EventType: 'logout'}});  //set Clickstream Login event    
                localStorage.removeItem('token');
                localStorage.removeItem('ae');
                history.push('/');
                window.location.reload();
            })
            .catch( error => {
                //console.log('logout error',error);                
            });
    }
}

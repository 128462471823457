// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import FormComponent from 'shared/components/form/FormComponent';
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import InputFieldAutocompletePaymentCountries2 from 'shared/components/input-field-autocomplete-with-search/InputFieldAutocompletePaymentCountries2';

//Validators
import { EmailRequiredValidator, RequiredValidator } from 'shared/validators/RequiredValidator';

// React device detect
import { isMobile } from 'react-device-detect';

// Styles
import './BaloaProAddPaymentMethodModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

//Stripe
import {CardNumberElement, CardExpiryElement, CardCvcElement, useElements, useStripe} from '@stripe/react-stripe-js';
//import { TextField } from '@mui/material';


function BaloaProAddPaymentMethodModalComponent ( props ){
    const [ignored, forceUpdate] = React.useReducer(x => x + 1, 0); //eslint-disable-line
    const [cardNumberComplete, setCardNumberComplete] = React.useState(false);
    const [cardExpiryComplete, setCardExpiryComplete] = React.useState(false);
    const [cardCvcComplete, setCardCvcComplete] = React.useState(false);
    const [formValid, setFormValid] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const formRef = React.createRef();

    const formInitialValues = {
        country: '',
        nombre: '',
    }

    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                color: '#2A302A',
                fontFamily: "'Roboto', sans-serif",
                fontSmoothing: 'antialiased',
                fontSize: '14px',
                '::placeholder': {
                    color: '#CDCDCD',
                },

            },
            invalid: {
                color: '#FF515B',
                iconColor: '#FF515B',
            },
        },
    };

    const CARD_ELEMENT_OPTIONS_MOBILE = {
        style: {
            base: {
                color: '#2A302A',
                fontFamily: "'Roboto', sans-serif",
                fontSmoothing: 'antialiased',
                fontSize: '14px',
                '::placeholder': {
                    color: '#CDCDCD',
                },

            },
            invalid: {
                color: '#FF515B',
                iconColor: '#FF515B',
            },
        },
    };

    const valiadateForm = ( values )=> {
        if(values === null){
            setFormValid(false);
        }else if(formRef?.current?.isValid && cardNumberComplete && cardExpiryComplete && cardCvcComplete && formRef?.current?.values.country){
            setFormValid(true);
        }else{
            setFormValid(false);
        }
    }

    const handleSubmit = async ( values) => {
        //event.preventDefault();
        setLoading(true);

        const cardElement = elements.getElement(CardNumberElement);

        const { error: stripeError, setupIntent } = await stripe.confirmCardSetup(props.stripe_setup_intent.client_secret, {
            payment_method: {
                card: cardElement,
                billing_details: {
                    email: values.email,
                },
            },
        }  
        );

        if (stripeError) {
            setError(stripeError.message);
            setLoading(false);
            return;
        }

        setLoading(false);
        props.onClose();
    };

    const myInput = ()=>{
        <CardNumberElement 
                        options={isMobile? CARD_ELEMENT_OPTIONS_MOBILE : CARD_ELEMENT_OPTIONS}
                        onChange={(event) => {setCardNumberComplete(event.complete); valiadateForm(); }}
                    />
    }

    return(
        <Modal2Component             
            isOpen={props.isOpen} 
            onClose={props.onClose}
            className={'baloa-pro-add-payment-method-modal'}
            title={props.t('addPaymentModal.title')}
        >
            <div className='baloa-pro-add-payment-method-modal__container'>
                <FormComponent
                    formInitialValues={formInitialValues}
                    onSubmit={(values, actions) => {
                        handleSubmit(values);
                    }}
                    className="baloa-pro-stripe-payment-modal__form scroll"
                    innerRef={formRef}
                    onChange={ ()=>{ valiadateForm()}}
                >
                    <label className='baloa-username'>
                    {props.t('baloaProStripePaymentModal.cardLbl')}
                    <CardNumberElement 
                        options={isMobile? CARD_ELEMENT_OPTIONS_MOBILE : CARD_ELEMENT_OPTIONS}
                        onChange={(event) => {setCardNumberComplete(event.complete); valiadateForm() }}
                    />
                    {/* <TextField
                        InputProps={{
                            inputComponent: myInput, // we are added the mask component here
                        }}
                    /> */}
                    
                    </label>
                    <div className='baloa-pro-stripe-payment-modal__columns'>
                        <label className='baloa-username'>
                            {props.t('baloaProStripePaymentModal.expireDateLbl')}
                            <CardExpiryElement 
                                options={isMobile? CARD_ELEMENT_OPTIONS_MOBILE : CARD_ELEMENT_OPTIONS}
                                onChange={(event) => {setCardExpiryComplete(event.complete); valiadateForm() }}
                            />
                        </label>
                        <label className='baloa-username'>
                            {props.t('baloaProStripePaymentModal.cvcLbl')}
                            <CardCvcElement 
                                options={isMobile? CARD_ELEMENT_OPTIONS_MOBILE : CARD_ELEMENT_OPTIONS}
                                onChange={(event) => {setCardCvcComplete(event.complete); valiadateForm() }}
                            />
                        </label>

                    </div>
                    
                    <InputFieldComponent
                        label={props.t('baloaProStripePaymentModal.nameLbl')}
                        className="baloa-pro-stripe-payment-modal__input"
                        input_id=""
                        field_name="nombre"
                        validationFunction={RequiredValidator}
                        disabled={false}
                        input_type="text"
                        resetError={() => { }}
                        error={{nombre: ''}}
                        message=""
                        onChange={() => {  }}
                        //defaultValue={}
                    />

                    <InputFieldAutocompletePaymentCountries2
                        options={ props.countries }
                        noOptionsText={props.t('baloaProStripePaymentModal.emptyCountryLbl')}
                        optionLabelField={ "name" }
                        optionSelectedField={ "name" }
                        lettercount={ "2" }
                        handleSearchWord ={ ( newCountryValue ) => props.handleSearchCountry( newCountryValue ) }
                        className="baloa-pro-stripe-payment-modal__country-select"
                        label={props.t('baloaProStripePaymentModal.countryLbl')}
                        placeholder=""
                        id="country"
                        field_name="country"
                        validationFunction={ RequiredValidator }
                        resetError={ () => {}}
                        //resetError={ () => props.onResetError( { 'country': "" } )}
                        error={ {country:''} }
                        //value={  }
                        onInputChange={(val)=>{ valiadateForm(val); forceUpdate(); }}
                    />
                </FormComponent>

                <div className='baloa-pro-add-payment-method-modal__buttons'>
                    <PrimaryButtonComponent
                        input_type={'button'}
                        className={'small'}
                        onClick={ ()=>{formRef.current.handleSubmit();} }
                        disabled={!formValid }
                    >
                        <span>{props.t('addPaymentModal.addBtnLbl')}</span>
                    </PrimaryButtonComponent>
                </div>
            </div>
        </Modal2Component>
    )

}

export default withTranslation('baloapro')(BaloaProAddPaymentMethodModalComponent);

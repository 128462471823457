import React, { useState } from 'react';
// Components
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import FormComponent from 'shared/components/form/FormComponent';
import TournamentBasicDFormEditComponent from '../tournament-basic-data-form-edit/TournamentBasicDFormEditComponent';
import TournamentInscriptionFormEditComponent from '../tournament-inscription-form-edit/TournamentInscriptionFormEditComponent';
// Styles
import './TournamentEditBasicDataComponent.styles.css';
//i18
import { withTranslation } from 'react-i18next';

function TournamentEditBasicDataComponentV2(props) {

    const [tournamentPhotoB64, setTournamentPhotoB64] = useState(null);
    const [tournamentPhotoUrl, setTournamentPhotoUrl] = useState(props.selected_edit_tournament?.tournament_photo ?? null);
    const [tournamentLogoB64, setTournamentLogoB64] = useState(null);
    const [tournamentLogoUrl, setTournamentLogoUrl] = useState(props.selected_edit_tournament?.tournament_logo ?? null);
    const [summonRegistrationType, setSummonRegistrationType] = useState(props.selected_edit_tournament.tournament_summon_registration);
    const [registrationType, setRegistrationType] = useState(props.selected_edit_tournament.tournament_registration_type);
    const [tournamentPrizeType, setTournamentPrizeType] = useState(props.selected_edit_tournament.tournament_prize_type);
    const [minTeams, setMinTeams] = useState(props.selected_edit_tournament.min_participant_teams);
    const [maxTeams, setMaxTeams] = useState(props.selected_edit_tournament.max_participant_teams);
    const [minPlayers, setMinPlayers] = useState(props.selected_edit_tournament.min_participant_players);
    const [maxPlayers, setMaxPlayers] = useState(props.selected_edit_tournament.max_participant_players);
    const [materialPrize, setMaterialPrize] = useState(props.selected_edit_tournament.material_prize ?? "");
    const [monetaryPrize, setMonetaryPrize] = useState(props.selected_edit_tournament.monetary_prize ?? "");
    const [registrationTypePerTeam, setRegistrationTypePerTeam] = useState(props.selected_edit_tournament.registration_price_per_team ?? "");
    const [registrationTypePerPlayer, setRegistrationTypePerPlayer] = useState(props.selected_edit_tournament.registration_price_per_player ?? "");
    const [bankAccount, setBankAccount] = useState('');
    const [footballTypeMin, setFootballTypeMin] = useState(0);
    const [footballTypeText, setFootballTypeText] = useState('');
    const [isUnlimited, setIsUnlimited] = React.useState();
    const [teamForm, setTeamForm] = React.useState(); // eslint-disable-line
    const [playerForm, setPlayerForm] = React.useState(); // eslint-disable-line
    const [isTeamForm, setIsTeamForm] = useState(props.selected_edit_tournament.has_team_form ? 'team_form_yes' : 'team_form_no');
    const [isPlayerForm, setIsPlayerForm] = useState(props.selected_edit_tournament.has_player_form ? 'player_form_yes' : 'player_form_no');
    const [newFormatDays, setNewFormatDays] = useState([]); // eslint-disable-line
    const [newFormatTimeDay, setNewFormatTimeDay] = useState([]); // eslint-disable-line
    const formRef = React.useRef();

    const formInitialValues = {
        name: props.selected_edit_tournament.name,
        football_type: props.selected_edit_tournament.football_type,
        category: props.selected_edit_tournament.category,
        tournament_gender: props.selected_edit_tournament.tournament_gender,
        main_country: props.selected_edit_tournament.main_country,
        main_city: props.selected_edit_tournament.main_city,
        start_date: props.selected_edit_tournament.start_date,
        end_date: props.selected_edit_tournament.end_date,
        fair_play_most_points_wins: props.selected_edit_tournament.fair_play_most_points_wins? 'true' : 'false',
        fair_play_initial_points: props.selected_edit_tournament.fair_play_initial_points,
        description: props.selected_edit_tournament.description,
        rules: props.selected_edit_tournament.rules ?? '', 
        tournament_timeday: props.selected_edit_tournament.tournament_timeday,
        tournament_day: props.selected_edit_tournament.tournament_day,
        tournament_type: props.selected_edit_tournament.tournament_type, // End values to BasicDataComponent Form

        tournament_summon_registration: props.selected_edit_tournament.tournament_summon_registration,
        tournament_registration_type: props.selected_edit_tournament.tournament_registration_type,
        tournament_prize_type: props.selected_edit_tournament.tournament_prize_type,
        min_participant_teams: props.selected_edit_tournament.min_participant_teams ?? "",
        max_participant_teams: props.selected_edit_tournament.max_participant_teams ?? "",
        min_participant_players: props.selected_edit_tournament.min_participant_players,
        max_participant_players: props.selected_edit_tournament.max_participant_players,
        material_prize: props.selected_edit_tournament.material_prize ?? "",
        monetary_prize: props.selected_edit_tournament.monetary_prize,
        registration_price_per_team: props.selected_edit_tournament.registration_price_per_team ?? "",
        registration_price_per_player: props.selected_edit_tournament.registration_price_per_player ?? "",
        bank_account: null,

        league_page: props.selected_edit_tournament.league_page,
        tournament_photo: props.selected_edit_tournament.tournament_photo,
        registration_deadline: props.selected_edit_tournament.registration_deadline ?? "",
        tournament_registration_pay: props.selected_edit_tournament.tournament_registration_pay ?? "",

        unlimited_participant_players: props.selected_edit_tournament.unlimited_participant_players,
        various_editions: props.selected_edit_tournament.various_editions,
        various_categories: props.selected_edit_tournament.various_categories,
        tournament_logo: props.selected_edit_tournament.tournament_logo,
        tournament_tag: [{ extra: { atype: "tournament-tag", value: props.selected_edit_tournament.category } }],
        has_player_form: props.selected_edit_tournament.has_player_form,
        has_team_form: props.selected_edit_tournament.has_team_form,
    };

    /* FUNCTIONS OF BASIC DATA COMPONENT - Function for set data image in base64 and URL to basic data information */
    const handleTournamentPhotoB64 = (photo_b64, imageurl) => {
        setTournamentPhotoB64(photo_b64);
        setTournamentPhotoUrl(imageurl);
    };

    const handleTournamentLogoB64 = (logo_b64, imageurl) => {
        setTournamentLogoB64(logo_b64);
        setTournamentLogoUrl(imageurl);
    };

    const setFootbalTypeMinPlayers = (football_type) => {
        setFootballTypeText(football_type);
        switch (football_type) {
            case 'Football_8': {//Football_8
                setFootballTypeMin(5);
                break;
            }
            case 'Football_11': {//Football_11
                setFootballTypeMin(7);
                break;
            }
            case 'Football_7': {//Football_7
                setFootballTypeMin(4);
                break;
            }
            case 'Football_5': {//Football_5
                setFootballTypeMin(3);
                break;
            }
            case 'Football_6': {//Football_6
                setFootballTypeMin(3);
                break;
            }
            case 'Football_9': {//Football_9
                setFootballTypeMin(5);
                break;
            }
            case 'Football_10': {//Football_10
                setFootballTypeMin(6);
                break;
            }
            // es-lint warning, switch without defalut case. from here, always at the bottom of docuemnt 
            // no default
        }
    };
    // END FUNCTIONS OF BASIC DATA COMPONENT 

    // FUNCTIONS OF TOURNAMENT INCRIPTION COMPONENT
    const handleFormCheckBtns = (value, type) => {
        if (type === "summon_registration_type") {
            setSummonRegistrationType(value);
        }
        if (type === "registration_type") {
            setRegistrationType(value);
        }
        if (type === "tournament_prize_type") {
            setTournamentPrizeType(value);
        }
        if (type === "is_player_form") {
            setIsPlayerForm(value);
        }
        if (type === "is_team_form") {
            setIsTeamForm(value);
        }
    };

    const handleParticipantTeams = (value, min_max) => {
        if (min_max === "min_teams") setMinTeams(value)
        else setMaxTeams(value)
    };

    const handlePlayersByTeams = (value, min_max) => {
        if (min_max === "min_players") setMinPlayers(value)
        else setMaxPlayers(value)
    };

    const handlePrizeType = (value) => {
        if (value === 'Material_Prize') {
            setMaterialPrize(true);
            setMonetaryPrize(false);
        }
        if (value === 'Monetary_Prize') {
            setMaterialPrize(false);
            setMonetaryPrize(true);
        }
        if (value === 'No_Prize') {
            setMaterialPrize(false);
            setMonetaryPrize(false);
        }
    };

    const handleRegistrationType = (value, type) => {
        if (type === 'registration_price_per_team') {
            setRegistrationTypePerTeam(value);
        }
        if (type === 'registration_price_per_player') {
            setRegistrationTypePerPlayer(value);
        }
        if (type === 'bank_account') {
            setBankAccount(value);
        }
    };

    const handleUnlimited = (value) => {
        setIsUnlimited(value);
    }
    // END FUNCTIONS OF TOURNAMENT INCRIPTION COMPONENT

    const validationfieldStatusInitialValues = (initialValues_objet) => {
        const statusValues = props.fieldStatusEditing;
        let newFormValues = {};

        for (let key in statusValues) {
            for (let i in initialValues_objet) {
                if (i === key && statusValues[key]) {
                    newFormValues[key] = initialValues_objet[i];
                }
            }
        }
        return newFormValues;
    };

    const sentToEditTournament = (values_form) => {
        //console.log('sentToEditTournament',values_form);
        setFootbalTypeMinPlayers(values_form.football_type);
        const {
            name,
            start_date,
            end_date,
            fair_play_most_points_wins,
            fair_play_initial_points,
            registration_deadline,
            main_country,
            main_city,
            category,
            description,
            rules,
            tournament_gender,
            tournament_summon_registration,
            tournament_type,
            min_participant_teams,
            max_participant_teams,
            min_participant_players,
            max_participant_players,
            tournament_prize_type,
            monetary_prize,
            material_prize,
        } = values_form;
        const new_tournament_data = {
            name,
            start_date,
            end_date,
            fair_play_most_points_wins,
            fair_play_initial_points,
            registration_deadline,
            main_country,
            main_city : props.cities.find((city) => city.name === main_city).id ?? main_city,
            category,
            description,
            rules,
            tournament_gender,
            tournament_summon_registration,
            tournament_type,
            min_participant_teams,
            max_participant_teams,
            min_participant_players,
            max_participant_players,
            tournament_prize_type,
            monetary_prize,
            material_prize,
            tournament_photo: tournamentPhotoB64 ?? '',
            tournament_logo: tournamentLogoB64 ?? '',
            football_type: footballTypeText,
            tournament_day: newFormatDays.length > 0 ? newFormatDays : formInitialValues.tournament_day ,
            tournament_timeday: newFormatTimeDay.length > 0 ? newFormatTimeDay : formInitialValues.tournament_timeday,
            registration_price_per_player: registrationTypePerPlayer,
            registration_price_per_team: registrationTypePerTeam,
        };

        const newValues = JSON.stringify(validationfieldStatusInitialValues(new_tournament_data));
        const formRefCurrenValues = JSON.stringify(validationfieldStatusInitialValues(formInitialValues));

        if (newValues !== formRefCurrenValues) {
            props.editTournamentInfo(validationfieldStatusInitialValues(new_tournament_data));
        } else props.handlebackToSummaryTournament();
    };

    const validationValuesToEdit = () => {
        const initialValues = JSON.stringify(validationfieldStatusInitialValues(formInitialValues));
        const formRefCurrenValues = JSON.stringify(validationfieldStatusInitialValues(formRef.current?.values));
        if (initialValues !== formRefCurrenValues) {
            props.handleOpenConfirmationModal(true);
        } else props.handlebackToSummaryTournament();
    };

    /* const handleSelectDays = (array_days) => {
        const new_days = array_days.map((day) => {
            return { day: day }
        });
        setNewFormatDays(new_days);
    }; */

    /* const handleSelectDayTime = (array_dayTime) => {
        const new_dayTime = array_dayTime.map((day) => {
            return { timeday: day }
        });
        setNewFormatTimeDay(new_dayTime);
    }; */

    return (<div className="tournament-edit-tournament-v2-form">
        <FormComponent
            formInitialValues={formInitialValues}
            onSubmit={(values) => sentToEditTournament(values)}
            className="tournament-create-new-tournament-v2__form form"
            // functionDisabled={(form_props) => { onBasciDataFormProps(form_props) }}
            // enableReinitialize={true}
            innerRef={formRef}>
            <TournamentBasicDFormEditComponent
                errors={props.errors}
                league_page={props.league_page}
                basic_data_info={props.basic_data_info}
                cities={props.cities}
                countries={props.countries}
                disableCities={props.disableCities}
                tournament_tag={props.tournament_tag}
                tournament_football_type={props.tournament_football_type}
                tournament_photo_url={tournamentPhotoUrl}
                tournament_logo_url={tournamentLogoUrl}
                isTournamentLogo={tournamentLogoUrl ? true : false}
                isTournamentPhoto={tournamentPhotoUrl ? true : false}
                disableFormValues={true} // Disable some form values in inputsField
                valuestoEditForm={formInitialValues}
                fieldStatusEditing={props.fieldStatusEditing}
                onResetError={(data) => { props.onResetError(data) }}
                handleSearchNationality={(new_nationality_search) => { props.handleSearchNationality(new_nationality_search) }}
                handleSearchWorldCities={(searched_city) => props.handleSearchWorldCities(searched_city)}
                handleTournamentPhotoB64={(photo_b64, imageurl) => handleTournamentPhotoB64(photo_b64, imageurl)}
                handleTournamentLogoB64={(logo_b64, imageurl) => handleTournamentLogoB64(logo_b64, imageurl)}
                setFootbalTypeMinPlayers={(football_type) => setFootbalTypeMinPlayers(football_type)}
                //handleSelectDays={(array_days) => handleSelectDays(array_days)}
                //handleSelectDayTime={(array_dayTime) => handleSelectDayTime(array_dayTime)}
                list_tournament_types={props.list_tournament_types}
            />

            <TournamentInscriptionFormEditComponent
                inscription_errors={props.inscription_errors}
                summonRegistrationType={summonRegistrationType}
                registrationType={registrationType}
                tournamentPrizeType={tournamentPrizeType}
                minTeams={minTeams}
                maxTeams={maxTeams}
                minPlayers={minPlayers}
                maxPlayers={maxPlayers}
                materialPrize={materialPrize ?? ''}
                monetaryPrize={monetaryPrize ?? ''}
                registrationTypePerTeam={registrationTypePerTeam ?? ''}
                registrationTypePerPlayer={registrationTypePerPlayer ?? ''}
                bankAccount={bankAccount ?? ''}
                footballTypeMin={footballTypeMin ?? ''}
                handleFormCheckBtns={(value, type) => handleFormCheckBtns(value, type)}
                handlePrizeType={(type) => handlePrizeType(type)}
                handleParticipantTeams={(value, min_max) => handleParticipantTeams(value, min_max)}
                handlePlayersByTeams={(value, min_max) => handlePlayersByTeams(value, min_max)}
                handleRegistrationType={(value, type) => { handleRegistrationType(value, type) }}
                handleOpenEditFormModal={props.handleOpenEditFormModal}
                handleUnlimited={(v) => { handleUnlimited(v) }}
                isUnlimited={isUnlimited}
                playerForm={playerForm}
                teamForm={teamForm}
                isTeamForm={isTeamForm}
                isPlayerForm={isPlayerForm}
                disableFormValues={true} // Disable some form values in inputsField
                editTournamentInscription={true}
                valuestoEditForm={formInitialValues}
                fieldStatusEditing={props.fieldStatusEditing}
                currency={props.currency}
            />
            <div className="tournament-edit-tournament-v2-buttons">
                <SecondaryButtonComponent
                    type="button"
                    disabled={false}
                    onClick={() => { validationValuesToEdit() }}
                    className="tournament-edit-basic-data__cancel-btn">
                    <span>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelEditStopEditBtn')}</span>
                </SecondaryButtonComponent>
                <PrimaryButtonComponent
                    type="submit"
                    //disabled={validationChanges}
                    onClick={() => { }}
                    className="edit-basic-data__form__submit">
                    <span>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelEditSaveBasicDataBtn')}</span>
                </PrimaryButtonComponent>
            </div>
        </FormComponent>
    </div>);

};
export default withTranslation('league')(TournamentEditBasicDataComponentV2);


// React
import React, { Component } from 'react';

// Styles
import './CsaeView.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

class CsaeView extends Component {

    render(){
        //console.log(this.props.state);
        return(
            <div className="csae-view">
                <div className="csae-view__container">
                    <div className='baloa-headline-2'>{this.props.t('title')}</div>
                    <ol>
                        <li className='baloa-subtitle-1'>{this.props.t('item1.title')}</li>
                        <div className='baloa-normal full-text'>{this.props.t('item1.text')}</div>
                        <li className='baloa-subtitle-1'>{this.props.t('item2.title')}</li>
                        <div className=''>
                            <ul>
                                <li className='baloa-normal'>{this.props.t('item2.text1')}</li>
                                <li className='baloa-normal'>{this.props.t('item2.text2')}</li>
                                <li className='baloa-normal'>{this.props.t('item2.text3')}</li>
                            </ul>
                        </div>
                        <li className='baloa-subtitle-1'>{this.props.t('item3.title')}</li>
                        <div className=''>
                            <ul>
                                <li className='baloa-normal'>{this.props.t('item3.text1')}</li>
                                <li className='baloa-normal'>{this.props.t('item3.text2')}</li>
                                <li className='baloa-normal'>{this.props.t('item3.text3')}</li>
                            </ul>
                        </div>
                        <li className='baloa-subtitle-1'>{this.props.t('item4.title')}</li>
                        <div className=''>
                            <ul>
                                <li className='baloa-normal'>{this.props.t('item4.text1')}</li>
                                <li className='baloa-normal'>{this.props.t('item4.text2')}</li>
                                <ul>
                                    <li className='baloa-normal link'><a href="https://www.missingkids.org/" target="_blank">{this.props.t('item4.text2_1')}</a></li>
                                    <li className='baloa-normal link'><a href="https://www.iwf.org.uk/" target="_blank">{this.props.t('item4.text2_2')}</a></li>
                                    <li className='baloa-normal'>{this.props.t('item4.text2_3')}</li>
                                </ul>                                
                            </ul>
                        </div>
                        <li className='baloa-subtitle-1'>{this.props.t('item5.title')}</li>
                        <div className=''>
                            <ul>
                                <li className='baloa-normal'>{this.props.t('item5.text1')}</li>
                                <li className='baloa-normal'>{this.props.t('item5.text2')}</li>
                                <li className='baloa-normal'>{this.props.t('item5.text3')}</li>
                            </ul>
                        </div>
                        <li className='baloa-subtitle-1'>{this.props.t('item6.title')}</li>
                        <div className=''>
                            <ul>
                                <li className='baloa-normal'>{this.props.t('item6.text1')}</li>
                                <div className='baloa-normal sec-email'>{this.props.t('item6.text2')}</div>
                            </ul>
                        </div>
                    </ol>
                </div>
            </div>
        )
    }

}

export default withTranslation('csae')(CsaeView)

/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// React device detect
import { isMobile } from 'react-device-detect';

// Styles
import './BaloaPlayMyClipsComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

//Components
import ScrollTrigger from 'react-scroll-trigger';

//Material
import { Skeleton } from '@mui/material';

function BaloaPlayMyClipsComponent( props ) {

    const [activeClip, setActiveClip]= React.useState(undefined);
    
    React.useEffect( () => {
        // Need more pages?
        if(props.my_clips_next_url && (activeClip == props.my_clips[ props.my_clips.length -1 ] || activeClip == props.my_clips[ props.my_clips.length -2 ] ) ) { 
            props.handleOnLoadMoreClips();
        }
    }, [ activeClip ] );// eslint-disable-line

    return(
        <div className='baloa-play-my-clips__container'>
            {props.my_clips?.map((clip,index) =>
                <ScrollTrigger 
                    onEnter = { () => { 
                        setActiveClip( clip );
                    } }
                    onExit = { () => { } }
                    key={clip.id}
                    //className="home-players__player"
                >
                    {!isMobile?
                        <div key={index} className='baloa-play-my-clips__clip-container' onClick={()=>{props.handleOnClip(clip.id)}}>
                            {clip.thumbnail?
                                <img src={ clip.thumbnail } alt={ clip.post_video_caption } />
                                :
                                <Skeleton sx={{ height: 137, borderRadius: 2 }} animation="wave" variant="rectangular" />
                            }                        
                            <div className='baloa-names'>{ clip.post_video_caption }</div>
                            <div className='baloa-play-my-clips__match-data'>
                                <span className='icon-field' />
                                <div className='baloa-table-column'>{clip.post_match_recording_caption}</div>
                            </div>
                        </div>    
                    :
                        <div key={index} className='baloa-play-my-clips__clip-container' onClick={()=>{props.handleOnClip(clip.id)}}>
                            <div className='baloa-names'>{ clip.post_video_caption }</div>                        
                            <div className='baloa-play-my-clips__mobile-data'>
                                {clip.thumbnail?
                                    <img src={ clip.thumbnail } alt={ clip.post_video_caption } />
                                    :
                                    <Skeleton sx={{ height: 79, width: 129, borderRadius: 2 }} animation="wave" variant="rectangular" />
                                }
                                <div className='baloa-play-my-clips__match-data'>
                                    <span className='icon-field' />
                                    <div className='baloa-table-column'>{clip.post_match_recording_caption}</div>
                                </div>
                            </div>
                        </div>    
                    }
                </ScrollTrigger>        
            )}
        </div>
    )
}

export default withTranslation('baloaplay')(BaloaPlayMyClipsComponent);
import React from "react";

//Styles
import "./TournamentTeamInfoComponent.styles.css"

//Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

function TournamentTeamInfoComponent (props) {
    return(
        <div className="tournament-team-info__container">
            {props.photo?
                <img src={props.photo} alt="Baloa team"/>
                :
                <span className="icon-ball" color={getRandonIconBallColor()}/>
            }
            <div className="tournament-team-info__player-data">
                <div className="baloa-normal-medium" >{props.name}</div>
                <div className="baloa-table" >@{props.teamname}</div>
            </div>
        </div>
    )
}

export default TournamentTeamInfoComponent;
/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// React device detect
import { isMobile } from 'react-device-detect';

// Styles
import './BaloaPlayAcceptSharedMatchLinkModalComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

//Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';

function BaloaPlayAcceptSharedMatchLinkModalComponent( props ) {

    return(
        <ModalComponent
            isOpen={props.isOpen}
            onClose={props.onClose}
            className="baloa-play-accept-shared-match-link-modal"
        >
            <div className={`baloa-play-accept-shared-match-link-modal__container `}>
                <span className='hubicon-play_circle' />
                <div className='baloa-normal-medium'>{props.t('acceptSharedMatchLinkModal.title')}</div>
                <div className='baloa-names'>{props.t('acceptSharedMatchLinkModal.message')}</div>
                <div className='baloa-play-accept-shared-match-link-modal__match'>
                    <div className='baloa-play-accept-shared-match-link-modal__match-image'>
                        <img src={props.sharedMatch?.thumbnail} alt=""/>
                    </div>
                    <div className='baloa-play-accept-shared-match-link-modal__match-info'>
                        <div className='baloa-table'>{props.sharedMatch?.match_name}</div>
                        <div className='baloa-play-accept-shared-match-link-modal__match-info__data'>
                            <span className='hubicon-date'/>
                            <div className='baloa-username'>{props.sharedMatch?.match_date}</div>
                        </div>
                        <div className='baloa-play-accept-shared-match-link-modal__match-info__data'>
                            <span className='hubicon-location'/>
                            <div className='baloa-username'>{`${props.sharedMatch?.main_city} - ${props.sharedMatch?.main_country}`}</div>
                        </div>
                        <div className='baloa-play-accept-shared-match-link-modal__match-info__data'>
                            <span className='hubicon-stadium'/>
                            <div className='baloa-username'>{props.sharedMatch?.location}</div>
                        </div>
                    </div>
                </div>
                <div>
                    <PrimaryButtonComponent
                        input_type={'button'}
                        onClick={()=>{props.handleOnAccept()}}
                    >
                        <span>{props.t('acceptSharedMatchLinkModal.acceptBtnLbl')}</span>
                    </PrimaryButtonComponent>
                    <TextButtonComponent
                        input_type={'button'}
                        onClick={()=>{props.onClose()}}
                    >
                        <span>{props.t('acceptSharedMatchLinkModal.closeBtnLbl')}</span>
                    </TextButtonComponent>
                </div>
            </div>
        </ModalComponent>
    )
}

export default withTranslation('baloaplay')(BaloaPlayAcceptSharedMatchLinkModalComponent);
// React
import React, { Component } from 'react'
import { connect } from 'react-redux';
// Redux
import { compose } from 'redux';

// Variables
import { web_url } from "config/GlobalData";

// React device detect
import { isMobile } from 'react-device-detect';

// Styles
import './BaloaPlayView.styles.css';

//Actions
import {
    loadBaloaPlaySubSections,
    searchMatchVideos,
    loadBaloaPlaySubSection,
    loadBaloaPlayMatch,
    matchPayment,
    loadBaloaPlayMyClips,
    loadBaloaPlayClip,
    clipClaim,
    updateClip,
    postClip,
    deletePostClip,
    loadSharedLinkMatchInfo,
    loadBaloaPlayMatchClips,
} from 'app/baloa-play/actions/BaloaPlayActions';

import {
    loadUnauthStripePrices,
} from 'app/baloa-pro/actions/BaloaProActions';

//Actions Creators
import {
    changeBaloaPlayFullVideoModalStateAction,
    changeBaloaPlayPaymentMessageModalStateAction,
    changeBaloaPlayPaymentOptionsModalStateAction,
    changeBaloaPlayClipsModalStateAction,
    changeBaloaPlayClipsVideoModalStateAction,
    //changeBaloaPlayPaymentClipsOptionsModalStateAction,
    changeBaloaPlayClaimClipsOptionsModalStateAction,
    changeBaloaPlayMyClipsVideoModalStateAction,
    changeBaloaPlayUpdateClipModalStateAction,
    changeBaloaPlayAddToPostModalStateAction,
    changeBaloaPlayDeletePresentationVideoModalStateAction,
    changeBaloaProWelcomeModalStateAction,
    changeBaloaPlayUnauthPlayModalStateAction,
    changeBaloaPlayAcceptSharedMatchLinkModalStateAction,
} from 'app/baloa-play/actions/BaloaPlayActionsCreators';
import { changeErrorMessageSnackBar } from 'app/home/actions/HomeActionsCreators';

// Components
import BaloaPlaySearchComponent from '../components/baloa-play-search-component/BaloaPlaySearchComponent';
import BaloaPlaySubsectionComponent from 'app/baloa-play/components/baloa-play-subsection-component/BaloaPlaySubsectionComponent';
import BaloaPlayVideoMatchesComponent from 'app/baloa-play/components/baloa-play-video-matches-component/BaloaPlayVideoMatchesComponent';
import BaloaPlayMatchVideosComponent from 'app/baloa-play/components/baloa-play-match-videos-component/BaloaPlayMatchVideosComponent';
import BaloaPlayClipComponent from '../components/baloa-play-clip-component/BaloaPlayClipComponent';
import BaloaPlayMyClipsComponent from '../components/baloa-play-my-clips-component/BaloaPlayMyClipsComponent';

//modals
import BaloaPlayFullVideoModalComponent from 'app/baloa-play/components/baloa-play-full-video-modal-component/BaloaPlayFullVideoModalComponent';
import BaloaPlayPaymentMessageModalComponent from 'app/baloa-play/components/baloa-play-payment-message-modal-component/BaloaPlayPaymentMessageModalComponent';
import BaloaPlayPaymentOptionsModalComponent from 'app/baloa-play/components/baloa-play-payment-options-modal-component/BaloaPlayPaymentOptionsModalComponent';
import BaloaPlayClipsModalComponent from 'app/baloa-play/components/baloa-play-clips-modal-component/BaloaPlayClipsModalComponent';
import BaloaPlayClipsVideoModalComponent from 'app/baloa-play/components/baloa-play-clips-video-modal-component/BaloaPlayClipsVideoModalComponent';
import BaloaPlayClaimClipsOptionsModalComponent from '../components/baloa-play-claim-clips-options-modal-component/BaloaPlayClaimClipsOptionsModalComponent';
import BaloaPlayMyClipsVideoModalComponent from '../components/baloa-play-my-clips-video-modal-component/BaloaPlayMyClipsVideoModalComponent';
import BaloaPlayUpdateClipModalComponent from '../components/baloa-play-update-clip-modal-component/BaloaPlayUpdateClipModalComponent';
import BaloaPlayAddToPostModalComponent from '../components/baloa-play-add-to-post-modal-component/BaloaPlayAddToPostModalComponent';
import BaloaPlayDeletePresentationPostModalComponent from '../components/baloa-play-delete-presentation-post-modal-component/BaloaPlayDeletePresentationPostModalComponent';
import BaloaPlayProWelcomeModalComponent from '../components/baloa-play-pro-welcome-modal-component/BaloaPlayProWelcomeModalComponent';
import BaloaPlayUnauthPlayModalComponent from '../components/baloa-play-unauth-play-modal/BaloaPlayUnauthPlayModalComponent';
import BaloaPlayAcceptSharedMatchLinkModalComponent from '../components/baloa-play-accept-shared-match-link-modal/BaloaPlayAcceptSharedMatchLinkModalComponent';

// Assets
import explore_pages_empty from 'assets/images/explore_pages_empty.png';
import match_access_denied from 'assets/images/match_access_denied.png';
import empty_my_clips from 'assets/images/empty_my_clips.png'

// i18n
import { withTranslation } from 'react-i18next';


//Material
import { Skeleton } from '@mui/material';

class BaloaPlayView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show_section: '',
            is_search: false,
            selected_video_index: 0,
            match_to_pay: null,
            clip_video_index: 0,
            clip_to_pay: undefined,
            redirect_match: undefined,
            is_featured: false,
            plan_code_id: null,
            accept_link: false,
        }
    }

    async componentDidMount(){
        if(this.props.location.pathname === '/BaloaPlay'){
            this.setState({show_section:'home', is_search: false});
            this.props.loadBaloaPlaySubSections();            
        }else if(this.props.location.pathname === '/BaloaPlay/mis-clips') {
            this.setState({show_section:'misclips', is_search: false});
            await this.props.loadBaloaPlayMyClips(6);
        }else if(this.props.location.pathname === '/BaloaPlay/en-vivo') {
            this.setState({show_section:'envivo', is_search: false});
            if(this.props.state.baloaplay.subsections.length === 0 && !this.props.state.baloaplay.loading_games){
                await this.props.loadBaloaPlaySubSections();
                //await this.props.loadBaloaPlaySubSection( this.props.state.baloaplay.subsections?.find(subsection => subsection.filter_types === 'Live_Matches')?.id, 'Live_Matches', 6);
            }else if (!this.props.state.baloaplay.loading_games){
                if(this.props.state.baloaplay.subsections?.find(subsection => subsection.filter_types === 'Live_Matches')?.id){
                    await this.props.loadBaloaPlaySubSection( this.props.state.baloaplay.subsections?.find(subsection => subsection.filter_types === 'Live_Matches')?.id, 'Live_Matches', 6);
                }
            }
        }else if(this.props.location.pathname === '/BaloaPlay/transmisiones-finalizadas') {
            this.setState({show_section:'transmisionesf', is_search: false});
            if(this.props.state.baloaplay.subsections.length === 0 && !this.props.state.baloaplay.loading_games){
                await this.props.loadBaloaPlaySubSections();
                //await this.props.loadBaloaPlaySubSection( this.props.state.baloaplay.subsections?.find(subsection => subsection.filter_types === 'Recent_Transmissions')?.id, 'Recent_Transmissions', 6);
            }else if (!this.props.state.baloaplay.loading_games){
                if( this.props.state.baloaplay.subsections?.find(subsection => subsection.filter_types === 'Recent_Transmissions').id ){
                    await this.props.loadBaloaPlaySubSection( this.props.state.baloaplay.subsections?.find(subsection => subsection.filter_types === 'Recent_Transmissions').id, 'Recent_Transmissions', 6);
                }
            }
        }
        {/** load match and videos by match_id */}
        if( this.props.match.params.match_id ){
            await this.props.loadBaloaPlayMatch(this.props.match.params?.match_id);
            this.setState({show_section: 'match'});

            //Load BaloaPro Share link modal (after payment)
            let acceptLink = this.props.location.search.split('?accept_link=');
            if(acceptLink.length > 1){
                let plan_code = acceptLink[1].split('plan_code=');
                if( plan_code.length > 1 ){
                    this.props.history.replace({
                        search: ''
                    });
                    this.props.loadSharedLinkMatchInfo( plan_code[1] );
                    this.props.changeBaloaPlayAcceptSharedMatchLinkModalStateAction(true);
                    this.setState( {plan_code_id: plan_code[1]} );
                }
            }
        }

        {/** load clip detail by clip_id */}
        if( this.props.match.params.clip_id ){
            await this.props.loadBaloaPlayClip(this.props.match.params?.clip_id);
            this.setState({show_section: 'clip'});
            if(this.props.location.search !== ''){
                this.setState({redirect_match: this.props.location.search.split('match_id=')[1] })
            }
        }

        //Load from BaloPro subscription
        let prosearch = this.props.location.search.split('?pro=');
        if(prosearch.length > 1 && this.props.state.profile.loggedin?.user?.subscription_plan !== 'FREE' ){
            this.props.history.replace({
                search: ''
            });
            this.props.changeBaloaProWelcomeModalStateAction(true);
        }

        //Load BaloaPro subscription prices
        if(this.props.state.baloapro.stripe_prices?.length <= 0 && !this.props.state.baloapro.loading_uanuth_stripe_prices){
            await this.props.loadUnauthStripePrices();
        }
    }

    async componentDidUpdate(prevProps){

        if(this.props.location.pathname === '/BaloaPlay' && this.state.show_section !== 'home'){
            this.setState({show_section:'home', is_search: false});
            this.props.loadBaloaPlaySubSections();    
        }else if(this.props.location.pathname === '/BaloaPlay/mis-clips' && this.state.show_section !== 'misclips') {
            this.setState({show_section:'misclips', is_search: false});
            if(this.props.state.baloaplay.my_clips.length === 0  && !this.props.state.baloaplay.loading_clips){
                await this.props.loadBaloaPlayMyClips(6);
            }            
        }else if(this.props.location.pathname === '/BaloaPlay/en-vivo' && this.state.show_section !== 'envivo') {
            this.setState({show_section:'envivo', is_search: false});
            if(this.props.state.baloaplay.subsections.length === 0 && !this.props.state.baloaplay.loading_games){
                await this.props.loadBaloaPlaySubSections();
                //await this.props.loadBaloaPlaySubSection( this.props.state.baloaplay.subsections?.find(subsection => subsection.filter_types === 'Live_Matches')?.id, 'Live_Matches', 6);
            }else if (!this.props.state.baloaplay.loading_games){
                if(this.props.state.baloaplay.subsections?.find(subsection => subsection.filter_types === 'Live_Matches')?.id){
                    await this.props.loadBaloaPlaySubSection( this.props.state.baloaplay.subsections?.find(subsection => subsection.filter_types === 'Live_Matches')?.id, 'Live_Matches', 6);
                }                
            }
        }else if(this.props.location.pathname === '/BaloaPlay/transmisiones-finalizadas' && this.state.show_section !== 'transmisionesf') {
            this.setState({show_section:'transmisionesf', is_search: false});
            if(this.props.state.baloaplay.subsections.length === 0 && !this.props.state.baloaplay.loading_games){
                await this.props.loadBaloaPlaySubSections();
                //await this.props.loadBaloaPlaySubSection( this.props.state.baloaplay.subsections?.find(subsection => subsection.filter_types === 'Recent_Transmissions').id, 'Recent_Transmissions', 6);
            }else if (!this.props.state.baloaplay.loading_games){
                if( this.props.state.baloaplay.subsections?.find(subsection => subsection.filter_types === 'Recent_Transmissions').id ){
                    await this.props.loadBaloaPlaySubSection( this.props.state.baloaplay.subsections?.find(subsection => subsection.filter_types === 'Recent_Transmissions').id, 'Recent_Transmissions', 6);
                }
            }
        }

        {/** load match and videos by match_id */}
        if( this.props.match.params?.match_id && (prevProps.match.params?.match_id !== this.props.match.params?.match_id)){
            await this.props.loadBaloaPlayMatch(this.props.match.params?.match_id);
            this.setState({show_section: 'match'})
        }

        {/** load clip detail by clip_id */}
        if( this.props.match.params?.clip_id && (prevProps.match.params?.clip_id !== this.props.match.params?.clip_id)){
            await this.props.loadBaloaPlayClip(this.props.match.params?.clip_id);
            this.setState({show_section: 'clip'})
        }

        //Load BaloaPro subscription prices
        if(this.props.state.baloapro.stripe_prices?.length <= 0 && !this.props.state.baloapro.loading_uanuth_stripe_prices){
            await this.props.loadUnauthStripePrices();
        }
    }

    handleSearchVideo( search ){
        if(search !== ''){
            this.props.searchMatchVideos(search); 
            this.setState({is_search: true})
        }else{
            this.props.loadBaloaPlaySubSections();
            this.setState({is_search: false})
        }
    }

    returnBaloaPlayHome(){
        this.props.history.push('/BaloaPlay')
    }

    handleViewAllSection(section){
        this.props.history.push(`/BaloaPlay/${section}`)
    }

    loadMoreMatches( filter_type, next_url){
        this.props.loadBaloaPlaySubSection(null, filter_type,null,next_url)
    }

    async handleOnMatch( match_id ){
        
        this.props.history.push(`/BaloaPlay/match/${match_id}`)
    }

    handleOnLiveMatch(){
        console.log('Ver video en vivo');
    }

    handlePlayVideo( video_index ){
        /* this.props.changeBaloaPlayFullVideoModalStateAction(true);
        this.setState({selected_video_index: video_index}) */
        this.props.changeBaloaPlayUnauthPlayModalStateAction(true);
    }

    handleOnShareMatch= async (match_id) => {
        try {
            //const url = `${web_url}/BaloaPlay/match/${match_id}`;
            const url = `${web_url}/api/social/baloaplay/${match_id}`;
            await navigator.clipboard.writeText(url);
            this.props.showCopyPostMessage(this.props.t('copyLinkSuccess'));
        } catch (err) {
            this.props.showCopyPostMessage(this.props.t('copyLinkError'));
        }
    }

    handleOnPayMatch( match_id ){
        /* this.setState({match_to_pay: match_id});
        this.props.changeBaloaPlayPaymentMessageModalStateAction(true); */
        this.props.changeBaloaPlayUnauthPlayModalStateAction(true);
    }

    handleOnPlayBlockedMatch( match_id ){
        //this.setState({match_to_pay: match_id});
        this.props.changeBaloaPlayUnauthPlayModalStateAction(true);
    }

    async payMatch(){
        let metadata = {redirect: `BaloaPlay/match/${this.state.match_to_pay}`, mobile: false, payment_type: 'BALOA_PLAY_MATCH_CO' }
        await this.props.matchPayment( this.state.match_to_pay, metadata);
        if(this.props.state.baloaplay?.match_payment_url){
            const newWindow = window.open(this.props.state.baloaplay?.match_payment_url, '_self', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        }
        this.props.changeBaloaPlayPaymentOptionsModalStateAction(false); 
        this.setState({match_to_pay: null})
    }

    handlePlayClip( clip_index ){
        /* this.props.changeBaloaPlayClipsVideoModalStateAction(true);
        this.setState({clip_video_index: clip_index}) */
        this.props.changeBaloaPlayUnauthPlayModalStateAction(true);
    }

    handleOnPayMatchFromClip( match_id ){
        this.setState({match_to_pay: match_id});
        this.props.changeBaloaPlayPaymentOptionsModalStateAction(true);
    }

    handleOnPayClip( clip ){//Claim clip
        //this.props.changeBaloaPlayPaymentClipsOptionsModalStateAction(true);
        this.props.changeBaloaPlayClaimClipsOptionsModalStateAction(true);
        this.setState({clip_to_pay: clip})
    }

    async claimClip(){//Claim clip
        await this.props.clipClaim( this.state.clip_to_pay?.id);         
        this.props.changeBaloaPlayClaimClipsOptionsModalStateAction(false);
        this.props.changeBaloaPlayClipsVideoModalStateAction(false);
        this.handleOnClip(this.state.clip_to_pay?.id, this.props.state.baloaplay.selected_match.id);        
        this.setState({clip_to_pay: undefined});
    }

    async handleOnClip( clip_id, match_id ){
        if(match_id){
            this.props.history.push(`/BaloaPlay/mis-clips/${clip_id}?match_id=${match_id}`)
        }else{
            this.props.history.push(`/BaloaPlay/mis-clips/${clip_id}`)
        }        
    }

    async hanldeUpdateClip( title ){
        await this.props.updateClip( this.props.state.baloaplay.selected_clip?.id, title);
        this.props.changeBaloaPlayUpdateClipModalStateAction(false);
    }

    addToFeatured(){
        this.setState({is_featured: true})
        this.props.changeBaloaPlayAddToPostModalStateAction(true);
    }

    addToPrincipal(){
        this.setState({is_featured: false});
        if(this.props.state.baloaplay.selected_clip?.profile_presentation_clip_id){
            this.props.changeBaloaPlayDeletePresentationVideoModalStateAction(true);
        }else{
            this.props.changeBaloaPlayAddToPostModalStateAction(true);
        }
        
    }

    async publishPost( title ){
        if(this.state.is_featured){
            await this.props.postClip( this.props.state.baloaplay.selected_clip?.id, {'is_profile_clip': true, 'caption': title});
            this.props.changeBaloaPlayAddToPostModalStateAction(false);
        }else{
            await this.props.postClip( this.props.state.baloaplay.selected_clip?.id, {'is_profile_presentation_clip': true, 'caption': title});
            this.props.changeBaloaPlayAddToPostModalStateAction(false);
        }        
    }

    async deletePresentationPost(){
        await this.props.deletePostClip( this.props.state.baloaplay.selected_clip?.profile_presentation_clip_id, this.props.state.baloaplay.selected_clip?.id );
        this.props.changeBaloaPlayDeletePresentationVideoModalStateAction(false);
    }

    subscribeAndReturnToMatch(){
        this.props.history.push(`/BaloaPro?match_id=${this.props.state.baloaplay.selected_match.id}`)
    }

    handleLogin(accesLink){
        if(accesLink){
            this.props.history.push(`/cuenta/ingresar?BaloaPlay/match/${this.props.state.baloaplay.selected_match.id}/?accept_link=true&plan_code=${this.state.plan_code_id}`)
        }else{
            this.props.history.push(`/cuenta/ingresar?BaloaPlay/match/${this.props.state.baloaplay.selected_match.id}`)
        }

    }

    handleOnBaloaProSubscription = ( ) => {
        this.props.history.push(`/BaloaPro`);
    }

    async handleViewAllClips(){
        await this.props.loadBaloaPlayMatchClips(this.props.state.baloaplay.selected_match?.post_clip_recording_id,9)
        this.props.changeBaloaPlayClipsModalStateAction(true);
    }

    render() {

        return (
            <div className='baloa-play-view__container'>
                {this.state.show_section === 'home' && this.state.show_section !== 'clip' && this.state.show_section !== 'misclips' &&
                    <React.Fragment>
                        <BaloaPlaySearchComponent
                            handleOnSearchVideo={(search)=>{ this.handleSearchVideo(search)}}
                            handleOnMyClips={()=>{ this.handleViewAllSection('mis-clips')}}
                            requestRecording={()=>{ this.props.changeBaloaPlayUnauthPlayModalStateAction(true) }}//TODO: No se ha desarrollado la funcionalidad
                            unauth={true}
                        />
                        {this.props.state.baloaplay.loading_games && !isMobile && !this.state.is_search &&
                            <div className='baloa-play-view__skeleton-container'>
                                {[...Array(Number(6))].map((e, i) => 
                                    <div className='baloa-play-view__skeleton-container__element'>
                                        <Skeleton animation="wave" variant='rect' component={'div'} width='100%' height={'140px'} sx={{borderRadius: 'inherit',}}/>
                                        <div className='baloa-play-view__skeleton-container__element__image-line'>
                                            <Skeleton animation="wave" variant='circular' component={'div'} width='35px' height={'35px'} />
                                            <Skeleton animation="wave" component={'div'} width='70%' />
                                        </div>
                                        <div className='baloa-play-view__skeleton-container__element__text-lines'>
                                            <Skeleton animation="wave" component={'div'} width='70%' />
                                            <Skeleton animation="wave" component={'div'} width='50%' />
                                            <Skeleton animation="wave" component={'div'} width='30%' />
                                        </div>
                                    </div>
                                )}
                            </div>
                        }
                        {this.props.state.baloaplay.loading_games && isMobile && !this.state.is_search &&
                            <div className='baloa-play-view__skeleton-container'>
                                {[...Array(Number(4))].map((e, i) => 
                                    <div className='baloa-play-view__skeleton-container__element'>
                                        <Skeleton animation="wave" variant='rect' component={'div'} width='120px' height={'80px'} sx={{borderRadius: 'inherit',}}/>        
                                        <div className='baloa-play-view__skeleton-container__element__text-lines'>
                                            <Skeleton animation="wave" component={'div'} width='90%' />
                                            <Skeleton animation="wave" component={'div'} width='70%' />
                                            <Skeleton animation="wave" component={'div'} width='50%' />
                                        </div>
                                    </div>
                                )}
                            </div>
                        }
                        {!this.props.state.baloaplay.loading_games && !this.state.is_search &&
                            this.props.state.baloaplay.subsections_data?.map( subsection =>//Validar el orden de las secciones o fijar
                                /* TODO: se deshabilita mientras se impleneta la seccion de videos en vivo
                                <BaloaPlaySubsectionComponent
                                    seccion={ subsection }
                                    handleViewAllSection={ (section)=>{ this.handleViewAllSection(section)} }//TODO: implementar en componente anidado
                                /> */
                                //Lo siguiente se debe eliminar cuendo se implemente la seccion de videos en vivo //TODO: revisar vista no autenticada en esta implementacion
                                <React.Fragment>
                                    <div className='baloa-play-subsection__container'>
                                        <div className='baloa-play-subsection__title' >
                                            <div className='baloa-subtitle-1'>{this.props.t(`filter_types.${subsection.filter_type}`)}</div>
                                            {this.props.state.user.me?.username &&
                                                <div className='baloa-subtitle-1 green' onClick={()=>{ this.handleViewAllSection('transmisiones-finalizadas')}}>{this.props.t('viewAllLbl')}</div>
                                            }
                                        </div>
                                    </div>
                                    <BaloaPlayVideoMatchesComponent
                                        match_videos={subsection.matches}
                                        is_live_video={false}
                                        handleOnMatch={( match_id )=>this.handleOnMatch( match_id )}
                                    />
                                </React.Fragment>
                                //Fin de lo siguiente
                            )
                        }
                        {this.props.state.baloaplay.search_match_videos_loading && !isMobile &&
                            <div className='baloa-play-view__skeleton-container'>
                                {[...Array(Number(3))].map((e, i) => 
                                    <div className='baloa-play-view__skeleton-container__element'>
                                        <Skeleton animation="wave" variant='rect' component={'div'} width='100%' height={'140px'} sx={{borderRadius: 'inherit',}}/>
                                        <div className='baloa-play-view__skeleton-container__element__image-line'>
                                            <Skeleton animation="wave" variant='circular' component={'div'} width='35px' height={'35px'} />
                                            <Skeleton animation="wave" component={'div'} width='70%' />
                                        </div>
                                        <div className='baloa-play-view__skeleton-container__element__text-lines'>
                                            <Skeleton animation="wave" component={'div'} width='70%' />
                                            <Skeleton animation="wave" component={'div'} width='50%' />
                                            <Skeleton animation="wave" component={'div'} width='30%' />
                                        </div>
                                    </div>
                                )}
                            </div>
                        }
                        {this.props.state.baloaplay.search_match_videos_loading && isMobile &&
                            <div className='baloa-play-view__skeleton-container'>
                                {[...Array(Number(4))].map((e, i) => 
                                    <div className='baloa-play-view__skeleton-container__element'>
                                        <Skeleton animation="wave" variant='rect' component={'div'} width='120px' height={'80px'} sx={{borderRadius: 'inherit',}}/>        
                                        <div className='baloa-play-view__skeleton-container__element__text-lines'>
                                            <Skeleton animation="wave" component={'div'} width='90%' />
                                            <Skeleton animation="wave" component={'div'} width='70%' />
                                            <Skeleton animation="wave" component={'div'} width='50%' />
                                        </div>
                                    </div>
                                )}
                            </div>
                        }
                        {!this.props.state.baloaplay.search_match_videos_loading && this.props.state.baloaplay.search_match_videos?.length > 0 && this.state.is_search &&
                            <BaloaPlayVideoMatchesComponent
                                match_videos={this.props.state.baloaplay.search_match_videos}
                                is_live_video={false}
                                handleOnMatch={( match_id )=>this.handleOnMatch( match_id )}//TODO: validar que sucede con los videos en vivo
                            />
                        }
                        {!this.props.state.baloaplay.search_match_videos_loading && this.props.state.baloaplay.search_match_videos?.length === 0 && this.state.is_search &&
                            <div className='baloa-play-view__empty-search-results'>
                                <img src={explore_pages_empty} alt='NO results'/>
                                <div className='baloa-subtitle-2'>{this.props.t('emptySearchLbl')}</div>
                            </div>
                        }
                    </React.Fragment>
                }
                {/**Seccion Mis Clips */}
                {this.state.show_section === 'misclips' &&
                    <React.Fragment>
                        <div className='baloa-play-view__section-header'>
                            {//!isMobile &&
                                <span className='icon-arrow-left' onClick={()=>{ this.returnBaloaPlayHome()}}/>
                            }                            
                            <div className='baloa-headline-2'>{this.props.t(`menu.myClipsLbl`)}</div>
                            {/* isMobile &&
                                <span className='hubicon-clear_option' onClick={()=>{ this.returnBaloaPlayHome()}}/> */
                            } 
                        </div>
                        {this.props.state.baloaplay.loading_clips && !isMobile &&
                            <div className='baloa-play-view__skeleton-container-clips'>
                                {[...Array(Number(6))].map((e, i) => 
                                    <div className='baloa-play-view__skeleton-container__element-clips'>
                                        <Skeleton animation="wave" variant='rect' component={'div'} width='100%' height={'137px'} sx={{borderRadius: 'inherit',}}/>        
                                        <div className='baloa-play-view__skeleton-container__element__text-lines-clips'>
                                            <Skeleton animation="wave" component={'div'} width='90%' />
                                            <Skeleton animation="wave" component={'div'} width='10%' />
                                            <Skeleton animation="wave" component={'div'} width='50%' />
                                        </div>
                                    </div>
                                )}
                            </div>
                        }
                        {this.props.state.baloaplay.loading_clips && isMobile &&
                            <div className='baloa-play-view__skeleton-container-clips'>
                                {[...Array(Number(3))].map((e, i) => 
                                    <div className='baloa-play-view__skeleton-container__element-clips'>
                                        <Skeleton animation="wave" component={'div'} width='80%' />
                                        <div className='baloa-play-view__skeleton-container__element__text-lines-clips'>
                                            <Skeleton animation="wave" variant='rect' component={'div'} width='129px' height={'79px'} sx={{borderRadius: 'inherit',}}/>        
                                            <Skeleton animation="wave" component={'div'} width='60%' height={'20px'}/>
                                        </div>
                                    </div>
                                )}
                            </div>
                        }
                        {!this.props.state.baloaplay.loading_clips && this.props.state.baloaplay.my_clips?.length === 0 &&
                            <div className='baloa-play-view__empty-my-clips'>
                                <img src={empty_my_clips} alt='NO clips'/>
                                <div className='baloa-subtitle-2'>{this.props.t('emptyMyClipsMsg')}</div>
                            </div>
                        }
                        {!this.props.state.baloaplay.loading_clips && this.props.state.baloaplay.my_clips?.length > 0 &&
                            <BaloaPlayMyClipsComponent
                                my_clips={ this.props.state.baloaplay.my_clips }
                                handleOnClip={ (clip_id)=>{ this.handleOnClip(clip_id) }}                                
                            />
                        }
                    </React.Fragment>
                }
                {/**Seccion En Vivo */}
                {this.state.show_section === 'envivo' &&
                    <React.Fragment>
                        <div className='baloa-play-view__section-header'>
                            {!isMobile &&
                                <span className='icon-arrow-left' onClick={()=>{ this.returnBaloaPlayHome()}}/>
                            }                            
                            <div className='baloa-headline-2'>{this.props.t(`filter_types.Live_Matches`)}</div>
                            {isMobile &&
                                <span className='hubicon-clear_option' onClick={()=>{ this.returnBaloaPlayHome()}}/>
                            } 
                        </div>
                        {this.props.state.baloaplay.loading_games &&
                            <div className='baloa-play-view__skeleton-container'>
                                {[...Array(Number(3))].map((e, i) => 
                                    <div className='baloa-play-view__skeleton-container__element'>
                                        <Skeleton animation="wave" variant='rect' component={'div'} width='100%' height={'140px'} sx={{borderRadius: 'inherit',}}/>
                                        <div className='baloa-play-view__skeleton-container__element__image-line'>
                                            <Skeleton animation="wave" variant='circular' component={'div'} width='35px' height={'35px'} />
                                            <Skeleton animation="wave" component={'div'} width='70%' />
                                        </div>
                                        <div className='baloa-play-view__skeleton-container__element__text-lines'>
                                            <Skeleton animation="wave" component={'div'} width='70%' />
                                            <Skeleton animation="wave" component={'div'} width='50%' />
                                            <Skeleton animation="wave" component={'div'} width='30%' />
                                        </div>
                                    </div>
                                )}
                            </div>
                        }
                        {this.props.state.baloaplay.loading_games && isMobile &&
                            <div className='baloa-play-view__skeleton-container'>
                                {[...Array(Number(4))].map((e, i) => 
                                    <div className='baloa-play-view__skeleton-container__element'>
                                        <Skeleton animation="wave" variant='rect' component={'div'} width='120px' height={'80px'} sx={{borderRadius: 'inherit',}}/>        
                                        <div className='baloa-play-view__skeleton-container__element__text-lines'>
                                            <Skeleton animation="wave" component={'div'} width='90%' />
                                            <Skeleton animation="wave" component={'div'} width='70%' />
                                            <Skeleton animation="wave" component={'div'} width='50%' />
                                        </div>
                                    </div>
                                )}
                            </div>
                        }
                        {!this.props.state.baloaplay.loading_games &&
                            <BaloaPlayVideoMatchesComponent
                                match_videos={this.props.state.baloaplay.subsections_data?.find(subsection => subsection.filter_type === 'Live_Matches')?.matches}
                                next_url={this.props.state.baloaplay.subsections_data?.find(subsection => subsection.filter_type === 'Live_Matches')?.next_url}
                                is_live_video={true}
                                handleOnLoadMore={(next_url)=>{ this.loadMoreMatches('Live_Matches', next_url)}}
                                handleOnMatch={( match_id )=>this.handleOnLiveMatch( match_id )}
                            />
                        }
                    </React.Fragment>
                }
                {/**Seccion Transmisiones finalizadas */}
                {this.state.show_section === 'transmisionesf' &&
                    <React.Fragment>
                        <div className='baloa-play-view__section-header'>
                            {!isMobile &&
                                <span className='icon-arrow-left' onClick={()=>{ this.returnBaloaPlayHome()}}/>
                            } 
                            <div className='baloa-headline-2'>{this.props.t(`filter_types.Recent_Transmissions`)}</div>
                            {isMobile &&
                                <span className='hubicon-clear_option' onClick={()=>{ this.returnBaloaPlayHome()}}/>
                            } 
                        </div>
                        {this.props.state.baloaplay.loading_games && !isMobile &&
                            <div className='baloa-play-view__skeleton-container'>
                                {[...Array(Number(3))].map((e, i) => 
                                    <div className='baloa-play-view__skeleton-container__element'>
                                        <Skeleton animation="wave" variant='rect' component={'div'} width='100%' height={'140px'} sx={{borderRadius: 'inherit',}}/>
                                        <div className='baloa-play-view__skeleton-container__element__image-line'>
                                            <Skeleton animation="wave" variant='circular' component={'div'} width='35px' height={'35px'} />
                                            <Skeleton animation="wave" component={'div'} width='70%' />
                                        </div>
                                        <div className='baloa-play-view__skeleton-container__element__text-lines'>
                                            <Skeleton animation="wave" component={'div'} width='70%' />
                                            <Skeleton animation="wave" component={'div'} width='50%' />
                                            <Skeleton animation="wave" component={'div'} width='30%' />
                                        </div>
                                    </div>
                                )}
                            </div>
                        }
                        {this.props.state.baloaplay.loading_games && isMobile &&
                            <div className='baloa-play-view__skeleton-container'>
                                {[...Array(Number(4))].map((e, i) => 
                                    <div className='baloa-play-view__skeleton-container__element'>
                                        <Skeleton animation="wave" variant='rect' component={'div'} width='120px' height={'80px'} sx={{borderRadius: 'inherit',}}/>        
                                        <div className='baloa-play-view__skeleton-container__element__text-lines'>
                                            <Skeleton animation="wave" component={'div'} width='90%' />
                                            <Skeleton animation="wave" component={'div'} width='70%' />
                                            <Skeleton animation="wave" component={'div'} width='50%' />
                                        </div>
                                    </div>
                                )}
                            </div>
                        }
                        {!this.props.state.baloaplay.loading_games &&
                            <BaloaPlayVideoMatchesComponent
                                match_videos={this.props.state.baloaplay.subsections_data?.find(subsection => subsection.filter_type === 'Recent_Transmissions')?.matches}
                                next_url={this.props.state.baloaplay.subsections_data?.find(subsection => subsection.filter_type === 'Recent_Transmissions')?.next_url}
                                is_live_video={false}
                                handleOnLoadMore={(next_url)=>{ this.loadMoreMatches('Recent_Transmissions', next_url)}}
                                handleOnMatch={( match_id )=>this.handleOnMatch( match_id )}
                            />
                        }
                    </React.Fragment>                    
                }
                {/** Reproduccion de videos de partido */}
                {this.state.show_section === 'match' &&
                    <React.Fragment>
                        <div className='baloa-play-view__section-header'>
                            {!isMobile &&
                                <span className='icon-arrow-left' onClick={()=>{ this.returnBaloaPlayHome()}}/>
                            }                            
                            {/* <div className='baloa-headline-2'>{this.props.t(`filter_types.Recent_Transmissions`)}</div> */}
                            {isMobile &&
                                <span className='hubicon-clear_option' onClick={()=>{ this.returnBaloaPlayHome()}}/>
                            }
                        </div>
                        {this.props.state.baloaplay.is_match_hidden?
                            <div className='baloa-play-view__matc-acces-denied'>
                                <img src={match_access_denied} alt='match access denied'/>
                                <div className='baloa-normal-medium'>{this.props.t('matchAccessDeniedSubtitle')}</div>
                                <div className='baloa-names'>{this.props.t('matchAccessDeniedMsg')}</div>
                            </div>
                            :
                            <BaloaPlayMatchVideosComponent
                                selected_match={ this.props.state.baloaplay.selected_match }
                                handleOnPlayVideo={ (video_index)=>{this.handlePlayVideo( video_index )}}
                                handleOnShareMatch={ (match_id)=>{this.handleOnShareMatch(match_id)} }
                                match_clips={ this.props.state.baloaplay.match_clips }
                                handleOnPayMatch={( match_id )=>{ this.handleOnPayMatch(match_id) }}
                                handleOnPlayBlockedMatch={( match_id )=>{ this.handleOnPlayBlockedMatch(match_id) }}
                                handleViewAllClips={ ()=>{ this.handleViewAllClips() } }
                                handleOnPlayClip={ (clip_index)=>{this.handlePlayClip( clip_index ) }}
                                handleOnBaloaProSubscription={()=>{ this.handleOnBaloaProSubscription() }}
                                baloa_pro_month_price={ this.props.state.baloapro.stripe_prices?.find(price => price.metadata?.recurring?.interval === 'month')? this.props.state.baloapro.stripe_prices?.find(price => price.metadata?.recurring?.interval === 'month').value : ''}
                                baloa_pro_month_currencye={ this.props.state.baloapro.stripe_prices?.find(price => price.metadata?.recurring?.interval === 'month')? this.props.state.baloapro.stripe_prices?.find(price => price.metadata?.recurring?.interval === 'month').currency : ''}
                            />
                        }
                        
                    </React.Fragment>
                }
                {/** Detalle de clip */}
                {this.state.show_section === 'clip' &&
                    <React.Fragment>
                        <div className='baloa-play-view__section-header'>
                            {//!isMobile &&
                                <span className='icon-arrow-left' onClick={()=>{ this.state.redirect_match? this.handleViewAllSection(`match/${this.state.redirect_match}`) : this.handleViewAllSection('mis-clips')}}/>
                            }                            
                            {/* isMobile &&
                                <span className='hubicon-clear_option' onClick={()=>{ this.state.redirect_match? this.handleViewAllSection(`match/${this.state.redirect_match}`) : this.handleViewAllSection('mis-clips')}}/> */
                            }
                        </div>
                        <BaloaPlayClipComponent
                            clip={this.props.state.baloaplay.selected_clip}
                            handleOnMatch={(match_id)=>{ this.handleOnMatch(match_id)}}
                            handleOnPlayClip={()=>{ this.props.changeBaloaPlayMyClipsVideoModalStateAction(true)}}
                            handleOnEditClip={()=>{ this.props.changeBaloaPlayUpdateClipModalStateAction(true)}}
                            handleAddToPrincipal={()=>{ this.addToPrincipal()}}
                            handleAddToFeatured={()=>{ this.addToFeatured() }}
                            handleOnSubscribe={()=>{ console.log('suscribirse a baloa pro y regresar al clip');}}//TODO: suscribirse a baloa pro y regresar al clip
                        />
                    </React.Fragment>
                }

                {/** Modals */}
                {/** Match video modal */}
                <BaloaPlayFullVideoModalComponent
                    isOpen={ this.props.state.baloaplay.modals.full_video_is_open }
                    onClose={ ()=>{this.props.changeBaloaPlayFullVideoModalStateAction(false)} }
                    match_video={this.props.state.baloaplay.selected_match?.assets[this.state.selected_video_index]}
                    handleOnPlayVideo={ (video_index)=>{this.handlePlayVideo( video_index )}}
                    selected_video_index={this.state.selected_video_index}
                    assets_number={this.props.state.baloaplay.selected_match?.assets}
                />

                {/** Match payment modal message */}
                <BaloaPlayPaymentMessageModalComponent  
                    isOpen={ this.props.state.baloaplay.modals.payment_message_is_open }
                    onClose={ ()=>{ this.props.changeBaloaPlayPaymentMessageModalStateAction(false); this.setState({match_to_pay: null}) } }
                    match_recording_price={ this.props.state.baloaplay.selected_match?.baloa_match_recording_price }
                    handleOnPay={ ()=>{ this.props.changeBaloaPlayPaymentOptionsModalStateAction(true); this.props.changeBaloaPlayPaymentMessageModalStateAction(false); } }
                    handleOnBaloaProSubscription={()=>{ this.handleOnBaloaProSubscription() }}
                    baloa_pro_month_price={ this.props.state.baloapro.stripe_prices?.find(price => price.metadata?.recurring?.interval === 'month')? this.props.state.baloapro.stripe_prices?.find(price => price.metadata?.recurring?.interval === 'month').value : ''}
                    baloa_pro_month_currencye={ this.props.state.baloapro.stripe_prices?.find(price => price.metadata?.recurring?.interval === 'month')? this.props.state.baloapro.stripe_prices?.find(price => price.metadata?.recurring?.interval === 'month').currency : ''}
                />

                {/** Match payment options modal */}
                <BaloaPlayPaymentOptionsModalComponent  
                    isOpen={ this.props.state.baloaplay.modals.payment_options_is_open }
                    onClose={ ()=>{ this.props.changeBaloaPlayPaymentOptionsModalStateAction(false); this.setState({match_to_pay: null}) } }
                    match_recording_price={ this.props.state.baloaplay.selected_match?.baloa_match_recording_price }
                    handleOnPay={ ()=>{ this.payMatch() } }
                    handleOnSubscribe={ ()=>{ this.subscribeAndReturnToMatch() } }
                />

                {/** Accept shared match link modal */}
                <BaloaPlayAcceptSharedMatchLinkModalComponent
                    isOpen={ this.props.state.baloaplay.modals.accept_shared_math_link_is_open }
                    onClose={ ()=>{ this.props.changeBaloaPlayAcceptSharedMatchLinkModalStateAction(false); this.setState( {plan_code_id: null} );} }
                    sharedMatch={ this.props.state.baloaplay.shared_link_match_info }
                    handleOnAccept={ ()=>{ this.props.changeBaloaPlayUnauthPlayModalStateAction(true); this.setState( {accept_link: true} ); } }
                />
                
                {/** Match clips modal */}
                <BaloaPlayClipsModalComponent
                    isOpen={ this.props.state.baloaplay.modals.clips_is_open }
                    onClose={ ()=>{this.props.changeBaloaPlayClipsModalStateAction(false)} }
                    match_clips={ this.props.state.baloaplay.match_clips }
                    selected_match={ this.props.state.baloaplay.selected_match }
                    match_is_blocked={ this.props.state.baloaplay.selected_match?.is_blocked }
                    handleOnPayMatch={( match_id )=>{ this.handleOnPayMatch(match_id) }}
                    handleOnPlayClip={ (clip_index)=>{this.handlePlayClip( clip_index ) }}
                    handleOnBaloaProSubscription={()=>{ this.handleOnBaloaProSubscription() }}
                    baloa_pro_month_price={ this.props.state.baloapro.stripe_prices?.find(price => price.metadata?.recurring?.interval === 'month')? this.props.state.baloapro.stripe_prices?.find(price => price.metadata?.recurring?.interval === 'month').value : ''}
                    baloa_pro_month_currencye={ this.props.state.baloapro.stripe_prices?.find(price => price.metadata?.recurring?.interval === 'month')? this.props.state.baloapro.stripe_prices?.find(price => price.metadata?.recurring?.interval === 'month').currency : ''}
                    match_clips_next_url={this.props.state.baloaplay.match_clips_next_url}
                    loadMoreClips={ (next_url)=>{ this.props.loadBaloaPlayMatchClips(null,null,next_url) } }
                />

                {/** Clips view modal */}
                <BaloaPlayClipsVideoModalComponent
                    isOpen={ this.props.state.baloaplay.modals.clips_video_is_open }
                    onClose={ ()=>{this.props.changeBaloaPlayClipsVideoModalStateAction(false)} }
                    clip_video={this.props.state.baloaplay.match_clips[this.state.clip_video_index]}
                    handleOnPlayClip={ (clip_video_index)=>{this.handlePlayClip( clip_video_index )}}
                    selected_video_index={this.state.clip_video_index}
                    match_is_blocked={ this.props.state.baloaplay.selected_match?.is_blocked }
                    is_baloa_pro={ this.props.state.baloaplay.selected_match?.user?.subscription_plan !== 'FREE' }
                    selected_match={ this.props.state.baloaplay.selected_match }
                    clips_number={this.props.state.baloaplay.match_clips?.length}
                    handleOnPayMatchFromClip={ ( )=>{ this.handleOnPayMatchFromClip( this.props.state.baloaplay.selected_match?.id ) } }
                    handleOnPayClip={ (clip)=>{ this.handleOnPayClip(clip) } }
                    handleOnBaloaProSubscription={()=>{ this.handleOnBaloaProSubscription() }}
                    baloa_pro_month_price={ this.props.state.baloapro.stripe_prices?.find(price => price.metadata?.recurring?.interval === 'month')? this.props.state.baloapro.stripe_prices?.find(price => price.metadata?.recurring?.interval === 'month').value : ''}
                    baloa_pro_month_currency={ this.props.state.baloapro.stripe_prices?.find(price => price.metadata?.recurring?.interval === 'month')? this.props.state.baloapro.stripe_prices?.find(price => price.metadata?.recurring?.interval === 'month').currency : ''}
                />

                {/** Clips payment options modal */}
                {/* <BaloaPlayPaymentClipsOptionsModalComponent  
                    isOpen={ this.props.state.baloaplay.modals.payment_clips_options_is_open }
                    onClose={ ()=>{ this.props.changeBaloaPlayPaymentClipsOptionsModalStateAction(false); this.setState({clip_to_pay: null}) } }
                    clip_recording_price={ this.state.clip_to_pay?.baloa_clip_recording_price }
                    handleOnPay={ ()=>{ this.payClip() } }
                    handleOnSubscribe={ ()=>{ } }
                /> */}

                {/** Clips claim options modal */}
                <BaloaPlayClaimClipsOptionsModalComponent
                    isOpen={ this.props.state.baloaplay.modals.claim_clips_options_is_open }
                    onClose={ ()=>{ this.props.changeBaloaPlayClaimClipsOptionsModalStateAction(false); this.setState({clip_to_pay: null}) } }
                    handleOnClaim={ ()=>{ this.claimClip()} }
                    claim_clips_loading={ this.props.state.baloaplay.claim_clips_loading}
                />

                {/** Clip video view*/}
                <BaloaPlayMyClipsVideoModalComponent
                    isOpen={ this.props.state.baloaplay.modals.my_clips_video_is_open }
                    onClose={ ()=>{this.props.changeBaloaPlayMyClipsVideoModalStateAction(false)} }
                    clip_video={this.props.state.baloaplay.selected_clip}
                />

                {/** Edit clip title */}
                <BaloaPlayUpdateClipModalComponent
                    isOpen={ this.props.state.baloaplay.modals.update_clip_is_open }
                    onClose={ ()=>{this.props.changeBaloaPlayUpdateClipModalStateAction(false)} }
                    clip_title={this.props.state.baloaplay.selected_clip?.post_video_caption}
                    handleOnSave={ ( title )=>{ this.hanldeUpdateClip(title)} }
                />

                {/** Add clip to post presentation or featured */}
                <BaloaPlayAddToPostModalComponent
                    isOpen={ this.props.state.baloaplay.modals.add_to_post_is_open }
                    onClose={ ()=>{this.props.changeBaloaPlayAddToPostModalStateAction(false)} }
                    clip_video={this.props.state.baloaplay.selected_clip}
                    is_featured={this.state.is_featured}
                    handleOnPublish={ (title)=>{ this.publishPost(title)} }
                />

                {/** Delete presentation clip alert modal */}
                <BaloaPlayDeletePresentationPostModalComponent
                    isOpen={ this.props.state.baloaplay.modals.delete_post_is_open }
                    onClose={ ()=>{this.props.changeBaloaPlayDeletePresentationVideoModalStateAction(false)} }
                    handleOnDelete={ ()=>{ this.deletePresentationPost() } }
                />

                {/** Baloa Pro Welcome Modal baloa_pro_welcome_is_open */}
                <BaloaPlayProWelcomeModalComponent 
                    isOpen={ this.props.state.baloaplay.modals.baloa_pro_welcome_is_open }
                    onClose={ ()=>{this.props.changeBaloaProWelcomeModalStateAction(false)} }
                />

                <BaloaPlayUnauthPlayModalComponent
                    isOpen={ this.props.state.baloaplay.modals.baloa_play_unauth_play_is_open }
                    onClose={ ()=>{this.props.changeBaloaPlayUnauthPlayModalStateAction(false)} }
                    handleLogin={()=>{this.handleLogin(this.state.accept_link)}}
                />

            </div>
        )
    }
  
}
  
const mapStateToProps = state => {
    return {
      state
    }
  }
  
const mapDispatchToProps = dispatch => {
    return {
        loadBaloaPlaySubSections: async (  ) => {
            await dispatch( loadBaloaPlaySubSections(  ))
        },
        searchMatchVideos:( search ) => {
            dispatch( searchMatchVideos( search ))
        },
        loadBaloaPlaySubSection: async (id_subsection, filter_type, pager, next_value) => {
            await dispatch( loadBaloaPlaySubSection( id_subsection, filter_type, pager, next_value ))
        },
        loadBaloaPlayMatch: async (match_id) => {
            await dispatch( loadBaloaPlayMatch( match_id ))
        },
        changeBaloaPlayFullVideoModalStateAction: (new_state)=> {
            dispatch( changeBaloaPlayFullVideoModalStateAction( new_state))
        },
        showCopyPostMessage: (copymessage) => {
            return dispatch( changeErrorMessageSnackBar( {
                message: copymessage,
                error_message_is_open: true
            } ) );
        },
        changeBaloaPlayPaymentMessageModalStateAction: (new_state)=> {
            dispatch( changeBaloaPlayPaymentMessageModalStateAction( new_state))
        },
        changeBaloaPlayPaymentOptionsModalStateAction: (new_state)=> {
            dispatch( changeBaloaPlayPaymentOptionsModalStateAction( new_state))
        },
        matchPayment: async ( match_id, metadata )=> {
            await dispatch( matchPayment( match_id, metadata ))
        },
        changeBaloaPlayClipsModalStateAction: (new_state)=> {
            dispatch( changeBaloaPlayClipsModalStateAction( new_state))
        },
        changeBaloaPlayClipsVideoModalStateAction: (new_state)=> {
            dispatch( changeBaloaPlayClipsVideoModalStateAction( new_state))
        },
        /* changeBaloaPlayPaymentClipsOptionsModalStateAction: (new_state)=> {
            dispatch( changeBaloaPlayPaymentClipsOptionsModalStateAction( new_state))
        }, */
        loadBaloaPlayMyClips: async ( page_size ) => {
            await dispatch( loadBaloaPlayMyClips( page_size ))
        },
        loadBaloaPlayClip: async (clip_id) => {
            await dispatch( loadBaloaPlayClip( clip_id ))
        },
        changeBaloaPlayClaimClipsOptionsModalStateAction: (new_state)=> {
            dispatch( changeBaloaPlayClaimClipsOptionsModalStateAction( new_state))
        },
        clipClaim: async (clip_id) => {
            await dispatch( clipClaim( clip_id ))
        },
        changeBaloaPlayMyClipsVideoModalStateAction: (new_state)=> {
            dispatch( changeBaloaPlayMyClipsVideoModalStateAction( new_state))
        },
        updateClip: async (clip_id, title) => {
            await dispatch( updateClip( clip_id, title ))
        },
        changeBaloaPlayUpdateClipModalStateAction: (new_state)=> {
            dispatch( changeBaloaPlayUpdateClipModalStateAction( new_state))
        },
        changeBaloaPlayAddToPostModalStateAction: (new_state)=> {
            dispatch( changeBaloaPlayAddToPostModalStateAction( new_state))
        },
        postClip: async (clip_id, body) => {
            await dispatch( postClip( clip_id, body ))
        },
        changeBaloaPlayDeletePresentationVideoModalStateAction: (new_state)=> {
            dispatch( changeBaloaPlayDeletePresentationVideoModalStateAction( new_state))
        },
        deletePostClip: async (post_id, clip_id ) => {
            await dispatch( deletePostClip( post_id, clip_id ))
        },
        changeBaloaProWelcomeModalStateAction: (new_state)=> {
            dispatch( changeBaloaProWelcomeModalStateAction( new_state))
        },
        changeBaloaPlayUnauthPlayModalStateAction: (new_state)=> {
            dispatch( changeBaloaPlayUnauthPlayModalStateAction( new_state))
        },
        loadUnauthStripePrices: () =>{
            dispatch (loadUnauthStripePrices());
        },
        changeBaloaPlayAcceptSharedMatchLinkModalStateAction: (new_state)=> {
            dispatch( changeBaloaPlayAcceptSharedMatchLinkModalStateAction( new_state))
        },
        loadSharedLinkMatchInfo: async ( plan_code_id, match_id ) => {
            await dispatch( loadSharedLinkMatchInfo( plan_code_id, match_id ))
        },
        /* acceptBaloaPlaySharedLinkMatch: async ( plan_code_id, match_id ) => {
            await dispatch( acceptBaloaPlaySharedLinkMatch( plan_code_id, match_id ))
        }, */
        loadBaloaPlayMatchClips: ( post_clip_recording_id, page_size, next_page )=> {
            dispatch( loadBaloaPlayMatchClips( post_clip_recording_id, page_size, next_page ))
        },
    }
}

export default compose(
        withTranslation("baloaplay"),
        connect(mapStateToProps, mapDispatchToProps)
    )(BaloaPlayView);
// React
import React, { Component } from 'react'
import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
// import {compose} from 'redux';

// Styles
import './TermsAndConditionsView.styles.css';

// Actions
import { getTermsAndConditionsAction } from 'app/authentication/actions/LoginActions';

// Components
import TermsComponent from 'app/terms-and-conditions/components/terms/TermsComponent';

class TermsAndConditionsView extends Component {

    constructor( props ) {
        super(props);
        this.username = this.props.match.params.username;
    }

    componentDidMount() {
        this.props.getTermsAndConditionsAction();
    }
    
    render() {
        
        return(
            <div className="terms-and-conditions">
                <TermsComponent
                    terms={this.props.state.signup.terms_conditions}
                />

            </div>
        )
    }
};

const mapStateToProps = state => {
    return {
        state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getTermsAndConditionsAction:( ) => {
            return dispatch( getTermsAndConditionsAction())
        },
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditionsView);

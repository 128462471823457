// React
import React from 'react'

// Components
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// Styles
import './ProfileTournamentVictoriesComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

function ProfileTournamentVictoriesComponent( props ) {

    return (
        <div className="profile-tournament-victories__container">
            <div className="profile-tournament-victories__title">
                <div className="baloa-subtitle-2">{props.t('tournamentSummary.labelAchievements')}</div>
                <div className='profile-tournament-victories__tooltip'>
                    <div className='baloa-table-column'>{props.t('tournamentSummary.toottip')}</div>
                    <span className='hubicon-help_fill'/>
                </div>
            </div>
            <div className='profile-tournament-victories__table' >
                <div className='profile-tournament-victories__table-header'>
                    <div className='baloa-normal-medium'>{props.t('tournamentSummary.labelTournament')}</div>
                    <div className='baloa-normal-medium'>{props.t('tournamentSummary.labelPosition')}</div>                    
                </div>
                {props.tournaments?.length > 0
                ?   <div className='profile-tournament-victories__table-body'>
                    { props.tournaments.map((tournament, index) => (
                        <div key={`${tournament.tournament_id}__${index}`} className='profile-tournament-victories__table-body__container'>                            
                            <div className='profile-tournament-victories__table-body-col'>
                                <div className='profile-tournament-victories__table-body-col__img'>
                                    { tournament.tournament_logo
                                        ? <img src={tournament.tournament_logo} alt={`${tournament.tournament_name} logo`} />
                                        : <span className='icon-ball' color={getRandonIconBallColor()} />
                                    }
                                </div>
                                <div className='profile-tournament-victories__table-body-col__info'>
                                    <div className='baloa-normal-medium' >{tournament.tournament_name}</div>
                                    <div className='profile-tournament-victories__table-body-col__info__labels'>
                                        <div className='baloa-table-column'>{props.t(`tournamentSummary.footballType.${tournament.tournament_football_type}`)}</div>
                                        <div className='baloa-table-column'>{props.t(`tournamentSummary.gender.${tournament.tournament_gender}`)}</div>
                                        <div className='baloa-table-column'>{tournament.tournament_category}</div>
                                    </div>
                                </div>
                            </div>                            
                            <div className={`profile-tournament-victories__table-body-col baloa-normal-medium ${!tournament.player_has_paid ? 'locked_values' : ''}`} >{tournament.position === 'ND' ? tournament.position : `${props.t(`tournamentSummary.labelsTournamentsAchievements.${tournament.position}`)} (${tournament.tournament_year})`}</div>
                        </div>
                    ))}
                    </div>
                :''
                }
            </div>
            { props.tournaments?.length > 0 
                ? props.profile_tournament_paid 
                    ? <div className='profile-tournament-victories__unlock-values__action'>
                        <div className='profile-tournament-victories__unlock-values__text'>
                            
                            {props.is_owner && 
                                <div className='baloa-normal-medium'>
                                    <span className='hubicon-award_star'/>
                                    {/* props.t('tournamentSummary.blockedText') */}
                                    {props.t('tournamentSummary.subscribeLbl')} 
                                </div>
                            }
                            {!props.is_owner && 
                                <div className='baloa-normal-medium'>
                                    <span className='hubicon-info' />
                                    {props.t('tournamentSummary.visitantBlockedText', {user_name: props.username})}
                                </div>}
                        </div>
                        { props.is_owner && <PrimaryButtonComponent onClick={() => {props.handleModalUnlockData()}} >{props.t('tournamentSummary.subscribeBtnLbl')}</PrimaryButtonComponent> }
                    </div>
                    : ''
                : <div className='profile-tournament-victories__unlock-values__action'>
                    <div className='profile-tournament-victories__unlock-values__text'>
                        <span className='hubicon-info' />
                        <div className='baloa-normal-medium'>
                            { props.is_owner 
                                ? props.t('tournamentSummary.blockedEmptyDataProfileVitories')
                                : props.t('tournamentSummary.visitantEmptyDataTournament', {user_name: props.username})
                            }
                        </div>
                    </div>
            </div>
            }

            {props.tournaments?.length > 0 &&
                <div className='profile-tournament-victories__view-all baloa-normal-medium' onClick={() => {props.handleOnSummaryViewAll(props.summary_type)}}>{props.t('tournamentSummary.labelViewAll')}</div>
            }
        </div>
    )
}

export default withTranslation('profile')(ProfileTournamentVictoriesComponent);


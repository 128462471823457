import React, { useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// Components
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

// Styles
import './TournamentPhasesDropDown.styles.css'

//i18
import { withTranslation } from 'react-i18next';


function TournamentPhasesDropDownComponent(props) {
    const [nameSelectedPhase, setNameSelectedPhase] = useState(props.tournament_active_phase?.id ?? '');
    const [isBoxOpen, setBoxOpen] = useState(false); //eslint-disable-line
    const [boxPosition, setBoxPosition] = useState(false); //eslint-disable-line

    useEffect(() => {
        if (props.tournament_phases?.length > 0) {
            if(props.referees_payment_params.type && props.referees_payment_params.type === 'referee') {
                let aux_phases = props.tournament_phases?.filter(phase => phase.id === props.referees_payment_params.phase_id);
                onClickPhaseTab(aux_phases[0]);
                setNameSelectedPhase(aux_phases[0]?.id);
            } else {
                let aux_phases = props.tournament_phases?.filter(phase => phase.is_last_active_phase === true);
                if (aux_phases.length === 0) {
                    onClickPhaseTab(props.tournament_phases[props.tournament_phases.length - 1]);
                    setNameSelectedPhase(props.tournament_phases[props.tournament_phases.length - 1]?.id);
                } else {
                    onClickPhaseTab(aux_phases[0]);
                    setNameSelectedPhase(aux_phases[0]?.id);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.tournament_phases]);


    const onClickPhaseTab = (phase) => {
        props.handleOnClickPhaseTab(phase, 'tabCalendar');
        if (phase.state !== 'Finalized') {
            props.finishedMatchesByPhase(phase);
        }
    };


    /* const handleOnBoxOpen = (index) => {
        if (!isBoxOpen) {
            setBoxPosition(index)
            setBoxOpen(true)
        } else {
            setBoxPosition()
            setBoxOpen(false)
        }
    }; */

    return (
        // <div className={`league-tournament-report__phases__list tournament-phases ${props.showEndPhaseMenu ? 'end-phase' : ''}`}>
        <div className='tournament-phases-drop-down__container'>
            <FormControl >
                <Select
                    value={nameSelectedPhase ? nameSelectedPhase : props.nameActivePhase}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    variant='standard'
                >
                    {props.tournament_phases?.filter(phase => phase.phase_type !== null).map((phase, index) => (
                        <MenuItem
                            key={`Phase ${index + 1}`}
                            value={phase.id}
                            onClick={() => {
                                props.handleOnClickPhaseTab(phase, 'tabCalendar');
                                props.finishedMatchesByPhase(phase);
                                setNameSelectedPhase(phase.id);
                            }}
                        >
                            <div className='league-tournament-report__phases__items'>
                                <div>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelPhase')}
                                    {index + 1} - {props.t(`options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.${phase.phase_name}`)}
                                </div>
                            </div>
                        </MenuItem>
                    )
                    )
                    }
                </Select>
            </FormControl>
            <div className='tournament-phases__phase-options__container'>
                {/* { !props.showEndPhaseMenu && <span className='icon-options' onClick={() => { handleOnBoxOpen(true) }} />} */}
                <div className={`tournament-phases-information_options-box ${boxPosition ? "active" : ""}`}>
                    {/* {props.showEndPhaseMenu &&
                        <div className='tournament-phases-information_options-box__option'
                            onClick={() => {
                                props.handleOnClickFinishPhase(props.tournament_active_phase);
                                setBoxOpen(false);
                            }}>
                            <div className='baloa-names'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelEndPhase')}</div>
                        </div>
                    } */}
                    {props.showDeletePhaseMenu &&
                        <div className='tournament-phases-information_options-box__option'
                            onClick={() => {
                                props.deleteTournamentPhase(props.tournament_active_phase, props.tournament_phases.findIndex(phase  => phase.id === props.tournament_active_phase?.id));
                                setBoxOpen(false);
                            }}>
                            <div className='baloa-names'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelDeletePhase')}</div>
                        </div>
                    }
                    {props.showAddTeams &&
                        <div className='tournament-phases-information_options-box__option'
                            onClick={() => {
                                props.AddTeamToGroupModal();
                            }}>
                            <div className='baloa-names'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelTeams')}</div>
                        </div>
                    }
                    {props.showEditPhaseMenu &&
                        <div className='tournament-phases-information_options-box__option'
                            onClick={() => {
                                props.editTournamentPhaseV2(props.selected_tournament?.id, props.tournament_active_phase);
                            }}>
                            <div className='baloa-names'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelEditPhase')}</div>
                        </div>
                    }

                </div>
            </div>
            {props.selected_tournament.state !== 'Finalized' &&
                <div className='tournament-phases-drop-down__btns-action' >                
                    <div className='tournament-phases-drop-down__btns-action__unfinished-matches'>
                        { props.unfinished_matches &&
                            <div className='tournament-phases-drop-down__btns-action__unfinished-matches__tooltip baloa-table-column'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.textEndCurrentPhaseTooltip')}</div>
                        }
                        {props.tournament_active_phase?.state === 'Finalized'?
                            <SecondaryButtonComponent 
                                disabled={!props.tournament_active_phase?.can_reopen_phase}
                                onClick={() => {props.handleOnClickReopenPhase(props.tournament_active_phase.id)}} 
                            >
                                {props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelReOpenPhase')}
                            </SecondaryButtonComponent>
                        :
                            <SecondaryButtonComponent 
                                disabled={(props.unfinished_matches || props.tournament_active_phase?.state === 'Finalized')}
                                onClick={() => {props.handleOnClickFinishPhase(props.tournament_active_phase)}} 
                            >
                                {props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelEndCurrentPhase')}
                            </SecondaryButtonComponent>
                        }
                        
                    </div>
                    <PrimaryButtonComponent onClick={ () => {props.handleOnClickNewPhase(true)} }>
                        <span className='hubicon-add_option' />
                        <div>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelNewPhase')}</div>
                    </PrimaryButtonComponent>
                </div>
            }
            
        </div>
    )
}

export default withTranslation('league')(TournamentPhasesDropDownComponent);
// React
import React from 'react';

// Material UI
import { withStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Link } from 'react-router-dom';

// Components
import TableHeaderActions from './TableHeaderActions'
import TablePaginationActions from './TablePagination'
import TableHeaderButtonsActionsComponent from './TableHeaderButtonsActionsComponent'
import TeamVSTeamPhotosComponent from './TeamVSTeamPhotosComponent'
import TableMatchScoreComponent from './TableMatchScoreComponent'
import PlayerColumnComponent from './PlayerColumnComponent'
import EditRoleComponent from './EditRoleComponent'
import TeamColumnComponent from './TeamColumnComponent'

// Styles
import './TableComponent.styles.css';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// i18n
import { withTranslation } from 'react-i18next';



const StyledTableCell = withStyles((theme) => ({
  
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    
}))(TableRow);


 function TableComponent(props) {
  
  const [page, setPage] = React.useState(0);    
  const [currentSort, setCurrentSort] = React.useState('asc');
  const [currentColumnSort, setCurrentColumnSort] = React.useState(-1);
  const [currentFilter, setCurrentFilter] = React.useState('');

  const { rows, columns, columns_headers, has_image_column, image_column, rows_per_page, has_searchbar, has_add_button,
        pagination, action_column, search_by, searchbar_by, placeholder_search_bar, order_by, has_button_filter, add_button_caption, 
        filter_buttons_captions, filter_buttons_states, title_caption, message, action_link = true, action_buttons = false, actions_component, 
        image_vs_image = false, photo_1_column = null, photo_2_column = null, team = false, enable_add_match_button = false, // eslint-disable-line
         hasToolTipMessageInAddButton = false, itemClicLabel, image_column2, image_column3, has_autofixtures_button, autofixtures_button_caption, 
         enable_autofixtures_button, has_optional_button, optional_button_caption, has_html, action_modal_buttons, link_column, has_player_column ,player_column, 
         edit_row, edit_row_column, edit_row_id, handleRolChange, has_team_column = false, team_column, toggle_header_buttons = false, button_icon, 
         optional_button_icon, link_row, show_only_header_title} = props;

  const [rowsPerPage, setRowsPerPage] = React.useState(rows_per_page); // eslint-disable-line
  const [rowsCount, setRowsCount] = React.useState(rows ? rows.length : 0 );
  const [rowsData, setRowsData] = React.useState(rows ? rows.sort((a, b) => (a[order_by] > b[order_by]) ? 1 : -1) : []); // eslint-disable-line
  const [rowsDataFilter, setRowsDataFilter] = React.useState(rows ? rows.slice(0, rows_per_page) : []);
  const [newSearch, setNewSearch] = React.useState(false);    
  const [isOrdered, setIsOrdered] = React.useState(false);
  const [menuOptions, setMenuOptions] = React.useState([false]);

  /* eslint-disable */
  React.useEffect(() => {
      setRowsDataFilter(rows ? rows.slice(0, rows_per_page) : []);
  }, [props]);
  
  React.useEffect(() => {
    setRowsCount(rows ? rows.length : 0);
    setRowsData(rows ? rows.sort((a, b) => (a[order_by] > b[order_by]) ? 1 : -1) : []);
  }, [rows]);
 /* eslint-enable */

  const handleChangePage = (event) => {
    setPage(parseInt(event.target.text) - 1);
    currentFilter 
      ? currentSort.currentSort
        ? currentSort.currentSort === "asc"
          ?
            setRowsDataFilter(rowsData
            .filter(function (el) { return el[search_by].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(currentFilter.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) } )
            .sort((a, b) => b[columns[currentColumnSort]].localeCompare(a[columns[currentColumnSort]]))
            .slice((parseInt(event.target.text) - 1) * rowsPerPage, (parseInt(event.target.text) - 1) * rowsPerPage + rowsPerPage))
          : 
            setRowsDataFilter(rowsData.filter(function (el) { return el[search_by].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(currentFilter.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) } )
            .sort((a, b) => a[columns[currentColumnSort]].localeCompare(b[columns[currentColumnSort]]))
            .slice((parseInt(event.target.text) - 1) * rowsPerPage, (parseInt(event.target.text) - 1) * rowsPerPage + rowsPerPage))
        :   
          setRowsDataFilter(rowsData.filter(function (el) { return el[search_by].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(currentFilter.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) } )
          .slice((parseInt(event.target.text) - 1) * rowsPerPage, (parseInt(event.target.text) - 1) * rowsPerPage + rowsPerPage))
      :  
        currentSort.currentSort
        ? currentSort.currentSort === "asc"
          ?
            setRowsDataFilter(rowsData.sort((a, b) => b[columns[currentColumnSort]].localeCompare(a[columns[currentColumnSort]]))
            .slice((parseInt(event.target.text) - 1) * rowsPerPage, (parseInt(event.target.text) - 1) * rowsPerPage + rowsPerPage))
          : 
            setRowsDataFilter(rowsData.sort((a, b) => a[columns[currentColumnSort]].localeCompare(b[columns[currentColumnSort]]))
            .slice((parseInt(event.target.text) - 1) * rowsPerPage, (parseInt(event.target.text) - 1) * rowsPerPage + rowsPerPage))
        :   
          setRowsDataFilter(rowsData.slice((parseInt(event.target.text) - 1) * rowsPerPage, (parseInt(event.target.text) - 1) * rowsPerPage + rowsPerPage));
            
  };

  const handleButtonSearchClick = (filterData) => {       
    
    
    setPage(0);    
    let currentFilter = filterData.toLowerCase();
    currentFilter = currentFilter === "todos" ? "" : currentFilter;
    setCurrentFilter(currentFilter);

    currentSort.currentSort 
      ? currentSort.currentSort === "asc" 
        ? 
          setRowsDataFilter(rows.filter(function (el) { return el[search_by]?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(currentFilter.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) } ) 
          .sort((a, b) => b[columns[currentColumnSort]].localeCompare(a[columns[currentColumnSort]]))
          .slice(0 * rowsPerPage, 0 * rowsPerPage + rowsPerPage))
        :
          setRowsDataFilter(rows.filter(function (el) { return el[search_by]?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(currentFilter.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) } )
          .sort((a, b) => a[columns[currentColumnSort]].localeCompare(b[columns[currentColumnSort]]))
          .slice(0 * rowsPerPage, 0 * rowsPerPage + rowsPerPage))
      :  
        setRowsDataFilter(rows.filter(function (el) { return el[search_by]?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(currentFilter.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) } )
        .slice(0 * rowsPerPage, 0 * rowsPerPage + rowsPerPage));

    setRowsCount(rows.filter(function (el) { return el[search_by]?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(currentFilter.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) } ).length);
    setNewSearch(true);
  };

  const handleSearchClick = (event) => {                
    setPage(0);    
    let currentFilter = event.target.value.toLowerCase();
    setCurrentFilter(currentFilter);

    currentSort.currentSort 
      ? currentSort.currentSort === "asc" 
        ? 
          setRowsDataFilter(rows.filter(function (el) { return el[searchbar_by].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(currentFilter.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) } ) 
          .sort((a, b) => b[columns[currentColumnSort]].localeCompare(a[columns[currentColumnSort]]))
          .slice(0 * rowsPerPage, 0 * rowsPerPage + rowsPerPage))
        :
          setRowsDataFilter(rows.filter(function (el) { return el[searchbar_by].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(currentFilter.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) } )
          .sort((a, b) => a[columns[currentColumnSort]].localeCompare(b[columns[currentColumnSort]]))
          .slice(0 * rowsPerPage, 0 * rowsPerPage + rowsPerPage))
      :  
        setRowsDataFilter(rows.filter(function (el) { return el[searchbar_by].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(currentFilter.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) } )
        .slice(0 * rowsPerPage, 0 * rowsPerPage + rowsPerPage));

    setRowsCount(rows.filter(function (el) { return el[searchbar_by].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(currentFilter.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) } ).length);
    setNewSearch(true);
  };
	
	const onSortChange = (column, index) => {
    
    setPage(0);
    setIsOrdered(true);
    setCurrentColumnSort(index);

		let nextSort;

    try{

      if (currentSort.currentSort === 'asc') nextSort = 'desc';
      else nextSort = 'asc';
      
      if(nextSort === 'asc'){   
        if(currentFilter.length > 0){
          setRowsDataFilter(rows.sort((a, b) => b[columns[index]].localeCompare(a[columns[index]]))
          .filter(function (el) { return el[search_by].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(currentFilter.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) } )
          .slice(0 * rowsPerPage, 0 * rowsPerPage + rowsPerPage));
        }else{
          setRowsDataFilter(rows.sort((a, b) => b[columns[index]].localeCompare(a[columns[index]]))
          .slice(0 * rowsPerPage, 0 * rowsPerPage + rowsPerPage));
        }
      }
      else{
        if(currentFilter.length > 0){
          setRowsDataFilter(rows.sort((a, b) => a[columns[index]].localeCompare(b[columns[index]]))
          .filter(function (el) { return el[search_by].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(currentFilter.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) } )
          .slice(0 * rowsPerPage, 0 * rowsPerPage + rowsPerPage));
        }else{
          setRowsDataFilter(rows.sort((a, b) => a[columns[index]].localeCompare(b[columns[index]]))   
          .slice(0 * rowsPerPage, 0 * rowsPerPage + rowsPerPage));
        }
      }

      setCurrentSort({
        currentSort: nextSort
      });

    }catch(error){
      console.log("Ordenando tabla " + error);
    }

	};

  const handleRowClick = (event, selrow) => {     
    props.handleOnActionClick(selrow);
  };

  const handleAddAction = () => {
    props.handleOnAddButtonClick();
  };

  const handleMenuOptions = (index) =>{
    let newMenuOptions = Object.assign([], menuOptions);
    newMenuOptions[index] = !menuOptions[index];
    setMenuOptions(newMenuOptions);
  }

  return (
      <div>

        {pagination && has_button_filter &&
          <TableHeaderButtonsActionsComponent
            onSearch={ handleButtonSearchClick }                        
            search_by = { search_by }                                    
            has_add_button = { has_add_button }
            enable_add_match_button = { enable_add_match_button }
            add_button_caption = { add_button_caption }
            filter_buttons_captions = { filter_buttons_captions } 
            filter_buttons_states = { filter_buttons_states }                        
            title_caption = { title_caption }            
            handleOnAddButtonClick = { handleAddAction }
            rowsData = {rowsDataFilter.length}
            rowsMatches = { rows.length }
            message = { message }
            hasToolTipMessageInAddButton = { hasToolTipMessageInAddButton }
            has_autofixtures_button = { has_autofixtures_button }
            autofixtures_button_caption = { autofixtures_button_caption } 
            enable_autofixtures_button = { enable_autofixtures_button }
            handleOnAutomaticFixture = { props.handleOnAutomaticFixture }

            onSearchBar={ handleSearchClick } 
            placeholder = { placeholder_search_bar }
            has_searchbar = { has_searchbar }
            has_optional_button={ has_optional_button } 
            optional_button_caption={ optional_button_caption }
            handleOnOptionalButtonClick = { props.handleOnOptionalButtonClick }
            show_only_header_title={show_only_header_title} //Exclusivo para mostrar el titulo en el header buttons actions (has_add_button = false)
          >
          </TableHeaderButtonsActionsComponent>
        }
          
        {pagination && !has_button_filter &&
          <TableHeaderActions
            onSearch={ handleSearchClick }            
            page={ page }
            placeholder = { placeholder_search_bar }
            search_by = { search_by }                        
            has_searchbar = { has_searchbar }
            has_add_button = { has_add_button }
            add_button_caption = { add_button_caption }  
            handleOnAddButtonClick = { props.handleOnAddButtonClick }  
            has_optional_button={ has_optional_button } 
            optional_button_caption={ optional_button_caption }
            handleOnOptionalButtonClick = { props.handleOnOptionalButtonClick }
            toggle_header_buttons={toggle_header_buttons}
            button_icon={button_icon}
            optional_button_icon={optional_button_icon}
          >
          </TableHeaderActions>
        }        

        <TableContainer className="table-component__table-container">
            <Table className="component__table" size="medium" aria-label="custom pagination table">
                <TableHead>
                <TableRow>
                    {columns_headers.map((column, index) => (

                         image_column !== index  ? 
                            <StyledTableCell className="table-headers__sort" 
                                key={index}                              
                                onClick={ () => onSortChange(column, index) }
                              >
                              {column}  {image_column === index || !isOrdered ? "" : currentColumnSort === index ? currentSort.currentSort === 'asc' ? "▼" : "▲" : ""}                     
                            </StyledTableCell> 
                            :
                            <StyledTableCell className="table-headers__no-sort"  key={index}>
                                
                            </StyledTableCell>


                    ))}
                    {action_column && action_link &&
                      <StyledTableCell className="table-headers__no-sort">
                      </StyledTableCell>
                    }  
                    {action_column && action_buttons &&
                      <StyledTableCell className="table-headers__no-sort">
                      </StyledTableCell>
                    }
                    {action_column && action_modal_buttons &&
                      <StyledTableCell className="table-headers__no-sort">                        
                      </StyledTableCell>
                    }                     
                </TableRow>
                </TableHead>
                
                <TableBody>

                    { props.rows?.length !== 0 && rowsDataFilter.map((row, rindex) => (
                        <StyledTableRow key={rindex} className={'table-row ' +( row.id === edit_row_id? 'editing':'') + (link_row? 'link':'')}  onClick={ link_row? (event) => { handleRowClick( event, row )} :undefined  }>
                            {columns.map((column, index) => (
                                <StyledTableCell className={'table-cell '  + (index===link_column? 'link':'') }  key={index}  onClick={ index===link_column? (event) => { handleRowClick( event, row )} :undefined  } >  
                                    { has_image_column && index === image_column && !image_vs_image &&
                                      <div>
                                        { row[column.toLocaleLowerCase()] &&
                                            <img src={ row[column.toLocaleLowerCase()] } alt={`${row.pagename} img`} />
                                        }                                      
                                        { !row[column.toLocaleLowerCase()] &&
                                            <span className="icon-ball" color={getRandonIconBallColor()} />
                                        }
                                      </div>
                                    }

                                    {/* { has_image_column && index === image_column && image_vs_image &&
                                      <TeamVSTeamPhotosComponent
                                        photo_1 = { row[photo_1_column.toLocaleLowerCase()] }
                                        photo_2 = { row[photo_2_column.toLocaleLowerCase()] }
                                      ></TeamVSTeamPhotosComponent> 
                                    }                    */}
                                    
                                    { has_image_column && index === image_column && image_vs_image &&
                                      <TeamVSTeamPhotosComponent
                                        photo_1 = { row[photo_1_column.toLocaleLowerCase()] } photoOnly = {true}
                                      ></TeamVSTeamPhotosComponent>
                                    }

                                    { has_image_column && index === image_column2 && image_vs_image &&
                                      <TableMatchScoreComponent
                                        local_team_penalty_score = {row.local_team_penalty_score}
                                        local_team_score = {row.local_team_score}
                                        visitant_team_penalty_score = {row.visitant_team_penalty_score}
                                        visitant_team_score = {row.visitant_team_score}
                                        match_state = { row.state }
                                      />
                                    }

                                    {  has_image_column && index === image_column3 && image_vs_image &&
                                      <TeamVSTeamPhotosComponent
                                        photo_2 = { row[photo_2_column.toLocaleLowerCase()] } photoOnly = {true}
                                      ></TeamVSTeamPhotosComponent>
                                    }

                                    {has_player_column && index === player_column && row.full_name && (row.profile || row.username) &&
                                      <PlayerColumnComponent
                                        row = {row}
                                      />
                                    }

                                    {has_team_column && index === team_column && row.name && row.teamname &&
                                      <TeamColumnComponent
                                        row = {row}
                                      />
                                    }

                                    {edit_row && edit_row_column === index && row.id === edit_row_id &&
                                      <EditRoleComponent
                                        row = {row}
                                        handleRolChange = {handleRolChange}
                                      />
                                    }

                                    { index !== image_column && index !== player_column && index !== team_column && row.id !== edit_row_id &&(
                                      has_html?
                                        <div dangerouslySetInnerHTML={{__html: row[column.toLocaleLowerCase()]}}></div>
                                      :
                                      row[column.toLocaleLowerCase()]
                                    )}

                                </StyledTableCell>
                            ))}

                            {action_column && action_link &&

                              <StyledTableCell align="right" className="table-cell">
                                <Link onClick={ (event) => { handleRowClick( event, row ) } }>
                                  <div className="team__action-link baloa-hyperlink-2">{ itemClicLabel? itemClicLabel : props.t('viewMore')}</div>
                                </Link>
                              </StyledTableCell>

                            }

                            {action_column && action_modal_buttons &&

                            <StyledTableCell align="right" className="table-cell table-option-buttons">
                              <span className='table-row-menu-options__icon icon-options' onClick={ ()=>{handleMenuOptions(rindex)}}/>
                              <div className={'table-row-menu-options ' + (menuOptions[rindex]? '':'hide')}>
                                 { React.cloneElement(actions_component, {row: row, onHandleMenuOptions: ()=>{handleMenuOptions(rindex)} }) }
                              </div>
                            </StyledTableCell>  

                            }

                            {action_column && action_buttons && (row.state || row.role_state) &&

                              <StyledTableCell align="right" className="table-cell">
                                  { React.cloneElement(actions_component, {row: row, edit_row_id: edit_row_id}) }
                              </StyledTableCell>

                            }
                        
                        </StyledTableRow>
                    ))}
                    
                </TableBody>   
                
                 
        
            </Table>
            { props.rows?.length === 0 &&                   
                <div className='table-body__empty-table-msg'>
                    <h5 className='baloa-headline-5'>{props.msg_empty_table_title}</h5>
                    <p className='baloa-body-1'>{props.msg_empty_table_text}</p>
                </div>                                             
            }
        </TableContainer>
        
        {pagination &&          
          <TablePaginationActions
            onChangePage={handleChangePage}
            count={rowsCount}
            rowsPerPage={rowsPerPage}
            page={page}
            newSearch = { newSearch }
          >
          </TablePaginationActions>
        }

      </div>
    
  );
}

export default withTranslation('common')(TableComponent);
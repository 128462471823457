import React from 'react';

//Components
import ModalComponent from 'shared/components/modal/ModalComponent';

//Styles
import './TournamentPhaseDeleteExtraSanctionConfirmationModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function TournamentPhaseDeleteExtraSanctionConfirmationModalComponent(props) {

    return (
        <ModalComponent
            isOpen={props.isOpen}
            ///onClose={props.onClose}
            className='delete-extra-sanction-confirmation-modal'
        >
            <div className='baloa-names'>{props.t('options.tournaments.pages.newTournament.tabs.tabPunctuationAndFairPlay.confirmDeleteExtraSanctionModal.title')}</div>
            <div className='baloa-table'>{props.t('options.tournaments.pages.newTournament.tabs.tabPunctuationAndFairPlay.confirmDeleteExtraSanctionModal.message')}</div>
            <div className='baloa-names btn-delete' onClick={ ()=>{ props.handleOnDelete() } }>{props.t('options.tournaments.pages.newTournament.tabs.tabPunctuationAndFairPlay.confirmDeleteExtraSanctionModal.deleteBtnLbl')}</div>
            <div className='baloa-names btn-cancel' onClick={ ()=>{ props.handleOnCancel() } }>{props.t('options.tournaments.pages.newTournament.tabs.tabPunctuationAndFairPlay.confirmDeleteExtraSanctionModal.cancelBtnLbl')}</div>
        </ModalComponent>
    )
}
export default withTranslation('league')(TournamentPhaseDeleteExtraSanctionConfirmationModalComponent)
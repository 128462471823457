// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirm-reopen-phase-modal .modal__container {
    padding: 0;
    padding-top: 26px;
    text-align: center;
    width: 375px;
}

.confirm-reopen-phase-modal .baloa-names {
    color: var(--primary-very-dark-color);
    padding: 0 24px;
}

.confirm-reopen-phase-modal .baloa-table {
    color: var(--primary-dark-color);
    margin-top: 10px;
    padding: 0 24px;
}

.confirm-reopen-phase-modal .baloa-names.link-button {
    color: var(--new-green-color);
    border-top: 1px solid var(--new-gray-background-color);
    padding: 16px 0;
    margin-top: 24px;
    cursor: pointer;
}

.confirm-reopen-phase-modal .baloa-names.link-button-cancel {
    color: var(--primary-very-dark-color);
    font-weight: var(--font-regular);
    border-top: 1px solid var(--new-gray-background-color);
    padding: 16px 0;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/tournament-phase-confirm-reopen-phase-modal/TournamentPhaseConfirmReopenPhaseModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,iBAAiB;IACjB,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,qCAAqC;IACrC,eAAe;AACnB;;AAEA;IACI,gCAAgC;IAChC,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,6BAA6B;IAC7B,sDAAsD;IACtD,eAAe;IACf,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,qCAAqC;IACrC,gCAAgC;IAChC,sDAAsD;IACtD,eAAe;IACf,eAAe;AACnB","sourcesContent":[".confirm-reopen-phase-modal .modal__container {\n    padding: 0;\n    padding-top: 26px;\n    text-align: center;\n    width: 375px;\n}\n\n.confirm-reopen-phase-modal .baloa-names {\n    color: var(--primary-very-dark-color);\n    padding: 0 24px;\n}\n\n.confirm-reopen-phase-modal .baloa-table {\n    color: var(--primary-dark-color);\n    margin-top: 10px;\n    padding: 0 24px;\n}\n\n.confirm-reopen-phase-modal .baloa-names.link-button {\n    color: var(--new-green-color);\n    border-top: 1px solid var(--new-gray-background-color);\n    padding: 16px 0;\n    margin-top: 24px;\n    cursor: pointer;\n}\n\n.confirm-reopen-phase-modal .baloa-names.link-button-cancel {\n    color: var(--primary-very-dark-color);\n    font-weight: var(--font-regular);\n    border-top: 1px solid var(--new-gray-background-color);\n    padding: 16px 0;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// React device detect
import { isMobile } from 'react-device-detect';

// Styles
import './BaloaPlayClipsModalComponent.styles.css';

//Assests
import whatsAppImg from 'assets/images/help_WhatsApp_2.png';

// i18n
import { withTranslation } from 'react-i18next';

//Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

//Material
import { Skeleton } from '@mui/material';
import InfiniteScroll from "react-infinite-scroll-component";


function BaloaPlayClipsModalComponent( props ) {

    const [loadMore, setLoadMore] = React.useState(false);
    
    React.useEffect(() => {
        if(props.match_clips_next_url){
            setLoadMore(true);
        } else {
            setLoadMore(false);
        }
    }, [props.match_clips_next_url]); // eslint-disable-line

    return(
        <Modal2Component
            isOpen={props.isOpen}
            onClose={props.onClose}
            title={
                <div className='baloa-play-match-videos__clips-title'>
                    <span className='hubicon-video_library'/>
                    <div className='baloa-normal-medium'>{props.t('clipsTitle')}</div>
                </div>
            }
            className="baloa-play-clips-modal"
        >
            <div className={`baloa-play-clips-modal__container `}>
                <div className='baloa-names'>{props.t('clipsMessage')}</div>
                <PrimaryButtonComponent
                    input_type={'button'}
                    onClick={()=>{ props.match_is_blocked? props.handleOnPlayBlockedMatch(props.selected_match?.id) : props.is_maximum_create_clips? props.handleOnMaxClips() : props.handleOnPlayVideo(0) }}
                    className={'small'}
                >
                    <span className='hubicon-add_option icon' />
                    <span>{props.t('createClipBtnLbl')}</span>
                </PrimaryButtonComponent>
                <InfiniteScroll
                    dataLength={props.match_clips.length }
                    next={ ()=>{ props.loadMoreClips(props.match_clips_next_url) }}
                    hasMore={loadMore}
                    loader={
                        <React.Fragment>
                            <div className='baloa-play-clips-modal__clips__clip'>
                                <Skeleton sx={{ height: isMobile? 191 : 310, borderRadius: 2 }} animation="wave" variant="rectangular" />
                            </div>
                            <div className='baloa-play-clips-modal__clips__clip'>
                                <Skeleton sx={{ height: isMobile? 191 : 310, borderRadius: 2 }} animation="wave" variant="rectangular" />
                            </div>
                            <div className='baloa-play-clips-modal__clips__clip'>
                                <Skeleton sx={{ height: isMobile? 191 : 310, borderRadius: 2 }} animation="wave" variant="rectangular" />
                            </div>
                        </React.Fragment>
                        }
                    height={'calc(100vh - 300px)'}
                    endMessage={''}
                    className='baloa-play-clips-modal__clips-container'
                >     
                    {props.match_clips?.map( (clip, indc) =>
                        <div className='baloa-play-clips-modal__clips__clip' key={indc} onClick={()=>{ props.handleOnPlayClip(indc)}}>
                            {clip.thumbnail?
                                <img src={ clip.thumbnail } alt={ clip.post_video_caption } />
                                :
                                <Skeleton sx={{ height: isMobile? 191 : 310, borderRadius: 2 }} animation="wave" variant="rectangular" />
                            }
                            <div className='baloa-table-column'>{clip.post_video_caption}</div>
                        </div>
                    )}
                </InfiniteScroll>
                {!props.match_is_blocked &&
                    <div className="baloa-play-clips-modal__mobile-support">
                        <div className='baloa-names'>{props.t('clipsSupportMessage')}</div>
                        <a className='baloa-play-clips-modal__clips-support' target='_blank' href={'https://wa.link/pwhk7x'}>
                            <img src={whatsAppImg}/>
                            <div className='baloa-table-column'>{props.t('clipsSupportLbl')}</div>
                            <span className='icon-chevron-roght'/>
                        </a>
                    </div>
                }
                {//props.match_is_blocked &&
                    /* <div className='baloa-play-clips-modal__clips-payment-message'>
                        <div className='baloa-play-clips-modal__clips-payment__title'>
                            <span className='hubicon-play_arrow'/>
                            <div className='baloa-normal-medium'>{props.t('clipsPaymentTitle')}</div>
                        </div> */
                        /* <div className='baloa-names'>{props.t('clipsPaymentMessage',{_valueMonth: props.baloa_pro_month_price, _currencyMonth: props.baloa_pro_month_currency, _valueMatch: props.selected_match?.baloa_match_recording_price?.value, _currencyMatch: props.selected_match?.baloa_match_recording_price?.currency})}</div>
                        <PrimaryButtonComponent
                            input_type={'button'}
                            onClick={()=>{props.handleOnBaloaProSubscription()}}
                        >
                            <span>{props.t('paymentOptionsModal.subscribeBtnLb')}</span>
                        </PrimaryButtonComponent>

                        <SecondaryButtonComponent
                            input_type={'button'}
                            onClick={()=>{props.handleOnPayMatch(props.selected_match?.id)}}
                        >
                            <span>{props.t('paymentBtnLbl')}</span>
                        </SecondaryButtonComponent> */
                        /* <div className='baloa-names'>{props.t('paymentMessageProDisabled',{_valueMatch: props.selected_match?.baloa_match_recording_price?.value, _currencyMatch: props.selected_match?.baloa_match_recording_price?.currency})}</div>
                        <PrimaryButtonComponent
                            input_type={'button'}
                            onClick={()=>{props.handleOnPayMatch(props.selected_match?.id)}}
                        >
                            <span>{props.t('paymentBtnLblProDisabled')}</span>
                        </PrimaryButtonComponent>
                    </div> */
                }
            </div>
        </Modal2Component>
    )
}

export default withTranslation('baloaplay')(BaloaPlayClipsModalComponent);
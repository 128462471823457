// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tournament-statistics-skeleton__scorers-row {
    display: flex;
    align-items: center;
    margin-top: 16px;
    margin-right: 24px;
}

.tournament-statistics-skeleton__scorers-row__number.baloa-normal-medium {
    color: var(--basic-gray-color);
    margin-right: 16px;
}

.tournament-statistics-skeleton__scorers-row__skeleton-group {
    margin-left: 16px;
}

.tournament-statistics-skeleton__scorers-row__goals.baloa-normal-medium {
    color: var(--primary-very-dark-color);
    margin-left: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/tournament-statistics-skeleton/TournamentStatisticsSkeletonComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,8BAA8B;IAC9B,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,qCAAqC;IACrC,iBAAiB;AACrB","sourcesContent":[".tournament-statistics-skeleton__scorers-row {\n    display: flex;\n    align-items: center;\n    margin-top: 16px;\n    margin-right: 24px;\n}\n\n.tournament-statistics-skeleton__scorers-row__number.baloa-normal-medium {\n    color: var(--basic-gray-color);\n    margin-right: 16px;\n}\n\n.tournament-statistics-skeleton__scorers-row__skeleton-group {\n    margin-left: 16px;\n}\n\n.tournament-statistics-skeleton__scorers-row__goals.baloa-normal-medium {\n    color: var(--primary-very-dark-color);\n    margin-left: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// React
import React from 'react';

//Stripe
import {CardNumberElement,
    CardExpiryElement,
    CardCvcElement, useStripe, useElements} from '@stripe/react-stripe-js';

//Globals
import { stripe_connected_account } from 'config/GlobalData';

// Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import FormComponent from 'shared/components/form/FormComponent';
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import InputFieldAutocompletePaymentCountries2 from 'shared/components/input-field-autocomplete-with-search/InputFieldAutocompletePaymentCountries2';

//Material
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

//Validators
import { EmailRequiredValidator, RequiredValidator } from 'shared/validators/RequiredValidator';

// React device detect
import { isMobile } from 'react-device-detect';

// Moment
import * as moment from 'moment';
// Locale
import 'moment/locale/en-au';
import 'moment/locale/es';

// Styles
import './BaloaProStripePaymentModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';



function BaloaProStripePaymentModalComponent ( props ){
    const [ignored, forceUpdate] = React.useReducer(x => x + 1, 0); //eslint-disable-line
    const [clientSecret, setClientSecret] = React.useState('');
    const [cardNumberComplete, setCardNumberComplete] = React.useState(false);
    const [cardExpiryComplete, setCardExpiryComplete] = React.useState(false);
    const [cardCvcComplete, setCardCvcComplete] = React.useState(false);
    const [formValid, setFormValid] = React.useState(false);
    const formRef = React.createRef();
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [succeeded, setSucceeded] = React.useState(false);


    const [montValue, setMonthValue] = React.useState(null);
    const [yearValue, setYearValue] = React.useState(null);
    const [monthCurrency, setMonthCurrency] = React.useState(null);
    const [yearCurrency, setYearCurrency] = React.useState(null);
    const [monthExpireTrialDate, setMonthExpireTrialDate] = React.useState(null);//Solo cuando aplica trial period
    const [yearExpireTrialDate, setYearExpireTrialDate] = React.useState(null);//Solo cuando aplica trial period
    const [monthTrialDays, setMonthTrialDays] = React.useState(null);//Solo cuando aplica trial period
    const [yearTrialDays, setYearTrialDays] = React.useState(null);//Solo cuando aplica trial period

    const [subscriptionPlan, setSubscriptionPlan] = React.useState('');

    React.useEffect(() => {
        if( props.prices?.find(price => price.metadata?.recurring?.interval === 'month') ){
            setMonthValue( props.prices?.find(price => price.metadata?.recurring?.interval === 'month').value );
            setMonthCurrency( props.prices?.find(price => price.metadata?.recurring?.interval === 'month').currency );
            setMonthExpireTrialDate( props.prices?.find(price => price.metadata?.recurring?.interval === 'month').expiration_date );
            setMonthTrialDays( props.prices?.find(price => price.metadata?.recurring?.interval === 'month').trial_period_days );
        }else{
            setMonthValue(null);
            setMonthCurrency(null);
            setMonthExpireTrialDate(null);
            setMonthTrialDays(null);
        }
        if( props.prices?.find(price => price.metadata?.recurring?.interval === 'year') ){
            setYearValue( props.prices?.find(price => price.metadata?.recurring?.interval === 'year').value );
            setYearCurrency( props.prices?.find(price => price.metadata?.recurring?.interval === 'year').currency );
            setYearExpireTrialDate( props.prices?.find(price => price.metadata?.recurring?.interval === 'year').expiration_date );
            setYearTrialDays( props.prices?.find(price => price.metadata?.recurring?.interval === 'month').trial_period_days );
        }else{
            setYearValue(null);
            setYearCurrency(null);
            setYearExpireTrialDate(null);
            setYearTrialDays(null);
        }
        if(props.is_new_baloa_pro_user){
            props.selectNewSubscriberPayment('year',yearValue,yearCurrency);
            setSubscriptionPlan('year');
            //props.selectNewSubscriberPayment('month',montValue,monthCurrency);
            //setSubscriptionPlan('month');
        }
    }, [props.prices]);// eslint-disable-line
    
    const formInitialValues = {
        country: '',
        nombre: '',
        email: '',
    }

    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                color: '#2A302A',
                fontFamily: "'Roboto', sans-serif",
                fontSmoothing: 'antialiased',
                fontSize: '14px',
                '::placeholder': {
                    color: '#CDCDCD',
                },

            },
            invalid: {
                color: '#FF515B',
                iconColor: '#FF515B',
            },
        },
    };

    const CARD_ELEMENT_OPTIONS_MOBILE = {
        style: {
            base: {
                color: '#2A302A',
                fontFamily: "'Roboto', sans-serif",
                fontSmoothing: 'antialiased',
                fontSize: '11px',
                '::placeholder': {
                    color: '#CDCDCD',
                },

            },
            invalid: {
                color: '#FF515B',
                iconColor: '#FF515B',
            },
        },
    };

    React.useEffect(() => {
        if(props.subscription && !props.is_new_baloa_pro_user){
            setClientSecret(props.subscription?.latest_invoice?.payment_intent?.client_secret);
            formRef.current.handleSubmit();
        }
        if(props.subscription && props.is_new_baloa_pro_user){
            createPaymentSetUpIntent(props.subscription?.customer);
        }
    }, [props.subscription]);

    React.useEffect(() => {
        if(props.stripe_setup_intent && props.is_new_baloa_pro_user){
            formRef.current.handleSubmit();
        }
    }, [props.stripe_setup_intent]);

    async function createPaymentSetUpIntent( customer_id){
        await props.createPaymentSetUpIntent( customer_id, stripe_connected_account );
    }

    const handlePaymentClick = async (event) => {
        event.preventDefault();
        setLoading(true);
        setError(null);
        if(!clientSecret){
            await props.createStripeSubscription( formRef.current.values.nombre, formRef.current.values.email, props.countries?.find( country => country.name === formRef.current.values.country).iata_code);
        }else{
            formRef.current.handleSubmit();
        }
        //setLoading(false);
    }

    const handleSubmit = async ( values) => {
        //event.preventDefault();
        setLoading(true);

        const cardElement = elements.getElement(CardNumberElement);

        const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: cardElement,
                billing_details: {
                    email: values.email,
                },
            },
            setup_future_usage: 'off_session',
        }  
        );

        if (stripeError) {
            setError(stripeError.message);
            setLoading(false);
            return;
        }

        if (paymentIntent.status === 'succeeded') {
            setSucceeded(true);
            props.handleOnPaymentResumeModal( paymentIntent );                
        }

        setLoading(false);
    };

    const valiadateForm = ( values )=> {
        if(values === null){
            setFormValid(false);
        }else if(formRef?.current?.isValid && cardNumberComplete && cardExpiryComplete && cardCvcComplete && formRef?.current?.values.country){
            setFormValid(true);
        }else{
            setFormValid(false);
        }
    }

    const checkSubscriptionPlan = (event) =>{
        setSubscriptionPlan(event.target.value);
        if(event.target.value === 'year'){
            props.selectNewSubscriberPayment('year',yearValue,yearCurrency);
        }else if(event.target.value === 'month'){
            props.selectNewSubscriberPayment('month',montValue,monthCurrency);
        }
    }

    const handleSubmitNewUser = async ( values) => {
        //event.preventDefault();
        setLoading(true);

        const cardElement = elements.getElement(CardNumberElement);

        const { error: stripeError, setupIntent } = await stripe.confirmCardSetup(props.stripe_setup_intent.client_secret, {
            payment_method: {
                card: cardElement,
                billing_details: {
                    email: values.email,
                },
            },
        }  
        );

        if (stripeError) {
            setError(stripeError.message);
            setLoading(false);
            return;
        }

        setLoading(false);
        props.onCloseNewUser();
    };

    return(
        <ModalComponent             
            isOpen={props.isOpen} 
            //onClose={props.onClose}
            className={'baloa-pro-stripe-payment-modal'}
        >
            <div className='baloa-pro-stripe-payment-modal__container'>
                <div className='baloa-pro-stripe-payment-modal__header'>
                    <div className='baloa-pro-stripe-payment-modal__header-title'>
                        <span className='hubicon-award_star'/>
                        <div className='baloa-headline-2'>{props.is_new_baloa_pro_user? props.t('baloaProStripePaymentModal.title2') : props.t('baloaProStripePaymentModal.title')}</div>
                    </div>
                    <div className='baloa-pro-stripe-payment-modal__header-message baloa-username'>{props.is_new_baloa_pro_user? props.t('baloaProStripePaymentModal.subtitle2',{ _user_name: props.user_name}) : props.t('baloaProStripePaymentModal.subtitle',{ _user_name: props.user_name})}</div>
                    <span className='hubicon-clear_option' onClick={props.onClose}/>
                </div>
                <div className={`baloa-pro-stripe-payment-modal__content scroll `}>
                    {props.is_new_baloa_pro_user &&
                        <div className={"baloa-pro-stripe-payment-modal__option-buttons__container"}>
                            <div className='baloa-table'>{props.t('baloaProStripePaymentModal.billingPeriodLbl')}</div>
                            <FormControl component="fieldset" className={"baloa-pro-stripe-payment-modal__option-buttons"} >                        
                                <RadioGroup row aria-labelledby="baloa-pro-stripe-payment-modal" name="baloa-pro-stripe-payment-modal" defaultValue="top" /* className="tournament-inscription__content__option-button" */ /* error={props.inscription_errors} */>
                                    <div className={`baloa-pro-stripe-payment-modal__option-buttons__button ${subscriptionPlan == "year"? 'selected':''}`}>
                                        <FormControlLabel
                                            value="year"
                                            control={ subscriptionPlan === "year"? <Radio color="secondary" checked={true} />: <Radio color="secondary" required={true} />}
                                            label={
                                                <div className='baloa-pro-stripe-payment-modal__option-buttons__button__label'>
                                                    <div className='baloa-names'>{props.t('baloaProStripePaymentModal.annualPlanLbl')}</div>
                                                    <div className='baloa-table' dangerouslySetInnerHTML={{
                                                        __html: `$0 ${yearCurrency}  <span class="strike">${yearValue} ${yearCurrency}</span>`}}
                                                    ></div>                                                
                                                </div>
                                            }
                                            labelPlacement="start"
                                            onChange={ checkSubscriptionPlan }
                                        />
                                        <div className='baloa-table-column'>{props.t('baloaProStripePaymentModal.freePricOptionMsg', {_days: yearTrialDays, _price: yearValue, _currency: yearCurrency})}</div>
                                    </div>
                                    <div className={`baloa-pro-stripe-payment-modal__option-buttons__button ${subscriptionPlan == "month"? 'selected':''}`}>
                                        <FormControlLabel
                                            value="month"
                                            control={ subscriptionPlan === "month"? <Radio color="secondary" checked={true} />: <Radio color="secondary" required={true} />}
                                            label={
                                                <div className='baloa-pro-stripe-payment-modal__option-buttons__button__label'>
                                                    <div className='baloa-names'>{props.t('baloaProStripePaymentModal.monthPlanLbl')}</div>
                                                    <div className='baloa-table' dangerouslySetInnerHTML={{
                                                        __html: `$0 ${monthCurrency}  <span class="strike">${montValue} ${monthCurrency}</span>`}}
                                                    ></div>                                                
                                                </div>
                                            }
                                            labelPlacement="start"
                                            onChange={ checkSubscriptionPlan }
                                        />
                                        <div className='baloa-table-column'>{props.t('baloaProStripePaymentModal.freePricOptionMsg', {_days: monthTrialDays, _price: montValue, _currency: monthCurrency})}</div>
                                    </div>
                                </RadioGroup>                        
                            </FormControl>
                        </div>
                    }
                    <FormComponent
                        formInitialValues={formInitialValues}
                        onSubmit={(values, actions) => {
                            if(props.is_new_baloa_pro_user){
                                handleSubmitNewUser(values);
                            }else{
                                handleSubmit(values);
                            }
                        }}
                        className={`baloa-pro-stripe-payment-modal__form `}
                        innerRef={formRef}
                        onChange={ ()=>{ valiadateForm()}}
                    >
                        
                        <InputFieldComponent
                            label={props.t('baloaProStripePaymentModal.emailLbl')}
                            className="baloa-pro-stripe-payment-modal__input"
                            input_id=""
                            field_name="email"
                            validationFunction={ EmailRequiredValidator}
                            disabled={false}
                            input_type="text"
                            resetError={() => { }}
                            error={{email: ''}}
                            message=""
                            onChange={() => { }}
                            //defaultValue={}
                        />
                        <label className='baloa-username'>
                        {props.t('baloaProStripePaymentModal.cardLbl')}
                        <CardNumberElement 
                            options={isMobile? CARD_ELEMENT_OPTIONS_MOBILE : CARD_ELEMENT_OPTIONS}
                            onChange={(event) => {setCardNumberComplete(event.complete); valiadateForm(); }}
                        />
                        </label>
                        <div className='baloa-pro-stripe-payment-modal__columns'>
                            <label className='baloa-username'>
                                {props.t('baloaProStripePaymentModal.expireDateLbl')}
                                <CardExpiryElement 
                                    options={isMobile? CARD_ELEMENT_OPTIONS_MOBILE : CARD_ELEMENT_OPTIONS}
                                    onChange={(event) => {setCardExpiryComplete(event.complete); valiadateForm();}}
                                />
                            </label>
                            <label className='baloa-username'>
                                {props.t('baloaProStripePaymentModal.cvcLbl')}
                                <CardCvcElement 
                                    options={isMobile? CARD_ELEMENT_OPTIONS_MOBILE : CARD_ELEMENT_OPTIONS}
                                    onChange={(event) => {setCardCvcComplete(event.complete); valiadateForm();}}
                                />
                            </label>

                        </div>
                        
                        <InputFieldComponent
                            label={props.t('baloaProStripePaymentModal.nameLbl')}
                            className="baloa-pro-stripe-payment-modal__input"
                            input_id=""
                            field_name="nombre"
                            validationFunction={RequiredValidator}
                            disabled={false}
                            input_type="text"
                            resetError={() => { }}
                            error={{nombre: ''}}
                            message=""
                            onChange={() => {  }}
                            //defaultValue={}
                        />

                        <InputFieldAutocompletePaymentCountries2
                            options={ props.countries }
                            noOptionsText={props.t('baloaProStripePaymentModal.emptyCountryLbl')}
                            optionLabelField={ "name" }
                            optionSelectedField={ "name" }
                            lettercount={ "2" }
                            handleSearchWord ={ ( newCountryValue ) => props.handleSearchCountry( newCountryValue ) }
                            className="baloa-pro-stripe-payment-modal__country-select"
                            label={props.t('baloaProStripePaymentModal.countryLbl')}
                            placeholder=""
                            id="country"
                            field_name="country"
                            validationFunction={ RequiredValidator }
                            resetError={ () => {}}
                            //resetError={ () => props.onResetError( { 'country': "" } )}
                            error={ {country:''} }
                            //value={  }
                            onInputChange={(val)=>{ valiadateForm(val); forceUpdate(); }}
                            autoComplete='off'
                        />
                    </FormComponent>
                    <div className={`baloa-pro-stripe-payment-modal__conditions `}>                    
                        {props.subscription_type === 'month' && !props.is_new_baloa_pro_user &&
                            <div className='baloa-table'>{props.t('baloaProStripePaymentModal.monthPaymentBtnLbl', {_price: props.subscription_price, _currency: props.subscription_currency})}</div>
                        }
                        {props.subscription_type === 'year' && !props.is_new_baloa_pro_user &&
                            <div className='baloa-table'>{props.t('baloaProStripePaymentModal.yearPaymentBtnLbl', {_price: props.subscription_price, _currency: props.subscription_currency})}</div>
                        }
                        {!props.is_new_baloa_pro_user &&
                            <div className='baloa-username'dangerouslySetInnerHTML={{
                                __html: props.t('baloaProStripePaymentModal.paymentSubscriptionMsg',{_link: '/terms', _price_month: montValue, _currency_month: monthCurrency, _price_year: yearValue, _currency_year: yearCurrency}) }}
                            ></div>
                        }
                        {props.is_new_baloa_pro_user &&
                            <React.Fragment>
                                <div className='baloa-table'>{props.t('baloaProStripePaymentModal.freeTrialValue')}</div>
                                <div className='baloa-table second-title'>{subscriptionPlan === "year"? 
                                    props.t('baloaProStripePaymentModal.afterTrialYearValueLbl', {_price: yearValue, _currency: yearCurrency}) 
                                    : 
                                    props.t('baloaProStripePaymentModal.afterTrialMonthValueLbl', {_price: montValue, _currency: monthCurrency})
                                }</div>
                                <div className='baloa-pro-stripe-payment-modal__conditions__new-subscriber__conditions'>
                                    <div className='baloa-username'dangerouslySetInnerHTML={{
                                        __html: props.t('baloaProStripePaymentModal.trailConditions.condition1',{_end_trial_date: moment(subscriptionPlan === "year"? yearExpireTrialDate : monthExpireTrialDate).format('LL') }) }}
                                    ></div>
                                    {subscriptionPlan === "year" &&
                                        <div className='baloa-username'dangerouslySetInnerHTML={{
                                            __html: props.t('baloaProStripePaymentModal.trailConditions.condition2Year',{_end_trial_date: moment(yearExpireTrialDate).format('LL'), _price: yearValue, _currency: yearCurrency}) }}
                                        ></div>
                                    }
                                    {subscriptionPlan === "month" &&
                                        <div className='baloa-username'dangerouslySetInnerHTML={{
                                            __html: props.t('baloaProStripePaymentModal.trailConditions.condition2Month',{_end_trial_date: moment(monthExpireTrialDate).format('LL'), _price: montValue, _currency: monthCurrency}) }}
                                        ></div>
                                    }
                                    <div className='baloa-username'dangerouslySetInnerHTML={{
                                        __html: props.t('baloaProStripePaymentModal.trailConditions.condition3',{_end_trial_date: moment(subscriptionPlan === "year"? yearExpireTrialDate : monthExpireTrialDate).format('LL')}) }}
                                    ></div>
                                    <div className='baloa-username'dangerouslySetInnerHTML={{
                                        __html: props.t('baloaProStripePaymentModal.trailConditions.condition4',{_link: '/terms'}) }}
                                    ></div>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                    {error && 
                        <div className='baloa-pro-stripe-payment-modal__error-message'>
                            <span className='hubicon-info'/>
                            <div className='baloa-table'>
                                <div>{props.t('baloaProStripePaymentModal.subscriptionErrorMsg')}</div>
                                <div>{error} </div>
                            </div>
                        </div>
                    }
                    
                </div>
                <div className={`baloa-pro-stripe-payment-modal__buttons `}>                    
                    <PrimaryButtonComponent
                        input_type={'button'}
                        className={'small'}
                        onClick={handlePaymentClick}
                        disabled={!stripe || loading || !formValid || succeeded}
                    >
                        {loading ?
                            <span className="baloa-pro-stripe-payment-modal__loading" />
                        :                    
                            <span>{props.t('baloaProStripePaymentModal.susbcribeBtnLbl')}</span>
                        }
                    </PrimaryButtonComponent>
                </div>
            </div>
        </ModalComponent>
    )

}

export default withTranslation('baloapro')(BaloaProStripePaymentModalComponent);

// React
import React, { Component }from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Components
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import FormComponent from 'shared/components/form/FormComponent';

//Modals
//import SignUpSessionLimitModalComponent from '../signup-session-limit-modal/SignUpSessionLimitModalComponent';


// Styles
import './SignUpOtpPanelComponent.styles.css';

// Actions
import { changeSignUpError, changeSignUpStatus, changeSignUpSessionLimitModalStateAction } from 'app/authentication/actions/LoginActionsCreators';
import { signUpWithOtpAction, sendPhoneAction } from 'app/authentication/actions/LoginActions';

// Validators
import { OtpValidator } from 'shared/validators/SignUpValidator';
//import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';

// Constants
import { SIGNUP_STATUS } from 'app/authentication/reducers/SignUpReducer';

// i18n
import { withTranslation } from 'react-i18next';
// Redux
import {compose} from 'redux';

// Assets
import baloa_logo from 'assets/images/logo-baloa-white.png';
import baloa_green_logo from 'assets/logos/baloa-logo.png';

// React device detect
import {isMobile} from 'react-device-detect';

//Functions
import { generateBaloaToken } from 'shared/functions/GenerateBaloaToken';

class SignUpOtpPanelComponent extends Component {

    constructor(props){
        super(props);
        this.state = { 
            disabled: false,
            minutes_left: 2,
            seconds_left: 0,
            timer_id: undefined,
            sended: true,
            resended: false,
            isValid: false
        };
    };

    disabled(data) {
        if (data.isValid && !this.state.disabled) {
            this.setState({isValid:true});
            this.setState({disabled:true})
        }
        if(!data.isValid && this.state.disabled){
            this.setState({isValid:false});
            this.setState({disabled:false}) 
        }
    }
    
    resetError(){
        const data = {
            'field':'code',
            'error':''
        }
        this.props.changeSignUpError(data);  
    }

    stopTimers() {

        for (var i = 1; i < 99999; i++)
                window.clearInterval(i);

        this.setState( { seconds_left: 0 });
        this.setState( { minutes_left: 2 });                

    }

    startTimer() {

        if(!this.state.isValid && this.state.resended){
            
            this.setState({resended:false}) 
            
        }

        this.state.timer_id = setInterval( () => {   // eslint-disable-line
            this.setState( { seconds_left: this.state.seconds_left - 1 });
            if ( this.state.seconds_left < 0 ) {
                this.setState( { seconds_left: 59 });
                this.setState( { minutes_left: this.state.minutes_left - 1 });
                if( this.state.minutes_left < 0 ) {
                    this.setState( { seconds_left: 0 });
                    this.setState( { minutes_left: 2 });
                    clearInterval( this.state.timer_id );
                    this.setState( { sended: false });
                    this.setState({resended:false}) 
                }
            }
        }, 1000 );

        
    }

    componentDidMount() {
        this.startTimer();
    }

    componentWillUnmount() {
        clearInterval( this.state.timer_id );
    }
    
    render = () => {

        const formInitialValues = {
            code: '',
        }

        return (

            <React.Fragment>
                <div className="signup-otp-panel__container">
                    <div className="signup-otp-panel__left-col">
                        <Link to="/">
                            { !isMobile 
                                ?
                                <img className="signup-otp-panel__image" src={baloa_logo} alt=""/>
                                :
                                <img className="signup-header__image" src={baloa_green_logo} alt=""/>
                            }
                        </Link>
                    </div>
                    <div className="signup-otp-panel__right-col">

                        {/* Title */}
                        <div className="signup-otp-panel-verification-panel__container-title">
                            <span className="icon-arrow-left baloa-headline-1" onClick   = { () => this.props.changeSignUpStatus( SIGNUP_STATUS['PHONE'] ) }/>
                            <div className="signup-otp-panel-verification-panel__title baloa-headline-1">{this.props.t('phone.verificationTitle')}</div>
                        </div>
                        
                        <div className="signup-otp-panel-verification-panel__text baloa-subtitle-2">{this.props.t('loginEmail.singInPhone')} <span>+{this.props.state.signup.phone}</span></div>
                        
                        {/* Otp Form */}
                        <FormComponent 
                            formInitialValues = { formInitialValues } 
                            onSubmit          = { ( values, actions ) => { 
                                values['phone'] = this.props.state.signup.phone;
                                this.props.signUpWithOtpAction( values, actions, this.props.location_pathname );
                            } }
                            className         = "signup-otp-panel__form"
                            functionDisabled  = { this.disabled.bind(this) }
                        >
                            <lable className="signup-otp-panel__form__label">{this.props.t('code.title')}</lable>
                            <InputFieldComponent 
                                input_id           = "signup-otp-panel-ver" 
                                field_name         = "code"
                                validationFunction = { OtpValidator }
                                disabled           = { false }
                                className          = "signup-otp-panel__form__field"
                                type               = "text"
                                resetError         = { () => this.resetError() }
                                error              = { this.props.state.signup_error.error }
                                message            = ""
                                maxLength          = "5"
                            />
                            
                        

                            {/* Retry Otp */}
                            <div className="signup-otp-panel__retry-otp">
                                
                                <div className="signup-otp-panel__form__valid-for">
                                    <text className="signup-otp-panel__form__valid-for__text">{this.props.t('code.timer')}</text> 
                                    <text className="signup-otp-panel__form__valid-for__text-alert">
                                        { this.state.seconds_left > 9? `${ this.state.minutes_left }:${this.state.seconds_left} ${this.props.t('signUpEmailVerification.minutesLabel') }`: `${ this.state.minutes_left }:0${this.state.seconds_left} ${this.props.t('signUpEmailVerification.minutesLabel') }` }
                                    </text>
                                </div>                        
                                
                                <div className="signup-otp-panel__retry-otp__options">                            
                                    <SecondaryButtonComponent
                                        input_type={'button'} 
                                        disabled  = { this.state.sended && this.state.isValid} 
                                        onClick   = { async () => { 
                                            let token = await generateBaloaToken( this.props.state.signup.phone );
                                            this.props.sendPhoneAction( { phone: this.props.state.signup.phone }, token );
                                            this.setState( { resended: true } );
                                            this.stopTimers( );
                                            this.startTimer();
                                            this.setState( { sended: true } );                                
                                            this.setState( { isValid: true } );
                                        } } 
                                        className = "signup-otp-panel__retry-otp__options__option">
                                        <span>{this.props.t('signUpEmailVerification.retryCode')}</span>
                                    </SecondaryButtonComponent>
                                    <PrimaryButtonComponent type="submit" disabled={!this.state.disabled} onClick={()=>{}} className="signup-otp-panel__form__submit">
                                        <span>{this.props.t('code.button')}</span>
                                    </PrimaryButtonComponent>
                                    
                                </div>

                                {/* <TextButtonComponent 
                                    className = "signup-otp-panel__retry-otp__other-method"
                                    onClick   = { () => this.props.changeSignUpStatus( SIGNUP_STATUS['START'] ) } >
                                    <span>{this.props.t('code.method')}</span>
                                </TextButtonComponent> */}

                            </div>
                        </FormComponent>
                    </div>

                </div>

                {/* <SignUpSessionLimitModalComponent //Se deshabilita funcionalidad
                    isOpen={ this.props.state.signup.session_limit_modal } 
                    onClose={() => { this.props.changeSignUpSessionLimitModalStateAction( false ) }} 
                    handleCloseSession={()=>{}}//TODO: pendiente definir api que cierra ultima sesion
                /> */}
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        signUpWithOtpAction: ( values, actions, pathname ) => {
            return dispatch( signUpWithOtpAction( values, actions, pathname ) )
        },
        sendPhoneAction: ( values, data ) => {
            return dispatch( sendPhoneAction( values, data ) )
        },
        changeSignUpStatus: ( new_status ) => {
            return dispatch( changeSignUpStatus( new_status ) )
        },
        changeSignUpError : ( values ) => {
            return dispatch( changeSignUpError( values ) )
        },
        changeSignUpSessionLimitModalStateAction: ( new_state ) => {
            return dispatch( changeSignUpSessionLimitModalStateAction( new_state ) )
        },
    }
}

export default compose(
    withTranslation("signUp"),
    connect(mapStateToProps, mapDispatchToProps)
)(SignUpOtpPanelComponent);

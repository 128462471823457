// React
import React from 'react';

//Styles
import './BaloaPlayCreateClipConfirmationModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

//Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';

function BaloaPlayCreateClipConfirmationModalComponent ( props ){

    return(
        <ModalComponent
            isOpen={props.isOpen}
            //onClose={}
            //title={}
            className="baloa-play-create-clip-confirmation-modal"
        >
            <div className='baloa-play-create-clip-confirmation-modal__container'>
                <span className='hubicon-video_library-1'/>
                <div className='baloa-subtitle-2'>{props.t('createClipConfirmationModal.title')}</div>
                <div className='baloa-names'>{props.t('createClipConfirmationModal.message')}</div>
                <div className='baloa-play-create-clip-confirmation-modal__buttons'>
                   
                    <PrimaryButtonComponent
                        input_type={'button'}
                        onClick={()=>{ props.is_maximum_create_clips? props.handleOnMaxClips() : props.handleOnCretaOtherClip() }}
                    >
                        <span>{props.t('createClipConfirmationModal.createOtherBtnLbl')}</span>
                    </PrimaryButtonComponent>
                    <TextButtonComponent
                        input_type={'button'}
                        onClick={()=>{props.onClose()}}
                    >
                        <span>{props.t('createClipConfirmationModal.exitBtnLbl')}</span>
                    </TextButtonComponent>
                </div>
            </div>
        </ModalComponent>
    )

}

export default withTranslation('baloaplay')(BaloaPlayCreateClipConfirmationModalComponent);

/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Components
import ModalComponent from 'shared/components/modal/ModalComponent';

// Styles
import './TournamentPhaseConfirmReopenPhaseModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';


function TournamentPhaseConfirmReopenPhaseModalComponent( props ) {

    return(
        <ModalComponent
            isOpen={props.isOpen}
            //onClose={props.onClose}
            className="confirm-reopen-phase-modal"
            title={props.t('options.tournaments.setupTournamentInvitationInscription.paymentInformationModal.titleModal')}
        >
            <div className='baloa-names'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.confirmReOpenPhaseModal.title')}</div>
            <div className='baloa-table'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.confirmReOpenPhaseModal.subTitle')}</div>
            <div className='baloa-names link-button' onClick={ ()=>{ props.handleOnReOpen() } }>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.confirmReOpenPhaseModal.reOpenBtnLbl')}</div>
            <div className='baloa-names link-button-cancel' onClick={ ()=>{ props.handleOnCancel() } }>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.confirmReOpenPhaseModal.cancelBtnLbl')}</div>
        </ModalComponent>
    )
}

export default withTranslation('league')(TournamentPhaseConfirmReopenPhaseModalComponent);


/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Components
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
//import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import TournamentPhasesContainerComponent from '../tournament-phases-container/TournamentPhasesContainerComponent';

//import TournamentPhasesComponentV2 from 'app/leagues/components/tournament-phases/TournamentPhasesComponentV2';

// Styles
import './TournamentStartTournamentComponent.styles.css';

//Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';


//i18
import { withTranslation } from 'react-i18next';


function TournamentStartTournamentComponent ( props ) {

    const [ isTournamentEnrolledTeams, setIsTournamentEnrolledTeams ] = React.useState(true);
    const [ isTournamentPhases, setIsTournamentPhases ] = React.useState(false);
    const [ step2, setStep2 ] = React.useState(false);
    const [ step3, setStep3 ] = React.useState(false);
    
    const handleStartTournamentSteps = ( value ) => {

        if(value === 'enrolled_teams') {
            setIsTournamentEnrolledTeams(false);
            setIsTournamentPhases(true);
            setStep2(true);
        }

        if(value === 'tournament_phases') {
            setIsTournamentPhases(false);
            setStep3(true);
            props.handleCalendarStep();
        }
    }

    /* const handleStartTournametBackSteps = ( value ) => {
        if(value === 'tournament_phases') {
            setStep2(false);
            setIsTournamentPhases(false);
            setIsTournamentEnrolledTeams(true);
        }
    } */

    const handleStartTournamentBackBtn = (value) => {
        props.handleStartTournamentBackBtn();
        props.deleteTournamentPhase(props.tournament_phases[props.tournament_phases.length - 1],props.tournament_phases.length - 1);
    }

    function handleEnrolledTeams() {
        let enrolled_teams = props.tournament.tournament_teams?.filter(team => team.team_state === "Enrolled");
        return enrolled_teams.length;
    }

    return(
        <div>
            <div className="tournament-start-tournament__header">
                <span className="icon-arrow-left" onClick={() => handleStartTournamentBackBtn('enrolled_teams') } />
                <div className="baloa-headline-3">{props.t('options.tournaments.startTournament.startTournamentLabel')}</div>
            </div>
            <div className="tournament-start-tournament__container">
                <div className="tournament-start-tournament__stepper">
                    <div className='step-point__container'>
                        <div className={ "step-point" }>
                            <div className='baloa-username step-point-label-completed'>{props.t('options.tournaments.startTournament.stepper.step1')}</div>
                            <div className='point step-point-completed' />
                        </div>
                        <div className={ "step-point" }>
                            <div className={`baloa-username ${step2? 'step-point-label-completed': ''}`}>{props.t('options.tournaments.startTournament.stepper.step2')}</div>
                            <div className={`point ${step2? 'step-point-completed': ''}`} />
                        </div>
                        <div className={ "step-point" }>
                            <div className={`baloa-username ${step3? 'step-point-label-completed': ''}`}>{props.t('options.tournaments.startTournament.stepper.step3')}</div>
                            <div className={`point ${step3? 'step-point-completed': ''}`} />
                        </div>
                        
                    </div>
                    <div className='step-line__container'>
                        <div className={ `step-line ${ step2? 'step-line-completed': ''}` } />
                        <div className={ `step-line ${ step3? 'step-line-completed': ''}` } />
                        
                    </div>
                </div>

                { isTournamentEnrolledTeams &&
                    <div className='tournament-start-tournament__enrolled-teams'>
                        <div className='baloa-normal-medium'>{props.t('options.tournaments.startTournament.participantTeams', {teams: handleEnrolledTeams()})}</div>
                        { props.tournament.tournament_teams.map((team, index) => (                                              
                            team.team_state === 'Enrolled' &&
                                <div key={index} className='tournament-start-tournament__enrolled-teams__container'>                                    
                                    { team.team_logo
                                        ?
                                        <img alt="" src={team.team_logo} />
                                        :
                                        <span className='icon-ball' color={getRandonIconBallColor()}/>                            
                                    }
                                    <div className='tournament-start-tournament__team-info'> 
                                        <div className='baloa-names'>{team.team}</div>
                                        <div className='baloa-table-column'>{props.t(`options.tournaments.pages.newTournament.tabs.tabBasicData.`+team.team_gender)}  &nbsp;&bull;&nbsp; {props.t("options.tournaments.pages.newTournament.tabs.tabTeams." + team.team_category)}</div>
                                    </div>
                                </div>
                            )) 
                        }
                    </div>
                }
                { isTournamentPhases &&                    
                    <TournamentPhasesContainerComponent
                            // Props to TournamentNewPhaseComponent
                            selected_phase = {props.tournament_active_phase}
                            errorsNewGroupTournament={props.errorsNewGroupTournament}
                            league_page={props.league_page}
                            phase_teams={props.tournament_teams}
                            tournament_phases={ props.tournament_phases }
                            tournament_setup_teams={props.tournament_setup_teams}
                            handleEditPhase={props.handleEditPhase}

                            handleReturnPhaseComponent={props.handleReturnPhaseComponent}
                            onResetError={ props.onResetError }
                            pageRoles={ props.pageRoles }
                            addTournamentPhase={ props.addTournamentPhase }
                            tournament_id={ props.tournament?.id}
                            createNewGroupPhases={ props.createNewGroupPhases }                            
                            // Props to TournamentGroupsComponent
                            groups={ props.groups }
                            tournament_teams={ props.phase_teams }
                            tournament_active_phase={ props.tournament_active_phase }
                            handleAddTeamToGroup={ props.handleAddTeamToGroup }
                            handleDeleteTeamFromGroup={ props.handleDeleteTeamFromGroup }
                            handleActivePhaseCreateState={ props.handleActivePhaseCreateState }
                            active_phase_groups_create={ props.active_phase_groups_create }
                            tournament_criteria_tie_breaking = { props.tournament_criteria_tie_breaking }
                            tournament_new_order_criteria_tie_breaking = { props.tournament_new_order_criteria_tie_breaking }
                            select_groups_teams={true}
                            // handleBestThirdByGroup={(status, groupId) => this.handleBestThirdByGroup(status, groupId)}
                            handleOnClickNewPhase={ props.handleOnClickNewPhase }
                            handle_team_filter= { props.handle_team_filter }
                            deleteNewPhase={ props.deleteNewPhase }
                            handleDeleteGroupPhase={ props.handleDeleteGroupPhase }
                            handleCalendarStep={ props.handleCalendarStep }
                            isNewTournament={ true }
                            handleOnEditPhaseModal={props.handleOnEditPhaseModal}
                            handleOnPhaseValidation={props.handleOnPhaseValidation}
                            handleNumberClassified={props.handleNumberClassified}
                            handleCleanAllGroups={props.handleCleanAllGroups}
                            handleCreateAutomaticGroupPhases={props.handleCreateAutomaticGroupPhases}
                            deleteTournamentPhase={props.handleDeleteTournamentPhase} // Change name of prop by multiple name in TournamentView
                            click_source_create_phase={props.click_source_create_phase}
                            deleteTeamFromCreatePhase={props.deleteTeamFromCreatePhase}
                            loadTournamentPhaseTeams={props.loadTournamentPhaseTeams}
                            handleOnClickPhaseTab={props.handleOnClickPhaseTab}
                            handleOnAddAllTeamsToGroup={props.handleOnAddAllTeamsToGroup}
                            handleOnCreateFairPlaySettings={props.handleOnCreateFairPlaySettings}
                            handleOnEditFairPlaySettings={props.handleOnEditFairPlaySettings}
                            handleOnChangeSetting={props.handleOnChangeSetting}
                            selected_fair_play_setting={props.selected_fair_play_setting}
                        />
                }
                <div className='tournament-start-tournament__actions'>
                    { isTournamentEnrolledTeams &&
                        <div className='tournament-start-tournament__btns-container'>
                            {/* <SecondaryButtonComponent onClick={ () => props.handleStartTournamentBackBtn() } >{props.t('options.tournaments.startTournament.backBtn')}</SecondaryButtonComponent> */}
                            <PrimaryButtonComponent
                                onClick={ () => handleStartTournamentSteps('enrolled_teams') }
                            >{props.t('options.tournaments.startTournament.nextBtn')}</PrimaryButtonComponent>
                        </div>
                    }
                    {// isTournamentPhases && props.tournament_phases.length === 0 && 
                       /*  <div className='tournament-start-tournament__btns-container'>
                            <SecondaryButtonComponent onClick={ () => handleStartTournametBackSteps('tournament_phases') } >{props.t('options.tournaments.startTournament.backBtn')}</SecondaryButtonComponent>
                        </div> */
                    }
                </div>
            </div>
        </div>
    )
}

export default withTranslation('league')(TournamentStartTournamentComponent)

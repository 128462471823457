/*eslint eqeqeq: "off"*/
// React
import React, { Component } from 'react';
import { connect, batch } from 'react-redux';

// Variables
import { team_form_id, player_form_id } from "config/GlobalData";

// i18n
import { withTranslation } from 'react-i18next';
// Redux
import {compose} from 'redux';

// Moment
import * as moment from 'moment';

// Locale
import 'moment/locale/en-au';
import 'moment/locale/es';

// Actions
import {loadPage} from 'app/pages/actions/PageActions';
import { 
    changeTournamentBasicDataError,
    changeNewMatchModalStateAction,
    changeNewMatchError,
    changeEndMatchModalStateAction,
    changeWarningLeagueWithNoTeamsModalStateAction,
    changeEndMatchError,
    setSelectedTournamentDateAction,
    resetTournamentMembersAction,
    resetTournamentTeamsAction,
    resetTournamentActivePhaseAction,
    resetTournamentPhaseTeamsAction,
    resetTournamentDatesAction,
    resetTournamentMatchesAction,
    resetTournamentPhasesAction,
    resetTournamentSelectedDateAction,
    changeEndTournamentModalStateAction,
    changeEndTournamnetError,
    changeActivePhaseGroupsCreateStateAction,
    setTournamentActivePhaseAction,
    setTournamentActivePhaseEditAction,
    changeMatchsToCloseModalStateAction,
    changeWarningLeavingPhaseStateAction,
    changeTeamsDisabledDialogModalStateAction,
    changeFinishFreePhaseModalStateAction,
    changeFinishGroupsPhaseModalStateAction,
    changeTournamentSaveTournamentModalStateAction, 
    changeAddTeamToGroupModalStateAction,
    changeRemoveMultiplePhasesModalStateAction,   
    changeUnfinishedTournamentMatchesStateAction,
    changeDeleteDateModalStateAction,
    changeCloseMatchModalStateAction,
    changeTournamentFinalizedSuggestionModalStateAction,
    cleanBaloaTeamsSearchAction,
    changeRoundtripFixtureModalStateAction,
    changeAddTeamAdviceModalStateAction,
    changeTeamDetailModalStateAction,
    changeStartTournamentModalStateAction,
    changeInviteTeamToTournamentModalStateAction,
    changeTeamInscriptionModalStateAction,
    changeInvitationInscriptionActionsModalStateAction,
    changeInscriptionViewFormDataModalStateAction,
    changePaymentInformationModalStateAction,
    changeTournamentDynamicFormsModalStateAction,
    disableNewTournamentSubmitButtonAction,
    changeTournamentStartInfoModalStateAction,
    changeTournamentTranferPlayerModalStateAction,
    changeAddStaffModalStateAction,
    cleanBaloaUserAction,
    resetRefereeFeeAction,
    changeEditPhaseModalStateAction,
    changeEditPhaseValidationModalStateAction,
    resetTeamsToMatchAssistanceAligment,
    changeRejectMultiplePlayerModalStateAction,
    //loadFormsAction,
    cleanPageFormTemplateAction,
    changeCardIdValidationModalStateAction,
    changeCardIdDeleteValidationModalStateAction,
    changeCardIdTeamsSelectModalStateAction,
    changeCardIdPdfGeneratorModalStateAction,
    changeCardIdExitValidationModalStateAction,
    changeCardIdComponentStateAction,
    changeCreateFieldDataError,
    changeFieldsAdminModalStateAction,
    changeFieldsCreateModalStateAction,
    changeFieldsWarningModalStateAction,
    changeTournamentFieldsAdminModalStateAction,
    changeTournamentAddExistingFieldsStateAction,
    changeTournamentFieldsWarningModalStateAction,
    changeFixtureMatchesAvailableDatesModalStateAction,
    changeFixtureMatchesBlockDatesModalStateAction,
    clearTournamentAvailableFixtureDatesAction,
    changeFixtureAddTeamToFieldModalStateAction,
    changeFixtureFieldsMatchesAvailableDatesModalStateAction,
    changeFixtureFieldsMatchesBlockDatesModalStateAction,
    changeAcceptAutomaticFixtureModalStateAction,
    cleanNewPageFieldAction,
    cleanTournamentPhaseGroupDatesFilterAction,
    changeMatchReportPdfModalStateAction,
    resetTournamentCountryCitiesAction,
    resetTournmentTeamPlayersAction,
    changeTournamentViewMoreStatisticsModalStateAction,
    changeTournamentEditMatchInfoModalStateAction,
    changeCreateEditFairPlaySettingsModalStateAction,
    changeFairPlaySelectSettingModalStateAction,
    setSelectedFairPlaySettingAction,
    changeFairPlayAddExtraPenaltyModalStateAction,
    setSelectedFairPlayPhaseAction,
    changeAddAssociateMatchModalStateAction,
    changeViewMoreSanctionsModalStateAction,
    changeConfirmDeleteSanctionsModalStateAction,
    changeConfirmReOpenPhaseModalStateAction,
} from 'app/leagues/actions/LeagueTournamentActionsCreators';

import { changeShareInscriptionLinkModalStateAction, changeShareIframeLinkStateAction } from 'app/tournaments/actions/TournamentActionsCreators';

// Match Actions
import { loadMatchReport } from '../actions/LeagueMatchActions';
import { 
    changeEditMatchModalStateAction,          
} from 'app/leagues/actions/LeagueMatchActionsCreators';

//import loadMatchReportAction from '../actions/LeagueMatchActionsCreators'

import { 
    createNewTournament,

    createNewGroupsPhases,
    
    NewMatch,
    editMatch,
    //loadTournamentDates, 
    loadTournamentPhaseGroupDates,
    //addDateToTournament, 
    addDateToTournamentGroup,
    loadDateMatches,
    loadLeagueTournaments,
    addMemberToTournament,
    deleteMemberFromTournament,
    addTeamToTournament,
    addInviteTeamToTournament,
    deleteTeamToTournament,
    disableTeamFromTournament,
    loadTournamentTeams,
    //loadTournamentMembers,  
    loadTournamentPhases,
    loadPhaseResultsTable,       
    finishTournament,
    deleteMatchGame,
    loadTournamentInfo,
    searchWorldCities,
    getCountries,
    searchCityByCountry,
    loadTournamentPlayers,
    addTeamToGroup,
    deleteTeamFromGroup,
    addTournamentPhase,
    //finishTournamentPhase,
    deleteTournamentPhase,
    deleteTournamentPhaseWihtOutType,
    classifiedTeamsByPhase,
    loadTournamentPhaseTeams,
    finishedMatchesByPhase,
    loadScorerTeamPageMembers,
    loadBeatenFenceTeamPageMembers,
    loadChampionTeamPageMembers,
    deleteDate,
    searchBaloaTeam,
    updateTournamentBasicInfo,
    setFinalFixture,
    searchBaloaMember,
    loadfilterTournament,
    changeTournamentTeamState, 
    changeTournamentState,
    loadTournamentTeamPlayers,
    changeTeamPlayerState,
    loadTournamentSetupTeams,
    searchBaloaTeamToInvited,
    loadFormData,
    setNewCriteriaTournamentPhase,
    createNewForm,
    getFieldsStatusEditing,
    loadTournamentPhaseFilterTeams,
    UpdateBestThirdToGroup,
    loadCriteriaTournamentPhase,
    EditPhaseById,
    transferTeamPlayer,
    transferTeamKnockout,
    setTournamentActivePhase,
    GetCodeFormTemplate,
    searchBaloaUser,
    addUserToStaff,
    loadTournamentStaff,
    updateStaffState,
    deleteStaff,
    loadRefeeringPaymentUrl,
    assignRefereesMatch,
    makeRefereePayment,
    calculateRefereeFee,
    loadTournamentPhaseInterGroupsTeams,
    loadPhaseTeamsNotAssigned,
    downloadFormData,
    downloadPaymentData,
    updateClassifiedCountGroup,
    createTournamentPhaseGroup,
    deleteGroupFromPhase,
    cleanAllGroupOfPhase,
    createAutomaticGroupPhases,
    endTournamentPhase,
    addAllTeamsToGroup,
    loadTeamsToMatchAssistanceAligment,
    updatePlayersAssistanceAligment,
    loadSearchRunnerUpTeamPlayers,
    loadSearchThirdPlaceTeamPlayers,
    loadSearchTournamentPlayerTeamPlayers,
    loadSearchRevelationTeamPlayers,
    enrollRejectMultiplePlayers,
    loadListFormExisting,
    loadPageFormTemplate,
    loadTournamentPlayerFormTemplate,
    createCarnetFormTemplate,
    saveCardIdFormInfo,
    saveCardIdFormInfoAndClean,
    loadCardIdTemplate,
    deleteCardIdTemplate,
    loadCardIdsData,
    loadPlayerCardIdData,
    loadTeamManagerAndStaff,
    loadStaffCardIdData,
    createPageFields,
    loadPageFieldsData,
    editPageFields,
    deletePageField,
    createTournamentFields,
    loadTournamentFieldsData,
    deleteTournamentField,
    loadAvailableTournamentFieldsData,
    setTournamentFixture,
    generateMatchesFixture,
    setTournamentFixtureAvailableDays,
    deleteFixtureAvailableDay,
    setTournamentFixtureLockedDays,
    deleteFixtureLockedDay,
    loadTournamentMatchesFilterData,
    newTournamentmatch,
    loadFixtureFieldTeams,
    addFixtureTeamsToField,
    deleteFixtureFieldTeam,
    setTournamentFieldFixtureAvailableDays,
    deleteFixtureFieldAvailableDay,
    setTournamentFixtureFieldLockedDays,
    deleteFixtureFieldLockedDay,
    loadFullFixtureConfig,
    loadGroupedAvailableFixtureDays,
    loadGroupedAvailableFieldFixtureDays,
    loadTournamentPhaseGroupDatesFilterData,
    editFixtureMatch,
    deleteFixture,
    searchTournamentTeamsAdmin,
    searchAdminTournamentTeamPlayers,
    loadPhaseGroupPositionsTable,
    loadTournamentScorersTable,
    loadYellowCardsTable,
    loadRedCardsTable,
    loadBlueCardsTable,
    loadAssistsTable,
    loadLeastDefeatedFenceTable,
    loadTeamsMostGoalsTable,
    loadFairPlayTable,
    loadSummaryTable,
    createFairPlaySettings,
    loadFairPlaySettings,
    loadPhaseSanctions,
    createPhaseExtraPenalty,
    loadPhaseMatchesByTeam,
    loadPhaseFairPlaySetting,
    updatePhaseExtraPenalty,
    deletePhaseExtraPenalty,
    loadAllTeamPlayers,
    reOpenPhase,
} from 'app/leagues/actions/LeagueTournamentActions';

import { 
    setMatchScore,
    loadLocalTeamPageMembers,
    loadVisitorTeamPageMembers,
    loadLocalTeamPageData,
    loadVisitorTeamPageData,
    setMatchScorers,
    setMatchChanges,
    setMatchPlayersCards,
    setMatchComments,
    getOfficialRefereeMember,
    getSecondRefereeMember,
    getThirdRefereeMember,
    getFourthRefereeMember,
    getRefereeAssignerMember,
} from 'app/leagues/actions/LeagueMatchActions';

import { 
    setMatchDataAction,
    cleanRefereesMemberAction,
    resetMatchDataAction,
    resetLocalTeamPageMembersAction,
    resetVisitorTeamPageMembersAction,
    resetLocalTeamPageDataAction,
    resetVisitorTeamPageDataAction,
} from 'app/leagues/actions/LeagueMatchActionsCreators';

import {loadLeagueMembers, loadLeagueTeams} from 'app/leagues/actions/LeagueActions';


import {     
    seeTournamentFromMemberOrTeamModalAction,
} from 'app/leagues/actions/LeagueActionsCreators';

import { changeErrorMessageSnackBar, changeMobileMenuStateAction } from 'app/home/actions/HomeActionsCreators';
import { addEventAction } from 'app/clickstream/actions/ClickstreamActionsCreators';
import { getTeamPlayers, loadTeamData, loadMemberPositionsData} from 'app/leagues/actions/LeagueTeamsActions';
import { followProfile, unfollowProfile } from 'app/profiles/actions/ProfileActions';

// Styles
import './TournamentView.styles.css';

// Component
import TournamentCreateNewTournamentV2Component from 'app/leagues/components/tournament-create-new-tournament-v2/TournamentCreateNewTournamentV2Component';
import LeagueTournamentComponent from 'app/leagues/components/league-tournament-component/LeagueTournamentComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import ModalConfirmDialogComponent from 'shared/components/modal-confirm-dialog/ModalConfirmDialogComponent';
import TournamentEditTournamentComponent from 'app/leagues/components/tournament-edit-tournament/TournamentEditTournamentComponent';
import LeagueMatchReportComponent from 'app/leagues/components/league-match-report-component/LeagueMatchReportComponent';
//import TournamentPhaseFixtureResumeComponent from 'app/leagues/components/tournament-phase-fixture-resume/TournamentPhaseFixtureResumeComponent';
import TournamentTeamsEnrollment from '../components/tournament-teams-enrollment/TournamentTeamsEnrollment';
import TournamentStartTournamentComponent from '../components/tournament-start-tournament/TournamentStartTournamentComponent';
import TournamentEditBasicDataComponentV2 from 'app/leagues/components/tournament-edit-basic-data/TournamentEditBasicDataComponentV2';
import TournamentPhaseEditComponent from 'app/leagues/components/tournament-phase-edit/TournamentPhaseEditComponent';
import TournamentPlayersInformationComponent from 'app/leagues/components/tournament-players-information/TournamentPlayersInformationComponent';
import TournamentPhasesContainerComponent from 'app/leagues/components/tournament-phases-container/TournamentPhasesContainerComponent';
import TournamentCardIdCreatorComponent from 'app/leagues/components/tournament-cardid-creator/TournamentCardIdCreatorComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

// Modals
import TournamentNewMatchModal from 'app/leagues/components/tournament-new-match-modal/TournamentNewMatchModal';
import TournamentEndMatchModalComponent from '../components/Tournament-end-match-modal/TournamentEndMatchModalComponent';
import EndTournamentMatchModal from 'app/leagues/components/league-end-tournament-modal/EndTournamentMatchModal';
import TournamentEditMatchModal from 'app/leagues/components/tournament-edit-match-modal/TournamentEditMatchModal';
import TournamnentMatchesToCloseModal from 'app/leagues/components/tournament-matches-to-close-modal/TournamentMatchesToCloseModalComponent';
import TournamentFinishFreePhaseModal from 'app/leagues/components/tournament-finish-free-phase-modal/TournamentFinishFreePhaseModalComponent';
import TournamentFinishGroupsPhaseModal from 'app/leagues/components/tournament-finish-groups-phase-modal/TournamentFinishGroupsPhaseModalComponent';
import TournamentWarningWhenLeavePhasesModal from 'app/leagues/components/tournament-warning-leave-phases/TournamentWarningWhenLeavePhasesModal';
import TournamentSaveTournamentModalComponent from 'app/leagues/components/tournament-save-tournament-modal/TournamentSaveTournamentModalComponent';
import TournamentWarningNoTeamsLeagueModalComponent from 'app/leagues/components/tournament-warning-no-teams-in-league-modal/TournamentWarningNoTeamsLeagueModalComponent';
import TournamentAddTeamToGroupModalComponent from 'app/leagues/components/tournament-add-team-to-group-modal/TournamentAddTeamToGroupModalComponent';
import TournamentRemoveMultiplePhasesModalComponent from 'app/leagues/components/tournament-remove-multiple-phases/TournamentRemoveMultiplePhasesModalComponent';
import TournamentDeleteDateModalComponent from 'app/leagues/components/Tournament-delete-date-modal/TournamentDeleteDateModalComponent';
import TournamentCloseMatchModalComponent from 'app/leagues/components/tournament-close-match-modal/TournamentCloseMatchModalComponent';
import TournamentFinalizeTournamentSuggestionModalComponent from 'app/leagues/components/tournament-finalize-tournament-suggestion-modal/TournamentFinalizeTournamentSuggestionModalComponent';
import PhaseRoundtripFixtureSelectorModalComponent from '../components/phase-roundtrip-fixture-selector-modal/PhaseRoundtripFixtureSelectorModalComponent';
import AddPhaseTeamWarningModalComponent from 'app/leagues/components/add-phase-team-warning-modal/AddPhaseTeamWarningModalComponent';
import PageTeamDetailModalComponent from 'app/pages/components/page-team-detail-modal/PageTeamDetailModalComponent';
import TournamentStartTournamentModalComponent from 'app/leagues/components/tournament-start-tournament-modal/TournamentStartTournamentModalComponent';
import TournamentModalInvitedTeamComponent from 'app/leagues/components/tournament-list-invited-teams/TournamentModalInvitedTeamComponent';
//import TournamentTeamInscriptionModalComponent from '../components/tournament-team-inscription-modal/TournamentTeamInscriptionModalComponent';
import TournamentInvitationInscriptionActionsModalComponent from 'app/leagues/components/tournament-invitation-inscription-actions-modal/TournamentInvitationInscriptionActionsModalComponent';
import TournamentInscriptionViewFormDataModalComponent from 'app/leagues/components/tournament-inscription-view-form-data-modal/TournamentInscriptionViewFormDataModalComponent';
import TournamentPaymentInfoModalComponent from '../components/tournament-payment-info-modal/TournamentPaymentInfoModalComponent';
import TournamentDynamicFormsModalComponent from '../components/tournament-dynamic-forms-modal/TournamentDynamicFormsModalComponent';
import TournamentEditFormComponent from '../components/tournament-edit-form-modal/TournamentEditFormModalComponent';
import TournamentStartInfoModalComponent from '../components/tournament-start-info-modal/TournamentStartInfoModalComponent';
import TournamentTransferPlayerModalComponent from 'app/leagues/components/tournament-transfer-player-modal/TournamentTransferPlayerModalComponent';
import TournamentGeneralEndPhasesComponent from 'app/leagues/components/tournament-general-end-phases/TournamentGeneralEndPhasesComponent';
import ShareInscriptionLinkModalComponent from 'app/tournaments/components/share-inscription-link-modal/ShareInscriptionLinkModalComponent';
import ShareIframeLinkModalComponent from 'app/tournaments/components/share-iframe-link-modal/ShareIframeLinkModalComponent';
import TournamentAddStaffModalComponent from '../components/tournament-add-staff-modal/TournamentAddStaffModalComponent';
import TournamentEditPhaseModalComponent from '../components/tournament-edit-phase-modal/TournamentEditPhaseModalComponent';
import TournamentEditPhaseValidationModalComponent from '../components/tournament-edit-phase-validation-modal/TournamentEditPhaseValidationModalComponent';
import TournamentRejectMultiplePlayersModalComponent from 'app/leagues/components/tournament-reject-multiple-players-modal/TournamentRejectMultiplePlayersModalComponent';
import TournamentCardIdValidationModalComponent from 'app/leagues/components/tournament-card-id-validation-modal/TournamentCardIdValidationModalComponent';
import TournamentCardIdDeleteValidationModalComponent from 'app/leagues/components/tournament-card-id-delete-validation-modal/TournamentCardIdDeleteValidationModalComponent';
import TournamentCardIdTeamsSelectionModalComponent from 'app/leagues/components/tournament-card-id-teams-selection-modal/TournamentCardIdTeamsSelectionModalComponent';
import TournamentCardIdPdfGeneratorModalComponent from 'app/leagues/components/tournament-card-id-pdf-generator-modal/TournamentCardIdPdfGeneratorModalComponent';
import TournamentCardIdExitValidationModalComponent from 'app/leagues/components/tournament-card-id-exit-validation-modal/TournamentCardIdExitValidationModalComponent';
import TournamentPageFieldsAdminModalComponent from '../components/tournament-page-fields-admin-modal/TournamentPageFieldsAdminModalComponent';
import TournamentFieldsFormModalComponent from '../components/tournament-fields-form-modal/TournamentFieldsFormModalComponent';
import TournamentFieldsWarningModalComponent from '../components/tournament-fields-warning-modal/TournamentFieldsWarningModalComponent';
import TournamentFieldsAdminModalComponent from '../components/tournament-fields-admin-modal/TournamentFieldsAdminModalComponent';
import TournamentAddExistingFieldsModalComponent from '../components/tournament-add-existing-fields-modal/TournamentAddExistingFieldsModalComponent';
import TournamentFixtureMatchesAvailableDatesModalComponent from 'app/leagues/components/tournament-fixture-matches-available-dates-modal/TournamentFixtureMatchesAvailableDatesModalComponent';
import TournamentFixtureMatchesLockedDatesModalComponent from 'app/leagues/components/tournament-fixture-matches-locked-dates-modal/TournamentFixtureMatchesLockedDatesModalComponent';
import TournamentFixtureAddTeamToFieldModalComponent from 'app/leagues/components/tournament-fixture-add-team-to-field-modal/TournamentFixtureAddTeamToFieldModalComponent';
import TournamentFixtureAcceptModalComponent from 'app/leagues/components/tournament-fixture-accept-modal/TournamentFixtureAcceptModalComponent';
import TournamentMatchReportPdfModalComponent from 'app/leagues/components/tournament-match-report-pdf-modal/TournamentMatchReportPdfModalComponent';
import TournamentViewMoreStatisticsModalComponent from 'app/leagues/components/tournament-view-more-statistics-modal/TournamentViewMoreStatisticsModalComponent';
import TournamentMatchEditMainInfoModalComponent from '../components/tournament-match-edit-main-info-modal/TournamentMatchEditMainInfoModalComponent';
import TournamentPhasesCreateEditFairPlaySettingsModalComponent from 'app/leagues/components/tournament-phases-create-edit-fair-play-settings-modal/TournamentPhasesCreateEditFairPlaySettingsModalComponent';
import TournamentFairPlayAddExtraPenaltyModalComponent from 'app/leagues/components/tournament-fair-play-add-extra-penalty/TournamentFairPlayAddExtraPenaltyModalComponent';
import TournamentPhasesFairPlaySelectSettingModalComponent from '../components/tournament-phases-fair-play-select-setting-modal/TournamentPhasesFairPlaySelectSettingModalComponent';
import TournamentPhasesViewMoreSanctionsModalComponent from '../components/tournament-phases-view-more-sanctions-modal/TournamentPhasesViewMoreSanctionsModalComponent';
import TournamentPhasesAddAssociateMatchToSanctionModalComponent from 'app/leagues/components/tournament-phases-add-associate-match-modal/TournamentPhasesAddAssociateMatchToSanctionModalComponent';
import TournamentPhaseDeleteExtraSanctionConfirmationModalComponent from 'app/leagues/components/tournament-phase-delete-extra-sanction-confirm-modal/TournamentPhaseDeleteExtraSanctionConfirmationModalComponent';

//Functions
import { permissionsController } from 'shared/functions/PemissionsController';

//Material
import ErrorIcon from "@mui/icons-material/Error";

// Variables
import { web_url } from "config/GlobalData";
import TournamentPhaseConfirmReopenPhaseModalComponent from '../components/tournament-phase-confirm-reopen-phase-modal/TournamentPhaseConfirmReopenPhaseModalComponent';

class TournamentView extends Component {
    constructor( props ) {
        super( props );
        this.pagename = this.props.match.params.pagename;       
        this.showtournament = this.props.match.params.showtournament? this.props.match.params.showtournament : false ; 
        this.setshowtournament = this.props.match.params.setshowtournament? this.props.match.params.setshowtournament : undefined ; 
        this.state = {                        
            new_tournament: this.props.state.leagues.see_tournament_from_team_member_modal ? false : false,
            edit_tournament: this.props.state.leagues.see_tournament_from_team_member_modal ? true : false,
            selected_tournament: {},
            selected_active_tournament: {},
            selected_completed_tournament: {},
            disabled_edit_tournament_data: false,
            show_match_report: false,
            //selected_edit_row: {},
            enable_add_match_button: false,
            phase: null,
            active_group: undefined,
            count_loaded_dates: 0,
            loaded_tournament: true,
            selected_to_disabled_team: undefined,
            date: null,
            index_date: null,
            group_date: null,
            end_match_reminder: false,
            comments: null,
            clean_data: false,
            tournament_tag: [
                { value: 'Free', content: props.t("options.tournaments.pages.newTournament.tabs.tabTeams.Free") },
                { value: 'sub-23', content: props.t("options.tournaments.pages.newTournament.tabs.tabTeams.sub-23") },
                { value: 'sub-22', content: props.t("options.tournaments.pages.newTournament.tabs.tabTeams.sub-22") },
                { value: 'sub-21', content: props.t("options.tournaments.pages.newTournament.tabs.tabTeams.sub-21") },
                { value: 'sub-20', content: props.t("options.tournaments.pages.newTournament.tabs.tabTeams.sub-20") },
                { value: 'sub-19', content: props.t("options.tournaments.pages.newTournament.tabs.tabTeams.sub-19") },
                { value: 'sub-18', content: props.t("options.tournaments.pages.newTournament.tabs.tabTeams.sub-18") },
                { value: 'sub-17', content: props.t("options.tournaments.pages.newTournament.tabs.tabTeams.sub-17") },
                { value: 'sub-16', content: props.t("options.tournaments.pages.newTournament.tabs.tabTeams.sub-16") },
                { value: 'sub-15', content: props.t("options.tournaments.pages.newTournament.tabs.tabTeams.sub-15") },
                { value: 'sub-14', content: props.t("options.tournaments.pages.newTournament.tabs.tabTeams.sub-14") },
                { value: 'sub-13', content: props.t("options.tournaments.pages.newTournament.tabs.tabTeams.sub-13") },
                { value: 'sub-12', content: props.t("options.tournaments.pages.newTournament.tabs.tabTeams.sub-12") },
                { value: 'sub-11', content: props.t("options.tournaments.pages.newTournament.tabs.tabTeams.sub-11") },
                { value: 'sub-10', content: props.t("options.tournaments.pages.newTournament.tabs.tabTeams.sub-10") },
                { value: 'sub-9', content: props.t("options.tournaments.pages.newTournament.tabs.tabTeams.sub-9") },
                { value: 'sub-8', content: props.t("options.tournaments.pages.newTournament.tabs.tabTeams.sub-8") },
                { value: 'sub-7', content: props.t("options.tournaments.pages.newTournament.tabs.tabTeams.sub-7") }
            ],
            is_tournament_finalized: false,            
            edit_basic_info: false,
            show_fixture_resume: false,
            loading_fixture_state: false,
            fixture_button_active: true,
            phase_teams: 0,
            phase_dates: 0,
            tabAfterFixture: undefined,
            selected_to_add_team: undefined,
            data_match_end : null,
            can_edit_match : false,
            data_match_end_step : undefined,
            state_match : undefined,
            football_type: [],
            tournament_teams_enrollment_component: false,
            start_tournament_component: false,
            // filter_tournament:{},
            invitation_inscription_modal_actions_mainLabel: '',
            invitation_inscription_modal_actions_secondaryLabel: '',
            invitation_inscription_modal_actions_acceptBtn: '',
            invitation_inscription_modal_actions_cancelBtn: '',
            accept_team_inscription_btn: false,
            selected_team: [],
            team_state: '',
            change_tournament_state: '',
            change_player_state: '',
            team_player: [],
            team_player_payment_info: [],
            team_player_payment_currency: '',
            preview_dynamic_form: [],
            is_best_third: false,
            state_edit_form_modal: false,
            modal_type_edit_form: '',
            modal_confirmation_back_edit: false,
            switch_status_teams: false,
            modal_type_invited_inscription: '',
            match_action: {},
            edit_phase_component: false,
            change_phase_state:'',
            phase_inf_edit:{},
            is_player_information: false,
            selected_player: [],
            tournament_player_id: '', 
            teamname: '',
            team_id:'',
            is_transfer_player: false,
            teams_transfer_list: [],
            general_end_phases:false,
            share_inscription_url: undefined,
            share_iframe_url: undefined,
            add_staff_modal_title: undefined,
            share_add_staff_url: undefined,
            staff_role: undefined,
            delete_staff: undefined,
            add_staff_modal_subtitle: undefined,
            add_staff_modal_share_title: undefined,
            add_staff_modal_share_note: undefined,
            set_type: undefined,
            is_referees_payment_response: false,
            edit_phase_type: '',
            edit_phase_selected_group: [],
            create_phase_component: false,
            modal_validation_message: '',
            phase_action: '',
            state_phase_actions:false,
            click_source_create_phase: '',
            is_phase_finalizing: false,
            api_dates_calling: undefined,
            teams_to_group: false,
            preSelectedTab: 0,
            teams_filter_type: 'not_assigned',
            tournament_type: [],
            type_inscription_link_modal: '',
            rejected_multiple_players: [],
            listFormExisting: [],
            cardIdInfoData: null,
            downloadCardIdData: null,
            field_action_type: '',
            page_field: {},
            is_tournament_field: false,
            tournament_available_fields: [],
            availableTime: [],
            availableDays: [],
            fixture_current_tournament_field_id: null,
            availableFieldTime: [],
            availableFieldDays: [],
            tournament_fields: [],
            tournament_dates: [],
            tournament_selected_date: undefined,
            tournament_phase_teams: [],
            tournament_dates_filtered: [],
            phase_calendar: 0,
            tournament_staff: [],
            statistic_data_type: null,
            endingTournament: false,
            modal_statistic_data: [],
            next_modal_statistic_data: null,
            loading_modal_statistic_data: false,
            fair_play_setting_state: null,
            base_edit_fair_play_setting: null,
            sanction_associate_match: null,
            penalty_to_edit: null,
            penalty_to_delete: null,
            filter_penalty_type: 'Sport',
        }
        this.referees_payment_response = this.props.match?.params;
    }


    async componentDidMount() {
        this.props.loadPage( this.pagename );        
        this.props.loadLeagueTournaments( this.pagename );
        this.props.loadLeagueTeams( this.pagename );
        this.props.loadLeagueMembers( this.pagename );
        this.props.loadPageFieldsData( this.pagename );
        /* if(this.props.state.leagueTournaments?.tournament.id){
            console.log('entro por 1');
            this.props.loadTournament( this.props.state.leagueTournaments?.tournament.id );
            this.props.loadTournamentSetupTeams(this.props.state.leagueTournaments?.tournament.id, '')
            this.setState({loaded_tournament: true});
        } */

        if(this.props.location.state?.idTournament){
            await this.props.loadTournament( this.props.location.state.idTournament );
            this.setState({loaded_tournament: true});
            this.handleOnSelectedTournamentHub(this.props.state.leagueTournaments?.tournament);
            this.props.history.replace({ state: {} });
        }
        if(this.props.state.leagues.see_tournament_from_team_member_modal){           
            this.props.resetTournament({});   
            this.props.loadTournament( this.props.state.leagues.see_tournament_from_team_member_modal.tournament.tournament_id );
            this.setState({loaded_tournament: true});
            this.props.seeTournamentFromMemberOrTeamModal(null);
        }
        this.props.changeMobileMenuStateAction(false);
    }

    componentWillUnmount () {
      
        //console.log("Component will unmount");
        
    }

    componentDidUpdate(prevProps) {
        if (this.props.state.leagueTournaments.tournament.state !== 'Cancelled' && this.props.state.leagueTournaments.tournament.state !== prevProps.state.leagueTournaments.tournament.state && prevProps.state.leagueTournaments.tournament.state !== undefined && prevProps.state.leagueTournaments.tournament.id === this.props.state.leagueTournaments.tournament.id ) {
            this.handleViewEndedTournament(this.props.state.leagueTournaments.tournament)
        }
        if(JSON.stringify(this.props.state.leagueTournaments.tournament) != JSON.stringify(prevProps.state.leagueTournaments.tournament)){
            this.setState({selected_tournament: {id: this.props.state.leagueTournaments.tournament.id, name: this.props.state.leagueTournaments.tournament.name,
                category: this.props.state.leagueTournaments.tournament.category,tournament_gender: this.props.state.leagueTournaments.tournament.tournament_gender,
                league_page: this.props.state.leagueTournaments.tournament.league_page, start_date: this.props.state.leagueTournaments.tournament.start_date,
                end_date: this.props.state.leagueTournaments.tournament.end_date, 
                fair_play_initial_points: this.props.state.leagueTournaments.tournament.fair_play_initial_points, fair_play_most_points_wins: this.props.state.leagueTournaments.tournament.fair_play_most_points_wins,
                description: this.props.state.leagueTournaments.tournament.description, 
                state: this.props.state.leagueTournaments.tournament.state, main_city: this.props.state.leagueTournaments.tournament.main_city, 
                main_country: this.props.state.leagueTournaments.tournament.main_country, champion: this.props.state.leagueTournaments.tournament.champion, 
                champion_name: this.props.state.leagueTournaments.tournament.champion_name,  champion_page_owner: this.props.state.leagueTournaments.tournament.champion_page_owner,
                champion_photo: this.props.state.leagueTournaments.tournament.champion_photo, runner_up: this.props.state.leagueTournaments.tournament.runner_up,
                runner_up_name: this.props.state.leagueTournaments.tournament.runner_up_name, runner_up_page_owner: this.props.state.leagueTournaments.tournament.runner_up_page_owner, 
                runner_up_photo: this.props.state.leagueTournaments.tournament.runner_up_photo, third_place: this.props.state.leagueTournaments.tournament.third_place, 
                third_place_name: this.props.state.leagueTournaments.tournament.third_place_name, third_place_page_owner: this.props.state.leagueTournaments.tournament.third_place_page_owner,
                third_place_photo: this.props.state.leagueTournaments.tournament.third_place_photo, scorer: this.props.state.leagueTournaments.tournament.scorer, scorer_name: this.props.state.leagueTournaments.tournament.scorer_name, 
                scorer_photo: this.props.state.leagueTournaments.tournament.scorer_photo, team_scorer_page_owner: this.props.state.leagueTournaments.tournament.team_scorer_page_owner, champion_coach: this.props.state.leagueTournaments.tournament.champion_coach ,
                champion_coach_name: this.props.state.leagueTournaments.tournament.champion_coach_name, champion_coach_photo: this.props.state.leagueTournaments.tournament.champion_coach_photo, 
                least_beaten_fence: this.props.state.leagueTournaments.tournament.least_beaten_fence, least_beaten_fence_name: this.props.state.leagueTournaments.tournament.least_beaten_fence_name,
                team_least_beaten_fence_page_owner: this.props.state.leagueTournaments.tournament.team_least_beaten_fence_page_owner, least_beaten_fence_photo: this.props.state.leagueTournaments.tournament.least_beaten_fence_photo, count_teams: this.props.state.leagueTournaments.tournament.count_teams, 
                organizer: this.props.state.leagueTournaments.tournament.organizer, team_least_beaten_fence: this.props.state.leagueTournaments.tournament.team_least_beaten_fence,
                team_scorer: this.props.state.leagueTournaments.tournament.team_scorer, team_least_beaten_fence_photo: this.props.state.leagueTournaments.tournament.team_least_beaten_fence_photo, 
                team_scorer_photo: this.props.state.leagueTournaments.tournament.team_scorer_photo, tournament_tag: this.props.state.leagueTournaments.tournament.tournament_tag, tournament_referee_payment_config: this.props.state.leagueTournaments.tournament.tournament_referee_payment_config,
                referee_payments: this.props.state.leagueTournaments.tournament.referee_payments, currency_payment: this.props.state.leagueTournaments.tournament.currency_payment,
                revelation_player: this.props.state.leagueTournaments.tournament.revelation_player, revelation_player_name: this.props.state.leagueTournaments.tournament.revelation_player_name, revelation_player_photo: this.props.state.leagueTournaments.tournament.revelation_player_photo,
                runner_up_coach: this.props.state.leagueTournaments.tournament.runner_up_coach, runner_up_coach_name: this.props.state.leagueTournaments.tournament.runner_up_coach_name, runner_up_coach_photo: this.props.state.leagueTournaments.tournament.runner_up_coach_photo,
                team_name_least_beaten_fence: this.props.state.leagueTournaments.tournament.team_name_least_beaten_fence, team_name_scorer: this.props.state.leagueTournaments.tournament.team_name_scorer,
                team_revelation_player: this.props.state.leagueTournaments.tournament.team_revelation_player, team_revelation_player_name: this.props.state.leagueTournaments.tournament.team_revelation_player_name, team_revelation_player_photo: this.props.state.leagueTournaments.tournament.team_revelation_player_photo,
                team_tournament_player: this.props.state.leagueTournaments.tournament.team_tournament_player, team_tournament_player_name: this.props.state.leagueTournaments.tournament.team_tournament_player_name, team_tournament_player_photo: this.props.state.leagueTournaments.tournament.team_tournament_player_photo,
                third_place_coach: this.props.state.leagueTournaments.tournament.third_place_coach, third_place_coach_name: this.props.state.leagueTournaments.tournament.third_place_coach_name, third_place_coach_photo: this.props.state.leagueTournaments.tournament.third_place_coach_photo,
                total_goals_scorer: this.props.state.leagueTournaments.tournament.total_goals_scorer, total_goals_conceded: this.props.state.leagueTournaments.tournament.total_goals_conceded,
                tournament_player: this.props.state.leagueTournaments.tournament.tournament_player, tournament_player_name: this.props.state.leagueTournaments.tournament.tournament_player_name, tournament_player_photo: this.props.state.leagueTournaments.tournament.tournament_player_photo,
                active_players: this.props.state.leagueTournaments.tournament.active_players, inactive_players: this.props.state.leagueTournaments.tournament.inactive_players, team_total_values_stats: this.props.state.leagueTournaments.tournament.team_total_values_stats,
            }
            });
        }
        if( prevProps.state.leagueTournaments.tournament?.name !== this.props.state.leagueTournaments.tournament?.name ){    
            this.props.addEventAction({Datetime: new Date().toISOString(),EventData: {EventType: 'tournament-view', TournamentId: this.props.state.leagueTournaments.tournament?.id }});  //set Clickstream profile-visit event            
        }
        
        if(this.showtournament && this.setshowtournament && this.props.state.leagueTournaments.league_tournaments && (prevProps.state.leagueTournaments.league_tournaments !== this.props.state.leagueTournaments.league_tournaments)){
            let filtered_tournament =this.props.state.leagueTournaments.league_tournaments?.filter( tournament => tournament.id == this.setshowtournament);
            if(filtered_tournament[0].state === "Finalized"){
                this.handleOnCompletedTournament(filtered_tournament[0]);
            }else{
                this.handleOnActiveTournament(filtered_tournament[0]);
            }            
        }

        if(this.state.selected_team?.length !== 0 && this.props.state.leagueTournaments.tournament?.tournament_teams !== prevProps.state.leagueTournaments.tournament?.tournament_teams) {
            let selected_team = this.props.state.leagueTournaments.tournament?.tournament_teams.find(team => team?.teamname === this.state.selected_team?.teamname )
            this.setState({selected_team: selected_team});
        }  

        if( this.props.match.params && this.props.match.params?.type === 'referee' && (prevProps.state.leagueTournaments.league_tournaments !== this.props.state.leagueTournaments.league_tournaments)) {
            let tournament = this.props.state.leagueTournaments.league_tournaments.find( tournament => tournament.id === this.props.match.params?.tournamentid )
            this.handleOnActiveTournament(tournament);
            let selected_row = {
                id: this.props.match.params?.match_id
            }
            this.viewMatchReport(selected_row);
            this.setState({is_referees_payment_response: true});
        }

        if(prevProps.state.leagueTournaments.tournament?.is_phase_finalized !== this.props.state.leagueTournaments.tournament.is_phase_finalized) {
            this.setState({is_phase_finalizing: false});
        }

        //Actualizacion del carnet con la primera info -Imagen plantilla y Orientacion
        if(this.props.state.leagueTournaments.tournament.carnet_id_template && this.props.state.leagueTournaments.tournament.card_id_orientation && (prevProps.state.leagueTournaments.tournament.carnet_id_template !== this.props.state.leagueTournaments.tournament.carnet_id_template)){
            this.prepareFirstCardIdFormData(this.props.state.leagueTournaments.tournament.carnet_id_template, this.props.state.leagueTournaments.tournament.card_id_template_image, this.props.state.leagueTournaments.tournament.card_id_orientation)
        }

        /** Se valida la info de los carnets para crear el objeto que va al componente de pdf */
        if (prevProps.state.leagueTournaments?.card_ids_data?.results !== this.props.state.leagueTournaments?.card_ids_data?.results){
            this.prepareCardIdsPDFData();
        }

        if (prevProps.state.leagueTournaments?.grouped_fixture_available_days !== this.props.state.leagueTournaments?.grouped_fixture_available_days){
            if(this.props.state.leagueTournaments?.grouped_fixture_available_days.length >0){
                this.setState({availableTime: []});
                this.setState({availableDays: []});
                const timeoutDates = setTimeout(() => {
                    //eslint-disable-next-line array-callback-return
                    this.props.state.leagueTournaments?.grouped_fixture_available_days.map(date=> {
                        this.addAvailableDateToState(date);
                    })
                }, 500);
                return () => clearTimeout(timeoutDates);
            }
        }
        
        if (prevProps.state.leagueTournaments?.grouped_fields_fixture_available_days !== this.props.state.leagueTournaments?.grouped_fields_fixture_available_days){
            if(this.props.state.leagueTournaments?.grouped_fields_fixture_available_days.length >0){
                this.addAvailableFieldDateToState();
            }else{
                this.setState({availableFieldTime: [], availableFieldDays: []});
            }
        }

        /** Verifica las acciones sobre canchas de una pagina/torneo */
        if ( this.props.state.leagueTournaments?.tournament_fields && (prevProps.state.leagueTournaments?.tournament_fields !== this.props.state.leagueTournaments?.tournament_fields) ){
            this.handleTournamentMatchAdminTournamentFields();
        }

        /** Verifica las acciones sobre jornadas(fechas) de un torneo */
        if ( /* this.props.state.leagueTournaments.tournament?.tournament_dates && */ (prevProps.state.leagueTournaments.tournament?.tournament_dates !== this.props.state.leagueTournaments.tournament?.tournament_dates) ){
            this.handleTournamentMatchAdminTournamentDates();
        }    

        /** Verifica las acciones sobre jornadas(fechas) filtradas de un torneo */
        if ( this.props.state.leagueTournaments.tournament_dates_filter && (prevProps.state.leagueTournaments.tournament_dates_filter !== this.props.state.leagueTournaments?.tournament_dates_filter) ){
            this.handleTournamentMatchAdminTournamentDatesFiltered();
        }

        /** Verifica las acciones sobre el staff torneo y actualiza lista para filtros admin */
        if ( this.props.state.leagueTournaments?.tournament?.tournament_staff && (prevProps.state.leagueTournaments?.tournament?.tournament_staff !== this.props.state.leagueTournaments?.tournament?.tournament_staff) ){
            this.handleTournamentMatchAdminTournamentStaff();
        }
    }


    handleOnAddTeamToTournament( selected_row ) {
        this.props.addTeamToTournament( this.props.state.leagueTournaments?.tournament.id, selected_row.teamname, selected_row.state );
        this.props.loadTournament( this.props.state.leagueTournaments?.tournament.id);
    }

    handleOnAddInviteTeamToTournament (team_to_invite){
        this.props.addInviteTeamToTournament(team_to_invite.id_tournament_team, team_to_invite.state_invite_team);
    }

    handleOnDeleteTeamFromTournament(selected_row) {  
        this.props.deleteTeamToTournament(  selected_row.id, this.props.state.leagueTournaments?.tournament.id )          
    }

    handleOnAddMemberToTournament(selected_row) {        
        this.props.addMemberToTournament(  this.props.state.leagueTournaments?.tournament.id, selected_row.username );                
    }

    handleOnDeleteMemberFromTournament(selected_row) {
        this.props.deleteMemberFromTournament( selected_row.id );
    }

    handleOnNewTournamentDate() {
        const dateName = "Fecha " + (this.props.state.leagueTournaments?.tournament.tournament_dates.length + 1);
        //this.setState( { enable_add_match_button: true } );       
        //this.props.addDateToTournament( this.props.state.leagueTournaments?.tournament.id, dateName );
        this.props.addDateToTournamentGroup( this.state.active_group.id, dateName );
    }

    //START: Necesario validar estado del torneo no trae id para estos metodos
    handleOnAddEditTeamToTournament( selected_row ) {   
        
        if(this.props.state.leagueTournaments?.tournament?.tournament_phases.length > 1){  
            this.setState( { selected_to_add_team: selected_row } );       
            this.props.changeAddTeamAdviceModalStateAction(true);    
        }else{
            this.addPhaseTeam(selected_row);
        }      
    }

    handleOnDeleteEditTeamFromTournament(selected_row) {        
        this.props.deleteTeamToTournament(  selected_row.id, this.state.selected_tournament.id )          
        this.props.loadTournament( this.state.selected_tournament.id );             
    }

    handleOnDisableEditTeamFromTournament(selected_row) {     
        this.setState( { selected_to_disabled_team: selected_row } ); 
        this.props.changeTeamsDisabledDialogModalStateAction( true );
        //this.props.disableTeamFromTournament(  selected_row.id, this.state.selected_tournament.id )          
    }

    handleOnAcceptDisableTeamFromTournament(){
        this.props.disableTeamFromTournament(  this.state.selected_to_disabled_team.id, this.state.selected_tournament.id );
        this.setState( { selected_to_disabled_team: undefined } ); 
        this.props.changeTeamsDisabledDialogModalStateAction( false );
    }
    
    handleOnAddEditMemberToTournament(selected_row) {        
        this.props.addMemberToTournament(  this.state.selected_tournament.id, selected_row.username );   
        this.props.loadTournament( this.state.selected_tournament.id );             
    }


    handleOnNewEditTournamentDate() {
        const dateName = "Fecha " + (this.props.state.leagueTournaments?.tournament.tournament_dates.length + 1);   
        //this.setState( { enable_add_match_button: true } );                
        //this.props.addDateToTournament( this.state.selected_tournament.id, dateName );
        this.props.addDateToTournamentGroup( this.state.active_group.id, dateName );
                     
    }
    //END: Necesario validar estado del torneo no trae id para estos metodos
    async callDateMatches( date_id){
        //console.log('calling date matches 1',new Date().toISOString());
        await this.props.loadDateMatches(date_id);
        //console.log('calling date matches 2',new Date().toISOString());
        return true;
    }

    async handleOnTournamentDate(date){
        this.props.setSelectedTournamentDate( date );
        if(date?.id){
            await this.props.loadDateMatches(date.id);
        }else{
            if(!this.props.state.leagueTournaments.date_matches_is_loading ){
                this.props.resetTournamentMatchesAction( {} );
            }
        }
        this.setState( { enable_add_match_button: true } );   
    }

    async handleOnGroupClick( group, tab_click ){    
        this.props.resetTournamentGroup({});
        this.setState( { active_group: group } );          
        if(group !== undefined ){
            await this.props.loadTournamentPhaseGroupDates(group.id); 
            this.handleTournamentMatchAdminTournamentDates();  
            this.handleTournamentMatchAdminTournamentDatesFiltered();                
        }
    }
    
    newTournament () {
        this.setState({football_type: this.handleTranslateFootballTypes()});
        this.setState({tournament_type: this.handleTranslateTournamentType()})
        this.props.resetTournament({});
        this.props.GetCodeFormTemplate();

        //this.props.state.leagues?.league_teams?.results
        //this.props.state.leagues.league_teams_is_loading

        /*if(this.props.state.leagues && this.props.state.leagues?.league_teams 
            && this.props.state.leagues.league_teams.results 
            && this.props.state.leagues.league_teams.results.length > 1){

            this.setState( { enable_add_match_button: false } );
            this.setState( { new_tournament: true } );                
            this.setState( { edit_tournament: false } );

        }else{

            this.props.changeWarningLeagueWithNoTeamsModalStateAction(true);

        }*/

        this.setState( { enable_add_match_button: false } );
        this.setState( { new_tournament: true } );                
        this.setState( { edit_tournament: false } );


        // if(this.props.state.leagues?.league_teams?.results.length > 18){

        //     this.setState( { enable_add_match_button: false } );
        //     this.setState( { new_tournament: true } );                
        //     this.setState( { edit_tournament: false } );

        // }
        
    }

    async handleOnActiveTournament (selected_row) {
        this.props.loadCardIdTemplate(selected_row.id);//busca la plantilla de carnets
        if(selected_row.state === "Registration_Open"){
            this.setState( { tournament_teams_enrollment_component: true } );
            this.setState( { edit_tournament: false } );            
        }else{
            await this.props.getCountries(selected_row.main_country);
            await this.props.searchCityByCountry( this.props.state.leagueTournaments.countryId, selected_row.main_city )
            await this.props.loadTournamentFieldsData( selected_row.id );
            this.handleTournamentMatchAdminTournamentFields();
            this.props.loadAvailableTournamentFieldsData( this.props.state.page.page?.pagename, selected_row.id );
            this.setState( { edit_tournament: true } );
            this.setState( { tournament_teams_enrollment_component: false } );
        }
        this.setState( { new_tournament: false } );          
        this.setState( { selected_tournament: selected_row } );
        this.setState({football_type: this.handleTranslateFootballTypes()});

        this.setState( { disabled_edit_tournament_data: false } )                     
        this.props.resetTournament({});
        this.props.loadTournament( selected_row.id );
        //this.props.loadTournamentSetupTeams(selected_row.id, '');
    }

    handleOnCompletedTournament (selected_row) {                 
        this.setState( { edit_tournament: true } );
        this.setState( { new_tournament: false } );                
        this.setState( { selected_tournament: selected_row } )
        this.setState( { disabled_edit_tournament_data: true } ) 
        this.props.resetTournament({});            
        this.props.loadTournament( selected_row.id );
    }

    handleOnFinishedTournament (tournament_id) {                
        this.setState( { edit_tournament: true } );
        this.setState( { new_tournament: false } );                
        //this.setState( { selected_tournament: selected_row } )
        this.setState( { disabled_edit_tournament_data: true } ) 
        this.props.resetTournament({});            
        this.props.loadTournament( tournament_id );
        this.setState({ is_tournament_finalized: true });
    }

    handleBackButton () {   
        this.props.history.push(`/league/tournament/${ this.pagename }`);  
        this.showtournament = false;
        this.setshowtournament = undefined;      
        this.setState( { show_match_report: false } );
        this.setState( { new_tournament: false } );                
        this.setState( { edit_tournament: false } );
        this.setState( { enable_add_match_button: false } );
        this.setState( { edit_basic_info: false } );
        this.props.loadLeagueTournaments( this.pagename );        
        this.setState({ is_tournament_finalized: false });
        this.props.resetTournament({});   
    }

    handleBackFromMatchResultButton () {   
        let clean_path = `${this.props.state.page.page.pagename}`;
        this.props.history.replace({
            pathname: clean_path,
            search: '',
        });      
        this.setState( { show_match_report: false } );
        this.setState( { new_tournament: false } );                
        this.setState( { edit_tournament: true } );
        this.setState({is_referees_payment_response: false});
        this.props.loadLeagueTournaments( this.pagename );
    }

    async createNewTournament (tournament_data) {
        const formIdsArray =  this.props.state.leagueTournaments.tournament.tournament_forms_ids_template;

        const ids_forms ={
            team_form: formIdsArray.find((form_id)=> form_id.caption === 'team_tournament_form')?.id,
            player_form: formIdsArray.find((form_id)=> form_id.caption === 'player_tournament_form')?.id
        }
        
        this.props.disableNewTournamentSubmitButtonAction(true);
        await this.props.createNewTournament(tournament_data,this.pagename, ids_forms);
        //this.props.loadTournament( selected_row.id );
        this.props.loadLeagueTournaments( this.pagename );
        this.setState({ is_tournament_finalized: false });

        if(this.props.state.leagueTournaments.tournament.id){
            this.setState({ new_tournament: false });
            this.setState({ tournament_teams_enrollment_component: true });
            //this.props.changeInviteTeamToTournamentModalStateAction(true);
            this.handleAddStaf('invitation')
            this.props.disableNewTournamentSubmitButtonAction(false);
        }

    }

    async saveNewTournament () {        

        await this.props.loadLeagueTournaments( this.pagename );         
        
        this.props.changeErrorMessageSnackBar( {
            message: this.props.t('options.tournaments.savedNewTournament'),
            error_message_is_open: true
        } );

        this.setState( { show_match_report: false } );
        this.setState( { new_tournament: false } );                
        this.setState( { edit_tournament: false } );
        this.setState( { enable_add_match_button: false } );

    }

    viewMatchReport (selected_row) {                
        this.props.loadMatchReport(selected_row.id);
        this.setState( { show_match_report: true } );
        this.props.loadTeamsToMatchAssistanceAligment(selected_row.id)
    }

    async handleEndTournamentModal () {  
        
        let phase=this.props.state.leagueTournaments.tournament?.tournament_phases[this.props.state.leagueTournaments.tournament?.tournament_phases.length-1];
        if( phase !== undefined){
            this.setState( { endingTournament: true } );
            await this.props.loadTournamentPlayers( this.props.state.leagueTournaments?.tournament.id );
            await this.props.loadTournamentTeams(  this.props.state.leagueTournaments?.tournament.id, '', '', 200 );
            await this.props.loadPhaseResultsTable( phase.id );
            await this.props.changeEndTournamentModalStateAction( true );
            this.setState( { endingTournament: false } );
        }
        
        // console.log('phase',phase);
    }
    
    async handleEndTournament (id_tournament, tournament_data ,page) {                                     
        
        await this.props.finishTournament(id_tournament, tournament_data, page);
        this.handleOnFinishedTournament(id_tournament);

    }

    handleViewEndedTournament(selected_tournament) {
        this.handleOnCompletedTournament(selected_tournament)
     }
 

    handleEndMatchTournament (selected_row) {                             
        console.log(selected_row);
    }

     async handleEditMatchTournament (selected_row) {
        await this.props.loadMatchReport( selected_row.id );//New call
        //this.setState( { selected_edit_row: this.props.state.leagueMatches?.match_report } );//Se deja el nuevo match del estado global para no hacer mas cambios
        await this.handleSearchCityNewMatchModal(selected_row.match_city ? selected_row.match_city : this.props.state.leagueTournaments.tournament.main_city);
        if(selected_row.official_referee !== null){await this.props.getOfficialRefereeMember(selected_row.official_referee)}
        if(this.props.state.leagueMatches?.match_report.second_assistant_referee !== null){await this.props.getSecondRefereeMember(this.props.state.leagueMatches?.match_report?.second_assistant_referee)}
        if(this.props.state.leagueMatches?.match_report.third_assistant_referee !== null){await this.props.getThirdRefereeMember(this.props.state.leagueMatches?.match_report?.third_assistant_referee)}  
        if(this.props.state.leagueMatches?.match_report.fourth_official_referee !== null){await this.props.getFourthRefereeMember(this.props.state.leagueMatches?.match_report?.fourth_official_referee)}
        if(this.props.state.leagueMatches?.match_report.referee_assigner !== null){await this.props.getRefereeAssignerMember(this.props.state.leagueMatches?.match_report?.referee_assigner)}
        this.props.changeEditMatchModalState(true);
    }

    handleDeleteMatchTournament (selected_row) {
        this.setState({ team_state: 'delete_match' });
        this.setState({ match_action: selected_row });
        this.setState({invitation_inscription_modal_actions_mainLabel: this.props.t('options.tournaments.setupTournamentInvitationInscription.invitationInscriptionModal.deleteMatchLabelModal') });
        this.setState({invitation_inscription_modal_actions_secondaryLabel: this.props.t('options.tournaments.setupTournamentInvitationInscription.invitationInscriptionModal.deleteMatchSecondaryLabelModal') });
        this.setState({invitation_inscription_modal_actions_acceptBtn: this.props.t('options.tournaments.setupTournamentInvitationInscription.modalBtns.deleteBtn') });
        this.setState({invitation_inscription_modal_actions_cancelBtn: this.props.t('options.tournaments.setupTournamentInvitationInscription.modalBtns.cancelBtn') });
        this.props.changeInvitationInscriptionActionsModalStateAction(true);
    }

    async handleNewMatchTournament (selected_row) {  
        if(this.props.state.leagueTournaments.selected_date?.id){                        
            await this.handleSearchCityNewMatchModal(this.props.state.leagueTournaments.tournament.main_city);
            this.props.changeNewMatchModalStateAction(true);
        }    
    }

    handleAddMatchTournament (tournament_date_id, new_tournament) {                                            
        //this.props.NewMatch(this.props.state.leagueTournaments.selected_date.id, new_tournament, this.props.state.leagueTournaments?.tournament?.tournament_active_phase);
        this.setState({ tournament_selected_date: tournament_date_id });
        this.props.newTournamentmatch(tournament_date_id, new_tournament, this.props.state.leagueTournaments?.tournament?.tournament_active_phase);
        this.setState( { switch_status_teams:  false } );
    }

    handleClosedAddMatchTournament (){
        this.props.changeNewMatchModalStateAction( false );
        this.setState( { switch_status_teams:  false } );
    }

    handleEditMatchGameTournament ( id_match, match ) {
        this.setState({ tournament_selected_date: match.date_match });
        this.props.editMatch(id_match, match, match.date_match );
    }

    async handleOnfinishMatch(match){
        await this.props.loadMatchReport( match.id );
        this.handleMatchEnd(this.props.state.leagueMatches?.match_report);
    }

    handleMatchEnd = (match_data) => {
        let data_match_end = []; 
        let data_match_end_step =  []; 

        // goals
        let scorersLocal = [];
        let scorersVisitant = [];

        // cards
        let cardsLocal = [];
        let carsdVisitant = [];

        // changes
        let changesLocal = [];
        let changesVisitant = [];
        
        // Identify team local.
        let localTeam = match_data.local_team;

        // Separate events: cards, goals and changes.
        let read_event_match = match_data.events.map( ( position)  => { // eslint-disable-line
            //variable molde
            let pivoteAll = {
                profile: {
                    id: null,
                    username: null,
                    email: null,
                    first_name: null,
                    last_name: null,
                    photo: null,
                    followers_count: null,
                    following_count: null,
                    phone: null,
                    role: null
                },
                player_in: {
                    id: null,
                    username: null,
                    email: null,
                    first_name: null,
                    last_name: null,
                    photo: null,
                    followers_count: null,
                    following_count: null,
                    phone: null,
                    role: null
                },
                player_out: {
                    id: null,
                    username: null,
                    email: null,
                    first_name: null,
                    last_name: null,
                    photo: null,
                    followers_count: null,
                    following_count: null,
                    phone: null,
                    role: null
                },
                assistant: {
                    id: null,
                    username: null,
                    email: null,
                    first_name: null,
                    last_name: null,
                    photo: null,
                    followers_count: null,
                    following_count: null,
                    phone: null,
                    role: null
                },
                event: null,
                minute: null,
                own_goal: null
            }
            
            // Se alimenta la información que tiene en común los 3 tipos de evento.
            pivoteAll.profile.id = position.id; // FIXME: El id asociado es Id del evento, falta el id del jugador/asistencia
            pivoteAll.profile.username = position.player;
            pivoteAll.profile.photo = position.player_photo;
            pivoteAll.profile.first_name =  (position.player_full_name !=null ) ? position.player_full_name.split(" ")[0] : "";
            pivoteAll.profile.last_name = (position.player_full_name !=null )  ? position.player_full_name.split(" ")[1] : " ";
            pivoteAll.minute = position.minute;

            // Se alimenta la informacion para asistencia en el gol
            pivoteAll.assistant.id = '';
            pivoteAll.assistant.username = position.assistant;
            pivoteAll.assistant.photo = position.assistant_photo;
            pivoteAll.assistant.first_name =  (position.assistant_full_name !=null ) ? position.assistant_full_name.split(" ")[0] : "";
            pivoteAll.assistant.last_name = (position.assistant_full_name !=null )  ? position.assistant_full_name.split(" ")[1] : " ";


            // Cards.
            if(position.event == "Yellow_Card" || position.event == "Red_Card" || position.event == "Double_Yellow_Card" || position.event == "Blue_Card" ){
                pivoteAll.event = position.event;
                pivoteAll.player_in = null;
                pivoteAll.player_out = null;

                if( localTeam.trim() == position.team.trim() )
                {
                    cardsLocal.push(pivoteAll)
                }
                else
                {
                    carsdVisitant.push(pivoteAll)
                }  
            }  
            else if(position.event == "Goal" ){
                pivoteAll.own_goal = position.own_goal;
                pivoteAll.player_in = null;
                pivoteAll.player_out = null;
                if( localTeam.trim() == position.team.trim() )
                {
                    scorersLocal.push(pivoteAll)
                }
                else
                {
                    scorersVisitant.push(pivoteAll)
                }   
            }
            else if(position.event == "Player_Change" )
            {
                pivoteAll.profile = null;
             
                if( localTeam.trim() == position.team.trim() )
                {
                    //el que entra
                    pivoteAll.player_in.id = 0; //no devuelve el API.
                    pivoteAll.player_in.username = position.player;
                    pivoteAll.player_in.photo = position.player_photo;
                    pivoteAll.player_in.first_name = (position.player_full_name !=null ) ? position.player_full_name.split(" ")[0] : "";
                    pivoteAll.player_in.last_name = (position.player_full_name !=null )  ? position.player_full_name.split(" ")[1] : " ";

                    //el que sale
                    pivoteAll.player_out.id = 0; //no devuelve el API.
                    pivoteAll.player_out.username = position.player_two;
                    pivoteAll.player_out.photo = position.player_two_photo;
                    pivoteAll.player_out.first_name = (position.player_two_full_name !=null ) ? position.player_two_full_name.split(" ")[0] : "";
                    pivoteAll.player_out.last_name = (position.player_two_full_name !=null)  ? position.player_two_full_name.split(" ")[1] : " ";
                    changesLocal.push(pivoteAll);
                }
                else
                {
                    pivoteAll.player_in.id = 0; //no devuelve el API.
                    pivoteAll.player_in.username = position.player;
                    pivoteAll.player_in.photo = position.player_photo;
                    pivoteAll.player_in.first_name = (position.player_full_name !=null ) ? position.player_full_name.split(" ")[0] : "";
                    pivoteAll.player_in.last_name = (position.player_full_name !=null )  ? position.player_full_name.split(" ")[1] : " ";

                    //el que sale
                    pivoteAll.player_out.id = 0; //no devuelve el API.
                    pivoteAll.player_out.username = position.player_two;
                    pivoteAll.player_out.photo = position.player_two_photo;
                    pivoteAll.player_out.first_name = (position.player_two_full_name !== null) ? position.player_two_full_name.split(" ")[0] : "";
                    pivoteAll.player_out.last_name = (position.player_two_full_name !== null)  ? position.player_two_full_name.split(" ")[1] : " ";
                    changesVisitant.push(pivoteAll);
                } 
            }
            else{
                console.log("Not Implement");
            }
        })

        let scorers = {
            local : scorersLocal,
            visitant: scorersVisitant
        }

        let cards = {
            local : cardsLocal,
            visitant: carsdVisitant
        }

        let changes = {
            local : changesLocal,
            visitant: changesVisitant
        }

        // preparar informacion para enviar al modal
        data_match_end = {
            score : {
                local_scoreboard: match_data.local_team_score ,
                visitant_scoreboard: match_data.visitant_team_score,
                local_team_penalty_score: match_data.local_team_penalty_score,
                visitant_team_penalty_score: match_data.visitant_team_penalty_score,
                penalty_definition: match_data.penalty_definition
            },
            scorers: scorers, 
            changes: changes,
            cards: cards,
            comments: match_data.comments
        } 
        data_match_end_step = {
            score_step: true,
            scorers_step: true,
            changes_step: true, 
            cards_step: true, 
            comments_step: true,
        }

        this.setState( {
            data_match_end: data_match_end, 
            data_match_end_step : data_match_end_step,
            can_edit_match : match_data.can_edit_match,
            state_match : match_data.state
        } );

        this.handleOnFinishMatchActionClick(match_data)
    }

    handleLoadMatchTeamsMembers (match_data) {
        /* let local_teamname = match_data.local_team;
        let visitant_teamname = match_data.visitant_team;
        let tournament_teams = this.props.state.leagueTournaments.tournament.tournament_teams;        
        let local_team = tournament_teams.find( (team) =>  team.teamname === local_teamname  );
        let visitant_team = tournament_teams.find( (team) => team.teamname === visitant_teamname);
        this.props.loadLocalTeamPageMembers(local_team.tournament_team_id);
        this.props.loadVisitorTeamPageMembers(visitant_team.tournament_team_id); */
        this.props.loadLocalTeamPageMembers(match_data.local_tournament_team_id);
        this.props.loadVisitorTeamPageMembers(match_data.visitant_tournament_team_id);
    }
   
    async handleOnFinishMatchActionClick ( match_data ) {
        await this.props.loadTeamsToMatchAssistanceAligment(match_data.id);
        this.props.setMatchDataAction(match_data);
        this.handleLoadMatchTeamsMembers(match_data);
        this.props.loadLocalTeamPageData(match_data.local_team);
        this.props.loadVisitorTeamPageData(match_data.visitant_team);
        this.props.loadMemberPositionsData();
        this.props.changeEndMatchModalStateAction( true );    
        this.props.loadPhaseResultsTable( this.props.state.leagueTournaments?.tournament?.tournament_active_phase?.id );
    }

    handleOnRegisterScore( score ){
        this.props.setMatchScore( this.props.state.leagueMatches?.match.id, score);
    }

    handleOnRegisterScorers( scorers ){
        this.props.setMatchScorers( this.props.state.leagueMatches?.match.id, scorers );
    }

    handleOnRegisterChanges( changes ){
        this.props.setMatchChanges( this.props.state.leagueMatches?.match.id, changes );
    }

    handleOnRegisterCards( cards ){
        //if(cards.local.length != 0  ||  cards.visitant.length != 0 ){
            this.props.setMatchPlayersCards( this.props.state.leagueMatches?.match.id, cards );
        //}        
    }
    handleOnRegisterComments( comments ){
        //comments is a object  {playerUsername: '',comment: ''}
        this.props.setMatchComments( this.props.state.leagueMatches?.match.id, comments, this.props.state.leagueTournaments?.selected_date.id, this.props.state.leagueTournaments?.tournament?.tournament_active_phase );
        this.props.changeEndMatchModalStateAction( false );
        this.definePhaseMatchesStatus(this.props.state.leagueTournaments?.tournament?.tournament_active_phase);
        this.props.resetMatchData({});
    }

    async handleOnClickPhaseTab( phase, typeAction ){

        // if(this.props.state.leagueTournaments.tournament.tournament_phases){
        //     const indexPhase = this.props.state.leagueTournaments.tournament.tournament_phases?.findIndex(tounament_phase  => tounament_phase.id === phase.id);
        //     const phaseTeamsNotAssigned = (indexPhase === 0 ? phase?.id : this.props.state.leagueTournaments.tournament.tournament_phases[indexPhase-1].id);
        //     this.props.loadPhaseTeamsNotAssigned(phaseTeamsNotAssigned, 'not_assigned);
        // }

        if(phase.id != this.props.state.leagueTournaments.tournament?.tournament_active_phase?.id){
            this.props.resetTournamentPhase({});
        }
        if(phase.phase_type){
            //this.props.setTournamentActivePhaseAction( phase );
            await this.props.setTournamentActivePhase(phase.id);//New on change phases api
            if(typeAction !== 'tabCalendar'){
                this.props.loadTournamentPhaseInterGroupsTeams(phase?.id, 'assigned');
            }
            await this.props.loadTournamentPhaseTeams(phase?.id, `${typeAction === 'tabCalendar' ? 'assigned': this.state.teams_filter_type}`);
            this.handleTournamentMatchAdminPhaseTeams();
        }
        this.definePhaseMatchesStatus(this.props.state.leagueTournaments?.tournament?.tournament_active_phase);
    }

    handleOnClickPhaseTabInPositionsTable(phase){
        if(phase.best_third){
            this.setState({is_best_third: true});
        } else {
            this.setState({is_best_third: false});
        }
        // console.log("cargando phase en tabla de posiciones");
        if(phase.phase_type){
            this.props.loadPhaseResultsTable(phase.id);
        }

    }

    async handleOnClickFinishPhase (phase){
        this.setState( { phase: phase } );
        await this.props.classifiedTeamsByPhase(phase);
        if(this.props.state.leagueTournaments.modals?.finish_groups_phase_is_open || this.props.state.leagueTournaments.modals?.finish_free_phase_is_open){
            this.setState({edit_basic_info: false});
            this.setState( { edit_phase_component: false } );
            this.setState( { general_end_phases: true } );
        }
    }

    handleFinishedMatchesByPhase(phase) {
         this.props.finishedMatchesByPhase(phase)
    }


    handleOnAddTournamentPhase(id_tournament){
        this.props.resetTournamentPhase({});
        this.props.addTournamentPhase(id_tournament);
        this.setState( { enable_add_match_button: false } );
    }

    handleLeavingPhaseTab(){
        // console.log("Leaving tab phases");
        this.props.changeWarningLeavingPhaseStateAction(true);   
    }

    handleTeamsFinishTournament(){
        
        let classified_teams = []
        let tournament_teams = []
        this.props.state.leagueTournaments.tournament?.phase_results_table?.groups?.forEach( (group, index) => {//TODO: Verificacion tabla de posiciones
            group.positions_table.forEach( (position_team_table) => {
                if(position_team_table.classified === true) {
                    classified_teams.push(position_team_table);
                }
            })
        });
        
        classified_teams.forEach((classified_team) => {
            if(!classified_team.best_third) {
                tournament_teams.push(this.props.state.leagueTournaments.tournament?.tournament_teams.find((team) => team.teamname === classified_team.name));
            }
        });

        return tournament_teams
    }

    handleScorerOfTournament(){
        
        //let list = [];
        let list = undefined;
        /* eslint-disable */
        let scorers = this.props.state.leagueTournaments.tournament?.phase_results_table?.scorers_table?.map((user,index) => {//TODO: Verificacion tabla de posiciones
            if(index === 0){
                list = user;
            }
        });
        /* eslint-enable */
        return list; 
    }

    handleAddTeamToGroupModal() {
        this.setState({teams_to_group: true});
        this.props.loadTournamentPhaseFilterTeams( this.props.state.leagueTournaments.tournament.tournament_active_phase?.id, this.state.teams_filter_type, 'tournament_active_phase_teams_modal');
        this.props.changeAddTeamToGroupModalStateAction( true );
    }
    handleOnProfileClick = ( username ) => {
        this.props.history.push(`/profile/${ username }`);
    }
    handleOnPageClick = ( pagename, teampagename ) => {
        //this.props.history.push("/league/team/"+ pagename + "&setshowteam=" + teampagename +"&showteam=true");
        this.props.getTeamPlayers(teampagename);
        this.props.loadTeamData(teampagename);
    }

    handleDeleteTournamentPhase(phase ,index){
    
        if(phase?.id === this.props.state.leagueTournaments.tournament.tournament_phases[this.props.state.leagueTournaments.tournament.tournament_phases.length -1].id){
            this.props.deleteTournamentPhase(this.props.state.leagueTournaments.tournament.id, phase);
            this.setState({ change_phase_state: ''});
            this.setState({ phase_inf_edit: {} });
            this.props.changeInvitationInscriptionActionsModalStateAction(false);
        }else{
            this.props.changeRemoveMultiplePhasesModalStateAction( false );
            this.handleDeleteTournamentPhaseModal(index);
        }
        
    }

    handleDeleteTournamentPhaseModal(phaseIndex){
        if(phaseIndex < this.props.state.leagueTournaments.tournament.tournament_phases.length){
            for(let i= phaseIndex; i < this.props.state.leagueTournaments.tournament.tournament_phases.length; i++){
                this.props.deleteTournamentPhase(this.props.state.leagueTournaments.tournament.id, this.props.state.leagueTournaments.tournament.tournament_phases[i]);
            }
        }
    }

    handleDeleteDate( date,index, group ){
        this.setState({date: date});
        this.setState({index_date: index});
        this.setState({group_date: group});
        this.props.changeDeleteDateModalStateAction( true );
    }

    handleCloseMatchModal(values){
        this.setState({comments: values});
        // if(this.state.end_match_reminder === false){
        //     this.props.changeCloseMatchModalStateAction( true );
        // }else{
        //     this.handleOnRegisterComments( values );
        // }
    }

    handleEndMatchReminder(checked){
        if(checked){
            this.setState({end_match_reminder: true});
        }else{
            this.setState({end_match_reminder: false});
        }
    }

    handleCleanData(clean){
        if(clean){
            this.setState({clean_data: true});
        }else{
            this.setState({clean_data: false});
        }
    }

    handleTournamentFinalizedSuggestion(is_suggestion_on){    
        if(is_suggestion_on){
            this.props.changeTournamentFinalizedSuggestionModalStateAction(true);
        }else{
            this.props.changeTournamentFinalizedSuggestionModalStateAction(false);            
        }
    }

    handleOnCloseTournamentSuggestionModal() {        
        this.props.changeTournamentFinalizedSuggestionModalStateAction(false);
    }

    // handleCreateNewAutomaticGroupPhasesModal(is_shuffle){
    //     // let teams_clasified_by_groups = this.props.state.leagueTournaments.tournament.tournament_active_phase
    //     // teams_clasified_by_groups.is_shuffle = is_shuffle

    //     console.log('click')

    //     //this.props.createAutomaticGroupPhases(phaseId, this.props.state.leagueTournaments.tournament?.id)

    //     this.props.createAutomaticGroupPhases(this.props.state.leagueTournaments.tournament.tournament_active_phase?.id, this.props.state.leagueTournaments.tournament?.id)

    //     //this.props.createNewGroupsPhases(this.props.state.leagueTournaments.tournament.id, this.props.state.leagueTournaments.tournament.tournament_active_phase.id, teams_clasified_by_groups)
    // }
    
    handleCleanBaloaTeamsSearch = () => {
        this.props.cleanBaloaTeamsSearchAction({});
    }
    
    async handleOnEditTournamentInfo(){
        //console.log('se disparo por aca 1');
        await this.props.getCountries(this.state.selected_tournament.main_country);
        await this.props.searchCityByCountry(this.props.state.leagueTournaments.countryId, this.state.selected_tournament.main_city);
        await this.props.getFieldsStatusEditing(this.state.selected_tournament.id);
        this.setState({tournament_type: this.handleTranslateTournamentType()})
        this.setState( { tournament_teams_enrollment_component: false } );
        this.setState({edit_tournament: true, edit_basic_info: true});  
    }

    stopEditEditTournamentInfo(){
        this.setState({edit_basic_info: false});
    }

    handleOnAccept = async () => {
        this.setState({show_fixture_resume: false});
        //this.setState({tabAfterFixture: 4});
    }

    async handleOnReorganize(phase_id/* , roundtrip  */){
        this.setState({loading_fixture_state: true});
        //await this.props.setFinalFixture(phase_id, true, roundtrip);
        await this.props.generateMatchesFixture(phase_id, true);
        this.definePhaseMatchesStatus( this.props.state.leagueTournaments.tournament.active_phase_fixture_resume);
        this.setState({loading_fixture_state: false});
    }

    async handleOnAutomaticFixture() {
        let matches = false;
        //eslint-disable-next-line array-callback-return
        this.props.state.leagueTournaments?.tournament?.tournament_active_phase?.groups?.map(group => {
            //eslint-disable-next-line array-callback-return
            group.dates?.map( date => {
                if(date.matches?.length > 0){
                    matches = true;
                    //eslint-disable-next-line array-callback-return
                    return;
                }
            })
        })
        if(matches){
            this.props.changeAcceptAutomaticFixtureModalStateAction( true );
        }else{
            await this.props.loadFullFixtureConfig( this.props.state.leagueTournaments.tournament.id )
            this.props.changeRoundtripFixtureModalStateAction( true );
        }        
    }

    async handleOnAcceptAutomaticFixture() {
        this.props.changeAcceptAutomaticFixtureModalStateAction( false );
        await this.props.loadFullFixtureConfig( this.props.state.leagueTournaments.tournament.id )
        this.props.changeRoundtripFixtureModalStateAction( true );
    }

    async setAutomaticFixture( fixture_config_data ) {
        // this.props.changeRoundtripFixtureModalStateAction( false );
        // this.setState({show_fixture_resume: true});
        this.setState({loading_fixture_state: true});
        this.setState({phase_teams: 0});
        this.setState({phase_dates: 0});
        await this.props.setTournamentFixture(this.props.state.leagueTournaments.tournament.id, fixture_config_data, this.props.state.leagueTournaments.tournament.tournament_active_phase.id);
        this.definePhaseMatchesStatus( this.props.state.leagueTournaments.tournament.active_phase_fixture_resume);
        this.setState({loading_fixture_state: false});
    }

    definePhaseMatchesStatus( phase ){
        //let phase_teams = 0;
        let phase_dates = 0;
        let button_state = true;
        phase_dates = phase_dates + phase?.calendar?.length;
        if(phase){
            /* eslint-disable */
            phase?.calendar?.map((jornadas) =>{
                //phase_teams = phase_teams + grupo.teams_count;                
                (jornadas.groups?.map((groups) => (
                    groups.matches?.map((dat) =>{
                        if(dat.state == "Finalized"){
                            button_state = false;
                        }
                    })
                )))
            })
            /* eslint-enable */
        }
        this.setState({fixture_button_active: button_state});
        //this.setState({phase_teams: phase_teams});
        this.setState({phase_dates: phase_dates});
    }

    handleSearchCityNewMatchModal = async (city) => {
        //console.log('se disparo por aca 2');
        await this.props.getCountries(this.state.selected_tournament.main_country);
        await this.props.searchCityByCountry( this.props.state.leagueTournaments.countryId, city )
    }

    handleOnCloseEditMatchModal () {
        this.props.changeEditMatchModalState( false );
        this.props.cleanRefereesMemberAction({});
    }

    cleanTabAfterFixture = () => {
        this.setState({tabAfterFixture: undefined});
    }

    /*Function to translate tournament category  (tournament_tag) */
    trastaleTournamentCategory (tournament_list) {
        let tournament_final = [];
        let new_tournament_list= tournament_list.map((torneo) =>{ // eslint-disable-line
            
            let list_tournament ={...torneo, category_name: torneo.tournament_tag != null ? this.props.t("options.tournaments.pages.newTournament.tabs.tabTeams." + torneo.tournament_tag.extra.value) : torneo.category}
            tournament_final.push(list_tournament)
        })
        return tournament_final;
    }

    /*Function to translate tournament football types */
    handleTranslateFootballTypes () {
        let football_type_array = [];
        this.props.state.lineups.alignments.map((type) => {
            football_type_array.push({ "value": type.description, "content": this.props.t(`options.tournaments.pages.newTournament.tabs.tabBasicData.${type.description}`), "initial": type.id  });
            return 'none'
        });
        return football_type_array;
    }

    /*Function to translate tournament types */
    handleTranslateTournamentType (){
        let tournamentTypeArray = []
        this.props.state.leagueTournaments.items_tournament_type?.forEach((tournamentType) => {
            tournamentTypeArray.push({ 
                "value": tournamentType.tournament_type, 
                "content": this.props.t(`options.tournaments.pages.newTournament.tabs.tabBasicData.${tournamentType.tournament_type}`) 
            });
        });
        return tournamentTypeArray;
    }
    
    addPhaseTeam2(){
        this.props.addTeamToTournament( this.state.selected_tournament.id, this.state.selected_to_add_team.pagename );
        this.props.loadTournament( this.state.selected_tournament.id );  
        this.props.changeAddTeamAdviceModalStateAction( false )  
    }

    addPhaseTeam(selected_team){
        this.props.addTeamToTournament( this.state.selected_tournament.id, selected_team.pagename );
        this.props.loadTournament( this.state.selected_tournament.id );  
        this.props.changeAddTeamAdviceModalStateAction( false );  
    }

    handleOnMatchChanges () {
        this.props.changeErrorMessageSnackBar( {
            message: this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabChanges.maxChangesAllowed'),
            error_message_is_open: true
        } );
    }

    handleOnFinishCreateNewTournament () {
        this.setState({ new_tournament: false });
        this.setState({ tournament_teams_enrollment_component: true });
        this.props.changeInviteTeamToTournamentModalStateAction(true);
        
    }

    handleOnStartTournament (tournament_state) {
        this.setState({ change_tournament_state: tournament_state });
        this.props.changeStartTournamentModalStateAction(true);        
    }

    handleOnConfirmStartTournament () {
        this.props.changeTournamentState(this.props.state.leagueTournaments.tournament.id, this.state.change_tournament_state)
        this.setState({ tournament_teams_enrollment_component: false });
        this.props.changeStartTournamentModalStateAction(false);
        this.setState({ start_tournament_component: true });
        this.setState({ change_tournament_state: '' });
    }

    handleCalendarStep () {
        this.setState({start_tournament_component: false});
        this.setState({edit_tournament: true});
    }

    handleStartTournamentBackBtn () {  
        this.props.changeTournamentState(this.props.state.leagueTournaments.tournament.id, 'Registration_Open' )
        this.setState({ edit_tournament: false });
        this.setState({ tournament_teams_enrollment_component: true });
        this.setState({ start_tournament_component: false });
        //this.props.loadTournamentSetupTeams(this.props.state.leagueTournaments.tournament.id, '');  
    }

    handleTeamsEnrollmentBackBtn () {  
        this.props.history.push(`/league/tournament/${ this.pagename }`);  
        this.showtournament = false;
        this.setshowtournament = undefined;      
        this.setState({
            new_tournament: false,
            edit_tournament: false,
            show_match_report: false,
            tournament_teams_enrollment_component: false,
            start_tournament_component: false,
        })
    }

    handleOnFilterTournament(filter){
        this.props.loadfilterTournament(filter.league_pagename, filter.type_filter);
    }

    handleOnSelectedTournamentHub (selected_row) {
        if(selected_row.state === "Registration_Open"){
            this.setState( { tournament_teams_enrollment_component: true } );
            this.setState( { edit_tournament: false } );            
        }else{
            this.setState( { edit_tournament: true } );
            this.setState( { tournament_teams_enrollment_component: false } );
        }
        
        this.setState( { new_tournament: false } );          
        this.setState( { selected_tournament: selected_row } )
        this.setState( { disabled_edit_tournament_data: false } )                     
        this.props.resetTournament({});
        this.props.loadTournament( selected_row.id );
        //this.props.loadTournamentSetupTeams(selected_row.id, '');

        // this.setState( { edit_tournament: true } );
        // this.setState( { new_tournament: false } );          
        // this.setState( { selected_tournament: selected_tournament } )
        // this.setState( { disabled_edit_tournament_data: false } )                     
        // this.props.resetTournament({});
        // this.props.loadTournament( selected_tournament.id );
    }

    handleOnValidateTeamInscriptionModal (team, state) {
        if(team.team_state === 'Register' || team.team_state === 'Enrolled' || team.team_state === 'Refused' || team.team_state === 'Unpaid') {
            this.props.resetTournmentTeamPlayersAction();
            this.props.loadTournamentTeamPlayers(team.id, 'Enrolled|Pending|Late_Register');
            this.props.loadTeamManagerAndStaff(team.teamname, this.props.state.leagueTournaments?.tournament.id);
        }
        this.setState({ selected_team: team });
        this.setState({ team_state: state })
        // this.props.changeTeamInscriptionModalStateAction(true);
        this.setState({is_player_information: true});
    }

    handleInscriptionInvitationModalActions(team, team_state) {
        this.props.changeInvitationInscriptionActionsModalStateAction(true);
        this.setState({ selected_team: team });
        this.setState({ team_state: team_state })
        if(team_state === 'Cancelled'){
            this.setState({invitation_inscription_modal_actions_mainLabel: this.props.t('options.tournaments.setupTournamentInvitationInscription.invitationInscriptionModal.cancelledMainLabelModal') });
            this.setState({invitation_inscription_modal_actions_secondaryLabel: this.props.t('options.tournaments.setupTournamentInvitationInscription.invitationInscriptionModal.cancelledSecondaryLabelModal') });
            this.setState({invitation_inscription_modal_actions_acceptBtn: this.props.t('options.tournaments.setupTournamentInvitationInscription.modalBtns.continueBtn') });
            this.setState({invitation_inscription_modal_actions_cancelBtn: this.props.t('options.tournaments.setupTournamentInvitationInscription.modalBtns.cancelBtn') });
        }
        if(team_state === 'Rejected'){
            this.setState({invitation_inscription_modal_actions_mainLabel: this.props.t('options.tournaments.setupTournamentInvitationInscription.invitationInscriptionModal.rejectedMainLabelModal') });
            this.setState({invitation_inscription_modal_actions_secondaryLabel: this.props.t('options.tournaments.setupTournamentInvitationInscription.invitationInscriptionModal.rejectedSecondaryLabelModal') });
            this.setState({invitation_inscription_modal_actions_acceptBtn: this.props.t('options.tournaments.setupTournamentInvitationInscription.modalBtns.rejecInvitationtBtn') });
            this.setState({invitation_inscription_modal_actions_cancelBtn: this.props.t('options.tournaments.setupTournamentInvitationInscription.modalBtns.cancelBtn') });
            this.setState({accept_team_inscription_btn: false});
            
        }

        if(team_state === 'Enrolled'){
            this.setState({invitation_inscription_modal_actions_mainLabel: this.props.t('options.tournaments.setupTournamentInvitationInscription.invitationInscriptionModal.acceptTeamInscription') });
            this.setState({invitation_inscription_modal_actions_secondaryLabel: '' });
            this.setState({invitation_inscription_modal_actions_acceptBtn: this.props.t('options.tournaments.setupTournamentInvitationInscription.modalBtns.continueBtn') });
            this.setState({invitation_inscription_modal_actions_cancelBtn: this.props.t('options.tournaments.setupTournamentInvitationInscription.modalBtns.cancelBtn') });
            this.setState({accept_team_inscription_btn: true});
        }

        if(team_state === 'Refused'){
            this.setState({invitation_inscription_modal_actions_mainLabel: this.props.t('options.tournaments.setupTournamentInvitationInscription.invitationInscriptionModal.refusedMainLabelModal') });
            this.setState({invitation_inscription_modal_actions_secondaryLabel: this.props.t('options.tournaments.setupTournamentInvitationInscription.invitationInscriptionModal.refusedSecondaryLabelModal') });
            this.setState({invitation_inscription_modal_actions_acceptBtn: this.props.t('options.tournaments.setupTournamentInvitationInscription.modalBtns.refuseEnrollmentdBtn') });
            this.setState({invitation_inscription_modal_actions_cancelBtn: this.props.t('options.tournaments.setupTournamentInvitationInscription.modalBtns.cancelBtn') });
        }

        if(team_state === 'player_rejected'){
            this.setState({ change_player_state: 'Rejected'});
            this.setState({ team_player: team });
            this.setState({invitation_inscription_modal_actions_mainLabel: this.props.t('options.tournaments.setupTournamentInvitationInscription.invitationInscriptionModal.rejectedPlayerMainLabelModal') });
            this.setState({invitation_inscription_modal_actions_secondaryLabel: this.props.t('options.tournaments.setupTournamentInvitationInscription.invitationInscriptionModal.rejectedPlayerSecondaryLabelModal') });
            this.setState({invitation_inscription_modal_actions_acceptBtn: this.props.t('options.tournaments.setupTournamentInvitationInscription.modalBtns.rejecInvitationtBtn') });
            this.setState({invitation_inscription_modal_actions_cancelBtn: this.props.t('options.tournaments.setupTournamentInvitationInscription.modalBtns.cancelBtn') });
        }
    }

    handleChangeTournamentState(tournament_state) {
        this.props.changeInvitationInscriptionActionsModalStateAction(true);
        this.setState({invitation_inscription_modal_actions_mainLabel: this.props.t('options.tournaments.setupTournamentInvitationInscription.invitationInscriptionModal.cancelTournamentMainLabelModal') });
        this.setState({invitation_inscription_modal_actions_secondaryLabel: this.props.t('options.tournaments.setupTournamentInvitationInscription.invitationInscriptionModal.cancelTournamentSecondaryLabelModal') });
        this.setState({invitation_inscription_modal_actions_acceptBtn: this.props.t('options.tournaments.setupTournamentInvitationInscription.modalBtns.continueBtn') });
        this.setState({invitation_inscription_modal_actions_cancelBtn: this.props.t('options.tournaments.setupTournamentInvitationInscription.modalBtns.cancelBtn') });
        this.setState({ change_tournament_state: tournament_state });
    }

    handleOnInvitationInscriptionModalActions () {
        if((this.state.team_state === 'Cancelled' || this.state.team_state === 'Register' || this.state.team_state === 'Enrolled' || this.state.team_state === 'Refused' || this.state.team_state === 'Rejected') && !this.state.is_transfer_player && this.state.change_player_state === '' ){
            this.props.changeTournamentTeamState(this.state.selected_team.id, this.state.team_state);
            if(this.state.team_state === 'Cancelled') {
                this.props.deleteTeamToTournament(  this.state.selected_team.id, this.state.selected_team.tournament_id );                
            }
            if(this.state.team_state === 'Rejected') {//Regresa al listado de equipos del torne y lo refresca
                this.props.loadTournament( this.props.state.leagueTournaments?.tournament.id);
                this.setState({is_player_information: false, preSelectedTab: this.props.state.leagueTournaments.tournament.state === 'Finalized'? 4 : 3 });                
            }
            this.props.changeInvitationInscriptionActionsModalStateAction(false);
            //this.props.changeTeamInscriptionModalStateAction(false);
        } 
        
        if(this.state.change_tournament_state === 'Scheduled' || this.state.change_tournament_state === 'Cancelled') {
            this.props.changeTournamentState(this.props.state.leagueTournaments.tournament.id, this.state.change_tournament_state);
            if(this.state.change_tournament_state === 'Cancelled') {
                this.setState( { edit_tournament: false } );
                this.setState( { tournament_teams_enrollment_component: false } );
            }
            this.setState({ change_tournament_state: '' });
            this.props.changeInvitationInscriptionActionsModalStateAction(false);
            
        }

        if(this.state.change_player_state === 'Rejected'){
            this.props.changeTeamPlayerState(this.state.team_player.id, this.state.change_player_state, this.state.selected_team.tournament_team_id);
            this.setState({ team_player: [] });
            this.setState({ change_player_state: ''});
            this.props.changeInvitationInscriptionActionsModalStateAction(false);
        }

        if(this.state.team_state === 'delete_match'){
            this.props.deleteMatchGame(this.state?.match_action.id, this.props.state.leagueTournaments.selected_date.id, this.props.state.leagueTournaments?.tournament?.tournament_active_phase);
            this.setState({ team_state: ''});
            this.handleOnCloseInvitationInscriptionActionsModal();
        }
        
        if(this.state.change_phase_state === 'phases_edit_validation'){
            this.handleReturnPhaseComponent();
        }

        if(this.state.change_phase_state === 'phase_edit_status'){
            this.handleEditPhase(this.state.phase_inf_edit?.id_phase, this.state.phase_inf_edit);
        }

        if(this.state.change_phase_state === 'phases_delete_validation'){
            this.props.changeInvitationInscriptionActionsModalStateAction(false);
            this.setState({teams_filter_type: 'not_assigned'});
            this.handleDeleteTournamentPhase(this.state.phase_inf_edit?.phase_data, this.state.phase_inf_edit?.index);
        }

        if(this.state.change_player_state === 'Pending'){
            this.props.changeTeamPlayerState(this.state.tournament_player_id, this.state.change_player_state, this.state.selected_team.tournament_team_id);
            this.setState({ team_player: [] });
            this.setState({ change_player_state: ''});
            this.props.changeInvitationInscriptionActionsModalStateAction(false);
            this.setState({invitation_inscription_modal_actions_mainLabel: ''});
            this.setState({invitation_inscription_modal_actions_secondaryLabel: '' })
            this.setState({accept_team_inscription_btn: false})
            this.setState({invitation_inscription_modal_actions_acceptBtn: '' });
            this.setState({invitation_inscription_modal_actions_cancelBtn: '' });            
            this.setState({tournament_player_id: ''});            
            this.setState({team_id: ''});        
        }

        if(this.state.is_transfer_player){
            this.props.transferTeamPlayer(this.state.tournament_player_id, this.state.teamname, this.props.state.leagueTournaments?.tournament.id);
            this.props.changeTournamentTranferPlayerModalStateAction( false );
            this.props.changeInvitationInscriptionActionsModalStateAction(false)
            this.setState({invitation_inscription_modal_actions_secondaryLabel: '' })
            this.setState({accept_team_inscription_btn: false})
            this.setState({invitation_inscription_modal_actions_acceptBtn: '' });
            this.setState({invitation_inscription_modal_actions_cancelBtn: '' });
            this.setState({tournament_player_id: ''});
            this.setState({teamname: ''});
            this.setState({is_transfer_player: false});           
        }
        if(this.state.change_phase_state === 'team_delete_validation'){
            //NOTE: groupId = index ,phase_data= teamName
            this.props.deleteTeamFromGroup(this.state.phase_inf_edit?.phase_data, this.state.phase_inf_edit?.groupId, this.props.state.leagueTournaments?.tournament?.tournament_active_phase?.id, this.state.teams_filter_type)
            this.setState({ change_phase_state: ''});
            this.setState({ phase_inf_edit: {} });
            this.props.changeInvitationInscriptionActionsModalStateAction(false);
            this.props.setTournamentActivePhaseAction(  this.props.state.leagueTournaments?.tournament?.tournament_active_phase );
            this.props.loadTournament( this.props.state.leagueTournaments?.tournament?.id );
            // this.props.loadTournamentPhaseFilterTeams( this.props.state.leagueTournaments?.tournament?.tournament_active_phase?.id, 'not_assigned');
        }

        if(this.state.change_phase_state === 'phases_delete_Knockout'){
            this.handleTeamGroupPhaseknockout(this.state.phase_inf_edit?.win_team, this.state.phase_inf_edit?.lost_team, this.state.phase_inf_edit?.group_id)
            this.props.changeInvitationInscriptionActionsModalStateAction(false);
            this.props.setTournamentActivePhaseAction(  this.props.state.leagueTournaments?.tournament?.tournament_active_phase );
            this.props.loadTournament( this.props.state.leagueTournaments?.tournament?.id );
        }

        if(this.state.change_phase_state === 'phases_move_to_group'){
            this.handleMoveToGroup(this.state.phase_inf_edit?.group_best_third, this.state.phase_inf_edit.team, this.state.phase_inf_edit.original_id ,this.state.phase_inf_edit.group_id);
            this.props.changeInvitationInscriptionActionsModalStateAction(false);
            this.props.setTournamentActivePhaseAction(  this.props.state.leagueTournaments?.tournament?.tournament_active_phase );
            this.props.loadTournament( this.props.state.leagueTournaments?.tournament?.id );
        }

        //staff delete confirmation
        if(this.state.delete_staff){
            this.setState({delete_staff: undefined});
            this.setState({invitation_inscription_modal_actions_mainLabel: '' });
            this.setState({invitation_inscription_modal_actions_acceptBtn: '' });
            this.setState({invitation_inscription_modal_actions_cancelBtn: '' });
            this.props.changeInvitationInscriptionActionsModalStateAction(false);
            this.props.deleteStaff(this.state.delete_staff)
        }
    }

    handleTeamInfo(team) {
        this.props.resetTournmentTeamPlayersAction();
        this.props.loadTournamentTeamPlayers(team.id, 'Enrolled');
        this.props.loadTeamManagerAndStaff(team.teamName, this.props.state.leagueTournaments?.tournament.id);
        this.setState({selected_team: team});
        this.setState({state: team.team_state});
        //this.props.changeTeamInscriptionModalStateAction(true);
    }

    loadInfoFormModal(form_id){
        this.props.loadFormData(form_id);
        this.props.changeInscriptionViewFormDataModalStateAction(true);
    }
    
    handleTeamPlayerPaymentInfo (payment_info, currency) {
        this.setState({ team_player_payment_info: payment_info });
        this.setState({ team_player_payment_currency: currency })
        this.props.changePaymentInformationModalStateAction(true);
    }

    handleOnClosePaymentInfoModal () {
        this.setState({ team_player_payment_info: [] });
        this.setState({ team_player_payment_currency: '' });
        this.props.changePaymentInformationModalStateAction(false);
    }

    handlePreviewForm (questions) {
        this.setState({preview_dynamic_form: questions});        
        this.props.changeTournamentDynamicFormsModalStateAction(true);
    }

    handleCloseInscriptionViewFormDataModal () {
        this.setState({preview_dynamic_form: []});
        this.props.changeTournamentDynamicFormsModalStateAction(false);
    }

    handleOpenEditFormModal(state_modal, modal_type){
        this.setState({state_edit_form_modal:state_modal });
        this.setState({modal_type_edit_form: modal_type });
    }

    handleOnCloseEditFormModal(){
        this.setState({state_edit_form_modal:false });
        this.setState({modal_type_edit_form: '' });
    }

    handleSendToEditFormTournament( form, form_type ){
        const formID= form_type === 'PLAYER_FORM' ? player_form_id : team_form_id;
        this.props.createNewForm(form, formID, form_type, this.state.selected_tournament.id);
    };
    
    handlebackToSummaryTournament(){
        this.setState( { edit_basic_info: false } );
        this.setState({modal_confirmation_back_edit: false});
        this.setState({state_edit_form_modal:false });

        if(this.state.selected_tournament.state === "Registration_Open"){
            this.setState({edit_tournament: false, tournament_teams_enrollment_component: true });
        }
    };

    handleOpenConfirmationModal(state_modal){
        this.setState({modal_confirmation_back_edit: state_modal});
        this.setState({state_edit_form_modal:state_modal });
    }

    handleChangesSelectedTeams(value){
        this.setState( { switch_status_teams:  value } );
    }

    handleInviteOrInscriptionTeamModal(modal_type){
        this.setState({modal_type_invited_inscription: modal_type});
        this.props.changeInviteTeamToTournamentModalStateAction(true);
    }
    
    handleTransferPlayer(player) {
        let new_array_teams=[];
        this.props.state.leagueTournaments.tournament?.tournament_teams.forEach(team => {
            if(team.teamname !== this.state.selected_team.teamname && team.team_state === "Enrolled") {
                new_array_teams.push(team);
            }
        });
        this.setState({teams_transfer_list: new_array_teams})
        this.setState({ selected_player: player });
        this.props.changeTournamentTranferPlayerModalStateAction(true)
    }

    handleOnTransferPlayer(tournament_player_id, teamname, team_id) {
        this.setState({invitation_inscription_modal_actions_secondaryLabel: this.props.t('options.tournaments.setupTournamentInvitationInscription.teamInscriptionModal.transferPlayerConfirmation') });
        this.setState({accept_team_inscription_btn: true})
        this.setState({invitation_inscription_modal_actions_acceptBtn: this.props.t('options.tournaments.setupTournamentInvitationInscription.modalBtns.continueBtn') });
        this.setState({invitation_inscription_modal_actions_cancelBtn: this.props.t('options.tournaments.setupTournamentInvitationInscription.modalBtns.cancelBtn') });
        this.setState({tournament_player_id: tournament_player_id});
        this.setState({teamname: teamname});
        this.setState({team_id: team_id})
        this.setState({is_transfer_player: true})
        this.props.changeInvitationInscriptionActionsModalStateAction(true)
    }

    handlePlayerInscription(player_id, player_state, tournament_team_id) {
        if(player_state === "Pending") {
            this.setState({invitation_inscription_modal_actions_mainLabel: this.props.t('options.tournaments.setupTournamentInvitationInscription.invitationInscriptionModal.refusedPlayerMainText') });
            this.setState({invitation_inscription_modal_actions_secondaryLabel: this.props.t('options.tournaments.setupTournamentInvitationInscription.invitationInscriptionModal.refusedPlayerSecondaryText') })
            this.setState({accept_team_inscription_btn: false})
            this.setState({invitation_inscription_modal_actions_acceptBtn: this.props.t('options.tournaments.setupTournamentInvitationInscription.cancelEnrollment') });
            this.setState({invitation_inscription_modal_actions_cancelBtn: this.props.t('options.tournaments.setupTournamentInvitationInscription.modalBtns.cancelBtn') });
            this.setState({tournament_player_id: player_id});
            this.setState({change_player_state: player_state});
            this.setState({team_id: tournament_team_id})
            this.props.changeInvitationInscriptionActionsModalStateAction(true)
        }
        if(player_state === "Enrolled"){
            this.props.changeTeamPlayerState(player_id, player_state, tournament_team_id)
        }
    }

    handleOnCloseInvitationInscriptionActionsModal() {
        this.setState({ team_player: [] });
        this.setState({ change_player_state: ''});
        this.setState({invitation_inscription_modal_actions_mainLabel: ''});
        this.setState({invitation_inscription_modal_actions_secondaryLabel: '' })
        this.setState({accept_team_inscription_btn: false})
        this.setState({invitation_inscription_modal_actions_acceptBtn: '' });
        this.setState({invitation_inscription_modal_actions_cancelBtn: '' });            
        this.setState({tournament_player_id: ''});            
        this.setState({team_id: ''});
        this.setState({is_transfer_player: false});
        this.setState({selected_player: []});
        this.setState({teamname: ''});
        this.setState({delete_staff: undefined});
        this.props.changeInvitationInscriptionActionsModalStateAction(false);
    }

    handleTeamsFilterinPhases = (phaseId, type_filter) =>{ 
        this.setState({teams_filter_type: type_filter});
        this.props.loadTournamentPhaseFilterTeams( phaseId, type_filter);
    }

    handleBestThirdByGroup = (status, groupId)=> {
        this.props.UpdateBestThirdToGroup(status, groupId);
        this.props.loadTournament( this.props.state.leagueTournaments?.tournament?.id );
        // this.props.setTournamentActivePhase(this.props.state.leagueTournaments?.tournament?.id, this.props.state.leagueTournaments?.tournament?.tournament_active_phase?.id);
    }

    handleLoadTieBreakerByPhase = (phaseId) => {
        this.setState( { edit_phase_component: true } );
        this.props.loadCriteriaTournamentPhase(phaseId);
    }

    handleEditPhase = async (phasesId, phaseData ) => {
        this.props.EditPhaseById(phaseData?.dataToEdit, phasesId?.id);
        this.setState({ change_phase_state: ''});
        this.setState({ phase_inf_edit: {} });
        await this.props.loadTournament( this.props.state.leagueTournaments?.tournament?.id );        
        this.setState( { edit_phase_component: false } );
        this.props.changeInvitationInscriptionActionsModalStateAction(false);
        this.setState({preSelectedTab: 4});
    }

    handleReturnPhaseComponent = () => {
        this.setState( { edit_phase_component: false} );
        this.setState({preSelectedTab: 4});
    }

    handleModalStateForPhases = (phase_data, phase_status, index)=>{
        if(phase_status === 'phase_edit_status'){
            this.setState({ change_phase_state: phase_status});
            this.setState({ phase_inf_edit: phase_data });
            this.setState({invitation_inscription_modal_actions_mainLabel: this.props.t('options.tournaments.setupTournamentInvitationInscription.invitationInscriptionModal.acceptEditPhase') });
            this.setState({invitation_inscription_modal_actions_secondaryLabel: this.props.t('options.tournaments.setupTournamentInvitationInscription.invitationInscriptionModal.acceptEditPhaseSubtitle') });
            this.setState({invitation_inscription_modal_actions_acceptBtn: this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelEditSaveBasicDataBtn')});
            this.setState({invitation_inscription_modal_actions_cancelBtn: this.props.t('options.tournaments.setupTournamentInvitationInscription.modalBtns.cancelBtn') });
            this.props.changeInvitationInscriptionActionsModalStateAction(true);
            this.setState({accept_team_inscription_btn: true});
        }
        if(phase_status === 'phases_edit_validation'){
            this.setState({ change_phase_state: phase_status});
            this.setState({ phase_inf_edit: {} });
            this.setState({accept_team_inscription_btn: true});
            this.setState({invitation_inscription_modal_actions_mainLabel: this.props.t('options.tournaments.setupTournamentInvitationInscription.invitationInscriptionModal.validationEditPhase') });
            this.setState({invitation_inscription_modal_actions_secondaryLabel: ''});
            this.setState({invitation_inscription_modal_actions_acceptBtn: this.props.t('options.tournaments.setupTournamentInvitationInscription.modalBtns.continueBtn') });
            this.setState({invitation_inscription_modal_actions_cancelBtn: this.props.t('options.tournaments.setupTournamentInvitationInscription.modalBtns.cancelBtn') });
            this.props.changeInvitationInscriptionActionsModalStateAction(true);
        }
        if(phase_status === 'phases_delete_validation'){
            this.setState({ change_phase_state: phase_status});
            this.setState({ phase_inf_edit:{ phase_data, index} });
            this.setState({invitation_inscription_modal_actions_mainLabel: this.props.t('options.tournaments.setupTournamentInvitationInscription.invitationInscriptionModal.deletePhase') });
            this.setState({invitation_inscription_modal_actions_secondaryLabel:  this.props.t('options.tournaments.setupTournamentInvitationInscription.invitationInscriptionModal.deletePhaseSubTitle') });
            this.setState({invitation_inscription_modal_actions_acceptBtn: this.props.t('options.tournaments.setupTournamentInvitationInscription.modalBtns.deleteBtn') });
            this.setState({invitation_inscription_modal_actions_cancelBtn: this.props.t('options.tournaments.setupTournamentInvitationInscription.modalBtns.cancelBtn') });
            this.props.changeInvitationInscriptionActionsModalStateAction(true);
            
        }
        //groupId = index ,phase_data= teamName
        if(phase_status === 'team_delete_validation'){
            this.setState({ change_phase_state: phase_status});
            this.setState({ phase_inf_edit:{ phase_data, index} });
            this.setState({invitation_inscription_modal_actions_mainLabel: this.props.t('options.tournaments.setupTournamentInvitationInscription.invitationInscriptionModal.teamDeleteValidation') });
            this.setState({invitation_inscription_modal_actions_secondaryLabel: this.props.t('options.tournaments.setupTournamentInvitationInscription.invitationInscriptionModal.teamDeleteValidationSubt') });
            this.setState({invitation_inscription_modal_actions_acceptBtn: this.props.t('options.tournaments.setupTournamentInvitationInscription.modalBtns.deleteBtn') });
            this.setState({invitation_inscription_modal_actions_cancelBtn: this.props.t('options.tournaments.setupTournamentInvitationInscription.modalBtns.cancelBtn') });
            this.props.changeInvitationInscriptionActionsModalStateAction(true);
            
        }

        if(phase_status === 'phases_delete_Knockout'){
            this.setState({ change_phase_state: phase_status});
            this.setState({ phase_inf_edit:phase_data });
            this.setState({invitation_inscription_modal_actions_mainLabel: this.props.t('options.tournaments.setupTournamentInvitationInscription.invitationInscriptionModal.phaseDeleteKnockout') });
            this.setState({invitation_inscription_modal_actions_secondaryLabel:  this.props.t('options.tournaments.setupTournamentInvitationInscription.invitationInscriptionModal.phaseDeleteKnockoutSubTitle') });
            this.setState({invitation_inscription_modal_actions_acceptBtn: this.props.t('options.tournaments.setupTournamentInvitationInscription.modalBtns.deleteBtn') });
            this.setState({invitation_inscription_modal_actions_cancelBtn: this.props.t('options.tournaments.setupTournamentInvitationInscription.modalBtns.cancelBtn') });
            this.props.changeInvitationInscriptionActionsModalStateAction(true);
            
        }

        if(phase_status === 'phases_move_to_group'){
            this.setState({ change_phase_state: phase_status});
            this.setState({ phase_inf_edit:phase_data });
            this.setState({accept_team_inscription_btn: true});
            this.setState({invitation_inscription_modal_actions_mainLabel: this.props.t('options.tournaments.setupTournamentInvitationInscription.invitationInscriptionModal.moveTeamValidation') });
            this.setState({invitation_inscription_modal_actions_secondaryLabel:  this.props.t('options.tournaments.setupTournamentInvitationInscription.invitationInscriptionModal.moveTeamValidationSubtitle') });
            this.setState({invitation_inscription_modal_actions_acceptBtn: this.props.t('options.tournaments.setupTournamentInvitationInscription.modalBtns.continueBtn') });
            this.setState({invitation_inscription_modal_actions_cancelBtn: this.props.t('options.tournaments.setupTournamentInvitationInscription.modalBtns.cancelBtn') });
            this.props.changeInvitationInscriptionActionsModalStateAction(true);
        }

    }

    handleMoveToGroup = (group_best_third, team, original_id ,group_id) =>{
        this.props.UpdateBestThirdToGroup(group_best_third, original_id, team, group_id);
        this.props.loadTournament( this.props.state.leagueTournaments?.tournament?.id );
    }

    handleTeamGroupPhaseknockout = (win_team, lost_team, group_id) =>{
        this.props.transferTeamKnockout(win_team, lost_team, group_id);
    }

    handleReturnFinishComponent = () =>{
        this.props.changeFinishGroupsPhaseModalState( false );
        this.props.changeFinishFreePhaseModalState( false );
        this.setState( { edit_basic_info: false } );
        this.setState( { edit_phase_component: false } );
        this.setState( { general_end_phases: false } );
        this.setState( { edit_tournament: true } );
    }

    handleOnShareTournamentLinkClick = async (tournament) => {
        try {
            const url = `${web_url}/api/social/tournament/hub/${tournament.id}/`;
            await navigator.clipboard.writeText(url);
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkSuccess'));
        } catch (err) {
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkError'));
        }
    };

    handleOnShareInscriptionTournament(tournament_team_id){
        this.props.changeShareInscriptionLinkModalStateAction(true);
        const url = `${web_url}/api/social/tournament/hub/team/${tournament_team_id}/`;
        this.setState( {share_inscription_url: url } );
    }

    handleOnShareInscriptionTournamentLinkClick = async () => {
        try {
            await navigator.clipboard.writeText(this.state.share_inscription_url);
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkSuccess'));
        } catch (err) {
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkError'));
        }
    };

    handleOpenShareIframeLinkModal(tournament_id){
        this.props.changeShareIframeLinkStateAction(true);
        const codeIframe = `<iframe id="inlineFrameExample"
            title="Inline Frame Example"
            width="600"
            height="300"
            src="${web_url}/api/embed/tournament/team-positions-table/${tournament_id}/">
        </iframe>`
        this.setState( {share_iframe_url: codeIframe } );
    };

    handleOnShareIFramePositionTableLink = async () => {
        try {
            await navigator.clipboard.writeText(this.state.share_iframe_url);
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkSuccess'));
        } catch (err) {
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkError'));
        }
    };

    handleAddStaf(profile){
        if(profile === 'admin'){
            this.setState( {add_staff_modal_title: this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.addStaffModal.modalAdminTitle'), 
                share_add_staff_url: `${web_url}/api/social/tournament/${this.state.selected_tournament.id}/join/?role=Administrator`, 
                staff_role: 'Administrator',
                add_staff_modal_subtitle: this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.addStaffModal.searchStaffAdminTitle'),
                add_staff_modal_share_title: this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.addStaffModal.shareInvitationAdminMessage'),
                add_staff_modal_share_note: this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.addStaffModal.shareInvitiationAdminNote'),
                add_staff_modal_search_placeholder:  this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.addStaffModal.searchMembersPlaceholder'),
                add_staff_modal_button_label: this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.addStaffModal.AddButtonLbl'),
                add_staff_modal_icon_share_desc: 'hubicon-manage_accounts-1',
                set_type: 'member' } );
        }
        if(profile === 'referee'){
            this.setState( {add_staff_modal_title: this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.addStaffModal.modalRefereeTitle'), 
                share_add_staff_url: `${web_url}/api/social/tournament/${this.state.selected_tournament.id}/join/?role=Referee`, 
                staff_role: 'Referee',
                add_staff_modal_subtitle: this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.addStaffModal.searchStaffRefereeTitle'),
                add_staff_modal_share_title: this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.addStaffModal.shareInvitationRefereeMessage'),
                add_staff_modal_share_note: this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.addStaffModal.shareInvitiationRefereeNote'),
                add_staff_modal_search_placeholder:  this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.addStaffModal.searchMembersPlaceholder'),
                add_staff_modal_button_label: this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.addStaffModal.AddButtonLbl'),
                add_staff_modal_icon_share_desc: 'icon-wistle',
                set_type: 'member' } );
        }
        if(profile === 'assigner'){
            this.setState( {add_staff_modal_title: this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.addStaffModal.modalAssignerTitle'), 
                share_add_staff_url: `${web_url}/api/social/tournament/${this.state.selected_tournament.id}/join/?role=Assigner`, 
                staff_role: 'Assigner',
                add_staff_modal_subtitle: this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.addStaffModal.searchStaffAssignerTitle'),
                add_staff_modal_share_title: this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.addStaffModal.shareInvitationAssignerMessage'),
                add_staff_modal_share_note: this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.addStaffModal.shareInvitiationAssignerNote'),
                add_staff_modal_search_placeholder:  this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.addStaffModal.searchMembersPlaceholder'),
                add_staff_modal_button_label: this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.addStaffModal.AddButtonLbl'),
                add_staff_modal_icon_share_desc: 'hubicon-assignment_ind',
                set_type: 'member' } );
        }
        //Se reutiliza el modal de addstaff para invitar/inscribir equipos
        if(profile === 'inscription'){
            this.setState( {add_staff_modal_title: this.props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelInscriptionTeam'), 
                share_add_staff_url: `${web_url}/api/social/tournament/hub/${this.state.selected_tournament.id}/`, 
                staff_role: 'Enrolled',
                add_staff_modal_subtitle: this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabTeams.modals.modalAddStafMemberTeam.searchRegisterTeamTitle'),
                add_staff_modal_share_title: this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabTeams.modals.modalAddStafMemberTeam.shareUrlInviteTeam'),
                add_staff_modal_share_note: '',
                add_staff_modal_search_placeholder:  this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.addStaffModal.searchTeamsPlaceHolder'),
                add_staff_modal_button_label: this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.addStaffModal.RegisterButtonLbl'),
                add_staff_modal_icon_share_desc: 'icon-trophy',
                set_type: 'team' } );
        }
        if(profile === 'invitation'){
            
            this.setState( {add_staff_modal_title: this.props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelTitleInviteTeam'), 
                share_add_staff_url: `${web_url}/api/social/tournament/hub/${this.state.selected_tournament.id}/`, 
                staff_role: 'Invited',
                add_staff_modal_subtitle: this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabTeams.modals.modalAddStafMemberTeam.searchInviteTeamTitle'),
                add_staff_modal_share_title: this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabTeams.modals.modalAddStafMemberTeam.shareUrlInviteTeam'),
                add_staff_modal_share_note: '',
                add_staff_modal_search_placeholder:  this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.addStaffModal.searchTeamsPlaceHolder'),
                add_staff_modal_button_label: this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.addStaffModal.InviteButtonLbl'),
                add_staff_modal_icon_share_desc: 'icon-trophy',
                set_type: 'team' } );
        }
        
        this.props.changeAddStaffModalStateAction(true);
    };

    handleOnShareAddStaffLink = async () => {
        try {
            await navigator.clipboard.writeText(this.state.share_add_staff_url);
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkSuccess'));
        } catch (err) {
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkError'));
        }
    };

    handleCloseAddStaffModal(){
        this.setState( {add_staff_modal_title: undefined, share_add_staff_url: undefined, staff_role: undefined, add_staff_modal_subtitle: undefined, add_staff_modal_share_title: undefined, add_staff_modal_share_note: undefined} );
        this.props.changeAddStaffModalStateAction(false);
    }

    async addStaff(item){
        if(this.state.set_type === 'member'){
            this.props.addUserToStaff(item.username, this.state.staff_role, 'Active',this.state.selected_tournament.id );
            this.props.showCopyPostMessage(this.state.staff_role === 'Administrator'? this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.addStaffModal.successfulAdminMessage'): this.state.staff_role === 'Referee'? this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.addStaffModal.successfulRefereeMessage') :this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.addStaffModal.successfulAssignerMessage') );            
        }
        if(this.state.set_type === 'team'){
            await this.props.addTeamToTournament( this.props.state.leagueTournaments?.tournament.id, item.teamname, this.state.staff_role );
            this.props.loadTournament( this.props.state.leagueTournaments?.tournament.id);
        }
        //this.handleCloseAddStaffModal();
    }

    handleDeleteStaff(role_id){
        this.props.changeInvitationInscriptionActionsModalStateAction(true);
        this.setState({delete_staff:role_id})
        this.setState({invitation_inscription_modal_actions_mainLabel: this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.deleteStaffModal.mainLabel') });
        this.setState({invitation_inscription_modal_actions_acceptBtn: this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.deleteStaffModal.acceptBtn') });
        this.setState({invitation_inscription_modal_actions_cancelBtn: this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.deleteStaffModal.cancelBtn') });
    }

    handleCleanBaloaMemberTeamData = () => {
        if(this.state.set_type == 'member'){
            this.props.cleanBaloaUserAction({});
        }
        if(this.state.set_type == 'team'){
            this.props.cleanBaloaTeamsSearchAction({});
        }
        
    }

    handlesearchMemberTeamData = (search_data) => {
        if(this.state.set_type == 'member'){
            this.props.searchBaloaUser(search_data);
        }
        if(this.state.set_type == 'team'){
            this.props.searchBaloaTeamToInvited(search_data);
        }
        
    }

    handleOnLoadRefereeingPaymentUrl = async (payment_data, match_id, match_date_id) => {
        payment_data.redirect = `/league/tournament/${this.props.state.page.page?.pagename}?tournamentid=${this.props.state.leagueTournaments.tournament.id}?phase=${this.props.state.leagueTournaments.tournament.tournament_active_phase?.id}?match_date=${match_date_id}?match=${match_id}?type=referee`;
        await this.props.loadRefeeringPaymentUrl(payment_data, match_id)
        if(this.props.state.leagueTournaments?.refereeing_payment_url){
            const newWindow = window.open(this.props.state.leagueTournaments.refereeing_payment_url.url, '_self', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        }
    }
    
    handleNewTournamentCreateOnBoardingAccount = () => {
        this.setState({new_tournament: false});
        this.props.history.push(`/league/payments`);
    }

    handleOnShareMatchLinkClick = async (tournament) => {
        try {
            const url = `${web_url}/api/social/tournament/match/${this.props.state.leagueMatches.match_report.id}/`;
            await navigator.clipboard.writeText(url);
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkSuccess'));
        } catch (err) {
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkError'));
        }
    };
    
    handleOnDownloadForms( tournament_id){
        this.props.downloadFormData(tournament_id);
    }

    handleLoadformPlayer( tournament_id){
        this.props.loadTournamentPlayerFormTemplate("player_tournament_form", tournament_id);
    }

    handleOnDownloadPayments( tournament_id){
        this.props.downloadPaymentData(tournament_id);
    }

    async handleOnEditPhaseModal (edit_phase_type, group, isCreatePhase) {
        if(edit_phase_type === 'create_group' || edit_phase_type === 'create_key') {
            await this.props.createTournamentPhaseGroup(this.props.state.leagueTournaments.tournament.tournament_active_phase?.id, this.props.state.leagueTournaments.tournament?.id);
        }
        this.setState({edit_phase_type: edit_phase_type});
        this.setState({edit_phase_selected_group: group});
        if(!isCreatePhase) {
            this.props.changeEditPhaseModalStateAction(true);
        };
    }
    
    handleOnClickNewPhase(state, clickSource){
        this.setState({create_phase_component: state});
        this.setState({click_source_create_phase: clickSource});
        this.props.loadFairPlaySettings(this.pagename);
    }

    handleDeleteGroupPhase = (groupId)=>{
        this.props.deleteGroupFromPhase(groupId, this.props.state.leagueTournaments.tournament.tournament_active_phase?.id, this.props.state.leagueTournaments.tournament?.id, this.state.teams_filter_type, 'tournament_active_phase_teams_modal');
    }

    handleNumberClassified = (id_group, classified_count) => {
        this.props.updateClassifiedCountGroup(id_group, classified_count, this.props.state.leagueTournaments.tournament.tournament_active_phase?.id, this.props.state.leagueTournaments.tournament?.id);
    }

    handleOnEditPhaseAction (phaseType) {
        if(this.state.edit_phase_type === 'create_group') {
            this.setState({modal_validation_message: this.props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.tournamentEditPhaseModal.validationGroupText')});
        }
        if(this.state.edit_phase_type === 'create_key' && phaseType !== 'edit_key_validation') {
            this.setState({modal_validation_message: this.props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.tournamentEditPhaseModal.validationKnockoutText')});
        }
        if(this.state.edit_phase_type === 'edit_group' || this.state.edit_phase_type === 'edit_free') {
            this.setState({modal_validation_message: this.props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.tournamentEditPhaseModal.validationEditGroupText')});
        }
        if(this.state.edit_phase_type === 'edit_key') {
            this.setState({modal_validation_message: this.props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.tournamentEditPhaseModal.validationEditKeyText')});
        }
        if(phaseType === 'createPhaseTypeGroups'){
            this.setState({modal_validation_message: this.props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.tournamentEditPhaseModal.validationCreateGroupText')});
        }
        if(phaseType === 'createPhaseTypeFree'){
            this.setState({modal_validation_message: this.props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.tournamentEditPhaseModal.validationCreateFreeText')});
        }
        if(phaseType === 'createPhaseTypeKnockout'){
            this.setState({modal_validation_message: this.props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.tournamentEditPhaseModal.validationCreateKnockoutText')});
        }
        if(phaseType === 'end_phase'){
            this.setState({modal_validation_message: this.props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.tournamentEditPhaseModal.finishPhaseText')});
        }
        if(phaseType === 'start_phase'){
            this.setState({modal_validation_message: this.props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.tournamentEditPhaseModal.newPhaseText')});
        }
        if(phaseType === 'edit_key_validation'){
            this.setState({modal_validation_message: this.props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.tournamentEditPhaseModal.validationKnockoutText')});
        }
        this.props.changeEditPhaseValidationModalStateAction(true);
    }

    handleOnPhaseActions(phase_action) {
        if(phase_action === 'end_phase'){
            this.handleOnClickFinishPhase(this.props.state.leagueTournaments.tournament.tournament_active_phase);
        }
        if(phase_action === 'start_phase'){
            this.handleAddTeamToGroupModal();
        }
        this.setState({modal_validation_message: ''}); 
        this.setState({phase_action: ''}); 
        this.setState({state_phase_actions: false});
        this.props.changeEditPhaseValidationModalStateAction(false);
    }

    handleVerifyMatches(phase_action) {
        this.setState({state_phase_actions: true});
        this.setState({phase_action: phase_action});
        this.handleOnEditPhaseAction(phase_action);
    }

    handleOncloseEditPhase() {
        this.setState({edit_phase_selected_group: []});
        this.setState({edit_phase_type: ''})
        this.props.changeEditPhaseModalStateAction(false);
    }

    handleOnCloseTeamToGroupModal () {
        let groups_teams_validation = false;
        this.setState({teams_to_group: false});
        this.props.state.leagueTournaments.tournament.tournament_active_phase?.groups.forEach((group, index) => {
            if (group && group.teams?.length < 2) {
                groups_teams_validation = true;                
            }             
        });
        if(groups_teams_validation) {
           this.props.cleanAllGroupOfPhase(this.props.state.leagueTournaments.tournament.tournament_active_phase?.id, this.props.state.leagueTournaments.tournament?.id, 'tournament_active_phase_teams_modal');
        }    
        this.props.changeAddTeamToGroupModalStateAction(false);
    }

    resetPreSelectedTab () {
        this.setState({preSelectedTab: 0});
    }

    handleOnShareTeamPageInscription(pagename){
        this.setState( {type_inscription_link_modal:"sharePageLink"} );
        this.props.changeShareInscriptionLinkModalStateAction(true);
        const url = `${web_url}/api/social/pages/${pagename}/teams/`;
        this.setState( {share_inscription_url: url } );
    }

    handleRejectMultiplePlayers( players_states ) {
        this.setState( {rejected_multiple_players: players_states } );
        this.props.changeRejectMultiplePlayerModalStateAction( true );
    }

    handleAcceptRejectMultiplePlayers() {
        this.props.enrollRejectMultiplePlayers( this.state.selected_team.tournament_team_id, this.state.rejected_multiple_players, 'Enrolled|Pending|Late_Register' );
        this.setState( {rejected_multiple_players: [] } );
        this.props.changeRejectMultiplePlayerModalStateAction( false );
    }

    handleListExistingForm(form_type) {
        this.props.loadListFormExisting(this.pagename,form_type);
    }

    handleOnLoadPageFormTemplate(id_form_template) {
        this.props.loadPageFormTemplate(id_form_template);
    }

    handleSendCarnet( form, form_type_id, caption, templateImage, orientation ){
        this.props.createCarnetFormTemplate(form, form_type_id, caption, this.state.selected_tournament.id, templateImage, orientation);
    }; //carnet



    handleSaveCardId(cardIdData, emptyCardIdQuestions, savedForm){
        let arrayNewQuestions = [];
        //eslint-disable-next-line array-callback-return
        cardIdData.map( data =>{
            let newQuestionObj = {                
                form_question_template: data.id,
                text_answer:"",
                file_document:null,
                date_field:null,
                boolean_answer:null,
                answers:[],
                question_type: "json" 
            }
            if(data.type === 'json'){
                newQuestionObj.json_answer ={
                    type: data.type, 
                    fontFamily: data.fontFamily, 
                    fontSize: data.fontSize, 
                    color: data.color, 
                    x: data.x, 
                    y: data.y,
                }
            }
            if(data.type === 'image'){
                newQuestionObj.json_answer ={
                    type: data.type, 
                    fileName: '', 
                    width: data.width, 
                    height: data.height, 
                    shape: data.shape, 
                    x: data.x, 
                    y: data.y,
                }
            }

            if(data.type === 'file'){
                newQuestionObj.json_answer ={
                    type: data.type, 
                    fileName: '', 
                    width: data.width, 
                    height: data.height, 
                    shape: data.shape, 
                    x: data.x, 
                    y: data.y,
                }
            }

            arrayNewQuestions.push(newQuestionObj);
        });

        //Se agregan las preguntas que no se utilizaron en el diseño del carnet pero si se agregaron a la plantilla
        if(emptyCardIdQuestions.length > 0){
            //eslint-disable-next-line array-callback-return
            emptyCardIdQuestions.map( data =>{
                let newQuestionObj = {                
                    form_question_template: data.id,
                    text_answer:"",
                    file_document:null,
                    date_field:null,
                    boolean_answer:null,
                    answers:[],
                    question_type: "json",
                    json_answer: "{}"
                }
                arrayNewQuestions.push(newQuestionObj);
            });
        }

        let template_image_question = savedForm.questions.find(ques => ques.form_question_template.caption === 'Plantilla');
        if(template_image_question){
            let newQuestionObj = {                
                form_question_template: template_image_question.form_question_template.id,
                text_answer:"",
                file_document: this.props.state.leagueTournaments.tournament?.card_id_template_image,
                date_field:null,
                boolean_answer:null,
                answers:[],
                question_type: "file" 
            }
            arrayNewQuestions.push(newQuestionObj);
        }

        let orientation_question = savedForm.questions.find(ques => ques.form_question_template.caption === 'Orientacion');

        if(orientation_question){
            let newQuestionObj = {                
                form_question_template: orientation_question.form_question_template.id,
                text_answer: orientation_question.text_answer,
                file_document: null,
                date_field:null,
                boolean_answer:null,
                answers:[],
                question_type: "text" 
            }
            arrayNewQuestions.push(newQuestionObj);
        }

        let new_form_info = {
            form_template: savedForm.form_template,
            questions: arrayNewQuestions
        }
        

        if(emptyCardIdQuestions.length > 0){
            this.setState({cardIdInfoData: new_form_info});
            this.props.changeCardIdValidationModalStateAction(true);
        }else{
            this.props.saveCardIdFormInfoAndClean(new_form_info);//Guarda la informacion del carnet y limpia la info del estado
        }

    }
    

    prepareFirstCardIdFormData(form_template, imageB64, orientation){
        let new_questions = [];
        //eslint-disable-next-line array-callback-return
        form_template.questions?.map(question =>{
            let new_question = {}
            if(question.caption === 'Plantilla' ){
                if(imageB64 !== ''){
                    new_question = {
                        form_question_template: question.id,
                        file_document: imageB64,
                        text_answer:"",
                        date_field:null,
                        boolean_answer:null,
                        answers:[],
                        question_type:"file"
                    }
                }else {
                    new_question = {
                        form_question_template: question.id,
                        file_document: '',
                        text_answer:"",
                        date_field:null,
                        boolean_answer:null,
                        answers:[],
                        question_type:"file"
                    }
                }
            }
            if(question.caption === 'Orientacion'){
                new_question = {
                    form_question_template: question.id,                    
                    text_answer: orientation,
                    file_document:null,
                    date_field:null,
                    boolean_answer:null,
                    answers:[],
                    question_type: "text"
                }
            }
            if(question.caption !== 'Orientacion' && question.caption !== 'Plantilla'){
                new_question = {
                    form_question_template: question.id,                    
                    text_answer: '',
                    file_document:null,
                    date_field:null,
                    boolean_answer:null,
                    json_answer: "{}",
                    answers:[],
                    question_type: "json"
                }
            }
            new_questions.push(new_question);
        });
        let new_form_info = {
            form_template: form_template.id,
            questions: new_questions
        }

        this.props.saveCardIdFormInfo(new_form_info);//Guarda la orientacion y la imagen en el formulario de carnet del torneo
    }
    
    prepareCardIdCreatorData(cardIdTemplate){
        let cardIdData = [];
        //eslint-disable-next-line array-callback-return
        cardIdTemplate?.questions?.map(question => {
            let new_ques = {};
            if(question.code === 'image'){
                new_ques = {
                    id: question.id,
                    type: 'image',
                    caption: question.caption,
                    fileName: '', 
                    width: '60pt', 
                    height: '60pt', 
                    shape: 'square',
                    x: 100, 
                    y: 100, 
                    value: ''
                };
            }else if(question.code === 'file'){
                new_ques = {
                    id: question.id,
                    type: 'file',
                    caption: question.caption,
                    fileName: '', 
                    width: '60pt', 
                    height: '60pt', 
                    shape: 'square',
                    x: 100, 
                    y: 100, 
                    value: ''
                };
            }else if(question.caption !== 'Orientacion' && question.caption !== 'Plantilla'){
                new_ques = {
                    id: question.id,
                    type: 'json',
                    caption: question.caption,
                    fontFamily: 'Roboto', 
                    fontSize: '12pt', 
                    color: '#000000', 
                    x: 100, 
                    y: 100, 
                    value: ''
                };
            }
            if(Object.keys(new_ques).length > 0){
                cardIdData.push(new_ques);
            }
            
        })

        return cardIdData;
    }

    GetCardIdBackgroundImage(savedForm){
        let template_question = savedForm?.questions?.find(ques => ques.form_question_template.caption === 'Plantilla');
        if(template_question){
            return template_question.file_document;
        }else{
            return null;
        }        
    }

    handleOnDeleteCardIdTemplate(){
        this.props.changeCardIdDeleteValidationModalStateAction( true );
    }

    async loadCardIdsTeamInfo( teams_list ){
        await this.props.loadCardIdsData( this.props.state.leagueTournaments?.tournament?.id, teams_list );
        //this.prepareCardIdsPDFData();
        this.props.changeCardIdPdfGeneratorModalStateAction(true);
    }

    findQuestionResponses(answers){
        let newAnswers = '';
        //eslint-disable-next-line array-callback-return
        answers?.map(ans => {
            if(ans.is_checked){
                newAnswers += ans.form_template_answer.caption + ',';
            }    
        });
        newAnswers= newAnswers.slice(0, -1);
        return newAnswers;
    }

    prepareCardIdsPDFData(){
        let all_players_data = [];
        if(this.props.state.leagueTournaments?.card_ids_data?.results?.length > 0){
            //eslint-disable-next-line array-callback-return
            this.props.state.leagueTournaments?.card_ids_data?.results?.map(team => {
                //eslint-disable-next-line array-callback-return
                team?.player_forms.map(player => {
                    let player_data = [];
                    //eslint-disable-next-line array-callback-return
                    player?.questions?.map(ques => {
                        let card_id_question = this.props.state.leagueTournaments?.card_ids_data?.form_card.questions.find(q => q.form_question_template.caption === ques.caption )
                        if(card_id_question){
                            let new_question = undefined;
                            if((card_id_question.form_question_template.code === 'text' || card_id_question.form_question_template.code === 'email' || card_id_question.form_question_template.code === 'phone') && card_id_question.json_answer !== "{}"){
                                new_question = {
                                    caption: card_id_question.form_question_template.caption,
                                    code: card_id_question.form_question_template.code,
                                    color: card_id_question.json_answer.color,
                                    fontFamily: card_id_question.json_answer.fontFamily,
                                    fontSize: card_id_question.json_answer.fontSize,
                                    x: card_id_question.json_answer.x,
                                    y: card_id_question.json_answer.y,
                                    value: ques.text_answer,
                                }
                            }
                            if(card_id_question.form_question_template.code === 'date'){
                                new_question = {
                                    caption: card_id_question.form_question_template.caption,
                                    code: card_id_question.form_question_template.code,
                                    color: card_id_question.json_answer.color,
                                    fontFamily: card_id_question.json_answer.fontFamily,
                                    fontSize: card_id_question.json_answer.fontSize,
                                    x: card_id_question.json_answer.x,
                                    y: card_id_question.json_answer.y,
                                    value: ques.date_field,
                                }
                            }
                            if(card_id_question.form_question_template.code === 'image'){
                                new_question = {
                                    caption: card_id_question.form_question_template.caption,
                                    code: card_id_question.form_question_template.code,
                                    shape: card_id_question.json_answer.shape,
                                    width: card_id_question.json_answer.width,
                                    height: card_id_question.json_answer.height,
                                    x: card_id_question.json_answer.x,
                                    y: card_id_question.json_answer.y,
                                    value: ques.file_document,
                                    data: ques.parent_data,
                                }
                            }
                            if(card_id_question.form_question_template.code === 'file'){
                                new_question = {
                                    caption: card_id_question.form_question_template.caption,
                                    code: card_id_question.form_question_template.code,
                                    shape: card_id_question.json_answer.shape,
                                    width: card_id_question.json_answer.width,
                                    height: card_id_question.json_answer.height,
                                    x: card_id_question.json_answer.x,
                                    y: card_id_question.json_answer.y,
                                    value: ques.file_document,
                                    data: ques.parent_data,
                                }
                            }
                            if(card_id_question.form_question_template.code === 'boolean'){//TO DO
                                new_question = {
                                    caption: card_id_question.form_question_template.caption,
                                    code: card_id_question.form_question_template.code,
                                    color: card_id_question.json_answer.color,
                                    fontFamily: card_id_question.json_answer.fontFamily,
                                    fontSize: card_id_question.json_answer.fontSize,
                                    x: card_id_question.json_answer.x,
                                    y: card_id_question.json_answer.y,
                                    value: ques.boolean_answer ? this.props.t('tournament:registerPlayer.viewForm.trueAnswer'): this.props.t('tournament:registerPlayer.viewForm.falseAnswer'),
                                }
                            }
                            if(card_id_question.form_question_template.code === 'single_choice' || card_id_question.form_question_template.code === 'multi_choice' || card_id_question.form_question_template.code === 'list'){//TO DO: , 
                                new_question = {
                                    caption: card_id_question.form_question_template.caption,
                                    code: card_id_question.form_question_template.code,
                                    color: card_id_question.json_answer.color,
                                    fontFamily: card_id_question.json_answer.fontFamily,
                                    fontSize: card_id_question.json_answer.fontSize,
                                    x: card_id_question.json_answer.x,
                                    y: card_id_question.json_answer.y,
                                    value: this.findQuestionResponses(ques.answers),
                                }
                            }
                            if(new_question){
                                player_data.push(new_question);
                            }
                            
                        }
                    })
                    all_players_data.push(player_data)
                })
            })
        }
        this.setState({downloadCardIdData: all_players_data});
        return true;
    }

    async downloadPlayerCardId( tournament_player_id){
        await this.props.loadPlayerCardIdData( tournament_player_id );
        //this.prepareCardIdsPDFData();
        this.props.changeCardIdPdfGeneratorModalStateAction(true);
    }

    async downloadStaffCardId( team_profile_id, tournament_team_id){
        await this.props.loadStaffCardIdData( team_profile_id, tournament_team_id );
        //this.prepareCardIdsPDFData();
        this.props.changeCardIdPdfGeneratorModalStateAction(true);
    }

    async handlePageFieldsActions ( form_data ) {   
        switch (this.state.field_action_type) {
            case 'create-page-field':
                form_data.page_id = this.props.state.page.page?.id;
                this.props.createPageFields( form_data, this.props.state.page.page.pagename );
                break;
            case 'edit-page-field':
                await this.props.editPageFields( form_data, this.state.page_field.id, this.props.state.page.page.pagename );
                if(this.state.is_tournament_field) {
                    this.props.loadTournamentFieldsData( this.props.state.leagueTournaments.tournament.id );
                    this.setState({ is_tournament_field: false });
                }
                break;
            case 'create-tournament-field':
                form_data.page_id = this.props.state.page.page?.id;
                this.props.createPageFields( form_data, this.props.state.page.page.pagename );
                break;        
            default:
                break;
        }
        this.setState({ field_action_type: '' });
        this.setState({ page_field: {} });
        this.props.changeFieldsCreateModalStateAction(false);
    }

    handleFieldsAdminActions (action_type, field) { 
        this.setState({ field_action_type: action_type });
        this.setState({ page_field: field });
        if(action_type === 'create-page-field' || action_type === 'edit-page-field' || action_type === 'create-tournament-field') {
            this.props.changeFieldsCreateModalStateAction( true ); 
        }
        if(action_type === 'delete-page-field') {
            this.props.changeFieldsWarningModalStateAction( true ); 
        }
        if(action_type === 'delete-tournament-field') {
            this.props.changeTournamentFieldsWarningModalStateAction( true ); 
        }
    }

    async handleDeleteField () {
        await this.props.deletePageField( this.state.page_field.id );
        this.props.loadPageFieldsData( this.props.state.page.page?.pagename );
        this.setState({ field_action_type: '' });
        this.setState({ page_field: {} });
        this.props.changeFieldsWarningModalStateAction( false );
    }

    async handleDeleteTournamentField() {
        this.props.deleteTournamentField( this.state.page_field.id, this.props.state.leagueTournaments.tournament.id, true );
        this.setState({ field_action_type: '' });
        this.setState({ page_field: {} });
        this.props.changeTournamentFieldsWarningModalStateAction( false );
    }

    handleTournamentFieldsAdmin () {
        this.props.changeTournamentFieldsAdminModalStateAction( true );
    }    

    handleTournamentFieldActions (action_type, field) {
        switch (action_type) {
            case 'create-tournament-field':
                this.props.createTournamentFields( field, this.props.state.leagueTournaments.tournament.id );
                this.setState({ field_action_type: '' });
                this.setState({ page_field: {} });                
                break;
            case 'delete-tournament-field':
                this.props.deleteTournamentField( field.id, this.props.state.leagueTournaments.tournament.id );
                break;
            default:
                break;
        }
        this.props.loadAvailableTournamentFieldsData(this.props.state.page.page?.pagename, this.props.state.leagueTournaments.tournament.id );
    }

    handleExistingFields () {
        this.props.loadAvailableTournamentFieldsData( this.props.state.page.page?.pagename, this.props.state.leagueTournaments.tournament.id );
        this.props.changeTournamentAddExistingFieldsStateAction( true );
    }

    async handleOnAddAvailableDates(){
        await this.props.loadGroupedAvailableFixtureDays(this.props.state.leagueTournaments.tournament.id);
        this.props.changeFixtureMatchesAvailableDatesModalStateAction( true );        
    }        

    async saveFixtureAvailableDays(tournament_days) {
        await this.props.setTournamentFixtureAvailableDays( this.props.state.leagueTournaments.tournament.id, tournament_days);
        this.props.changeFixtureMatchesAvailableDatesModalStateAction( false );
    }

    handleOnDeleteFixtureAvailableDay(tournament_day){
        if(this.state.availableTime.length > 0 && this.state.availableDays.length > 0){//Se valida que la informacion temporal del estado de las fechas exista
            var indexTime = this.state.availableTime.findIndex((el) => ((moment(el.start, ["HH:mm"]).format('HH:mm:ss') === moment(tournament_day.start_time, ["HH:mm"]).format('HH:mm:ss')) && (moment(el.end, ["HH:mm"]).format('HH:mm:ss') === moment(tournament_day.end_time, ["HH:mm"]).format('HH:mm:ss')) )); //Identificamos en que grupo esta el dia eliminado
            let newDayGroup = this.state.availableDays[indexTime]?.filter(el => el !== tournament_day.day ); //Quitamos el dia eliminado del arreglo del grupo       
            let newAvailableDays = this.state.availableDays;//Creamos uan copia del objeto this.state.availableDays
            let newAvailableTime = this.state.availableTime;//Creamos uan copia del objeto this.state.availableDays
            if(newDayGroup.length > 0){
                newAvailableDays[indexTime] = newDayGroup; //reemplazamos el arreglo de dias para ese grupo sin el dia eliminado
            }else{
                //Eliminamos el grupo (horas y dias) si no hay dias dentro de este
                newAvailableDays.splice(indexTime, 1);
                newAvailableTime.splice(indexTime, 1);
                this.setState({availableTime: newAvailableTime}); //Actualizamos el valor de this.state.availableTime en el estado
                //console.log('newAvailableTime',newAvailableTime);
            }
            
            //console.log('newAvailableDays',newAvailableDays);
            this.setState({availableDays: newAvailableDays}); //Actualizamos el valor de this.state.availableDays en el estado
        }
        this.props.deleteFixtureAvailableDay(tournament_day.id, this.props.state.leagueTournaments.tournament.id); //Eliminamos el dia en el back
    }

    async saveFixtureLockedDays(tournament_locked_date) {
        await this.props.setTournamentFixtureLockedDays( this.props.state.leagueTournaments.tournament.id, tournament_locked_date);
        this.props.changeFixtureMatchesBlockDatesModalStateAction( false );
    }

    async handleOnAddTeamField( tournament_field_id ) {
        this.setState({fixture_current_tournament_field_id: tournament_field_id});
        await this.props.loadFixtureFieldTeams( tournament_field_id );
        this.props.changeFixtureAddTeamToFieldModalStateAction( true );
    }

    setAvailableFieldTime(timeArray){
        let newAvialbleFieldTime = this.state.availableFieldTime;
        var fieldIndex = newAvialbleFieldTime.findIndex(el => el.tournament_field_id === this.state.fixture_current_tournament_field_id);
        if(fieldIndex > -1){
            newAvialbleFieldTime[fieldIndex] = {tournament_field_id: this.state.fixture_current_tournament_field_id, timeArray: timeArray}
        }else{
            newAvialbleFieldTime.push({tournament_field_id: this.state.fixture_current_tournament_field_id, timeArray: timeArray})
        }
        
        this.setState({availableFieldTime : newAvialbleFieldTime});
    }

    setAvailableFieldDays(daysArray){
        let newAvialbleFieldDays = this.state.availableFieldDays;
        var fieldIndex = newAvialbleFieldDays.findIndex(el => el.tournament_field_id === this.state.fixture_current_tournament_field_id);
        if(fieldIndex > -1){
            newAvialbleFieldDays[fieldIndex] = {tournament_field_id: this.state.fixture_current_tournament_field_id, daysArray: daysArray}
        }else{
            newAvialbleFieldDays.push({tournament_field_id: this.state.fixture_current_tournament_field_id, daysArray: daysArray});
        }
        
        this.setState({availableFieldDays : newAvialbleFieldDays});
    }

    async saveFixtureFieldAvailableDays(field_tournament_days) {
        await this.props.setTournamentFieldFixtureAvailableDays( this.state.fixture_current_tournament_field_id, field_tournament_days, this.props.state.leagueTournaments.tournament.id );
        this.props.changeFixtureFieldsMatchesAvailableDatesModalStateAction( false );
    }

    handleOnDeleteFixtureFieldAvailableDay(tournament_field_day, tournament_field_id){ 
        var fieldIndex = this.state.availableFieldDays.findIndex(el => el.tournament_field_id === tournament_field_id);//Encontramos la posicion del objeto correspondiente a la cancha a la que se le va a a eliminar la fecha
        var indexTime = this.state.availableFieldTime[fieldIndex].timeArray.findIndex((el) => ((moment(el.start, ["HH:mm"]).format('HH:mm:ss') === moment(tournament_field_day.start_time, ["HH:mm"]).format('HH:mm:ss')) && (moment(el.end, ["HH:mm"]).format('HH:mm:ss') === moment(tournament_field_day.end_time, ["HH:mm"]).format('HH:mm:ss')) )); //Identificamos en que grupo esta el dia eliminado
        let newDayGroup = this.state.availableFieldDays[fieldIndex].daysArray[indexTime]?.filter(el => el !== tournament_field_day.day ); //Quitamos el dia eliminado del arreglo del grupo       
                
        let newAvailableFieldDays = this.state.availableFieldDays;//Creamos uan copia del objeto this.state.availableFieldDays
        let newAvailableFieldTime = this.state.availableFieldTime;//Creamos uan copia del objeto this.state.availableFieldDays
        if(newDayGroup.length > 0){
            newAvailableFieldDays[fieldIndex].daysArray[indexTime] = newDayGroup; //reemplazamos el arreglo de dias para ese grupo sin el dia eliminado
        }else{
            //Eliminamos el grupo (horas y dias) si no hay dias dentro de este
            newAvailableFieldDays[fieldIndex].daysArray.splice(indexTime, 1);
            newAvailableFieldTime[fieldIndex].timeArray.splice(indexTime, 1);
            this.setState({ availableFieldTime: newAvailableFieldTime }); //Actualizamos el valor de this.state.availableTime en el estado
        }
        
        this.setState({ availableFieldDays: newAvailableFieldDays }); //Actualizamos el valor de this.state.availableFieldDays en el estado
        this.props.deleteFixtureFieldAvailableDay(tournament_field_day.id, this.props.state.leagueTournaments.tournament.id); //Eliminamos el dia en el back
    }

    handleOnAddFixtureFieldTeams( field_teams ) {        
        this.props.addFixtureTeamsToField( this.state.fixture_current_tournament_field_id, field_teams, this.props.state.leagueTournaments.tournament.id );
        this.props.changeFixtureAddTeamToFieldModalStateAction( false );
    }

    async handleOnFieldAddAvailableDates(tournament_field_id){
        await this.props.loadGroupedAvailableFieldFixtureDays(tournament_field_id);
        this.props.changeFixtureFieldsMatchesAvailableDatesModalStateAction( true ); 
        this.setState({fixture_current_tournament_field_id: tournament_field_id});
    }

    async saveFixtureFieldsLockedDays( tournament_field_locked_date ){
        await this.props.setTournamentFixtureFieldLockedDays( this.state.fixture_current_tournament_field_id, tournament_field_locked_date, this.props.state.leagueTournaments.tournament.id );
        this.props.changeFixtureFieldsMatchesBlockDatesModalStateAction( false );
    }

    addAvailableDateToState(date){
        let arrayStart = date.start_date.split(':');
        let arrayEnd = date.end_date.split(':');
        let hoursObj = {end: `${arrayEnd[0]}:${arrayEnd[1]}`, start: `${arrayStart[0]}:${arrayStart[1]}`};
        let newAvailableTime = this.state.availableTime;
        newAvailableTime.push(hoursObj);
        this.setState({availableTime: newAvailableTime});
        
        let daysArray = [];
        //eslint-disable-next-line array-callback-return
        date.days.map(d=>{
            daysArray.push(d.day);
        });
        let newAvailableDays = this.state.availableDays;
        newAvailableDays.push(daysArray);        
        this.setState({availableDays: newAvailableDays});
    }

    addAvailableFieldDateToState(date){
        
        var fieldIndex = this.state.availableFieldDays.findIndex(el => el.tournament_field_id === this.state.fixture_current_tournament_field_id);//Encontramos la posicion del objeto correspondiente si existe

        let newAvailableTime = [];
        let newAvailableDays = [];

        //eslint-disable-next-line array-callback-return
        this.props.state.leagueTournaments?.grouped_fields_fixture_available_days.map(date=> {
            let arrayStart = date.start_date.split(':');
            let arrayEnd = date.end_date.split(':');
            let hoursObj = {end: `${arrayEnd[0]}:${arrayEnd[1]}`, start: `${arrayStart[0]}:${arrayStart[1]}`};
            
            newAvailableTime.push(hoursObj);
            let daysArray = [];
            //eslint-disable-next-line array-callback-return
            date.days.map(d=>{
                daysArray.push(d.day);
            });
            newAvailableDays.push(daysArray);    
        });

        let newAvailableFieldTime = this.state.availableFieldTime;
        let newAvailableFieldDays = this.state.availableFieldDays;

        if( fieldIndex === -1 ){
            newAvailableFieldTime.push({tournament_field_id: this.state.fixture_current_tournament_field_id, timeArray: newAvailableTime});
            newAvailableFieldDays.push({tournament_field_id: this.state.fixture_current_tournament_field_id, daysArray: newAvailableDays});
        }else{
            newAvailableFieldTime[fieldIndex] = {tournament_field_id: this.state.fixture_current_tournament_field_id, timeArray: newAvailableTime}
            newAvailableFieldDays[fieldIndex] = {tournament_field_id: this.state.fixture_current_tournament_field_id, daysArray: newAvailableDays}
        }
        this.setState({availableFieldTime: newAvailableFieldTime, availableFieldDays: newAvailableFieldDays});
    }

    handleTournamentMatchAdminTournamentFields () {
        let list_tournament_fields = [];
        this.props.state.leagueTournaments?.tournament_fields.forEach( field => {
            let obj = {
                value: field.field.id,
                caption: field.field.name,
                checked: false,
            }
            list_tournament_fields.push(obj);
        });
        this.setState({ tournament_fields: list_tournament_fields });
    }

    handleTournamentMatchAdminTournamentStaff () {
        let list_tournament_staff = [];
        this.props.state.leagueTournaments?.tournament?.tournament_staff.forEach( staff => {
            let obj = {
                value: staff.profile,
                caption: `${staff.first_name} ${staff.last_name}`,
                photo: staff.photo,
                checked: false,
            }
            list_tournament_staff.push(obj);
        });
        this.setState({ tournament_staff: list_tournament_staff });
    }

    handleTournamentMatchAdminTournamentDates () {
        let list_tournament_dates = [];
        if(this.props.state.leagueTournaments.tournament?.tournament_dates?.length > 0){
            this.props.state.leagueTournaments.tournament?.tournament_dates?.forEach( date => {
                let dateArray = date.name.split(" ");
                let obj = {
                    value: date.id,
                    caption: this.props.t(`options.tournaments.pages.matchRefereesPayment.${dateArray[0]}`,{_number: dateArray[1]}),
                    checked: false,
                    current_date: date.current_date,
                    id: date.id,
                    name: date.name,
                    tournament_group: date.tournament_group,
                }
                list_tournament_dates.push(obj)
            });
        }
        this.setState({ tournament_dates: list_tournament_dates });
    }

    handleTournamentMatchAdminTournamentDatesFiltered () {
        let list_tournament_dates_filtered = [];
        this.props.state.leagueTournaments?.tournament_dates_filter.forEach( date => {
            let dateArray = date.name.split(" ");
            let obj = {
                value: date.id,
                caption: this.props.t(`options.tournaments.pages.matchRefereesPayment.${dateArray[0]}`,{_number: dateArray[1]}),
                checked: false,
                current_date: date.current_date,
                id: date.id,
                name: date.name,
                tournament_group: date.tournament_group,
            }
            list_tournament_dates_filtered.push(obj)
        });
        this.setState({ tournament_dates_filtered: list_tournament_dates_filtered });
    }

    handleTournamentMatchAdminPhaseTeams () {
        let tournament_phase_teams = [];
        this.props.state.leagueTournaments.tournament?.tournament_teams.forEach( team => {
            let obj = {
                value: team.tournament_team_id,
                caption: team.team,
                checked: false,
                img: team.team_logo
            }
            tournament_phase_teams.push(obj);
        });
        this.setState({ tournament_phase_teams: tournament_phase_teams });
    }

    handleEditFixtureMatchGame ( id_match, match ) {
        this.props.editFixtureMatch(id_match, match, this.props.state.leagueTournaments.tournament?.tournament_active_phase?.id );
    }

    handleCloseRoundtripFixtureSelector() {
        this.props.changeRoundtripFixtureModalStateAction( false ); 
        this.props.clearTournamentAvailableFixtureDatesAction(); 
        this.props.deleteFixture(this.props.state.leagueTournaments.tournament?.tournament_active_phase?.id, this.state.active_group?.id, "Fecha 1"); 
        this.props.resetTournamentGroup([]);
        this.props.cleanTournamentPhaseGroupDatesFilterAction();
    }
    async downloadMatchReport( match ){
        await this.props.loadMatchReport( match.id );
        this.props.loadTeamsToMatchAssistanceAligment( match.id );
        this.props.changeMatchReportPdfModalStateAction(true);
    }

    loadMoreTeamPlayers(next_page){
        this.props.loadTournamentTeamPlayers(null, null, next_page);
    }

    /* loadStatistics( tournament_id ){
        batch(() => {
            this.props.loadYellowCardsTable(tournament_id);
            this.props.loadRedCardsTable(tournament_id);
            this.props.loadBlueCardsTable(tournament_id);
            this.props.loadTournamentScorersTable(tournament_id);        
            this.props.loadAssistsTable(tournament_id);
            this.props.loadLeastDefeatedFenceTable(tournament_id);
            this.props.loadTeamsMostGoalsTable(tournament_id);
            this.props.loadFairPlayTable(tournament_id);
            this.props.loadSummaryTable(tournament_id);
        });
    } */

    loadStatistics( tournament_id ){
        this.loadCardsStatistics(tournament_id); 
        this.loadPlayerStatistics(tournament_id); 
        this.loadTeamsStatistics(tournament_id); 
    }

    loadCardsStatistics(tournament_id){
        this.props.loadYellowCardsTable(tournament_id);
        this.props.loadRedCardsTable(tournament_id);
        this.props.loadBlueCardsTable(tournament_id);
    }

    loadPlayerStatistics(tournament_id){
        this.props.loadTournamentScorersTable(tournament_id);        
        this.props.loadAssistsTable(tournament_id);
        this.props.loadLeastDefeatedFenceTable(tournament_id);
    }

    loadTeamsStatistics(tournament_id){
        this.props.loadTeamsMostGoalsTable(tournament_id);
        this.props.loadFairPlayTable(tournament_id);
        this.props.loadSummaryTable(tournament_id);
    }

    async handleViewMoreStatistics(tournament_id, type){
        this.setState({statistic_data_type: type});
        switch (type){
            case 'scorer':
                await this.props.loadTournamentScorersTable(tournament_id, 17);
                this.setState({modal_statistic_data: this.props.state.leagueTournaments.tournament_scorers, 
                    next_modal_statistic_data: this.props.state.leagueTournaments.next_tournament_scorers,
                    loading_modal_statistic_data: this.props.state.leagueTournaments.loading_tournament_scorers,
                });
                break;
            case 'yellow-card':
                await this.props.loadYellowCardsTable(tournament_id, 17);
                this.setState({modal_statistic_data: this.props.state.leagueTournaments.tournament_yellow_cards, 
                    next_modal_statistic_data: this.props.state.leagueTournaments.next_tournament_yellow_cards,
                    loading_modal_statistic_data: this.props.state.leagueTournaments.loading_tournament_yellow_cards,
                });
                break;
            case 'red-card':
                await this.props.loadRedCardsTable(tournament_id, 17);
                this.setState({modal_statistic_data: this.props.state.leagueTournaments.tournament_red_cards, 
                    next_modal_statistic_data: this.props.state.leagueTournaments.next_tournament_red_cards,
                    loading_modal_statistic_data: this.props.state.leagueTournaments.loading_tournament_red_cards,
                });
                break;
            case 'blue-card':
                await this.props.loadBlueCardsTable(tournament_id, 17);
                this.setState({modal_statistic_data: this.props.state.leagueTournaments.tournament_blue_cards, 
                    next_modal_statistic_data: this.props.state.leagueTournaments.next_tournament_blue_cards,
                    loading_modal_statistic_data: this.props.state.leagueTournaments.loading_tournament_blue_cards,
                });
                break;
            case 'assist':
                await this.props.loadAssistsTable(tournament_id, 17);
                this.setState({modal_statistic_data: this.props.state.leagueTournaments.tournament_assists, 
                    next_modal_statistic_data: this.props.state.leagueTournaments.next_tournament_assists,
                    loading_modal_statistic_data: this.props.state.leagueTournaments.loading_tournament_assists,
                });
                break;
            case 'ldf':
                await this.props.loadLeastDefeatedFenceTable(tournament_id, 17);
                this.setState({modal_statistic_data: this.props.state.leagueTournaments.tournament_least_defeated_fence, 
                    next_modal_statistic_data: this.props.state.leagueTournaments.next_tournament_least_defeated_fence,
                    loading_modal_statistic_data: this.props.state.leagueTournaments.loading_tournament_least_defeated_fence,
                });
                break;
            case 'tmg':
                await this.props.loadTeamsMostGoalsTable(tournament_id, 17);
                this.setState({modal_statistic_data: this.props.state.leagueTournaments.tournament_teams_most_goals, 
                    next_modal_statistic_data: this.props.state.leagueTournaments.next_tournament_teams_most_goals,
                    loading_modal_statistic_data: this.props.state.leagueTournaments.loading_tournament_teams_most_goals,
                });
                break;
            case 'fairplay':
                await this.props.loadFairPlayTable(tournament_id, 17);
                this.setState({modal_statistic_data: this.props.state.leagueTournaments.tournament_fair_play, 
                    next_modal_statistic_data: this.props.state.leagueTournaments.next_tournament_fair_play,
                    loading_modal_statistic_data: this.props.state.leagueTournaments.loading_tournament_fair_play,
                });
                break;
            //no default
        }
        this.props.changeTournamentViewMoreStatisticsModalStateAction(true);
    }
    
    async loadMoreStatistics(next_url){
        switch (this.state.statistic_data_type){
            case 'scorer':
                await this.props.loadTournamentScorersTable(null, null, next_url);
                this.setState({modal_statistic_data: this.props.state.leagueTournaments.tournament_scorers, 
                    next_modal_statistic_data: this.props.state.leagueTournaments.next_tournament_scorers,
                    loading_modal_statistic_data: this.props.state.leagueTournaments.loading_tournament_scorers,
                });
                break;
            case 'yellow-card':
                await this.props.loadYellowCardsTable(null, null, next_url);
                this.setState({modal_statistic_data: this.props.state.leagueTournaments.tournament_yellow_cards, 
                    next_modal_statistic_data: this.props.state.leagueTournaments.next_tournament_yellow_cards,
                    loading_modal_statistic_data: this.props.state.leagueTournaments.loading_tournament_yellow_cards,
                });
                break;
            case 'red-card':
                await this.props.loadRedCardsTable(null, null, next_url);
                this.setState({modal_statistic_data: this.props.state.leagueTournaments.tournament_red_cards, 
                    next_modal_statistic_data: this.props.state.leagueTournaments.next_tournament_red_cards,
                    loading_modal_statistic_data: this.props.state.leagueTournaments.loading_tournament_red_cards,
                });
                break;
            case 'blue-card':
                await this.props.loadBlueCardsTable(null, null, next_url);
                this.setState({modal_statistic_data: this.props.state.leagueTournaments.tournament_blue_cards, 
                    next_modal_statistic_data: this.props.state.leagueTournaments.next_tournament_blue_cards,
                    loading_modal_statistic_data: this.props.state.leagueTournaments.loading_tournament_blue_cards,
                });
                break;
            case 'assist':
                await this.props.loadAssistsTable(null, null, next_url);
                this.setState({modal_statistic_data: this.props.state.leagueTournaments.tournament_assists, 
                    next_modal_statistic_data: this.props.state.leagueTournaments.next_tournament_assists,
                    loading_modal_statistic_data: this.props.state.leagueTournaments.loading_tournament_assists,
                });
                break;
            case 'ldf':
                await this.props.loadLeastDefeatedFenceTable(null, null, next_url);
                this.setState({modal_statistic_data: this.props.state.leagueTournaments.tournament_least_defeated_fence, 
                    next_modal_statistic_data: this.props.state.leagueTournaments.next_tournament_least_defeated_fence,
                    loading_modal_statistic_data: this.props.state.leagueTournaments.loading_tournament_least_defeated_fence,
                });
                break;
            case 'tmg':
                await this.props.loadTeamsMostGoalsTable(null, null, next_url);
                this.setState({modal_statistic_data: this.props.state.leagueTournaments.tournament_teams_most_goals, 
                    next_modal_statistic_data: this.props.state.leagueTournaments.next_tournament_teams_most_goals,
                    loading_modal_statistic_data: this.props.state.leagueTournaments.loading_tournament_teams_most_goals,
                });
                break;
            case 'fairplay':
                await this.props.loadFairPlayTable(null, null, next_url);
                this.setState({modal_statistic_data: this.props.state.leagueTournaments.tournament_fair_play, 
                    next_modal_statistic_data: this.props.state.leagueTournaments.next_tournament_fair_play,
                    loading_modal_statistic_data: this.props.state.leagueTournaments.loading_tournament_fair_play,
                });
                break;
            //no default
        }
    }

    editMatchMainInfo(info_type, match){
        switch (info_type) {
            case 'field':
                this.setState({matchInfoType:'field', matchInfoTitle: this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelAddField'), matchInfoMatch: match })
                break;
            case 'referee':
                this.setState({matchInfoType:'referee', matchInfoTitle: this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelAddReferee'), matchInfoMatch: match })
                break;
            case 'date-time':
                this.setState({matchInfoType:'date-time', matchInfoTitle: this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelAddDateTime'), matchInfoMatch: match })
                break;        
            default:
                break;
        }
        this.props.changeTournamentEditMatchInfoModalStateAction(true);
    }

    handleCloseMatchMainInfo(){
        this.setState({matchInfoType:'', matchInfoTitle: '', matchInfoMatch: null });
        this.props.changeTournamentEditMatchInfoModalStateAction(false);
    }

    handleEditMatchInfo = (form_values) => {
        let match_city = this.props.state.leagueTournaments?.world_cities?.filter( (city) => city.name === this.state.matchInfoMatch?.match_city )
        let match_info = {
            "local_team": this.state.matchInfoMatch?.local_team,
            "visitant_team": this.state.matchInfoMatch?.visitant_team,
            "match_date": this.state.matchInfoMatch?.match_date ? this.state.matchInfoMatch?.match_date : null,
            "match_time": this.state.matchInfoMatch?.match_time ? this.state.matchInfoMatch?.match_time : null,
            "field": this.state.matchInfoMatch?.match_field ? this.state.matchInfoMatch?.match_field.name : null,
            "match_city": match_city[0] ? match_city[0]?.id : null, 
            "official_referee": this.state.matchInfoMatch?.official_referee ? this.state.matchInfoMatch?.official_referee : null,
            "second_assistant_referee": this.state.matchInfoMatch?.second_assistant_referee ? this.state.matchInfoMatch?.second_assistant_referee : null,
            "third_assistant_referee": this.state.matchInfoMatch?.third_assistant_referee ? this.state.matchInfoMatch?.third_assistant_referee : null,
            "fourth_official_referee": this.state.matchInfoMatch?.fourth_official_referee ? this.state.matchInfoMatch?.fourth_official_referee : null,
            "referee_assigner": this.state.matchInfoMatch?.referee_assigner ? this.state.matchInfoMatch?.referee_assigner : null,
            "date_match": this.state.matchInfoMatch?.date_id,
            "match_field": this.state.matchInfoMatch?.match_field ? this.state.matchInfoMatch?.match_field.id : null,
        }

        if(this.state.matchInfoType === 'date-time') { 
            const [date, time] = form_values.match_date.split('T');
            match_info.match_date = date;
            match_info.match_time = time;
            }
            if(this.state.matchInfoType === 'field') { 
            match_info.match_field = form_values.field;
            }
            if(this.state.matchInfoType === 'referee') { 
            match_info.official_referee = form_values.referee.profile
        }

        this.handleEditMatchGameTournament(this.state.matchInfoMatch.id, match_info);
        this.props.changeTournamentEditMatchInfoModalStateAction(false);
        this.setState({matchInfoType:'', matchInfoTitle: '', matchInfoMatch: null });
    }

    async handleCreateFairPlaySettings(){
        this.setState({fair_play_setting_state: 'new'});
        if(!this.props.state.leagueTournaments.default_fair_play_setting){
            await this.props.loadFairPlaySettings(this.pagename);
            this.props.changeCreateEditFairPlaySettingsModalStateAction(true);
        }else{
            this.props.changeCreateEditFairPlaySettingsModalStateAction(true);
        }
        
    }

    async handleEditFairPlaySettings(){
        this.setState({fair_play_setting_state: 'edit'});
        this.props.changeFairPlaySelectSettingModalStateAction(true);
    }

    selectEditSetting(setting){
        switch (this.state.fair_play_setting_state) {
            case 'edit':
                this.setState({base_edit_fair_play_setting: setting})
                this.props.changeFairPlaySelectSettingModalStateAction(false);
                this.props.changeCreateEditFairPlaySettingsModalStateAction(true);
                break;
            case 'change':
                this.props.setSelectedFairPlaySettingAction( setting );
                this.props.changeFairPlaySelectSettingModalStateAction(false);
                break; 
            default:
                break;
        }
    }

    createFairPlaySettings(settings ){
        this.props.createFairPlaySettings(this.pagename, settings);
        this.props.setSelectedFairPlaySettingAction( null );
    }

    handleChangeFairPlaySetting() {
        this.setState({fair_play_setting_state: 'change'});
        this.props.changeFairPlaySelectSettingModalStateAction(true);
    }

    async handleAddPenalty(){
        await this.props.loadTournamentPhaseTeams(this.props.state.leagueTournaments.selected_fair_play_phase.id, 'assigned');
        this.props.changeFairPlayAddExtraPenaltyModalStateAction(true);
    }

    async handleAddAssociateMatch(tournament_team_id, modal){
        this.props.loadPhaseMatchesByTeam( this.props.state.leagueTournaments.selected_fair_play_phase.id, tournament_team_id );
        if(modal){
            this.props.changeAddAssociateMatchModalStateAction(true); 
        }        
    }

    loadPuntuactionTabData( phase ){
        if(phase){
            this.props.setSelectedFairPlayPhaseAction(phase);
            this.props.loadPhaseFairPlaySetting( phase.phase_fair_play_id )
            this.props.loadPhaseSanctions(phase.id, 6, 'Sport');
            this.setState({filter_penalty_type: 'Sport' });
        }        
    }

    async handleEditPenalty( penalty ) {
        this.setState({penalty_to_edit: penalty} );
        await this.props.loadTournamentPhaseTeams(this.props.state.leagueTournaments.selected_fair_play_phase.id, 'assigned');
        this.props.changeFairPlayAddExtraPenaltyModalStateAction(true);
    }

    handleDeletePenalty( penalty ) {
        this.setState({penalty_to_delete: penalty} );
        this.props.changeConfirmDeleteSanctionsModalStateAction(true);
    }

    deletePenalty(){
        this.props.deletePhaseExtraPenalty( this.props.state.leagueTournaments.selected_fair_play_phase.id, this.state.penalty_to_delete.id );
    }

    handleFilterPenaltys( penalty_type ){
        this.setState({filter_penalty_type: penalty_type });
        this.props.loadPhaseSanctions( this.props.state.leagueTournaments.selected_fair_play_phase.id, 6, penalty_type);
    }

    handleOnReopenPhase( tournament_phase_id ){
        this.setState({reopen_phase: tournament_phase_id });
        this.props.changeConfirmReOpenPhaseModalStateAction(true);
    }

    render(){
        return(
            <React.Fragment>

                {/* Tournament Modals */}

                {/* Edit match info */}
                <TournamentMatchEditMainInfoModalComponent
                    isOpen={ this.props.state.leagueTournaments.modals?.edit_match_info_is_open } 
                    onClose={() => { this.handleCloseMatchMainInfo()}}
                    info_type={ this.state.matchInfoType }
                    title={ this.state.matchInfoTitle }
                    handleEditMatchInfo={ (values) => this.handleEditMatchInfo(values) }
                    tournament_fields={ this.state?.tournament_fields }
                    new_page_field={ this.props.state.leagueTournaments?.new_page_field }
                    handleFieldsAdminActions={ (action_type, field) => { this.handleFieldsAdminActions(action_type, field)} }
                    handleTournamentFieldActions={ (action_type, field) => this.handleTournamentFieldActions(action_type, field) }
                    staff={ this.props.state.leagueTournaments.tournament?.tournament_staff }
                    handleAddStaf={ (profile)=>{this.handleAddStaf(profile)} }
                />

                {/* Tournament New Match */}
                <TournamentNewMatchModal
                    onClick={() => {}} 
                    isOpen={ this.props.state.leagueTournaments.modals?.match_is_open } 
                    onClose={() => { this.handleClosedAddMatchTournament()}}
                    onResetError={(data) => {this.props.changeNewMatchError(data)}}
                    errors={this.props.state.leagueTournaments?.errors.new_match}                    
                    newMatch={(tournament_date_id, new_tournament) => { this.handleAddMatchTournament(tournament_date_id, new_tournament) } }    
                    // tournament_teams = { this.state.active_group?.teams }
                    tournament_teams = { this.state.switch_status_teams ? this.props.state.leagueTournaments?.tournament?.inter_groups_teams : this.state.active_group?.teams}
                    cities = { this.props.state.leagueTournaments?.world_cities }
                    handleSearchCityNewMatchModal = { (city) => this.handleSearchCityNewMatchModal(city) }
                    handleSearchbBaloaMember = { (member) => this.props.searchBaloaMember(member) }
                    baloaMembers = { this.props.state.leagueTournaments.baloa_members }
                    tournament_city = { this.state.selected_tournament.main_city }
                    handleChangesSelectedTeams={(value)=> this.handleChangesSelectedTeams(value)}

                    staff={this.props.state.leagueTournaments.tournament?.tournament_staff}
                    users={this.props.state.leagueTournaments.baloa_users}
                    handleCleanBaloaUsers={()=>{this.handleCleanBaloaMemberTeamData()}}
                    handleSearchBaloaUser={ (data)=>{ this.props.searchBaloaUser(data) }}
                    id_tournament = { this.state.selected_tournament.id }
                    tournament_referee_payment_config={this.state.selected_tournament?.tournament_referee_payment_config}
                    referee_payments={this.state.selected_tournament?.referee_payments}
                    currency_payment={this.state.selected_tournament?.currency_payment}
                    tournament_fields={ this.state.tournament_fields }
                    tournament_dates={this.props.state.leagueTournaments?.tournament.tournament_dates}
                    handleOnNewEditTournamentDate={ () => this.handleOnNewEditTournamentDate() }
                    new_page_field={ this.props.state.leagueTournaments?.new_page_field }
                    handleFieldsAdminActions={ (action_type, field) => { this.handleFieldsAdminActions(action_type, field)} }
                    handleTournamentFieldActions={ (action_type, field) => this.handleTournamentFieldActions(action_type, field) }
                    cleanNewPageField={()=>{ this.props.cleanNewPageFieldAction() }}
                    //handleAddStaf={(profile)=> { this.handleAddStaf(profile) }}
                />

                {/* Tournament end match modal */}
                <TournamentEndMatchModalComponent
                    onClick={() => {}} 
                    isOpen={ this.props.state.leagueTournaments.modals?.end_match_is_open } 
                    onClose={() => { this.props.changeEndMatchModalStateAction( false ) }}
                    errors_comment={this.props.state.leagueTournaments.errors.comment_match}
                    onResetError={(data) => {this.props.changeEndMatchError(data)}}
                    errors_scoreboard={this.props.state.leagueTournaments.errors.scoreboard_match}
                    onNewDataScoreboard={ ( score ) => { this.handleOnRegisterScore( score )} }
                    //scorers/cards Form props
                    local_score= { this.props.state.leagueMatches?.match_ended?.score.local }
                    local_team = { this.props.state.leagueMatches?.match?.local_team_data }
                    local_team_members={ this.props.state.leagueMatches?.match?.local_team_members }
                    visitor_score= { this.props.state.leagueMatches?.match_ended?.score.visitor }
                    visitor_team = { this.props.state.leagueMatches?.match?.visitor_team_data }
                    visitor_team_members = { this.props.state.leagueMatches?.match?.visitor_team_members }
                    onNewDataScorers = { ( scorers ) => { this.handleOnRegisterScorers( scorers )}  }
                    onNewDataChanges = { ( changes ) => { this.handleOnRegisterChanges( changes )}  }
                    onNewDataCards =  { ( cards ) => { this.handleOnRegisterCards( cards )}  }
                    //Comments
                    onNewDataComment =  { ( dataComments ) => { this.handleOnRegisterComments( dataComments )}  }
                    //handleCloseMatchModal={(value) => { this.handleCloseMatchModal(value) }}
                    handleCleanData={(clean) => {this.handleCleanData(clean)}}//TODO: eliminar en los hijos
                    clean_data={this.state.clean_data}
                    data_match_end = {this.state.data_match_end}
                    // data_match_end = {this.props.state.leagueMatches.match_ended}
                    can_edit_match = {this.state.can_edit_match}
                    data_match_end_step = {this.state.data_match_end_step}
                    state_match = { this.state.state_match}
                    handleOnMatchChanges = {() => this.handleOnMatchChanges()}
                    positions_members={this.props.state.teams.member_positions}
                    teams_match_assistance={this.props.state.leagueTournaments.tournament.teams_match_assistance}
                    updatePlayersAssistanceAligment={(playerArray)=> {
                        this.props.updatePlayersAssistanceAligment(this.props.state.leagueTournaments.tournament.teams_match_assistance?.match_id, playerArray)
                    }}
                    playerMatch={this.props.state.leagueMatches.match?.player_match}
                    cleanTeamsMatchAssistance={()=>this.props.resetTeamsToMatchAssistanceAligment()}
                />

                {/* Tournament end tournament modal */}
                <EndTournamentMatchModal
                    onClick={() => {}}
                    isOpen={ this.props.state.leagueTournaments.modals?.end_tournament_is_open }                        
                    onClose = {() => this.props.changeEndTournamentModalStateAction( false ) } 
                    errors = {this.props.state.leagueTournaments.errors.end_tournament}
                    onResetError={(data) => {this.props.changeEndTournamnetError(data)}}
                    phase = { this.props.state.leagueTournaments.tournament?.tournament_phases[this.props.state.leagueTournaments.tournament?.tournament_phases.length-1]}                    
                    finishTournament = { (id_tournament, tournament_data) => {this.handleEndTournament(id_tournament, tournament_data, this.props.state.page.page.name)}}
                    id_tournament = { this.state.selected_tournament.id }
                    //finishTournament = { (id_tournament, tournament_data) => {this.props.finishTournament(id_tournament, tournament_data)}}
                    classified_teams = { this.handleTeamsFinishTournament() }
                    tournament_teams = { this.props.state.leagueTournaments.tournament?.tournament_teams }
                    scorer = {this.handleScorerOfTournament()}
                    tournament_players = { this.props.state.leagueTournaments.tournament?.tournament_players }
                    handleOnFinishedTournament = { (id_tournament) => {this.handleOnFinishedTournament(id_tournament)}}  
                    handleSearchScorerTeamPlayers = { ( team_name ) =>this.props.loadScorerTeamPageMembers( team_name ) }
                    handleSearchBeatenFenceTeamPlayers = { ( team_name ) =>this.props.loadBeatenFenceTeamPageMembers( team_name ) }                    
                    scorerTeamPlayers = { this.props.state.leagueTournaments.tournament?.tournament_scorer_team_members }
                    beatenFenceTeamPlayers = { this.props.state.leagueTournaments.tournament?.tournament_beaten_fence_team_members }
                    handleSearchChampionTeamPlayers = { ( team_name ) =>this.props.loadChampionTeamPageMembers( team_name ) }
                    championTeamPlayers = { this.props.state.leagueTournaments.tournament?.tournament_champion_team_members }
                    handleSearchRunnerUpTeamPlayers={(team_name) => this.props.loadSearchRunnerUpTeamPlayers(team_name)}
                    handleSearchThirdPlaceTeamPlayers={(team_name)=> this.props.loadSearchThirdPlaceTeamPlayers(team_name)}
                    handleSearchtournamentTeamPlayers={(team_name)=> this.props.loadSearchTournamentPlayerTeamPlayers(team_name)}
                    handleSearchRevelationTeamPlayers={(team_name)=>this.props.loadSearchRevelationTeamPlayers(team_name)}
                    revelationPlayersList={this.props.state.leagueTournaments.tournament?.tournament_revelation_players_team_members}
                    tournamentPlayersList={this.props.state.leagueTournaments.tournament?.tournament_player_team_members}
                    thirdPlacesPlayersList={this.props.state.leagueTournaments.tournament?.tournament_third_placer_team_members}
                    runnerUpPlayersList={this.props.state.leagueTournaments.tournament?.tournament_runner_up_team_members}
                />

                {/* Tournament edit tournament modal */}                
                <TournamentEditMatchModal
                    onClick={() => {}}
                    isOpen = { this.props.state.leagueMatches.modals.edit_match_is_open }                                        
                    onClose={() => { this.handleOnCloseEditMatchModal() }}                                                          
                    //row = { this.state.selected_edit_row }
                    row = {this.props.state.leagueMatches?.match_report}
                    resetError={()=>{}}
                    errors={this.props.state.leagueTournaments?.errors.edit_match}
                    onResetError={(data) => {this.props.changeNewMatchError(data)}}
                    onEditMatch = { ()=>{} }
                    editMatch={(id_match, match) => { this.handleEditMatchGameTournament( id_match, match ) } }                    
                    cities = { this.props.state.leagueTournaments?.world_cities }
                    handleSearchCityNewMatchModal = { (city) => this.handleSearchCityNewMatchModal(city) }
                    handleSearchbBaloaMember = { (member) => this.props.searchBaloaMember(member) }
                    baloaMembers = { this.props.state.leagueTournaments.baloa_members }
                    official_referee = { this.props.state.leagueMatches.match_referees.official_referee }
                    second_assistant_referee = { this.props.state.leagueMatches.match_referees.second_assistant_referee }
                    third_assistant_referee = { this.props.state.leagueMatches.match_referees.third_assistant_referee }
                    fourth_assistant_referee = { this.props.state.leagueMatches.match_referees.fourth_official_referee }
                    referee_assigner={this.props.state.leagueMatches.match_referees.referee_assigner}
                    tournament_city = { this.state.selected_tournament.main_city }
                    staff={this.props.state.leagueTournaments.tournament?.tournament_staff}
                    users={this.props.state.leagueTournaments.baloa_users}
                    tournament_referee_payment_config={this.state.selected_tournament?.tournament_referee_payment_config}
                    referee_payments={this.state.selected_tournament?.referee_payments}
                    currency_payment={this.state.selected_tournament?.currency_payment}
                    tournament_teams={ this.state.active_group?.teams }
                    tournament_fields={ this.state.tournament_fields }
                    tournament_dates={ this.state.tournament_dates }
                    handleOnNewEditTournamentDate={ () => this.handleOnNewEditTournamentDate() }
                    new_page_field={ this.props.state.leagueTournaments?.new_page_field }
                    handleFieldsAdminActions={ (action_type, field) => { this.handleFieldsAdminActions(action_type, field)} }
                    handleTournamentFieldActions={ (action_type, field) => this.handleTournamentFieldActions(action_type, field) }
                    cleanNewPageField={()=>{ this.props.cleanNewPageFieldAction() }}
                >                
                </TournamentEditMatchModal>

                {/* Tournament matches to close modal */}
                <TournamnentMatchesToCloseModal
                    onClick={() => {}}
                    isOpen = { this.props.state.leagueTournaments.modals?.matchs_to_close_is_open }                                        
                    onClose={() => { this.props.changeMatchsToCloseModalStateAction( false ) }}
                    unfinished_matches={this.props.state.leagueTournaments.tournament?.unfinished_matches}
                />

                {/* Tournament disable team confirm dialog modal */}
                <ModalConfirmDialogComponent
                    onPrincipalButtonClick = { ( ) => { this.props.changeTeamsDisabledDialogModalStateAction( false ) } }
                    principalButtonLabel = { this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabTeams.modals.modalDisableTeam.principalButtonLabel') }
                    secondaryButton = { true }
                    onSecondaryButtonClick = { () => { this.handleOnAcceptDisableTeamFromTournament() } }
                    secondaryButtonLabel = { this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabTeams.modals.modalDisableTeam.secondaryButtonLabel') }
                    isOpen = { this.props.state.leagueTournaments.modals.teams_disabled_dialog_is_open } 
                    onClose={() => { this.props.changeTeamsDisabledDialogModalStateAction( false ) }}
                    modalTitle = { this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabTeams.modals.modalDisableTeam.modalTitle') }
                    modalContent = { this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabTeams.modals.modalDisableTeam.modalContent') }
   
                />

                {/* Tournament finish free phase modal */}
                <TournamentFinishFreePhaseModal
                    onClick={() => {}}
                    // isOpen = { this.props.state.leagueTournaments.modals?.finish_free_phase_is_open }                                        
                    onClose={() => { this.props.changeFinishFreePhaseModalState( false ) }}
                    // tournament_id={this.props.state.leagueTournaments?.tournament.id}
                    phase_id={this.state.phase?.id}
                    finishTournamentPhase ={( id_phase) => {this.props.endTournamentPhase( id_phase)}}
                    teams_classified_by_phases={this.props.state.leagueTournaments.tournament?.teams_classified_by_phases}
                />

                {/* Tournament finish groups phase modal */}
                <TournamentFinishGroupsPhaseModal
                    onClick={() => {}}
                    // isOpen = { this.props.state.leagueTournaments.modals?.finish_groups_phase_is_open }                                        
                    onClose={() => { this.props.changeFinishGroupsPhaseModalState( false ) }}
                    // tournament_id={this.props.state.leagueTournaments?.tournament.id}
                    phase_id={this.state.phase?.id}
                    finishTournamentPhase ={(id_phase) => {this.props.endTournamentPhase(id_phase)}}
                    teams_classified_by_phases={this.props.state.leagueTournaments.tournament?.teams_classified_by_phases}
                />

                {/* Tournament warning phase modal */}
                <TournamentWarningWhenLeavePhasesModal
                    onClick={() => {}}
                    isOpen = { this.props.state.leagueTournaments.modals?.warning_leving_phase_is_open }                                        
                    onClose={() => { this.props.changeWarningLeavingPhaseStateAction( false ) }}
                    unfinished_matches={this.props.state.leagueTournaments.tournament?.unfinished_matches}
                />

                {/* Tournament save tournament modal */}

                <TournamentSaveTournamentModalComponent
                    
                    onClick={()=>{}}
                    //isOpen={true}
                    
                    isOpen={ this.props.state.leagueTournaments.modals?.tournament_save_tournament_is_open }
                    onClose={() => { this.props.changeTournamentSaveTournamentModalStateAction( false ) }} 

                
                />
                <TournamentWarningNoTeamsLeagueModalComponent
                    onClick={() => {}}
                    isOpen = { this.props.state.leagueTournaments.modals?.warning_league_with_no_teams_is_open }                                        
                    onClose={() => { this.props.changeWarningLeagueWithNoTeamsModalStateAction( false ) }}  
                    pagename = { this.pagename }
                />

                {/* Add team to group modal */}
                <TournamentAddTeamToGroupModalComponent
                    onClick={() => {}}
                    isOpen = { this.props.state.leagueTournaments.modals?.add_team_to_group_is_open }                                        
                    onClose={() => { this.handleOnCloseTeamToGroupModal() }}                      
                    groups={ this.props.state.leagueTournaments?.tournament?.tournament_active_phase?.groups }
                    tournament_teams={this.props.state.leagueTournaments?.tournament?.tournament_active_phase_teams_modal}
                    tournament_active_phase={ this.props.state.leagueTournaments?.tournament?.tournament_active_phase }
                    handleAddTeamToGroup={ (groupId, teamName) => this.props.addTeamToGroup(groupId, teamName, this.props.state.leagueTournaments.tournament.tournament_active_phase.id, this.state.teams_filter_type, 'tournament_active_phase_teams_modal') }
                    handleDeleteTeamFromGroup={ (teamName,groupId) => this.handleModalStateForPhases(teamName, 'team_delete_validation', groupId)}
                    handleActivePhaseCreateState={ (new_state) => this.props.changeActivePhaseGroupsCreateStateAction(new_state) }
                    active_phase_groups_create={ this.props.state.leagueTournaments?.tournament?.active_phase_groups_create }
                    handleCreateAutomaticGroupPhases={(phaseId, filter_state)=> this.props.createAutomaticGroupPhases(phaseId, this.props.state.leagueTournaments.tournament?.id, filter_state, 'tournament_active_phase_teams_modal')}
                    tournament_criteria_tie_breaking = { this.props.state.leagueTournaments.tournament.tournament_criteria_phase }
                    handle_team_filter= {(phaseId, type_filter) => {
                        this.setState({teams_filter_type: type_filter});
                        this.props.loadTournamentPhaseFilterTeams( phaseId, type_filter, 'tournament_active_phase_teams_modal');
                    }}
                    handleDeleteGroupPhase={(groupId) => this.handleDeleteGroupPhase(groupId)}
                    handleOnEditPhaseModal={(edit_phase_type, group, isCreatePhase) => { this.handleOnEditPhaseModal(edit_phase_type, group, isCreatePhase) }}
                    handleNumberClassified={(id_group, classified_count)=>this.handleNumberClassified(id_group, classified_count)}
                    handleCleanAllGroups={(phaseId) => this.props.cleanAllGroupOfPhase(phaseId, this.props.state.leagueTournaments.tournament?.id, 'tournament_active_phase_teams_modal')}
                    click_source_create_phase='onClickNewPhase'
                    deleteTeamFromCreatePhase={(team, teamname, phase_id) => {this.props.deleteTeamFromGroup(team, teamname, phase_id, this.state.teams_filter_type, 'tournament_active_phase_teams_modal')}}
                    handleOnPhaseValidation={(phaseType) => this.handleOnEditPhaseAction(phaseType)}
                    handleOnAddAllTeamsToGroup={(group_id, state) => {this.props.addAllTeamsToGroup(group_id, state, this.props.state.leagueTournaments.tournament?.id, this.props.state.leagueTournaments.tournament.tournament_active_phase?.id)}}
                    teams_filter_type={this.state.teams_filter_type}
                />

                {/* delete phases modal */}
                {/* FIXME: Remover el modal de eliminar si afectar mas cambios */}
                <TournamentRemoveMultiplePhasesModalComponent
                    onClick={() => {}}
                    isOpen = { this.props.state.leagueTournaments.modals?.remove_multiple_phases_is_open }                                        
                    onClose={() => { this.props.changeRemoveMultiplePhasesModalStateAction( false ) }}
                    handleDeleteTournamentPhaseModal = {() => this.handleDeleteTournamentPhaseModal()}
                />

                {/* delete date modal */}
                <TournamentDeleteDateModalComponent
                    onClick={() => {}}
                    isOpen = { this.props.state.leagueTournaments.modals?.delete_date_is_open }                                        
                    onClose={() => { this.props.changeDeleteDateModalStateAction( false ) }}
                    deleteDate={(date_id,index,group) => {this.props.deleteDate(date_id,index,group)}}
                    date={this.state.date}
                    index={this.state.index_date}
                    group_id={this.state.group_date}
                    // selected_date={this.props.state.leagueTournaments?.selected_date}
                />

                {/* close match modal */}
                <TournamentCloseMatchModalComponent
                    onClick={() => {}}
                    isOpen = { this.props.state.leagueTournaments.modals?.close_match_is_open }                                        
                    onClose={() => { this.props.changeCloseMatchModalStateAction( false ) }}
                    onNewDataComment =  { ( comments ) => { this.handleOnRegisterComments( comments )}  }
                    comments={this.state.comments}
                    checked={this.state.end_match_reminder}
                    handleEndMatchReminder={(check) => {this.handleEndMatchReminder(check)}}
                    handleCleanData={(clean) => {this.handleCleanData(clean)}}
                />

                {/* tournament finalize tournament suggestion modal */}
                <TournamentFinalizeTournamentSuggestionModalComponent 
                    isOpen={ this.props.state.leagueTournaments.modals?.tournament_finalized_suggestion_is_open } 
                    onClose={() => this.handleOnCloseTournamentSuggestionModal()}
                />

                <PhaseRoundtripFixtureSelectorModalComponent
                    principalButtonLabel = { this.props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalRoundtripFixture.roundTripOption') }
                    secondaryButtonLabel = { this.props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalRoundtripFixture.oneWayOption') }
                    isOpen = { this.props.state.leagueTournaments.modals?.phase_roundtrip_fixture_modal } 
                    onClose={() => { this.props.changeRoundtripFixtureModalStateAction( false ); this.props.clearTournamentAvailableFixtureDatesAction(); }}
                    onCloseModal={() => { this.handleCloseRoundtripFixtureSelector() } }
                    modalTitle = { this.props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalRoundtripFixture.modalTitle') }
                    modalContent = { this.props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalRoundtripFixture.modalText') }
                    setAutomaticFixture={ (fixture_config_data) => this.setAutomaticFixture( fixture_config_data ) }
                    fixture={ this.props.state.leagueTournaments.tournament.active_phase_fixture_resume }
                    handleOnReorganize={ (phase_id) => this.handleOnReorganize(phase_id) }
                    loading_fixture_state={ this.state.loading_fixture_state }
                    phase_teams={ this.props.state.leagueTournaments?.tournament?.tournament_active_phase_teams }
                    phase_dates={ this.state.phase_dates }
                    phase_type={ this.props.state.leagueTournaments.tournament?.tournament_active_phase?.phase_type }
                    handleOnAddAvailableDates={ ()=>{ this.handleOnAddAvailableDates( ) }}
                    handleOnAddBlockDates={ ()=>{ this.props.changeFixtureMatchesBlockDatesModalStateAction( true ) }}
                    //fixture_available_days={ this.props.state.leagueTournaments.fixture_available_days }
                    fixture_available_days={ this.props.state.leagueTournaments?.full_fixture_config?.tournament_days }
                    handleOnDeleteFixtureAvailableDay={ (tournament_day)=>{ this.handleOnDeleteFixtureAvailableDay(tournament_day) }}
                    //fixture_locked_days={ this.props.state.leagueTournaments.fixture_locked_days }
                    fixture_locked_days={ this.props.state.leagueTournaments?.full_fixture_config?.non_playable_dates }
                    handleOnDeleteFixtureLockedDay = { (locked_day_id) => {this.props.deleteFixtureLockedDay(locked_day_id, this.props.state.leagueTournaments.tournament.id) } }

                    page_fields={ this.props.state.leagueTournaments?.page_fields }
                    new_page_field={ this.props.state.leagueTournaments?.new_page_field }
                    handleTournamentFieldActions={ (action_type, field) => this.handleTournamentFieldActions(action_type, field) }
                    tournament_available_fields={ this.props.state.leagueTournaments?.tournament_available_fields }
                    handleExistingFields={ () => this.handleExistingFields() }
                    handleFieldsAdminActions={ (action_type, field) => { 
                        this.setState({is_tournament_field: true}); 
                        this.handleFieldsAdminActions(action_type, field)} 
                    }
                    tournament_fields={this.props.state.leagueTournaments?.tournament_fields}
                    tournament_field_list={this.state.tournament_fields}

                    assign_days={ this.props.state.leagueTournaments?.full_fixture_config?.assign_days }
                    assign_fields={ this.props.state.leagueTournaments?.full_fixture_config?.assign_fields }
                    match_duration={ this.props.state.leagueTournaments?.full_fixture_config?.match_duration }
                    number_matches={ this.props.state.leagueTournaments?.full_fixture_config?.number_matches }
                    start_date={ this.props.state.leagueTournaments?.full_fixture_config?.start_date }
                    time_between_games={ this.props.state.leagueTournaments?.full_fixture_config?.time_between_games? this.props.state.leagueTournaments?.full_fixture_config?.time_between_games.split(':') : null  }

                    handleOnAddTeamField={( tournament_field_id )=>{ this.handleOnAddTeamField( tournament_field_id ) }}
                    fixture_tournament_fields={this.props.state.leagueTournaments?.full_fixture_config?.tournament_fields}
                    handleOnDeleteFieldTeam={ ( tournament_field_team_id )=> { this.props.deleteFixtureFieldTeam( tournament_field_team_id, this.props.state.leagueTournaments.tournament.id ) } }

                    //fieldsFixtureAvailableDays={ this.props.state.leagueTournaments.fields_fixture_available_days }
                    handleOnDeleteFieldFixtureAvailableDay={ (tournament_field_day, tournament_field_id)=>{ this.handleOnDeleteFixtureFieldAvailableDay(tournament_field_day, tournament_field_id) } }
                    handleOnFieldAddAvailableDates={ ( tournament_field_id )=>{ this.handleOnFieldAddAvailableDates(tournament_field_id) } }
                    //fields_fixture_locked_days={ this.props.state.leagueTournaments.fields_fixture_locked_days }
                    handleOnDeleteFieldFixtureLockedDay={ (field_locked_day_id) => {this.props.deleteFixtureFieldLockedDay(field_locked_day_id, this.props.state.leagueTournaments.tournament.id ) } }
                    handleOnFieldAddBlockDates={ (tournament_field_id)=>{ this.props.changeFixtureFieldsMatchesBlockDatesModalStateAction( true ); this.setState({fixture_current_tournament_field_id: tournament_field_id}); } }

                    handleSubmitEditInfoMatch={ (match_id, form_data) => this.handleEditFixtureMatchGame( match_id, form_data ) }
                    
                />

                <AddPhaseTeamWarningModalComponent
                    onPrincipalButtonClick = { ( ) => { this.addPhaseTeam2() } }
                    principalButtonLabel = { this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabTeams.modals.modalAddPhaseTeamWarning.addTeamOption') }
                    onSecondaryButtonClick = { () => { this.props.changeAddTeamAdviceModalStateAction( false )  } }
                    secondaryButtonLabel = { this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabTeams.modals.modalAddPhaseTeamWarning.backOption') }
                    isOpen = { this.props.state.leagueTournaments.modals?.add_team_advice } 
                    onClose={() => { this.props.changeAddTeamAdviceModalStateAction( false ) }}
                    modalTitle = { this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabTeams.modals.modalAddPhaseTeamWarning.modalTitle') }
                    modalContent = { this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabTeams.modals.modalAddPhaseTeamWarning.modalText') }
                />

                {/* Team Detail Modal */}
                <PageTeamDetailModalComponent
                    isOpen={this.props.state.leagueTournaments.modals?.team_detail_is_open}
                    onClose={() => { this.props.changeTeamDetailModalStateAction(false) }}
                    team= { this.props.state.teams.team }
                    tournaments = { this.props.state.teams.team.tournaments }
                    followProfile={(profile) => { this.props.followProfile(profile) }}
                    unfollowProfile={(profile) => { this.props.unfollowProfile(profile) }}
                />

                {/* Team Start Tournament Modal */}
                <TournamentStartTournamentModalComponent
                    isOpen={this.props.state.leagueTournaments.modals?.start_tournament_modal}
                    onClose={() => { this.props.changeStartTournamentModalStateAction(false) }}
                    handleOnConfirmStartTournament={()=>{this.handleOnConfirmStartTournament()}}
                />

                {/** Invite teams modal */}
                <TournamentModalInvitedTeamComponent
                    isOpen={this.props.state.leagueTournaments.modals?.invite_team_to_tournament_modal}
                    onClose={() => this.props.changeInviteTeamToTournamentModalStateAction(false)}
                    tournament={this.props.state.leagueTournaments.tournament}
                    tournament_teams={ this.props.state.leagueTournaments?.tournament?.tournament_teams }
                    handleOnAddTeamToTournament={ ( value ) => { this.handleOnAddTeamToTournament( value )} }
                    handleOnDeleteTeamFromTournament={ ( value ) => { this.handleOnDeleteTeamFromTournament( value )} }
                    sendInvitationToTeamTournament={(value)=>this.handleOnAddInviteTeamToTournament( value )}
                    handleSearchBaloaTeam = { (searched_team) => { this.props.searchBaloaTeamToInvited(searched_team) }}
                    baloaTeams = { this.props.state.leagueTournaments.baloa_teams }
                    handleCleanBaloaTeamsSearch = { ( )=> { this.handleCleanBaloaTeamsSearch()}}
                    pageRoles = { this.props.state.page.page.roles }
                    modal_type_invited={this.state.modal_type_invited_inscription}
                />

                { /** Team inscription modal */}
                {/* <TournamentTeamInscriptionModalComponent 
                    isOpen={ this.props.state.leagueTournaments.modals?.team_inscription_modal }
                    onClose={ () => this.props.changeTeamInscriptionModalStateAction(false) }
                    team={ this.state.selected_team }
                    state={ this.state.team_state}
                    tournament={ this.props.state.leagueTournaments.tournament }
                    handleInscriptionInvitationModalActions={ (team, team_state) => this.handleInscriptionInvitationModalActions(team, team_state) }
                    edit_tournament={this.state.edit_tournament}
                    loadInfoFormModal={(form_id)=>{this.loadInfoFormModal(form_id)}}
                    handleTeamPlayerPaymentInfo={ (payment_info, currency) => this.handleTeamPlayerPaymentInfo(payment_info, currency) }
                /> */} 

                { /** Tournament invitations inscriptions actions modal */}
                <TournamentInvitationInscriptionActionsModalComponent 
                    isOpen={ this.props.state.leagueTournaments.modals?.invitation_inscription_actions_modal }
                    onClose={ () => {this.handleOnCloseInvitationInscriptionActionsModal()} }
                    mainLabel={this.state.invitation_inscription_modal_actions_mainLabel}
                    secondaryLabel={this.state.invitation_inscription_modal_actions_secondaryLabel}
                    acceptBtn={this.state.invitation_inscription_modal_actions_acceptBtn}
                    cancelBtn={this.state.invitation_inscription_modal_actions_cancelBtn}
                    handleOnInvitationInscriptionModalActions={ () => this.handleOnInvitationInscriptionModalActions() }
                    accept_team_inscription_btn={ this.state.accept_team_inscription_btn }
                />

                { /** View form data modal */}
                <TournamentInscriptionViewFormDataModalComponent 
                    isOpen={ this.props.state.leagueTournaments.modals?.view_form_data_modal }
                    onClose={ () => this.props.changeInscriptionViewFormDataModalStateAction(false) }
                    formData={ this.props.state.leagueTournaments.selected_form_data }
                />

                { /** Tournament team/player payment information modal */ }
                <TournamentPaymentInfoModalComponent
                    isOpen={ this.props.state.leagueTournaments.modals?.payment_information_modal }
                    onClose={ () => this.handleOnClosePaymentInfoModal() }
                    team_player_payment_info={ this.state.team_player_payment_info }
                    team_player_payment_currency={ this.state.team_player_payment_currency }        
                />

                { /** Tournament dynamic team / player forms */ }
                <TournamentDynamicFormsModalComponent
                    isOpen={ this.props.state.leagueTournaments.modals?.tournament_dynamic_forms_modal }
                    onClose={ () => this.handleCloseInscriptionViewFormDataModal() }
                    questions={this.state.preview_dynamic_form}
                />

                <TournamentStartInfoModalComponent
                    isOpen={ this.props.state.leagueTournaments.modals?.tournament_start_info_modal } 
                    onClose={() => { this.props.changeTournamentStartInfoModalStateAction( false ) }}
                    modalTitle={ <ErrorIcon
                        style={{
                            fontSize: 38,
                            color: "var(--primary-dark-color)",
                            marginRight: "var(--space)",
                            alignSelf: "center",
                        }}
                    />}
                    modalText={ this.props.t('options.tournaments.startTournament.infoModal.infoText1',{__min_teams: this.props.state.leagueTournaments.tournament.min_participant_teams, __enrolled_teams: this.props.state.leagueTournaments.tournament.tournament_teams?.filter(team => team.team_state == "Enrolled").length})}  
                    optiontButtonText = { this.props.t('options.tournaments.startTournament.infoModal.settingButtonLbl') }                  
                    acceptButtonText = { this.props.t('options.tournaments.startTournament.infoModal.okButtonLbl') }
                    onOptionButtonAction = { () => { this.handleOnEditTournamentInfo(); this.props.changeTournamentStartInfoModalStateAction( false ); } }
                />

                <TournamentTransferPlayerModalComponent 
                    isOpen={ this.props.state.leagueTournaments.modals?.tournament_transfer_player_modal } 
                    onClose={() => { this.props.changeTournamentTranferPlayerModalStateAction( false ) }}
                    team={ this.state?.selected_team }
                    player={ this.state?.selected_player } 
                    tournament_teams={ this.state.teams_transfer_list }  
                    handleOnTransferPlayer={(tournament_player_id, teamname, team_id)=>{this.handleOnTransferPlayer(tournament_player_id, teamname, team_id)}} 
                />

                <ShareInscriptionLinkModalComponent
                    isOpen= {this.props.state.tournaments.modals.share_incription_link_modal_is_open}
                    onClose={() => {this.props.changeShareInscriptionLinkModalStateAction(false)}}
                    share_url={this.state.share_inscription_url}
                    handleOnCopyShareLink={()=>{this.handleOnShareInscriptionTournamentLinkClick()}}
                    type_inscription_link_modal={this.state.type_inscription_link_modal}
                />

                <ShareIframeLinkModalComponent
                    isOpen= {this.props.state.tournaments.modals.share_iframe_link_modal_is_open}
                    onClose={() => {this.props.changeShareIframeLinkStateAction(false);}}
                    share_url={this.state.share_iframe_url}
                    handleOnCopyShareLink={()=>{this.handleOnShareIFramePositionTableLink()}}
                />

                <TournamentAddStaffModalComponent
                    isOpen= {this.props.state.leagueTournaments.modals.add_staff_modal}
                    onClose={() => { this.handleCloseAddStaffModal()}}
                    title={this.state.add_staff_modal_title}
                    share_url={this.state.share_add_staff_url}
                    handleSearchBaloaUser={ (data)=>{ this.handlesearchMemberTeamData(data) } }
                    handleAddStaff={ (item)=>{this.addStaff(item) } }
                    handleOnCopyShareLink={()=>{this.handleOnShareAddStaffLink()}}
                    users={this.state.set_type === 'member'? this.props.state.leagueTournaments.baloa_users : this.props.state.leagueTournaments.baloa_teams}
                    modal_subtitle={this.state.add_staff_modal_subtitle}
                    modal_share_title={this.state.add_staff_modal_share_title}
                    modal_share_note={this.state.add_staff_modal_share_note}
                    modal_search_placeholder={this.state.add_staff_modal_search_placeholder}
                    modal_button_label={this.state.add_staff_modal_button_label}
                    modal_icon_share_desc={this.state.add_staff_modal_icon_share_desc}
                    handleCleanBaloaUsers={()=>{this.handleCleanBaloaMemberTeamData()}}
                    set_type={this.state.set_type}
                    tournament_id={this.state.selected_tournament.id}
                />

                <TournamentEditPhaseModalComponent 
                    isOpen={this.props.state.leagueTournaments.modals.edit_phase_modal}
                    onClose={() => {this.handleOncloseEditPhase()} }
                    edit_phase_type={this.state.edit_phase_type}
                    edit_phase_selected_group={this.state.edit_phase_selected_group}
                    handleDeleteTeamFromGroup={(team) => {this.handleModalStateForPhases(team, 'team_delete_validation')}}
                    groups={ this.props.state.leagueTournaments.tournament.tournament_active_phase?.groups }
                    teams={ this.props.state.leagueTournaments?.tournament?.tournament_active_phase_teams }
                    tournament_active_phase={ this.props.state.leagueTournaments.tournament.tournament_active_phase }
                    handleAddTeamToGroup={ (groupId, teamName) => {this.props.addTeamToGroup(groupId, teamName, this.props.state.leagueTournaments.tournament.tournament_active_phase.id, this.state.teams_filter_type)} }
                    handleActivePhaseCreateState={ (new_state) => this.props.changeActivePhaseGroupsCreateStateAction(new_state) }
                    active_phase_groups_create={ this.props.state.leagueTournaments.tournament.active_phase_groups_create }                    
                    handleCreateNewAtomaticGroups={ () => { this.props.createAutomaticGroupPhases(this.props.state.leagueTournaments.tournament.tournament_active_phase?.id, this.props.state.leagueTournaments.tournament?.id)  }}
                    handleCleanAllGroups={(phaseId) => this.props.cleanAllGroupOfPhase(phaseId, this.props.state.leagueTournaments.tournament?.id)}
                    filter_phase_teams={this.props.state.leagueTournaments?.tournament?.tournament_filter_phase_teams}
                    handleBestThirdByGroup={(status, groupId) => this.handleBestThirdByGroup(status, groupId)}
                    handleTeamGroupPhaseknockout={(dataKnockout)=> this.handleModalStateForPhases(dataKnockout, 'phases_delete_Knockout')}
                    handle_team_filter={(phaseId, type_filter) => this.handleTeamsFilterinPhases(phaseId, type_filter)}
                    handleOnEditPhaseAction={(phaseType) => {this.handleOnEditPhaseAction(phaseType)}}
                    handleDeleteGroupPhase={(group_id) => {this.handleDeleteGroupPhase(group_id)}}
                    select_groups_teams={true}
                    handleNumberClassified={(id_group, classified_count) => this.handleNumberClassified(id_group, classified_count)}
                    handleOnAddAllTeamsToGroup={(group_id, state) => {this.props.addAllTeamsToGroup(group_id, state, this.props.state.leagueTournaments.tournament?.id, this.props.state.leagueTournaments.tournament.tournament_active_phase?.id)}}
                    teams_filter_type={this.state.teams_filter_type}
                />

                <TournamentEditPhaseValidationModalComponent 
                    isOpen= {this.props.state.leagueTournaments.modals.edit_phase_validation_modal}
                    onClose={ () => {
                                this.setState({modal_validation_message: ''});  
                                this.setState({state_phase_actions: false});
                                this.props.changeEditPhaseValidationModalStateAction(false);
                            }
                        }
                    message={this.state.modal_validation_message} 
                    state_phase_actions={this.state.state_phase_actions} 
                    phase_action={this.state.phase_action}  
                    handleOnPhaseActions={ (phase_action) => {this.handleOnPhaseActions(phase_action)} }
                />

                {/* Reject multiple players modal */}
                <TournamentRejectMultiplePlayersModalComponent
                    isOpen = { this.props.state.leagueTournaments.modals?.reject_multiple_players_modal }                                        
                    onClose={() => { this.props.changeRejectMultiplePlayerModalStateAction( false ) }} 
                    handleAcceptRejectMultiplePlayers = { () => { this.handleAcceptRejectMultiplePlayers() } } 
                />

                {/** Valida guardar carnet sin todos los campos usados */}
                <TournamentCardIdValidationModalComponent
                    isOpen = { this.props.state.leagueTournaments.modals?.card_id_validation_modal }                                        
                    onClose={() => { this.props.changeCardIdValidationModalStateAction( false ) }} 
                    handleSaveCardIdFormInfo = { () => { this.props.saveCardIdFormInfoAndClean(this.state.cardIdInfoData); this.props.changeCardIdValidationModalStateAction( false ); } } 
                />

                {/** Verificación antes de eliminar el carnet */}
                <TournamentCardIdDeleteValidationModalComponent
                    isOpen = { this.props.state.leagueTournaments.modals?.card_id_delete_validation_modal }                                        
                    onClose={() => { this.props.changeCardIdDeleteValidationModalStateAction( false ) }} 
                    handleDeleteCardIdTemplate = { () => { this.props.deleteCardIdTemplate(this.props.state.leagueTournaments.tournament.carnet_id_template.id); this.props.changeCardIdDeleteValidationModalStateAction( false ); } } 
                />

                {/** Permite seleccionar los equipos a los que se desea descargar el carnet */}
                <TournamentCardIdTeamsSelectionModalComponent
                    isOpen = {  this.props.state.leagueTournaments.modals?.card_id_teams_select_modal }                                        
                    onClose={() => { this.props.changeCardIdTeamsSelectModalStateAction(false) }} 
                    title={this.props.t('options.tournaments.cardIds.selectCardIdsTeamsModal.title')}
                    tournament_teams={this.props.state.leagueTournaments?.tournament?.tournament_teams}
                    loadCardIdsTeamInfo={ ( teams_list )=> { this.loadCardIdsTeamInfo( teams_list ) } }
                />

                {/** Permite visualizar y descargar el documento pdf con el/los carnets solicitados */}
                <TournamentCardIdPdfGeneratorModalComponent
                    isOpen = {  this.props.state.leagueTournaments.modals?.card_id_pdf_generator_modal }                                        
                    onClose={() => { this.props.changeCardIdPdfGeneratorModalStateAction(false); this.setState({downloadCardIdData: null}); }}                                         
                    downloadCardIdData={ this.state.downloadCardIdData }
                    card_id_form_data={ this.props.state.leagueTournaments?.card_ids_data?.form_card }
                    orientation={this.props.state.leagueTournaments?.card_ids_data?.form_card?.questions.find(q => q.form_question_template.caption === 'Orientacion')?.text_answer}
                    template_image={this.props.state.leagueTournaments?.card_ids_data?.form_card?.questions.find(q => q.form_question_template.caption === 'Plantilla')}
                />

                {/** Valida salir del editor de carnet sin guardar cambios */}
                <TournamentCardIdExitValidationModalComponent
                    isOpen = { this.props.state.leagueTournaments.modals?.card_id_exit_validation_modal }                                        
                    onClose={() => { this.props.changeCardIdExitValidationModalStateAction( false ); window.history.pushState(null, null, window.location.pathname); }} 
                    handleDeleteCardIdTemplate = { () => { this.props.deleteCardIdTemplate(this.props.state.leagueTournaments.tournament.carnet_id_template.id); this.props.changeCardIdExitValidationModalStateAction( false ); this.props.changeCardIdComponentStateAction(false) } } 
                />

                {/** Administración de canchas en una página */}
                <TournamentPageFieldsAdminModalComponent 
                    isOpen={ this.props.state.leagueTournaments.modals?.page_fields_admin_modal }                                        
                    onClose={ () => this.props.changeFieldsAdminModalStateAction(false) }
                    handleFieldsAdminActions={ (action_type, field) => this.handleFieldsAdminActions(action_type, field) }
                    page_fields={ this.props.state.leagueTournaments?.page_fields }
                />

                {/** Creación/Edición de canchas en una página/torneo */}
                <TournamentFieldsFormModalComponent 
                    isOpen={ this.props.state.leagueTournaments.modals?.page_fields_create_modal }                                        
                    onClose={ () => this.props.changeFieldsCreateModalStateAction(false) }
                    errors={ this.props.state.leagueTournaments.errors.create_field }
                    onResetError={ (data) => {this.props.changeTournamentBasicDataError(data)} }
                    handlePageFieldsActions={ (form_data) => this.handlePageFieldsActions(form_data) }
                    field_action_type={ this.state.field_action_type }
                    field_to_edit={ this.state.page_field }
                />

                {/** Modal advertencia eliminar canchas en una página */}
                <TournamentFieldsWarningModalComponent 
                    isOpen={ this.props.state.leagueTournaments.modals?.page_fields_warning_modal }                                        
                    onClose={ () => this.props.changeFieldsWarningModalStateAction( false ) }
                    mainLabel={ this.props.t('options.pageFields.labelWarningMain') }
                    secondaryLabel={ this.props.t('options.pageFields.labelWarningSecondary') }
                    handleDeleteField={ () => this.handleDeleteField() }
                />

                {/** Modal advertencia eliminar canchas de un torneo */}
                <TournamentFieldsWarningModalComponent 
                    isOpen={ this.props.state.leagueTournaments.modals?.tournament_fields_warning_modal }                                        
                    onClose={ () => this.props.changeTournamentFieldsWarningModalStateAction( false ) }
                    mainLabel={ this.props.t('options.tournaments.automaticFixturesModal.deleteTournamentFieldWarningTitle') }
                    secondaryLabel={ this.props.t('options.tournaments.automaticFixturesModal.deleteTournamentFieldWarningMsg') }
                    handleDeleteField={ () => this.handleDeleteTournamentField() }
                />

                {/** Administración de canchas en un torneo */}
                <TournamentFieldsAdminModalComponent 
                    isOpen={ this.props.state.leagueTournaments.modals?.tournament_fields_admin_modal }                                        
                    onClose={ () => this.props.changeTournamentFieldsAdminModalStateAction( false ) }
                    tournament_fields={ this.props.state.leagueTournaments?.tournament_fields }
                    handleFieldsAdminActions={ (action_type, field) => { 
                        this.setState({is_tournament_field: true}); 
                        this.handleFieldsAdminActions(action_type, field)} 
                    }
                    page_fields={ this.props.state.leagueTournaments?.page_fields }
                    new_page_field={ this.props.state.leagueTournaments?.new_page_field }
                    handleTournamentFieldActions={ (action_type, field) => this.handleTournamentFieldActions(action_type, field) }
                    tournament_available_fields={ this.props.state.leagueTournaments?.tournament_available_fields }
                    handleExistingFields={ () => this.handleExistingFields() }
                />

                {/** Agrega canchas disponibles para un torneo */}
                <TournamentAddExistingFieldsModalComponent 
                    isOpen={ this.props.state.leagueTournaments.modals?.tournament_add_existing_fields }                                        
                    onClose={ () => this.props.changeTournamentAddExistingFieldsStateAction( false ) }
                    tournament_available_fields={ this.props.state.leagueTournaments?.tournament_available_fields }
                    handleTournamentFieldActions={ (action_type, fields) => this.handleTournamentFieldActions(action_type, fields) }
                />
                
                {/** Modal para validar la eliminacion de los partidos que ya se encuentran creados en la fase */}
                <TournamentFixtureAcceptModalComponent
                    isOpen={ this.props.state.leagueTournaments.modals?.accept_automatic_fixture }                                        
                    onClose={ () => { this.props.changeAcceptAutomaticFixtureModalStateAction( false )}  }
                    handleOnAccept={ () => { this.handleOnAcceptAutomaticFixture() } }
                    handleOnCancel={ () => { this.props.changeAcceptAutomaticFixtureModalStateAction( false ) } }
                />
                
                {/** Fixture - modal para agregar dias disponibles aun torneo */}
                <TournamentFixtureMatchesAvailableDatesModalComponent 
                    isOpen={ this.props.state.leagueTournaments.modals?.fixture_matches_available_dates }                                        
                    onClose={ () => this.props.changeFixtureMatchesAvailableDatesModalStateAction( false ) }
                    handleOnSaveFixtureAvailableDays={ (tournament_days) => { this.saveFixtureAvailableDays(tournament_days) }}
                    setAvailableTime={(timeArray)=>{ this.setState({availableTime: timeArray})}}
                    setAvailableDays={(daysArray)=>{ this.setState({availableDays: daysArray})}}
                    availableTime={this.state.availableTime}
                    availableDays={this.state.availableDays}
                />

                {/** Fixture - modal para agregar dias bloquedaos a un torneo */}
                <TournamentFixtureMatchesLockedDatesModalComponent 
                    isOpen={ this.props.state.leagueTournaments.modals?.fixture_matches_block_dates }
                    onClose={ () => this.props.changeFixtureMatchesBlockDatesModalStateAction( false ) }
                    handleOnSaveFixtureLockedDay={ (tournament_locked_date) => { this.saveFixtureLockedDays(tournament_locked_date) }}
                />

                {/** Fixture - modal para agregar equipos a una cancha */}
                <TournamentFixtureAddTeamToFieldModalComponent
                    isOpen={ this.props.state.leagueTournaments.modals?.fixture_add_team_to_field }
                    onClose={ () => this.props.changeFixtureAddTeamToFieldModalStateAction( false ) }
                    fixture_field_teams={this.props.state.leagueTournaments?.fixture_field_teams}
                    handleOnAddFieldTeams={ ( field_teams )=>{ this.handleOnAddFixtureFieldTeams(field_teams) }}
                />

                {/** Fixture - modal para agregar dias disponibles a una cancha dentro de un torneo */}
                <TournamentFixtureMatchesAvailableDatesModalComponent 
                    isOpen={ this.props.state.leagueTournaments.modals?.fixture_fields_matches_available_dates }                                        
                    onClose={ () => this.props.changeFixtureFieldsMatchesAvailableDatesModalStateAction( false ) }
                    handleOnSaveFixtureAvailableDays={ (tournament_days) => { this.saveFixtureFieldAvailableDays(tournament_days) }}
                    setAvailableTime={(timeArray)=>{ this.setAvailableFieldTime(timeArray);}}
                    setAvailableDays={(daysArray)=>{ this.setAvailableFieldDays(daysArray);}}
                    availableTime={this.state.availableFieldTime.find(ft => ft.tournament_field_id === this.state.fixture_current_tournament_field_id)? this.state.availableFieldTime.find(ft => ft.tournament_field_id === this.state.fixture_current_tournament_field_id).timeArray : []}
                    availableDays={this.state.availableFieldDays.find(fd => fd.tournament_field_id === this.state.fixture_current_tournament_field_id)? this.state.availableFieldDays.find(fd => fd.tournament_field_id === this.state.fixture_current_tournament_field_id).daysArray : []}
                />

                {/** Fixture - modal para agregar dias bloquedaos a una cancha dentro de un torneo */}
                <TournamentFixtureMatchesLockedDatesModalComponent 
                    isOpen={ this.props.state.leagueTournaments.modals?.fixture_fields_matches_block_dates }
                    onClose={ () => this.props.changeFixtureFieldsMatchesBlockDatesModalStateAction( false ) }
                    handleOnSaveFixtureLockedDay={ (tournament_field_id, tournament_field_locked_date) => { this.saveFixtureFieldsLockedDays( tournament_field_id, tournament_field_locked_date ) }}
                />

                <TournamentMatchReportPdfModalComponent
                    isOpen = {  this.props.state.leagueTournaments.modals?.match_report_pdf }                                        
                    onClose={() => { this.props.changeMatchReportPdfModalStateAction(false) }}                                         
                    matchData={ this.props.state.leagueMatches.match_report }
                    matchAlignments={this.props.state.leagueTournaments.tournament.teams_match_assistance}
                />

                {/** View more statistics modal */}
                <TournamentViewMoreStatisticsModalComponent
                    isOpen={ this.props.state.leagueTournaments.modals?.view_more_statistics_is_open } 
                    onClose={() => { this.props.changeTournamentViewMoreStatisticsModalStateAction( false ) }}
                    statistic_data={ this.state.modal_statistic_data }
                    card_type={this.state.statistic_data_type}
                    loadMoreStatistics={ (next_url)=>{ this.loadMoreStatistics(next_url)}}
                    statistics_next_page={this.state.next_modal_statistic_data}
                />

                {/** Fair play add extra penalty modal */}
                <TournamentFairPlayAddExtraPenaltyModalComponent
                    isOpen={ this.props.state.leagueTournaments.modals?.fair_play_add_extra_penalty }
                    onClose={ ()=>{this.props.changeFairPlayAddExtraPenaltyModalStateAction(false); this.setState({sanction_associate_match: null, penalty_to_edit: null })} }
                    tournament_active_phase_teams={this.props.state.leagueTournaments?.tournament?.tournament_active_phase_teams}
                    loadTeamPlayers={ (tournament_team_id)=>{ this.props.loadAllTeamPlayers(tournament_team_id, 'Enrolled|Late_Register')} }
                    team_members={ this.props.state.leagueTournaments?.tournament?.tournament_team_players }
                    reset_players={ ()=>{ this.props.resetTournmentTeamPlayersAction(); this.setState({sanction_associate_match: null }) }}
                    handleOnAddAssocMatch={ (tournament_team_id, modal)=>{ this.handleAddAssociateMatch(tournament_team_id, modal) } }
                    sanction_associate_match={ this.state.sanction_associate_match }
                    handleOnAdd={ (sanction)=>{ this.props.createPhaseExtraPenalty( this.props.state.leagueTournaments?.selected_fair_play_phase?.id, sanction, 'Extra_Sport' ); this.setState({filter_penalty_type: 'Extra_Sport'}) } }
                    penalty_to_edit={ this.state.penalty_to_edit }
                    phase_matches_by_team={ this.props.state.leagueTournaments.phase_matches_by_team }
                    setMatch={(match)=>{ this.setState({sanction_associate_match: match})}}
                    handleOnEdit={ (sanction_id, sanction )=>{ this.props.updatePhaseExtraPenalty( this.props.state.leagueTournaments?.selected_fair_play_phase?.id, sanction_id, sanction ) } }
                />

                {/** Create/edit fair play settings modal */}
                <TournamentPhasesCreateEditFairPlaySettingsModalComponent
                    isOpen={ this.props.state.leagueTournaments.modals?.create_edit_fair_play_settings_modal }
                    onClose={ ()=>{ this.props.changeCreateEditFairPlaySettingsModalStateAction(false); this.setState({base_edit_fair_play_setting: null }) } }
                    fp_setting={ this.state.fair_play_setting_state ==='new'? this.props.state.leagueTournaments.default_fair_play_setting : this.state.base_edit_fair_play_setting }
                    edit={this.state.fair_play_setting_state === 'edit'}                    
                    handleOnSave={ (settings)=>{ this.createFairPlaySettings( settings ); this.props.changeCreateEditFairPlaySettingsModalStateAction(false); this.setState({base_edit_fair_play_setting: null})}}//TODO: reset selected_fair_play_setting
                    tournament_fair_play_most_points_wins={ this.props.state.leagueTournaments.tournament?.fair_play_most_points_wins }
                />

                {/**Fair Play Select Setting modal */}
                <TournamentPhasesFairPlaySelectSettingModalComponent
                    isOpen={ this.props.state.leagueTournaments.modals?.select_fair_play_setting_modal }
                    onClose={ ()=>{ this.props.changeFairPlaySelectSettingModalStateAction(false) } }
                    //fair_play_settings_options={[]}
                    fair_play_settings_options={ this.props.state.leagueTournaments.fair_play_settings }
                    next_fair_play_settings={this.props.state.leagueTournaments.next_fair_play_settings}
                    handleOnAccept={ (setting)=>{ this.selectEditSetting(setting)}}
                    loadMoreSettings={ ()=>{ this.props.loadFairPlaySettings(null, null, this.props.state.leagueTournaments.next_fair_play_settings) }}
                />

                {/** Add associated match to sanction modal */}
                <TournamentPhasesAddAssociateMatchToSanctionModalComponent
                    isOpen={ this.props.state.leagueTournaments.modals?.add_associate_match }
                    onClose={ ()=>{ this.props.changeAddAssociateMatchModalStateAction(false) } }
                    phase_matches_by_team={ this.props.state.leagueTournaments.phase_matches_by_team }
                    next_phase_matches_by_team={ this.props.state.leagueTournaments.next_phase_matches_by_team }
                    handleOnSelectMatch={ ( match )=>{ this.setState({ sanction_associate_match: match }); this.props.changeAddAssociateMatchModalStateAction(false) } }
                    handleOnViewMoreMatches={ (next_page)=>{ this.props.loadPhaseMatchesByTeam( null, null, next_page ) } }
                />

                {/** View more phase sanctions modal */}
                <TournamentPhasesViewMoreSanctionsModalComponent
                    isOpen={ this.props.state.leagueTournaments.modals?.view_more_sanctions }
                    onClose={ ()=>{ this.props.changeViewMoreSanctionsModalStateAction(false) } }
                    penlatyList={ this.props.state.leagueTournaments.phase_sanctions }
                    next_phase_sanctions={ this.props.state.leagueTournaments.next_phase_sanctions }
                    loading_phase_sanctions={ this.props.state.leagueTournaments.loading_phase_sanctions }

                    selected_tournament = { this.state.selected_tournament }
                    tournament_phases={ this.props.state.leagueTournaments?.tournament?.tournament_phases }
                    tournament_active_phase={ this.props.state.leagueTournaments?.selected_fair_play_phase }
                    handleOnClickPhaseFairlPlay={ (phase)=>{ this.loadPuntuactionTabData(phase) } }
                    loadMoreSanctions={ ( next_url )=>{ this.props.loadPhaseSanctions(null,null, null, next_url) }}
                    handleOnEditPenalty={ ( penalty )=>{ this.handleEditPenalty( penalty ) } }
                    handleOnDeletePenalty={ ( penalty )=>{  this.handleDeletePenalty( penalty ) } }
                    handleOnFilterPenaltys={ ( penaty_type )=> {this.handleFilterPenaltys( penaty_type )} }
                    filter_penalty_type={ this.state.filter_penalty_type }
                />

                {/** Confirm delete extra sanction */}
                <TournamentPhaseDeleteExtraSanctionConfirmationModalComponent
                    isOpen={ this.props.state.leagueTournaments.modals?.confirm_delete_sanctions }
                    //onClose={ ()=>{ this.props.changeConfirmDeleteSanctionsModalStateAction(false) } }
                    handleOnCancel={ ()=>{ this.props.changeConfirmDeleteSanctionsModalStateAction(false); this.setState({penalty_to_delete: null})} }
                    handleOnDelete={ ()=>{ 
                        this.deletePenalty(); 
                        this.props.changeConfirmDeleteSanctionsModalStateAction(false); 
                        this.setState({penalty_to_delete: null}) 
                    } }
                />

                {/** Confirm reopen phase modal */}
                <TournamentPhaseConfirmReopenPhaseModalComponent
                    isOpen={ this.props.state.leagueTournaments.modals?.confirm_reopen_phase }
                    handleOnReOpen={ ()=>{ this.props.reOpenPhase( this.state.reopen_phase ); this.props.changeConfirmReOpenPhaseModalStateAction(false); this.setState({reopen_phase: null }) } }
                    handleOnCancel={ ()=>{ this.props.changeConfirmReOpenPhaseModalStateAction(false); this.setState({reopen_phase: null }) } }
                />
                
                {/** VIEW */}
                <div className="league">
                    {/*show fixture resume in all page*/}
                    {/* this.state.show_fixture_resume &&
                        <TournamentPhaseFixtureResumeComponent 
                            fixture = { this.props.state.leagueTournaments.tournament.active_phase_fixture_resume }
                            handleOnReorganize = { (phase_id) => { this.handleOnReorganize(phase_id) } }
                            handleOnAccept = { () => { this.handleOnAccept() } }
                            loading_fixture_state = { this.state.loading_fixture_state }
                            phase_teams = { this.state.phase_teams }
                            phase_dates = { this.state.phase_dates }
                        /> */      
                    }
                    {!this.state.show_fixture_resume && !this.props.state.leagueTournaments.start_cards_id_component &&
                    <React.Fragment>
                    <div className="league__left-container">
                    </div>
                    <div className="league__center-container">           

                    { !this.state.new_tournament && !this.state.edit_tournament && !this.state.show_match_report && !this.state.tournament_teams_enrollment_component && !this.state.start_tournament_component &&

                        <div className="tournament-view__new-tournament__header-container">
                            <div className="baloa-headline-2">{this.props.t('options.home.tournamentsOption')}</div>
                            <div className="tournament-view__tournament-actions">
                                <PrimaryButtonComponent 
                                    type="button"
                                    disabled={ this.props.state.leagues.league_teams_is_loading || !permissionsController(this.props.state.page.page.roles, ['TOURNAMENTAD'])}                                        
                                    onClick={()=>{ this.newTournament() }}
                                >  
                                    <div className="new-tournament__center__header__button__btn-container">                                           
                                        {!this.props.state.leagues.league_teams_is_loading 
                                            ? 
                                            <div className="span-newtournament__enabled" >{`+ ${this.props.t('options.tournaments.pages.newTournament.labelTitle')}`}</div>
                                            :
                                            <div className='new-tournament__center__header__button__spinner-container'>                                                
                                                <div className="span-newtournament__disabled" />
                                            </div>
                                        }
                                    </div>
                                </PrimaryButtonComponent>
                                <SecondaryButtonComponent onClick={ () => this.props.changeFieldsAdminModalStateAction( true ) }>{this.props.t('options.pageFields.labelTitle')}</SecondaryButtonComponent>
                            </div>
                        </div>
                    }

                    { !this.state.new_tournament && !this.state.edit_tournament && !this.state.show_match_report && !this.state.tournament_teams_enrollment_component && !this.state.start_tournament_component &&
                        <div>
                            {/* <div>{'componente de torneos- pagina -> torneos - tournament view'}</div> */}
                            <LeagueTournamentComponent // LeagueTournamentGeneralListComponent
                                tournament_tag = {this.state.tournament_tag}
                                league_page = {this.pagename}
                                tournamentHub={false}
                                tournament_admin_view={true}
                                //tournamentTile={this.props.t('options.tournaments.labelCreateTournament')}
                                filterType={'tournamentList'} // tournamentAll - tournamentSport - tournamentVideoGame
                                onResetError={(data) => {this.props.changeTournamentBasicDataError(data)}}
                                errors={this.props.state.leagueTournaments.errors.list_tournament_filters}
                                active_tournaments= { this.trastaleTournamentCategory(this.props.state.leagueTournaments.league_tournaments?.filter(function( el ) { return el.state.toLowerCase() !== "finalized" })) }
                                completed_tournaments={ this.trastaleTournamentCategory(this.props.state.leagueTournaments.league_tournaments?.filter(function( el ) { return el.state.toLowerCase() === "finalized" })) }
                                handleBackButton = { () => this.handleBackButton() } 
                                handleOnActiveTournamentActionClick={ ( value ) => { this.handleOnActiveTournament( value ); } }
                                handleOnCompletedTournamentActionClick={ ( value ) => { this.handleOnCompletedTournament( value )} } 
                                handleOnFinishedTournament={ ( value ) => { this.handleOnFinishedTournament( value )} }
                                handleFilterTournament={ (value) => { this.handleOnFilterTournament(value)} }                     
                                league_teams_is_loading = {this.props.state.leagues.league_teams_is_loading}
                                showFilter={true}    
                                handleOnShareTournamentLinkClick={(tournament)=>{this.handleOnShareTournamentLinkClick(tournament)}}        
                            />                             
                        </div>

                    }

                    { this.state.new_tournament &&

                        <TournamentCreateNewTournamentV2Component
                            /* Basic data */                            
                            //handleSearchWorldCities = {( searchedcity ) => this.props.searchWorldCities( searchedcity )}
                            //handleSearchWorldCities = {( countryId, searchedcity ) => this.props.searchCityByCountry( this.props.state.page.page.nationality.id, searchedcity )}
                            handleSearchWorldCities = {( searchedcity ) => this.props.searchCityByCountry( this.props.state.leagueTournaments.countryId, searchedcity )}
                            cities = {this.props.state.leagueTournaments?.world_cities}
                            countries =  { this.props.state.leagueTournaments?.countries}
                            handleSearchNationality={(new_nationality_search) => { this.props.getCountries( new_nationality_search) }}                            
                            disableCities = {this.props.state.leagueTournaments.countryId?false:true}
                            /* Add Team to tournament */
                            league_teams={this.props.state.leagues?.league_teams?.results}
                            tournament_teams={this.props.state.leagueTournaments?.tournament?.tournament_teams}
                            phase_teams={this.props.state.leagueTournaments?.tournament?.tournament_active_phase_teams}
                            handleOnAddTeamToTournament={ ( value ) => { this.handleOnAddTeamToTournament( value )} }
                            handleOnDeleteTeamFromTournament={ ( value ) => { this.handleOnDeleteTeamFromTournament( value )} }
                            sendInvitationToTeamTournament={(value)=>this.handleOnAddInviteTeamToTournament( value )}
                            /* Add Member to tournament */
                            league_members={this.props.state.leagues?.league_members?.results}
                            tournament_members={this.props.state.leagueTournaments?.tournament?.tournament_members}
                            handleOnAddMemberToTournament={ ( value ) => { this.handleOnAddMemberToTournament( value )} }
                            handleOnDeleteMemberFromTournament={ ( value ) => { this.handleOnDeleteMemberFromTournament( value )} }                            //
                            onResetError={(data) => {this.props.changeTournamentBasicDataError(data)}}
                            errors={this.props.state.leagueTournaments.errors.tournament_basic_data}
                            league_page = {this.pagename}
                            createNewTournament = {(tournament_data) => {this.createNewTournament(tournament_data)}} 
                            tournament_dates={this.props.state.leagueTournaments?.tournament.tournament_dates}
                            handleNewDateTournamentClick={ ( ) => { this.handleOnNewTournamentDate() } }
                            selectedDate={this.props.state.leagueTournaments?.selected_date}
                            handleDateClick={ ( v ) => { this.handleOnTournamentDate( v ) } }
                            date_matches={this.props.state.leagueTournaments?.selected_date?.matches}  
                            deleteDate={(date,index, group) => this.handleDeleteDate(date,index,group)}
                            handleBackButton = { () => this.handleBackButton() } 
                            saveNewTournament = { () => this.saveNewTournament() }
                            viewMatchReport = { ( v ) => { this.viewMatchReport( v ) } }
                            handleEditMatchTournament = { ( v ) => { this.handleEditMatchTournament( v ) } }
                            handleNewMatchTournament = { ( v ) => { this.handleNewMatchTournament( v ) } }
                            handleDeleteMatchTournament = { ( v ) => { this.handleDeleteMatchTournament( v ) } }
                            // enable_add_match_button = { this.state.enable_add_match_button }
                            disabled_edit_tournament_data = { this.state.disabled_edit_tournament_data }
                            enable_add_match_button = { this.state.enable_add_match_button }
                            handleOnFinishMatchActionClick = {  ( v ) => { /* this.handleMatchEnd( v ) */ this.handleOnfinishMatch(v) } }
                            errorsNewGroupTournament={this.props.state.leagueTournaments.errors.new_tournament_groups}
                            tournament_phases={ this.props.state.leagueTournaments.tournament?.tournament_phases}
                            tournament={ this.props.state.leagueTournaments.tournament}
                            addTournamentPhase = {(id_tournament) => {this.handleOnAddTournamentPhase(id_tournament)}}
                            deleteTournamentPhase = {(phase, index) => this.handleDeleteTournamentPhase(phase, index)}
                            handleGroupClick={ ( v, tab_click ) => { this.handleOnGroupClick( v, tab_click ) } }
                            groups = { this.props.state.leagueTournaments?.tournament?.tournament_active_phase?.groups }

                            /* add phases group to torunament */
                            edit_tournament={false}
                            createNewGroupPhases = {(tournament_id, phase_id, teams_clasified_by_groups) => { this.props.createNewGroupsPhases(tournament_id, phase_id, teams_clasified_by_groups)}}                          
                            /* Setting groups */
                            tournament_active_phase={ this.props.state.leagueTournaments?.tournament?.tournament_active_phase }
                            handleAddTeamToGroup = { (groupId, teamName) => this.props.addTeamToGroup(groupId, teamName, this.props.state.leagueTournaments.tournament.tournament_active_phase.id, this.state.teams_filter_type) }
                            handleDeleteTeamFromGroup = { (groupId, teamName) => this.props.deleteTeamFromGroup(groupId, teamName, this.props.state.leagueTournaments?.tournament?.tournament_active_phase?.id, this.state.teams_filter_type) }
                            active_phase_groups_create = { this.props.state.leagueTournaments?.tournament?.active_phase_groups_create }
                            handleActivePhaseCreateState = { (new_state) => this.props.changeActivePhaseGroupsCreateStateAction(new_state) }
                            /* Seting phases */
                            handleOnClickPhaseTab = { (phase) => this.handleOnClickPhaseTab(phase) } // There is no function inside the component 
                            handleOnClickFinishPhase = {(phase) => {this.handleOnClickFinishPhase(phase)}}
                            is_loaded_tournament_phases={this.props.state.leagueTournaments.tournament.is_loaded_tournament_phases}
                            deleteTournamentPhaseWihtOutType = {(id_tournament, phase) => {this.props.deleteTournamentPhaseWihtOutType(id_tournament, phase)}}
                            handleLeavingPhaseTab = {() => {this.handleLeavingPhaseTab()}} 
                            finishedMatchesByPhase = {(phase) => { this.handleFinishedMatchesByPhase(phase) }}
                            unfinished_matches={this.props.state.leagueTournaments.tournament.unfinished_tournament_matches}
                            phase_name_values = { this.props.state.leagueTournaments.phase_name_values}
                            tournament_gender = {this.state.tournament_gender}
                            tournament_tag = {this.state.tournament_tag}
                            handleSearchBaloaTeam = { (searched_team) => { this.props.searchBaloaTeam(searched_team) } }
                            baloaTeams = { this.props.state.leagueTournaments.baloa_teams }
                            handleCleanBaloaTeamsSearch = { ( )=> { this.handleCleanBaloaTeamsSearch()}}
                            handleOnAutomaticFixture = { () => {this.handleOnAutomaticFixture() } }                  
                            fixture_button_active = { this.state.fixture_button_active }
                            setTab = {this.state.tabAfterFixture}
                            cleanTabAfterFixture = { ()=> { this.cleanTabAfterFixture() } }
                            pageRoles = { this.props.state.page.page.roles }
                            inscription_errors={this.props.state.leagueTournaments.errors.new_tournament_inscription}
                            tournament_football_type = { this.state.football_type }
                            list_tournament_types={this.state.tournament_type}

                            handleOnFinishCreateNewTournament={ () => this.handleOnFinishCreateNewTournament() }
                            handlePreviewForm={(questions) => { this.handlePreviewForm(questions) }}
                            /**Submit button control */
                            disabled_submit_button={this.props.state.leagueTournaments.disabled_submit_button}
                            currency={this.props.state.leagueTournaments.countryCurrency?this.props.state.leagueTournaments.countryCurrency: ''}

                            handleNewTournamentCreateOnBoardingAccount={ () => this.handleNewTournamentCreateOnBoardingAccount() }
                            create_stripe_account={this.props.state.page.page?.create_stripe_account}
                            calculateRefereeFee={ (data) => {this.props.calculateRefereeFee(data)} }
                            referee_fee_value={this.props.state.leagueTournaments.tournament?.referee_fee_value}
                            handleOnResetRefereesFee={ () => {this.props.resetRefereeFeeAction(undefined)} } 
                            handleListExistingForm = { (formType) => { this.handleListExistingForm(formType) } } 
                            listExistingForm={ this.props.state.leagueTournaments.tournament?.list_existing_form}
                            handleOnLoadPageFormTemplate = { (id_form_template) => { this.handleOnLoadPageFormTemplate(id_form_template) } } 
                            pageTeamPlayerFormTemplate={ this.props.state.leagueTournaments.preselected_page_team_player_form_template}
                            handleCleanPageFormTemplate={ ()=> { this.props.cleanPageFormTemplateAction() }}
                        />
                    }
                    
                    { this.state.edit_tournament && !this.state.show_match_report && !this.state.start_tournament_component && !this.state.is_player_information && !this.state.tournament_teams_enrollment_component &&
                        <React.Fragment>
                            {!this.state.edit_basic_info && !this.state.edit_phase_component && !this.state.general_end_phases && !this.state.create_phase_component &&(
                                <TournamentEditTournamentComponent                        
                                league_teams={this.props.state.leagues?.league_teams?.results}
                                tournament_teams={this.props.state.leagueTournaments?.tournament?.tournament_teams}
                                phase_teams={this.props.state.leagueTournaments?.tournament?.tournament_active_phase_teams}
                                list_phase_teams={ this.state.tournament_phase_teams }//Se renombra el paramaetro para el filtro de partidos
                                filter_phase_teams={this.props.state.leagueTournaments?.tournament?.tournament_filter_phase_teams}
                                handleOnAddTeamToTournament={ ( value ) => { this.handleOnAddEditTeamToTournament( value )} }
                                handleOnAddInviteTeamToTournament={(value) => {this.handleOnAddInviteTeamToTournament( value)}}
                                handleOnDeleteTeamFromTournament={ ( value ) => { this.handleOnDeleteEditTeamFromTournament( value )} }
                                handleOnDisableTeamFromTournament={ ( value ) => { this.handleOnDisableEditTeamFromTournament( value )} }
                                sendInvitationToTeamTournament={(value)=>this.handleOnAddInviteTeamToTournament( value )}
                                /* Add Member to tournament */
                                league_members={this.props.state.leagues?.league_members?.results}
                                tournament_members={this.props.state.leagueTournaments?.tournament?.tournament_members}
                                handleOnAddMemberToTournament={ ( value ) => { this.handleOnAddEditMemberToTournament( value )} }
                                handleOnDeleteMemberFromTournament={ ( value ) => { this.handleOnDeleteMemberFromTournament( value )} }                            
                                onResetError={(data) => {this.props.changeTournamentBasicDataError(data)}}
                                errors={this.props.state.leagueTournaments.errors.tournament_basic_data}
                                errorsNewGroupTournament={this.props.state.leagueTournaments.errors.new_tournament_groups}
                                league_page={this.pagename}
                                createNewTournament={(tournament_data) => {this.props.createNewTournament(tournament_data)}}
                                // tournament_dates={this.props.state.leagueTournaments?.tournament.tournament_dates}
                                tournament_dates={this.state.tournament_dates}
                                handleNewDateTournamentClick={ ( ) => { this.handleOnNewEditTournamentDate() } }
                                selectedDate={this.props.state.leagueTournaments?.selected_date}
                                handleDateClick={ ( v ) => { this.handleOnTournamentDate( v ) } }
                                date_matches={this.props.state.leagueTournaments?.selected_date?.matches}
                                deleteDate={(date,index,group) => this.handleDeleteDate(date,index,group)}
                                selected_tournament={ this.state.selected_tournament }
                                selected_edit_tournament={this.props.state.leagueTournaments.tournament}
                                handleBackButton={ () => this.handleBackButton() }
                                handleEndTournamentModal={ () => this.handleEndTournamentModal() }
                                disabled_edit_tournament_data={ this.state.disabled_edit_tournament_data }
                                phase_groups_results_table={ this.props.state.leagueTournaments?.tournament?.phase_groups_results_table }
                                tournament_results_table={ this.props.state.leagueTournaments?.tournament?.phase_results_table }
                                handleOnPhaseGroupPositionTable={ (group_id)=>{ this.props.loadPhaseGroupPositionsTable(group_id)} }
                                loading_phase_group_positions_table={ this.props.state.leagueTournaments?.loading_phase_group_positions_table }
                                tournament_phases={ this.props.state.leagueTournaments?.tournament?.tournament_phases }
                                viewMatchReport={ ( v ) => { this.viewMatchReport( v ) } }    
                                handleEndTournament={ ( v ) => { this.handleEndTournament( v ) } }
                                handleEndMatchTournament={ ( v ) => { this.handleEndMatchTournament( v ) } }
                                handleEditMatchTournament={ ( v ) => { this.handleEditMatchTournament( v ) } }
                                handleNewMatchTournament={ ( v ) => { this.handleNewMatchTournament( v ) } }
                                enable_add_match_button={ this.state.enable_add_match_button }
                                handleDeleteMatchTournament={ ( v ) => { this.handleDeleteMatchTournament( v ) } }
                                handleOnFinishMatchActionClick={  ( v ) => { /* this.handleMatchEnd( v ) */ this.handleOnfinishMatch( v ) } }
                                tournament_name_from_edit_member_team_modal={ this.props.state.leagues.see_tournament_from_team_member_modal?.tournament?.tournament }
                                addTournamentPhase={(id_tournament) => {this.handleOnAddTournamentPhase(id_tournament)}}
                                /* Setting groups */
                                tournament_active_phase={ this.props.state.leagueTournaments?.tournament?.tournament_active_phase }
                                handleAddTeamToGroup={ (groupId, teamName) => this.props.addTeamToGroup(groupId, teamName, this.props.state.leagueTournaments.tournament.tournament_active_phase.id, this.state.teams_filter_type) }
                                // handleDeleteTeamFromGroup={ (groupId, teamName) => this.props.deleteTeamFromGroup(groupId, teamName) }
                                handleDeleteTeamFromGroup={ (teamName,groupId) => this.handleModalStateForPhases(teamName, 'team_delete_validation', groupId)}
                                active_phase_groups_create={ this.props.state.leagueTournaments?.tournament?.active_phase_groups_create }
                                handleActivePhaseCreateState={ (new_state) => this.props.changeActivePhaseGroupsCreateStateAction(new_state) }
                                handleGroupClick={ ( v, tab_click ) => { this.handleOnGroupClick( v, tab_click ) } }
                                groups={ this.props.state.leagueTournaments?.tournament?.tournament_active_phase?.groups }
                                /* Seting phases */
                                handleOnClickPhaseTab={ ( phase, typeAction) => this.handleOnClickPhaseTab( phase, typeAction ) }
                                handleOnClickPhaseTabInPositionsTable={ (phase) => this.handleOnClickPhaseTabInPositionsTable(phase) }
                                /* add phases group to torunament */                            
                                edit_tournament={true}
                                createNewGroupPhases={(tournament_id, phase_id, teams_clasified_by_groups) => { this.props.createNewGroupsPhases(tournament_id, phase_id, teams_clasified_by_groups)}}                            
                                // deleteTournamentPhas={(id_tournament, phase) => {this.props.deleteTournamentPhase(id_tournament, phase)}}
                                //deleteTournamentPhase={(phase, index) => this.handleDeleteTournamentPhase(phase, index)}

                                deleteTournamentPhase={(phase, index) => this.handleModalStateForPhases(phase, 'phases_delete_validation', index)}

                                deleteTournamentPhaseWihtOutType={(id_tournament, phase) => {this.props.deleteTournamentPhaseWihtOutType(id_tournament, phase)}}
                                handleOnClickFinishPhase={(phase) => {this.handleOnClickFinishPhase(phase)}}
                                teams_classified_by_phases={this.props.state.leagueTournaments.tournament?.teams_classified_by_phases}
                                is_loaded_tournament_phases={this.props.state.leagueTournaments.tournament.is_loaded_tournament_phases}
                                finished_last_phase_with_one_clasified={ false }
                                is_loaded_tournament_dates={this.props.state.leagueTournaments.tournament.is_loaded_tournament_dates}
                                loaded_tournament={this.state.loaded_tournament}
                                handleLeavingPhaseTab={() => {this.handleLeavingPhaseTab()}}                                                       
                                AddTeamToGroupModal={() => this.handleAddTeamToGroupModal()}
                                finishedMatchesByPhase={(phase) => { this.handleFinishedMatchesByPhase(phase) }}  
                                unfinished_matches={this.props.state.leagueTournaments.tournament.unfinished_tournament_matches}
                                handleOnProfileClick={ ( profile ) => { this.handleOnProfileClick( profile ) } }
                                handleOnPageClick={ ( page, team ) => { this.handleOnPageClick( page, team ) } }
                                phase_name_values={ this.props.state.leagueTournaments.phase_name_values}                           
                                tournament_tag={this.state.tournament_tag}
                                handleTournamentFinalizedSuggestion={(is_suggestion_on)=>{this.handleTournamentFinalizedSuggestion(is_suggestion_on)}}
                                is_tournament_finalized={ this.state.is_tournament_finalized }
                                handleSearchBaloaTeam={ (searched_team) => { this.props.searchBaloaTeam(searched_team) } }
                                baloaTeams={ this.props.state.leagueTournaments.baloa_teams }
                                handleCleanBaloaTeamsSearch={ ( )=> { this.handleCleanBaloaTeamsSearch()}}
                                handleSearchNationality={(new_nationality_search) => { this.props.getCountries( new_nationality_search) }}                            
                                handleSearchWorldCities={( searchedcity ) => this.props.searchCityByCountry( this.props.state.leagueTournaments.countryId, searchedcity )}
                                handleOnEditTournamentInfo={() => {this.handleOnEditTournamentInfo()}}
                                editBasicInfo={ this.state.edit_basic_info }
                                countries={ this.props.state.leagueTournaments?.countries}
                                disableCities={this.props.state.leagueTournaments.countryId?false:true}
                                cities={this.props.state.leagueTournaments?.world_cities}
                                editTournamentInfo={(tournament_data)=>{
                                    this.props.updateTournamentBasicInfo(tournament_data, this.state.selected_tournament.id);
                                    this.stopEditEditTournamentInfo();
                                }}         
                                handleOnAutomaticFixture={ () => {this.handleOnAutomaticFixture() } }                  
                                fixture_button_active={ this.state.fixture_button_active }
                                pageRoles={ this.props.state.page.page.roles }
                                stopEditTournamentInfo={ () => {this.stopEditEditTournamentInfo()} }
                                tournament={ this.props.state.leagueTournaments.tournament}
                                tournament_gender={this.state.tournament_gender}
                                setTab={this.state.tabAfterFixture}
                                cleanTabAfterFixture={ ()=> { this.cleanTabAfterFixture() } }
                                inscription_errors={this.props.state.leagueTournaments.errors.new_tournament_inscription}
                                tournament_football_type={ this.state.football_type }
                                handleOnFinishCreateNewTournament={ () => this.handleOnFinishCreateNewTournament() }
                                handleCalendarStep={ () => this.handleCalendarStep() }
                                tournament_setup_teams={this.props.state.leagueTournaments?.tournament.tournament_teams}
                                handleTeamInfo={ (team) => this.handleTeamInfo(team) }
                                handleDisableTeam={(team)=>this.handleOnDisableEditTeamFromTournament(team)}
                                tournament_criteria_tie_breaking = { this.props.state.leagueTournaments.tournament.tournament_criteria_phase }
                                tournament_new_order_criteria_tie_breaking = {(new_order) => this.props.setNewCriteriaTournamentPhase(this.props.state.leagueTournaments.tournament.tournament_active_phase.id,new_order)}
                                handleOpenEditFormModal={(state_modal, modal_type)=>this.handleOpenEditFormModal(state_modal, modal_type)}
                                handlebackToSummaryTournament={()=> this.handlebackToSummaryTournament()}
                                handleOpenConfirmationModal={(state_modal)=>this.handleOpenConfirmationModal(state_modal)}
                                fieldStatusEditing = {this.props.state.leagueTournaments.tournament.fields_status_editing}
                                is_best_third={this.state.is_best_third}
                                //handleOnInviteTeamModal={ (type_modal) => this.handleInviteOrInscriptionTeamModal(type_modal) }
                                handleOnInviteTeamModal={ (type_modal) => this.handleAddStaf(type_modal) }
                                handleOnValidateTeamInscriptionModal={ (team, state) => this.handleOnValidateTeamInscriptionModal(team, state) }
                                handleInscriptionInvitationModalActions={ (team, team_state) => this.handleInscriptionInvitationModalActions(team, team_state) }
                                handle_team_filter= {(phaseId, type_filter) => this.handleTeamsFilterinPhases(phaseId, type_filter)}
                                handleBestThirdByGroup={(status, groupId) => this.handleBestThirdByGroup(status, groupId)}
                                loadTieBreakerByPhase={(phaseId) => this.handleLoadTieBreakerByPhase(phaseId)}
                                handleEditPhase={(phaseData, phasesId)=> this.handleEditPhase(phaseData, phasesId)}
                                handleMoveToGroup={(group_best_third, team, original_id, group_id)=> this.handleModalStateForPhases({group_best_third, team, original_id, group_id}, 'phases_move_to_group')}
                                handleTeamGroupPhaseknockout={(dataKnockout)=> this.handleModalStateForPhases(dataKnockout, 'phases_delete_Knockout')}                                
                                handleOnShareTournamentLinkClick ={ (tournament)=>{this.handleOnShareTournamentLinkClick(tournament) } }
                                handleOpenShareIframeLinkModal={(tournametn_id) => this.handleOpenShareIframeLinkModal(tournametn_id)}

                                //New Staff params
                                handleAddStaf={ (profile)=>{this.handleAddStaf(profile)} }
                                staff={this.props.state.leagueTournaments.tournament?.tournament_staff}
                                handleOnAcceptStaffClick = { (role_id)=>{this.props.updateStaffState(role_id,'Active')} }
                                handleOnRejectStaffClick = { (role_id)=>{this.props.deleteStaff(role_id)} }
                                handleOnEditStaffClick = { (role_id, role_type)=>{this.props.updateStaffState(role_id, null, role_type)} }
                                handleOnDeleteStaffClick = { (role_id)=>{this.handleDeleteStaff(role_id)} }
                                referees_payment_params={this.props.match.params}
                                not_assigned_teams={this.props.state.leagueTournaments.tournament.number_unassigned_teams}

                                //Download forms/payment
                                handleOnDownloadForms={ (tournament_id)=>{ this.handleOnDownloadForms(tournament_id)} }
                                handleOnDownloadPayments={ (tournament_id)=>{ this.handleOnDownloadPayments(tournament_id)} }

                                handleOnEditPhaseModal={(edit_phase_type, group) => { this.handleOnEditPhaseModal(edit_phase_type, group) }}
                                handleOnClickNewPhase={(state, clickSource) => this.handleOnClickNewPhase(state, clickSource)}
                                handleDeleteGroupPhase={(group_id) => this.handleDeleteGroupPhase(group_id)}
                                handleNumberClassified={this.handleNumberClassified}
                                handleVerifyMatches={(phase_action) => {this.handleVerifyMatches(phase_action)}}
                                is_phase_finalized={this.props.state.leagueTournaments.tournament?.is_phase_finalized}
                                is_phase_finalizing={this.state.is_phase_finalizing}
                                deleteTeamFromCreatePhase={(team, teamname, phase_id) => {this.props.deleteTeamFromGroup(team, teamname, phase_id, this.state.teams_filter_type)}}
                                teams_to_group={this.state.teams_to_group}
                                preSelectedTab={this.state.preSelectedTab}
                                resetPreSelectedTab = { ()=>{ this.resetPreSelectedTab() } }
                                teams_filter_type={this.state.teams_filter_type}
                                loadPhaseResultsTable = { (phase_id)=>{ this.props.loadPhaseResultsTable(phase_id) } }

                                //CardIds
                                handleLoadformPlayer = {(tournament_id) => {this.handleLoadformPlayer(tournament_id)}}
                                sendCarnet = { (form, form_type_id, caption, templateImage, orientation) => this.handleSendCarnet(form, form_type_id, caption, templateImage, orientation) }
                                GetCodeFormTemplate = {()=>this.props.GetCodeFormTemplate()}
                                card_id_template ={ this.props.state.leagueTournaments.tournament.carnet_id_template }
                                deleteCardIdTemplate={()=>{this.props.deleteCardIdTemplate(this.props.state.leagueTournaments.tournament.carnet_id_template.id)}}
                                handleOnDeleteCardIdTemplate={()=>{this.handleOnDeleteCardIdTemplate()}}
                                handleOnDownloadCardIds={ ()=> { this.props.changeCardIdTeamsSelectModalStateAction(true) }}
                                
                                // Tournament Matches Admin
                                handleTournamentFieldsAdmin={ () => this.handleTournamentFieldsAdmin() }
                                tournament_fields={ this.state?.tournament_fields }
                                handleTournamentPhaseMatchFilters={ async (tournament_date_id, params) => await this.props.loadTournamentMatchesFilterData(tournament_date_id, params) }
                                handleSubmitEditInfoMatch={ (match_id, form_data) => this.handleEditMatchGameTournament( match_id, form_data ) }
                                new_page_field={ this.props.state.leagueTournaments?.new_page_field }
                                handleFieldsAdminActions={ (action_type, field) => { this.handleFieldsAdminActions(action_type, field)} }
                                handleTournamentFieldActions={ (action_type, field) => this.handleTournamentFieldActions(action_type, field) }
                                tournament_selected_date={ this.state.tournament_selected_date }
                                handleTournamentPhaseDaysFilters={ async (active_group_id, params) => await this.props.loadTournamentPhaseGroupDatesFilterData( active_group_id, params ) }
                                tournament_dates_filter={ this.state.tournament_dates_filtered }
                                language_code= { this.props.state.user.me.language?.language_code }
                                date_matches_is_loading = { this.props.state.leagueTournaments.date_matches_is_loading }
                                handleDownloadMatchReport={( match )=>{ this.downloadMatchReport( match )}}
                                next_tournament_teams={ this.props.state.leagueTournaments.next_tournament_teams }
                                loadMoreTeams={(next_page)=>{ this.props.loadTournamentTeams(this.props.state.leagueTournaments.tournament?.id, '', '', null, next_page) }}
                                tournament_teams_loading={this.props.state.leagueTournaments.tournament_teams_loading}
                                tournament_teams_count={ this.props.state.leagueTournaments.tournament_teams_count}

                                loadInfoFormModal={(form_id)=>{this.loadInfoFormModal(form_id)}}
                                handleTeamPlayerPaymentInfo={ (payment_info, currency) => this.handleTeamPlayerPaymentInfo(payment_info, currency) }
                                tournament_staff={ this.state.tournament_staff }

                                handleOnSearchTeams={ ( tournament_team_name, state )=>{ this.props.searchTournamentTeamsAdmin( this.state.selected_tournament.id, tournament_team_name, state ) }}

                                next_date_matches={this.props.state.leagueTournaments.selected_date?.next_date_matches}
                                handleViewMoreMatches={(next_url)=>{ this.props.loadDateMatches(null,next_url)}}

                                loadStatistics={ ( tournament_id )=> { this.loadStatistics( this.props.state.leagueTournaments.tournament.id ) }}
                                tournament_scorers = { this.props.state.leagueTournaments.tournament_scorers }
                                tournament_yellow_cards = { this.props.state.leagueTournaments.tournament_yellow_cards }
                                tournament_red_cards = { this.props.state.leagueTournaments.tournament_red_cards }
                                tournament_blue_cards = { this.props.state.leagueTournaments.tournament_blue_cards }
                                tournament_assists = { this.props.state.leagueTournaments.tournament_assists }
                                tournament_least_defeated_fence = { this.props.state.leagueTournaments.tournament_least_defeated_fence }
                                tournament_teams_most_goals = { this.props.state.leagueTournaments.tournament_teams_most_goals }
                                tournament_fair_play = { this.props.state.leagueTournaments.tournament_fair_play }
                                tournament_summary = { this.props.state.leagueTournaments.tournament_summary }
                                loading_tournament_scorers = { this.props.state.leagueTournaments.loading_tournament_scorers }
                                loading_tournament_yellow_cards = { this.props.state.leagueTournaments.loading_tournament_yellow_cards }
                                loading_tournament_red_cards = { this.props.state.leagueTournaments.loading_tournament_red_cards }
                                loading_tournament_blue_cards = { this.props.state.leagueTournaments.loading_tournament_blue_cards }
                                loading_tournament_assists = { this.props.state.leagueTournaments.loading_tournament_assists }
                                loading_tournament_least_defeated_fence = { this.props.state.leagueTournaments.loading_tournament_least_defeated_fence }
                                loading_tournament_teams_most_goals = { this.props.state.leagueTournaments.loading_tournament_teams_most_goals }
                                loading_tournament_fair_play = { this.props.state.leagueTournaments.loading_tournament_fair_play }
                                loading_tournament_summary = { this.props.state.leagueTournaments.loading_tournament_summary }
                                next_tournament_scorers = { this.props.state.leagueTournaments.next_tournament_scorers }
                                next_tournament_yellow_cards = { this.props.state.leagueTournaments.next_tournament_yellow_cards }
                                next_tournament_red_cards = { this.props.state.leagueTournaments.next_tournament_red_cards }
                                next_tournament_blue_cards = { this.props.state.leagueTournaments.next_tournament_blue_cards }
                                next_tournament_assists = { this.props.state.leagueTournaments.next_tournament_assists }
                                next_tournament_least_defeated_fence = { this.props.state.leagueTournaments.next_tournament_least_defeated_fence }
                                next_tournament_teams_most_goals = { this.props.state.leagueTournaments.next_tournament_teams_most_goals }
                                next_tournament_fair_play = { this.props.state.leagueTournaments.next_tournament_fair_play }
                                handleOnViewMoreStatistics = { (type)=>{ this.handleViewMoreStatistics(this.props.state.leagueTournaments.tournament.id,type) }}


                                editMatchMainInfo={ (info_type, match)=>{ this.editMatchMainInfo(info_type, match) } }
                                endingTournament={this.state.endingTournament}

                                handleOnClickPhaseFairlPlay = { (phase)=>{ this.loadPuntuactionTabData(phase) }}
                                selected_fair_play_setting={this.props.state.leagueTournaments?.selected_fair_play_setting}
                                handleOnAddPenalty={ ()=>{ this.handleAddPenalty() } }
                                penlatyList={ this.props.state.leagueTournaments.phase_sanctions }
                                selected_fair_play_phase={ this.props.state.leagueTournaments?.selected_fair_play_phase }
                                next_phase_sanctions={ this.props.state.leagueTournaments.next_phase_sanctions }
                                loading_phase_sanctions={ this.props.state.leagueTournaments.loading_phase_sanctions }
                                handleOnViewMoreSanctions={ ()=>{this.props.changeViewMoreSanctionsModalStateAction(true)} }
                                handleOnEditPenalty={ ( penalty )=>{ this.handleEditPenalty( penalty ) } }
                                handleOnDeletePenalty={ ( penalty )=>{  this.handleDeletePenalty( penalty ) } }
                                handleOnFilterPenaltys={ ( penaty_type )=> {this.handleFilterPenaltys( penaty_type )} }
                                filter_penalty_type={ this.state.filter_penalty_type }

                                handleOnClickReopenPhase={ ( tournament_phase_id )=> {this.handleOnReopenPhase( tournament_phase_id )}}
                            />
                            )}
                            
                            


                            {/* Phases edit component  */}
                            {this.state.edit_phase_component &&
                                <TournamentPhaseEditComponent
                                    selected_phase = {this.props.state.leagueTournaments.tournament?.tournament_active_phase}
                                    errorsNewGroupTournament={this.props.state.leagueTournaments.errors.new_tournament_groups}
                                    league_page={this.pagename}
                                    phase_teams={this.props.state.leagueTournaments?.tournament?.tournament_active_phase_teams}
                                    tournament_phases={ this.props.state.leagueTournaments?.tournament?.tournament_phases }
                                    edit_tournament={true}
                                    phase_name_values={ this.props.state.leagueTournaments.phase_name_values}
                                    tournament_teams={this.props.state.leagueTournaments?.tournament?.tournament_teams}
                                    tournament_criteria_tie_breaking = { this.props.state.leagueTournaments.tournament.tournament_criteria_phase }
                                    tournament_new_order_criteria_tie_breaking = {(new_order) => this.props.setNewCriteriaTournamentPhase(this.props.state.leagueTournaments.tournament.tournament_active_phase.id,new_order)}
                                    handleEditPhase={(phaseData)=> this.handleModalStateForPhases(phaseData, 'phase_edit_status')}
                                    handleReturnPhaseComponent={(state)=>this.handleReturnPhaseComponent(state)}
                                    onResetError={(data) => {this.props.changeTournamentBasicDataError(data)}}
                                    handle_team_filter= {(phaseId, type_filter) => this.handleTeamsFilterinPhases(phaseId, type_filter)}
                                    handleOnCreateFairPlaySettings = { ()=>{ this.handleCreateFairPlaySettings() } }
                                    handleOnEditFairPlaySettings = { ()=>{ this.handleEditFairPlaySettings() } }
                                    handleOnChangeSetting={ ()=>{ this.handleChangeFairPlaySetting() } }
                                    selected_fair_play_setting={this.props.state.leagueTournaments?.selected_fair_play_setting}
                                />
                            }
                            {/* General End phases */}
                            {(this.props.state.leagueTournaments.modals?.finish_groups_phase_is_open || this.props.state.leagueTournaments.modals?.finish_free_phase_is_open) &&
                                <TournamentGeneralEndPhasesComponent
                                    phase_id={this.state.phase?.id}
                                    finishTournamentPhase ={(id_phase) => {
                                        this.setState({is_phase_finalizing: true}); 
                                        this.props.endTournamentPhase(id_phase, this.props.state.leagueTournaments.tournament?.id)}
                                    }
                                    tournament_results_table={ this.props.state.leagueTournaments?.tournament?.phase_results_table }//TODO: Verificacion tabla de posiciones
                                    teams_classified_by_phases={this.props.state.leagueTournaments.tournament?.teams_classified_by_phases}
                                    handleReturnFinishComponent={()=>this.handleReturnFinishComponent()}
                                    selected_phase = {this.props.state.leagueTournaments.tournament?.tournament_active_phase}
                                />
                            }

                            {this.state.edit_basic_info &&(
                                <div>
                                    <div className="tournament-view__new-tournament__header-container_title"
                                            onClick={()=> this.handlebackToSummaryTournament()}>
                                        <span className="icon-arrow-left" />
                                        <div className="baloa-headline-2">
                                            {this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.editTournament')}
                                        </div>
                                    </div>
                                    <div className="tournament-view__new-tournament__header-container_edit_component">
                                        <TournamentEditBasicDataComponentV2
                                            onResetError={(data) => {this.props.changeTournamentBasicDataError(data)}}
                                            errors={this.props.state.leagueTournaments.errors.tournament_basic_data}
                                            inscription_errors={this.props.state.leagueTournaments.errors.new_tournament_inscription}
                                            league_page={this.pagename}
                                            selected_tournament={ this.state.selected_tournament }
                                            selected_edit_tournament={this.props.state.leagueTournaments.tournament}
                                            countries={ this.props.state.leagueTournaments?.countries}
                                            disableCities={this.props.state.leagueTournaments.countryId?false:true}
                                            cities={this.props.state.leagueTournaments?.world_cities}
                                            handleSearchNationality={(new_nationality_search) => { this.props.getCountries( new_nationality_search); /* console.log('se disparo por aca 3'); */ }}
                                            handleSearchWorldCities={( searchedcity ) => this.props.searchCityByCountry( this.props.state.leagueTournaments.countryId, searchedcity )}
                                            tournament_tag={this.state.tournament_tag}
                                            tournament_football_type={ this.state.football_type }
                                            editTournamentInfo={(tournament_data)=>{
                                                this.props.updateTournamentBasicInfo(tournament_data, this.state.selected_tournament.id);
                                                this.handlebackToSummaryTournament();
                                            }} 
                                            stopEditTournamentInfo={ () => {this.stopEditEditTournamentInfo()} }
                                            handleOpenEditFormModal={(state_modal, modal_type)=>this.handleOpenEditFormModal(state_modal, modal_type)}
                                            handlebackToSummaryTournament={()=> this.handlebackToSummaryTournament()}
                                            handleOpenConfirmationModal={(state_modal)=>this.handleOpenConfirmationModal(state_modal)}
                                            fieldStatusEditing = {this.props.state.leagueTournaments.tournament.fields_status_editing}
                                            currency={this.props.state.leagueTournaments.countryCurrency?this.props.state.leagueTournaments.countryCurrency: ''}
                                            list_tournament_types={this.state.tournament_type}
                                        />
                                    </div>
                                </div>
                            )}
                        </React.Fragment>
                    }

                    {/* Create Phase Component */}
                    {this.state.create_phase_component &&
                        <TournamentPhasesContainerComponent
                            // Props to TournamentNewPhaseComponent
                            selected_phase = {this.props.state.leagueTournaments.tournament?.tournament_active_phase}
                            errorsNewGroupTournament={this.props.state.leagueTournaments.errors.new_tournament_groups}
                            league_page={this.pagename}
                            phase_teams={this.props.state.leagueTournaments?.tournament?.tournament_active_phase_teams}
                            tournament_phases={ this.props.state.leagueTournaments?.tournament?.tournament_phases }
                            tournament_setup_teams={this.props.state.leagueTournaments?.tournament?.tournament_setup_teams}
                            handleEditPhase={(phaseData)=> this.handleModalStateForPhases(phaseData, 'phase_edit_status')}
                            handleReturnPhaseComponent={(state)=>this.handleReturnPhaseComponent(state)}
                            onResetError={(data) => {this.props.changeTournamentBasicDataError(data)}}
                            pageRoles={ this.props.state.page.page.roles }
                            addTournamentPhase={(id_tournament) => {this.handleOnAddTournamentPhase(id_tournament)}}
                            tournament_id={ this.props.state.leagueTournaments.tournament.id}
                            createNewGroupPhases={(tournament_id, phase_id, teams_clasified_by_groups) => { this.props.createNewGroupsPhases(tournament_id, phase_id, teams_clasified_by_groups)}}                            
                            // Props to TournamentGroupsComponent
                            groups={ this.props.state.leagueTournaments?.tournament?.tournament_active_phase?.groups }
                            tournament_teams={this.props.state.leagueTournaments?.tournament?.tournament_active_phase_teams}
                            tournament_active_phase={ this.props.state.leagueTournaments?.tournament?.tournament_active_phase }
                            handleAddTeamToGroup={ (groupId, teamName) => {this.props.addTeamToGroup(groupId, teamName, this.props.state.leagueTournaments.tournament.tournament_active_phase.id, this.state.teams_filter_type)} }
                            handleDeleteTeamFromGroup={ (teamName,groupId) => this.handleModalStateForPhases(teamName, 'team_delete_validation', groupId)}
                            handleActivePhaseCreateState={ (new_state) => this.props.changeActivePhaseGroupsCreateStateAction(new_state) }
                            active_phase_groups_create={ this.props.state.leagueTournaments?.tournament?.active_phase_groups_create }
                            tournament_criteria_tie_breaking = { this.props.state.leagueTournaments.tournament.tournament_criteria_phase }
                            tournament_new_order_criteria_tie_breaking = {(new_order) => this.props.setNewCriteriaTournamentPhase(this.props.state.leagueTournaments.tournament.tournament_active_phase.id,new_order)}
                            select_groups_teams={true}
                            
                            handleOnClickNewPhase={(state, clickSource) => this.handleOnClickNewPhase(state, clickSource)}
                            handle_team_filter= {(phaseId, type_filter) => this.handleTeamsFilterinPhases(phaseId, type_filter)}
                            deleteNewPhase={ (id_tournament, phase) => {this.props.deleteTournamentPhaseWihtOutType(id_tournament, phase)} }
                            handleDeleteGroupPhase={(groupId) => this.handleDeleteGroupPhase(groupId)}
                            handleCalendarStep={()=>{ this.handleCalendarStep() }}
                            handleOnEditPhaseModal={(edit_phase_type, group, isCreatePhase) => { this.handleOnEditPhaseModal(edit_phase_type, group, isCreatePhase) }}
                            handleOnPhaseValidation={(phaseType) => this.handleOnEditPhaseAction(phaseType)}
                            handleNumberClassified={(id_group, classified_count)=>this.handleNumberClassified(id_group, classified_count)}
                            handleCleanAllGroups={(phaseId) => {
                                this.setState({teams_filter_type: 'not_assigned'});
                                this.props.cleanAllGroupOfPhase(phaseId, this.props.state.leagueTournaments.tournament?.id);
                            }}
                            handleCreateAutomaticGroupPhases={(phaseId, filter_state)=> this.props.createAutomaticGroupPhases(phaseId, this.props.state.leagueTournaments.tournament?.id, filter_state)}
                            deleteTournamentPhase={(phase, index) => this.handleModalStateForPhases(phase, 'phases_delete_validation', index)}
                            click_source_create_phase={this.state.click_source_create_phase}
                            deleteTeamFromCreatePhase={(team, teamname, phase_id) => {this.props.deleteTeamFromGroup(team, teamname, phase_id, this.state.teams_filter_type)}}
                            loadTournamentPhaseTeams={(phaseId, stateTeams)=> this.props.loadTournamentPhaseTeams(phaseId, stateTeams)}
                            handleOnClickPhaseTab={ ( phase, typeAction) => this.handleOnClickPhaseTab( phase, typeAction ) }
                            handleOnAddAllTeamsToGroup={(group_id, state) => {this.props.addAllTeamsToGroup(group_id, state, this.props.state.leagueTournaments.tournament?.id, this.props.state.leagueTournaments.tournament.tournament_active_phase?.id)}}
                            teams_filter_type={this.state.teams_filter_type}
                            handleOnCreateFairPlaySettings = { ()=>{ this.handleCreateFairPlaySettings() } }
                            handleOnEditFairPlaySettings = { ()=>{ this.handleEditFairPlaySettings() } }
                            handleOnChangeSetting={ ()=>{ this.handleChangeFairPlaySetting() } }
                            selected_fair_play_setting={this.props.state.leagueTournaments?.selected_fair_play_setting}
                        />
                    }

                    { this.state.show_match_report &&
                         <div>

                            <LeagueMatchReportComponent
                                data = { this.props.state.leagueMatches.match_report }
                                handleBackButton = { () => this.handleBackFromMatchResultButton() }
                                backButtonTitle = {this.props.t('options.tournaments.pages.matchInfo.labelTitle')}
                                tournament={this.props.state.leagueTournaments?.tournament}

                                resetError={()=>{}}                
                                errors={this.props.state.leagueTournaments?.errors.edit_match}
                                onResetError={(data) => {this.props.changeNewMatchError(data)}}               
                                tournament_staff={this.props.state.leagueTournaments.tournament?.tournament_staff}
                                handleOnLoadRefereeingPaymentUrl={(payment_data, match_id, match_date_id) => { this.handleOnLoadRefereeingPaymentUrl(payment_data, match_id, match_date_id) }}
                                isRefereesPaymentResponse={this.state.is_referees_payment_response}
                                handleUpdateRefereesMatch={(match_id, referees_match_data, match_date_id) => { this.props.assignRefereesMatch(match_id, referees_match_data, match_date_id) }}
                                handleOnAcceptCrewPayment={ (match_id) => {this.props.makeRefereePayment(match_id)}}
                                referees_payment_response={this.referees_payment_response}
                                handleOnShareMatchLinkClick={ ()=>{ this.handleOnShareMatchLinkClick() } }
                                teams_match_assistance={this.props.state.leagueTournaments.tournament.teams_match_assistance}
                                updatePlayersAssistanceAligment={(playerArray)=> {
                                    this.props.updatePlayersAssistanceAligment(this.props.state.leagueTournaments.tournament.teams_match_assistance?.match_id, playerArray)
                                }}
                            ></LeagueMatchReportComponent>

                         </div>
                    }

                    { this.state.tournament_teams_enrollment_component && this.props.state.leagueTournaments.tournament.id && !this.state.is_player_information &&
                        <TournamentTeamsEnrollment
                            tournament={this.props.state.leagueTournaments.tournament}
                            league_members={this.props.state.leagues?.league_members?.results}
                            tournament_members={this.props.state.leagueTournaments?.tournament?.tournament_members}
                            handleOnAddMemberToTournament={ ( value ) => { this.handleOnAddMemberToTournament( value )} }
                            handleOnDeleteMemberFromTournament={ ( value ) => { this.handleOnDeleteMemberFromTournament( value )} }
                            pageRoles = { this.props.state.page.page.roles }
                            // Props to Invitation tab component
                            tournament_teams={ this.props.state.leagueTournaments?.tournament?.tournament_teams }
                            handleOnAddTeamToTournament={ ( value ) => { this.handleOnAddTeamToTournament( value )} }                            
                            //handleCancelTeamInvitation={ ( team, team_state ) => this.props.changeTournamentTeamState(team.id, team_state) }                            
                            sendInvitationToTeamTournament={(value)=>this.handleOnAddInviteTeamToTournament( value )}
                            handleSearchBaloaTeam = { (searched_team) => { this.props.searchBaloaTeam(searched_team) }}
                            baloaTeams = { this.props.state.leagueTournaments.baloa_teams }
                            handleCleanBaloaTeamsSearch = { ( )=> { this.handleCleanBaloaTeamsSearch()}}
                            handleOnStartTournament={ (tournament_state) => this.handleOnStartTournament(tournament_state) }
                            //handleOnInviteTeamModal={ (type_modal) => this.handleInviteOrInscriptionTeamModal(type_modal) }
                            handleOnInviteTeamModal={ (type_modal) => this.handleAddStaf(type_modal) }
                            handleTeamsEnrollmentBackBtn={ () => this.handleTeamsEnrollmentBackBtn() }
                            handleOnValidateTeamInscriptionModal={ (team, state) => this.handleOnValidateTeamInscriptionModal(team, state) }
                            handleInscriptionInvitationModalActions={ (team, team_state) => this.handleInscriptionInvitationModalActions(team, team_state) }
                            handleChangeTournamentState={ (tournament_state) => this.handleChangeTournamentState(tournament_state) }
                            handleOnEditTournamentInfo={() => {this.handleOnEditTournamentInfo()}}
                            handleStartTournamentInfo ={ ( new_state ) => { this.props.changeTournamentStartInfoModalStateAction( new_state ) } }
                            handleOnShareTournamentLinkClick ={ (tournament)=>{this.handleOnShareTournamentLinkClick(tournament) } }

                            //New Staff params
                            handleAddStaf={ (profile)=>{this.handleAddStaf(profile)} }
                            staff={this.props.state.leagueTournaments.tournament.tournament_staff}
                            handleOnAcceptStaffClick = { (role_id)=>{this.props.updateStaffState(role_id,'Active')} }
                            handleOnRejectStaffClick = { (role_id)=>{this.props.deleteStaff(role_id)} }
                            handleOnEditStaffClick = { (role_id, role_type)=>{this.props.updateStaffState(role_id, null, role_type)} }
                            handleOnDeleteStaffClick = { (role_id)=>{this.handleDeleteStaff(role_id)} }

                            //Download forms/payment
                            handleOnDownloadForms={ (tournament_id)=>{ this.handleOnDownloadForms(tournament_id)} }
                            handleOnDownloadPayments={ (tournament_id)=>{ this.handleOnDownloadPayments(tournament_id)} }
                            handleLoadformPlayer = {(tournament_id) => {this.handleLoadformPlayer(tournament_id)}}
                            sendCarnet = { (form, form_type_id, caption, templateImage, orientation) => this.handleSendCarnet(form, form_type_id, caption, templateImage, orientation) }
                            GetCodeFormTemplate = {()=>this.props.GetCodeFormTemplate()}
                            card_id_template ={ this.props.state.leagueTournaments.tournament.carnet_id_template }
                            deleteCardIdTemplate={()=>{this.props.deleteCardIdTemplate(this.props.state.leagueTournaments.tournament.carnet_id_template.id)}}
                            handleOnDeleteCardIdTemplate={()=>{this.handleOnDeleteCardIdTemplate()}}
                            handleOnDownloadCardIds={ ()=> { this.props.changeCardIdTeamsSelectModalStateAction(true) }}
                            next_tournament_teams={ this.props.state.leagueTournaments.next_tournament_teams }
                            loadMoreTeams={(next_page)=>{ this.props.loadTournamentTeams(this.props.state.leagueTournaments.tournament?.id,  '', '', null, next_page) }}
                            tournament_teams_loading={this.props.state.leagueTournaments.tournament_teams_loading}
                            tournament_teams_count={ this.props.state.leagueTournaments.tournament_teams_count}

                            loadInfoFormModal={(form_id)=>{this.loadInfoFormModal(form_id)}}
                            handleTeamPlayerPaymentInfo={ (payment_info, currency) => this.handleTeamPlayerPaymentInfo(payment_info, currency) }

                            handleOnSearchTeams={ ( tournament_team_name, state )=>{ this.props.searchTournamentTeamsAdmin( this.state.selected_tournament.id, tournament_team_name, state ) }}

                            next_date_matches={this.props.state.leagueTournaments.selected_date?.next_date_matches}
                            handleViewMoreMatches={(next_url)=>{ this.props.loadDateMatches(null,next_url)}}
                        /> 
                    }
                    { this.state.is_player_information &&
                        <TournamentPlayersInformationComponent
                            team={ this.state.selected_team }
                            tournament={ this.props.state.leagueTournaments.tournament }
                            handleInscriptionInvitationModalActions={ (team, team_state) => this.handleInscriptionInvitationModalActions(team, team_state) }
                            edit_tournament={this.state.edit_tournament}
                            loadInfoFormModal={(form_id)=>{this.loadInfoFormModal(form_id)}}
                            handleTeamPlayerPaymentInfo={ (payment_info, currency) => this.handleTeamPlayerPaymentInfo(payment_info, currency) }
                            handleTransferPlayer={(player)=>{ this.handleTransferPlayer(player)}}
                            handleBackAction={() => {this.setState({is_player_information: false, preSelectedTab: this.props.state.leagueTournaments.tournament.state === 'Finalized'? 4 : 3 }); this.props.searchTournamentTeamsAdmin( this.state.selected_tournament.id, '', '') }}
                            handlePlayerInscription={(player_id, player_state, tournament_team_id) => {this.handlePlayerInscription(player_id, player_state, tournament_team_id)}} 
                            //handleOnShareInscriptionTournamentLinkClick ={ (tournament_team_id)=>{this.handleOnShareInscriptionTournament(tournament_team_id) } }
                            handleOnShareInscriptionTournamentLinkClick ={ (team_pagename)=>{this.handleOnShareTeamPageInscription(team_pagename) } }
                            full_response_team_members={this.props.state.leagueTournaments.tournament.full_response_team_members}
                            handleOnShareInvitedPlayerTournamentLinkClick={(tournament_team_id)=>{
                                this.handleOnShareInscriptionTournament(tournament_team_id) 
                                this.setState( {type_inscription_link_modal:"shareTournamentLink"} )
                            }}
                            handleEnrollRejectMultiple = { (tournament_team_id, players_states, player_states_filter)=>{ this.props.enrollRejectMultiplePlayers( tournament_team_id, players_states, player_states_filter ) } }
                            handleRejectMultiplePlayers = { (players_states)=>{ this.handleRejectMultiplePlayers( players_states ) } }
                            handleOnDownloadCardIds = { (array_team_id)=>{ this.loadCardIdsTeamInfo( array_team_id ) } }
                            handleOnDownloadPlayerCardId = { (tournament_player_id)=>{ this.downloadPlayerCardId( tournament_player_id ) } }
                            handleOnDownloadStaffCardId={ (team_profile_id, tournament_team_id)=> { this.downloadStaffCardId(team_profile_id, tournament_team_id)} }
                            next_tournament_team_players= { this.props.state.leagueTournaments.next_tournament_team_players}
                            handleViewMoreTeamPlayers={ ( next_team_players )=>{ this.loadMoreTeamPlayers( next_team_players )}}
                            handleOnSearchAdminTeamPlayers={( tournament_team_id, search, state )=>{ this.props.resetTournmentTeamPlayersAction(); this.props.searchAdminTournamentTeamPlayers(tournament_team_id, search, state)}}
                        />
                    }
                    
                    {this.state.start_tournament_component && !this.state.create_phase_component &&
                        <TournamentStartTournamentComponent
                            handleSearchWorldCities = {( searchedcity ) => this.props.searchCityByCountry( this.props.state.leagueTournaments.countryId, searchedcity )}
                            cities = {this.props.state.leagueTournaments?.world_cities}
                            countries =  { this.props.state.leagueTournaments?.countries}
                            handleSearchNationality={(new_nationality_search) => { this.props.getCountries( new_nationality_search) }}                            
                            disableCities = {this.props.state.leagueTournaments.countryId?false:true}
                            league_teams={this.props.state.leagues?.league_teams?.results}
                            tournament_teams={this.props.state.leagueTournaments?.tournament?.tournament_teams}
                            phase_teams={this.props.state.leagueTournaments?.tournament?.tournament_active_phase_teams}
                            handleOnAddTeamToTournament={ ( value ) => { this.handleOnAddTeamToTournament( value )} }
                            handleOnDeleteTeamFromTournament={ ( value ) => { this.handleOnDeleteTeamFromTournament( value )} }
                            sendInvitationToTeamTournament={(value)=>this.handleOnAddInviteTeamToTournament( value )}
                            league_members={this.props.state.leagues?.league_members?.results}
                            tournament_members={this.props.state.leagueTournaments?.tournament?.tournament_members}
                            handleOnAddMemberToTournament={ ( value ) => { this.handleOnAddMemberToTournament( value )} }
                            handleOnDeleteMemberFromTournament={ ( value ) => { this.handleOnDeleteMemberFromTournament( value )} }
                            onResetError={(data) => {this.props.changeTournamentBasicDataError(data)}}
                            errors={this.props.state.leagueTournaments.errors.tournament_basic_data}
                            league_page = {this.pagename}
                            createNewTournament = {(tournament_data) => {this.createNewTournament(tournament_data)}} 
                            tournament_dates={this.props.state.leagueTournaments?.tournament.tournament_dates}
                            handleNewDateTournamentClick={ ( ) => { this.handleOnNewTournamentDate() } }
                            selectedDate={this.props.state.leagueTournaments?.selected_date}
                            handleDateClick={ ( v ) => { this.handleOnTournamentDate( v ) } }
                            date_matches={this.props.state.leagueTournaments?.selected_date?.matches}  
                            deleteDate={(date,index,group) => this.handleDeleteDate(date,index,group)}
                            handleBackButton = { () => this.handleBackButton() } 
                            saveNewTournament = { () => this.saveNewTournament() }
                            viewMatchReport = { ( v ) => { this.viewMatchReport( v ) } }
                            handleEditMatchTournament = { ( v ) => { this.handleEditMatchTournament( v ) } }
                            handleNewMatchTournament = { ( v ) => { this.handleNewMatchTournament( v ) } }
                            handleDeleteMatchTournament = { ( v ) => { this.handleDeleteMatchTournament( v ) } }
                            disabled_edit_tournament_data = { this.state.disabled_edit_tournament_data }
                            enable_add_match_button = { this.state.enable_add_match_button }
                            handleOnFinishMatchActionClick = {  ( v ) => { /* this.handleMatchEnd( v ) */ this.handleOnfinishMatch( v ) } }
                            errorsNewGroupTournament={this.props.state.leagueTournaments.errors.new_tournament_groups}
                            tournament_phases={ this.props.state.leagueTournaments.tournament?.tournament_phases}
                            tournament={ this.props.state.leagueTournaments.tournament}
                            addTournamentPhase = {(id_tournament) => {this.handleOnAddTournamentPhase(id_tournament)}}
                            deleteTournamentPhase = {(phase, index) => this.handleDeleteTournamentPhase(phase, index)}
                            handleGroupClick={ ( v, tab_click ) => { this.handleOnGroupClick( v, tab_click ) } }
                            groups = { this.props.state.leagueTournaments?.tournament?.tournament_active_phase?.groups }
                            edit_tournament={false}
                            createNewGroupPhases = {(tournament_id, phase_id, teams_clasified_by_groups) => { this.props.createNewGroupsPhases(tournament_id, phase_id, teams_clasified_by_groups)}}                          
                            tournament_active_phase={ this.props.state.leagueTournaments?.tournament?.tournament_active_phase }
                            handleAddTeamToGroup = { (groupId, teamName) => this.props.addTeamToGroup(groupId, teamName, this.props.state.leagueTournaments.tournament.tournament_active_phase.id, this.state.teams_filter_type) }
                            //handleDeleteTeamFromGroup = { (groupId, teamName) => this.props.deleteTeamFromGroup(groupId, teamName) }
                            active_phase_groups_create = { this.props.state.leagueTournaments?.tournament?.active_phase_groups_create }
                            handleActivePhaseCreateState = { (new_state) => this.props.changeActivePhaseGroupsCreateStateAction(new_state) }
                            handleOnClickFinishPhase = {(phase) => {this.handleOnClickFinishPhase(phase)}}
                            is_loaded_tournament_phases={this.props.state.leagueTournaments.tournament.is_loaded_tournament_phases}
                            deleteTournamentPhaseWihtOutType = {(id_tournament, phase) => {this.props.deleteTournamentPhaseWihtOutType(id_tournament, phase)}}
                            handleLeavingPhaseTab = {() => {this.handleLeavingPhaseTab()}} 
                            finishedMatchesByPhase = {(phase) => { this.handleFinishedMatchesByPhase(phase) }}
                            unfinished_matches={this.props.state.leagueTournaments.tournament.unfinished_tournament_matches}
                            phase_name_values = { this.props.state.leagueTournaments.phase_name_values}
                            tournament_gender = {this.state.tournament_gender}
                            tournament_tag = {this.state.tournament_tag}
                            handleSearchBaloaTeam = { (searched_team) => { this.props.searchBaloaTeam(searched_team) } }
                            baloaTeams = { this.props.state.leagueTournaments.baloa_teams }
                            handleCleanBaloaTeamsSearch = { ( )=> { this.handleCleanBaloaTeamsSearch()}}
                            handleOnAutomaticFixture = { () => {this.handleOnAutomaticFixture() } }                  
                            fixture_button_active = { this.state.fixture_button_active }
                            setTab = {this.state.tabAfterFixture}
                            cleanTabAfterFixture = { ()=> { this.cleanTabAfterFixture() } }
                            pageRoles = { this.props.state.page.page.roles }
                            inscription_errors={this.props.state.leagueTournaments.errors.new_tournament_inscription}
                            tournament_football_type = { this.state.football_type }
                            handleOnFinishCreateNewTournament={ () => this.handleOnFinishCreateNewTournament() }
                            handleCalendarStep={ () => this.handleCalendarStep() }
                            handleStartTournamentBackBtn={ () => this.handleStartTournamentBackBtn() }
                            tournament_criteria_tie_breaking = { this.props.state.leagueTournaments.tournament.tournament_criteria_phase }
                            tournament_new_order_criteria_tie_breaking = {(new_order) => this.props.setNewCriteriaTournamentPhase(this.props.state.leagueTournaments.tournament.tournament_active_phase.id,new_order)}
                            handleBestThirdByGroup={(status, groupId) => this.handleBestThirdByGroup(status, groupId)}
                            loadTieBreakerByPhase={(phaseId) => this.handleLoadTieBreakerByPhase(phaseId)}
                            handleViewTournament = { ( tournament )=>{ this.handleOnViewTournament(tournament) } } 
                            handle_team_filter= {(phaseId, type_filter) => this.handleTeamsFilterinPhases(phaseId, type_filter)}    
                            filter_phase_teams={this.props.state.leagueTournaments?.tournament?.tournament_filter_phase_teams}
                            handleOnClickNewPhase={(state, clickSource) => this.handleOnClickNewPhase(state, clickSource)}      
                            
                            //To phases container
                            tournament_setup_teams={this.props.state.leagueTournaments?.tournament?.tournament_setup_teams}
                            handleReturnPhaseComponent={(state)=>this.handleReturnPhaseComponent(state)}
                            handleDeleteTeamFromGroup={ (teamName,groupId) => this.handleModalStateForPhases(teamName, 'team_delete_validation', groupId)}
                            deleteNewPhase={ (id_tournament, phase) => {this.props.deleteTournamentPhaseWihtOutType(id_tournament, phase)} }
                            handleDeleteGroupPhase={(groupId) => this.handleDeleteGroupPhase(groupId)}
                            handleEditPhase={(phaseData)=> this.handleModalStateForPhases(phaseData, 'phase_edit_status')}
                            handleOnEditPhaseModal={(edit_phase_type, group, isCreatePhase) => { this.handleOnEditPhaseModal(edit_phase_type, group, isCreatePhase) }}
                            handleOnPhaseValidation={(phaseType) => this.handleOnEditPhaseAction(phaseType)}
                            handleNumberClassified={(id_group, classified_count)=>this.handleNumberClassified(id_group, classified_count)}
                            handleCleanAllGroups={(phaseId) => {
                                this.setState({teams_filter_type: 'not_assigned'});
                                this.props.cleanAllGroupOfPhase(phaseId, this.props.state.leagueTournaments.tournament?.id);
                            }}
                            handleCreateAutomaticGroupPhases={(phaseId, filter_state)=> this.props.createAutomaticGroupPhases(phaseId, this.props.state.leagueTournaments.tournament?.id, filter_state)}
                            handleDeleteTournamentPhase={(phase, index) => this.handleModalStateForPhases(phase, 'phases_delete_validation', index)}
                            click_source_create_phase={this.state.click_source_create_phase}
                            deleteTeamFromCreatePhase={(team, teamname, phase_id) => {this.props.deleteTeamFromGroup(team, teamname, phase_id, this.state.teams_filter_type)}}
                            loadTournamentPhaseTeams={(phaseId, stateTeams)=> this.props.loadTournamentPhaseTeams(phaseId, stateTeams)}
                            handleOnClickPhaseTab={ ( phase, typeAction) => this.handleOnClickPhaseTab( phase, typeAction ) }
                            handleOnAddAllTeamsToGroup={(group_id, state) => {this.props.addAllTeamsToGroup(group_id, state, this.props.state.leagueTournaments.tournament?.id, this.props.state.leagueTournaments.tournament.tournament_active_phase?.id)}}
                            handleOnCreateFairPlaySettings = { ()=>{ this.handleCreateFairPlaySettings() } }
                            handleOnEditFairPlaySettings = { ()=>{ this.handleEditFairPlaySettings() } }
                            handleOnChangeSetting={ ()=>{ this.handleChangeFairPlaySetting() } }
                            selected_fair_play_setting={this.props.state.leagueTournaments?.selected_fair_play_setting}
                        />
                    }
                    </div>{/** END center-container */}
                    <TournamentEditFormComponent
                        isOpen={ this.state.state_edit_form_modal }
                        modal_type_edit_form={this.state.modal_type_edit_form}
                        onClose={ () => this.handleOnCloseEditFormModal() }
                        sendToEditFormTournament = { (form, form_type) => this.handleSendToEditFormTournament(form, form_type) }
                        selected_tournament ={this.state.selected_tournament}
                        // review form created
                        modal_confirmation_back_edit={this.state.modal_confirmation_back_edit}
                        handlebackToSummaryTournament={()=> this.handlebackToSummaryTournament()}
                        />
                    </React.Fragment>
                    }
                    {this.props.state.leagueTournaments.start_cards_id_component &&
                        /*show cards id component in all page*/
                        <TournamentCardIdCreatorComponent
                            saveCard={(cardInfo, emptyCardIdQuestions)=>{this.handleSaveCardId(cardInfo, emptyCardIdQuestions, this.props.state.leagueTournaments.tournament?.card_id_form_info)}}
                            carnet_id_template={this.prepareCardIdCreatorData(this.props.state.leagueTournaments.tournament?.carnet_id_template)}
                            orientation={this.props.state.leagueTournaments.tournament?.card_id_orientation}
                            background_image={this.GetCardIdBackgroundImage(this.props.state.leagueTournaments.tournament?.card_id_form_info)}
                            cardInfo={this.state.cardInfo}
                            handleBackWindowButton={() => { this.props.changeCardIdExitValidationModalStateAction( true ) }} 
                        />
                    }      
                </div>{/** END view */}

            </React.Fragment>
        )
    }

}

const mapStateToProps = state => {
    return {
        state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadPage: ( username ) => {
            dispatch( loadPage( username ) )
        },
        loadTournament: async ( id_tournament ) => {   
            await dispatch( loadTournamentInfo(  id_tournament ) )
            //dispatch( loadTournamentDates(  id_tournament ) )
            dispatch( loadTournamentTeams(  id_tournament, '', '' ) )
            //dispatch( loadTournamentMembers(  id_tournament ) )//optimizacion de apis (api anterior)
            //dispatch( loadTournamentResultsTable(  id_tournament ) )
            dispatch( loadTournamentPhases(  id_tournament ) ) 
            //dispatch( loadPhaseResultsTable(  "id_tournament" ) )                        
            //loadPhases     
            dispatch( loadTournamentStaff(  id_tournament ) )        
        },    
        loadLeagueMembers: ( league_name ) => {
            dispatch( loadLeagueMembers( league_name ) )
        },
        loadMatchReport: async ( match_id ) => {
            await dispatch( loadMatchReport( match_id ) )
        },
        loadLeagueTeams: ( league_name ) => {
            dispatch( loadLeagueTeams( league_name ) )
        },  
        /*loadTournamentDates: ( id_tournament ) => {
            dispatch( loadTournamentDates(  id_tournament ) )
        },*/
        loadTournamentPhaseGroupDates: async ( id_group ) => {
            // console.log("Fechas");
            await dispatch( loadTournamentPhaseGroupDates( id_group ) )
        },
        /*addDateToTournament: ( id_tournament, date_name ) => {
            dispatch( addDateToTournament(  id_tournament, date_name ) )
        },*/
        addDateToTournamentGroup: ( id_tournament_group, date_name ) => {
            dispatch( addDateToTournamentGroup(  id_tournament_group, date_name ) )
        },
        setSelectedTournamentDate: ( active_date) => {
            dispatch( setSelectedTournamentDateAction(  active_date ) )
        },
        loadDateMatches: async ( id_date, next_url ) => {
            await dispatch( loadDateMatches( id_date, next_url ) )
        },
        changeTournamentBasicDataError: ( data ) => {
            dispatch( changeTournamentBasicDataError( data ))
        },
        createNewTournament: async ( tournament_data,league_pagename, ids_forms ) => {
            await dispatch( createNewTournament( tournament_data, league_pagename, ids_forms ))
        },
        createNewForm: (form, formID,form_type, tournamentId) =>{
            dispatch( createNewForm( form, formID, form_type, tournamentId ))
        },
        createNewGroupsPhases: ( tournament_id, phase_id, teams_clasified_by_groups ) => {     
            dispatch( createNewGroupsPhases( tournament_id, phase_id, teams_clasified_by_groups ) )
        },
        changeNewMatchModalStateAction: ( new_state ) => {
            dispatch( changeNewMatchModalStateAction( new_state ))
        },
        changeNewMatchError: ( data ) => {
            dispatch( changeNewMatchError( data ))
        },
        NewMatch: ( tournament_date, new_tournament, phase ) => {
                dispatch( NewMatch( tournament_date, new_tournament, phase ))
        },
        changeEndMatchModalStateAction: ( new_state ) => {
            dispatch( changeEndMatchModalStateAction( new_state ))
        },
        changeWarningLeagueWithNoTeamsModalStateAction: ( new_state ) => {
            dispatch( changeWarningLeagueWithNoTeamsModalStateAction( new_state ))
        },        
        changeEndMatchError: ( data ) => {
            dispatch( changeEndMatchError( data ))
        },
        loadLeagueTournaments: ( league_name ) => {
            dispatch( loadLeagueTournaments(  league_name ) )
        },
        addMemberToTournament: ( id_tournament, member_username ) => {
            dispatch( addMemberToTournament(  id_tournament, member_username ) )
        },
        deleteMemberFromTournament:( id_member_staff ) => {
            dispatch( deleteMemberFromTournament( id_member_staff ))
        },
        addTeamToTournament: async ( id_tournament, team_name, team_state ) => {
            await dispatch( addTeamToTournament( id_tournament, team_name, team_state ))
        },
        addInviteTeamToTournament: (id_tournament_team, state_invite_team)=>{
            dispatch(addInviteTeamToTournament( id_tournament_team, state_invite_team))
        },
        deleteTeamToTournament:( id_tournament_team, id_tournament ) => {
            dispatch( deleteTeamToTournament( id_tournament_team, id_tournament ))
        },     
        disableTeamFromTournament:( id_tournament_team, id_tournament ) => {
            dispatch( disableTeamFromTournament( id_tournament_team, id_tournament ))
        },
        resetTournament: ( data ) => {
            //dispatch( resetTournamentAction( data ))
            dispatch( resetTournamentMembersAction( data ));
            dispatch( resetTournamentTeamsAction( data ));
            dispatch( resetTournamentActivePhaseAction( data ));
            dispatch( resetTournamentPhaseTeamsAction( data ));
            dispatch( resetTournamentDatesAction( data ));
            dispatch( resetTournamentMatchesAction( data ));  
            dispatch( resetTournamentPhasesAction( data ));
            dispatch( resetTournamentCountryCitiesAction( data ));
            dispatch(changeUnfinishedTournamentMatchesStateAction( false ));
            dispatch( setSelectedFairPlayPhaseAction( null ));
        },        
        
        resetTournamentPhase: ( data ) => {
            dispatch( resetTournamentActivePhaseAction( data ));
            dispatch( resetTournamentPhaseTeamsAction( data ));
            dispatch( resetTournamentDatesAction( data ));
            dispatch( resetTournamentSelectedDateAction( data ));
            dispatch(changeUnfinishedTournamentMatchesStateAction( false ))          
        },
        resetTournamentGroup: async ( data ) => {
            await dispatch( resetTournamentDatesAction( data ));
            await dispatch( resetTournamentSelectedDateAction( data ));                       
        },        
        finishTournament: ( id_tournament, tournament_data, page ) => {
            dispatch( finishTournament( id_tournament, tournament_data, page ))
        },
        changeEndTournamentModalStateAction:( new_state ) => {
            dispatch( changeEndTournamentModalStateAction( new_state ))
        },
        changeEndTournamnetError: ( data ) => {
            dispatch( changeEndTournamnetError( data ))
        },
        changeEditMatchModalState: (isOpen) => {
            dispatch( changeEditMatchModalStateAction(isOpen) )
        },
        deleteMatchGame: (match_id, selected_date_id, phase) => {
            dispatch( deleteMatchGame(match_id, selected_date_id, phase) )
        },
        setMatchDataAction: ( match_data ) => {
            dispatch( setMatchDataAction( match_data ))
        },
        loadLocalTeamPageMembers: ( local_page_name ) => {
            dispatch( loadLocalTeamPageMembers( local_page_name ))
        },
        loadVisitorTeamPageMembers: ( visitor_page_name ) => {
            dispatch( loadVisitorTeamPageMembers( visitor_page_name ))
        },        
        loadLocalTeamPageData: ( local_page_name ) => {
            dispatch( loadLocalTeamPageData( local_page_name ))
        },
        loadVisitorTeamPageData: ( visitor_page_name ) => {
            dispatch( loadVisitorTeamPageData( visitor_page_name ))
        },
        setMatchScore:( match_id, score ) => {
            dispatch( setMatchScore( match_id, score ))
        },
        setMatchScorers:( match_id, scorers ) => {
            dispatch( setMatchScorers( match_id, scorers ))
        },
        setMatchChanges:( match_id, changes ) => {
            dispatch( setMatchChanges( match_id, changes ))
        },
        setMatchPlayersCards:( match_id, cards ) => {
            dispatch( setMatchPlayersCards( match_id, cards ))
        },     
        editMatch:( id_match, match, tournament_date ) => {
            dispatch( editMatch( id_match, match, tournament_date ))
        },    
        setMatchComments:( match_id, comments, date_id, phase ) => {
            dispatch( setMatchComments( match_id, comments, date_id, phase ))
        },
        seeTournamentFromMemberOrTeamModal: (new_state) => {
            return dispatch( seeTournamentFromMemberOrTeamModalAction(new_state) )
        },
        searchWorldCities:( searched_city)  => {
            dispatch( searchWorldCities( searched_city ) )
        },
        loadTournamentPlayers: async ( tournament_id)  => {
            // console.log("cargando jugadores...");
            await dispatch( loadTournamentPlayers( tournament_id ) )
        },
        addTeamToGroup:( groupId, teamName, phaseId, teams_filter_type, tournament_active_phase_teams_modal ) => {
            dispatch( addTeamToGroup( groupId, teamName, phaseId, teams_filter_type, tournament_active_phase_teams_modal ) );
        },
        deleteTeamFromGroup:( groupId, teamName, phaseId, teams_filter_type, tournament_active_phase_teams_modal ) => {
            dispatch( deleteTeamFromGroup( groupId, teamName, phaseId, teams_filter_type, tournament_active_phase_teams_modal ) )
        },
        changeActivePhaseGroupsCreateStateAction:( new_state ) => {
            dispatch( changeActivePhaseGroupsCreateStateAction( new_state ))
        },
        loadTournamentPhases:( tournament_id ) => {
            dispatch( loadTournamentPhases( tournament_id ) )
        },
        setTournamentActivePhaseAction:( phase ) => {
            dispatch( setTournamentActivePhaseAction( phase ))
        },
        loadTournamentPhaseTeams: async (phaseId, stateTeams) =>{
            await dispatch( loadTournamentPhaseTeams( phaseId, stateTeams ))
        },
        setTournamentActivePhaseEditAction:( phaseId ) => {
            dispatch( setTournamentActivePhaseEditAction( phaseId ))
            dispatch( loadTournamentPhaseTeams( phaseId, 'not_assigned' )) //REVIEW:This action is not called 
        },
        loadTournamentPhaseFilterTeams:(phaseId, filterType, tournament_active_phase_teams_modal)=>{
            dispatch(loadTournamentPhaseFilterTeams(phaseId, filterType, tournament_active_phase_teams_modal))
        },
        UpdateBestThirdToGroup:(status, groupId, team, original_Id)=>{
            dispatch(UpdateBestThirdToGroup(status, groupId, team, original_Id ))
        },
        loadCriteriaTournamentPhase: (phaseId) =>{
            dispatch(loadCriteriaTournamentPhase(phaseId))
        },
        EditPhaseById: (phaseData, phasesId) =>{
            dispatch(EditPhaseById(phaseData, phasesId))
        },
        addTournamentPhase: (id_tournament) => {
            dispatch( addTournamentPhase(id_tournament))
        },
        loadPhaseResultsTable: async (id_phase) => {
            await dispatch( loadPhaseResultsTable(id_phase))
        },
        // finishTournamentPhase: (id_phase) => {
        //     dispatch(finishTournamentPhase(id_phase))
        // },
        deleteTournamentPhase: (id_tournament, phase) => {
            //console.log('fase enviada para eliminar',phase);
            dispatch(deleteTournamentPhase(id_tournament, phase))
        },        
        deleteTournamentPhaseWihtOutType: async (id_tournament, phase) => {
            await dispatch(deleteTournamentPhaseWihtOutType(id_tournament, phase))
        },        
        changeMatchsToCloseModalStateAction: (new_state) => {
            dispatch( changeMatchsToCloseModalStateAction(new_state))
        },
        changeWarningLeavingPhaseStateAction: (new_state) => {
            dispatch( changeWarningLeavingPhaseStateAction(new_state))
        },
        changeTeamsDisabledDialogModalStateAction: (new_state) => {
            dispatch( changeTeamsDisabledDialogModalStateAction(new_state))
        },
        changeFinishFreePhaseModalState: ( new_state ) => {
            dispatch( changeFinishFreePhaseModalStateAction(new_state))
        },
        changeFinishGroupsPhaseModalState: ( new_state ) => {
            dispatch(  changeFinishGroupsPhaseModalStateAction(new_state))
        },
        classifiedTeamsByPhase: async  (phase) => {
            await dispatch( classifiedTeamsByPhase(phase))
        },
        finishedMatchesByPhase: (phase) => {
            dispatch( finishedMatchesByPhase(phase))
        },
        changeTournamentSaveTournamentModalStateAction: ( new_state ) => {
            dispatch( changeTournamentSaveTournamentModalStateAction( new_state ))
        },
        changeErrorMessageSnackBar: ( new_state ) => {
            return dispatch( changeErrorMessageSnackBar( new_state ) );
        }, 
        changeAddTeamToGroupModalStateAction: ( new_state ) => {
            dispatch(changeAddTeamToGroupModalStateAction(new_state))
        },
        changeRemoveMultiplePhasesModalStateAction: (new_state) => {
            dispatch(changeRemoveMultiplePhasesModalStateAction(new_state))
        },
        changeUnfinishedTournamentMatchesStateAction: ( new_state ) => {
            return dispatch( changeUnfinishedTournamentMatchesStateAction( new_state ) )
        },
        loadScorerTeamPageMembers: ( team_name ) => {
            return dispatch( loadScorerTeamPageMembers( team_name ) )
        },
        loadBeatenFenceTeamPageMembers: ( team_name ) => {
            return dispatch( loadBeatenFenceTeamPageMembers( team_name ) )
        },
        loadChampionTeamPageMembers: ( team_name ) => {
            return dispatch( loadChampionTeamPageMembers( team_name ) )
        },
        getCountries: async (country) => {
            await dispatch(getCountries(country));
        },        
        searchCityByCountry: async (countryId, searchedCity) => {
            await dispatch(searchCityByCountry(countryId, searchedCity));
        },
        deleteDate: ( date,index,group ) => {
            dispatch( deleteDate( date,index,group ))
        },
        changeDeleteDateModalStateAction: ( new_state ) => {
            dispatch( changeDeleteDateModalStateAction( new_state ))
        },
        changeCloseMatchModalStateAction: ( new_state ) => {
            dispatch( changeCloseMatchModalStateAction( new_state ))
        },
        changeTournamentFinalizedSuggestionModalStateAction: ( new_state ) => {
            dispatch( changeTournamentFinalizedSuggestionModalStateAction( new_state ))
        },
        updateTournamentBasicInfo: ( tournament_data, tournament_id ) => {
            dispatch( updateTournamentBasicInfo( tournament_data, tournament_id ))
        },
        searchBaloaTeam: (searched_team) => {
            dispatch(searchBaloaTeam(searched_team));
        },
        searchBaloaTeamToInvited: (searched_team) => {
            dispatch(searchBaloaTeamToInvited(searched_team));
        },
        cleanBaloaTeamsSearchAction: (data) => {
            dispatch(cleanBaloaTeamsSearchAction(data));
        },
        setFinalFixture: async (phase_id, fixture_shuffle, roundtrip) => {
            await dispatch(setFinalFixture(phase_id, fixture_shuffle, roundtrip));
        },
        changeRoundtripFixtureModalStateAction: ( new_state ) => {
            dispatch( changeRoundtripFixtureModalStateAction( new_state ))
        },
        searchBaloaMember: async (member) => {
            await dispatch(searchBaloaMember(member));
        },
        getOfficialRefereeMember: async (official_referee) =>{
            await dispatch(getOfficialRefereeMember (official_referee))
        },
        getSecondRefereeMember: async (second_referee) => {
            await dispatch(getSecondRefereeMember (second_referee))
        },
        getThirdRefereeMember: async (third_referee) => {
            await dispatch(getThirdRefereeMember (third_referee))
        },
        getFourthRefereeMember: async (fourth_referee) => {
            await dispatch(getFourthRefereeMember (fourth_referee))
        },
        getRefereeAssignerMember: async (referee_assigner) => {
            await dispatch(getRefereeAssignerMember (referee_assigner))
        },
        cleanRefereesMemberAction: (data) => {
            dispatch(cleanRefereesMemberAction(data));
        },
        changeAddTeamAdviceModalStateAction: ( new_state ) => {
            dispatch( changeAddTeamAdviceModalStateAction( new_state ))
        },
        addEventAction: ( data ) => {
            return dispatch( addEventAction( data ) )
        },
        getTeamPlayers: ( teamname ) => {
            dispatch( getTeamPlayers( teamname ) );
        },
        loadTeamData: ( teamname ) => {
            dispatch( loadTeamData( teamname ) );
        },
        followProfile: (profile) => {
            return dispatch( followProfile(profile) );
        },
        unfollowProfile: (profile) => {
            return dispatch( unfollowProfile(profile) );
        },
        changeTeamDetailModalStateAction: ( new_state ) => {
            dispatch( changeTeamDetailModalStateAction( new_state ) );
        },
        changeStartTournamentModalStateAction: ( new_state ) => {
            dispatch( changeStartTournamentModalStateAction( new_state ) );
        },
        loadfilterTournament: ( league_pagename, type_filter ) => {
            dispatch(loadfilterTournament( league_pagename, type_filter ));
        },
        changeInviteTeamToTournamentModalStateAction: ( new_state ) => {
            dispatch( changeInviteTeamToTournamentModalStateAction( new_state ) );
        },
        changeTeamInscriptionModalStateAction: ( new_state ) => {
            dispatch( changeTeamInscriptionModalStateAction( new_state ) );
        },
        changeTournamentTeamState: ( tournament_team_id, team_state ) => {
            dispatch( changeTournamentTeamState( tournament_team_id, team_state ) );
        },
        changeInvitationInscriptionActionsModalStateAction: ( new_state ) => {
            dispatch( changeInvitationInscriptionActionsModalStateAction( new_state ));
        },
        changeTournamentState: ( tournament_id, tournament_state ) => {
            dispatch( changeTournamentState( tournament_id, tournament_state) );
        },
        loadTournamentTeamPlayers: ( tournament_team_id, state, next_page ) => {
            dispatch( loadTournamentTeamPlayers( tournament_team_id, state, next_page ) );
        },
        changeTeamPlayerState: ( player_id, state, tournament_team_id ) => {
            dispatch( changeTeamPlayerState( player_id, state, tournament_team_id ) );
        },
        loadTournamentSetupTeams: ( tournament_id, team_state ) => {
            dispatch( loadTournamentSetupTeams( tournament_id, team_state ) );
        },
        loadFormData: (form_id) => {
            dispatch( loadFormData( form_id ) );
        },
        changeInscriptionViewFormDataModalStateAction: ( new_state ) => {
            dispatch( changeInscriptionViewFormDataModalStateAction( new_state ) );
        },
        changePaymentInformationModalStateAction: ( new_state ) => {
            dispatch( changePaymentInformationModalStateAction( new_state ) );
        },
        setNewCriteriaTournamentPhase: ( id, array ) => {
            dispatch( setNewCriteriaTournamentPhase( id, array ) );
        },
        changeTournamentDynamicFormsModalStateAction: ( new_state ) => {
            dispatch( changeTournamentDynamicFormsModalStateAction( new_state ) )
        },
        getFieldsStatusEditing: async (tournament_id)=> {
            await dispatch( getFieldsStatusEditing( tournament_id ))
        },
        changeMobileMenuStateAction: ( new_state ) => {
            dispatch( changeMobileMenuStateAction( new_state ) );
        },
        disableNewTournamentSubmitButtonAction: ( new_state ) => {
            dispatch( disableNewTournamentSubmitButtonAction( new_state ) );
        },
        changeTournamentStartInfoModalStateAction: ( new_state ) => {
            dispatch( changeTournamentStartInfoModalStateAction( new_state ) );
        },
        changeTournamentTranferPlayerModalStateAction: ( new_state ) => {
            dispatch( changeTournamentTranferPlayerModalStateAction( new_state ) );
        },
        transferTeamPlayer: ( tournament_player_id, teamname, tournament_id) => {
            dispatch( transferTeamPlayer(tournament_player_id, teamname, tournament_id) );
        },
        transferTeamKnockout: (win_team, lost_team, group_id)=>{
            dispatch(transferTeamKnockout(win_team, lost_team, group_id));
        },
        GetCodeFormTemplate: () =>{
            dispatch(GetCodeFormTemplate());
        },
        showCopyPostMessage: (copymessage) => {
            return dispatch( changeErrorMessageSnackBar( {
                message: copymessage,
                error_message_is_open: true
            } ) );
        },
        setTournamentActivePhase:(phase_id)=>{
            dispatch(setTournamentActivePhase(phase_id))
        },
        changeShareInscriptionLinkModalStateAction: ( new_state ) => {
            return dispatch( changeShareInscriptionLinkModalStateAction( new_state ) )
        },
        changeShareIframeLinkStateAction: ( new_state ) => {
            return dispatch( changeShareIframeLinkStateAction( new_state ) )
        },
        resetMatchData: ( data ) => {
            dispatch( resetMatchDataAction( data ));
            dispatch( resetLocalTeamPageMembersAction( data ));
            dispatch( resetVisitorTeamPageMembersAction( data ));
            dispatch( resetLocalTeamPageDataAction( data ));
            dispatch( resetVisitorTeamPageDataAction( data ));
        },
        changeAddStaffModalStateAction: ( new_state ) => {
            dispatch( changeAddStaffModalStateAction( new_state ) )
        },
        searchBaloaUser: async (member) => {
            await dispatch( searchBaloaUser(member) );
        },
        addUserToStaff: (username, role, staff_state, tournament_id) => {
            dispatch( addUserToStaff(username, role, staff_state, tournament_id) );
        },
        updateStaffState: (role_id, role_state, role_type) => {
            dispatch( updateStaffState(role_id, role_state, role_type) );
        },
        deleteStaff: (role_id) => {
            dispatch( deleteStaff(role_id) );
        },
        cleanBaloaUserAction: (data) => {
            dispatch(cleanBaloaUserAction(data));
        },
        loadTournamentPhaseInterGroupsTeams:(phaseId, teams_state)=>{
            dispatch(loadTournamentPhaseInterGroupsTeams(phaseId, teams_state));
        },
        loadRefeeringPaymentUrl: async ( payment_data, match_id ) => {
            await dispatch( loadRefeeringPaymentUrl(payment_data, match_id) );
        },
        assignRefereesMatch: ( match_id, referees_match_data, match_date_id ) => {
            dispatch( assignRefereesMatch( match_id, referees_match_data, match_date_id ))
        },
        makeRefereePayment: ( match_id ) => {
            dispatch( makeRefereePayment( match_id ) );
        },
        calculateRefereeFee: (data) => {
            dispatch( calculateRefereeFee(data) );
        },
        resetRefereeFeeAction: (new_value) => {
            dispatch(resetRefereeFeeAction(new_value));
        },
        loadPhaseTeamsNotAssigned: (phaseId, teams_state)=>{
            dispatch(loadPhaseTeamsNotAssigned(phaseId, teams_state))
        },
        downloadFormData: (tournament_id)=>{
            dispatch(downloadFormData(tournament_id))
        },
        downloadPaymentData: (tournament_id)=>{
            dispatch(downloadPaymentData(tournament_id))
        },
        changeEditPhaseModalStateAction: (new_state) => {
            dispatch( changeEditPhaseModalStateAction(new_state) );
        },
        changeEditPhaseValidationModalStateAction: (new_state) => {
            dispatch( changeEditPhaseValidationModalStateAction(new_state) );
        },
        updateClassifiedCountGroup: (id_group, classified_count, id_phase, id_tournament) => {
            dispatch( updateClassifiedCountGroup(id_group, classified_count, id_phase, id_tournament) );
        },
        createTournamentPhaseGroup: async (id_phase, id_tournament) => {
            await dispatch( createTournamentPhaseGroup(id_phase, id_tournament) );
        },
        deleteGroupFromPhase: (id_group, id_phase, id_tournament, teams_filter_type, tournament_active_phase_teams_modal) => {
            dispatch( deleteGroupFromPhase(id_group, id_phase, id_tournament, teams_filter_type, tournament_active_phase_teams_modal) );
        },
        cleanAllGroupOfPhase: (id_phase, id_tournament, tournament_active_phase_teams_modal) => {
            dispatch( cleanAllGroupOfPhase(id_phase, id_tournament, tournament_active_phase_teams_modal) );
        },
        createAutomaticGroupPhases: (id_phase, id_tournament, filter_state, tournament_active_phase_teams_modal) => {
            dispatch( createAutomaticGroupPhases(id_phase, id_tournament, filter_state, tournament_active_phase_teams_modal) );
        },
        resetTournamentMatchesAction: (data) => {
            dispatch( resetTournamentMatchesAction( data ));
        },
        endTournamentPhase: (id_phase, tournament_id) => {
            dispatch( endTournamentPhase(id_phase, tournament_id) );
        },
        addAllTeamsToGroup: (id_group, state, tournament_id, phase_id) => {
            dispatch( addAllTeamsToGroup(id_group, state, tournament_id, phase_id) );
        },
        loadMemberPositionsData: ( ) => {
            dispatch( loadMemberPositionsData() );
        },
        loadTeamsToMatchAssistanceAligment: (matchId)=>{
            dispatch(loadTeamsToMatchAssistanceAligment(matchId))
        },
        updatePlayersAssistanceAligment: (matchId, playerArray) => {
            dispatch(updatePlayersAssistanceAligment (matchId, playerArray))
        },
        resetTeamsToMatchAssistanceAligment:()=>{
            dispatch(resetTeamsToMatchAssistanceAligment())
        },
        loadSearchRunnerUpTeamPlayers: ( team_name ) => {
            return dispatch( loadSearchRunnerUpTeamPlayers( team_name ) )
        },
        loadSearchThirdPlaceTeamPlayers: ( team_name ) => {
            return dispatch( loadSearchThirdPlaceTeamPlayers( team_name ) )
        },
        loadSearchTournamentPlayerTeamPlayers: ( team_name ) => {
            return dispatch( loadSearchTournamentPlayerTeamPlayers( team_name ) )
        },
        loadSearchRevelationTeamPlayers: ( team_name ) => {
            return dispatch( loadSearchRevelationTeamPlayers( team_name ) )
        },
        enrollRejectMultiplePlayers: ( tournament_team_id, players_state, player_states_filter ) => {
            return dispatch( enrollRejectMultiplePlayers( tournament_team_id, players_state, player_states_filter ) )
        },
        changeRejectMultiplePlayerModalStateAction: ( new_state ) => {
            return dispatch( changeRejectMultiplePlayerModalStateAction( new_state ) )
        },
        // loadFormsAction: ( new_state ) => {
        //     return dispatch( loadFormsAction( new_state ) )
        // },
        loadTournamentPlayerFormTemplate:( form_type ,id_tournament ) => {
            dispatch( loadTournamentPlayerFormTemplate( form_type ,id_tournament ))
        }, 
        loadListFormExisting: ( page_name,form_type ) => {
            return dispatch( loadListFormExisting( page_name,form_type ) )
        },
        loadPageFormTemplate: ( id_form_template ) => {
            return dispatch( loadPageFormTemplate( id_form_template ) )
        },
        cleanPageFormTemplateAction: () => {
            return dispatch( cleanPageFormTemplateAction( {} ) )
        },
        createCarnetFormTemplate: (form, formID,form_type, tournamentId, imageBase64, orientation) =>{
            return dispatch( createCarnetFormTemplate( form, formID, form_type, tournamentId, imageBase64, orientation ))
        },
        saveCardIdFormInfo:( cardInfo ) => {
            dispatch( saveCardIdFormInfo( cardInfo ))
        },
        saveCardIdFormInfoAndClean:( cardInfo ) => {
            dispatch( saveCardIdFormInfoAndClean( cardInfo ))
        },
        changeCardIdValidationModalStateAction:( new_state ) => {
            dispatch( changeCardIdValidationModalStateAction( new_state ))
        },
        loadCardIdTemplate:( id_tournament ) => {
            dispatch( loadCardIdTemplate( 'tournament_player_card', id_tournament ))
        },
        deleteCardIdTemplate:( id_tournament ) => {
            dispatch( deleteCardIdTemplate( id_tournament ))
        },
        changeCardIdDeleteValidationModalStateAction:( new_state ) => {
            dispatch( changeCardIdDeleteValidationModalStateAction( new_state ))
        },
        changeCardIdTeamsSelectModalStateAction:( new_state ) => {
            dispatch( changeCardIdTeamsSelectModalStateAction( new_state ))
        },
        loadCardIdsData: async( tournament_id, teams_list ) => {
            await dispatch( loadCardIdsData( tournament_id, teams_list ))
        },
        changeCardIdPdfGeneratorModalStateAction:( new_state ) => {
            dispatch( changeCardIdPdfGeneratorModalStateAction( new_state ))
        },
        loadPlayerCardIdData:( tournament_player_id ) => {
            dispatch( loadPlayerCardIdData( tournament_player_id ))
        },
        changeCardIdExitValidationModalStateAction:( new_state ) => {
            dispatch( changeCardIdExitValidationModalStateAction( new_state ))
        },
        changeCardIdComponentStateAction:( new_state ) => {
            dispatch( changeCardIdComponentStateAction( new_state ))
        },
        loadTeamManagerAndStaff: ( team_name, tournament_id ) => {
            dispatch( loadTeamManagerAndStaff( team_name, tournament_id ) );
        },
        loadStaffCardIdData:( team_profile_id, tournament_team_id ) => {
            dispatch( loadStaffCardIdData( team_profile_id, tournament_team_id ))
        },
        changeCreateFieldDataError: ( data ) => {
            dispatch( changeCreateFieldDataError( data ))
        },
        changeFieldsAdminModalStateAction:( new_state ) => {
            dispatch( changeFieldsAdminModalStateAction( new_state ))
        },
        changeFieldsCreateModalStateAction:( new_state ) => {
            dispatch( changeFieldsCreateModalStateAction( new_state ))
        },
        createPageFields: ( form_data, page_name ) => {
            dispatch( createPageFields( form_data, page_name ))
        },
        loadPageFieldsData: ( page_name ) => {
            dispatch( loadPageFieldsData( page_name ))
        },
        editPageFields: async ( form_data, field_id, page_name ) => {
            await dispatch( editPageFields( form_data, field_id, page_name ))
        },
        changeFieldsWarningModalStateAction:( new_state ) => {
            dispatch( changeFieldsWarningModalStateAction( new_state ))
        },
        deletePageField: async ( field_id ) => {
            await dispatch( deletePageField( field_id ))
        },
        changeTournamentFieldsAdminModalStateAction: ( new_state ) => {
            dispatch( changeTournamentFieldsAdminModalStateAction( new_state ))
        },
        createTournamentFields: ( field, tournament_id ) => {
            dispatch( createTournamentFields( field, tournament_id ) )
        },
        loadTournamentFieldsData: async ( tournament_id ) => {
            await dispatch( loadTournamentFieldsData( tournament_id ) )
        },
        changeTournamentFieldsWarningModalStateAction:( new_state ) => {
            dispatch( changeTournamentFieldsWarningModalStateAction( new_state ))
        },
        deleteTournamentField: ( tournament_field_id, tournament_id, fixture ) => {
            dispatch( deleteTournamentField( tournament_field_id, tournament_id, fixture ) )
        },
        changeTournamentAddExistingFieldsStateAction: ( new_state ) => {
            dispatch( changeTournamentAddExistingFieldsStateAction( new_state ))
        },
        loadAvailableTournamentFieldsData: ( page_name, tournament_id ) => {
            dispatch( loadAvailableTournamentFieldsData( page_name, tournament_id ) )
        },
        setTournamentFixture: async (tournament_id, fixture_config_data, active_phase_id) => {
            await dispatch(setTournamentFixture(tournament_id, fixture_config_data, active_phase_id));
        },
        generateMatchesFixture: async ( tournament_phase_id, fixture_shuffle ) => {
            await dispatch(generateMatchesFixture( tournament_phase_id, fixture_shuffle ));
        },
        changeFixtureMatchesAvailableDatesModalStateAction: ( new_state ) => {
            dispatch( changeFixtureMatchesAvailableDatesModalStateAction( new_state ))
        },
        changeFixtureMatchesBlockDatesModalStateAction: ( new_state ) => {
            dispatch( changeFixtureMatchesBlockDatesModalStateAction( new_state ))
        },
        setTournamentFixtureAvailableDays: async ( tournament_id, tournament_days ) => {
            await dispatch(setTournamentFixtureAvailableDays( tournament_id, tournament_days ));
        },
        deleteFixtureAvailableDay: ( tournament_day_id, tournament_id ) => {
            dispatch( deleteFixtureAvailableDay( tournament_day_id, tournament_id ) )
        },
        clearTournamentAvailableFixtureDatesAction: () => {
            return dispatch( clearTournamentAvailableFixtureDatesAction( {} ) )
        },
        setTournamentFixtureLockedDays: async ( tournament_id, tournament_locked_date ) => {
            await dispatch(setTournamentFixtureLockedDays( tournament_id, tournament_locked_date ));
        },
        deleteFixtureLockedDay: ( tournament_non_playable_date_id, tournament_id ) => {
            dispatch( deleteFixtureLockedDay( tournament_non_playable_date_id, tournament_id ) )
        },
        loadTournamentMatchesFilterData: ( tournament_date_id, params ) => {
            dispatch( loadTournamentMatchesFilterData( tournament_date_id, params ) )
        },
        newTournamentmatch: ( tournament_date_id, form_data, phase ) => {
            dispatch( newTournamentmatch( tournament_date_id, form_data, phase ) )
        },
        loadTournamentPhaseGroupDatesFilterData: async ( tournament_group_id, params ) => {
            await dispatch( loadTournamentPhaseGroupDatesFilterData( tournament_group_id, params ) )
        },
        loadFixtureFieldTeams: async ( tournament_field_id ) => {
            await dispatch(loadFixtureFieldTeams( tournament_field_id ));
        },
        changeFixtureAddTeamToFieldModalStateAction: ( new_state ) => {
            dispatch( changeFixtureAddTeamToFieldModalStateAction( new_state ))
        },
        addFixtureTeamsToField: ( tournament_field_id, field_teams, tournament_id ) => {
            dispatch( addFixtureTeamsToField( tournament_field_id, field_teams, tournament_id ))
        },
        deleteFixtureFieldTeam: ( tournament_field_team_id, tournament_id ) => {
            dispatch( deleteFixtureFieldTeam( tournament_field_team_id, tournament_id ))
        },
        changeFixtureFieldsMatchesAvailableDatesModalStateAction: ( new_state ) => {
            dispatch( changeFixtureFieldsMatchesAvailableDatesModalStateAction( new_state ))
        },
        changeFixtureFieldsMatchesBlockDatesModalStateAction: ( new_state ) => {
            dispatch( changeFixtureFieldsMatchesBlockDatesModalStateAction( new_state ))
        },
        setTournamentFieldFixtureAvailableDays: ( tournament_field_id, field_tournament_days, tournament_id ) => {
            dispatch( setTournamentFieldFixtureAvailableDays( tournament_field_id, field_tournament_days, tournament_id ))
        },
        deleteFixtureFieldAvailableDay: ( tournament_field_day_id, tournament_id ) => {
            dispatch( deleteFixtureFieldAvailableDay( tournament_field_day_id, tournament_id ))
        },
        setTournamentFixtureFieldLockedDays: (tournament_field_id, tournament_field_locked_date, tournament_id) => {
            dispatch( setTournamentFixtureFieldLockedDays( tournament_field_id, tournament_field_locked_date, tournament_id ))
        },
        deleteFixtureFieldLockedDay: ( tournament_field_day_id, tournament_id ) => {
            dispatch( deleteFixtureFieldLockedDay( tournament_field_day_id, tournament_id  ))
        },
        loadFullFixtureConfig: async ( tournament_id ) => {
            await dispatch( loadFullFixtureConfig( tournament_id  ))
        },
        loadGroupedAvailableFixtureDays: async ( tournament_id ) => {
            await dispatch( loadGroupedAvailableFixtureDays( tournament_id  ))
        },
        loadGroupedAvailableFieldFixtureDays: async ( tournament_field_id ) => {
            await dispatch( loadGroupedAvailableFieldFixtureDays( tournament_field_id  ))
        },
        changeAcceptAutomaticFixtureModalStateAction: ( new_state ) => {
            dispatch( changeAcceptAutomaticFixtureModalStateAction( new_state ))
        },
        cleanNewPageFieldAction: () => {
            dispatch( cleanNewPageFieldAction( {} ) )
        },
        editFixtureMatch: (id_match, match, tournament_phase_id ) => {
            dispatch( editFixtureMatch( id_match, match, tournament_phase_id  ))
        },
        deleteFixture: ( tournament_phase_id, selected_group_id, date_name ) => {
            dispatch( deleteFixture( tournament_phase_id, selected_group_id, date_name  ))
        },
        cleanTournamentPhaseGroupDatesFilterAction: () => {
            dispatch( cleanTournamentPhaseGroupDatesFilterAction( {} ) )
        },
        changeMatchReportPdfModalStateAction: ( new_state ) => {
            dispatch( changeMatchReportPdfModalStateAction( new_state ))
        },
        loadTournamentTeams: async ( id_tournament, tournament_team_name, state, page_size, next_page ) => {
            await dispatch( loadTournamentTeams(  id_tournament,tournament_team_name, state, page_size, next_page ) )
        },
        searchTournamentTeamsAdmin: ( tournament_id, tournament_team_name, state, page_size, next_url )=> {
            dispatch( searchTournamentTeamsAdmin( tournament_id, tournament_team_name, state, page_size, next_url ) )
        },
        searchAdminTournamentTeamPlayers: ( tournament_team_id, search, state )=> {
            dispatch( searchAdminTournamentTeamPlayers( tournament_team_id, search, state ) )
        },
        resetTournmentTeamPlayersAction: () => {
            dispatch( resetTournmentTeamPlayersAction( {} ) )
        },
        loadPhaseGroupPositionsTable: ( tournament_group_id, page_size, next_url )=>{
            dispatch( loadPhaseGroupPositionsTable( tournament_group_id, page_size, next_url ));
        },
        loadTournamentScorersTable: ( tournament_id, page_size, next_url )=>{
            dispatch( loadTournamentScorersTable( tournament_id, page_size, next_url ));
        },
        loadYellowCardsTable: ( tournament_id, page_size, next_url )=>{
            dispatch( loadYellowCardsTable( tournament_id, page_size, next_url ));
        },
        loadRedCardsTable: ( tournament_id, page_size, next_url )=>{
            dispatch( loadRedCardsTable( tournament_id, page_size, next_url ));
        },
        loadBlueCardsTable: ( tournament_id, page_size, next_url )=>{
            dispatch( loadBlueCardsTable( tournament_id, page_size, next_url ));
        },
        changeTournamentViewMoreStatisticsModalStateAction: ( new_state ) => {            
            dispatch( changeTournamentViewMoreStatisticsModalStateAction( new_state ) )
        },
        changeTournamentEditMatchInfoModalStateAction: ( new_state ) => {            
            dispatch( changeTournamentEditMatchInfoModalStateAction( new_state ) )
        },
        loadAssistsTable: ( tournament_id, page_size, next_url )=>{
            dispatch( loadAssistsTable( tournament_id, page_size, next_url ));
        },
        loadLeastDefeatedFenceTable: ( tournament_id, page_size, next_url )=>{
            dispatch( loadLeastDefeatedFenceTable( tournament_id, page_size, next_url ));
        },
        loadTeamsMostGoalsTable: ( tournament_id, page_size, next_url )=>{
            dispatch( loadTeamsMostGoalsTable( tournament_id, page_size, next_url ));
        },
        loadFairPlayTable: ( tournament_id, page_size, next_url )=>{
            dispatch( loadFairPlayTable( tournament_id, page_size, next_url ));
        },
        loadSummaryTable: ( tournament_id )=>{
            dispatch( loadSummaryTable( tournament_id ));
        },
        changeCreateEditFairPlaySettingsModalStateAction: ( new_state ) => {            
            dispatch( changeCreateEditFairPlaySettingsModalStateAction( new_state ) )
        },
        changeFairPlaySelectSettingModalStateAction: ( new_state ) => {            
            dispatch( changeFairPlaySelectSettingModalStateAction( new_state ) )
        },
        createFairPlaySettings: async ( pagename, settings )=>{
            await dispatch( createFairPlaySettings( pagename, settings ));
        },
        loadFairPlaySettings: async ( pagename, page_size, next_page )=>{
            await dispatch( loadFairPlaySettings( pagename, page_size, next_page ));
        },
        setSelectedFairPlaySettingAction: ( setting )=>{
            dispatch( setSelectedFairPlaySettingAction( setting ));
        },
        changeFairPlayAddExtraPenaltyModalStateAction: ( new_state ) => {            
            dispatch( changeFairPlayAddExtraPenaltyModalStateAction( new_state ) )
        },
        setSelectedFairPlayPhaseAction: ( phase ) => {            
            dispatch( setSelectedFairPlayPhaseAction( phase ) )
        },
        resetTournamentPhaseTeamsAction: () => {
            dispatch( resetTournamentPhaseTeamsAction( {} ) )
        },
        loadPhaseSanctions: ( tournament_phase_id, page_size, sanction_type, next_page ) => {
            dispatch( loadPhaseSanctions( tournament_phase_id, page_size, sanction_type, next_page ) )
        },
        createPhaseExtraPenalty: ( tournament_phase_id, sanction, sanction_type ) => {
            dispatch( createPhaseExtraPenalty( tournament_phase_id, sanction, sanction_type ) )
        },
        changeAddAssociateMatchModalStateAction: ( new_state ) => {            
            dispatch( changeAddAssociateMatchModalStateAction( new_state ) )
        },
        loadPhaseMatchesByTeam: ( tournament_phase_id, tournament_team_id, next_page ) => {            
            dispatch( loadPhaseMatchesByTeam( tournament_phase_id, tournament_team_id, next_page ) )
        },
        loadPhaseFairPlaySetting: ( phase_fair_play_id ) => {            
            dispatch( loadPhaseFairPlaySetting( phase_fair_play_id ) )
        },
        changeViewMoreSanctionsModalStateAction: ( new_state ) => {            
            dispatch( changeViewMoreSanctionsModalStateAction( new_state ) )
        },
        updatePhaseExtraPenalty: ( tournament_phase_id, extra_sanction_id, sanction )=> {            
            dispatch( updatePhaseExtraPenalty( tournament_phase_id, extra_sanction_id, sanction ) )
        },
        deletePhaseExtraPenalty: ( tournament_phase_id, extra_sanction_id )=> {            
            dispatch( deletePhaseExtraPenalty( tournament_phase_id, extra_sanction_id ) )
        },
        changeConfirmDeleteSanctionsModalStateAction: ( new_state ) => {            
            dispatch( changeConfirmDeleteSanctionsModalStateAction( new_state ) )
        },
        loadAllTeamPlayers: ( tournament_team_id, state ) => {
            dispatch( loadAllTeamPlayers( tournament_team_id, state ) );
        },
        changeConfirmReOpenPhaseModalStateAction: ( new_state ) => {            
            dispatch( changeConfirmReOpenPhaseModalStateAction( new_state ) )
        },
        reOpenPhase:( tournament_phase_id ) => {
            dispatch( reOpenPhase( tournament_phase_id ) );
        },
    }
}

export default compose(
    withTranslation(["league", "home"]),
    connect(mapStateToProps, mapDispatchToProps)
)(TournamentView);
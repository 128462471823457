// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.baloa-play-block-video-modal .modal__container {
    width: 500px;
    text-align: center;
    padding: 24px 36px 36px 36px;
}

.baloa-play-block-video-modal .modal__container__close {
    top: 14px;
    right: 14px;
}

.baloa-play-block-video-modal .hubicon-info::before {
    color: var(--new-informative-banner-color);
    font-size: 30px;
}

.baloa-play-block-video-modal .baloa-normal-medium {
    color: var(--primary-very-dark-color);
    margin-top: 12px;
}

.baloa-play-block-video-modal .baloa-names {
    color: var(--primary-very-dark-color);
    margin-top: 20px;
}

.baloa-play-block-video-modal .baloa-names.message {
    font-weight: var(--font-regular);
}

.baloa-play-block-video-modal .button {
    margin-top: 12px;
    width: 100%;
}

@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */

    .baloa-play-block-video-modal .modal__container {
        padding: 24px 16px;
    }

    .baloa-play-block-video-modal .baloa-normal-medium {
        margin-top: 10px;
        font-size: 17px;
    }

    .baloa-play-block-video-modal .baloa-names {
        margin-top: 14px;
        font-size: 15px;
    }

    .baloa-play-block-video-modal .button {
        margin-top: 24px;
    }
}`, "",{"version":3,"sources":["webpack://./src/app/baloa-play/components/baloa-play-block-video-modal/BaloaPlayBlockVideoModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;IAClB,4BAA4B;AAChC;;AAEA;IACI,SAAS;IACT,WAAW;AACf;;AAEA;IACI,0CAA0C;IAC1C,eAAe;AACnB;;AAEA;IACI,qCAAqC;IACrC,gBAAgB;AACpB;;AAEA;IACI,qCAAqC;IACrC,gBAAgB;AACpB;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,gBAAgB;IAChB,WAAW;AACf;;AAEA,yCAAyC,4BAA4B;;IAEjE;QACI,kBAAkB;IACtB;;IAEA;QACI,gBAAgB;QAChB,eAAe;IACnB;;IAEA;QACI,gBAAgB;QAChB,eAAe;IACnB;;IAEA;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".baloa-play-block-video-modal .modal__container {\n    width: 500px;\n    text-align: center;\n    padding: 24px 36px 36px 36px;\n}\n\n.baloa-play-block-video-modal .modal__container__close {\n    top: 14px;\n    right: 14px;\n}\n\n.baloa-play-block-video-modal .hubicon-info::before {\n    color: var(--new-informative-banner-color);\n    font-size: 30px;\n}\n\n.baloa-play-block-video-modal .baloa-normal-medium {\n    color: var(--primary-very-dark-color);\n    margin-top: 12px;\n}\n\n.baloa-play-block-video-modal .baloa-names {\n    color: var(--primary-very-dark-color);\n    margin-top: 20px;\n}\n\n.baloa-play-block-video-modal .baloa-names.message {\n    font-weight: var(--font-regular);\n}\n\n.baloa-play-block-video-modal .button {\n    margin-top: 12px;\n    width: 100%;\n}\n\n@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */\n\n    .baloa-play-block-video-modal .modal__container {\n        padding: 24px 16px;\n    }\n\n    .baloa-play-block-video-modal .baloa-normal-medium {\n        margin-top: 10px;\n        font-size: 17px;\n    }\n\n    .baloa-play-block-video-modal .baloa-names {\n        margin-top: 14px;\n        font-size: 15px;\n    }\n\n    .baloa-play-block-video-modal .button {\n        margin-top: 24px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import InfiniteScroll from "react-infinite-scroll-component";

// Styles
import './TournamentPhasesFairPlaySelectSettingModalComponent.styles.css';

// Material
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel  from '@mui/material/FormControlLabel';
import { Skeleton } from '@mui/material';

//i18
import { withTranslation } from 'react-i18next';

function TournamentPhasesFairPlaySelectSettingModalComponent(props){

    const [selectedSetting, setSelectedSetting] = React.useState(null);
    const [loadMore, setLoadMore] = React.useState(false);
    
    React.useEffect(() => {
        if(props.next_fair_play_settings){
            setLoadMore(true);
        } else {
            setLoadMore(false);
        }
    }, [props.next_fair_play_settings]); // eslint-disable-line

    const handleOnSelectSetting=( event )=>{
        setSelectedSetting(event.target.value);
    }

    const handleOnBtnAccept=()=>{
        props.handleOnAccept(props.fair_play_settings_options.find( sett => sett.id === selectedSetting));        
        props.onClose();
        setSelectedSetting(null);
    }

    const handleOnBtnClose=()=>{   
        props.onClose();
        setSelectedSetting(null);
    }

    return(
        <Modal2Component
            isOpen={props.isOpen}
            onClose={handleOnBtnClose}
            title={props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySelectSettingsModal.title')}
            className='phases-fair-play-select-setting-modal'
        >
            <div className='phases-fair-play-select-setting-modal__container'>
                {(!props.fair_play_settings_options || props.fair_play_settings_options.length <= 0) &&
                    <div className='baloa-names empty-msg'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySelectSettingsModal.emptyTitle')}</div>
                }
                {props.fair_play_settings_options && props.fair_play_settings_options.length > 0 &&
                    <InfiniteScroll
                        dataLength={props.fair_play_settings_options.length}
                        next={()=>{ props.loadMoreSettings() }}
                        hasMore={loadMore}
                        loader={
                            
                            <div className='phases-fair-play-select-setting-modal__radio-options__skeleton' >                                
                                <Skeleton animation="wave" variant="circular" component="div" width={20} height={20} style={{ marginRight: 10, marginLeft: 10 }}/>
                                <div>
                                    <Skeleton animation="wave" variant="rounded" component="div" width={150} height={16} style={{  }}/>
                                    <div className='phases-fair-play-select-setting-modal__option__settings'>
                                        <Skeleton animation="wave" variant="rounded" component="div" width={60} height={12} style={{  }}/>
                                        <Skeleton animation="wave" variant="rounded" component="div" width={60} height={12} style={{  }}/>
                                        <Skeleton animation="wave" variant="rounded" component="div" width={60} height={12} style={{  }}/>
                                        <div className='baloa-username'>&bull;</div>
                                        <Skeleton animation="wave" variant="rounded" component="div" width={60} height={12} style={{  }}/>
                                        <Skeleton animation="wave" variant="rounded" component="div" width={60} height={12} style={{  }}/>
                                        <Skeleton animation="wave" variant="rounded" component="div" width={60} height={12} style={{  }}/>
                                        <Skeleton animation="wave" variant="rounded" component="div" width={60} height={12} style={{  }}/>
                                    </div>
                                </div>
                            </div>
                        }
                        height={'500px'}
                        endMessage={''}
                    >
                        {/* <div className=''>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySelectSettingsModal.subTitle')}</div> */}
                        <RadioGroup aria-labelledby="selected_setting" name="selected_setting"  defaultValue={''} value={ selectedSetting } onClick={ handleOnSelectSetting } className="phases-fair-play-select-setting-modal__radio-options" >
                            {props.fair_play_settings_options?.map(fp_setting =>
                                <FormControlLabel value={fp_setting.id} control={<Radio />} label={
                                    <div className='phases-fair-play-select-setting-modal__option'>
                                        <div className='baloa-names'>{fp_setting.name}</div>
                                        <div className='phases-fair-play-select-setting-modal__option__settings'>
                                            <div className='baloa-username'>{`${props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.scoringAndFairPlay.pointsWinLbl')} ${fp_setting.points_win}`}</div>
                                            <div className='baloa-username'>{`${props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.scoringAndFairPlay.pointsTieLbl')} ${fp_setting.points_draw}`}</div>
                                            <div className='baloa-username'>{`${props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.scoringAndFairPlay.pointsLoseLbl')} ${fp_setting.points_loss}`}</div>
                                            <div className='baloa-username'>&bull;</div>
                                            <div className='baloa-username'>{`${props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.scoringAndFairPlay.yellowLbl')} ${fp_setting.points_yellow_card}`}</div>
                                            <div className='baloa-username'>{`${props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.scoringAndFairPlay.doubleYellowLbl')} ${fp_setting.points_double_yellow_card}`}</div>
                                            <div className='baloa-username'>{`${props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.scoringAndFairPlay.redLbl')} ${fp_setting.points_red_card}`}</div>
                                            <div className='baloa-username'>{`${props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.scoringAndFairPlay.blueLbl')} ${fp_setting.points_blue_card}`}</div>
                                        </div>
                                    </div>
                                } />
                            )}
                        </RadioGroup>
                        
                    </InfiniteScroll>
                    
                }

            </div>
            <div className='phases-fair-play-select-setting-modal__buttons'>
                <PrimaryButtonComponent
                    type={'button'}
                    onClick={ ()=>{ handleOnBtnAccept() }}
                    className={'small'}
                    disabled={!selectedSetting}
                >
                    <span>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySelectSettingsModal.acceptBtnLbl')}</span>
                </PrimaryButtonComponent>
            </div>
        </Modal2Component>
    )
            
}

export default withTranslation('league')(TournamentPhasesFairPlaySelectSettingModalComponent)
/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Components
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import FormComponent from 'shared/components/form/FormComponent';
import TournamentBasicDataComponent from '../tournament-basic-data/TournamentBasicDataComponent';
import TournamentInscriptionComponent from '../tournament-inscription/TournamentInscriptionComponent';
import TournamentGeneralSummayComponent from '../tournament-general-summay/TournamentGeneralSummayComponent';
//import TournamentInviteTeamsComponent from '../tournament-invite-teams/TournamentInviteTeamsComponent';
//import TournamentDynamicFormMakerComponent from '../tournament-dynamic-form-maker/TournamentDynamicFormMakerComponent';

//Modals
import TournamentNewTournamentWarningModal from '../tournament-new-tournament-warning-modal/TournamentNewTournamentWarningModal';
import TournamentConfigArbitragePaymentsModalComponent from '../tournament-config-arbitrage-payments-modal/TournamentConfigArbitragePaymentsModalComponent';
import TournamentDynamicFormMakerModalComponent from '../tournament-dynamic-form-maker-modal/TournamentDynamicFormMakerModalComponent';
import TournamentsModalFormExistingComponent from '../tournaments-modal-form-existing/TournamentsModalFormExistingComponent';
import ModalConfirmDialogComponent from 'shared/components/modal-confirm-dialog/ModalConfirmDialogComponent';

// Styles
import './TournamentCreateNewTournamentV2Component.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function TournamentCreateNewTournamentV2Component ( props ) {

    const [ isBasicDataComponent, setIsBasicDataComponent ] = React.useState(true);
    const [ isInscriptionComponent, setIsInscriptionComponent ] = React.useState(false);
    const [ isInviteTeamsComponent, setIsInviteTeamsComponent ] = React.useState(false); // eslint-disable-line
    const [ isGeneralSummayComponent, setIsGeneralSummayComponent ] = React.useState(false);
    const [ step2, setStep2 ] = React.useState(false);
    const [ step3, setStep3 ] = React.useState(false);
    const [ tournamentPhotoB64, setTournamentPhotoB64 ] = React.useState(null);
    const [ tournamentPhotoUrl, setTournamentPhotoUrl ] = React.useState(null);
    const [ tournamentLogoB64, setTournamentLogoB64 ] = React.useState(null);
    const [ tournamentLogoUrl, setTournamentLogoUrl ] = React.useState(null);
    const [ isBasicDataFormValid, setIsBasicDataFormValid ] = React.useState(false);
    const [ formState, setFormState ] = React.useState({});
    const [ summonRegistrationType, setSummonRegistrationType ] = React.useState('');
    const [ registrationType, setRegistrationType ] = React.useState('');
    const [ tournamentPrizeType, setTournamentPrizeType ] = React.useState('');
    const [ minTeams, setMinTeams ] = React.useState(null);
    const [ maxTeams, setMaxTeams ] = React.useState(null);
    const [ minPlayers, setMinPlayers ] = React.useState(null);
    const [ maxPlayers, setMaxPlayers ] = React.useState(null);
    const [ materialPrize, setMaterialPrize ] = React.useState(false);
    const [ monetaryPrize, setMonetaryPrize ] = React.useState(false);
    const [ registrationTypePerTeam, setRegistrationTypePerTeam ] = React.useState('');
    const [ registrationTypePerPlayer, setRegistrationTypePerPlayer ] = React.useState('');
    const [ bankAccount, setBankAccount] = React.useState('');
    const [ isInscriptionDataFormValid, setIsInscriptionDataFormValid ] = React.useState(false);
    const [ isTournamentLogo, setIsTournamentLogo ] = React.useState();
    const [ isTournamentPhoto, setIsTournamentPhoto ] = React.useState();
    const [ footballTypeMin, setFootballTypeMin ] = React.useState();
    const [ isUnlimited, setIsUnlimited ] = React.useState();
    const [ teamForm, setTeamForm ] = React.useState();
    const [ playerForm, setPlayerForm ] = React.useState();
    const [ newTeamForm, setNewTeamForm ] = React.useState();
    const [ newPlayerForm, setNewPlayerForm ] = React.useState();

    const [ isTeamForm, setIsTeamForm ] = React.useState('team_form_no');
    const [ isPlayerForm, setIsPlayerForm ] = React.useState('player_form_no');

    //const [ showTeamForm, setShowTeamForm ] = React.useState(false);
    //const [ showPlayerForm, setShowPlayerForm ] = React.useState(false);

    const [ submitTeamPlayerForm, setSubmitTeamPlayerForm ] = React.useState(false);
    const [newFormatDays, setNewFormatDays] = React.useState([]); //eslint-disable-line
    const [newFormatTimeDay, setNewFormatTimeDay] = React.useState([]); //eslint-disable-line
    const [payment_option, setPaymentOption] = React.useState('');
    const [monetary_prize,setMonetary_Prize] = React.useState('');
    const [ warningModal, setWarningModal ] = React.useState(false);
    const [ warningType, setWarningType ] = React.useState('');    
    const [ configArbitrageModal, setConfigArbitrageModal ] = React.useState(false);
    const [ totalAmountArbitrageConfig, setTotalAmountArbitrageConfig ] = React.useState(0);
    const [ exceededAmountArbitrageConfig, setExceededAmountArbitrageConfig ] = React.useState(0);
    const [ arbitrageConfigFormValues, setArbitrageConfigFormValues ] = React.useState({});
    const [ localTeamFee, setLocalTeamFee ] = React.useState('');
    const [ visitantTeamFee, setVisitantTeamFee ] = React.useState('');
    const [ inscriptionTypeProcessRate, setInscriptionTypeProcessRate ] = React.useState('');
    const [ selectedCountry, setSelectedCountry ] = React.useState('');
    const [ teamFormName, setTeamFormName ] = React.useState(null);
    const [ playerFormName, setPlayerFormName ] = React.useState(null);
    const [ openNewTeamFormModal, setOpenNewTeamFormModal ] = React.useState(false);
    const [ openNewPlayerFormModal, setOpenNewPlayerFormModal ] = React.useState(false);
    const [ openExistingFormModal, setExistingFormModal ] = React.useState(false);
    const [ openConfirmDeleteTeamFormModal, setOpenConfirmDeleteTeamFormModal ] = React.useState(false);
    const [ openConfirmDeletePlayerFormModal, setOpenConfirmDeletePlayerFormModal ] = React.useState(false);

    const formRef = React.useRef();
    
    const formInitialValues = {
        name:"",
        league_page:"",
        category:"",
        tournament_photo:null,
        start_date:"",
        end_date:"",
        description:"",
        rules:"",
        main_city:"",
        main_country:"",
        tournament_gender:"",
        registration_deadline:"",
        bank_account: null,
        tournament_type:"",
        tournament_registration_type:"",
        tournament_summon_registration:"", 
        tournament_registration_pay:"",
        tournament_prize_type:"",
        material_prize:"",
        monetary_prize: null,
        registration_price_per_team:"",
        registration_price_per_player:"",
        min_participant_teams:"",
        max_participant_teams:"",
        min_participant_players: null,
        max_participant_players: null,
        unlimited_participant_players: false,
        various_editions: false,
        various_categories: false,
        tournament_logo: null,
        football_type: "",
        tournament_tag: [{extra:{atype: "tournament-tag", value: "" }}],
        has_player_form: false,
        has_team_form: false,
        tournament_day: [],
        tournament_timeday: [],
        tournament_processing_rate_type: "",
    }

    React.useEffect(()=>{
        if(props.pageTeamPlayerFormTemplate){
            if(props.pageTeamPlayerFormTemplate.caption === 'TEAM_FORM'){
                setTeamForm(transfomFormTemplate(props.pageTeamPlayerFormTemplate));
                setTeamFormName(props.pageTeamPlayerFormTemplate.name)
                setOpenNewTeamFormModal(true)
            }
            if(props.pageTeamPlayerFormTemplate.caption === 'PLAYER_FORM'){
                setPlayerForm(transfomFormTemplate(props.pageTeamPlayerFormTemplate));
                setPlayerFormName(props.pageTeamPlayerFormTemplate.name)
                setOpenNewPlayerFormModal(true)
            }
        }
    },[props.pageTeamPlayerFormTemplate]); // eslint-disable-line

    const transfomFormQuestionAnswers =(questionAnswers)=> {
        let answersToEdit = [];
        //eslint-disable-next-line array-callback-return
        questionAnswers.map((answ, index) => {
            let qobj = {
                id: index,
                caption: answ.caption,
                defaultValue: answ.is_default
            }
            answersToEdit[index] = qobj;            
        });

        return answersToEdit;
    }

    const transfomFormTemplate =(formTemplate)=> {
        let formToEdit = [];
        //eslint-disable-next-line array-callback-return
        formTemplate.questions.map((question, index) => {
            let qobj = {
                id: index,
                caption: question.caption,
                input_type: question.question_type.caption,
                is_required: question.is_required,
                //order_position: question.order_position,
                answers: transfomFormQuestionAnswers(question.answers)
            }
            formToEdit[index] = qobj;
        });

        return formToEdit;
    }

    const handleFormDataSteps = ( value ) => {

        if(value == 'basic_data') {
            setIsBasicDataComponent(false);
            setIsInscriptionComponent(true);
            setStep2(true);
            let formObject = formRef.current?.values;
            setSelectedCountry(formObject.main_country);
        }
        if(value == 'inscription_data') {            
            /* if(isTeamForm === 'team_form_yes' && (isPlayerForm === '' || isPlayerForm === 'player_form_no')) {                
                setIsInscriptionComponent(false);
                setShowTeamForm(true);
                setShowPlayerForm(false);                
            }
            if(isPlayerForm === 'player_form_yes' && (isTeamForm === '' || isTeamForm === 'team_form_no')) {                
                setIsInscriptionComponent(false);
                setShowPlayerForm(true);
                setShowTeamForm(false);
            }
            if(isTeamForm === 'team_form_yes' && isPlayerForm === 'player_form_yes') {                
                setIsInscriptionComponent(false);
                setShowTeamForm(true);
            }
            if((isTeamForm === '' || isTeamForm === 'team_form_no') && (isPlayerForm === '' || isPlayerForm === 'player_form_no')) {
                setIsInscriptionComponent(false);
                setIsGeneralSummayComponent(true);                
                setStep3(true);
            } */

            setIsInscriptionComponent(false);
            setIsGeneralSummayComponent(true);                
            setStep3(true);

            let formObject = formRef.current?.values;
            let football_type = props.tournament_football_type?.filter((type) => type.value == formRef.current?.values.football_type);
            let newFormObject = {...formObject, football_type: football_type[0].value}
            setFormState(newFormObject);
        }

        /* if(value == 'team_form') {
            if(isTeamForm === 'team_form_yes' && isPlayerForm === 'player_form_yes') {
                setShowTeamForm(false);
                setShowPlayerForm(true);
            } else {
                setShowPlayerForm(false);
                setShowTeamForm(false);
                setIsGeneralSummayComponent(true);
                setStep3(true);

            }

        } */

        /* if(value == 'player_form') {
            setShowPlayerForm(false);
            setShowTeamForm(false);
            setIsGeneralSummayComponent(true);
            setStep3(true);
    
        } */

        if(value == 'general_summay') {
            setIsGeneralSummayComponent(false);
            setIsInviteTeamsComponent(true);
        }

    }



    const handleFormBackBtn = ( value ) => {
        if(value == 'inscription_data') {
            setStep2(false);
            setIsBasicDataComponent(true);
            setIsInscriptionComponent(false);
        }

        if(value == 'general_summay') {
            /* if( isPlayerForm === 'player_form_yes') {
                setIsGeneralSummayComponent(false);                
                setShowPlayerForm(true);
            } else if(isTeamForm === 'team_form_yes') {
                setIsGeneralSummayComponent(false);
                setShowTeamForm(true);
            } else {
                setIsGeneralSummayComponent(false);
                setIsInscriptionComponent(true);
            } */

            setIsGeneralSummayComponent(false);
            setIsInscriptionComponent(true);

            setStep3(false);
            setStep2(true);
        }

        /* if(value === 'team_form') {
            setShowTeamForm(false);
            setIsInscriptionComponent(true);
        } */

        /* if(value === 'player_form') {
            setShowPlayerForm(false);
            if(isTeamForm === 'team_form_yes'){
                setShowTeamForm(true);
            } else {
                setIsInscriptionComponent(true);
            }
            
            //setIsInscriptionComponent(true);

        } */
    }

    const handleMonetaryPrize = (value) =>{
        setMonetary_Prize(value)
    }

    const handleFormData = ( values ) => {
        let city_id = props.cities.find((city) => city.name === values.main_city);
        //console.log('form values',values);
        let new_tournament_data = {
            "name": values.name,
            "league_page": props.league_page,
            "category": values.category,
            "tournament_photo": tournamentPhotoB64? tournamentPhotoB64: null,
            "start_date": values.start_date,
            "end_date": values.end_date,
            "description": values.description,
            "rules": values.rules? values.rules: null,
            "main_city": city_id.id,
            "main_country": props.countries[0]?.id,
            "tournament_gender": values.tournament_gender,
            "registration_deadline": values.registration_deadline,
            "bank_account": values.bank_account,
            "tournament_type": values.tournament_type,
            "tournament_registration_type": values.tournament_registration_type === 'Free'? values.tournament_registration_type: payment_option,
            //"tournament_registration_type": "Free",
            "tournament_summon_registration": values.tournament_summon_registration, 
            "tournament_registration_pay": values.tournament_registration_pay? values.tournament_registration_pay: null, //no usado
            "tournament_prize_type": values.tournament_prize_type,
            "material_prize": (values.tournament_prize_type === 'No_Prize' || values.tournament_prize_type === 'Monetary_Prize')? null : values.material_prize,
            "monetary_prize": (values.tournament_prize_type === 'No_Prize' || values.tournament_prize_type === 'Material_Prize')? null : monetary_prize,
            "registration_price_per_team": (payment_option === 'Paid' || payment_option === 'Team_paid') ? registrationTypePerTeam : 0,
            "registration_price_per_player":  (payment_option === 'Paid' || payment_option === 'Player_paid') ? registrationTypePerPlayer : 0,
            "min_participant_teams": values.min_participant_teams,
            "max_participant_teams": values.max_participant_teams,
            "min_participant_players": values.min_participant_players,
            "max_participant_players": values.max_participant_players,
            "unlimited_participant_players": values.unlimited_participant_players ==='unlimited'? true: false,
            "various_editions": false,
            "various_categories": false,
            "tournament_tag": [{extra:{atype: "tournament-tag", value: values.category }}],
            "tournament_logo": tournamentLogoB64? tournamentLogoB64: null,
            "football_type": props.tournament_football_type.find(ft => ft.value === values.football_type).initial,
            "has_player_form": isPlayerForm === 'player_form_yes' ? true : false,
            "has_team_form":  isTeamForm === 'team_form_yes' ? true : false,
            "tournament_day": newFormatDays ?? [],
            "tournament_timeday": newFormatTimeDay ?? [],
            "tournament_processing_rate_type": values.tournament_processing_rate_type? values.tournament_processing_rate_type: null,
            // arbitrage config init values
            "referee_payments": arbitrageConfigFormValues.referee_payments ? arbitrageConfigFormValues.referee_payments : false,
            "referee_processing_rate_type": arbitrageConfigFormValues.referee_processing_rate_type ? arbitrageConfigFormValues.referee_processing_rate_type : null,
            "tournament_referee_payment": arbitrageConfigFormValues.tournament_referee_payment ? arbitrageConfigFormValues.tournament_referee_payment : null,
            "refereeing_value_local_team": arbitrageConfigFormValues.refereeing_value_local_team ? arbitrageConfigFormValues.refereeing_value_local_team : 0,
            "refereeing_value_visitant_team": arbitrageConfigFormValues.refereeing_value_visitant_team ? arbitrageConfigFormValues.refereeing_value_visitant_team : 0,
            "official_referee_value": arbitrageConfigFormValues.official_referee_value ? arbitrageConfigFormValues.official_referee_value : 0,
            "second_assistant_referee_value": arbitrageConfigFormValues.second_assistant_referee_value ? arbitrageConfigFormValues.second_assistant_referee_value : 0,
            "third_assistant_referee_value": arbitrageConfigFormValues.third_assistant_referee_value ? arbitrageConfigFormValues.third_assistant_referee_value : 0,
            "fourth_official_referee_value": arbitrageConfigFormValues.fourth_official_referee_value ? arbitrageConfigFormValues.fourth_official_referee_value : 0,
            "referee_assigner_value": arbitrageConfigFormValues.referee_assigner_value ? arbitrageConfigFormValues.referee_assigner_value : 0,
            "fair_play_most_points_wins": values.fair_play_most_points_wins === 'true'? true : false,
            "fair_play_initial_points": values.fair_play_initial_points,
        }

        let apiObject = {
            form: new_tournament_data,
            team_form: newTeamForm,
            player_form: newPlayerForm,
            team_form_name: teamFormName,
            player_form_name: playerFormName
        }
        props.createNewTournament(apiObject);
        //props.handleOnFinishCreateNewTournament();
    }

    //Función que valida primera carga de datos en el componente, TournamentBasicDataComponent.
    const onBasciDataFormProps = (form_props) => {
        let initialValue = form_props.getFieldProps(form_props.initialValues);
        if(initialValue.value.name !== "" && form_props.isValid && tournamentLogoUrl !== null && tournamentPhotoUrl !== null ) { // Si el campo name (requerido) tiene valor y la prop, 'isValid' de formik es verdadero, la primera carga del formulario esta completa.
            setIsBasicDataFormValid(true);
        } else {                                                   // Valida si el campo name esta vacio ó la propiedad de formik 'isValid' es falsa, falta algún campo por diligenciar.
            setIsBasicDataFormValid(false);
        }

        if(selectedCountry !== initialValue.value.main_country) {
            handleInscriptionTypeProcessRate('');
            handleRegistrationType('', 'registration_price_per_team');
            handleRegistrationType('', 'registration_price_per_player');
        }

        if(isInscriptionComponent) {
            if( form_props.isValid
                && initialValue.value.tournament_summon_registration !== ''
                && initialValue.value.registration_deadline !== ''
                && registrationType !== ''
                && (((registrationType === 'Free' && inscriptionTypeProcessRate === '' && payment_option === 'Free' && registrationTypePerTeam === '' && registrationTypePerPlayer === '')
                    || ((registrationType === 'Paid' || registrationType === 'Paid_No_Account') && inscriptionTypeProcessRate !== '' && payment_option !== '')
                    )
                || 
                   ((payment_option === 'Paid' && registrationTypePerTeam !== '' && registrationTypePerPlayer !== '') 
                    || (payment_option === 'Team_paid' && registrationTypePerTeam !== '') 
                    || (payment_option === 'Player_paid' && registrationTypePerPlayer !== '')
                   ))
                && tournamentPrizeType !== ''
                && ((tournamentPrizeType === 'No_Prize')
                    || (tournamentPrizeType === 'Material_Prize' && initialValue.value.material_prize !== null)
                    || (tournamentPrizeType === 'Monetary_Prize' && monetary_prize !== null)                
                    )
                && isTeamForm !== ''
                && isPlayerForm !== ''

              ) {
                setIsInscriptionDataFormValid(true);
              } else {
                setIsInscriptionDataFormValid(false);
              }            
        }

        if(!tournamentLogoUrl && initialValue.value.name !== "") {
            setIsTournamentLogo(false);
        } else {
            setIsTournamentLogo(true);
        }
        if(!tournamentPhotoUrl && initialValue.value.name !== "") {
            setIsTournamentPhoto(false);
        } else {
            setIsTournamentPhoto(true);
        }
    }

    const handleTournamentPhotoB64 = (photo_b64, imageurl) => {
        setTournamentPhotoB64(photo_b64);
        setTournamentPhotoUrl(imageurl);
    }

    const handleTournamentLogoB64 = (logo_b64, imageurl) => {
        setTournamentLogoB64(logo_b64);
        setTournamentLogoUrl(imageurl);
    }

    const handleFormCheckBtns = (value, type) => {
        formRef.current?.setFieldValue("material_prize", null);
        if(type === "summon_registration_type") {
            setSummonRegistrationType(value);
        }
        if(type === "registration_type") {
            setRegistrationType(value);
        }
        if(type === "tournament_prize_type") {
            setTournamentPrizeType(value);
        }
        if(type === "is_player_form"){
            setIsPlayerForm(value);

        }
        if(type === "is_team_form"){
            setIsTeamForm(value);
        }

        
    }

    const handleParticipantTeams = (value, min_max) => {
        if(min_max === "min_teams"){
            setMinTeams(value);
        } else {
            setMaxTeams(value);
        }
    }

    const handlePlayersByTeams = (value, min_max) => {
        if(min_max === "min_players") {
            setMinPlayers(value);
        } else {
            setMaxPlayers(value);
        }
    }

    const handlePrizeType = (value) => {
        if(value === 'Material_Prize') {
            setMaterialPrize(true);
            setMonetaryPrize(false);
        } 
        
        if(value === 'Monetary_Prize') {
            setMaterialPrize(false);
            setMonetaryPrize(true);
        } 
        
        if(value === 'No_Prize') {
            setMaterialPrize(false);
            setMonetaryPrize(false);
        }
    }

    const handleRegistrationType = (value, type) => {
        
        if(type === 'registration_price_per_team') {
            setRegistrationTypePerTeam(value);
        }

        if(type === 'registration_price_per_player') {
            setRegistrationTypePerPlayer(value);
        }

        if(type === 'bank_account') {
            setBankAccount(value);
        }
    }

    const setFootbalTypeMinPlayers = (football_type) => {
        switch(football_type){
            
            case 'Football_8': {//Football_8
                setFootballTypeMin(5);
                break;
            }
            case 'Football_11': {//Football_11
                setFootballTypeMin(7);
                break;
            }
            case 'Football_7': {//Football_7
                setFootballTypeMin(4);
                break;
            }
            case 'Football_5': {//Football_5
                setFootballTypeMin(3);
                break;
            }
            case 'Football_6': {//Football_6
                setFootballTypeMin(3);
                break;
            }
            case 'Football_9': {//Football_9
                setFootballTypeMin(5);
                break;
            }
            case 'Football_10': {//Football_10
                setFootballTypeMin(6);
                break;
            }
            // es-lint warning, switch without defalut case. from here, always at the bottom of docuemnt 
            // no default
        }
    }

    const handleUnlimited = (value) => {
        setIsUnlimited( value );
    }

    const validationAnswers = (array_answers) => {
        const new_answers =  array_answers.filter(answer => answer.caption !== undefined ) ?? [];
        return new_answers.map((element,index) => {
            return {
                caption: element.caption,
                is_default: element.defaultValue,
                order_position: index + 1
            }
        });
    }

    const handleDynamicFormData = (form) => {
        const new_form = form.map((question, index) => {
            return {
                caption: question.caption,
                question_type: question.input_type,
                is_required: question.is_required,
                order_position: index + 1,
                answers: validationAnswers(question.answers)
            }
        } );
        return new_form;
    };

    /* const handleSelectDays = (array_days)=>{
        const new_days = array_days.map((day) => {
            return {day: day}
        });
        setNewFormatDays(new_days);
    }; */

    /* const handleSelectDayTime = (array_dayTime) =>{
        const new_dayTime = array_dayTime.map((day) => {
            return {timeday: day}
        });
        setNewFormatTimeDay(new_dayTime);
    } */

    const handlePaymentOption =(payment_option)=>{
        setPaymentOption(payment_option);
    }

    const handleNewTournamentCreateOnBoardingAccount = () => {
        setWarningType('');
        setWarningModal(false);
        props.handleNewTournamentCreateOnBoardingAccount();
    }

    const handleCheckPaymentAccount = (account_type) => {
        if(!props.create_stripe_account) {
            setWarningType(account_type);
            setWarningModal(true);
        }
    }

    const handleConfigArbitrageModal = (is_arbitrage_config) => {
        if(!props.create_stripe_account) {
            handleCheckPaymentAccount('need_referee_account');
        } else {
            setConfigArbitrageModal(is_arbitrage_config);
        }
    }

    const handleExceededValue = (total_amount, exceeded_amount) => {
        const options = { 
            minimumFractionDigits: 2,
            maximumFractionDigits: 2 
        };
        let formatted_total_amount = Number(total_amount).toLocaleString('en', options);
        let formatted_exceeded_amount = Number(exceeded_amount).toLocaleString('en', options);          
        setTotalAmountArbitrageConfig(formatted_total_amount);
        setExceededAmountArbitrageConfig(formatted_exceeded_amount);
        setWarningType('exceeded_amount');
        setWarningModal(true);
    }

    const handleOnSaveArbitrageConfig = (form_values, local_team_fee, visitant_team_fee) => {
        setArbitrageConfigFormValues(form_values);
        setLocalTeamFee(local_team_fee);
        setVisitantTeamFee(visitant_team_fee);
    }

    const handleInscriptionTypeProcessRate = (process_type) => {
        setInscriptionTypeProcessRate(process_type);
    }

    const handleOnCreateFormModal=( form_type)=>{
        switch (form_type){
            case 'team':
                setOpenNewTeamFormModal(true);
                break;
            case 'player':
                setOpenNewPlayerFormModal(true);
                break;
            //no default
        }
    }

    const handleOnExistingFormModal=(form_type)=> {
        switch(form_type){
            case 'team':
                props.handleListExistingForm("team_tournament_form");
                setExistingFormModal(true);
                break;
            case 'player':
                props.handleListExistingForm("player_tournament_form");
                setExistingFormModal(true);
                break;
            //no default
        }
        
    }
  
    const deleteTeamForm=()=> {
        setNewTeamForm()
        setTeamForm();
        setTeamFormName(null);
        handleFormCheckBtns('team_form_no', 'is_team_form');
        setOpenConfirmDeleteTeamFormModal( false )
    }

    const deletePlayerForm=()=> {
        setNewPlayerForm()
        setPlayerForm();
        setPlayerFormName(null);
        handleFormCheckBtns('player_form_no', 'is_player_form');
        setOpenConfirmDeletePlayerFormModal( false )
    }

    return(
        <div>
            {/* Modals component */}
            <TournamentNewTournamentWarningModal 
                isOpen={ warningModal }
                onClose={ () => { setWarningModal(false) } }
                warning_type={warningType}
                handleNewTournamentCreateOnBoardingAccount={ () => { handleNewTournamentCreateOnBoardingAccount() } }
                total_amount_arbitrage_config={ totalAmountArbitrageConfig }
                exceeded_amount_arbitrage_config={ exceededAmountArbitrageConfig }
                currency={props.currency}
            />
            <TournamentConfigArbitragePaymentsModalComponent 
                isOpen={configArbitrageModal}
                onClose={() => { setConfigArbitrageModal(false) }}
                new_tournament_currency={props.currency}
                handleOnSaveArbitrageConfig={ (form_values, local_team_fee, visitant_team_fee) => {handleOnSaveArbitrageConfig(form_values, local_team_fee, visitant_team_fee)}}
                handleExceededValue={ (total_amount, exceeded_amount) => { handleExceededValue(total_amount, exceeded_amount) } }
                calculateRefereeFee={props.calculateRefereeFee}
                referee_fee_value={props.referee_fee_value}
                handleOnResetRefereesFee={props.handleOnResetRefereesFee}
            />

            {/* Team Form modal */}
            {openNewTeamFormModal &&
                <TournamentDynamicFormMakerModalComponent
                    isOpen={ openNewTeamFormModal } 
                    onClose={ ()=>{setOpenNewTeamFormModal(false); setTeamForm(); props.handleCleanPageFormTemplate();} }
                    title={ props.t('options.tournaments.pages.newTournament.teamPlayerDynamicForm.teamFormTitle') }
                    onNewFormValues={(form, form_name)=>{
                        setNewTeamForm(handleDynamicFormData(form));
                        setTeamForm(form);
                        setTeamFormName(form_name);
                        handleFormCheckBtns('team_form_yes', 'is_team_form');
                        setOpenNewTeamFormModal(false);
                        //Esto aplica cuando se reutiliza un formulario anterior
                        setExistingFormModal(false);
                        props.handleCleanPageFormTemplate();
                    }}
                    form_title={props.t('options.tournaments.pages.newTournament.teamPlayerDynamicForm.teamFormTitle')}
                    form_description={props.t('options.tournaments.pages.newTournament.teamPlayerDynamicForm.teamFormLabel')}
                    submitTeamPlayerForm={submitTeamPlayerForm}
                    resetButton={ () => {setSubmitTeamPlayerForm(false)} }
                    formData={teamForm}
                    formName={teamFormName}
                    handlePreviewForm={ (teamForm) => { props.handlePreviewForm(teamForm) }}
                    handleCreate={()=>setSubmitTeamPlayerForm(true)}
                    createBtnLbl = {props.t('options.tournaments.pages.newTournament.tabs.tabInscription.createForm')}
                    saveBtnLbl = { props.t('options.tournaments.pages.newTournament.saveButton') }
                />
            }
            {/* Player Form modal */}
            {openNewPlayerFormModal && 
                <TournamentDynamicFormMakerModalComponent 
                    isOpen={ openNewPlayerFormModal } 
                    onClose={ ()=>{setOpenNewPlayerFormModal(false); setPlayerForm(); props.handleCleanPageFormTemplate();} }
                    title={ props.t('options.tournaments.pages.newTournament.teamPlayerDynamicForm.playerFormTitle') }
                    onNewFormValues={(form, form_name)=>{
                        setNewPlayerForm(handleDynamicFormData(form));
                        setPlayerForm(form);
                        setPlayerFormName(form_name);
                        handleFormCheckBtns('player_form_yes', 'is_player_form');
                        setOpenNewPlayerFormModal(false);
                        //Esto aplica cuando se reutiliza un formulario anterior
                        setExistingFormModal(false);
                        props.handleCleanPageFormTemplate();                              
                    }}
                    form_title={props.t('options.tournaments.pages.newTournament.teamPlayerDynamicForm.playerFormTitle')}
                    form_description={props.t('options.tournaments.pages.newTournament.teamPlayerDynamicForm.playerFormLabel')}
                    submitTeamPlayerForm={submitTeamPlayerForm}
                    resetButton={ () => {setSubmitTeamPlayerForm(false)} }
                    formData={playerForm}
                    formName={playerFormName}
                    handlePreviewForm={ (playerForm) => { props.handlePreviewForm(playerForm) }}
                    handleCreate={()=>setSubmitTeamPlayerForm(true)} 
                    createBtnLbl = {props.t('options.tournaments.pages.newTournament.tabs.tabInscription.createForm')}
                    saveBtnLbl = { props.t('options.tournaments.pages.newTournament.saveButton') }
            />
            }
            {/* Delete team form confirm dialog modal */}
            <ModalConfirmDialogComponent
                onPrincipalButtonClick = { ( ) => { setOpenConfirmDeleteTeamFormModal( false ) } }
                principalButtonLabel = { props.t('options.tournaments.pages.newTournament.tabs.tabInscription.confirmationModal.cancelBtnLbl') }
                secondaryButton = { true }
                onSecondaryButtonClick = { () => { deleteTeamForm() } }
                secondaryButtonLabel = { props.t('options.tournaments.pages.newTournament.tabs.tabInscription.confirmationModal.removeBtnLbl') }
                isOpen = { openConfirmDeleteTeamFormModal } 
                onClose={() => { setOpenConfirmDeleteTeamFormModal( false ) }}
                modalTitle = { props.t('options.tournaments.pages.newTournament.tabs.tabInscription.confirmationModal.removeTitle') }
                modalContent = { props.t('options.tournaments.pages.newTournament.tabs.tabInscription.confirmationModal.removeTeamMessage') }
            />

            {/* Delete player form confirm dialog modal */}
            <ModalConfirmDialogComponent
                onPrincipalButtonClick = { ( ) => { setOpenConfirmDeletePlayerFormModal( false ) } }
                principalButtonLabel = { props.t('options.tournaments.pages.newTournament.tabs.tabInscription.confirmationModal.cancelBtnLbl') }
                secondaryButton = { true }
                onSecondaryButtonClick = { () => { deletePlayerForm() } }
                secondaryButtonLabel = { props.t('options.tournaments.pages.newTournament.tabs.tabInscription.confirmationModal.removeBtnLbl') }
                isOpen = { openConfirmDeletePlayerFormModal } 
                onClose={() => { setOpenConfirmDeletePlayerFormModal( false ) }}
                modalTitle = { props.t('options.tournaments.pages.newTournament.tabs.tabInscription.confirmationModal.removeTitle') }
                modalContent = { props.t('options.tournaments.pages.newTournament.tabs.tabInscription.confirmationModal.removePlayerMessage') }

            />

            {/* form existing modal */}
            <TournamentsModalFormExistingComponent
                isOpen={openExistingFormModal}
                onClose={() => {setExistingFormModal(false); props.handleCleanPageFormTemplate();}}
                handleListExistingForm= {(formType) => {props.handleListExistingForm(formType)}}
                listExistingForm={props.listExistingForm}
                handleOnLoadPageFormTemplate = { props.handleOnLoadPageFormTemplate }
            />

            <div className="tournament-create-new-tournament-v2__header">
                <span className="icon-arrow-left" onClick={() => props.handleBackButton() } />
                <div className="baloa-headline-3">
                    {props.t('options.tournaments.pages.newTournament.labelTitle')}
                </div>
            </div>
            <div className="tournament-create-new-tournament-v2__container">
                <div className="tournament-create-new-tournament-v2__stepper">
                    <div className='step-point__container'>
                        <div className={ "step-point" }>
                            <div className='baloa-username step-point-label-completed'>{props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelTitle')}</div>
                            <div className='point step-point-completed' />
                        </div>
                        <div className={ "step-point" }>
                            <div className={`baloa-username ${step2? 'step-point-label-completed': ''}`}>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.labelTitle')}</div>
                            <div className={`point ${step2? 'step-point-completed': ''}`} />
                        </div>
                        <div className={ "step-point" }>
                            <div className={`baloa-username ${step3? 'step-point-label-completed': ''}`}>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.labelPhaseSummary')}</div>
                            <div className={`point ${step3? 'step-point-completed': ''}`} />
                        </div>
                        {/* <div className={ "step-point" }>
                            <div className={`baloa-username ${step4? 'step-point-label-completed': ''}`}>{props.t('options.tournaments.pages.newTournament.tabs.tabInviteTeams.labelInviteTeams')}</div>
                            <div className={`point ${step4? 'step-point-completed': ''}`} />
                        </div> */}
                    </div>
                    <div className='step-line__container'>
                        <div className={ `step-line ${ step2? 'step-line-completed': ''}` } />
                        <div className={ `step-line ${ step3? 'step-line-completed': ''}` } />
                        {/* <div className={ `step-line ${ step4? 'step-line-completed': ''}` } /> */}
                    </div>
                </div>

                <FormComponent
                    formInitialValues={formInitialValues}
                    onSubmit={ ( values ) => {
                        handleFormData(values);
                    }}
                    
                    className="tournament-create-new-tournament-v2__form form"
                    functionDisabled={ (form_props) => { onBasciDataFormProps(form_props) } }
                    enableReinitialize={true}
                    innerRef={formRef}
                > 
                    { isBasicDataComponent &&
                        <TournamentBasicDataComponent
                            onResetError={ (data) => {props.onResetError(data)} }
                            errors={ props.errors }
                            league_page={ props.league_page }
                            basic_data_info={ props.basic_data_info }
                            cities={ props.cities }
                            countries={props.countries}
                            disableCities={props.disableCities}
                            handleSearchNationality={(new_nationality_search) => { props.handleSearchNationality( new_nationality_search) }}
                            handleSearchWorldCities={ ( searched_city ) => props.handleSearchWorldCities( searched_city ) }
                            tournament_tag={ props.tournament_tag }
                            handleTournamentPhotoB64={ (photo_b64, imageurl) => handleTournamentPhotoB64(photo_b64, imageurl) }
                            handleTournamentLogoB64={ (logo_b64, imageurl) => handleTournamentLogoB64(logo_b64, imageurl) }
                            tournament_football_type={ props.tournament_football_type }
                            tournament_photo_url={ tournamentPhotoUrl }
                            tournament_logo_url={ tournamentLogoUrl }
                            isTournamentLogo={ isTournamentLogo }
                            isTournamentPhoto={ isTournamentPhoto }
                            setFootbalTypeMinPlayers={ (football_type)=>setFootbalTypeMinPlayers(football_type) }
                            /* handleSelectDays={(array_days)=> handleSelectDays(array_days)}
                            handleSelectDayTime={(array_dayTime)=> handleSelectDayTime(array_dayTime)}
                            newFormatDays={newFormatDays}
                            newFormatTimeDay={newFormatTimeDay}    */                         
                            name={formRef.current?.values.name}
                            list_tournament_types={props.list_tournament_types}
                            fair_play_most_points_wins={formRef.current?.values.fair_play_most_points_wins}
                            fair_play_initial_points={formRef.current?.values.fair_play_initial_points}
                            />
                    }

                    { isInscriptionComponent &&
                        <TournamentInscriptionComponent 
                            inscription_errors={ props.inscription_errors }
                            handleOnInscriptionDataComponent={ () => handleFormDataSteps('inscription_data')}
                            handleFormCheckBtns={ (value, type) => handleFormCheckBtns(value, type)}
                            summonRegistrationType={ summonRegistrationType }
                            registrationType={ registrationType }
                            tournamentPrizeType={ tournamentPrizeType }
                            handleParticipantTeams={ (value, min_max) => handleParticipantTeams(value, min_max) }
                            minTeams={ minTeams }
                            maxTeams={ maxTeams }
                            handlePlayersByTeams= { (value, min_max) => handlePlayersByTeams(value, min_max) }
                            minPlayers={ minPlayers }
                            maxPlayers={ maxPlayers }
                            handlePrizeType={ (type) => handlePrizeType(type) }
                            materialPrize={ materialPrize }
                            monetaryPrize={ monetaryPrize }
                            handleRegistrationType={ (value, type) => { handleRegistrationType(value, type) }}
                            registrationTypePerTeam={ registrationTypePerTeam }
                            registrationTypePerPlayer={ registrationTypePerPlayer }
                            bankAccount={ bankAccount }
                            selected_country={formRef.current?.values.main_country}
                            footballTypeMin={footballTypeMin}
                            handleUnlimited= { (v)=>{ handleUnlimited(v) }}
                            isUnlimited={isUnlimited}
                            playerForm={playerForm}
                            teamForm={teamForm}
                            isTeamForm={isTeamForm}
                            isPlayerForm={isPlayerForm}
                            handlePaymentOption={(payment_opt) => handlePaymentOption(payment_opt)}
                            payment_option={payment_option}
                            handleMonetaryPrize ={(value) => handleMonetaryPrize(value)}
                            currency ={props.currency}
                            monetary_prize={monetary_prize}

                            handleCheckPaymentAccount={(account_type) => {handleCheckPaymentAccount(account_type)}}
                            handleConfigArbitrageModal={ (is_arbitrage_config) => { handleConfigArbitrageModal(is_arbitrage_config) } }
                            local_team_fee={localTeamFee}
                            visitant_team_fee={visitantTeamFee}
                            referee_fee_value={props.referee_fee_value}
                            is_referee_confing={arbitrageConfigFormValues.referee_payments}
                            handleInscriptionTypeProcessRate={(process_type) => {handleInscriptionTypeProcessRate(process_type)}}
                            inscription_type_process_rate={inscriptionTypeProcessRate}
                            create_stripe_account={props.create_stripe_account}
                            country_iata_code={props.countries[0]?.iata_code}

                            teamFormName={teamFormName}
                            playerFormName={playerFormName}
                            handleOnNewFormModal={(form_type)=>{ handleOnCreateFormModal(form_type)}}
                            handleOnLoadFormModal={(form_type)=>{handleOnExistingFormModal(form_type)}}//TODO: Ana Maria define la accion que carga el modal
                            handlePreviewTeamForm={ () => { props.handlePreviewForm(teamForm) }}
                            handlePreviewPlayerForm={ () => { props.handlePreviewForm(playerForm) }}
                            handleOnDeleteTeamForm={ () => { setOpenConfirmDeleteTeamFormModal( true ) }}
                            handleOnDeletePlayerForm={ () => { setOpenConfirmDeletePlayerFormModal( true ) }}
                        />
                    }

                    { /* showTeamForm &&
                        <TournamentDynamicFormMakerComponent 
                            onNewFormValues={(form, form_name)=>{
                                setNewTeamForm(handleDynamicFormData(form))
                                setTeamForm(form);
                                setTeamFormName(form_name);
                                handleFormDataSteps('team_form');
                            }}
                            form_title={props.t('options.tournaments.pages.newTournament.teamPlayerDynamicForm.teamFormTitle')}
                            form_description={props.t('options.tournaments.pages.newTournament.teamPlayerDynamicForm.teamFormLabel')}
                            submitTeamPlayerForm={submitTeamPlayerForm}
                            resetButton={ () => {setSubmitTeamPlayerForm(false)} }
                            formData={teamForm}
                            handlePreviewForm={ (teamForm) => { props.handlePreviewForm(teamForm) }} 
                        /> */
                    }

                    { /* showPlayerForm &&
                        <TournamentDynamicFormMakerComponent 
                            onNewFormValues={(form, form_name)=>{
                                setNewPlayerForm(handleDynamicFormData(form))
                                setPlayerForm(form);
                                setPlayerFormName(form_name);
                                handleFormDataSteps('player_form');                                
                            }}
                            form_title={props.t('options.tournaments.pages.newTournament.teamPlayerDynamicForm.playerFormTitle')}
                            form_description={props.t('options.tournaments.pages.newTournament.teamPlayerDynamicForm.playerFormLabel')}
                            submitTeamPlayerForm={submitTeamPlayerForm}
                            resetButton={ () => {setSubmitTeamPlayerForm(false)} }
                            formData={playerForm}
                            handlePreviewForm={ (playerForm) => { props.handlePreviewForm(playerForm) }}
                        /> */
                    }

                    { isGeneralSummayComponent &&
                        <TournamentGeneralSummayComponent
                            tournament={formState}
                            isUnlimited={isUnlimited}
                            currency ={props.currency}
                            registrationTypePerTeam={ registrationTypePerTeam }
                            registrationTypePerPlayer={ registrationTypePerPlayer }

                        />
                    }

                    { isBasicDataComponent &&  
                        <div className="tournament-create-new-tournament-v2__form__actions">
                            <SecondaryButtonComponent
                                type="button"
                                id="tournament-basic-data__back-btn"
                                onClick={ () => props.handleBackButton() } 
                                disabled={false}
                            >
                                <span>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelBackBtn')}</span>
                            </SecondaryButtonComponent>
                            <PrimaryButtonComponent
                                type="button"
                                disabled={!isBasicDataFormValid}
                                onClick={() => handleFormDataSteps('basic_data')}
                                className="tournament-create-new-tournament__submit-btn"                
                            >
                                <span>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelNextBtn')}</span>
                            </PrimaryButtonComponent>
                        </div>
                    }

                    { isInscriptionComponent &&  
                        <div className="tournament-create-new-tournament-v2__form__actions">
                            <SecondaryButtonComponent
                                type="button"
                                id="tournament-basic-data__back-btn"
                                onClick={ () => handleFormBackBtn('inscription_data') } 
                                disabled={false}
                            >
                                <span>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelBackBtn')}</span>
                            </SecondaryButtonComponent>
                            <PrimaryButtonComponent
                                type="button"
                                disabled={!isInscriptionDataFormValid}
                                onClick={()=>handleFormDataSteps('inscription_data')}
                                className="tournament-create-new-tournament__submit-btn"                
                            >
                                <span>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelNextBtn')}</span>
                            </PrimaryButtonComponent>
                        </div>
                    }

                    { /* showTeamForm &&  
                        <div className="tournament-create-new-tournament-v2__form__actions">
                            <SecondaryButtonComponent
                                type="button"
                                id="tournament-basic-data__back-btn"
                                onClick={ () => handleFormBackBtn('team_form') } 
                                disabled={false}
                            >
                                <span>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelBackBtn')}</span>
                            </SecondaryButtonComponent>
                            <PrimaryButtonComponent
                                type="button"
                                disabled={false}
                                //onClick={()=>handleFormDataSteps('team_form')}
                                onClick={()=>setSubmitTeamPlayerForm(true)}
                                className="tournament-create-new-tournament__submit-btn"                
                            >
                                <span>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelNextBtn')}</span>
                            </PrimaryButtonComponent>
                        </div> */
                    }

                    { /* showPlayerForm &&  
                        <div className="tournament-create-new-tournament-v2__form__actions">
                            <SecondaryButtonComponent
                                type="button"
                                id="tournament-basic-data__back-btn"
                                onClick={ () => handleFormBackBtn('player_form') } 
                                disabled={false}
                            >
                                <span>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelBackBtn')}</span>
                            </SecondaryButtonComponent>
                            <PrimaryButtonComponent
                                type="button"
                                disabled={false}
                                //onClick={()=>handleFormDataSteps('player_form')}
                                onClick={()=>setSubmitTeamPlayerForm(true)}
                                className="tournament-create-new-tournament__submit-btn"                
                            >
                                <span>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelNextBtn')}</span>
                            </PrimaryButtonComponent>
                        </div> */
                    }

                    { isGeneralSummayComponent &&  
                        <div className="tournament-create-new-tournament-v2__form__actions">
                            <SecondaryButtonComponent
                                type="button"
                                id="tournament-basic-data__back-btn"
                                onClick={ () => handleFormBackBtn('general_summay') } 
                                disabled={false}
                            >
                                <span>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelBackBtn')}</span>
                            </SecondaryButtonComponent>
                            <PrimaryButtonComponent
                                type="submit"
                                disabled={props.disabled_submit_button}
                                onClick={()=> {}}
                                className="tournament-create-new-tournament__submit-btn"                
                            >
                                <span>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelFinalizeBtn')}</span>
                            </PrimaryButtonComponent>
                        </div>
                    }
                </FormComponent>
                
            </div>
        </div>
    )
}

export default withTranslation('league')(TournamentCreateNewTournamentV2Component)

/*eslint eqeqeq: "off"*/
// React
import React from 'react';


// Styles
import './BaloaPlayMatchPaymentExtraClipsModalComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

//Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SumRestInputComponent from 'shared/components/sum-rest-input/SumRestInputComponent';
import FormComponent from 'shared/components/form/FormComponent';
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import CurrencyFormat from 'react-currency-format';

function BaloaPlayMatchPaymentExtraClipsModalComponent( props ) {

    const formInitialValues = {
        extra_clips: 1
    }

    const [planValue, setPlanValue] = React.useState(0);
    const [clipsNumber, setClipsNumber] = React.useState(1);


    const changeClips = ( clipsNumber) => {

        setClipsNumber(clipsNumber)
        let clipsValue = props.extra_clips_value * clipsNumber;

        setPlanValue( clipsValue);
    }

    React.useEffect(() => {
        if (props.isOpen) {
            setPlanValue(0);
            setClipsNumber(1);
        }
    }, [props.isOpen]);

    return(
        <ModalComponent
            isOpen={props.isOpen}
            onClose={props.onClose}
            className="baloa-play-match-payment-extra-clips-modal"
        >
            <div className={`baloa-play-match-payment-extra-clips-modal__container `}>

                        <div className='baloa-play-match-payment-confirmation-modal__header'>
                            <span className='hubicon-play_circle'/>
                            <div className='baloa-names'>{props.t('matchPaymentConfirmationModal.title')}</div>
                        </div>
                        <div className='baloa-table subtitle'>{props.t('matchPaymentExtraClipsModal.subTitle')}</div>
                        <FormComponent
                            formInitialValues = { formInitialValues }
                            onSubmit = { ( values, actions ) => { 
                                    
                                }
                            }
                            className = ""
                        >
                            <SumRestInputComponent
                                label={props.t('matchPaymentExtraClipsModal.clipsNumberLbl')} 
                                input_id="extra_clips" 
                                field_name="extra_clips"
                                //validationFunction={ DateFormatValidator }
                                disabled={false}
                                className={``}
                                input_type="text"
                                //resetError={ () => props.onResetError( { 'end_date': "" } ) }
                                //error={ props.errors }
                                message=""
                                placeholder={""}
                                defaultValue={1}
                                min={1}
                                onChange={ (v)=>{ changeClips(v) }}
                            />
                            
                        </FormComponent>


                                
                <React.Fragment>
                    <div className='baloa-play-match-payment-extra-clips-modal__option-container'>
                        <div className='baloa-play-match-payment-extra-clips-modal__option-header__title'>
                            <div className='baloa-names resumen'>{props.t('matchPaymentConfirmationModal.purchaseSelected')}</div>
                            <span className='hubicon-video_library'/>
                            <div className='baloa-names'>{props.t('matchPaymentExtraClipsModal.product')}</div>
                        </div>
                        <div className='baloa-table'>{props.t('matchPaymentConfirmationModal.includesLbl')}</div>
                            

                        <li className="baloa-table lista2" dangerouslySetInnerHTML={{
                            __html: props.t('matchPaymentConfirmationModal.individualPlanListItem1Lbl',{_create_clips_max: clipsNumber})
                        }}></li>
                        <li className="baloa-table lista2" dangerouslySetInnerHTML={{
                            __html: props.t('matchPaymentConfirmationModal.individualPlanListItem2Lbl',{_download_clips_max: clipsNumber})
                        }}></li>
                            

                        

                        <div className='baloa-play-match-payment-extra-clips-modal__option-total'>
                            <div className='baloa-table'>{props.t('matchPaymentConfirmationModal.totalLbl')}&nbsp;</div>
                            <CurrencyFormat
                                value={planValue} 
                                displayType={'text'} 
                                thousandSeparator={'.'} 
                                decimalSeparator={","} 
                                prefix={'$'} 
                                suffix={' ' + `${props.extra_clips_currency}`}
                                //onValueChange ={}
                                renderText={value => <div className='baloa-subtitle-2'>{`${value}`}</div>}
                            />
                        </div> 
                    </div>
                    
                </React.Fragment>
                
            </div>


                <PrimaryButtonComponent
                    input_type={'button'}
                    onClick={ ()=>{ props.handleOnPay( clipsNumber  ) } }
                    disabled={clipsNumber === 0}
                >
                    <span>{props.t('matchPaymentConfirmationModal.payBtnLbl')}</span>
                </PrimaryButtonComponent>
    
        </ModalComponent>
    )
}

export default withTranslation('baloaplay')(BaloaPlayMatchPaymentExtraClipsModalComponent);
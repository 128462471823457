/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Styles
import './TournamentPositionGoalScorerTableComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// Material UI
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Skeleton } from '@mui/material';
import { isMobile } from 'react-device-detect';


function TournamentPositionGoalScorerTableComponent(props){ 

    const [groupExpanded, setGroupExpanded] = React.useState('group0');

    const handleGroupsChange = ( panel, group_id ) => (event, newGroupExpanded) => {
        if(newGroupExpanded){
            //props.handleOnDateGroup( props.active_phase_id, props.selectedDate, group_number)
            props.handleOnPhaseGroupPositionTable(group_id)
        }
        setGroupExpanded(newGroupExpanded ? panel : false);
    }

    const listPositionFreeTableHtml = 
            props.loading_phase_group_positions_table?
                <div className='tournament-position-goal-scorer-table__container'>                    
                    <table className='tournament-position-goal-scorer-table'>
                        <thead className="tournament-position-goal-scorer-table__table-header skeleton">
                            <Skeleton animation="wave" component="th" width={isMobile? 67:185} height={isMobile? 11:16} />
                            <Skeleton animation="wave" component="th" width={isMobile? 175:281} height={isMobile? 11:16}/>
                        </thead>
                        <tbody className="tournament-position-goal-scorer-table__table-body">
                            {[...Array(Number(5))].map((e, i) => 
                                <tr>
                                <div className='tournament-position-goal-scorer-table__skeleton-row'>
                                    <div className='tournament-position-goal-scorer-table__team-name'>
                                        <Skeleton animation="wave" variant="circular" component="div" width={isMobile? 24:40} height={isMobile? 24:40} style={{ marginRight: 10 }}/>
                                        <Skeleton animation="wave" variant="rounded" component="div" width={isMobile? 118:185} height={isMobile? 11:16} style={{  }}/>                                            
                                    </div>                            
                                    <Skeleton animation="wave" variant="rounded" component="div" width={isMobile? 172:281} height={isMobile? 11:16} style={{ borderRadius: 20 }}/>                                        
                                </div>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                :
                <div className='tournament-position-goal-scorer-table__container'>
                    {isMobile && 
                        <div className='tournament-position-goal-scorer-table__free-title baloa-names'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.labelLeague')}</div>
                    }
                    <table className='tournament-position-goal-scorer-table'>
                        <thead className="tournament-position-goal-scorer-table__table-header">
                            <th><div className="baloa-table">{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.columns.labelTeam')}</div></th>
                            <th><div className="baloa-table">{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.columns.labelPoints')}</div></th>
                            <th><div className="baloa-table">{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.columns.labelPlayed')}</div></th>
                            <th><div className="baloa-table">{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.columns.labelWon')}</div></th>
                            <th><div className="baloa-table">{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.columns.labelDrawn')}</div></th>
                            <th><div className="baloa-table">{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.columns.labelLost')}</div></th>
                            <th><div className="baloa-table">{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.columns.goalsOnFavor')}</div></th>
                            <th><div className="baloa-table">{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.columns.goalsAgainst')}</div></th>
                            <th><div className="baloa-table">{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.columns.labelGoalDiff')}</div></th>
                            <th><div className="baloa-table">{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.columns.labelFairPlay')}</div></th>
                        </thead>
                        <tbody className="tournament-position-goal-scorer-table__table-body">
                            { props.tournament_results_table?.map( (position, index) => {
                                return(
                                    <React.Fragment>
                                        { !position.disabled &&
                                            <tr key={index} >
                                                <td className="tournament-position-goal-scorer-table__team-name">
                                                    <div className={ ( 
                                                        !position.best_third && position.classified 
                                                        ? "tournament-position-goal-scorer-table__vertical-line__clasified-team" 
                                                        : "tournament-position-goal-scorer-table__vertical-line" 
                                                    ) } />
                                                    <div className={ "baloa-body-2 " }>{index + 1}</div>
                                                    { position.photo
                                                        ?
                                                        <img src={ position.photo } alt="" className="table-players-scored-item__img"/>
                                                        :
                                                        <span className="icon-ball" color={getRandonIconBallColor()} />
                                                    }
                                                    <div className="baloa-table">{position.page_name}</div> 
                                                </td>                                            
                                                <td><div className="baloa-table">{position.points}</div></td>
                                                <td><div className="baloa-table">{position.PJ}</div></td>
                                                <td><div className="baloa-table">{position.PG}</div></td>
                                                <td><div className="baloa-table">{position.PE}</div></td>
                                                <td><div className="baloa-table">{position.PP}</div></td>
                                                <td><div className="baloa-table">{position.GF}</div></td>
                                                <td><div className="baloa-table">{position.GC}</div></td>
                                                <td><div className="baloa-table">{position.GD}</div></td>
                                                <td><div className="baloa-table">{position.FP}</div></td>
                                            </tr>
                                        }
                                        { position.disabled &&
                                            <tr key={index} className="disabled-team">
                                                <td className="table-position-clasified-team__item"> 
                                                    <div className="baloa-table">{index + 1}</div>
                                                    { position.photo
                                                        ?
                                                        <img src={ position.photo } alt="" className="table-players-scored-item__img"/>
                                                        :
                                                        <span className="icon-ball" color={getRandonIconBallColor()} />
                                                    }
                                                </td>
                                                <td className='disabled'><div className="baloa-table">{position.page_name}</div></td>
                                                <td className='disabled'><div className="baloa-table">{position.points}</div></td>
                                                <td></td>
                                                <td className='disabled-msg'><div className='baloa-table'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.columns.disabledTeam')}</div></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        }
                                    </React.Fragment>
                                )
                            })}
                        </tbody>
                    </table>
                </div>            
        ;

    const listPositionGroupTableHtml = props.phase_groups_results_table?.map((group, index) => {
        return(
            <Accordion
                key={index} 
                onChange={ handleGroupsChange(`group${index}`, group.id) } 
                expanded={ groupExpanded === `group${index}` }
                className='tournament-position-goal-scorer-table__accordion-item-group'
            >
                <AccordionSummary
                    expandIcon={ <ExpandMoreIcon /> }
                    aria-label="Expand"
                    aria-controls={ `aria_controls_group_${index}` }
                    id={ `group_${index}` }
                    className='tournament-position-goal-scorer-table__accordion-item-group__summary'
                >                                        
                    <div className='baloa-normal-medium'>
                        {(props.phase_type === 'Groups' || props.phase_type === 'Free' ) 
                            ?
                                props.t(`league:options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelGroupNumber.${group.caption.split(" ")[0]}`,{_number: group.caption.split(" ")[1]})
                            :
                                props.t(`league:options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelKnockOutNumber.${group.caption.split(" ")[0]}`, {phase_name: props.t(`league:options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.${props.phase_name}`), _number: group.caption.split(" ")[1] })
                        }
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    {props.loading_phase_group_positions_table?
                        <div className='tournament-position-goal-scorer-table__container'>                    
                            <table className='tournament-position-goal-scorer-table'>
                                <thead className="tournament-position-goal-scorer-table__table-header skeleton">
                                    <Skeleton animation="wave" component="th" width={isMobile? 67:185} height={isMobile? 11:16} />
                                    <Skeleton animation="wave" component="th" width={isMobile? 175:281} height={isMobile? 11:16}/>
                                </thead>
                                <tbody className="tournament-position-goal-scorer-table__table-body">
                                    {[...Array(Number(5))].map((e, i) => 
                                        <tr>
                                        <div className='tournament-position-goal-scorer-table__skeleton-row'>
                                            <div className='tournament-position-goal-scorer-table__team-name'>
                                                <Skeleton animation="wave" variant="circular" component="div" width={isMobile? 24:40} height={isMobile? 24:40} style={{ marginRight: 10 }}/>
                                                <Skeleton animation="wave" variant="rounded" component="div" width={isMobile? 118:185} height={isMobile? 11:16} style={{  }}/>                                            
                                            </div>                            
                                            <Skeleton animation="wave" variant="rounded" component="div" width={isMobile? 172:281} height={isMobile? 11:16} style={{ borderRadius: 20 }}/>                                        
                                        </div>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        :
                        <div className='tournament-position-goal-scorer-table__container'>                    
                            <table className='tournament-position-goal-scorer-table'>
                                <thead className="tournament-position-goal-scorer-table__table-header">
                                    <th className="baloa-table" >{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.columns.labelTeam')}</th>
                                    <th className="baloa-table" >{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.columns.labelPoints')}</th>
                                    <th className="baloa-table" >{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.columns.labelPlayed')}</th>
                                    <th className="baloa-table" >{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.columns.labelWon')}</th>
                                    <th className="baloa-table" >{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.columns.labelDrawn')}</th>
                                    <th className="baloa-table" >{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.columns.labelLost')}</th>
                                    <th className="baloa-table" >{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.columns.goalsOnFavor')}</th>
                                    <th className="baloa-table" >{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.columns.goalsAgainst')}</th>
                                    <th className="baloa-table" >{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.columns.labelGoalDiff')}</th>
                                    <th className="baloa-table" >{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.columns.labelFairPlay')}</th>
                                </thead>
                                <tbody className="tournament-position-goal-scorer-table__table-body">
                                    { props.tournament_results_table?.map( (position, index) => {
                                        return (
                                            <React.Fragment>
                                                { !position.disabled &&
                                                    <tr key={index} >
                                                        <td className="tournament-position-goal-scorer-table__team-name">
                                                            <div className={ ( 
                                                                position.best_third && position.classified 
                                                                ? "tournament-position-goal-scorer-table__vertical-line__best-third-team" 
                                                                : !position.best_third && position.classified 
                                                                ? "tournament-position-goal-scorer-table__vertical-line__clasified-team" 
                                                                : "tournament-position-goal-scorer-table__vertical-line" 
                                                            ) } />
                                                            <div className={ "baloa-body-2 " }>{index + 1}</div>
                                                            { position.photo
                                                                ?
                                                                <img src={ position.photo } alt="" className="table-players-scored-item__img"/>
                                                                :
                                                                <span className="icon-ball" color={getRandonIconBallColor()} />
                                                            }
                                                            <div className="baloa-table">{position.page_name}</div> 
                                                        </td>
                                                        <td className='baloa-table'>{position.points}</td>
                                                        <td className='baloa-table'>{position.PJ}</td>
                                                        <td className='baloa-table'>{position.PG}</td>
                                                        <td className='baloa-table'>{position.PE}</td>
                                                        <td className='baloa-table'>{position.PP}</td>
                                                        <td className='baloa-table'>{position.GF}</td>
                                                        <td className='baloa-table'>{position.GC}</td>
                                                        <td className='baloa-table'>{position.GD}</td>
                                                        <td className='baloa-table'>{position.FP}</td>
                                                    </tr>
                                                }
                                                { position.disabled &&
                                                    <tr key={index} className="disabled-team">
                                                        <td className="table-position-clasified-team__item"> 
                                                            <div className="baloa-table">{index + 1}</div>
                                                            { position.photo
                                                                ?
                                                                <img src={ position.photo } alt="" className="table-players-scored-item__img"/>
                                                                :
                                                                <span className="icon-ball" color={getRandonIconBallColor()} />
                                                            }
                                                        </td>
                                                        <td className='disabled'><div className="baloa-table">{position.page_name}</div></td>
                                                        <td className='disabled'><div className="baloa-table">{position.points}</div></td>
                                                        <td></td>
                                                        <td className='disabled-msg'><div className="baloa-table">{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.columns.disabledTeam')}</div></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                }
                                            </React.Fragment>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    }
                </AccordionDetails>
            </Accordion>
        )   
    });

    return(
        <div className="container-position-goal-scorer-table">
            { props.phase_type === "Free" && 
                <div className="col-left">
                    { listPositionFreeTableHtml }
                </div>
            }
            { (props.phase_type === "Groups" || props.phase_type === "Knockout") &&
                <div className="col-left">
                    { listPositionGroupTableHtml }
                </div>
            }
        </div>
    )
}

export default withTranslation('league')(TournamentPositionGoalScorerTableComponent);
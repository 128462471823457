// React
import React from 'react';

//Material UI
import { Skeleton } from '@mui/material';

// Styles
import './TournamentStatisticsSkeletonComponent.styles.css';

//isMobile
import { isMobile } from 'react-device-detect';


function TournamentStatisticsSkeletonComponent(props){ 
    return(
        <React.Fragment>
        {isMobile &&
            <div className='tournament-statistics-skeleton__scorers-row' >
                <Skeleton animation="wave" variant="circular" component="div" width={30} height={30} style={{  }}/>
                <div className='tournament-statistics-skeleton__scorers-row__skeleton-group'>
                    <Skeleton animation="wave" variant="rounded" component="div" width={127} height={11} style={{  }}/>
                </div>
                <Skeleton className='tournament-statistics-skeleton__scorers-row__goals baloa-normal-medium' animation="wave" variant="squared" component="div" width={17} height={11} style={{  }}/>
            </div>
        }
        {!isMobile &&
            <div className='tournament-statistics-skeleton__scorers-row' >
                <Skeleton className='tournament-statistics-skeleton__scorers-row__number baloa-normal-medium' animation="wave" variant="squared" component="div" width={24} height={24} style={{  }}/>
                {props.summary?
                    <Skeleton animation="wave" variant="rounded" component="div" width={208} height={12} style={{  }}/>
                    :
                    <React.Fragment>
                        <Skeleton animation="wave" variant="circular" component="div" width={42} height={42} style={{  }}/>
                        <div className='tournament-statistics-skeleton__scorers-row__skeleton-group'>
                            <Skeleton animation="wave" variant="rounded" component="div" width={208} height={12} style={{  }}/>
                            <Skeleton animation="wave" variant="rounded" component="div" width={162} height={11} style={{ marginTop: 8 }}/>
                        </div>
                    </React.Fragment>
                }
                
                <Skeleton className='tournament-statistics-skeleton__scorers-row__goals baloa-normal-medium' animation="wave" variant="squared" component="div" width={24} height={24} style={{  }}/>
            </div>
        }
        </React.Fragment>
    )
}
    
export default TournamentStatisticsSkeletonComponent;
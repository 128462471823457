/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Components
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

// Styles
import './TournamentPhasesFairPlaySettingsComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

// React device detect
import {isMobile} from 'react-device-detect';


function TournamentPhasesFairPlaySettingsComponent(props){

    const [showMenu, setShowMenu] = React.useState(false);

    return(
        <div className='phases-fair-play-settings__container'>
            <div className='phases-fair-play-settings__header'>
                <div className='baloa-normal-medium'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.scoringAndFairPlay.title')}</div>
                {!props.hide_buttons &&
                    <React.Fragment>
                        <div className={`phases-fair-play-settings__button-create ${!props.editable_phase_fair_play? 'disabled' : ''}`} >
                            <div class={`phases-fair-play-settings__tooltip`} >
                                <span className='baloa-table-column'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.scoringAndFairPlay.tooltipMsg')}</span>
                            </div>
                            <SecondaryButtonComponent
                                type={'button'}
                                onClick={ ()=>{ setShowMenu(!showMenu)} }
                                disabled={!props.editable_phase_fair_play}                    
                            >                       
                                <span className='hubicon-add_option icon'/>
                                <span>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.scoringAndFairPlay.createBtnLbl')}</span>
                            </SecondaryButtonComponent>
                        </div>
                        <div className={`phases-fair-play-settings__header-menu-options ${showMenu? 'show_menu': ''}`}>
                            <div className='phases-fair-play-settings__header-menu-options__option baloa-names' onClick={()=>{props.handleOnCreateFairPlaySettings(); setShowMenu(!showMenu)}}>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.scoringAndFairPlay.createNewLbl')}</div>
                            <div className='phases-fair-play-settings__header-menu-options__option baloa-names' onClick={()=>{props.handleOnEditFairPlaySettings(); setShowMenu(!showMenu)}}>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.scoringAndFairPlay.createFromOtherLbl')}</div>
                        </div>
                    </React.Fragment>
                }
            </div>
            {!props.hide_fair_play_setting &&
                <div className='phases-fair-play-settings__select-row'>                
                    <div className='baloa-table'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.scoringAndFairPlay.selectedSettingLbl')}</div>
                    <div className={`phases-fair-play-settings__select-control baloa-names ${!props.editable_phase_fair_play? 'disabled' : ''}`} onClick={()=>{ props.handleOnChangeSetting() }}>{props.selected_fair_play_setting?.name}<span className='hubicon-expand_more'/></div>
                </div>
            }
            
            <div className='phases-fair-play-settings__resume-row'>
                <div className='phases-fair-play-settings__resume-column'>
                    <div className='phases-fair-play-settings__resume-option'>
                        <div className='phases-fair-play-settings__resume-option__title baloa-names'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.scoringAndFairPlay.matchPointsSubtitle')}</div>
                        <div className='phases-fair-play-settings__resume-option__settings'>
                            <div className='phases-fair-play-settings__resume-option__set baloa-table'>
                                <div>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.scoringAndFairPlay.pointsWinLbl')}</div>
                                <div className='phases-fair-play-settings__resume-option__set-value'>{props.selected_fair_play_setting?.points_win}</div>
                                <div>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.scoringAndFairPlay.pointsTieLbl')}</div>
                                <div className='phases-fair-play-settings__resume-option__set-value'>{props.selected_fair_play_setting?.points_draw}</div>
                                <div>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.scoringAndFairPlay.pointsLoseLbl')}</div>
                                <div className='phases-fair-play-settings__resume-option__set-value'>{props.selected_fair_play_setting?.points_loss}</div>
                            </div>
                        </div>
                    </div>
                    <div className='phases-fair-play-settings__resume-option'>
                        <div className='phases-fair-play-settings__resume-option__title baloa-names'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.scoringAndFairPlay.penaltyPoints')}</div>
                        <div className='phases-fair-play-settings__resume-option__settings'>
                            <div className='phases-fair-play-settings__resume-option__set baloa-table'>
                                <div>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.scoringAndFairPlay.pointsWinLbl')}</div>
                                <div className='phases-fair-play-settings__resume-option__set-value'>{props.selected_fair_play_setting?.points_win_penalty}</div>
                                <div>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.scoringAndFairPlay.pointsLoseLbl')}</div>
                                <div className='phases-fair-play-settings__resume-option__set-value'>{props.selected_fair_play_setting?.points_loss_penalty}</div>
                            </div>
                        </div>
                    </div>                    
                </div>
                <div className='phases-fair-play-settings__resume-column-2'>
                    <div className='phases-fair-play-settings__resume-option'>
                        <div className='phases-fair-play-settings__resume-option__title baloa-names'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.scoringAndFairPlay.cardsPoints')}</div>
                        <div className='phases-fair-play-settings__resume-option__settings'>
                            <div className='phases-fair-play-settings__resume-option__set baloa-table'>
                                <div>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.scoringAndFairPlay.yellowLbl')}</div>
                                <div className='phases-fair-play-settings__resume-option__set-value'>{props.selected_fair_play_setting?.points_yellow_card}</div>
                                <div>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.scoringAndFairPlay.doubleYellowLbl')}</div>
                                <div className='phases-fair-play-settings__resume-option__set-value'>{props.selected_fair_play_setting?.points_double_yellow_card}</div>
                                <div>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.scoringAndFairPlay.redLbl')}</div>
                                <div className='phases-fair-play-settings__resume-option__set-value'>{props.selected_fair_play_setting?.points_red_card}</div>
                                <div>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.scoringAndFairPlay.blueLbl')}</div>
                                <div className='phases-fair-play-settings__resume-option__set-value'>{props.selected_fair_play_setting?.points_blue_card}</div>
                            </div>
                        </div>
                    </div>
                    <div className='phases-fair-play-settings__resume-option'>
                        <div className='phases-fair-play-settings__resume-option__title baloa-names'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.scoringAndFairPlay.cardsPenaltyTime')}</div>
                        <div className='phases-fair-play-settings__resume-option__settings'>
                            <div className={`phases-fair-play-settings__resume-option__set baloa-table ${isMobile? 'view-column' : ''}`}>
                                <div className='phases-fair-play-settings__resume-option__set-group'>
                                    <div>{props.selected_fair_play_setting?.sanction_yellow_card}</div>&nbsp;
                                    <div className='phases-fair-play-settings__resume-option__set-card'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.scoringAndFairPlay.yellowLbl')}</div>&nbsp;
                                    <div>{props.selected_fair_play_setting?.sanction_match_yellow_card}</div>
                                    <div className='phases-fair-play-settings__resume-option__set-value'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.scoringAndFairPlay.matchLbl')}</div>                                
                                </div>
                                    <div className='phases-fair-play-settings__resume-option__set-group'>
                                    <div>{props.selected_fair_play_setting?.sanction_double_yellow_card}</div>&nbsp;
                                    <div className='phases-fair-play-settings__resume-option__set-card'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.scoringAndFairPlay.doubleYellowLbl')}</div>&nbsp;
                                    <div>{props.selected_fair_play_setting?.sanction_match_double_yellow_card}</div>
                                    <div className='phases-fair-play-settings__resume-option__set-value'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.scoringAndFairPlay.matchLbl')}</div>                                
                                </div>
                                <div className='phases-fair-play-settings__resume-option__set-group'>
                                    <div>{props.selected_fair_play_setting?.sanction_red_card}</div>&nbsp;
                                    <div className='phases-fair-play-settings__resume-option__set-card'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.scoringAndFairPlay.redLbl')}</div>&nbsp;
                                    <div>{props.selected_fair_play_setting?.sanction_match_red_card}</div>
                                    <div className='phases-fair-play-settings__resume-option__set-value'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.scoringAndFairPlay.matchLbl')}</div>                                
                                </div>
                                <div className='phases-fair-play-settings__resume-option__set-group'>
                                    <div>{props.selected_fair_play_setting?.sanction_blue_card}</div>&nbsp;
                                    <div className='phases-fair-play-settings__resume-option__set-card'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.scoringAndFairPlay.blueLbl')}</div>&nbsp;
                                    <div>{props.selected_fair_play_setting?.sanction_match_blue_card}</div>
                                    <div className='phases-fair-play-settings__resume-option__set-value'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.scoringAndFairPlay.matchLbl')}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default withTranslation('league')(TournamentPhasesFairPlaySettingsComponent)


// React
import React from 'react';
import { Link } from 'react-router-dom';

// Components
import ModalComponent from 'shared/components/modal/ModalComponent';
//import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';

// Styles
import './BaloaProModalComponent.styles.css';

//Images
//import goal from 'assets/images/goal.svg';

//i18
import { withTranslation } from 'react-i18next';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';


function BaloaProModalComponent( props ) {

    const handleViewChanges=()=>{
        const newWindow = window.open('https://baloa.notion.site/CAMBIOS-BALOA-PRO-16564fa79c288092aa93f6dea1073515', '_new', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null;
        props.onClose();
    }

    return (
        <ModalComponent 
            isOpen={props.isOpen}
            //onClose={props.onClose}
            className={'baloa-pro-modal'}
        >
            <div className='baloa-pro-modal__container'>
                <div className='baloa-pro-modal__title'>{props.t('baloaProModal.title')}</div>
                <div className="baloa-pro-modal__message" dangerouslySetInnerHTML={{
                    __html: props.t('baloaProModal.message',{_date: props.expiration_date})
                }}></div>
                <PrimaryButtonComponent
                    input_type={'button'}
                    onClick={ ()=>{ handleViewChanges() }}
                >
                    <span>{props.t('baloaProModal.consultBtnLbl')}</span>
                </PrimaryButtonComponent>
                <TextButtonComponent
                    input_type={'button'}
                    onClick={() => {props.onClose()}}
                >
                    <span>{props.t('baloaProModal.closeBtnLbl')}</span>
                </TextButtonComponent>
            </div>            
        </ModalComponent>
    )
}
    
export default withTranslation('signUp')(BaloaProModalComponent);
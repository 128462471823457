/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// React device detect
import { isMobile } from 'react-device-detect';

// Styles
import './BaloaProFunctionalitiesComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

function BaloaProFunctionalitiesComponent( props ) {

    return(
        <div className={`baloa-pro-functionalities__container ${props.className}`}>
            <div className='baloa-pro-functionalities__row'>
                <div className='baloa-pro-functionalities__row-text-title'>{props.t('baloaProFunctionalities.title')}</div>
                <div className='baloa-pro-functionalities__row-text-title2'>{props.t('baloaProFunctionalities.freeSubTitle')}</div>
                <div className='baloa-pro-functionalities__row-text-icon'>
                    {props.t('baloaProFunctionalities.proSubTitle')}
                    <span className='hubicon-award_star'/>
                </div>
            </div>
            <div className='baloa-pro-functionalities__row'>
                <div className='baloa-pro-functionalities__row-text'>{props.t('baloaProFunctionalities.rowFunc1')}</div>
                <div className='baloa-pro-functionalities__row-icon'>
                    <span className='hubicon-close'/>
                </div>
                <div className='baloa-pro-functionalities__row-icon'>
                    <span className='hubicon-check1'/>
                </div>
            </div>
            <div className='baloa-pro-functionalities__row'>
                <div className='baloa-pro-functionalities__row-text'>{props.t('baloaProFunctionalities.rowFunc2')}</div>
                <div className='baloa-pro-functionalities__row-icon'>
                    <span className='hubicon-close'/>
                </div>
                <div className='baloa-pro-functionalities__row-icon'>
                    <span className='hubicon-check1'/>
                </div>
            </div>
            <div className='baloa-pro-functionalities__row'>
                <div className='baloa-pro-functionalities__row-text'>{props.t('baloaProFunctionalities.rowFunc3')}</div>
                <div className='baloa-pro-functionalities__row-icon'>
                    <span className='hubicon-close'/>
                </div>
                <div className='baloa-pro-functionalities__row-icon'>
                    <span className='hubicon-check1'/>
                </div>
            </div>
            <div className='baloa-pro-functionalities__row'>
                <div className='baloa-pro-functionalities__row-text'>{props.t('baloaProFunctionalities.rowFunc4')}</div>
                <div className='baloa-pro-functionalities__row-icon'>
                    <span className='hubicon-close'/>
                </div>
                <div className='baloa-pro-functionalities__row-icon'>
                    <span className='hubicon-check1'/>
                </div>
            </div>
            <div className='baloa-pro-functionalities__row'>
                <div className='baloa-pro-functionalities__row-text'>{props.t('baloaProFunctionalities.rowFunc5')}</div>
                <div className='baloa-pro-functionalities__row-icon'>
                    <span className='hubicon-close'/>
                </div>
                <div className='baloa-pro-functionalities__row-icon'>
                    <span className='hubicon-check1'/>
                </div>
            </div>
            {/* <div className='baloa-pro-functionalities__row'>
                <div className='baloa-pro-functionalities__row-text'>{props.t('baloaProFunctionalities.rowFunc6')}</div>
                <div className='baloa-pro-functionalities__row-icon'>
                    <span className='hubicon-close'/>
                </div>
                <div className='baloa-pro-functionalities__row-icon'>
                    <span className='hubicon-check1'/>
                </div>
            </div> */}
            <div className='baloa-pro-functionalities__row'>
                <div className="baloa-pro-functionalities__row-text" dangerouslySetInnerHTML={{
                    __html: props.t('baloaProFunctionalities.rowFunc7')
                }}></div>
                <div className='baloa-pro-functionalities__row-icon'>
                    <span className='hubicon-close'/>
                </div>
                <div className='baloa-pro-functionalities__row-icon'>
                    <span className='hubicon-check1'/>
                </div>
            </div>
            <div className='baloa-pro-functionalities__row'>
                <div className='baloa-pro-functionalities__row-text'>{props.t('baloaProFunctionalities.rowFunc8')}</div>
                <div className='baloa-pro-functionalities__row-icon'>
                    <span className='hubicon-close'/>
                </div>
                <div className='baloa-pro-functionalities__row-icon'>
                    <span className='hubicon-check1'/>
                </div>
            </div>
            <div className='baloa-pro-functionalities__row'>
                <div className='baloa-pro-functionalities__row-text'>{props.t('baloaProFunctionalities.rowFunc9')}</div>
                <div className='baloa-pro-functionalities__row-icon'>
                    <span className='hubicon-close'/>
                </div>
                <div className='baloa-pro-functionalities__row-icon'>
                    <span className='hubicon-check1'/>
                </div>
            </div>
            <div className='baloa-pro-functionalities__row'>
                <div className='baloa-pro-functionalities__row-text'>{props.t('baloaProFunctionalities.rowFunc13')}</div>
                <div className='baloa-pro-functionalities__row-icon'>
                    <span className='hubicon-close'/>
                </div>
                <div className='baloa-pro-functionalities__row-icon'>
                    <span className='hubicon-check1'/>
                </div>
            </div>
            <div className='baloa-pro-functionalities__row'>
                <div className='baloa-pro-functionalities__row-text'>{props.t('baloaProFunctionalities.rowFunc10')}</div>
                <div className='baloa-pro-functionalities__row-icon'>
                    <span className='hubicon-close'/>
                </div>
                <div className='baloa-pro-functionalities__row-icon'>
                    <span className='hubicon-check1'/>
                </div>
            </div>
            <div className='baloa-pro-functionalities__row'>
                <div className='baloa-pro-functionalities__row-text'>{props.t('baloaProFunctionalities.rowFunc11')}</div>
                <div className='baloa-pro-functionalities__row-icon'>
                    <span className='hubicon-close'/>
                </div>
                <div className='baloa-pro-functionalities__row-icon'>
                    <span className='hubicon-check1'/>
                </div>
            </div>
            <div className='baloa-pro-functionalities__row'>
                <div className='baloa-pro-functionalities__row-text'>{props.t('baloaProFunctionalities.rowFunc12')}</div>
                <div className='baloa-pro-functionalities__row-icon'>
                    <span className='hubicon-close'/>
                </div>
                <div className='baloa-pro-functionalities__row-icon'>
                    <span className='hubicon-check1'/>
                </div>
            </div>
        </div>
    )
}

export default withTranslation('baloapro')(BaloaProFunctionalitiesComponent);
/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// React device detect
import { isMobile } from 'react-device-detect';

// Styles
import './BaloaPlayClipComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

//Components

//Material
import { Skeleton } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

function BaloaPlayClipComponent( props ) {

    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 10,
        marginTop: 6,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: '#D9D9D9',
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 10,
            backgroundColor: 'var(--new-green-color)',
        },
    }));

    return(
        <div className='baloa-play-clip__container'>
            <div className='baloa-play-clip__left-section'>
                <div className='baloa-normal-medium'>{props.clip?.post_video_caption}</div>
                {!props.clip?.post_stream?.thumbnail &&
                    <div className='baloa-play-clip__info'>
                        <span className='hubicon-info'/>
                        <div className='baloa-table'>{props.t('processingClipMsg')}</div>
                    </div>
                }
                <div className='baloa-play-clip__actions'>
                        <div className='baloa-play-clip__actions-image'>
                            {props.clip?.post_stream?.thumbnail?
                                <img src={props.clip?.post_stream?.thumbnail} alt={props.clip?.post_video_caption}/>
                                :
                                <Skeleton sx={{ height: 182, borderRadius: 2 }} animation="wave" variant="rectangular" />
                            }
                            
                        </div>
                        <div className='baloa-play-clip__actions-items'>
                            <div className={`baloa-play-clip__actions-items__action ${props.clip?.post_stream?.thumbnail? '' : 'disabled'}`} onClick={()=>{ props.handleOnPlayClip()}}>
                                <span className='hubicon-play_circle1'/>
                                <div className='baloa-names'>{props.t('clipsActions.playClip')}</div>
                            </div>                            
                            <div className={`baloa-play-clip__actions-items__action ${props.clip?.post_stream?.thumbnail? '' : 'disabled'}`} onClick={ ()=>{ (props.clip?.is_maximum_clips_downloaded && !props.clip?.clip_has_been_downloaded)? props.handleOnMaxDownloadClips() : props.handleOnDownloadClip( props.clip?.id, props.clip?.post_video_caption ) } }>
                                <span className='hubicon-download'/>
                                <div className='baloa-names'>{props.t('clipsActions.downloadClip')}</div>
                            </div>
                            
                            <div className='baloa-play-clip__actions-items__action' onClick={()=>{props.handleOnEditClip()}}>
                                <span className='hubicon-edit1'/>
                                <div className='baloa-names'>{props.t('clipsActions.editClip')}</div>
                            </div>
                        </div>
                    
                </div>
                {props.max_download_clips > 0 && props.max_download_clips < 10000 &&
                    <div className='baloa-play-clip__info download'>
                        <span className='hubicon-info'/>
                        <div className='baloa-play-clip__info__conditions'>
                            <div className='baloa-table'>{props.t('clipsDownloadCounter.condition1',{_max_download_clips: props.max_download_clips})}</div>
                            <div className='baloa-table'>{props.t('clipsDownloadCounter.condition2')}</div>
                            <div className='baloa-table'>{props.t('clipsDownloadCounter.condition3')}</div>
                        </div>
                        <BorderLinearProgress variant="determinate" value={(props.downloaded_clips /props.max_download_clips)*100} />
                        <div className='baloa-table counter'>{props.t('clipsDownloadCounter.downloadCounter')}<span className='value'>{props.t('clipsDownloadCounter.downloadCounterValue',{_downloaded_clips: props.downloaded_clips, _max_download_clips: props.max_download_clips})}</span></div>
                    </div>
                }
                <div className='baloa-play-clip__options'>                    
                    <div className={`baloa-play-clip__options__option ${props.clip?.post_stream?.thumbnail? '' : 'disabled'}`} onClick={ props.clip?.is_profile_clip? ()=>{} : ()=>{props.handleAddToFeatured()} }>
                        <span className='hubicon-video_library1'/>
                        <div className='baloa-names'>{props.t('addToFeatured')}</div>                        
                        {props.clip?.is_profile_clip?
                            <span className='hubicon-check_circle_fill'/>
                            :
                            <span className='icon-chevron-roght'/>
                        }
                    </div>
                    <div className={`baloa-play-clip__options__option ${props.clip?.post_stream?.thumbnail? '' : 'disabled'}`} onClick={props.clip?.is_profile_presentation_clip? ()=>{} : ()=>{props.handleAddToPrincipal()} }>
                        <span className='hubicon-video_camera_front1'/>
                        <div className='baloa-names'>{props.t('setAsMain')}</div>
                        {props.clip?.is_profile_presentation_clip?
                            <span className='hubicon-check_circle_fill'/>
                            :
                            <span className='icon-chevron-roght'/>
                        }
                        
                    </div>
                </div>
                {/*TODO: Pendiente mientras se deshabilita el mostrar los clips en el perfil por parte de backend*/}
                {/* props.clip?.user?.subscription_plan === 'FREE' && (props.clip?.is_profile_presentation_clip || props.clip?.is_profile_clip) &&//Se muestra cuando yo pagué el partido sin ser BaloaPro
                    <div className='baloa-play-clip__options__subscribe'>
                        <span className='hubicon-info'/>
                        <div className='baloa-table'>{props.t('subscribeProMsg')}</div>
                        <PrimaryButtonComponent
                            input_type={'button'}
                            onClick={()=>{ props.handleOnSubscribe()}}
                        >
                            <span>{props.t('subscribeBtnLbl')}</span>
                        </PrimaryButtonComponent>
                    </div> */
                }
            </div>
            <div className='baloa-play-clip__section'>
                <div className='baloa-play-clip__right-section'>
                    <div className='baloa-normal-medium'>{props.clip?.post_match_recording_caption}</div>
                    <div className='baloa-play-clip__right-section__match'>
                        <img src={props.clip?.post_match_recording_thumbnail} alt={props.clip?.post_match_recording_caption}/>
                        <div className='baloa-names' onClick={()=>{ props.handleOnMatch(props.clip?.post_match_recording_id)}}>{props.t('goToMatch')}</div>
                        <span className='icon-chevron-roght'/>
                    </div>
                </div>                
            </div>
        </div>
    )
}

export default withTranslation('baloaplay')(BaloaPlayClipComponent);
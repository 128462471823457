/*eslint eqeqeq: "off"*/
// React
import React from 'react';
//import { styled } from '@mui/material/styles';

// React device detect
//import { isMobile } from 'react-device-detect';

// Styles
import './BaloaPlayMatchPaymentConfirmationModalComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

//Components
import ModalComponent from 'shared/components/modal/ModalComponent';
//import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import FormComponent from 'shared/components/form/FormComponent';
import CurrencyFormat from 'react-currency-format';

function BaloaPlayMatchPaymentConfirmationModalComponent( props ) {

    /* const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 10,
        marginTop: 6,
        [`&.${linearProgressClasses.colorPrimary}`]: {
          backgroundColor: '#D9D9D9',
        },
        [`& .${linearProgressClasses.bar}`]: {
          borderRadius: 10,
          backgroundColor: 'var(--new-green-color)',
        },
    })); */
    const initialValues ={
        cupon: ''
    }

    const [ coupon, setCoupon] = React.useState(null);

    const checkValue=(v)=>{
        if(props.match_payment_coupon && v.length <= 0){
            props.resetCoupon();
            setCoupon(null);
        }
    }

    const deleteValidCoupon = () => {
        props.resetCoupon();
        setCoupon(null);
    }

    return(
        <ModalComponent
            isOpen={props.isOpen}
            onClose={props.onClose}
            className="baloa-play-match-payment-confirmation-modal"
        >
            <div className={`baloa-play-match-payment-confirmation-modal__container `}>
                <div className='baloa-play-match-payment-confirmation-modal__header'>
                    <span className='hubicon-play_circle'/>
                    <div className='baloa-names'>{props.t('matchPaymentConfirmationModal.title')}</div>
                </div>
                <div className='baloa-table subtitle'>{props.t('matchPaymentConfirmationModal.subTitle')}</div>
                
                <div className='baloa-names subtitle'>{props.t('matchPaymentConfirmationModal.purchaseSubTitle')}</div>
                
               
                    <React.Fragment>
                        <div className='baloa-play-match-payment-confirmation-modal__option-container scroll'>
                            <div className='baloa-play-match-payment-confirmation-modal__option-header__title'>
                                <div className='baloa-names resumen'>{props.t('matchPaymentConfirmationModal.purchaseSelected')}</div>
                                <span className={`hub${props.match_plan?.icon_class}`}/>
                                <div className='baloa-names'>{props.match_plan?.name}</div>
                            </div>
                            <div className='baloa-table'>{props.t('matchPaymentConfirmationModal.includesLbl')}</div>
                            <div className="baloa-table lista" dangerouslySetInnerHTML={{
                                    __html: props.t('matchPaymentConfirmationModal.groupPlanListItem0Lbl',{_max_matches: props.match_plan?.max_profiles_to_access})
                                }}></div>
                            <div className='baloa-table lista'>{props.t('matchPaymentOptionsModal.groupPlanListLbl')}</div>
                            {props.match_plan?.features?.map(feature =>
                                <li className='baloa-table lista2'>{feature}</li>
                            )}
                            
                            <div className='baloa-play-match-payment-confirmation-modal__option-counter'>
                                <div className="baloa-table-column">{props.t('matchPaymentConfirmationModal.paymentLinkInfoLbl')}</div>                            
                            </div>
                            <div className='baloa-play-match-payment-confirmation-modal__coupon'>
                                {(!props.match_payment_coupon || !props.match_payment_coupon?.coupon_is_valid) &&
                                    <FormComponent
                                        formInitialValues = {initialValues }
                                        onSubmit = { ( values, actions ) => { 
                                                props.handleOnValidateCupon( props.match_plan?.generic_wompi_product, values.cupon);
                                                setCoupon(values.cupon);
                                            }
                                        }
                                        className = "baloa-play-match-payment-confirmation-modal__form"
                                    >
                                        <div className='baloa-play-match-payment-confirmation-modal__form-coupon'>
                                            <InputFieldComponent
                                                label={props.t('matchPaymentConfirmationModal.couponCodeLbl')}
                                                input_id=""
                                                field_name="cupon"
                                                validationFunction={()=>{}}
                                                disabled={false}
                                                input_type="text"
                                                resetError={() => { }}
                                                error={{'cupon': ''}}
                                                message=""
                                                onChange={(v) => { checkValue(v) }}
                                                className={`${(props.match_payment_coupon && !props.match_payment_coupon?.coupon_is_valid)? 'error' : ''}`}          
                                            >
                                            </InputFieldComponent>
                                            <SecondaryButtonComponent
                                                onClick={()=>{}}
                                                disabled={ false }
                                            >
                                                <span>{props.t('matchPaymentConfirmationModal.applyBtnLbl')}</span>
                                            </SecondaryButtonComponent>
                                        </div>
                                        
                                        {props.match_payment_coupon && !props.match_payment_coupon?.coupon_is_valid &&
                                            <div className='baloa-mini error'>{props.match_payment_coupon?.coupon_response}</div>
                                        }
                                    </FormComponent>
                                }
                                {props.match_payment_coupon?.coupon_is_valid &&
                                    <div className='baloa-play-match-payment-confirmation-modal__coupon-valid'>
                                        <span className='hubicon-check_circle'/>
                                        <div className=''>
                                            <div className='baloa-table-column'><strong>{coupon}</strong>&nbsp;{props.t('matchPaymentConfirmationModal.appliedLbl')}</div>
                                            <CurrencyFormat
                                                value={props.match_payment_coupon?.discount_price} 
                                                displayType={'text'} 
                                                thousandSeparator={'.'} 
                                                decimalSeparator={","} 
                                                prefix={'-$'} 
                                                suffix={' ' + props.match_payment_coupon?.currency}
                                                //onValueChange ={}
                                                renderText={value => <div className='baloa-username'>{`${value} (${props.match_payment_coupon?.percent_off}% ${props.t('matchPaymentConfirmationModal.discountLbl')})`}</div>}
                                            />
                                        </div>
                                        <div className='baloa-table-column delete-button' onClick={()=>{ deleteValidCoupon() }}>{props.t('matchPaymentConfirmationModal.removeBtnLbl')}</div>
                                    </div>
                                }
                            </div>
                            <div className='baloa-play-match-payment-confirmation-modal__separator'></div>
                            {props.match_payment_coupon?.coupon_is_valid &&
                                <div className='baloa-play-match-payment-confirmation-modal__coupon-discount'>
                                    <CurrencyFormat
                                        value={props.match_payment_coupon?.discount_price} 
                                        displayType={'text'} 
                                        thousandSeparator={'.'} 
                                        decimalSeparator={","} 
                                        prefix={'-$'} 
                                        suffix={' ' + props.match_payment_coupon?.currency}
                                        //onValueChange ={}
                                        renderText={value => <div className='baloa-table'>{`${props.t('matchPaymentConfirmationModal.couponCodeLbl')}: ${value}`}</div>}
                                    />
                                </div>
                            }
                            <div className='baloa-play-match-payment-confirmation-modal__option-total'>                                    
                                <div className='baloa-table'>{props.t('matchPaymentConfirmationModal.totalLbl')}&nbsp;</div>
                                <CurrencyFormat
                                    value={props.match_payment_coupon?.coupon_is_valid? props.match_payment_coupon?.amount_total : props.match_plan?.value} 
                                    displayType={'text'} 
                                    thousandSeparator={'.'} 
                                    decimalSeparator={","} 
                                    prefix={'$'} 
                                    suffix={' ' + `${props.match_payment_coupon?.coupon_is_valid? props.match_payment_coupon?.currency : props.match_plan?.currency}`}
                                    //onValueChange ={}
                                    renderText={value => <div className='baloa-subtitle-2'>{`${value}`}</div>}
                                />
                            </div> 
                        </div>
                        
                    </React.Fragment>
                
            </div>
            <PrimaryButtonComponent
                input_type={'button'}
                onClick={ ()=>{ props.handleOnPay( props.match_plan?.generic_wompi_product, props.match_payment_coupon?.coupon_is_valid? coupon : null ) } }
            >
                <span>{props.t('matchPaymentConfirmationModal.payBtnLbl')}</span>
            </PrimaryButtonComponent>
        </ModalComponent>
    )
}

export default withTranslation('baloaplay')(BaloaPlayMatchPaymentConfirmationModalComponent);
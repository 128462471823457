// Axios
import Axios from "config/axiosConfig";

// Globals
import { api_url } from "config/GlobalData";


/* export const GetHomePostsApi = ( next_page ) => {

    return Axios.instance.get( next_page ? next_page : `${api_url}/home-feed/?version=2`,{
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });

} */

export const GetBaloaPlaySubSectionsApi = ( ) => {

    return Axios.instance.get( `${api_url}/post-match-subsection/`,{
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });
}

export const GetBaloaPlaySubSectionApi = ( id_subsection, pager, next_page )=> {

    return Axios.instance.get( next_page ? next_page : `${api_url}/post-match-subsection/${id_subsection}/matches/?page_size=${pager}`,{
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });
}


export const SearchMatchVideosApi = ( search )=> {

    return Axios.instance.get( `${api_url}/post-match-recording/?search=${search}`,{
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });
}

export const GetBaloaPlayMatchApi = ( match_id )=> {

    return Axios.instance.get( `${api_url}/post-match-recording/${match_id}/`,{
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });
}

export const GetBaloaPlayMatchClipsApi = ( post_clip_recording_id, page_size, next_page )=> {

    return Axios.instance.get( next_page? next_page : `${api_url}/post-clip-recording/${post_clip_recording_id}/clips/?page_size=${page_size}`,{
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });
}

export const SetMatchPaymentApi= ( match_id, metadata )=> {

    return Axios.instance.post( `${api_url}/post-match-recording/${match_id}/checkout/`,
        {  
            redirect: metadata.redirect,
            mobile: metadata.mobile,
            payment_type: metadata.payment_type,
            extra_access: metadata.extra_access,
            extra_clips: metadata.extra_clips,
            coupon_id: metadata.coupon_id
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

/* export const SetClipPaymentApi= ( clip_id, metadata )=> {

    return Axios.instance.post( `${api_url}/post-clip-asset-recording/${clip_id}/checkout/`,
        {  
            redirect: metadata.redirect,
            mobile: metadata.mobile,
            payment_type: metadata.payment_type
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
} */

export const SetClipClaimApi = ( clip_id )=> {

    return Axios.instance.post( `${api_url}/post-clip-asset-recording/${clip_id}/claim/`,
        {  
            mobile: false,
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetBaloaPlayClipsApi = ( page_size, next_page  ) => {

    return Axios.instance.get( next_page? next_page : `${api_url}/post-clip-asset-recording/?page_size=${page_size}`,{
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });
}

export const GetBaloaPlayClipApi = ( clip_id ) => {

    return Axios.instance.get( `${api_url}/post-clip-asset-recording/${clip_id}/`,{
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });
}

export const UpdateClipApi = ( clip_id, title )=> {

    return Axios.instance.put( `${api_url}/post-clip-asset-recording/${clip_id}/`,
        {  
            post_video_caption: title,
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const PostClipApi = ( clip_id, body )=> {

    return Axios.instance.post( `${api_url}/post-clip-asset-recording/${clip_id}/post_video/`,
        body,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const CreateMatchClipApi = ( match_recording, start_time, end_time, title )=> {

    return Axios.instance.post( `${api_url}/post-match-asset-recording/${match_recording}/create_clip/`,
        {  
            title: title,
            start_time: start_time,
            end_time: end_time
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetBaloaPlayMatchPlansApi = ( match_id )=> {

    return Axios.instance.get( `${api_url}/post-match-recording/${match_id}/baloa_play_plans/`,{
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });
}

export const GetBaloaPlaySharedLinkMatchInfoApi = ( plan_code_id )=> {

    return Axios.instance.get( `${api_url}/baloa-play-social-share/${plan_code_id}/`,{
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });
}

export const AcceptBaloaPlaySharedLinkMatchApi = ( plan_code_id )=> {

    return Axios.instance.post( `${api_url}/baloa-play-social-share/`,
        {  
            plan_code_id: plan_code_id
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }    
    );
}

export const GetClipUrlApi = ( clip_id )=> {

    return Axios.instance.post( `${api_url}/post-clip-asset-recording/${clip_id}/download/`,
        {},
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const SetPaymentExtraClipsApi = ( baloa_play_plan_id, clips_number, redirect_url )=> {

    return Axios.instance.post( `${api_url}/post-baloa-play-plan/${baloa_play_plan_id}/clips_checkout/`,
        {  
            redirect: redirect_url,
            mobile: false,
            extra_clips: clips_number
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}
/*eslint eqeqeq: "off"*/
// React
import React from 'react';


// Styles
import './BaloaPlayMatchPaymentPersonalizePlanModalComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

//Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SumRestInputComponent from 'shared/components/sum-rest-input/SumRestInputComponent';
import FormComponent from 'shared/components/form/FormComponent';
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import CurrencyFormat from 'react-currency-format';

function BaloaPlayMatchPaymentPersonalizePlanModalComponent( props ) {

    const formInitialValues = {
        extra_access: props.match_plan?.min_profiles_to_access,
        extra_clips: props.match_plan?.min_clips_to_create
    }

    const [planValue, setPlanValue] = React.useState();
    const [accessNumber, setAccessNumber] = React.useState(props.match_plan?.min_profiles_to_access);
    const [clipsNumber, setClipsNumber] = React.useState(props.match_plan?.min_clips_to_create);
    const [changeToText, setChangeToText] = React.useState(false);
    const [option, setOption] = React.useState('SETTING_PLAN');

    const initialValues ={
        cupon: ''
    }
    const [ coupon, setCoupon] = React.useState(null);

    const changeAccess = ( accessNumber) => {
        setAccessNumber(accessNumber)
        var accessValue = props.match_plan?.value_per_access * (accessNumber - props.match_plan?.min_profiles_to_access);
        var clipsValue = props.match_plan?.value_per_clip * (clipsNumber - props.match_plan?.min_clips_to_create);
        if(accessNumber >= 20){
            setChangeToText(true);
            setPlanValue( props.match_plan?.value + accessValue);
        }else{
            setChangeToText(false);
            setPlanValue( props.match_plan?.value + Number(clipsValue) + Number(accessValue));
        }
        
    }

    const changeClips = ( clipsNumber) => {

        setClipsNumber(clipsNumber)
        var clipsValue = props.match_plan?.value_per_clip * (clipsNumber - props.match_plan?.min_clips_to_create);
        var accessValue = props.match_plan?.value_per_access * (accessNumber - props.match_plan?.min_profiles_to_access);
        setPlanValue( props.match_plan?.value + Number(accessValue) + Number(clipsValue) );
    }

    React.useEffect(() => {
        if (props.isOpen) {
            setPlanValue(props.match_plan?.value);
            setAccessNumber(props.match_plan?.min_profiles_to_access);
            setClipsNumber(props.match_plan?.min_clips_to_create);
            setChangeToText(false);
            setOption('SETTING_PLAN');
        }
    }, [props.isOpen]);

    const checkValue=(v)=>{
        if(props.match_payment_coupon && v.length <= 0){
            props.resetCoupon();
            setCoupon(null);
        }
    }

    const deleteValidCoupon = () => {
        props.resetCoupon();
        setCoupon(null);
    }

    return(
        <ModalComponent
            isOpen={props.isOpen}
            onClose={props.onClose}
            className="baloa-play-match-payment-personalize-plan-modal"
        >
            <div className={`baloa-play-match-payment-personalize-plan-modal__container `}>
                {option === 'SETTING_PLAN' &&
                    <React.Fragment>
                        <div className='baloa-play-match-payment-personalize-plan-modal__header'>
                            <span className='iconb2-settings'/>
                            <div className='baloa-names'>{props.t('matchPaymentPersonalizedPlanModal.title')}</div>
                        </div>
                        <div className='baloa-table subtitle'>{props.t('matchPaymentPersonalizedPlanModal.subTitle')}</div>
                        <FormComponent
                            formInitialValues = { formInitialValues }
                            onSubmit = { ( values, actions ) => { 
                                    
                                }
                            }
                            className = ""
                        >
                            <SumRestInputComponent
                                label={props.t('matchPaymentPersonalizedPlanModal.extraAccessLbl')} 
                                input_id="extra_access" 
                                field_name="extra_access"
                                //validationFunction={ DateFormatValidator }
                                disabled={false}
                                className={``}
                                input_type="text"
                                //resetError={ () => props.onResetError( { 'end_date': "" } ) }
                                //error={ props.errors }
                                message=""
                                placeholder={""}
                                defaultValue={props.match_plan?.min_profiles_to_access}
                                min={props.match_plan?.min_profiles_to_access}
                                onChange={ (v)=>{ changeAccess(v) }}
                            />
                            <SumRestInputComponent
                                label={props.t('matchPaymentPersonalizedPlanModal.extraClipsLbl')} 
                                input_id="extra_clips" 
                                field_name="extra_clips"
                                //validationFunction={ DateFormatValidator }
                                disabled={changeToText}
                                className={``}
                                input_type="text"
                                //resetError={ () => props.onResetError( { 'end_date': "" } ) }
                                //error={ props.errors }
                                message=""
                                placeholder={""}
                                defaultValue={props.match_plan?.min_clips_to_create}
                                min={props.match_plan?.min_clips_to_create}
                                onChange={ (v)=>{ changeClips(v) }}
                                value={'test'}
                                changeToText={ changeToText }
                                textValue={props.t('matchPaymentPersonalizedPlanModal.unlimitedLbl')}
                            />
                        </FormComponent>
                    </React.Fragment>
                }
                {option === 'PAYMENT' &&
                    <React.Fragment>
                        <div className='baloa-play-match-payment-confirmation-modal__header'>
                            <span className='hubicon-play_circle'/>
                            <div className='baloa-names'>{props.t('matchPaymentConfirmationModal.title')}</div>
                        </div>
                        <div className='baloa-table subtitle'>{props.t('matchPaymentConfirmationModal.subTitle')}</div>
                        
                        <div className='baloa-names subtitle'>{props.t('matchPaymentConfirmationModal.purchaseSubTitle')}</div>
                    </React.Fragment>
                }
                                
                <React.Fragment>
                    <div className='baloa-play-match-payment-personalize-plan-modal__option-container'>
                        <div className='baloa-play-match-payment-personalize-plan-modal__option-header__title'>
                            <div className='baloa-names resumen'>{props.t('matchPaymentConfirmationModal.purchaseSelected')}</div>
                            <span className={`hub${props.match_plan?.icon_class}`}/>
                            <div className='baloa-names'>{props.match_plan?.name}</div>
                        </div>
                        <div className='baloa-table'>{props.t('matchPaymentConfirmationModal.includesLbl')}</div>
                        <div className="baloa-table lista" dangerouslySetInnerHTML={{
                                __html: props.t('matchPaymentConfirmationModal.groupPlanListItem0Lbl',{_max_matches: accessNumber})
                            }}></div>
                        <div className='baloa-table lista'>{props.t('matchPaymentOptionsModal.groupPlanListLbl')}</div>
                        {accessNumber <= 20 ?
                            <React.Fragment>
                                <li className="baloa-table lista2" dangerouslySetInnerHTML={{
                                    __html: props.t('matchPaymentConfirmationModal.individualPlanListItem1Lbl',{_create_clips_max: changeToText? props.t('matchPaymentPersonalizedPlanModal.unlimitedLbl') : clipsNumber})
                                }}></li>
                                <li className="baloa-table lista2" dangerouslySetInnerHTML={{
                                    __html: props.t('matchPaymentConfirmationModal.individualPlanListItem2Lbl',{_download_clips_max: changeToText? props.t('matchPaymentPersonalizedPlanModal.unlimitedLbl') : clipsNumber})
                                }}></li>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <li className="baloa-table lista2" dangerouslySetInnerHTML={{
                                    __html: props.t('matchPaymentConfirmationModal.groupPlanListItem1Lbl')
                                }}></li>
                                <li className="baloa-table lista2" dangerouslySetInnerHTML={{
                                    __html: props.t('matchPaymentConfirmationModal.groupPlanListItem2Lbl')
                                }}></li>
                            </React.Fragment>
                        }
                            
                        {accessNumber >= 2 &&
                            <div className='baloa-play-match-payment-personalize-plan-modal__option-counter'>
                                <div className="baloa-table-column">{props.t('matchPaymentConfirmationModal.paymentLinkInfoLbl')}</div>                            
                            </div>
                        }
                        {option === 'PAYMENT' &&
                            <div className='baloa-play-match-payment-confirmation-modal__coupon'>
                                {(!props.match_payment_coupon || !props.match_payment_coupon?.coupon_is_valid) &&
                                    <FormComponent
                                        formInitialValues = {initialValues }
                                        onSubmit = { ( values, actions ) => { 
                                                props.handleOnValidateCupon( props.match_plan?.generic_wompi_product, accessNumber, clipsNumber, values.cupon);
                                                setCoupon(values.cupon);
                                            }
                                        }
                                        className = "baloa-play-match-payment-confirmation-modal__form"
                                    >
                                        <div className='baloa-play-match-payment-confirmation-modal__form-coupon'>
                                            <InputFieldComponent
                                                label={props.t('matchPaymentConfirmationModal.couponCodeLbl')}
                                                input_id=""
                                                field_name="cupon"
                                                validationFunction={()=>{}}
                                                disabled={false}
                                                input_type="text"
                                                resetError={() => { }}
                                                error={{'cupon': ''}}
                                                message=""
                                                onChange={(v) => { checkValue(v) }}
                                                className={`${(props.match_payment_coupon && !props.match_payment_coupon?.coupon_is_valid)? 'error' : ''}`}          
                                            >
                                            </InputFieldComponent>
                                            <SecondaryButtonComponent
                                                onClick={()=>{}}
                                                disabled={ false }
                                            >
                                                <span>{props.t('matchPaymentConfirmationModal.applyBtnLbl')}</span>
                                            </SecondaryButtonComponent>
                                        </div>
                                        
                                        {props.match_payment_coupon && !props.match_payment_coupon?.coupon_is_valid &&
                                            <div className='baloa-mini error'>{props.match_payment_coupon?.coupon_response}</div>
                                        }
                                    </FormComponent>
                                }
                                {props.match_payment_coupon?.coupon_is_valid &&
                                    <div className='baloa-play-match-payment-confirmation-modal__coupon-valid'>
                                        <span className='hubicon-check_circle'/>
                                        <div className=''>
                                            <div className='baloa-table-column'><strong>{coupon}</strong>&nbsp;{props.t('matchPaymentConfirmationModal.appliedLbl')}</div>
                                            <CurrencyFormat
                                                value={props.match_payment_coupon?.discount_price} 
                                                displayType={'text'} 
                                                thousandSeparator={'.'} 
                                                decimalSeparator={","} 
                                                prefix={'-$'} 
                                                suffix={' ' + props.match_payment_coupon?.currency}
                                                //onValueChange ={}
                                                renderText={value => <div className='baloa-username'>{`${value} (${props.match_payment_coupon?.percent_off}% ${props.t('matchPaymentConfirmationModal.discountLbl')})`}</div>}
                                            />
                                        </div>
                                        <div className='baloa-table-column delete-button' onClick={()=>{ deleteValidCoupon() }}>{props.t('matchPaymentConfirmationModal.removeBtnLbl')}</div>
                                    </div>
                                }
                            </div>
                        }
                        <div className='baloa-play-match-payment-personalize-plan-modal__separator'></div>
                        {props.match_payment_coupon?.coupon_is_valid &&
                            <div className='baloa-play-match-payment-confirmation-modal__coupon-discount'>
                                <CurrencyFormat
                                    value={props.match_payment_coupon?.discount_price} 
                                    displayType={'text'} 
                                    thousandSeparator={'.'} 
                                    decimalSeparator={","} 
                                    prefix={'-$'} 
                                    suffix={' ' + props.match_payment_coupon?.currency}
                                    //onValueChange ={}
                                    renderText={value => <div className='baloa-table'>{`${props.t('matchPaymentConfirmationModal.couponCodeLbl')}: ${value}`}</div>}
                                />
                            </div>
                        }
                        <div className='baloa-play-match-payment-personalize-plan-modal__option-total'>
                            <div className='baloa-table'>{props.t('matchPaymentConfirmationModal.totalLbl')}&nbsp;</div>
                            <CurrencyFormat
                                value={props.match_payment_coupon?.coupon_is_valid? props.match_payment_coupon?.amount_total : planValue} 
                                displayType={'text'} 
                                thousandSeparator={'.'} 
                                decimalSeparator={","} 
                                prefix={'$'} 
                                suffix={' ' + `${props.match_payment_coupon?.coupon_is_valid? props.match_payment_coupon?.currency : props.match_plan?.currency}`}
                                //onValueChange ={}
                                renderText={value => <div className='baloa-subtitle-2'>{`${value}`}</div>}
                                decimalScale={2}
                                fixedDecimalScale={true}
                            />
                        </div> 
                    </div>
                    
                </React.Fragment>
                
            </div>
            {option === 'SETTING_PLAN' &&
                <PrimaryButtonComponent
                    input_type={'button'}
                    onClick={ ()=>{setOption('PAYMENT') } }
                >
                    <span>{props.t('matchPaymentPersonalizedPlanModal.continueBtnLbl')}</span>
                </PrimaryButtonComponent>
            }
            {option === 'PAYMENT' &&
                <PrimaryButtonComponent
                    input_type={'button'}
                    onClick={ ()=>{ props.handleOnPayPersonalizedPlan( props.match_plan?.generic_wompi_product, accessNumber, clipsNumber, props.match_payment_coupon?.coupon_is_valid? coupon : null  ) } }
                >
                    <span>{props.t('matchPaymentConfirmationModal.payBtnLbl')}</span>
                </PrimaryButtonComponent>
            }
        </ModalComponent>
    )
}

export default withTranslation('baloaplay')(BaloaPlayMatchPaymentPersonalizePlanModalComponent);
/*eslint eqeqeq: "off"*/

// Variables
// import { team_form_id, player_form_id } from "config/GlobalData";

// Action creators
import { 
    //loadTournamentDatesAction,
    loadTournamentPhaseGroupDatesAction,
    //addDateToTournamentAction,
    addDateToTournamentGroupAction,
    loadDateMatchesAction,
    addMemberToTournamentAction,
    loadLeagueTournamentsAction,
    addTournamentTeamAction,
    loadTournamentAction,
    addGroupsPhasesAction,
    loadTournamentTeamsAction,
    loadTournamentMembersAction,
    deleteTeamFromTournamentAction,
    disableTeamFromTournamentAction,
    deleteMemberFromTournamentAction,
    loadTournamentResultsTableAction,
    loadPhaseGroupPositionsTableAction,
    loadTournamentPhasesAction,
    loadTournamentInfoAction,
    searchWorldCitiesAction,
    getCountriesAction,
    searchCityByCountryAction,
    loadTournamentPlayersAction,
    loadAddPhaseAction,
    addTeamToGroupAction,
    deleteTeamFromGroupAction,
    setTournamentActivePhaseOfEndPointAction,
    deleteTournamentPhaseAction,
    //finishTournamentPhaseAction,
    teamsClassifiedByPhaseAction,
    unfinishedMatchesAction,
    //changeMatchsToCloseModalStateAction,
    changeFinishFreePhaseModalStateAction,
    changeFinishGroupsPhaseModalStateAction,
    loadTournamentPhaseTeamsAction,
    isLoadedTournamentPhasesAction,
    changeLeagueTournamentLoadingAction, 
    changeDateMatchesLoadingAction,  
    changeUnfinishedTournamentMatchesStateAction,
    loadScorerTeamPageMembersAction,
    loadBeatenFenceTeamPageMembersAction,
    loadChampionTeamPageMembersAction,
    deleteDateAction,
    resetTournamentSelectedDateAction,
    loadTournamentPostsAction,
    searchBaloaTeamAction,
    setActivePhaseFixtureAction,
    searchBaloaMemberAction,
    loadPhaseMatchesByDateAction,
    loadPhaseDatesAction,
    addTournamentInvitedTeamAction,
    changeTeamTournamentStateAction,
    loadTournamentTeamPlayersAction,
    loadTournamentSetupTeamsAction,
    //addTournamentSetupTeamAction,
    //deleteTeamSetupFromTournamentAction,
    loadFormDataAction,
    removerPlayerFromTeamAction,
    //resetTournamentAction,
    changeStartTournamentStateAction,
    updateTournamentTeamAction,
    loadCriteriaTournamentPhaseAction,
    loadFieldsStatusEditingAction,
    disableNewTournamentSubmitButtonAction,
    loadTournamentPhaseFilterTeamsAction,
    codeFormIDTemplateAction,
    stateSuccessFulPhaseEditionAction,
    setTournamentActivePhaseEditAction,
    resetTournamentDatesAction,
    resetTournamentPhaseTeamsAction,
    loadTournamentMembersTeamsAction,
    loadTournamentStaffAction,
    searchBaloaUserAction,
    addUserToStaffAction,
    updateStaffStateAction,
    deleteStaffAction,
    loadRefereeingPaymentUrlAction,
    calculateRefereeFeeAction,
    loadTournamentPhaseInterGroupsTeamsAction,
    loadPhaseTeamsNotAssignedAction,
    resetTournamentActivePhaseAction,
    changePhaseFinalizedStateAction,
    loadTournamentPhaseTeamsModalAction,
    loadFullResponseTeamMemberAction,
    setTeamsToMatchAssistanceAligment,
    loadRevelationPlayerTeamPlayersAction,
    loadTournamentPlayerTeamPlayersAction,
    loadThirdPlaceTeamPlayersAction,
    loadRunnerUpTeamPlayersAction,
    setItemsForTournamentTypeAction,
    loadFormsAction,
    loadListExistingFormAction,
    loadPageFormTemplateAction,
    createCarnetFormTemplateAction,
    saveCardIdFormInfoAction,
    changeCardIdComponentStateAction,
    cleanCardIdInfoAction,
    loadCardIdTemplateAction,
    deleteCardIdTemplateAction,
    loadCardIdsDataAction,
    loadTeamManagerAndStaffAction,
    loadPageFieldsDataAction,
    pageFieldData,
    loadTournamentFieldsDataAction,
    loadTournamentAvailableFieldsDataAction,
    setFixtureAvailableDaysAction,
    //setFixtureLockedDaysAction,
    setFixtureFieldTeamsAction,
    loadFullFixtureConfigAction,
    //setFixtureFieldAvailableDaysAction,
    setGroupedFixtureAvailableDaysAction,
    setGroupedFixtureFieldAvailableDaysAction,
    loadTournamentPhaseGroupDatesFilterAction,
    deleteFilterDateAction,
    changeLoadingTournamentTeamsStateAction,
    loadPhaseGroupResultsTableAction,
    loadingPhaseGroupPositionsTableStateAction,
    resetTournamentGroupResultTableAction,
    loadingTournamentScorersTableStateAction,
    loadTournamentScorersTableAction,
    loadingYellowCardsTableStateAction,
    loadYellowCardsTableAction,
    loadingRedCardsTableStateAction,
    loadRedCardsTableAction,
    loadingBlueCardsTableStateAction,
    loadBlueCardsTableAction,
    loadingAssistsTableStateAction,
    loadAssistsTableAction,
    loadingLeastDefeatedFenceTableStateAction,
    loadLeastDefeatedFenceTableAction,
    loadingTeamsMostGoalsTableStateAction,
    loadTeamsMostGoalsTableAction,
    loadingSummaryTableStateAction,
    loadSummaryTableAction,
    loadingCreateFairPlaySettingsStateAction,
    loadFairPlaySettingsAction,
    setSelectedFairPlaySettingAction,
    setDefaultFairPlaySettingAction,
    loadPhaseSanctionsAction,
    loadingPhaseSanctionsStateAction,
    loadPhaseMatchesByTeamAction,
    updatePhaseExtraPenaltyAction,
    deletePhaseExtraPenaltyAction,
    loadFairPlayTableAction,
    loadingFairPlayTableStateAction,
} from './LeagueTournamentActionsCreators';

// Apis
import { 
    //GetTournamentDatesApi,
    GetTournamentPhaseGroupDatesApi,
    //AddDateToTournamentApi,
    AddDateToTournamentGroupApi,
    CreateNewTournamentApi,

    CreateNewGroupsPhasesApi, 
    
    NewMatchApi,
    GetMatchesByDateApi,
    AddMemberToTournamentApi,
    GetLeagueTournamentsApi,
    // AddTeamToTournamentApi,
    AddInviteTeamToTournament,
    DeleteTeamToTournamentApi,
    DeleteMemberFromTournamentApi,
    //GetTournamentTeamsApi,
    GetTournamentMembersApi,
    GetTournamentResultsTableApi,
    GetTournamentPhasesApi,
    GetPhaseResultsTableApi,
    FinishTournamentApi,
    DeleteMatchGameApi,
    GetTournamentInfoApi,
    EditMatchApi,
    SearchWorldCitiesApi,
    GetCountriesApi,
    searchCityByCountryApi,
    GetTournamentPlayersApi,
    AddTournamentPhaseApi,
    AddTeamToGroupApi,
    DeleteTeamFromGroupApi,
    GetTournamentPhaseApi,
    DeleteTournamentPhaseApi,
    //FinishTournamentPhaseApi,
    GetTournamentClassifiedTeamsByPhaseApi,
    DeleteDateApi,
    GetTournamentPostsApi,
    UpdateTournamentApi,
    SearchBaloaTeamApi,
    SetFinalFixture,
    GetPhaseMatchesByDateApi,
    GetPhaseDatesApi,
    LoadFilterToTournamentApi,
    SearchBaloaTeamToInvitedApi,
    getFormApi,
    GetCriteriaTounretsApi,
    PutNewCriteriaTounretsApi,
    CreateNewFormApi,
    GetFieldsStatusEditingApi,
    GetFilterPhaseTeamsApi,
    UpdateBestThirdToGroupApi,
    EditPhasesApi,
    TransferTeamPlayerApi,
    TransferTeamKnockoutApi,
    GetCodeFormTemplateApi,
    GetTournamentStaffApi,
    AddStaffApi,
    UpdateStaffStateApi,
    DeleteStaffApi,
    GetRefereeingPaymentApi,
    RefereePaymentApi,
    CalculateRefereeFeeApi,
    DownloadFormDataApi,
    DownloadPaymentDataApi,
    UpdateClassifiedCountGroupApi,
    CreateTournamentPhaseGroupApi,
    DeleteGroupFromPhaseApi,
    CleanAllGroupOfPhaseApi,
    CreateAutomaticGroupPhasesApi,
    EndTournamentPhaseApi,
    AddAllTeamsToGroupApi,
    GetTeamsToMatchAssistanceAligmentApi,
    PatchPlayerAssistanceAligmentApi,
    GetItemsForTournamentTypeApi,
    EnrollRejectMultiplePlayersApi,
    GetListExistingFormApi,
    SaveCardIdFormInfoApi,
    DeleteFormTemplateApi,
    GetCardIdsDataApi,
    GetPlayerCardIdDataApi,
    GetStaffCardIdDataApi,
    PostCreatePageFieldsdApi,
    GetPageFieldsdApi,
    PatchEditPageFieldsdApi,
    DeletePageFielddApi,
    PostCreateTournamentFieldsdApi,
    GetTournamentFieldsdApi,
    DeleteTournamentFieldsdApi,
    GetPageToTournamentFieldsdApi,
    SetTournamentFixtureApi,
    GenerateFixtureMatchesApi,
    SetFixtureAvailableDaysApi,
    DeleteFixtureAvailableDayApi,
    GetAvailableFixtureDaysApi,
    SetFixtureLockedDaysApi,
    DeleteFixtureLockedDayApi,
    //GetFixtureLockedDaysApi,
    GetTournamentMatchesFilterApi,
    PostNewTournamentMatchApi,
    GetFixtureFieldTeamsApi,
    AddFixtureTeamsToFieldApi,
    GetFullFixtureConfigApi,
    DeleteFixtureFieldTeamApi,
    SetFixtureFieldAvailableDaysApi,
    DeleteFixtureFieldAvailableDayApi,
    //GetFieldAvailableFixtureDaysApi,
    SetFixtureFieldLockedDaysApi,
    DeleteFixtureFieldLockedDayApi,
    GetGroupedAvailableFixtureDaysApi,
    GetGroupedFieldAvailableFixtureDaysApi,
    GetTournamentPhaseGroupDatesFilterApi,
    DeleteFixtureApi,
    SearchTournamentTeamsAdminApi,
    GetGroupPhaseResultsTableApi,
    GetTournamentScorersTableApi,
    GetTournamentYellowCardsTableApi,
    GetTournamentRedCardsTableApi,
    GetTournamentBlueCardsTableApi,
    GetTournamentAssistsTableApi,
    GetTournamentLeastDefeatedFenceTableApi,
    GetTournamentTeamsMostGoalsTableApi,
    GetTournamentFairPlayTableApi,
    GetTournamentSummaryTableApi,
    CreateFairPlaySettingsApi,
    LoadFairPlaySettingsApi,
    GetFairPlaySettingApi,
    CreatePhaseExtraPenaltyApi,
    LoadPhaseSanctionsApi,
    GetPhaseMatchesByTeamApi,
    UpdatePhaseExtraPenaltyApi,
    DeletePhaseExtraPenaltyApi,
    GetAllTeamPlayersApi,
    ReOpenPhaseApi,
} from '../apis/LeagueTournamentApi';

import { GetTeamPageMembersApi } from '../apis/LeagueMatchApi';

import { changeErrorMessageSnackBar } from 'app/home/actions/HomeActionsCreators';
import { changeUserMessageSnackBar } from 'app/user-settings/actions/UserSettingsCreators';

import { GetSearchMembersApi, GetBaloaUsersApi } from '../apis/LeagueApi';

import { loadMatchReport } from './LeagueMatchActions';

import { InviteTeamToTournamentApi,
    ChangeTournamentTeamApi, 
    ChangeTournamentApi, 
    GetTournamentTeamPlayersApi, 
    ChangeTournamentPlayerApi,
    GetTournamentHubTeamsApi,
    GetTournamentMembersTeamsApi,
    GetTournamentInscriptionFormTemplateIdApi,
    GetTournamentInscriptionFormTemplateApi,
    GetTournamentTeamManagerAndStaffApi,
    GetPhaseCalendarApi,
    GetFrontTournamentTeamSearchPlayersApi,
} from '../../tournaments/apis/TournamentApi';

// i18next 
import i18n from 'i18next';

/*export const loadTournamentDates = ( id_tournament ) => {

    return ( dispatch ) => {
        
        GetTournamentDatesApi( id_tournament )
            .then( response => {
                dispatch( loadTournamentDatesAction( response ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}*/

export const loadTournamentPhaseGroupDates = ( id_group ) => {    

    return async dispatch => {
        await GetTournamentPhaseGroupDatesApi( id_group )
            .then( response => {
                dispatch( loadTournamentPhaseGroupDatesAction( response ) ); 
                dispatch( loadTournamentPhaseGroupDatesFilterAction( response ) );  
            })
            .catch( err => {
                    
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
                //console.log('error loadTournamentPhaseGroupDates', err);
            })
        }
}

export const loadLeagueTournaments = ( league_name ) => {

    return ( dispatch ) => {
        
        dispatch( changeLeagueTournamentLoadingAction( true ) );
        GetLeagueTournamentsApi( league_name )        
            .then( response => {
                // console.log("load cargo torneo");
                // console.log(response);
                dispatch( loadLeagueTournamentsAction( response ) );                
                dispatch( changeLeagueTournamentLoadingAction( false ) );
            })
            .catch(err => {
                if(err.response !== undefined && err.response.status !== 404){
                    if(localStorage.getItem('token') != null){
                        dispatch( changeErrorMessageSnackBar( {                        
                            message: i18n.t('league:snackBarMessages.snackBarError'),
                            error_message_is_open: true
                        } ) )
                    }
                }
            })
    }
}

export const createNewTournament = ( tournament_data,league_pagename, ids_forms ) => {

    return async ( dispatch ) => {
        //dispatch( resetTournamentAction({}) )//TODO: test for errors
        await CreateNewTournamentApi( tournament_data.form, league_pagename )
            .then( response => {
                dispatch( loadTournamentAction(response) )
                if(tournament_data.player_form){
                    dispatch( createNewForm(tournament_data.player_form, ids_forms.player_form, 'PLAYER_FORM', response.data.id, tournament_data.player_form_name))
                }
                if(tournament_data.team_form){
                    dispatch( createNewForm(tournament_data.team_form, ids_forms.team_form, 'TEAM_FORM', response.data.id, tournament_data.team_form_name))
                }               
                dispatch(changeErrorMessageSnackBar( {
                    message: i18n.t('league:snackBarMessages.createdTournament'),                    
                    error_message_is_open: true
                    } )
                );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        //message: i18n.t('league:snackBarMessages.snackBarError'),
                        message: manageCreateTournamentErrors(err),
                        error_message_is_open: true
                    } ) );
                    dispatch( disableNewTournamentSubmitButtonAction(false) );
                }
            })
        }
}

export const createNewForm = (form_data, form_id, type, tournament_id, form_name  ) => {

    return ( dispatch ) => {
        
        CreateNewFormApi(form_data, form_id, type, tournament_id, form_name )       
            .then( response => {
                // console.log('CreateNewFormApi', response);
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

function manageCreateTournamentErrors(error){
    let cleanMessage = "";
    if(error.response.data.non_field_errors){
        cleanMessage = "Error: "+error.response.data.non_field_errors[0];
    }else if(error.response.data.detail){
        cleanMessage = "Error: "+error.response.data.detail;
    }else if(error.response.data.main_country){
        cleanMessage = "Main country error: "+error.response.data.main_country[0];
    }else if(error.response.data.main_city){
        cleanMessage = "Main city error: "+error.response.data.main_city[0];
    }else if(error.response.data.football_type){
        cleanMessage = "Footbal type error: "+error.response.data.football_type[0];
    }else if(error.response.data.organizer){
        cleanMessage = "Organizer error: "+error.response.data.organizer[0];
    }else if(error.response.data.tournament_gender){
        cleanMessage = "Tournament gender error: "+error.response.data.tournament_gender[0];
    }else if(error.response.data.tournament_type){
        cleanMessage = "Tornament type error: "+error.response.data.tournament_type[0];
    }else if(error.response.data.tournament_registration_type){
        cleanMessage = "Tournament registration type error: "+error.response.data.tournament_registration_type[0];
    }else if(error.response.data.tournament_summon_registration){
        cleanMessage = "Tournament summon registration error: "+error.response.data.tournament_summon_registration[0];
    }else if(error.response.data.tournament_registration_pay){
        cleanMessage = "Payment error: "+error.response.data.tournament_registration_pay[0];
    }else if(error.response.data.tournament_prize_type){
        cleanMessage = "Prize type error: "+error.response.data.tournament_prize_type[0];
    }else if(error.response.data.material_prize){
        cleanMessage = "Material prize error: "+error.response.data.material_prize[0];
    }else if(error.response.data.registration_price_per_team){
        cleanMessage = "Prize per team error: "+error.response.data.registration_price_per_team[0];
    }else if(error.response.data.registration_price_per_player){
        cleanMessage = "Prize per player error: "+error.response.data.registration_price_per_player[0];
    }else if(error.response.data.min_participant_teams){
        cleanMessage = "Min teams error: "+error.response.data.min_participant_teams[0];
    }else if(error.response.data.max_participant_teams){
        cleanMessage = "Max teams error: "+error.response.data.max_participant_teams[0];
    }else if(error.response.data.min_participant_players){
        cleanMessage = "Min players error: "+error.response.data.min_participant_players[0];
    }else if(error.response.data.max_participant_players){
        cleanMessage = "Max players error: "+error.response.data.max_participant_players[0];
    }else if(error.response.data.name){
        cleanMessage = "Name error: "+error.response.data.name[0];
    }else if(error.response.data.rules){
        cleanMessage = "Rules error: "+error.response.data.rules[0];
    }else if(error.response.data.description){
        cleanMessage = "Description error: "+error.response.data.description[0];
    }else if(error.response.data.monetary_prize){
        cleanMessage = "Monetary prize error: "+error.response.data.monetary_prize[0];
    }

    return cleanMessage;
}

export const createNewGroupsPhases = ( tournament_id, phase_id, teams_clasified_by_groups ) => {    

    return ( dispatch ) => {
        CreateNewGroupsPhasesApi( tournament_id, phase_id, teams_clasified_by_groups )
            .then( response => {
                dispatch( addGroupsPhasesAction(response) ) 
                dispatch (loadTournamentPhaseTeams( response.data.id, 'not_assigned'));           
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

/*export const addDateToTournament = ( id_tournament, date_name ) => {

    return ( dispatch ) => {
        
        AddDateToTournamentApi( id_tournament, date_name )
            .then( response => {
                dispatch( addDateToTournamentAction( response ) );

            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}*/

export const addDateToTournamentGroup = ( tournament_group_id, date_name ) => {

    return ( dispatch ) => {
        
        AddDateToTournamentGroupApi( tournament_group_id, date_name )
            .then( response => {
                dispatch( addDateToTournamentGroupAction( response ) );
                dispatch( loadTournamentPhaseGroupDatesFilterData(tournament_group_id, '?pagination=False') );
                //console.log('ingreso por 1');
                dispatch(changeUnfinishedTournamentMatchesStateAction(true));
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const NewMatch = ( tournament_date, new_tournament, phase ) => {

    return ( dispatch ) => {
        
        NewMatchApi( tournament_date, new_tournament )
            .then  ( response => {  
                dispatch( finishedMatchesByPhase(phase)  );              
                dispatch( loadDateMatches(tournament_date) );                
                dispatch(changeErrorMessageSnackBar( {
                    message: i18n.t('league:snackBarMessages.createdMatch'),                    
                    error_message_is_open: true
                    } )
                );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const editMatch = ( id_match, match, tournament_date ) => {

    return ( dispatch ) => {
        
        EditMatchApi( id_match, match )
            .then( async response => {
                //dispatch( editTeamAction( response ) );
                await dispatch( loadDateMatches(tournament_date) )
                dispatch(changeErrorMessageSnackBar( {                    
                    message: i18n.t('league:snackBarMessages.editedMatch'),                    
                    error_message_is_open: true
                    } )
                );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadDateMatches = ( id_date, next_url ) => {

    return async ( dispatch ) => { 

        dispatch( changeDateMatchesLoadingAction( true ) );
        await GetMatchesByDateApi( id_date, next_url )
            .then( response => {  
                dispatch( loadDateMatchesAction( response ) );                  
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'), 
                        error_message_is_open: true
                    } ) )
                }
            })
            .then( () => {
                dispatch( changeDateMatchesLoadingAction( false ) );
            } );
    }
}

export const deleteMatchGame = ( match_id, selected_date_id, phase ) => {

    return ( dispatch ) => {

        DeleteMatchGameApi( match_id )
            .then( async response => {
                await dispatch( loadDateMatches(selected_date_id));
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('league:snackBarMessages.deletedMatch'),
                    error_message_is_open: true
                } ) );
                dispatch( finishedMatchesByPhase(phase)  );
            })
            .catch( error => {
                
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const addMemberToTournament = ( id_tournament, username ) => {

    return ( dispatch ) => {
        
        AddMemberToTournamentApi( id_tournament, username )
            .then( response => {
                dispatch( addMemberToTournamentAction( response ) );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const addTeamToTournament = ( id_tournament, team_name, team_state ) => {
    return async ( dispatch ) => {
        
        // AddTeamToTournamentApi( id_tournament, team_name, team_state )
        await InviteTeamToTournamentApi( id_tournament, team_name, team_state )
            .then( response => {
                dispatch( addTournamentTeamAction(response));                
                //dispatch( addTournamentSetupTeamAction(response) ); se comentariea por duplicidad con addTournamentTeamAction - optimizacion apis
                //dispatch( loadTournamentResultsTable( id_tournament )) ;
                dispatch(changeErrorMessageSnackBar( {                    
                    message: i18n.t('league:snackBarMessages.addedTeam'),                    
                    error_message_is_open: true
                    } )
                );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const addInviteTeamToTournament=( id_tournament_team, state_invite_team)=>{
    return ( dispatch ) => {        
        AddInviteTeamToTournament( id_tournament_team, state_invite_team )
            .then( response => {
                dispatch( addTournamentInvitedTeamAction(response));
                //dispatch( loadTournamentResultsTable( id_tournament )) ;
                dispatch(changeErrorMessageSnackBar( {                    
                    message: i18n.t('league:snackBarMessages.invitedTeam'),                    
                    error_message_is_open: true
                } )
            );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }   
            })
        }
    
}

export const finishTournament = ( id_tournament, tournament_data, page ) => {

    return ( dispatch ) => {
        
        FinishTournamentApi( id_tournament, tournament_data )
            .then( response => {
                dispatch(loadTournamentInfo(id_tournament))
                dispatch(changeErrorMessageSnackBar( {                    
                    message: i18n.t('league:snackBarMessages.endedTournament', {page: page}),                    
                    error_message_is_open: true
                    } )
                );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}


export const deleteTeamToTournament = ( id_tournament_team, id_tournament ) => {

    return ( dispatch ) => {
        
        DeleteTeamToTournamentApi( id_tournament_team )
            .then( response => {
                dispatch( deleteTeamFromTournamentAction( id_tournament_team ));
                //dispatch( deleteTeamSetupFromTournamentAction(id_tournament_team)); //optimizacion de apis    
                //dispatch( loadTournamentResultsTable( id_tournament )) ;
                dispatch(changeErrorMessageSnackBar( {                    
                    message: i18n.t('league:snackBarMessages.deletedTeam'),                    
                    error_message_is_open: true
                    } )
                );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const disableTeamFromTournament = ( id_tournament_team, id_tournament ) => {

    return ( dispatch ) => {
        
        DeleteTeamToTournamentApi( id_tournament_team )
            .then( response => {
                dispatch( disableTeamFromTournamentAction( response )) ;    
                //dispatch( loadTournamentResultsTable( id_tournament )) ;
                dispatch( deleteTeamFromTournamentAction( id_tournament_team )); //optimizacion de apis
                //dispatch( deleteTeamSetupFromTournamentAction(response) ); //optimizacion de apis
                dispatch(changeErrorMessageSnackBar( {                    
                    message: i18n.t('league:snackBarMessages.disabledTeam'),                    
                    error_message_is_open: true
                    } )
                );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const deleteMemberFromTournament = ( id_member_staff ) => {

    return ( dispatch ) => {
        
        DeleteMemberFromTournamentApi( id_member_staff )
            .then( response => {
                dispatch( deleteMemberFromTournamentAction( id_member_staff )) ;
                dispatch(changeErrorMessageSnackBar( {                    
                    message: i18n.t('league:snackBarMessages.deleted'),                    
                    error_message_is_open: true
                    } )
                );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadTournamentTeams = ( tournament_id, tournament_team_name, state, page_size=10, next_page ) => {

    return async ( dispatch ) => {
        dispatch( changeLoadingTournamentTeamsStateAction( true ) );
        //GetTournamentTeamsApi( tournament_id, next_page )
        await SearchTournamentTeamsAdminApi( tournament_id, tournament_team_name, state, page_size, next_page )
        //GetTournamentHubTeamsApi( tournament_id ) //optimizacion de fases
            .then( response => {
                dispatch( loadTournamentTeamsAction( response ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            }).then( () => {
                dispatch( changeLoadingTournamentTeamsStateAction( false ) );
            } );
    }
}

export const loadTournamentMembers = ( tournament_id ) => {

    return ( dispatch ) => {
        
        GetTournamentMembersApi( tournament_id )
            .then( response => {
                dispatch( loadTournamentMembersAction( response ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadTournamentResultsTable = ( tournament_id ) => {

    return ( dispatch ) => {
        
        GetTournamentResultsTableApi( tournament_id )
            .then( response => {
                dispatch( loadTournamentResultsTableAction( response ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadTournamentPhases = ( tournament_id ) => {

    return async ( dispatch ) => {
        
        dispatch( isLoadedTournamentPhasesAction( true ));

        await GetTournamentPhasesApi( tournament_id )
            .then( response => {
                dispatch( loadTournamentPhasesAction( response ) );
                dispatch( isLoadedTournamentPhasesAction( response.data.results.length > 0) );
                // dispatch(loadAddPhaseAction(response.data));
            })
            .catch(err => {
                dispatch( isLoadedTournamentPhasesAction( false ) );
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadPhaseResultsTable = ( phase_id ) => {

    
    return async ( dispatch ) => {
        dispatch ( resetTournamentGroupResultTableAction({}) );
        await GetPhaseResultsTableApi( phase_id )
            .then( response => {                   
                dispatch( loadPhaseGroupResultsTableAction( response.data ));
                if(response.data.length > 0 ){
                    dispatch( loadPhaseGroupPositionsTable( response.data[0].id));
                }
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadPhaseGroupPositionsTable = ( tournament_group_id, page_size, next_page ) => {

    
    return ( dispatch ) => {
        dispatch( loadingPhaseGroupPositionsTableStateAction(true))
        GetGroupPhaseResultsTableApi( tournament_group_id, page_size, next_page )
            .then( response => {             
                dispatch( loadPhaseGroupPositionsTableAction( response.data ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
            .then( () => {             
                dispatch( loadingPhaseGroupPositionsTableStateAction( false ) );                
            })
    }
}

export const loadTournamentInfo = ( tournament_id ) => {
    return async ( dispatch ) => {
        
        await GetTournamentInfoApi( tournament_id )
            .then( response => {
                dispatch( loadTournamentInfoAction(response) );               
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const searchWorldCities = ( searched_city ) => {

    return ( dispatch ) => {
        
        SearchWorldCitiesApi( searched_city )
            .then( response => {
                dispatch( searchWorldCitiesAction(response) );               
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const getCountries = (country) =>  {
    /*return (dispatch) => {
        GetCountriesApi(country)
            .then( response => {
                dispatch(getCountriesAction(response) );               
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }*/
    return async dispatch => {
        try {
            const response = await GetCountriesApi(country);
            dispatch(getCountriesAction(response) );
        }
        catch( error) {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('league:snackBarMessages.snackBarError'),
                    error_message_is_open: true
                } ) )
            }
        }
        return 'done';
    }
}

export const searchCityByCountry = (countryId, searched_city) => {
    /*return(dispatch)=> {
        searchCityByCountryApi(countryId, searched_city)
            .then(response => {
                dispatch(searchCityByCountryAction(response));
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
        })
    }*/
    return async dispatch => {
        try {
            const response = await searchCityByCountryApi(countryId, searched_city);
            dispatch(searchCityByCountryAction(response) );
        }
        catch( error) {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('league:snackBarMessages.snackBarError'),
                    error_message_is_open: true
                } ) )
            }
        }
        return 'done';
    }
}

export const loadTournamentPlayers = ( tournament_id ) => {

    return async ( dispatch ) => {
        
        await GetTournamentPlayersApi( tournament_id )
            .then( response => {
                dispatch( loadTournamentPlayersAction(response) );               
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const addTeamToGroup = ( group_id, team_name, phase_id, teams_filter_type, tournament_active_phase_teams_modal = null ) => {

    return ( dispatch ) => {

        AddTeamToGroupApi( group_id, team_name )
            .then( response => {
                dispatch( addTeamToGroupAction(response));
                if(tournament_active_phase_teams_modal === 'tournament_active_phase_teams_modal') {
                    dispatch(loadTournamentPhaseTeams( phase_id, teams_filter_type, tournament_active_phase_teams_modal));
                } 
                dispatch(loadTournamentPhaseTeams( phase_id, teams_filter_type)); 
                dispatch(changeErrorMessageSnackBar( {                    
                    message: i18n.t('league:snackBarMessages.addedTeam'),                    
                    error_message_is_open: true
                    } )
                );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const addTournamentPhase = ( id_tournament ) => {


    return ( dispatch ) => {
        
        AddTournamentPhaseApi( id_tournament )
            .then( response => {
                dispatch(loadAddPhaseAction(response.data));
                dispatch (loadTournamentPhases( id_tournament ));
                dispatch (loadTournamentPhaseTeams( response.data.id, 'not_assigned'));
                dispatch (loadCriteriaTournamentPhase(response.data.id));
                dispatch (loadPhaseFairPlaySetting( response.data.phase_fair_play_id ));
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const deleteTeamFromGroup = ( groupData, teamName, phaseId, teams_filter_type, tournament_active_phase_teams_modal = null ) => {
    return ( dispatch ) => {

        DeleteTeamFromGroupApi( groupData.group_id, groupData.id )
            .then( response => {
                dispatch( deleteTeamFromGroupAction( groupData ));
                if(tournament_active_phase_teams_modal === 'tournament_active_phase_teams_modal'){
                    dispatch(loadTournamentPhaseTeams( phaseId, teams_filter_type, tournament_active_phase_teams_modal));
                } 
                dispatch(loadTournamentPhaseTeams( phaseId, teams_filter_type));
                dispatch(changeErrorMessageSnackBar( {                    
                    message: i18n.t('league:snackBarMessages.deletedTeam'),                    
                    error_message_is_open: true
                    } )
                );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const setTournamentActivePhase = ( phase_id ) => {
    
    return async ( dispatch ) =>  {
        
       await GetTournamentPhaseApi( phase_id )
            .then( response => {
                dispatch( setTournamentActivePhaseOfEndPointAction(response) );
                dispatch (loadPhaseFairPlaySetting( response.data.phase_fair_play_id ));
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}
//TODO limpiar codigo
// export const finishTournamentPhase = ( id_phase  ) => {

//     return ( dispatch ) => {
        
//         FinishTournamentPhaseApi( id_phase )
//             .then( response => {
//                 // console.log('rf',response);
//                 dispatch( finishTournamentPhaseAction( response.data ));

//             })
//             .catch(err => {
//                 if(localStorage.getItem('token') != null){
//                     dispatch( changeErrorMessageSnackBar( {
//                         message: i18n.t('league:snackBarMessages.snackBarError'),
//                         error_message_is_open: true
//                     } ) )
//                 }
//             })
//     }
// }

export const deleteTournamentPhase = ( id_tournament, phase ) => {
    return ( dispatch ) => {
        
        DeleteTournamentPhaseApi( id_tournament, phase.id )
            .then( response => {
                // console.log('r',response);
                dispatch( deleteTournamentPhaseAction( phase ));
                dispatch( resetTournamentActivePhaseAction({}) );
                dispatch (loadTournamentPhases( id_tournament ));
                dispatch(changeErrorMessageSnackBar( {                    
                    message: i18n.t('league:snackBarMessages.deletedPhase'),                    
                    error_message_is_open: true
                    } )
                );            
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const deleteTournamentPhaseWihtOutType = ( id_tournament, phase ) => {
    return async ( dispatch ) => {
        await DeleteTournamentPhaseApi( id_tournament, phase.id )
            .then( async response => {
                // console.log('r',response);
                dispatch( deleteTournamentPhaseAction( phase ));
                dispatch( resetTournamentActivePhaseAction({}) );//TODO: validar flexibilizacion de fases
                await dispatch (loadTournamentPhases( id_tournament ));
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const classifiedTeamsByPhase = ( phase ) => {    
    return async ( dispatch ) => {
        
        await GetTournamentClassifiedTeamsByPhaseApi( phase.id )
            .then( response => {
                // console.log('rTeam',response);
                dispatch(teamsClassifiedByPhaseAction(response.data));
                if(phase.phase_type === 'Groups' || phase.phase_type === 'Knockout'){
                    dispatch(changeFinishGroupsPhaseModalStateAction(true));
                }else if(phase.phase_type === 'Free'){
                    dispatch(changeFinishFreePhaseModalStateAction(true));
                }          
            })
            .catch(err => {
                dispatch(unfinishedMatchesAction(err.response.data));
                //dispatch(changeMatchsToCloseModalStateAction(true));
                // if(localStorage.getItem('token') != null){
                //     dispatch( changeErrorMessageSnackBar( {
                //         message: i18n.t('league:snackBarMessages.snackBarError'),
                //         error_message_is_open: true
                //     } ) )
                // }
            })
    }
}




export const finishedMatchesByPhase = ( phase ) => {

    return ( dispatch ) => {
        
        GetTournamentClassifiedTeamsByPhaseApi( phase.id )
            .then( response => {
                if(response.data.length === 0 || response.data.some(data => data.calculate_positions_table === false)){
                    dispatch(changeUnfinishedTournamentMatchesStateAction(true));    
                } else {
                    dispatch(changeUnfinishedTournamentMatchesStateAction(false));
                }
            })
            .catch(err => {
                dispatch(changeUnfinishedTournamentMatchesStateAction(true));
            })
    }
}




export const loadTournamentPhaseTeams = ( id_phase, teams_state, tournament_active_phase_teams_modal = null ) => {
   
    return async ( dispatch ) => {
        
        await GetFilterPhaseTeamsApi( id_phase, teams_state )
            .then( response => {
                if(tournament_active_phase_teams_modal === 'tournament_active_phase_teams_modal') {
                    dispatch(loadTournamentPhaseTeamsModalAction(response));
                }
                dispatch( loadTournamentPhaseTeamsAction(response) );
                if(teams_state === 'assigned'){
                    dispatch( loadTournamentPhaseInterGroupsTeamsAction(response) );
                }
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){

                    if(err.response.status != 404){
                        dispatch( changeErrorMessageSnackBar( {
                            message: i18n.t('league:snackBarMessages.snackBarError'),
                            error_message_is_open: true
                        } ) )
                    }
                    
                }

            })
    }
}

export const loadScorerTeamPageMembers = ( team_page_name ) => {
    return ( dispatch ) => {
        GetTeamPageMembersApi( team_page_name )
            .then( response => {
                dispatch( loadScorerTeamPageMembersAction( response ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })

    }
}

export const loadBeatenFenceTeamPageMembers = ( team_page_name ) => {
    return ( dispatch ) => {
        // console.log('entro por loadBeatenFenceTeamPageMembers');
        GetTeamPageMembersApi( team_page_name )
            .then( response => {
                dispatch( loadBeatenFenceTeamPageMembersAction( response ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })

    }
}

export const loadChampionTeamPageMembers = ( team_page_name ) => {
    return ( dispatch ) => {
        // console.log('entro por loadChampionTeamPageMembers');
        GetTeamPageMembersApi( team_page_name )
            .then( response => {
                dispatch( loadChampionTeamPageMembersAction( response ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const deleteDate = ( date,index,group ) => {
    return ( dispatch ) => {
        
        DeleteDateApi( date.id )
        .then( response => {
            // console.log('r',response);
            dispatch( deleteDateAction( date.id ));
            dispatch( deleteFilterDateAction( date.id ) );
            if(group){
                dispatch( loadTournamentPhaseGroupDates(group) );
            }
            if(index === 1){
                dispatch( resetTournamentSelectedDateAction())
            }
            dispatch(changeErrorMessageSnackBar( {                    
                message: i18n.t('league:snackBarMessages.deletedDate'),                    
                error_message_is_open: true
                } )
            );            
        })
        .catch(err => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('league:snackBarMessages.snackBarError'),
                    error_message_is_open: true
                } ) )
            }
        })
    }
}

export const loadTournamentPosts = ( page_name, tournament_id, state, type ) => {

    return ( dispatch ) => {
        
        GetTournamentPostsApi( page_name, tournament_id, state, type )
            .then( response => {
               dispatch( loadTournamentPostsAction( response ) );
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            } )
    }
}

export const searchBaloaTeam = (searched_team) => {
    return(dispatch)=> {
        SearchBaloaTeamApi(searched_team)
            .then(response => {
                dispatch(searchBaloaTeamAction(response));
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })         
    }
}

export const updateTournamentBasicInfo  = ( tournament_data, tournament_id ) => {
   
    return ( dispatch ) => {
        
        UpdateTournamentApi( tournament_data, tournament_id )
            .then( response => {
                dispatch( loadTournamentAction(response) )
                dispatch( loadLeagueTournaments(response.data.league_page) )
                dispatch(changeErrorMessageSnackBar( {
                    message: i18n.t('league:snackBarMessages.updatedTournament'),                    
                    error_message_is_open: true
                    } )
                );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}  

export const setFinalFixture  = (phase_id, fixture_shuffle, roundtrip ) => {
   
    return async ( dispatch ) => {
        try {
            const response = await SetFinalFixture( phase_id, fixture_shuffle, roundtrip );
            dispatch( setActivePhaseFixtureAction(response) );     
        }
        catch( error) {
        
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('league:snackBarMessages.snackBarError'),
                    error_message_is_open: true
                } ) )
            }
        }
        return 'done';
    }
}

export const searchBaloaMember = (searched_member) => {
    return(dispatch)=> {
        GetSearchMembersApi(searched_member)
            .then(response => {
                dispatch(searchBaloaMemberAction(response));
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })         
    }
}

export const loadPhaseMatchesByDate = ( phase_id, calendar_date ) => {
    return async ( dispatch ) => {        
       await GetPhaseMatchesByDateApi( phase_id, calendar_date )
            .then( response => {             
                dispatch( loadPhaseMatchesByDateAction( response ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadPhaseDates = ( phase_id ) => {
    return async ( dispatch ) => { 
        dispatch( resetTournamentDatesAction( )); 
        dispatch( resetTournamentPhaseTeamsAction( ));       
       await GetPhaseDatesApi( phase_id )
            .then( response  => { 
                dispatch( loadPhaseDatesAction( response ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadfilterTournament =(league_pagename,type_filter)=> {
    return (dispatch)=>{
        LoadFilterToTournamentApi (league_pagename, type_filter)
            .then(response => {
                dispatch( loadLeagueTournamentsAction( response ) ); 
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }   
            })
    }
}

export const changeTournamentTeamState = ( tournament_team_id, team_state ) => {
    return ( dispatch ) => {
        ChangeTournamentTeamApi( tournament_team_id, team_state )        
            .then( response => {
                dispatch( changeTeamTournamentStateAction(response));
                dispatch( changeStartTournamentStateAction(response.data) );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const changeTournamentState = ( tournament_id, state ) => {
    return ( dispatch ) => {
        ChangeTournamentApi( tournament_id, state )
            .then( response => {
                dispatch( loadTournamentAction(response) );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadTournamentTeamPlayers = ( team_id, state, next_page ) => {
    return ( dispatch ) => {
        dispatch(loadFullResponseTeamMemberAction(true));
        GetTournamentTeamPlayersApi( team_id, state, next_page )
            .then( response => {                
                dispatch( loadTournamentTeamPlayersAction(response) );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            }).then( () => {
                dispatch(loadFullResponseTeamMemberAction(false));
            } );
    }
}

export const searchAdminTournamentTeamPlayers = ( team_id, search, state ) => {
    return ( dispatch ) => {
        dispatch(loadFullResponseTeamMemberAction(true));
        GetFrontTournamentTeamSearchPlayersApi( team_id, search, state)
            .then( response => {                
                dispatch( loadTournamentTeamPlayersAction(response) );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            }).then( () => {
                dispatch(loadFullResponseTeamMemberAction(false));
            } );
    }
}

export const loadTournamentMembersTeams = ( tournament_team_id ) => {
    return ( dispatch ) => {
        GetTournamentMembersTeamsApi( tournament_team_id )
            .then( response => {
                dispatch( loadTournamentMembersTeamsAction(response) );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const changeTeamPlayerState = ( player_id, state, tournament_team_id ) => {
    return ( dispatch ) => {
        ChangeTournamentPlayerApi( player_id, state )
            .then( response => {
                let data = {
                    team_enrolled_players: response.data.team_enrolled_players,
                    tournament_team_id: tournament_team_id,
                }
                if(state === 'Rejected'){
                    dispatch( removerPlayerFromTeamAction( player_id ) );
                    dispatch( updateTournamentTeamAction(data) );
                }
                if(state === 'Enrolled' || state === 'Pending') {
                    dispatch(loadTournamentTeamPlayers(tournament_team_id, 'Enrolled|Pending|Late_Register'));
                }                
            })
            .catch(err => {                
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadTournamentSetupTeams = ( tournament_id, team_state ) => {

    return ( dispatch ) => {
        
        GetTournamentHubTeamsApi( tournament_id, team_state )
            .then( response => {
                dispatch( loadTournamentSetupTeamsAction( response ) );
                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const searchBaloaTeamToInvited = (searched_team) => {
    const { id, teamName } = searched_team;
    return(dispatch)=> {
        SearchBaloaTeamToInvitedApi(id, teamName)
            .then(response => {
                dispatch(searchBaloaTeamAction(response));
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadFormData = ( form_id ) => {

    return ( dispatch ) => {
        
        getFormApi( form_id )
            .then( response => {
                dispatch( loadFormDataAction( response ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadCriteriaTournamentPhase = ( id_phase ) => {

    return ( dispatch ) => {
        
        GetCriteriaTounretsApi( id_phase )
            .then( response => {
                dispatch(loadCriteriaTournamentPhaseAction(response.data.results))
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const setNewCriteriaTournamentPhase = ( id_phase, array_criteria ) => {

    return ( dispatch ) => {
        
        PutNewCriteriaTounretsApi( id_phase, array_criteria )
            .then( response => {
                // console.log("🚀 New Order Criterios", response);
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const getFieldsStatusEditing = ( id_tournament ) => {

    return async ( dispatch ) => {
        
        await GetFieldsStatusEditingApi( id_tournament )
            .then( response => {
                dispatch(loadFieldsStatusEditingAction(response.data))
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const transferTeamPlayer = ( tournament_player_id, teamname, tournament_id ) => {
    return ( dispatch ) => {        
        TransferTeamPlayerApi( tournament_player_id, teamname )
            .then( response => {
                dispatch( removerPlayerFromTeamAction( tournament_player_id ) );
                //dispatch( loadTournamentSetupTeams(tournament_id))
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('league:snackBarMessages.transferPlayer'),
                    error_message_is_open: true
                } ) )
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    if(err.response.data.tournament_team_player){
                        dispatch( changeErrorMessageSnackBar( {
                            message: "Error: "+err.response.data.tournament_team_player[0],
                            error_message_is_open: true
                        } ) )
                    } else {
                        dispatch( changeErrorMessageSnackBar( {
                            message: i18n.t('league:snackBarMessages.snackBarError'),
                            error_message_is_open: true
                        } ) )
                    }
                }
            })
    }
}

export const loadTournamentPhaseFilterTeams = ( id_phase, filter_type, tournament_active_phase_teams_modal = null ) => {

    return ( dispatch ) => {
        
        GetFilterPhaseTeamsApi( id_phase,filter_type )
            .then( response => {
                if(tournament_active_phase_teams_modal === 'tournament_active_phase_teams_modal') {
                    dispatch(loadTournamentPhaseTeamsModalAction(response));
                } else {
                    dispatch( loadTournamentPhaseTeamsAction(response) );
                }
                dispatch( loadTournamentPhaseFilterTeamsAction(response) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){

                    if(err.response.status != 404){
                        dispatch( changeErrorMessageSnackBar( {
                            message: i18n.t('league:snackBarMessages.snackBarError'),
                            error_message_is_open: true
                        } ) )
                    }
                    
                }

            })
    }
}

export const UpdateBestThirdToGroup = (status, groupId,team,originla_Id) => {

    return ( dispatch ) => {
        
        UpdateBestThirdToGroupApi(status, groupId, team, originla_Id)
            .then( response => {
                dispatch( console.log(response) );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    if(err.response.status != 404){
                        dispatch( changeErrorMessageSnackBar( {
                            message: i18n.t('league:snackBarMessages.snackBarError'),
                            error_message_is_open: true
                        } ) )
                    }
                    
                }

            })
    }
}

export const EditPhaseById = (phases_data, phase_id) => {

    return ( dispatch ) => {
        
        EditPhasesApi(phases_data, phase_id)
            .then( response => {
                dispatch(stateSuccessFulPhaseEditionAction(response.status));
                dispatch(loadAddPhaseAction(response.data));
                dispatch(setTournamentActivePhaseEditAction(response.data));
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){

                    if(err.response.status != 404){
                        dispatch( changeErrorMessageSnackBar( {
                            message: i18n.t('league:snackBarMessages.snackBarError'),
                            error_message_is_open: true
                        } ) )
                    }
                    
                }

            })
    }
}

export const transferTeamKnockout = ( win_team, lost_team, group_id ) => {
    return ( dispatch ) => {        
        TransferTeamKnockoutApi( win_team, lost_team, group_id )
            .then( response => {
                // console.log(response)
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    if(err.response.data.tournament_team_player){
                        dispatch( changeErrorMessageSnackBar( {
                            message: "Error: "+err.response.data.tournament_team_player[0],
                            error_message_is_open: true
                        } ) )
                    } else {
                        dispatch( changeErrorMessageSnackBar( {
                            message: i18n.t('league:snackBarMessages.snackBarError'),
                            error_message_is_open: true
                        } ) )
                    }
                }
            })
    }
}

export const GetCodeFormTemplate = ()=>{
    return (dispatch)=>{
        GetCodeFormTemplateApi()
        .then( response => {
            dispatch(codeFormIDTemplateAction(response.data));
        }).catch(err => {
            if(localStorage.getItem('token') != null){

                if(err.response.status != 404){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            }
        })
    }
}


export const loadTournamentStaff = ( tournament_id ) => {

    return ( dispatch ) => {
        
        GetTournamentStaffApi( tournament_id )
            .then( response => {
                dispatch( loadTournamentStaffAction( response ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const searchBaloaUser = (searched_member) => {
    return(dispatch)=> {
        GetBaloaUsersApi(searched_member)
            .then(response => {
                dispatch(searchBaloaUserAction(response));
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })         
    }
}

export const addUserToStaff = (username, role, staff_state, tournament_id) =>{
    return(dispatch)=> {
        AddStaffApi(username, role, staff_state, tournament_id)
            .then(response => {
                dispatch(addUserToStaffAction(response));
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })         
    }
}

export const updateStaffState = (role_id, role_state, role_type) =>{
    return(dispatch)=> {
        UpdateStaffStateApi(role_id, role_state, role_type)
            .then(response => {
                dispatch(updateStaffStateAction(response));
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })         
    }
}

export const deleteStaff = (role_id) =>{
    return(dispatch)=> {
        DeleteStaffApi(role_id)
            .then(response => {
                dispatch(deleteStaffAction(role_id));
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })         
    }
}

export const loadRefeeringPaymentUrl = (payment_data, match_id) => {
    return async (dispatch) => {
        await GetRefereeingPaymentApi(payment_data, match_id)
            .then(response => {
                dispatch( loadRefereeingPaymentUrlAction( response.data ) );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            }) 
    }
}
export const calculateRefereeFee = (data) =>{
    return(dispatch)=> {
        CalculateRefereeFeeApi(data)
            .then(response => {
                dispatch(calculateRefereeFeeAction(response));
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            }) 
    }
}

export const assignRefereesMatch = ( id_match, match, tournament_date ) => {

    return async ( dispatch ) => {
        
        await EditMatchApi( id_match, match )
            .then( response => {
                dispatch( loadMatchReport( id_match ) );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const makeRefereePayment = ( match_id ) => {
    
    return (dispatch) => {
        RefereePaymentApi( match_id )
            .then( response => {
                dispatch( loadMatchReport( match_id ) );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadTournamentPhaseInterGroupsTeams = ( id_phase, teams_state ) => {

    return ( dispatch ) => {
        
        GetFilterPhaseTeamsApi(id_phase, teams_state)
            .then( response => {
                dispatch( loadTournamentPhaseInterGroupsTeamsAction(response) );               
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){

                    if(err.response.status != 404){
                        dispatch( changeErrorMessageSnackBar( {
                            message: i18n.t('league:snackBarMessages.snackBarError'),
                            error_message_is_open: true
                        } ) )
                    }
                    
                }

            })
    }
}

export const loadPhaseTeamsNotAssigned = ( id_phase, teams_state ) => {

    return ( dispatch ) => {
        
        GetFilterPhaseTeamsApi( id_phase, teams_state )
            .then( response => {
                dispatch( loadPhaseTeamsNotAssignedAction(response) );               
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){

                    if(err.response.status != 404){
                        dispatch( changeErrorMessageSnackBar( {
                            message: i18n.t('league:snackBarMessages.snackBarError'),
                            error_message_is_open: true
                        } ) )
                    }
                    
                }

            })
    }
}

export const downloadFormData = ( id_tournament ) => {
    return async ( dispatch ) => {
        
        await DownloadFormDataApi( id_tournament )
            .then( response => {
                let fileName = `baloa-forms_${id_tournament}.xlsx`;
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                // IE variant
                window.navigator.msSaveOrOpenBlob(
                    new Blob([response.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    encoding: 'UTF-8'
                    }),
                    fileName
                );
                } else {
                const url = window.URL.createObjectURL(
                    new Blob([response.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    encoding: 'UTF-8'
                    })
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
                }
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const downloadPaymentData = ( id_tournament ) => {
    return async ( dispatch ) => {
        
        await DownloadPaymentDataApi( id_tournament )
            .then( response => {        
                let fileName = `baloa-payments_${id_tournament}.xlsx`;
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                // IE variant
                window.navigator.msSaveOrOpenBlob(
                    new Blob([response.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    encoding: 'UTF-8'
                    }),
                    fileName
                );
                } else {
                const url = window.URL.createObjectURL(
                    new Blob([response.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    encoding: 'UTF-8'
                    })
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
                }
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const updateClassifiedCountGroup = ( id_group, classified_count, id_phase, id_tournament) => {
    return ( dispatch ) => {        
        UpdateClassifiedCountGroupApi( id_group, classified_count )
            .then( response => {
                dispatch(loadTournamentPhases(id_tournament));
                dispatch(setTournamentActivePhase(id_phase));
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    if(err.response.status != 404){
                        dispatch( changeErrorMessageSnackBar( {
                            message: i18n.t('league:snackBarMessages.snackBarError'),
                            error_message_is_open: true
                        } ) )
                    }                    
                }
            })
    }
}

export const createTournamentPhaseGroup = ( id_phase, id_tournament ) => {
    return async ( dispatch ) => {        
        await CreateTournamentPhaseGroupApi( id_phase )
            .then( async response =>  {
                dispatch(loadTournamentPhases(id_tournament));
                await dispatch(setTournamentActivePhase(id_phase));
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    if(err.response.status != 404){
                        dispatch( changeErrorMessageSnackBar( {
                            message: i18n.t('league:snackBarMessages.snackBarError'),
                            error_message_is_open: true
                        } ) )
                    }                    
                }
            })
    }
}

export const deleteGroupFromPhase = ( id_group, id_phase, id_tournament, teams_filter_type, tournament_active_phase_teams_modal = null) => {
    return ( dispatch ) => {        
        DeleteGroupFromPhaseApi( id_group )
            .then( response => {
                dispatch(loadTournamentPhases(id_tournament)); 
                dispatch(setTournamentActivePhase(id_phase));
                dispatch(loadTournamentPhaseTeams( id_phase, teams_filter_type, tournament_active_phase_teams_modal));
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    if(err.response.status != 404){
                        dispatch( changeErrorMessageSnackBar( {
                            message: i18n.t('league:snackBarMessages.snackBarError'),
                            error_message_is_open: true
                        } ) )
                    }                    
                }
            })
    }
}

export const cleanAllGroupOfPhase = ( id_phase, id_tournament, tournament_active_phase_teams_modal = null ) => {
    return  ( dispatch ) => {        
        CleanAllGroupOfPhaseApi( id_phase )
            .then( response => {
                dispatch(loadTournamentPhases(id_tournament));
                const timeout = setTimeout(() => { 
                    dispatch(setTournamentActivePhase(id_phase));                    
                    if(tournament_active_phase_teams_modal === 'tournament_active_phase_teams_modal') {                        
                            dispatch(loadTournamentPhaseTeams( id_phase, 'not_assigned', tournament_active_phase_teams_modal));
                            dispatch(loadTournamentPhaseTeams( id_phase, 'assigned')); 
                    } else {
                        dispatch(loadTournamentPhaseTeams( id_phase, 'not_assigned')); 
                    }
                    
                }, 1000);
                return () => clearTimeout(timeout)
                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    if(err.response.status != 404){
                        dispatch( changeErrorMessageSnackBar( {
                            message: i18n.t('league:snackBarMessages.snackBarError'),
                            error_message_is_open: true
                        } ) )
                    }                    
                }
            })
    }
}


export const createAutomaticGroupPhases = (id_phase, id_tournament, filter_state, tournament_active_phase_teams_modal = null) =>{
    return  ( dispatch ) => {        
        CreateAutomaticGroupPhasesApi( id_phase, filter_state )
            .then( response => {
                dispatch(loadTournamentPhases(id_tournament)); 
                dispatch(setTournamentActivePhase(id_phase));
                dispatch(loadTournamentPhaseTeams( id_phase, filter_state, tournament_active_phase_teams_modal));
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    if(err.response.status != 404){
                        dispatch( changeErrorMessageSnackBar( {
                            message: i18n.t('league:snackBarMessages.snackBarError'),
                            error_message_is_open: true
                        } ) )
                    } else {
                        dispatch( changeErrorMessageSnackBar( {
                            message: err.response.data.detail,
                            error_message_is_open: true
                        } ) )
                    }                  
                } 
            })
    }
}

export const endTournamentPhase = ( id_phase, tournament_id  ) => {

    return ( dispatch ) => {
        EndTournamentPhaseApi( id_phase )
            .then( async response => {
                
                await dispatch(loadTournamentPhases ( tournament_id ));
                dispatch( changePhaseFinalizedStateAction(true) );
                //dispatch( finishTournamentPhaseAction( response.data ));
            })
            .catch(err => {
                dispatch( changePhaseFinalizedStateAction(false) );
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const addAllTeamsToGroup = ( id_group, state, tournament_id, phase_id ) => {
    return ( dispatch ) => {
        AddAllTeamsToGroupApi(  id_group, state )
            .then( response => {
                dispatch(loadTournamentPhases(tournament_id)); 
                dispatch(setTournamentActivePhase(phase_id));
                dispatch(loadTournamentPhaseTeams( phase_id, 'not_assigned'));
                
            })
            .catch(err => {
                dispatch( changePhaseFinalizedStateAction(false) );
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadTeamsToMatchAssistanceAligment = ( matchId ) => {
    return ( dispatch ) => {
        GetTeamsToMatchAssistanceAligmentApi(  matchId )
            .then( response => {
                dispatch(setTeamsToMatchAssistanceAligment(response)); 
            })
            .catch(err => {
                dispatch( changePhaseFinalizedStateAction(false) );
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const updatePlayersAssistanceAligment = ( matchId, playerArray) => {
    return ( dispatch ) => {
        PatchPlayerAssistanceAligmentApi(  matchId, playerArray )
            .then( response => {
                dispatch(setTeamsToMatchAssistanceAligment(response)); 
            })
            .catch(err => {
                dispatch( changePhaseFinalizedStateAction(false) );
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadSearchRunnerUpTeamPlayers = ( team_page_name ) => {
    return ( dispatch ) => {
        GetTeamPageMembersApi( team_page_name )
            .then( response => {
                dispatch( loadRunnerUpTeamPlayersAction( response ) );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadSearchThirdPlaceTeamPlayers = ( team_page_name ) => {
    return ( dispatch ) => {
        GetTeamPageMembersApi( team_page_name )
            .then( response => {
                dispatch( loadThirdPlaceTeamPlayersAction( response ) );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadSearchTournamentPlayerTeamPlayers = ( team_page_name ) => {
    return ( dispatch ) => {
        GetTeamPageMembersApi( team_page_name )
            .then( response => {
                dispatch( loadTournamentPlayerTeamPlayersAction( response ) );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadSearchRevelationTeamPlayers = ( team_page_name ) => {
    return ( dispatch ) => {
        GetTeamPageMembersApi( team_page_name )
            .then( response => {
                dispatch( loadRevelationPlayerTeamPlayersAction( response ) );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadItemsForTournamentType = () => {
    return ( dispatch ) => {
        GetItemsForTournamentTypeApi()
            .then( response => {
                dispatch(setItemsForTournamentTypeAction(response)); 
            })
            .catch(err => {
                dispatch( changePhaseFinalizedStateAction(false) );
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const enrollRejectMultiplePlayers = ( tournament_team_id, players_states, player_states_filter ) =>{
    return ( dispatch ) => {
        EnrollRejectMultiplePlayersApi( tournament_team_id, players_states )
            .then( response => {
                dispatch( loadTournamentTeamPlayers( tournament_team_id, player_states_filter ) ); 
            })
            .catch(err => {            
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadTournamentPlayerFormTemplate = ( form_type ,id_tournament ) => { 

    return async ( dispatch ) => {
        
        await GetTournamentInscriptionFormTemplateIdApi( form_type, id_tournament )
            .then( response => {
                dispatch( loadFormsAction( response.data ) );                              
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            }
            )
        }
}


export const loadListFormExisting = (pageName, formtype) => {
    return ( dispatch ) => {
        GetListExistingFormApi(pageName, formtype)
            .then( response => {
                dispatch(loadListExistingFormAction(response.data.results)); 
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadPageFormTemplate = (id_form_template) => {
    return ( dispatch ) => {
        GetTournamentInscriptionFormTemplateApi(id_form_template)
            .then( response => {
                dispatch(loadPageFormTemplateAction(response.data)); 
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const createCarnetFormTemplate = (form_data, form_type_id, caption, tournament_id, templateImage, orientation ) => {

    return ( dispatch ) => {
        
        CreateNewFormApi(form_data, form_type_id, caption, tournament_id, caption )       
            .then( response => {
                dispatch(createCarnetFormTemplateAction(response.data, templateImage, orientation)); 
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const saveCardIdFormInfo = (form_info) => {

    return ( dispatch ) => {
        
        SaveCardIdFormInfoApi(form_info )       
            .then( response => {
                dispatch(saveCardIdFormInfoAction(response.data)); 
                dispatch(changeCardIdComponentStateAction(true));
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const saveCardIdFormInfoAndClean = (form_info) => {

    return ( dispatch ) => {
        
        SaveCardIdFormInfoApi(form_info )       
            .then( response => {
                dispatch(cleanCardIdInfoAction({})); 
                dispatch(changeCardIdComponentStateAction(false));
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadCardIdTemplate = ( form_type ,id_tournament ) => { 

    return async ( dispatch ) => {
        
        await GetTournamentInscriptionFormTemplateIdApi( form_type, id_tournament )
            .then( response => {
                dispatch( loadCardIdTemplateAction( response.data[0] ) );                              
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            }
            )
        }
}

export const deleteCardIdTemplate = (id_template) => {

    return ( dispatch ) => {
        
        DeleteFormTemplateApi(id_template )       
            .then( response => {
                dispatch(deleteCardIdTemplateAction({}));                 
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadCardIdsData = ( tournament_id, teams_list, token = null ) => {

    return async ( dispatch ) => {
        
        await GetCardIdsDataApi( tournament_id, teams_list, token )
            .then( response => {                
                dispatch( loadCardIdsDataAction( response.data ) );             
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}


export const loadPlayerCardIdData = ( tournament_player_id, token = null ) => {

    return async ( dispatch ) => {
        
        await GetPlayerCardIdDataApi( tournament_player_id, token )
            .then( response => {                
                dispatch( loadCardIdsDataAction( response.data ) );             
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadTeamManagerAndStaff = ( team_name, tournament_id ) => {
    return ( dispatch ) => {
        
        GetTournamentTeamManagerAndStaffApi( team_name, tournament_id )
            .then( response => {
                dispatch( loadTeamManagerAndStaffAction( response.data ) );
            })
            .catch(err => {

                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadStaffCardIdData =( team_profile_id, tournament_team_id, token = null ) => {

    return async ( dispatch ) => {
        
        await GetStaffCardIdDataApi( team_profile_id, tournament_team_id, token )
            .then( response => {                
                dispatch( loadCardIdsDataAction( response.data ) );             
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const createPageFields = ( form_data, page_name ) => {
    return ( dispatch ) => {
        
        PostCreatePageFieldsdApi( form_data )
            .then( response => {
                dispatch( pageFieldData( response.data ) );
                dispatch( loadPageFieldsData( page_name ) );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadPageFieldsData = ( page_name ) => {

    return async ( dispatch ) => {
        
        await GetPageFieldsdApi( page_name )
            .then( response => {  
                dispatch( loadPageFieldsDataAction( response.data ) );             
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const editPageFields = ( form_data, field_id, page_name ) => {

    return async ( dispatch ) => {
        
        await PatchEditPageFieldsdApi( form_data, field_id )
            .then( response => {
                dispatch( loadPageFieldsData( page_name ) );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const deletePageField = ( field_id ) => {

    return async ( dispatch ) => {
        
        await DeletePageFielddApi( field_id )
            .then( response => {
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const createTournamentFields = ( fields_array, tournament_id ) => {

    return ( dispatch ) => {
        
        PostCreateTournamentFieldsdApi( fields_array, tournament_id )
            .then( response => {
                dispatch( loadTournamentFieldsData( tournament_id ) );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadTournamentFieldsData = ( tournament_id ) => {

    return async ( dispatch ) => {
        
       await GetTournamentFieldsdApi( tournament_id )
            .then( response => {
                dispatch( loadTournamentFieldsDataAction( response.data ) );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const deleteTournamentField = ( tournament_field_id, tournament_id, fixture ) => {

    return ( dispatch ) => {
        
        DeleteTournamentFieldsdApi( tournament_field_id )
            .then( response => {    
                dispatch( loadTournamentFieldsData(tournament_id) );
                if(fixture){
                    dispatch( loadFullFixtureConfig(tournament_id) );
                }
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadAvailableTournamentFieldsData = ( page_name, tournament_id ) => {

    return async ( dispatch ) => {
        
        await GetPageToTournamentFieldsdApi( page_name, tournament_id )
            .then( response => {
                dispatch(loadTournamentAvailableFieldsDataAction(response.data));
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const setTournamentFixture  = (tournament_id, fixture_config_data, tournament_phase_id ) => {
   
    return async ( dispatch ) => {
        await SetTournamentFixtureApi( tournament_id, fixture_config_data )
            .then( async response => {
                //dispatch( setActivePhaseFixtureAction(response) ); 
                await dispatch( generateMatchesFixture(tournament_phase_id, false) )
            })
            .catch(err => {        
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
        })
    }
}

export const generateMatchesFixture  = (tournament_phase_id, fixture_shuffle ) => {
   
    return async ( dispatch ) => {
        await GenerateFixtureMatchesApi( tournament_phase_id, fixture_shuffle )
            .then( async response => {
                dispatch( setActivePhaseFixtureAction(response) );
                if(response.data?.calendar[0]?.groups?.length > 0){
                    await dispatch( loadTournamentPhaseGroupDates(response.data?.calendar[0]?.groups[0].id) ); 
                }
                
            })
            .catch(err => {        
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
        })
    }
}

export const setTournamentFixtureAvailableDays  = (tournament_id, tournament_days ) => {
   
    return async ( dispatch ) => {
        await SetFixtureAvailableDaysApi( tournament_id, tournament_days )
            .then( response => {                
                //dispatch( setFixtureAvailableDaysAction(response.data) );
                dispatch( loadFullFixtureConfig( tournament_id, true ) );
            })
            .catch(err => {        
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
        })
    }
}

export const deleteFixtureAvailableDay = ( tournament_day_id, tournament_id ) => {

    return ( dispatch ) => {        
        DeleteFixtureAvailableDayApi( tournament_day_id )
            .then( response => {
                //dispatch( loadAvailableFixtureDays(tournament_id) );
                dispatch( loadFullFixtureConfig( tournament_id ) );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadAvailableFixtureDays = ( tournament_id ) => {

    return ( dispatch ) => {
        
        GetAvailableFixtureDaysApi( tournament_id )
            .then( response => {
                dispatch( setFixtureAvailableDaysAction(response.data) );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadGroupedAvailableFixtureDays = ( tournament_id ) => {

    return async ( dispatch ) => {
        
        await GetGroupedAvailableFixtureDaysApi( tournament_id )
            .then( response => {
                dispatch( setGroupedFixtureAvailableDaysAction(response.data) );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const setTournamentFixtureLockedDays  = (tournament_id, tournament_locked_date ) => {
   
    return async ( dispatch ) => {
        await SetFixtureLockedDaysApi( tournament_id, tournament_locked_date )
            .then( response => {                
                //dispatch( setFixtureLockedDaysAction(response.data) );
                dispatch( loadFullFixtureConfig( tournament_id, true ) );
            })
            .catch(err => {        
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
        })
    }
}

export const deleteFixtureLockedDay = ( tournament_non_playable_date_id, tournament_id ) => {

    return ( dispatch ) => {        
        DeleteFixtureLockedDayApi( tournament_non_playable_date_id )
            .then( response => {
                //dispatch( loadLockedFixtureDays(tournament_id) );
                dispatch( loadFullFixtureConfig( tournament_id ) );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

/* export const loadLockedFixtureDays = ( tournament_id ) => {

    return ( dispatch ) => {
        
        GetFixtureLockedDaysApi( tournament_id )
            .then( response => {
                dispatch( setFixtureLockedDaysAction(response.data) );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
} */

export const loadTournamentMatchesFilterData = ( tournament_date_id, params ) => {
    return async ( dispatch ) => {
        
       await GetTournamentMatchesFilterApi( tournament_date_id, params )
            .then( response => {                 
                dispatch( loadDateMatchesAction( response ) );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const newTournamentmatch = ( tournament_date_id, form_data, phase  ) => {

    return ( dispatch ) => {
        
        PostNewTournamentMatchApi( tournament_date_id, form_data )
            .then( response => {
                dispatch( finishedMatchesByPhase(phase)  );  
                dispatch( loadDateMatches(tournament_date_id) );  
                dispatch(changeErrorMessageSnackBar( {
                    message: i18n.t('league:snackBarMessages.createdMatch'),                    
                    error_message_is_open: true
                    } )
                );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadFixtureFieldTeams  = (tournament_field_id ) => {
   
    return async ( dispatch ) => {
        await GetFixtureFieldTeamsApi( tournament_field_id )
            .then( response => {                
                dispatch( setFixtureFieldTeamsAction(response.data) );
            })
            .catch(err => {        
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
        })
    }
}

export const addFixtureTeamsToField = (tournament_field_id, field_teams, tournament_id) => {
   
    return ( dispatch ) => {
        AddFixtureTeamsToFieldApi( tournament_field_id, field_teams )
            .then( response => {                
                dispatch( loadFullFixtureConfig( tournament_id, true ) );
            })
            .catch(err => {        
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
        })
    }
}

export const loadFullFixtureConfig = ( tournament_id, eval_create ) => {
   
    return async ( dispatch ) => {
        await GetFullFixtureConfigApi( tournament_id )
            .then( response => {
                if(eval_create && !response.data.assign_days && !response.data.assign_fields && !response.data.match_duration && !response.data.number_matches && !response.data.start_date && !response.data.time_between_games){
                    SetTournamentFixtureApi( tournament_id, { assign_days: false, assign_fields: false, match_duration: null, number_matches: 1, start_date: null, time_between_games: null} )
                        .then( response => {
                            dispatch( loadFullFixtureConfigAction(response.data) );
                        }
                        );
                }else{
                    dispatch( loadFullFixtureConfigAction(response.data) );
                }            
                
            })
            .catch(err => {        
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
        })
    }
}

export const deleteFixtureFieldTeam = ( tournament_field_team_id, tournament_id ) => {

    return ( dispatch ) => {        
        DeleteFixtureFieldTeamApi( tournament_field_team_id )
            .then( response => {
                dispatch( loadFullFixtureConfig(tournament_id) );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const setTournamentFieldFixtureAvailableDays  = (tournament_field_id, field_tournament_days, tournament_id ) => {
   
    return async ( dispatch ) => {
        await SetFixtureFieldAvailableDaysApi( tournament_field_id, field_tournament_days )
            .then( response => {                
                //dispatch( setFixtureFieldAvailableDaysAction(response.data) );
                dispatch( loadFullFixtureConfig( tournament_id, true ) );
            })
            .catch(err => {        
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
        })
    }
}

export const deleteFixtureFieldAvailableDay = ( tournament_field_day_id, tournament_id ) => {

    return ( dispatch ) => {        
        DeleteFixtureFieldAvailableDayApi( tournament_field_day_id )
            .then( response => {
                //dispatch( loadAvailableFieldFixtureDays(tournament_field_id) );
                dispatch( loadFullFixtureConfig( tournament_id ) );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

//No se utiliza ya que la data por el componente utilizado se saca del resumen del fixture
/* export const loadAvailableFieldFixtureDays = ( tournament_field_id ) => { 

    return ( dispatch ) => {
        
        GetFieldAvailableFixtureDaysApi( tournament_field_id )
            .then( response => {
                dispatch( setFixtureFieldAvailableDaysAction(response.data) );
                })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
*/

export const loadTournamentPhaseGroupDatesFilterData = ( tournament_group_id, params ) => {

    return async ( dispatch ) => {
        
        await GetTournamentPhaseGroupDatesFilterApi( tournament_group_id, params )
            .then( response => { 
                dispatch( loadTournamentPhaseGroupDatesFilterAction( response ) );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadGroupedAvailableFieldFixtureDays = ( tournament_field_id ) => { 

    return ( dispatch ) => {
        
        GetGroupedFieldAvailableFixtureDaysApi( tournament_field_id )
            .then( response => {
                dispatch( setGroupedFixtureFieldAvailableDaysAction(response.data) );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const setTournamentFixtureFieldLockedDays  = (tournament_field_id, tournament_field_locked_date, tournament_id) => {
   
    return async ( dispatch ) => {
        await SetFixtureFieldLockedDaysApi( tournament_field_id, tournament_field_locked_date )
            .then( response => {                
                dispatch( loadFullFixtureConfig(tournament_id, true) );
            })
            .catch(err => {        
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
        })
    }
}

export const deleteFixtureFieldLockedDay = ( tournament_field_day_id, tournament_id ) => {

    return ( dispatch ) => {        
        DeleteFixtureFieldLockedDayApi( tournament_field_day_id )
            .then( response => {
                dispatch( loadFullFixtureConfig(tournament_id) );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const editFixtureMatch = ( id_match, match, tournament_phase_id ) => {

    return ( dispatch ) => {
        
        EditMatchApi( id_match, match )
            .then( async response => {
                await dispatch( reloadFixturPhaseCalendar(tournament_phase_id) )
                dispatch(changeErrorMessageSnackBar( {                    
                    message: i18n.t('league:snackBarMessages.editedMatch'),                    
                    error_message_is_open: true
                    } )
                );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const reloadFixturPhaseCalendar =( tournament_phase_id ) => {
    return async ( dispatch ) => {  

       await GetPhaseCalendarApi( tournament_phase_id )
            .then( response => { 
                dispatch( setActivePhaseFixtureAction( response ) );              
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const deleteFixture = ( tournament_phase_id, tournament_group_id, date_name ) => {

    return ( dispatch ) => {        
        DeleteFixtureApi( tournament_phase_id )
            .then( response => {
                dispatch( loadFullFixtureConfigAction({}) );
                dispatch( addDateToTournamentGroup ( tournament_group_id, date_name ) ); //Se crea una nueva jornada en el primer grupo
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const searchTournamentTeamsAdmin = ( tournament_id, tournament_team_name, state, page_size, next_url ) => {

    return ( dispatch ) => {
        dispatch( changeLoadingTournamentTeamsStateAction( true ) );
        SearchTournamentTeamsAdminApi( tournament_id, tournament_team_name, state, page_size, next_url )
            .then( response => {
                dispatch( loadTournamentTeamsAction( response ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            }).then( () => {
                dispatch( changeLoadingTournamentTeamsStateAction( false ) );
            } );
    }
}

export const loadTournamentScorersTable = ( tournament_id, page_size, next_page ) => {

    return async ( dispatch ) => {
        dispatch( loadingTournamentScorersTableStateAction(true))
        await GetTournamentScorersTableApi( tournament_id, page_size, next_page )
            .then( response => {             
                dispatch( loadTournamentScorersTableAction( response.data ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
                //console.log('error GetTournamentScorersTableApi',err);
            })
            .then( () => {             
                dispatch( loadingTournamentScorersTableStateAction( false ) );                
            })
    }
}

export const loadYellowCardsTable = ( tournament_id, page_size, next_page ) => {

    return async ( dispatch ) => {
        dispatch( loadingYellowCardsTableStateAction(true))
        await GetTournamentYellowCardsTableApi( tournament_id, page_size, next_page )
            .then( response => {             
                dispatch( loadYellowCardsTableAction( response.data ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
                //console.log('error GetTournamentYellowCardsTableApi',err);
            })
            .then( () => {             
                dispatch( loadingYellowCardsTableStateAction( false ) );                
            })
    }
}

export const loadRedCardsTable = ( tournament_id, page_size, next_page ) => {

    return async ( dispatch ) => {
        dispatch( loadingRedCardsTableStateAction(true))
        await GetTournamentRedCardsTableApi( tournament_id, page_size, next_page )
            .then( response => {             
                dispatch( loadRedCardsTableAction( response.data ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
                //console.log('error GetTournamentRedCardsTableApi',err);
            })
            .then( () => {             
                dispatch( loadingRedCardsTableStateAction( false ) );                
            })
    }
}

export const loadBlueCardsTable = ( tournament_id, page_size, next_page ) => {

    return async ( dispatch ) => {
        dispatch( loadingBlueCardsTableStateAction(true))
        await GetTournamentBlueCardsTableApi( tournament_id, page_size, next_page )
            .then( response => {             
                dispatch( loadBlueCardsTableAction( response.data ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
                //console.log('error GetTournamentBlueCardsTableApi',err);
            })
            .then( () => {             
                dispatch( loadingBlueCardsTableStateAction( false ) );                
            })
    }
}

export const loadAssistsTable = ( tournament_id, page_size, next_page ) => {
    return async ( dispatch ) => {
        dispatch( loadingAssistsTableStateAction(true))
        await GetTournamentAssistsTableApi( tournament_id, page_size, next_page )
            .then( response => {             
                dispatch( loadAssistsTableAction( response.data ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
                //console.log('error GetTournamentAssistsTableApi',err);
            })
            .then( () => {             
                dispatch( loadingAssistsTableStateAction( false ) );                
            })
    }
    
}

export const loadLeastDefeatedFenceTable = ( tournament_id, page_size, next_page ) => {
    return async ( dispatch ) => {
        dispatch( loadingLeastDefeatedFenceTableStateAction(true))
        await GetTournamentLeastDefeatedFenceTableApi( tournament_id, page_size, next_page )
            .then( response => {             
                dispatch( loadLeastDefeatedFenceTableAction( response.data ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
                //console.log('error GetTournamentLeastDefeatedFenceTableApi',err);
            })
            .then( () => {             
                dispatch( loadingLeastDefeatedFenceTableStateAction( false ) );                
            })
    }
}

export const loadTeamsMostGoalsTable = ( tournament_id, page_size, next_page ) => {
    return async ( dispatch ) => {
        dispatch( loadingTeamsMostGoalsTableStateAction(true))
        await GetTournamentTeamsMostGoalsTableApi( tournament_id, page_size, next_page )
            .then( response => {             
                dispatch( loadTeamsMostGoalsTableAction( response.data ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
                //console.log('error GetTournamentTeamsMostGoalsTableApi',err);
            })
            .then( () => {             
                dispatch( loadingTeamsMostGoalsTableStateAction( false ) );                
            })
    }
}

export const loadFairPlayTable = ( tournament_id, page_size, next_page ) => {
    return async ( dispatch ) => {
        dispatch( loadingFairPlayTableStateAction(true))
        await GetTournamentFairPlayTableApi( tournament_id, page_size, next_page )
            .then( response => {             
                dispatch( loadFairPlayTableAction( response.data ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
                //console.log('error GetTournamentLeastDefeatedFenceTableApi',err);
            })
            .then( () => {             
                dispatch( loadingFairPlayTableStateAction( false ) );                
            })
    }
}

export const loadSummaryTable = ( tournament_id ) => {
    return async ( dispatch ) => {
        dispatch( loadingSummaryTableStateAction(true))
        await GetTournamentSummaryTableApi( tournament_id )
            .then( response => {             
                dispatch( loadSummaryTableAction( response.data ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
                //console.log('error GetTournamentSummaryTableApi',err);
            })
            .then( () => {             
                dispatch( loadingSummaryTableStateAction( false ) );                
            })
    }
}

export const createFairPlaySettings = ( page_pagename, settings ) => {
    return async ( dispatch ) => {
        dispatch( loadingCreateFairPlaySettingsStateAction(true))
        await CreateFairPlaySettingsApi( page_pagename ,settings )
            .then( response => {             
                dispatch( setSelectedFairPlaySettingAction( response.data ) );   
                dispatch( loadFairPlaySettings( page_pagename, 6 ) );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
                //console.log('error GetTournamentSummaryTableApi',err);
            })
            .then( () => {             
                dispatch( loadingCreateFairPlaySettingsStateAction( false ) );                
            })
    }
}

export const loadFairPlaySettings = ( page_pagename, page_size, next_page ) => {
    return async ( dispatch ) => {

        await LoadFairPlaySettingsApi( page_pagename, page_size, next_page )
            .then( response => {             
                dispatch( loadFairPlaySettingsAction( response.data ) );
                dispatch( setDefaultFairPlaySettingAction( response.data.results.find(res => res.default_active_configuration === true) ) ); 
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
                //console.log('error loadFairPlaySettings',err);
            })
    }
}

export const loadPhaseFairPlaySetting = ( fair_play_id ) => {
    return async ( dispatch ) => {

        await GetFairPlaySettingApi( fair_play_id )
            .then( response => {             
                dispatch( setSelectedFairPlaySettingAction( response.data ) ); 
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
                //console.log('error loadPhaseFairPlaySetting',err);
            })
    }
}

export const loadPhaseSanctions = ( tournament_phase_id, page_size, sanction_type, next_page ) => {
    return async ( dispatch ) => {
        dispatch( loadingPhaseSanctionsStateAction(true))
        await LoadPhaseSanctionsApi( tournament_phase_id, page_size, sanction_type, next_page )
            .then( response => {             
                dispatch( loadPhaseSanctionsAction( response.data ) ); 
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
                //console.log('error loadPhaseFairPlaySetting',err);
            })
            .then( () => {             
                dispatch( loadingPhaseSanctionsStateAction( false ) );                
            })
    }
}

export const createPhaseExtraPenalty  = ( tournament_phase_id, sanction, penalty_type ) => {
    return async ( dispatch ) => {

        await CreatePhaseExtraPenaltyApi( tournament_phase_id, sanction )
            .then( response => {             
                dispatch( loadPhaseSanctions( tournament_phase_id, 6, penalty_type ) );
                dispatch( changeUserMessageSnackBar( {
                    message: i18n.t('league:snackBarMessages.sanctionCreated'),
                    user_message_is_open: true
                } ) )
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
                //console.log('error loadPhaseFairPlaySetting',err);
            })
    }
}

export const loadPhaseMatchesByTeam = ( tournament_phase_id, tournament_team_id, next_page ) => {
    return async ( dispatch ) => {

        await GetPhaseMatchesByTeamApi( tournament_phase_id, tournament_team_id, next_page )
            .then( response => {             
                dispatch( loadPhaseMatchesByTeamAction( response.data ) ); 
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
                //console.log('error loadPhaseFairPlaySetting',err);
            })
    }
}

export const updatePhaseExtraPenalty = ( tournament_phase_id, extra_sanction_id, sanction ) => {

    return async ( dispatch ) => {
        
        await UpdatePhaseExtraPenaltyApi( tournament_phase_id, extra_sanction_id, sanction )
            .then( response => {
                dispatch( updatePhaseExtraPenaltyAction( response.data ) );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const deletePhaseExtraPenalty = ( tournament_phase_id, extra_sanction_id ) => {

    return ( dispatch ) => {        
        DeletePhaseExtraPenaltyApi( tournament_phase_id, extra_sanction_id )
            .then( response => {
                dispatch( deletePhaseExtraPenaltyAction(extra_sanction_id) );
                dispatch( changeUserMessageSnackBar( {
                    message: i18n.t('league:snackBarMessages.sanctionDeleted'),
                    user_message_is_open: true
                } ) )
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadAllTeamPlayers = ( team_id, state ) => {//Se crea para exclusivamante para el selecctor de jugador en el modal de agregar sanciones
    return ( dispatch ) => {
        GetAllTeamPlayersApi( team_id, state )
            .then( response => {                
                dispatch( loadTournamentTeamPlayersAction(response) );//Se mantiene la respuesta en el mismo objeto del estado de tournament_team_players
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            }).then( () => {
                dispatch(loadFullResponseTeamMemberAction(false));
            } );
    }
}

export const reOpenPhase = ( tournament_phase_id ) => {

    return async ( dispatch ) => {
        
        await ReOpenPhaseApi( tournament_phase_id )
            .then( response => {
                dispatch( setTournamentActivePhase( tournament_phase_id ) );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}
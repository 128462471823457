// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.baloa-pro-modal .modal__container {
    padding: 0;
}

.modal__container .baloa-pro-modal__container {
    width: 440px;
    padding: 55px 24px 24px 24px;
}

.baloa-pro-modal__title {
    color: var(--primary-very-dark-color);
    text-align: center;
    font-family: "Lexend Deca";
    font-size: 20px;
    font-weight: var(--font-medium);
}

.baloa-pro-modal__message {
    color: var(--primary-very-dark-color);
    text-align: center;
    font-family: "Lexend Deca";
    font-size: 17px;
    font-weight: var(--font-regular);
    margin-top: 12px;
}

.baloa-pro-modal__container .button {
    width: 100%;
    padding: 12px;
    font-family: "Lexend Deca";
    font-size: 18px;
}

.baloa-pro-modal__container .button.primary-button {
    margin-top: 24px;
}

.baloa-pro-modal__container .button.text-button {
    margin-top: 4px;
    color: var(--primary-very-dark-color);
}

@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */

    .modal__container .baloa-pro-modal__container {
        width: unset;
        padding: 14px;
    }

    .baloa-pro-modal__title {
        font-size: 18px;
    }

    .baloa-pro-modal__message {
        font-size: 14px;
    }

    .baloa-pro-modal__container .button {
        font-size: 14px;
    }
}`, "",{"version":3,"sources":["webpack://./src/app/authentication/components/baloa-pro-modal/BaloaProModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;;AAEA;IACI,YAAY;IACZ,4BAA4B;AAChC;;AAEA;IACI,qCAAqC;IACrC,kBAAkB;IAClB,0BAA0B;IAC1B,eAAe;IACf,+BAA+B;AACnC;;AAEA;IACI,qCAAqC;IACrC,kBAAkB;IAClB,0BAA0B;IAC1B,eAAe;IACf,gCAAgC;IAChC,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,0BAA0B;IAC1B,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,qCAAqC;AACzC;;AAEA,yCAAyC,4BAA4B;;IAEjE;QACI,YAAY;QACZ,aAAa;IACjB;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".baloa-pro-modal .modal__container {\n    padding: 0;\n}\n\n.modal__container .baloa-pro-modal__container {\n    width: 440px;\n    padding: 55px 24px 24px 24px;\n}\n\n.baloa-pro-modal__title {\n    color: var(--primary-very-dark-color);\n    text-align: center;\n    font-family: \"Lexend Deca\";\n    font-size: 20px;\n    font-weight: var(--font-medium);\n}\n\n.baloa-pro-modal__message {\n    color: var(--primary-very-dark-color);\n    text-align: center;\n    font-family: \"Lexend Deca\";\n    font-size: 17px;\n    font-weight: var(--font-regular);\n    margin-top: 12px;\n}\n\n.baloa-pro-modal__container .button {\n    width: 100%;\n    padding: 12px;\n    font-family: \"Lexend Deca\";\n    font-size: 18px;\n}\n\n.baloa-pro-modal__container .button.primary-button {\n    margin-top: 24px;\n}\n\n.baloa-pro-modal__container .button.text-button {\n    margin-top: 4px;\n    color: var(--primary-very-dark-color);\n}\n\n@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */\n\n    .modal__container .baloa-pro-modal__container {\n        width: unset;\n        padding: 14px;\n    }\n\n    .baloa-pro-modal__title {\n        font-size: 18px;\n    }\n\n    .baloa-pro-modal__message {\n        font-size: 14px;\n    }\n\n    .baloa-pro-modal__container .button {\n        font-size: 14px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

/*eslint eqeqeq: "off"*/
// React
import React, { Component } from 'react'
import { connect } from 'react-redux';
import {Helmet} from "react-helmet";

// Actions
import { deleteAttribute, loadAttributesConstants } from 'app/generalattributes/actions/AttributeActions';
import { loadHomeProfileSuggestions, loadHomePageSuggestions } from 'app/home/actions/HomeActions';
import { changePageNewPageTypeModalStateAction } from 'app/pages/actions/PageActionsCreators';
import { 
    loadProfile,
    loadProfilePosts,
    createOrEditAttributeOnProfile,
    updateProfileData,
    followProfile,
    unfollowProfile,
    loadProfileFollowers,
    loadProfileFollowing,
    followProfileByUsername,
    unfollowProfileByUsername,
    followPageByUsername,
    unfollowPageByUsername,
    inviteProfileToValidateAttribute,
    searchProfileNationality, 
    searchProfileLocations,
    addProfileNationality,
    switchProfileToPage,
    blockProfile,
    addProfileTeamByCode,
    deleteProfileTeam,
    loadProfileTournamentStats,
    loadProfileAchievementsStats,
    profileTournamentStatsPayment,
    loadProfileTournaments,
    loadProfileTeamStats,
    uploadVideoProfile,
    loadAllProfileVideo,
    deleteProfileVideo,
    updateProfileVideo,
    loadProfileMatches,
    updateProfileFeaturedMatchVideo,
    loadProfileAchievements,
    createProfileAchievement,
    updateProfileAchievement,
    loadProfileTrajectories,
    createProfileTrajectory,
    updateProfileTrajectory,
    searchProfileWorldCities,
    loadProfileExternalStats,
    addExternalStat,
    updateExternalStat,
    deleteExternalStat,
    loadMoreProfileVideo,
} from 'app/profiles/actions/ProfileActions';
import { 
    changeProfileUploadPhotoModalStateAction, 
    changeProfileBasicInfoModalStateAction,
    changeProfileNationalityModalStateAction,
    changeProfileDescriptionModalStateAction, 
    changeProfileDataSheetModalStateAction, 
    changeProfileTechnicsPrimaryPositionModalStateAction, 
    changeProfileTechnicsSecondaryPositionModalStateAction, 
    changeProfileTechnicsMeassuresModalStateAction,
    changeProfileTechnicsLateralityModalStateAction,
    changeProfileExperiencesModalStateAction,
    changeProfileSpecialtiesModalStateAction,
    changeProfileAptitudesModalStateAction,
    changeProfileAptitudesAdminModalStateAction,
    loadProfileErrorsAction, 
    changeProfileFollowersModalStateAction,
    editProfileExperiencesAction,
    deleteProfileExperiencesAction,
    changeInviteToValidateAptitudeModalStateAction,
    changeProfileTeamsModalStateAction,
    changeProfileBasicInformationModalStateAction,
    changeProfileRoleModalStateAction,
    changeProfileTournamentsTeamsSummaryModalStateAction,
    clearProfileStatsAction,
    changeProfileStatisticsPaymentModalStateAction,
    changeProfileStatisticsPaymentResumeModalStateAction,
    clearProfileTournamentsAction,
    resetProfilePostAction,
    setProfileSelectedPostAction,
    changeProfilePostFormationModalStateAction,
    changeProfilePollQuizPostModalStateAction,
    changeProfilePostCommentModalStateAction,
    changeProfilePostMediaModalStateAction,
    changeProfileTournamentsMatchesModalStateAction,
    changeProfilePostVideoClipModalStateAction,
    addProfileVideoClipAction,
    changeProfileChooseMatchClipModalStateAction,
    changeShareProfileLinkModalStateAction,
    changeProfileExternalStatsModalStateAction,
    changeProfileMoreExternalStatsModalStateAction,
    changeEditExternalStatsInfoModalAction,
    changeDeleteExternalStatsInfoModalAction,
    changeProfileFeaturedClipsViewMoreModalStateAction,
    changeNoProUploadMainVideoModalStateAction,
    changeNoProUploadFeaturedVideosModalStateAction,
    changeNoProAddExternalStatsModalStateAction,
    changeVerifiedExternalStatsInsigniaModalStateAction,
} from 'app/profiles/actions/ProfileActionsCreators';
import { followPage, loadPage } from 'app/pages/actions/PageActions';
import { clapPost, 
    deletePost, 
    loadPostClapsById, 
    commentPostById,
    reportPostById,
    sendReaction, 
    overrideReaction, 
    loadPostReactionsById,
    loadPostReactionsNext,
    loadPostReactionsByReactionType,
    deleteReaction,
    followPostProfile,
    unfollowPostProfile,
    followPostPage,
    unfollowPostPage,
    answerQuiz,
    answerPoll,
    uploadPost,
} from 'app/posts/actions/PostActions';
import { changeHomeEditPostModal, 
    changeErrorMessageSnackBar,
    changePageSuggestionsModalStateAction,
    changeProfileSuggestionsModalStateAction, 
} from 'app/home/actions/HomeActionsCreators';
import { validateAttribute } from 'app/generalattributes/actions/AttributeActions';

import { 
    changePostClapsModalStateAction, 
    changeReportPostModalStateAction,
    changeBlockPostModalStateAction,
    changeAcceptReportPostModalStateAction,
    changePostReactionsModalStateAction,
    changeQuotePostModalStateAction,
    postToQuoteAction,
    changePostIsFollowingStateAction,
    changePostIsFollowingStateFromReactionTypeAction,
 } from 'app/posts/actions/PostsActionsCreators';

import { addEventAction } from 'app/clickstream/actions/ClickstreamActionsCreators';



// Material UI
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Skeleton from '@mui/material/Skeleton';

// Styles
import './ProfileView.styles.css';

// Components
import ProfileFeedComponent from 'app/profiles/components/profile-feed/ProfileFeedComponent';
import ProfileInformationComponent from 'app/profiles/components/profile-information/ProfileInformationComponent';
//import ProfileCompletionComponent from 'app/profiles/components/profile-completion/ProfileCompletionComponent';
import ProfileBasicInfoComponent from 'app/profiles/components/profile-basic-info/ProfileBasicInfoComponent';
import ProfileTechnicsComponent from 'app/profiles/components/profile-technics/ProfileTechnicsComponent';
// import ProfileSkillsComponent from 'app/profiles/components/profile-skills/ProfileSkillsComponent';
import ProfileExperiencesComponent from 'app/profiles/components/profile-experiences/ProfileExperiencesComponent';
// import ProfileSpecialtiesComponent from 'app/profiles/components/profile-specialties/ProfileSpecialtiesComponent';
import ProfileAptitudesComponent from 'app/profiles/components/profile-aptitudes/ProfileAptitudesComponent';
// import MyPagesComponent from 'app/profiles/components/profile-pages/MyPagesComponent';
//import ProfileInterestsComponent from '../components/profile-interests/ProfileInterestsComponent';
// import SeparatorComponent from 'shared/components/separator/SeparatorComponent';
import ProfileSuggestionsComponent from 'app/profiles/components/profile-suggestions/ProfileSuggestionsComponent';
import PageSuggestionsComponent from 'app/pages/components/page-suggestions/PageSuggestionsComponent';
// import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
// import ProfileTeamsComponent from '../components/profile-teams/ProfileTeamsComponent';
import ProfileRoleComponent from '../components/profile-role/ProfileRoleComponent';
import ProfileHistoricalSummaryComponent from '../components/profile-historical-summary/ProfileHistoricalSummaryComponent';
import ProfileTournamentsTeamsSummaryComponent from '../components/profile-tournaments-teams-summary/ProfileTournamentsTeamsSummaryComponent';
import ProfileTournamentsMatchesComponent from 'app/profiles/components/profile-tournaments-matches/ProfileTournamentsMatchesComponent';
import ProfileFeaturedClips from '../components/profile-featured-clips/ProfileFeaturedClips';
import BaloaProSubscriptionWidgetComponent from 'shared/components/baloa-pro-subscription-widget/BaloaProSubscriptionWidgetComponent';

// Modals
import ProfileUploadPhotoModalComponent from 'app/profiles/components/profile-upload-photo-modal/ProfileUploadPhotoModalComponent';
import ProfileDescriptionModalComponent from 'app/profiles/components/profile-description-modal/ProfileDescriptionModalComponent';
import ProfileBasicInfoModalComponent from 'app/profiles/components/profile-basic-info-modal/ProfileBasicInfoModalComponent';
import ProfileNationalityModalComponent from 'app/profiles/components/profile-nationality-modal/ProfileNationalityModalComponent';
import ProfileTechnicsPrimaryPositionModalComponent from 'app/profiles/components/profile-technics-primary-position-modal/ProfileTechnicsPrimaryPositionModalComponent';
import ProfileTechnicsSecondaryPositionModalComponent from 'app/profiles/components/profile-technics-secondary-position-modal/ProfileTechnicsSecondaryPositionModalComponent';
// import ProfileTechnicsMeassuresModalComponent from 'app/profiles/components/profile-technics-meassures-modal/ProfileTechnicsMeassuresModalComponent';
import ProfileTechnicsLateralityModalComponent from 'app/profiles/components/profile-technics-laterality-modal/ProfileTechnicsLateralityModalComponent';
import ProfileExperiencesModalComponent from 'app/profiles/components/profile-experiences-modal/ProfileExperiencesModalComponent';
import ProfileSpecialtiesModalComponent from 'app/profiles/components/profile-specialties-modal/ProfileSpecialtiesModalComponent';
import ProfileAptitudesModalComponent from 'app/profiles/components/profile-aptitudes-modal/ProfileAptitudesModalComponent';
//import ProfileAptitudesAdminModalComponent from 'app/profiles/components/profile-aptitudes-admin-modal/ProfileAptitudesAdminModalComponent';
import ProfileFollowersModalComponent from 'app/profiles/components/profile-followers-modal/ProfileFollowersModalComponent';
//import ProfileInviteToValidateAptitudeModalComponent from 'app/profiles/components/profile-invite-to-validate-aptitude-modal/ProfileInviteToValidateAptitudeModalComponent';
import ReportModalComponent from 'app/posts/components/report-modal/ReportModalComponent';
import BlockModalComponent from 'app/posts/components/block-modal/BlockModalComponent';
import AcceptReportModalComponent from 'app/posts/components/acceptance-modal/AcceptReportModalComponent';
import ReactionsModalComponent from 'app/posts/components/reactions-modal/ReactionsModalComponent';
//import ProfileDataSheetModalComponent from '../components/profile-data-sheet/ProfileDataSheetModalComponent';
import ProfileSuggestionsModalComponent from '../components/profile-suggestions-modal/ProfileSuggestionsModalComponent';
import PageSuggestionsModalComponent from 'app/pages/components/page-suggestions-modal/PageSuggestionsModalComponent';
import QuoteModalComponent from 'app/posts/components/quote-modal/QuoteModalComponent';
import ProfileTeamsModalComponent from "app/profiles/components/profile-teams-modal/ProfileTeamsModalComponent";
import ProfileRoleModalComponent from '../components/profile-role-modal/ProfileRoleModalComponent';
import ProfileBasicInformationModalComponent from '../components/profile-basic-information-modal/ProfileBasicInformationModalComponent';
import ProfileTournamentVictoriesComponent from '../components/profile-tournament-victories/ProfileTournamentVictoriesComponent';
import ProfileTournamentsTeamsSummaryModalComponent from '../components/profile-tournaments-teams-summary-modal/ProfileTournamentsTeamsSummaryModalComponent';
import ProfileStatisticsPaymentModalComponent from '../components/profile-statistics-payment-modal/ProfileStatisticsPaymentModalComponent';
import ProfileStatisticsPaymentResumeModalComponent from '../components/profile-statistics-payment-resume-modal/ProfileStatisticsPaymentResumeModalComponent';
import ProfilePostFormationModalComponent from 'app/profiles/components/profile-post-formation-modal/ProfilePostFormationModalComponent';
import ProfilePostQuizPollModalComponent from 'app/profiles/components/profile-post-quiz-poll-modal/ProfilePostQuizPollModalComponent';
import ProfilePostCommentModalComponent from 'app/profiles/components/profile-post-comment-modal/ProfilePostCommentModalComponent';
import ProfilePostMediaModalComponent from 'app/profiles/components/profile-post-media-modal/ProfilePostMediaModalComponent';
import ProfileTournamentsMatchesModalComponent from '../components/profile-tournaments-matches-modal/ProfileTournamentsMatchesModalComponent';
import ProfileAddVideoModalComponent from '../components/profile-add-video-modal/ProfileAddVideoModalComponent';
import ProfilePostVideoClipsComponent from '../components/profile-post-video-clips/ProfilePostVideoClipsComponent';
import ProfilePostVideoClipsOptionsModalComponent from '../components/profile-post-video-clips-options-modal/ProfilePostVideoClipsOptionsModalComponent';
import ProfileChooseMatchClipModalComponent from 'app/profiles/components/profile-choose-match-clip-modal/ProfileChooseMatchClipModalComponent';
import ShareProfileLinkModalComponent from 'app/profiles/components/share-profile-link-modal/ShareProfileLinkModalComponent';
import ProfileExternalStatsComponent from 'app/profiles/components/profile-external-stats/ProfileExternalStatsComponent';
import ProfileExternalStatsModalComponent from 'app/profiles/components/profile-external-stats-modal/ProfileExternalStatsModalComponent';
import ProfileMoreExternalStatsModalComponent from 'app/profiles/components/profile-more-external-stats-modal/ProfileMoreExternalStatsModalComponent';
import ProfileEditExternalStatsInfoModalComponent from 'app/profiles/components/profile-edit-external-stats-info-modal/ProfileEditExternalStatsInfoModalComponent';
import ProfileDeleteExternalStatsInfoModalComponent from 'app/profiles/components/profile-delete-external-stats-info-modal/ProfileDeleteExternalStatsInfoModalComponent';
import ProfileFeaturedClipsViewMoreModalComponent from 'app/profiles/components/profile-featured-clips-view-more-modal/ProfileFeaturedClipsViewMoreModalComponent';
import ProfileNoProUploadMainVideoModalComponent from 'app/profiles/components/profile-no-pro-upload-main-video-modal/ProfileNoProUploadMainVideoModalComponent';
import ProfileNoProUploadFeaturedVideosModalComponent from 'app/profiles/components/profile-no-pro-upload-featured-videos-modal/ProfileNoProUploadFeaturedVideosModalComponent';
import ProfileNoProAddExternalStatsModalComponent from 'app/profiles/components/profile-no-pro-add-external-stats-modal/ProfileNoProAddExternalStatsModalComponent';
import ProfileVerifiedExternalStatsInsigniaModalComponent from '../components/profile-verified-external-stats-insignia-modal/ProfileVerifiedExternalStatsInsigniaModalComponent';

// i18n
import { withTranslation } from 'react-i18next';
// Redux
import {compose} from 'redux';

// Variables
import { web_url } from "config/GlobalData";

// React device detect
import {isMobile} from 'react-device-detect';

function TabPanel( props ) {

    const { children, value, index, ...other } = props;

    if ( props.profile.loading ) {
        return (
            <div
                role="tabpanel"
                hidden={ value !== index }
                id={ `simple-tabpanel-${index}` } 
                aria-labelledby={ `simple-tab-${index}` }
                { ...other }
            >
                <React.Fragment>
                    <Skeleton animation="wave" variant="rect" component="div" height={100} style={{ marginTop: 30 }}/>
                    <Skeleton animation="wave" variant="rect" component="div" height={200} style={{ marginTop: 30 }}/>
                    <Skeleton animation="wave" variant="rect" component="div" height={100} style={{ marginTop: 30 }}/>
                </React.Fragment>
            </div>
            );
    } 
    else {
  
        return (
            <div
                role="tabpanel"
                hidden={ value !== index }
                id={ `simple-tabpanel-${index}` }
                aria-labelledby={ `simple-tab-${index}` }
                { ...other }
            >
                {/* Profile Data */}
                { index === 0 && (
                    <React.Fragment>
                        {props.profile.profile_type === 'player' &&
                            <div className='profile__information-type__btns-container'>
                                <div onClick={() => {props.handleFilterInfo('stats')}} className={`baloa-table-column ${props.information_type === 'stats' ? 'selected' : ''}`}>{props.infoAndStatsTabTitle}</div>
                                <div onClick={() => {props.handleFilterInfo('achievements_trajectory')}} className={`baloa-table-column ${props.information_type === 'achievements_trajectory' ? 'selected' : ''}`}>{props.achievementsAndCareerTabTitle}</div>
                            </div>
                        }       
                        
                        { ((props.profile.profile_type === 'player' && props.information_type === 'stats') || props.profile.profile_type !== 'player') &&
                            <div className='profile__stats-container'>
                                {/* Profile Completion */}
                                {/* { props.profile.is_owner && 
                                    <ProfileCompletionComponent 
                                        percentage={ props.profile.profile_completion | 0 }
                                        className="profile__completion"
                                        is_owner={ props.profile.is_owner } 
                                        //message={t('profileCompletedText')}
                                    /> 
                                } */}
                                {/* Profile Role */}
                                <ProfileRoleComponent 
                                    profile={ props.profile }
                                    is_owner={ props.profile.is_owner } 
                                    onNewButton={ () => {} } 
                                    handleOnEditProfileRole={() => {props.handleOnEditProfileRole()}}                       
                                />
                
                                {/* Profile Basic Information */}
                                <ProfileBasicInfoComponent 
                                    className="profile__basic-info"
                                    profile={ props.profile }
                                    is_owner={ props.profile.is_owner } 
                                    onNewButton={ props.onNewBasicInfoButton }                             
                                />

                                {/* Profile Tournament Historical Summary */}
                                {props.profile.profile_type === 'player' &&
                                    <ProfileHistoricalSummaryComponent 
                                        handleModalUnlockData={props.handleModalUnlockData} 
                                        profile_data={props.profile.summary_historic_player_stats}  
                                        is_owner={props.profile.is_owner}    
                                        username={props.profile.user.username} 
                                        profile_tournament_paid={ props.profile.profile_tournament_paid }                
                                    />
                                }

                                {/* Profile Teams Information */}
                                {/* <ProfileTeamsComponent
                                    className="profile__teams"
                                    profile={props.profile}
                                    is_owner={props.profile.is_owner}
                                    onNewButton={props.onAddNewTeamsButton}
                                /> */}

                                {/* Profile Tournaments Matches */}
                                {props.profile.profile_type === 'player' &&
                                    <ProfileTournamentsMatchesComponent 
                                        profile_matches ={props.profile_matches}
                                        handleOnViewAllMatches={props.handleOnViewAllMatches}
                                        handleProfilePostModal={ props.handleProfilePostModal }
                                        handleModalUnlockData={props.handleModalUnlockData}
                                        handleOnVideoChange={ props.handleOnVideoChange }
                                        setClipMatch={ props.setClipMatch }
                                        has_unbloqued_tournaments={ props.has_unbloqued_tournaments }
                                        has_pending_tournaments={ props.has_pending_tournaments}
                                        is_owner={ props.profile.is_owner }
                                        username={props.profile.user.username} 
                                        handleOnMatch={ props.handleOnMatch }
                                    />
                                }

                                {/* Profile Technics */}
                                { props.profile.profile_type === 'player' && 
                                    <ProfileTechnicsComponent 
                                        className="profile__technics"
                                        profile={ props.profile }
                                        is_owner={ props.profile.is_owner } 
                                        onNewButton={ props.onNewTechnicsButton }
                                        handleOnPrimaryPosition={ props.handleOnPrimaryPosition }
                                        handleOnSecondaryPosition={ props.handleOnSecondaryPosition }
                                        handleOnLaterality={ props.handleOnLaterality }
                                    /> 
                                }

                                {/** Profile external stats */}
                                { props.profile.profile_type === 'player' &&
                                    <ProfileExternalStatsComponent
                                        is_owner={ props.profile.is_owner } 
                                        handleOnAddExternalStats={ props.handleOnAddExternalStats }
                                        externalStats={props.profile_external_stats}
                                        handleOnEditExternalStats={props.handleOnEditExternalStats}
                                        handleOnViewMore={props.handleOnViewMore}
                                        is_baloa_pro={ props.profile.user?.subscription_plan !== 'FREE' }
                                        username={ props.profile.user?.username }
                                        handleOnNoProAddExternalStats={props.handleOnNoProAddExternalStats}
                                        handleOnBaloaProSubscription={ props.handleOnBaloaProSubscription }
                                        handleOnVerifiedExternalStat={props.handleOnVerifiedExternalStat}
                                    />
                                }

                                {/* Profile Tournaments Summary */}
                                {props.profile.profile_type === 'player' &&
                                    <ProfileTournamentsTeamsSummaryComponent 
                                        summary_type='tournaments_summary'
                                        handleModalUnlockData={props.handleModalUnlockData}
                                        handleOnSummaryViewAll={props.handleOnSummaryViewAll}
                                        table_data={props.profile?.tournament_player_stats}
                                        is_owner={props.profile?.is_owner}    
                                        username={props.profile.user?.username}
                                        profile_tournament_paid={props.profile_tournament_paid}
                                    />
                                }

                                {/* Profile Teams Summary */}
                                {props.profile.profile_type === 'player' &&
                                    <ProfileTournamentsTeamsSummaryComponent 
                                        summary_type='teams_summary'
                                        handleModalUnlockData={props.handleModalUnlockData}
                                        handleOnSummaryViewAll={props.handleOnSummaryViewAll}
                                        table_data={props.profile?.team_player_stats}
                                        is_owner={props.profile?.is_owner}    
                                        username={props.profile.user?.username} 
                                        profile_tournament_paid={props.profile_tournament_paid}
                                    />
                                }

                                {/* Profile Skills */}
                                {/* {props.profile.is_owner && 
                                    <ProfileSkillsComponent 
                                        className="profile__skills"
                                        profile={ props.profile }
                                        is_owner={ props.profile.is_owner } 
                                    /> 
                                } */}

                                {/* Profile Specialties */}
                                {/* { (props.profile.is_owner || props.profile.attributes.specialty.length > 0 ) && 
                                    <ProfileSpecialtiesComponent 
                                        className="profile__specialties"
                                        profile={ props.profile }
                                        is_owner={ props.profile.is_owner } 
                                        onNewButton={ props.onNewSpecialtiesButton }

                                    /> 
                                } */}                                                                

                                {/* Profile Tournament Victories (Palmarés) */}
                                { props.profile.profile_type === 'player' &&
                                    <ProfileTournamentVictoriesComponent 
                                        handleModalUnlockData={props.handleModalUnlockData}
                                        summary_type={'victories_summary'}
                                        handleOnSummaryViewAll={props.handleOnSummaryViewAll} 
                                        tournaments={props.profile?.tournament_player_achievements}
                                        is_owner={props.profile?.is_owner}    
                                        username={props.profile.user?.username}
                                        profile_tournament_paid={props.profile_tournament_paid}
                                    />
                                }                                

                                {/* Profile Aptitudes */}
                                {props.profile.profile_type === 'player' &&
                                    <ProfileAptitudesComponent 
                                        className="profile__aptitudes"
                                        profile={ props.profile }
                                        is_owner={ props.profile.is_owner } 
                                        onNewButton={ props.onNewAptitudesButton }
                                        onValidateAptitude={ props.onValidateAptitude }
                                        onInviteToValidateAptitude={ props.onInviteToValidateAptitude }
                                    /> 
                                }
                            </div>
                        }
                        { ((props.profile.profile_type === 'player' && props.information_type === 'achievements_trajectory') || props.profile.profile_type !== 'player')  &&
                            <div className='profile__achievements-container'>
                                {/* Profile profesional Experiences */}
                                {props.profile.profile_type === 'professional' && 
                                    <ProfileExperiencesComponent 
                                        className="profile__experiences"
                                        profile={ props.profile }
                                        is_owner={ props.profile.is_owner } 
                                        onNewButton={ props.onNewExperiencesButton }
                                        onEditButton={ props.onEditExperiencesButton }
                                        onDeleteButton={ props.onDeleteExperiencesButton } 
                                        experiences_type={'experiences'}                           
                                    /> 
                                }

                                {/* Profile Experiences Achievements */}
                                { props.profile.profile_type !== 'fanatic' &&
                                    <ProfileExperiencesComponent 
                                        className="profile__experiences"
                                        profile={ props.profile }
                                        is_owner={ props.profile.is_owner } 
                                        onNewButton={ props.onNewExperiencesButton }
                                        onEditButton={ props.onEditExperiencesButton }
                                        onDeleteButton={ props.onDeleteExperiencesButton } 
                                        experiences_type={'achievements'}                                                       
                                    /> 
                                }

                                {/* Profile Experiences Trajectories */}
                                {props.profile.profile_type === 'player' &&
                                    <ProfileExperiencesComponent 
                                        className="profile__experiences"
                                        profile={ props.profile }
                                        is_owner={ props.profile.is_owner } 
                                        onNewButton={ props.onNewExperiencesButton }
                                        onEditButton={ props.onEditExperiencesButton }
                                        onDeleteButton={ props.onDeleteExperiencesButton } 
                                        experiences_type={'trajectory'}                           
                                    /> 
                                }

                                {/* Profile Interests */}
                                { /* props.profile.profile_type === 'fanatic' && //TODO: esperando que la funcionalidad este disponible para realizar el modulo
                                    <ProfileInterestsComponent
                                        className="profile__interests"
                                        is_owner={ props.profile.is_owner } 
                                        pages={ props.profile.pages }
                                        handleOnPageClick = { ( page ) => props.handleOnPageClick( page ) }
                                    /> */ 
                                }
                            </div>
                        }
                    </React.Fragment>
                ) }

                {/* Profile Feed */}
                { index === 1 && (
                    
                    <ProfileFeedComponent 

                        posts = { props.posts }
                        profile_suggestions = { [] }
                        me_photo = { props.me_photo }

                        handleOnLoadMorePosts = { props.loadProfilePosts }
                        
                        //handleOnProfileClick = { ( profile ) => props.handleOnProfileClick( profile ) }
                        //handleOnPageClick = { ( page ) => props.handleOnPageClick( page ) }

                        handleClap = { ( post ) => props.handleClap( post ) }
                        onCloseClapsModal={() => { props.onCloseClapsModal( true ) }}
                        loadPostClapsById={(post_id) => props.loadPostClapsById(post_id)}

                        handleOnMessageIconClick = { ( post ) => props.handleOnMessageIconClick( post ) }
                        handleOnCommentsClick = { ( post ) => props.handleOnCommentsClick( post ) }
                        handleOnComment = { ( post, comment ) => props.handleOnComment( post, comment )}

                        openReportPostModal={() => {props.openReportPostModal( true )}}
                        handleReportPost={(post_id) => {props.handleReportPost(post_id)}}

                        openBlockPostModal={() => {props.openBlockPostModal( true )}}
                        handleBlockProfile={(username) => {props.handleBlockProfile(username)}}

                        handleOnDeletePostClick = { ( post ) => props.deletePost( post ) }

                        handleOnCopyLinkClick = {(post) => {props.handleOnCopyLinkClick(post)}}
                        
                        handleOnAuthorClick = { props.handleOnAuthorClick }

                        menu_is_open = {props.menu_is_open}    
                        profile = { props.profile_post }
                        user = { props.user }
                        sendReaction= { props.sendReaction }
                        overrideReaction= { props.overrideReaction }
                        handleReactionsDetails = { props.handleReactionsDetails }
                        loadPostReactionsById = { props.loadPostReactionsById }
                        deleteReaction = { props.deleteReaction }
                        followPostProfile = { props.followPostProfile }                            
                        unfollowPostProfile = { props.unfollowPostProfile }                            
                        followPostPage = { props.followPostPage }                            
                        unfollowPostPage = { props.unfollowPostPage }

                        handleOnClickQuizAnswer={ props.handleOnClickQuizAnswer }
                        handleOnClickPollAnswer={ props.handleOnClickPollAnswer }
                        handleOnQuoteIconClick={props.handleOnQuoteIconClick}
                        handleProfilePostModal={ props.handleProfilePostModal }
                    />
                ) }

            </div>
        );
    }
}


class ProfileView extends Component {

    constructor( props ) {
        super( props );
        this.username = this.props.match.params.username;
        this.state = {
            tab_selected: 0,
            aptitude_id: null,
            share_menu_is_open: false,
            summary_type: '',
            selected_tournament: {},
            selected_video_profile: null,
            optionMenu_video_clip:false,
            type_modal_video_clip:'',
            type_action_modal_video_clip:'',
            share_clip_on_match: undefined,
            clip_match: undefined,
            information_type: 'stats',
            experience_type: '',
            edit_experience: false,
            share_profile_url: `${web_url}/profile/${this.props.match.params.username}`,
            profession_specialties : null,
            selected_stat: undefined,
            edit_stat: false,
        }
    }

    //number_of_showing_aptitudes = 2;
    number_of_showing_pages = 10;
    number_of_showing_profiles = 10;
    
    async componentDidMount(){
        await this.props.resetProfilePostAction({});
        this.props.loadProfile( this.username, this.props.match.params.username );
        this.props.loadProfilePosts( this.username );
        this.props.loadAttributesConstants();
        this.props.loadProfileFollowers( this.username );
        this.props.loadProfileFollowing( this.username );
        this.props.loadHomeProfileSuggestions();
        this.props.loadHomePageSuggestions();
        this.props.loadAllProfileVideo( this.username );
        this.props.loadProfileAchievements( this.props.match.params.username );
        this.props.loadProfileTrajectories( this.props.match.params.username );
        await this.props.loadProfileExternalStats( this.props.match.params.username );


        let payment_confirmation = this.props.location.search.split('?profile_payment=');
        let username_confirmation = this.props.match.params.username;
        if(payment_confirmation.length > 1) {
            this.props.loadProfileTournaments(username_confirmation, this.props.state.profile?.profile_tournaments_next_page);
            this.props.changeProfileStatisticsPaymentModalStateAction( true );
            this.props.history.replace({                            
                search: '',
            });
        }
    }

        
    async componentDidUpdate( prevProps ) {
        if ( this.props.match.params.username !== prevProps.match.params.username ) {
            await this.props.resetProfilePostAction({});
            this.props.loadProfile( this.props.match.params.username );
            this.props.loadProfilePosts( this.props.match.params.username );
            this.props.loadProfileFollowers( this.props.match.params.username );
            this.props.loadProfileFollowing( this.props.match.params.username );
            this.props.loadAllProfileVideo( this.props.match.params.username );
            this.props.loadProfileAchievements( this.props.match.params.username );
            this.props.loadProfileTrajectories( this.props.match.params.username );
            await this.props.loadProfileExternalStats( this.props.match.params.username );
        }
        if( prevProps.state.profile.profile?.user?.username !== this.props.state.profile?.profile?.user?.username ){            
            this.props.addEventAction({Datetime: new Date().toISOString(),EventData: {EventType: 'profile-visit', ProfileId: this.props.state.profile.profile.id }});  //set Clickstream profile-visit event            
        }

        if(this.props.state.signup.professional_types.length > 0 && this.state.profession_specialties === null){
            const specialties =this.props.state.signup.professional_types?.map(specialty =>{
                let tspec = {};
                tspec["content"] = this.props.t('profile:professional_types.'+specialty.caption);
                tspec["value"] = specialty.caption;
                return tspec;
            });
            this.setState({profession_specialties: specialties});
        }
    }

    handleOnProfileClick = ( profile ) => {
        this.props.history.push(`/profile/${profile.user.username}`);
        this.props.changeProfileFollowersModalStateAction( false );
        this.props.loadProfile( profile.user.username );
        this.props.loadProfilePosts( profile.user.username );
        this.props.loadProfileFollowers(profile.user.username);
        this.props.loadProfileFollowing(profile.user.username);
    }

    handleOnPageClick = ( page ) => {
        this.props.history.push(`/page/${page.pagename}`);
    }

    handleOnAuthorClickV2 = ( author ) => {
        this.props.history.push(`/${ author.type_object }/${ author.identifier }`);
        if(author.type_object == "profile"){
            this.props.changeProfileFollowersModalStateAction( false );
            this.props.loadProfile( author.identifier );
            this.props.loadProfilePosts( author.identifier );
            this.props.loadProfileFollowers( author.identifier );
            this.props.loadProfileFollowing( author.identifier );
        }
    }

    handleOnMessageIconClick = ( post ) => {
        this.props.history.push(`/posts/${post.id}`);
    }

    handleOnCommentsClick = ( post ) => {
        this.props.history.push(`/posts/${post.id}`);
    }

    handleOnCopyLinkClick = async (post) => {
        try {
            const url = `${web_url}/api/social/posts/${post.id}/`;
            await navigator.clipboard.writeText(url);
            this.props.showCopyPostMessage(this.props.t('copyLink'));
        } catch (err) {
            this.props.showCopyPostMessage(this.props.t('copyLinkError'));
        }
    };

    handleReportPost = ( post ) => {
        this.setState({post_id: post});
    };

    handleBlockProfile = ( username ) => {
        this.setState({profile_username: username});
    };

    handleQuoteClick = ( post ) => {
        this.props.changeQuotePostModalStateAction( true );
        this.props.postToQuoteAction( post );
    }

    handleOnSummaryViewAll = (summary_type) => {
        this.setState({summary_type: summary_type})
        if(summary_type === 'tournaments_summary') {
            this.props.loadProfileTournamentStats(this.props.state.profile.profile.user.username, this.props.state.profile.profile_stats_next_page);
        }
        if(summary_type === 'victories_summary') {
            this.props.loadProfileAchievementsStats(this.props.state.profile.profile.user.username, this.props.state.profile.profile_stats_next_page);
        }
        if(summary_type === 'teams_summary') {
            this.props.loadProfileTeamStats(this.props.state.profile.profile.user.username, this.props.state.profile.profile_stats_next_page);
        }
        this.props.changeProfileTournamentsTeamsSummaryModalStateAction(true);
    }
    
    handleOnResumePaymentStats = (tournament) => {
        this.setState({selected_tournament: tournament});
        let payment_data = {
            "tournament_id": tournament.tournament_id,
            "redirect": `/profile/${this.props.state.profile.profile.user.username}/?profile_payment=true`,
            "mobile": false,
        }
        this.props.profileTournamentStatsPayment(this.props.state.profile.profile.user.username, payment_data);
        this.props.changeProfileStatisticsPaymentResumeModalStateAction( true );
    }

    handleOnStatsCheckout = async () => {
        if(this.props.state.profile?.profile_payment_checkout.url){
            const newWindow = window.open(this.props.state.profile?.profile_payment_checkout.url, '_self', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        }
        this.props.changeProfileStatisticsPaymentResumeModalStateAction( false );
        this.props.changeProfileStatisticsPaymentModalStateAction( false );
    }

    handleModalUnlockData = () => {
        this.props.loadProfileTournaments(this.props.state.profile.profile.user.username, this.props.state.profile.profile_tournaments_next_page);
        this.props.changeProfileStatisticsPaymentModalStateAction( true );
    }

    handleCloseTournamentsTeamsSummaryModal = () => {
        this.props.clearProfileStatsAction(); 
        this.props.changeProfileTournamentsTeamsSummaryModalStateAction(false);
    }

    handleProfilePostModal=(post, modal_post_type) => {
        this.props.setProfileSelectedPostAction(post);
        switch(modal_post_type){
            case 'Formation':                
                this.props.changeProfilePostFormationModalStateAction(true);
            break;
            case 'Comment':
                this.props.changeProfilePostCommentModalStateAction(true);
            break;
            case 'Poll':
                this.props.changeProfilePollQuizPostModalStateAction(true);
                break;
            case 'Quiz':
                this.props.changeProfilePollQuizPostModalStateAction(true);
                break;
            case 'Media':
                this.props.changeProfilePostMediaModalStateAction(true);
                break;
            case 'presentationVideoClip':
                this.setState({type_modal_video_clip: modal_post_type});
                this.props.changeProfilePostVideoClipModalStateAction(true);
                break;
            case 'featuredVideoClip':
                this.props.changeProfilePostVideoClipModalStateAction(true);
                this.setState({type_modal_video_clip: modal_post_type});
                break;
            case 'matchVideoClip':
                this.props.changeProfilePostVideoClipModalStateAction(true);
                this.setState({type_modal_video_clip: modal_post_type});
                break;
            // no default
        }
    }

    handleOnVideoChange=( videoObject ,modalPostType )=>{
        this.setState({selected_video_profile: videoObject});
        this.setState({type_modal_video_clip: modalPostType});
        this.props.addProfileVideoClipAction(true);
    }

    handleOnPostVideo =(formData, configUpload, loadPost, username)=>{
        this.props.uploadVideoProfile(formData, configUpload, loadPost, username)
    }

    handleVideoClipOptionMenu=()=>{
        this.setState({optionMenu_video_clip: true});
    }

    handleDeleteVideoClip = (postId, username)=>{
        this.props.deleteProfileVideo(postId, username);
        this.setState({optionMenu_video_clip: false});
        this.props.changeProfilePostVideoClipModalStateAction(false);
    }

    handleEditVideoClip = (postId, titleVideo, params, username)=>{
        if(params){
            this.props.updateProfileFeaturedMatchVideo(postId, params, username);
        }else{
            this.props.updateProfileVideo(postId, titleVideo, username);
        }
        
        this.props.addProfileVideoClipAction( false );
        this.setState({optionMenu_video_clip: false,  type_action_modal_video_clip: ''});
        this.props.changeProfilePostVideoClipModalStateAction(true);
    }

    handleOnMatch = (tournament_id,phase_id, match_date_id, match_id) => {
        this.props.history.push(`/tournament/hub/${tournament_id}&phase=${phase_id}&match_date=${match_date_id}&match=${match_id}&type=match&participate=False`);
    }

    handleOnShareProfileLinkClick = async () => {
        try {
            await navigator.clipboard.writeText(this.state.share_profile_url);
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkSuccess'));
        } catch (err) {
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkError'));
        }
    };

    handleOnEditExternalStats( stat ){    
        this.setState({selected_stat: stat, edit_stat: true});
        //this.props.changeEditExternalStatsInfoModalAction(true); //TODO: disable temporaly -> waiting Baloa PRO
        this.props.changeProfileExternalStatsModalStateAction(true);//TODO: open edit stat directly temporaly -> waitig Baloa PRO 
    }

    handleOnDeleteStat(){
        this.props.changeDeleteExternalStatsInfoModalAction(true);
    }

    handleOnBaloaProSubscription = ( ) => {
        this.props.history.push(`/BaloaPro`);
    }

    render() {

        return (

            <React.Fragment>
                {/* Social share headers */}
                <Helmet>
                    <title>{`Baloa :: ${this.props.state.profile?.profile?.user?.first_name} ${this.props.state.profile?.profile?.user?.last_name} (${this.props.state.profile?.profile?.user?.username})`}</title>
                    {/* Facebook/WhatsApp  */}
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content={`${this.props.state.profile?.profile?.user?.first_name} ${this.props.state.profile?.profile?.user?.last_name} (@${this.props.state.profile?.profile?.user?.username})`} />
                    <meta property="og:description" content={`${this.props.state.profile?.profile?.attributes?.description?.value}`} />
                    <meta property="og:image" content={this.props.state.profile?.profile?.user?.photo? this.props.state.profile?.profile?.user?.photo : `${web_url}/logo_baloa_social_share.png`} />
                    <meta property="og:url" content={`${web_url}/profile/${this.props.state.profile?.profile?.user?.username}`} />
                    {/* Twitter  */}
                    <meta name="twitter:card" content="summary_large_image" />                    
                    <meta name="twitter:title" content={`${this.props.state.profile?.profile?.user?.first_name} ${this.props.state.profile?.profile?.user?.last_name} (@${this.props.state.profile?.profile?.user?.username})`} />
                    <meta name="twitter:description" content={`${this.props.state.profile?.profile?.attributes?.description?.value}`} />
                    <meta name="twitter:image" content={this.props.state.profile?.profile?.user?.photo? this.props.state.profile?.profile?.user?.photo : `${web_url}/logo_baloa_social_share.png`} />
                    <meta name="twitter:url" content={`${web_url}/profile/${this.props.state.profile?.profile?.user?.username}`} />
                </Helmet>

                {/* Profile Modals */}

                {/* Profile Photo Modal */}
                <ProfileUploadPhotoModalComponent 
                    onClick={() => {}} 
                    isOpen={ this.props.state.profile.modals?.upload_photo_is_open } 
                    onClose={() => { this.props.changeProfileUploadPhotoModalStateAction( false ) }}
                />

                {/* Profile Basic Info Modal */}
                <ProfileBasicInfoModalComponent 
                    onClick={() => {}} 
                    isOpen={ this.props.state.profile.modals?.basic_info_is_open } 
                    onClose={() => { this.props.changeProfileBasicInfoModalStateAction( false ) }}
                    model = { this.props.state.profile.profile }
                    onNewData = { ( new_profile_type ) => { 
                        this.props.updateProfileData( this.props.state.profile.profile.user.username, new_profile_type );
                        this.props.changeProfileBasicInfoModalStateAction( false );
                        if(new_profile_type === 'professional'){
                            this.props.changeProfileSpecialtiesModalStateAction( true )
                        }
                    } }
                />

                {/* Profile Teams Modal */}
                <ProfileTeamsModalComponent
                    onClick={() => { }}
                    isOpen={this.props.state.profile.modals?.teams_is_open}
                    onClose={() => this.props.changeProfileTeamsModalStateAction(false)}
                    model={this.props.state.profile.profile}
                    onNewData={(teamcode)=>{
                        this.props.addProfileTeamByCode(this.props.state.profile?.profile?.user?.username, teamcode);
                    }}
                    onDelete={(teamname)=>{
                        this.props.deleteProfileTeam(this.props.state.profile?.profile?.user?.username, teamname)
                    }}
                />

                {/* Profile Nationality Modal */}
                <ProfileNationalityModalComponent 
                    onClick={() => {}} 
                    isOpen={ this.props.state.profile.modals?.nationality_is_open } 
                    onClose={() => { this.props.changeProfileNationalityModalStateAction( false ) }}
                    handleSearchNationality={(new_nationality_search) => { this.props.searchProfileNationality( new_nationality_search) }}
                    handleSearchLocation={( nationality, new_location_search ) => { this.props.searchProfileLocations( nationality, new_location_search ) }}
                    model = { this.props.state.profile.profile }
                    nationalities = { this.props.state.profile.nationalities_search_results }
                    locations = { this.props.state.profile.locations_search_results }
                    onNewData = { ( new_nationality_data ) => { 
                        this.props.changeProfileNationalityModalStateAction( false )
                        this.props.addProfileNationality( this.props.state.profile.profile, new_nationality_data ) } }
                />

                {/* Profile Description Modal */}
                <ProfileDescriptionModalComponent 
                    onClick={() => {}} 
                    isOpen={ this.props.state.profile.modals?.description_is_open } 
                    onClose={() => { this.props.changeProfileDescriptionModalStateAction( false ) }}
                    errors = { this.props.state.profile.errors.general } 
                    model = { this.props.state.profile.profile }
                    onNewData = { ( new_attribute_data ) =>{ 
                        this.props.createOrEditAttributeOnProfile( this.props.state.profile.profile, new_attribute_data );
                        this.props.changeProfileDescriptionModalStateAction( false );
                    }}
                    onResetError = { ( new_errors ) => this.props.loadProfileErrorsAction( new_errors, 'general' ) }
                />

                {/* Profile Technics Primary Team Modal */}
                {/* <ProfileDataSheetModalComponent 
                    onClick={() => {}} 
                    isOpen={ this.props.state.profile.modals?.technics?.primary_team_is_open } 
                    onClose={() => { this.props.changeProfileDataSheetModalStateAction( false ) }}
                    errors = { this.props.state.profile.errors.technics } 
                    model = { this.props.state.profile.profile }
                    onNewData = { ( new_attribute_data ) => this.props.createOrEditAttributeOnProfile( this.props.state.profile.profile, new_attribute_data ) }
                    onResetError = { ( new_errors ) => this.props.loadProfileErrorsAction( { ...this.props.state.profile.errors.technics, ...new_errors }, 'technics' ) }
                /> */}

                {/* Profile Technics Primary Position Modal */}
                <ProfileTechnicsPrimaryPositionModalComponent 
                    onClick={() => {}} 
                    isOpen={ this.props.state.profile.modals?.technics?.primary_position_is_open } 
                    onClose={() => { this.props.changeProfileTechnicsPrimaryPositionModalStateAction( false ) }}
                    errors = { this.props.state.profile.errors.technics } 
                    model = { this.props.state.profile.profile }
                    onNewData = { ( new_attribute_data ) => this.props.createOrEditAttributeOnProfile( this.props.state.profile.profile, new_attribute_data ) }
                    onResetError = { ( new_errors ) => this.props.loadProfileErrorsAction( { ...this.props.state.profile.errors.technics, ...new_errors }, 'technics' ) }
                />

                {/* Profile Technics Secondary Position Modal */}
                <ProfileTechnicsSecondaryPositionModalComponent 
                    onClick={() => {}} 
                    isOpen={ this.props.state.profile.modals?.technics?.secondary_position_is_open } 
                    onClose={() => { this.props.changeProfileTechnicsSecondaryPositionModalStateAction( false ) }}
                    errors = { this.props.state.profile.errors.technics } 
                    model = { this.props.state.profile.profile }
                    onNewData = { ( new_attribute_data ) => this.props.createOrEditAttributeOnProfile( this.props.state.profile.profile, new_attribute_data ) }
                    onResetError = { ( new_errors ) => this.props.loadProfileErrorsAction( { ...this.props.state.profile.errors.technics, ...new_errors }, 'technics' ) }
                />

                {/* Profile Technics Meassures Modal */}
                {/* <ProfileTechnicsMeassuresModalComponent 
                    onClick={() => {}} 
                    isOpen={ this.props.state.profile.modals?.technics?.meassures_is_open } 
                    onClose={() => { this.props.changeProfileTechnicsMeassuresModalStateAction( false ) }}
                    errors = { this.props.state.profile.errors.technics } 
                    model = { this.props.state.profile.profile }
                    onNewData = { ( new_attribute_data ) => this.props.createOrEditAttributeOnProfile( this.props.state.profile.profile, new_attribute_data ) }
                    onResetError = { ( new_errors ) => this.props.loadProfileErrorsAction( { ...this.props.state.profile.errors.technics, ...new_errors }, 'technics' ) }
                /> */}

                {/* Profile Technics Laterality Modal */}
                <ProfileTechnicsLateralityModalComponent 
                    onClick={() => {}} 
                    isOpen={ this.props.state.profile.modals?.technics?.laterality_is_open } 
                    onClose={() => { this.props.changeProfileTechnicsLateralityModalStateAction( false ) }}
                    errors = { this.props.state.profile.errors.technics } 
                    model = { this.props.state.profile.profile }
                    onNewData = { ( new_attribute_data ) => this.props.createOrEditAttributeOnProfile( this.props.state.profile.profile, new_attribute_data ) }
                    onResetError = { ( new_errors ) => this.props.loadProfileErrorsAction( { ...this.props.state.profile.errors.technics, ...new_errors }, 'technics' ) }
                />

                {/* Profile Experiences Modal */}
                <ProfileExperiencesModalComponent 
                    onClick={() => {}} 
                    isOpen={ this.props.state.profile.modals?.experiences_is_open }                     
                    onClose={() => { this.props.changeProfileExperiencesModalStateAction( false ) }}
                    errors = { this.props.state.profile.errors.experiences } 
                    experience = { this.props.state.profile.experience }
                    /* eslint-disable */                    
                    onNewData = { 
                        this.state.experience_type === 'achievements' && (
                            this.state.edit_experience?
                                ( achievement_id, new_attribute_data ) => {this.props.updateProfileAchievement( achievement_id, new_attribute_data )}                            
                            :
                                ( new_attribute_data ) => {this.props.createProfileAchievement( this.props.state.profile.profile?.user?.username, new_attribute_data )}
                        ) 
                        ||
                        (this.state.experience_type === 'trajectory' || this.state.experience_type === 'experiences') && (
                            this.state.edit_experience?
                                ( trajectory_id, new_attribute_data ) => {this.props.updateProfileTrajectory( trajectory_id, new_attribute_data )}                            
                            :
                                ( new_attribute_data ) => {this.props.createProfileTrajectory( this.props.state.profile.profile?.user?.username, new_attribute_data )}
                        )
                    }
                    /* eslint-enable */                                            
                    onResetError = { ( new_errors ) => this.props.loadProfileErrorsAction( { ...this.props.state.profile.errors.experiences, ...new_errors }, 'experiences' ) }
                    is_profile = { true }
                    experience_type={this.state.experience_type}
                    edit_experience={this.state.edit_experience}
                    profile_type={this.props.state.profile.profile.profile_type}
                />

                {/* Profile Specialties Modal */}
                <ProfileSpecialtiesModalComponent 
                    onClick={() => {}} 
                    isOpen={ this.props.state.profile.modals?.specialties_is_open } 
                    onClose={() => { this.props.changeProfileSpecialtiesModalStateAction( false ) }}
                    specialties = { this.props.state.attributes.constants.specialties }
                    specialty = {this.props.state.profile.profile.attributes.specialty }
                    onNewData = { ( new_specialty_data ) => this.props.createOrEditAttributeOnProfile( this.props.state.profile.profile, new_specialty_data ) }
                />

                {/* Profile Aptitudes Modal */}
                <ProfileAptitudesModalComponent 
                    /* eslint-disable */
                    onClick={() => {}} 
                    isOpen={ this.props.state.profile.modals?.aptitudes_is_open } 
                    onClose={() => { this.props.changeProfileAptitudesModalStateAction( false ) }}
                    aptitudes = { 
                        this.props.state.attributes.constants.aptitudes?.filter( aptitude => {
                                const already_have = ( el ) => el['value'] == aptitude;
                                if ( this.props.state.profile.profile.attributes.aptitudes.findIndex( already_have ) == -1 ) {
                                    return true;
                                }
                            }
                        )
                    }
                    onNewData = { ( new_attribute_data ) => this.props.createOrEditAttributeOnProfile( this.props.state.profile.profile, new_attribute_data ) }
                    onAdminButton = { () => { this.props.changeProfileAptitudesModalStateAction( false ); this.props.changeProfileAptitudesAdminModalStateAction( true ); } }
                    profileAptitudes = { this.props.state.profile.profile.attributes.aptitudes }
                    onDeleteData = { ( delete_attribute_data ) => this.props.deleteAttribute( this.props.state.profile.profile, delete_attribute_data ) }
                    /* eslint-enable */
                />

                {/* Profile Aptitudes Admin Modal */}
                {/* <ProfileAptitudesAdminModalComponent 
                    onClick={() => {}} 
                    isOpen={ this.props.state.profile.modals?.aptitudes_admin_is_open } 
                    onClose={() => { this.props.changeProfileAptitudesAdminModalStateAction( false ) }}
                    aptitudes = { this.props.state.profile.profile.attributes.aptitudes }
                    onDeleteData = { ( delete_attribute_data ) => this.props.deleteAttribute( this.props.state.profile.profile, delete_attribute_data ) }
                    // onDeleteData = { ( delete_attribute_data ) => console.log( delete_attribute_data ) }
                    onAddButton = { () => { this.props.changeProfileAptitudesAdminModalStateAction( false ); this.props.changeProfileAptitudesModalStateAction( true ); } }
                /> */}

                {/* Profile followers Modal */}
                <ProfileFollowersModalComponent
                    onClick={() => {}}
                    isOpen={ this.props.state.profile.modals?.followers_is_open } 
                    onClose={ () => {this.props.changeProfileFollowersModalStateAction( false ) }}
                    followers={ this.props.state.profile.followers }
                    following={ this.props.state.profile.following }
                    onFollowProfile = { ( profile_username, username ) => { this.props.followProfileByUsername( profile_username, username ) } }
                    current_username= { this.username }
                    onUnfollowProfile={ ( profile_username, username ) => { this.props.unfollowProfileByUsername( profile_username, username ) } }
                    onFollowPage = { ( page_pagename, username ) => { this.props.followPageByUsername( page_pagename, username ) } }
                    onUnfollowPage = { ( page_pagename, username ) => { this.props.unfollowPageByUsername( page_pagename, username ) } }
                    handleOnProfileClick = { ( profile ) => this.handleOnProfileClick( profile ) }
                    handleOnPageClick = { ( page ) => this.handleOnPageClick( page ) }
                />
                
                {/* Profile validate Aptitudes Modal */}
                {/* <ProfileInviteToValidateAptitudeModalComponent 
                    onClick={() => {}} 
                    isOpen={ this.props.state.profile.modals.invite_to_validate_aptitude.is_open } 
                    onClose={() => { this.props.changeInviteToValidateAptitudeModalState( false, undefined ) }}
                    followers={ this.props.state.profile.followers }
                    aptitude={ this.props.state.profile.modals.invite_to_validate_aptitude?.aptitude }
                    onInviteToValidate = { ( aptitude, username ) => { this.props.inviteProfileToValidateAttribute( aptitude, username ) } }
                /> */}
                {/** Share profile url modal */}
                <ShareProfileLinkModalComponent
                    isOpen= {this.props.state.profile.modals?.share_profile_link}
                    onClose={() => {this.props.changeShareProfileLinkModalStateAction(false)}}
                    share_url={this.state.share_profile_url}
                    handleOnCopyShareLink={()=>{this.handleOnShareProfileLinkClick()}}
                />


                {/* Report Post Modal */}
                <ReportModalComponent
                    onClick={() => {}} 
                    isOpen={ this.props.state.post.modals?.report_is_open } 
                    onClose={() => { this.props.changeReportPostModalStateAction( false ) }}
                    post_id={this.state.post_id}
                    reportPostById={ (post_id, denunciation) => {this.props.reportPostById(post_id, denunciation)}}
                    openAcceptReport={ () => this.props.changeAcceptReportPostModalStateAction( true ) }
                />

                {/* Block Profile Modal */}
                <BlockModalComponent 
                    onClick={() => {}} 
                    isOpen={ this.props.state.post.modals?.block_is_open } 
                    onClose={() => { this.props.changeBlockPostModalStateAction( false ) }}
                    profile_username={this.state.profile_username}
                    blockProfile={ (username) => {this.props.blockProfile(username)}}
                />

                {/* Accept Report Modal */}
                <AcceptReportModalComponent
                    onClick={() => {}} 
                    isOpen={ this.props.state.post.modals?.accept_report_is_open } 
                    onClose={() => { this.props.changeAcceptReportPostModalStateAction( false ) }}
                />

                {/* Reactions Post Modal */}
                <ReactionsModalComponent
                    onClick={() => {}} 
                    isOpen={ this.props.state.post.modals?.reactions_is_open } 
                    onClose={() => { this.props.changePostReactionsModalStateAction( false ) }}
                    reactions={ this.props.state.post.reactions }
                    page={this.props.state.profile.page_active}
                    user_me={this.props.state.user.me}
                    reactions_next_page = { this.props.state.post.reactions_next_page}
                    followProfile = { ( profile ) => { this.props.followProfile( profile ) } }
                    unfollowProfile = { ( profile ) => { this.props.unfollowProfile( profile ) } }
                    changePostIsFollowing ={(username) => {this.props.changePostIsFollowingStateAction(username)}}
                    loadPostReactionsNext={(next_page, reaction_type) => this.props.loadPostReactionsNext(next_page, reaction_type)}
                    loadPostReactionsByType = { ( reaction_type ) => {this.props.loadPostReactionsByReactionType( this.props.state.post.post.id, reaction_type ) } }
                    reactions_by_type = { this.props.state.post.reactions_by_type }
                    changePostIsFollowingFromReactionType={(username, reaction_type) => {this.props.changePostIsFollowingStateFromReactionTypeAction (username, reaction_type)}}
                />

                <ProfileSuggestionsModalComponent
                    onClick={() => {}} 
                    isOpen={ this.props.state.home.modals.profile_suggestions_is_open } 
                    onClose={() => { this.props.changeProfileSuggestionsModalStateAction( false ) }}
                    title={this.props.t('home:follow.suggestions.title')}
                    profiles = { this.props.state.home.profile_suggestions }
                    //className = "home__right-container__profile-suggestions sm"
                    handleFollow = { ( profile ) => { this.props.followProfile( profile ) } }
                />
                <PageSuggestionsModalComponent
                    onClick={() => {}} 
                    isOpen={ this.props.state.home.modals.page_suggestions_is_open } 
                    onClose={() => { this.props.changePageSuggestionsModalStateAction( false ) }}
                    title={this.props.t('home:follow.suggestions.subtitle')}
                    pages = { this.props.state.home.page_suggestions }
                    handleFollow = { ( page ) => { this.props.followPage( page ) } }
                />

                {/* Repost Modal */}
                <QuoteModalComponent
                    isOpen={ this.props.state.post.modals?.quote_modal_is_open } 
                    onClose={() => { this.props.changeQuotePostModalStateAction( false ) } }
                    post={this.props.state.post.post_to_quote}
                    profile = { this.props.state.profile }
                    user = { this.props.state.user }
                    page = { this.props.state.page }
                    onNewData = {(data)=>{ this.props.uploadPost(data) }}

                    followPostProfile= { ( username, post_id )=> { this.props.followPostProfile( username, post_id, 'profile' ) } }                            
                    unfollowPostProfile= { ( username, post_id )=> { this.props.unfollowPostProfile( username, post_id, 'profile' ) } }                            
                    followPostPage= { ( pagename, post_id )=> { this.props.followPostPage( pagename, post_id ) } }                            
                    unfollowPostPage= { ( pagename, post_id )=> { this.props.unfollowPostPage( pagename, post_id ) } }  
                />

                <ProfileRoleModalComponent 
                    isOpen={ this.props.state.profile.modals?.profile_role_is_open } 
                    onClose={() => { this.props.changeProfileRoleModalStateAction( false ) } }
                    profile={ this.props.state.profile.profile }
                    profession_specialties={ this.state.profession_specialties }
                    handleSubmitProfileType={ (new_profile_type) => {this.props.updateProfileData( this.props.state.profile.profile.user.username, new_profile_type )} }
                />

                <ProfileBasicInformationModalComponent 
                    isOpen={ this.props.state.profile.modals?.profile_basic_info } 
                    onClose={() => { this.props.changeProfileBasicInformationModalStateAction( false ) } }
                    handleSearchNationality={(new_nationality_search) => { this.props.searchProfileNationality( new_nationality_search) }}
                    model = { this.props.state.profile.profile }
                    nationalities = { this.props.state.profile.nationalities_search_results }
                    errors = { this.props.state.profile.errors }
                    handleUpdateProfileData = { ( profile_data ) => {                         
                        this.props.updateProfileData( this.props.state.profile.profile.user.username, profile_data );
                        this.props.changeProfileBasicInformationModalStateAction( false );
                        } 
                    }
                    searchProfileWorldCities = { (city_name)=>{ this.props.searchProfileWorldCities(city_name) }}
                    cities = { this.props.state.profile.world_cities_search_result }         
                />
                
                <ProfileTournamentsTeamsSummaryModalComponent 
                    isOpen={ this.props.state.profile.modals?.profile_tournaments_teams_summary } 
                    onClose={ () => { this.handleCloseTournamentsTeamsSummaryModal()} }
                    summary_type={this.state.summary_type}
                    is_owner={this.props.state.profile.profile.is_owner}
                    profile_stats_next_page={this.props.state.profile?.profile_stats_next_page}
                    profile_stats={this.props.state.profile?.profile_stats}
                    fetchMoreData={() => {
                        if(this.state.summary_type === 'tournaments_summary') {
                            this.props.loadProfileTournamentStats(this.props.state.profile.profile.user.username, this.props.state.profile.profile_stats_next_page);
                        }
                        if(this.state.summary_type === 'victories_summary') {
                            this.props.loadProfileAchievementsStats(this.props.state.profile.profile.user.username, this.props.state.profile.profile_stats_next_page);
                        }
                    }} 
                    handleModalUnlockData={() => {
                        //this.handleModalUnlockData();
                        this.handleOnBaloaProSubscription();
                        this.handleCloseTournamentsTeamsSummaryModal();
                    }}  
                    profile_tournament_paid={this.props.state.profile.profile?.profile_tournament_paid}                     
                />

                <ProfileStatisticsPaymentModalComponent 
                    isOpen={ this.props.state.profile.modals?.profile_statistics_payment } 
                    onClose={() => { 
                        this.props.clearProfileTournamentsAction();
                        this.props.changeProfileStatisticsPaymentModalStateAction( false ) 
                        } 
                    }
                    tournaments={this.props.state.profile?.profile_tournaments}
                    handleOnResumePaymentStats={ (tournament) => {this.handleOnResumePaymentStats(tournament)} }
                    tournaments_next_page={this.props.state.profile?.profile_tournaments_next_page}
                    fetchMoreData={ () => {this.props.loadProfileTournaments(this.props.state.profile.profile.user.username, this.props.state.profile.profile_tournaments_next_page)} }
               />

                <ProfileStatisticsPaymentResumeModalComponent 
                    isOpen={ this.props.state.profile.modals?.profile_statistics_resume_payment } 
                    onClose={() => { 
                        this.setState({selected_tournament: []});
                        this.props.changeProfileStatisticsPaymentResumeModalStateAction( false ); 
                    } }
                    tournament={this.state?.selected_tournament}
                    handleOnStatsCheckout={() => { this.handleOnStatsCheckout() }}
                    profile_payment_checkout={this.props.state.profile?.profile_payment_checkout}
                />

                <ProfilePostFormationModalComponent 
                    isOpen={this.props.state.profile?.modals.profile_post_formation}
                    onClose={()=>{this.props.changeProfilePostFormationModalStateAction(false);}}
                    post={this.props.state.profile?.profile_selected_post}

                    //Actions
                    handleOnMessageIconClick = { ( post ) => this.handleOnMessageIconClick( post ) }
                    handleOnCommentsClick = { ( post ) => this.handleOnCommentsClick( post ) }
                    handleOnComment = { ( post, comment ) => this.props.commentPostById( post.id, comment )}

                    openReportPostModal={() => {this.props.changeReportPostModalStateAction( true )}}
                    handleReportPost={(post_id) => {this.handleReportPost(post_id)}}

                    openBlockPostModal={() => {this.props.changeBlockPostModalStateAction( true )}}
                    handleBlockProfile={(username) => {this.handleBlockProfile(username)}}

                    handleOnDeletePostClick = { ( post ) => {this.props.deletePost( post ); this.props.changeProfilePostFormationModalStateAction(false);} }

                    handleOnCopyLinkClick = {(post) => {this.handleOnCopyLinkClick(post)}}
                    
                    handleOnAuthorClick = { ( author ) => this.handleOnAuthorClickV2( author ) }

                    changeHomeEditPostModal = { ( state, post)=>{ this.props.changeHomeEditPostModal( state, post) } }

                    menu_is_open = {true}
                    sendReaction= { (post_id, reaction)=> { this.props.sendReaction(post_id, reaction, 'profile', this.username) }}
                    overrideReaction= { (post_id, reaction, reaction_id)=> { this.props.overrideReaction(post_id, reaction, reaction_id, 'profile', this.username ) }}
                    handleReactionsDetails = { (new_state) => { this.props.changePostReactionsModalStateAction(new_state) } }
                    loadPostReactionsById = { (post_id) => this.props.loadPostReactionsById(post_id) }
                    deleteReaction = { ( post_id, reaction_id )=> { this.props.deleteReaction( post_id, reaction_id, 'profile', this.username ) } }
                    followPostProfile= { ( username, post_id )=> { this.props.followPostProfile( username, post_id, 'profile' ) } }                            
                    unfollowPostProfile= { ( username, post_id )=> { this.props.unfollowPostProfile( username, post_id, 'profile' ) } }                            
                    followPostPage= { ( pagename, post_id )=> { this.props.followPostPage( pagename, post_id ) } }                            
                    unfollowPostPage= { ( pagename, post_id )=> { this.props.unfollowPostPage( pagename, post_id ) } }

                    handleOnQuoteIconClick = { ( post )=>{ this.handleQuoteClick( post ) }}
                />

                <ProfilePostQuizPollModalComponent 
                    isOpen={this.props.state.profile?.modals.profile_post_quiz_poll}
                    onClose={()=>{this.props.changeProfilePollQuizPostModalStateAction(false);}}
                    post={this.props.state.profile?.profile_selected_post}

                    //Actions
                    openReportPostModal={() => {this.props.changeReportPostModalStateAction( true )}}
                    handleReportPost={(post_id) => {this.handleReportPost(post_id)}}

                    openBlockPostModal={() => {this.props.changeBlockPostModalStateAction( true )}}
                    handleBlockProfile={(username) => {this.handleBlockProfile(username)}}

                    handleOnDeletePostClick = { ( post ) => {this.props.deletePost( post ); this.props.changeProfilePollQuizPostModalStateAction(false); } }
                    
                    handleOnAuthorClick = { ( author ) => this.handleOnAuthorClickV2( author ) }

                    changeHomeEditPostModal = { ( state, post)=>{ this.props.changeHomeEditPostModal( state, post) } }

                    menu_is_open = {true}
                    followPostProfile= { ( username, post_id )=> { this.props.followPostProfile( username, post_id, 'profile' ) } }                            
                    unfollowPostProfile= { ( username, post_id )=> { this.props.unfollowPostProfile( username, post_id, 'profile' ) } }                            

                    handleOnClickQuizAnswer={ (post_id, answer_id)=>{ this.props.answerQuiz(post_id, answer_id, 'profile', this.username ) } }
                    handleOnClickPollAnswer={ (post_id, answer_id)=>{ this.props.answerPoll(post_id, answer_id, 'profile', this.username ) } }
                    handleOnQuoteIconClick = { ( post )=>{ this.handleQuoteClick( post ) }}
                />

                <ProfilePostCommentModalComponent 
                    isOpen={this.props.state.profile?.modals.profile_post_comment}
                    onClose={()=>{this.props.changeProfilePostCommentModalStateAction(false);}}
                    post={this.props.state.profile?.profile_selected_post}

                    //Actions
                    handleOnMessageIconClick = { ( post ) => this.handleOnMessageIconClick( post ) }
                    handleOnCommentsClick = { ( post ) => this.handleOnCommentsClick( post ) }
                    handleOnComment = { ( post, comment ) => this.props.commentPostById( post.id, comment )}

                    openReportPostModal={() => {this.props.changeReportPostModalStateAction( true )}}
                    handleReportPost={(post_id) => {this.handleReportPost(post_id)}}

                    openBlockPostModal={() => {this.props.changeBlockPostModalStateAction( true )}}
                    handleBlockProfile={(username) => {this.handleBlockProfile(username)}}

                    handleOnDeletePostClick = { ( post ) => {this.props.deletePost( post ); this.props.changeProfilePostCommentModalStateAction(false);} }

                    handleOnCopyLinkClick = {(post) => {this.handleOnCopyLinkClick(post)}}
                    
                    handleOnAuthorClick = { ( author ) => this.handleOnAuthorClickV2( author ) }

                    changeHomeEditPostModal = { ( state, post)=>{ this.props.changeHomeEditPostModal( state, post) } }

                    menu_is_open = {true}
                    sendReaction= { (post_id, reaction)=> { this.props.sendReaction(post_id, reaction, 'profile', this.username) }}
                    overrideReaction= { (post_id, reaction, reaction_id)=> { this.props.overrideReaction(post_id, reaction, reaction_id, 'profile', this.username ) }}
                    handleReactionsDetails = { (new_state) => { this.props.changePostReactionsModalStateAction(new_state) } }
                    loadPostReactionsById = { (post_id) => this.props.loadPostReactionsById(post_id) }
                    deleteReaction = { ( post_id, reaction_id )=> { this.props.deleteReaction( post_id, reaction_id, 'profile', this.username ) } }
                    followPostProfile= { ( username, post_id )=> { this.props.followPostProfile( username, post_id, 'profile' ) } }                            
                    unfollowPostProfile= { ( username, post_id )=> { this.props.unfollowPostProfile( username, post_id, 'profile' ) } }                            
                    followPostPage= { ( pagename, post_id )=> { this.props.followPostPage( pagename, post_id ) } }                            
                    unfollowPostPage= { ( pagename, post_id )=> { this.props.unfollowPostPage( pagename, post_id ) } }
                    
                    handleOnQuoteIconClick = { ( post )=>{ this.handleQuoteClick( post ) }}
                />    
                <ProfilePostMediaModalComponent 
                    isOpen={this.props.state.profile?.modals.profile_post_media}
                    onClose={()=>{this.props.changeProfilePostMediaModalStateAction(false);}}
                    post={this.props.state.profile?.profile_selected_post}

                    //Actions
                    handleOnMessageIconClick = { ( post ) => this.handleOnMessageIconClick( post ) }
                    handleOnCommentsClick = { ( post ) => this.handleOnCommentsClick( post ) }
                    handleOnComment = { ( post, comment ) => this.props.commentPostById( post.id, comment )}

                    openReportPostModal={() => {this.props.changeReportPostModalStateAction( true )}}
                    handleReportPost={(post_id) => {this.handleReportPost(post_id)}}

                    openBlockPostModal={() => {this.props.changeBlockPostModalStateAction( true )}}
                    handleBlockProfile={(username) => {this.handleBlockProfile(username)}}

                    handleOnDeletePostClick = { ( post ) => {this.props.deletePost( post ); this.props.changeProfilePostMediaModalStateAction(false);} }

                    handleOnCopyLinkClick = {(post) => {this.handleOnCopyLinkClick(post)}}
                    
                    handleOnAuthorClick = { ( author ) => this.handleOnAuthorClickV2( author ) }

                    changeHomeEditPostModal = { ( state, post)=>{ this.props.changeHomeEditPostModal( state, post) } }

                    menu_is_open = {true}
                    sendReaction= { (post_id, reaction)=> { this.props.sendReaction(post_id, reaction, 'profile', this.username) }}
                    overrideReaction= { (post_id, reaction, reaction_id)=> { this.props.overrideReaction(post_id, reaction, reaction_id, 'profile', this.username ) }}
                    handleReactionsDetails = { (new_state) => { this.props.changePostReactionsModalStateAction(new_state) } }
                    loadPostReactionsById = { (post_id) => this.props.loadPostReactionsById(post_id) }
                    deleteReaction = { ( post_id, reaction_id )=> { this.props.deleteReaction( post_id, reaction_id, 'profile', this.username ) } }
                    followPostProfile= { ( username, post_id )=> { this.props.followPostProfile( username, post_id, 'profile' ) } }                            
                    unfollowPostProfile= { ( username, post_id )=> { this.props.unfollowPostProfile( username, post_id, 'profile' ) } }                            
                    followPostPage= { ( pagename, post_id )=> { this.props.followPostPage( pagename, post_id ) } }                            
                    unfollowPostPage= { ( pagename, post_id )=> { this.props.unfollowPostPage( pagename, post_id ) } }

                    handleOnQuoteIconClick = { ( post )=>{ this.handleQuoteClick( post ) }}
                /> 

                {/* Profile tournaments matches modal*/}
                <ProfileTournamentsMatchesModalComponent
                    isOpen={this.props.state.profile?.modals.profile_tournaments_matches}
                    onClose={()=>{this.props.changeProfileTournamentsMatchesModalStateAction(false)}}
                    profile_matches={this.props.state.profile.profile_matches}
                    profile_tournaments={this.props.state.profile.profile_tournaments_matches_data}
                    profile_matches_next_page={this.props.state.profile.profile_matches_next_page}
                    handleOnVideoChange={(videoObj, typeModal)=> this.handleOnVideoChange(videoObj, typeModal)}
                    setClipMatch={(match_id)=>{ this.setState({clip_match: match_id})}}
                    handleModalUnlockData= {()=>{ /* this.handleModalUnlockData() */ this.handleOnBaloaProSubscription(); this.props.changeProfileTournamentsMatchesModalStateAction(false); } }
                    loadMatches={() => { this.props.loadProfileMatches(this.props.state.profile.profile.user.username, this.props.state.profile.profile_matches_next_page) } }
                    matchesFilter={ (tournamentsListString, matchesOrder)=> this.props.loadProfileMatches(this.props.state.profile.profile.user.username, null, tournamentsListString, matchesOrder ) }
                    handleProfilePostModal={ ( post, modal_post_type )=>{ this.handleProfilePostModal( post, modal_post_type ) }}
                    has_unbloqued_tournaments={ this.props.state.profile.profile.profile_pay_a_tournament } //Tiene uno o mas torneos desbloqueados
                    has_pending_tournaments={ this.props.state.profile.profile.profile_tournament_paid} //Tiene torneos pendientes por desbloquear
                    is_owner={ this.props.state.profile.profile.is_owner }
                    handleOnMatch ={ (tournament_id,phase_id, match_date_id, match_id)=>{ this.handleOnMatch(tournament_id,phase_id, match_date_id, match_id) } }
                    username={ this.props.state.profile.profile?.user?.username }
                />

                {/* Profile match clip modal*/}
                <ProfileChooseMatchClipModalComponent
                    isOpen={this.props.state.profile?.modals.profile_choose_match_clip}
                    onClose={()=>{
                        this.props.changeProfileChooseMatchClipModalStateAction(false);
                        this.props.loadProfileMatches( this.props.state.profile.profile.user.username );
                    }}
                    profile_matches={this.props.state.profile.profile_matches}
                    profile_tournaments={this.props.state.profile.profile_tournaments_matches_data}
                    profile_matches_next_page={this.props.state.profile.profile_matches_next_page}
                    resetShareClip={()=>{this.setState({share_clip_on_match: undefined})}}
                    setClipMatch={(match_id)=>{ this.setState({clip_match: match_id}); this.props.changeProfileChooseMatchClipModalStateAction(false); }}
                    loadMatches={() => { this.props.loadProfileMatches(this.props.state.profile.profile.user.username, this.props.state.profile.profile_matches_next_page) } }
                    matchesFilter={ (tournamentsListString, matchesOrder)=> this.props.loadProfileMatches(this.props.state.profile.profile.user.username, null, tournamentsListString, matchesOrder ) }
                    handleModalUnlockData= {()=>{ this.handleModalUnlockData() } }
                    has_unbloqued_tournaments={ this.props.state.profile.profile.profile_pay_a_tournament } //Tiene uno o mas torneos desbloqueados
                    has_pending_tournaments={ this.props.state.profile.profile.profile_tournament_paid} //Tiene torneos pendientes por desbloquear
                />

                <ProfilePostVideoClipsComponent
                    isOpen={this.props.state.profile?.modals.profile_post_video_clips}
                    onClose={()=>{                        
                        this.setState({type_modal_video_clip: '', type_action_modal_video_clip: ''});
                        this.props.changeProfilePostVideoClipModalStateAction(false);
                    }}
                    post={this.props.state.profile?.profile_selected_post}
                    //Actions
                    handleOnMessageIconClick = { ( post ) => this.handleOnMessageIconClick( post ) }
                    handleOnCommentsClick = { ( post ) => this.handleOnCommentsClick( post ) }
                    handleOnComment = { ( post, comment ) => this.props.commentPostById( post.id, comment )}
                    openReportPostModal={() => {this.props.changeReportPostModalStateAction( true )}}
                    handleReportPost={(post_id) => {this.handleReportPost(post_id)}}
                    openBlockPostModal={() => {this.props.changeBlockPostModalStateAction( true )}}
                    handleBlockProfile={(username) => {this.handleBlockProfile(username)}}
                    handleOnDeletePostClick = { ( post ) => {this.props.deletePost( post ); this.props.changeProfilePostVideoClipModalStateAction(false);} }
                    handleOnCopyLinkClick = {(post) => {this.handleOnCopyLinkClick(post)}}
                    handleOnAuthorClick = { ( author ) => this.handleOnAuthorClickV2( author ) }
                    changeHomeEditPostModal = { ( state, post)=>{ this.props.changeHomeEditPostModal( state, post) } }
                    menu_is_open = {true}
                    sendReaction= { (post_id, reaction)=> { this.props.sendReaction(post_id, reaction, 'profile', this.username) }}
                    overrideReaction= { (post_id, reaction, reaction_id)=> { this.props.overrideReaction(post_id, reaction, reaction_id, 'profile', this.username ) }}
                    handleReactionsDetails = { (new_state) => { this.props.changePostReactionsModalStateAction(new_state) } }
                    loadPostReactionsById = { (post_id) => this.props.loadPostReactionsById(post_id) }
                    deleteReaction = { ( post_id, reaction_id )=> { this.props.deleteReaction( post_id, reaction_id, 'profile', this.username ) } }
                    followPostProfile= { ( username, post_id )=> { this.props.followPostProfile( username, post_id, 'profile' ) } }                            
                    unfollowPostProfile= { ( username, post_id )=> { this.props.unfollowPostProfile( username, post_id, 'profile' ) } }                            
                    followPostPage= { ( pagename, post_id )=> { this.props.followPostPage( pagename, post_id ) } }                            
                    unfollowPostPage= { ( pagename, post_id )=> { this.props.unfollowPostPage( pagename, post_id ) } }
                    handleVideoClipOptionMenu={()=>{this.handleVideoClipOptionMenu()}}
                    type_modal_video_clip={this.state.type_modal_video_clip}
                    me_photo={this.props.state.user.me.photo}
                    is_baloa_pro={ this.props.state.profile.profile?.user?.subscription_plan !== 'FREE' }
                    handleOnBaloaProSubscription={ ()=> { this.handleOnBaloaProSubscription() }}
                />

                <ProfileAddVideoModalComponent
                    isOpen={ this.props.state.profile.modals?.profile_add_video_profile } 
                    onClose={() => {
                        this.props.addProfileVideoClipAction( false );
                        this.setState({selected_video_profile: null,  type_action_modal_video_clip: '', clip_match: undefined, share_clip_on_match: undefined});
                    }}
                    selected_video_profile={this.state.selected_video_profile}
                    uploadVideoProfile={(formData, configUpload, loadPost)=>this.handleOnPostVideo(formData, configUpload, loadPost, this.props.state.profile.profile.user.username)}
                    type_modal_video_clip={this.state.type_modal_video_clip}
                    post={this.props.state.profile?.profile_selected_post}
                    type_action_modal_video_clip={this.state.type_action_modal_video_clip}
                    handleEditTitleVideoClip = {(postId, titleVideo)=> this.handleEditVideoClip(postId, titleVideo, null, this.props.state.profile.profile.user.username )}
                    profile_tournament_paid={this.props.state.profile.profile?.profile_tournament_paid}
                    handleModalUnlockData={() => { this.handleModalUnlockData() } }
                    handleLoadMatchClipModal={ () => { 
                        this.props.changeProfileChooseMatchClipModalStateAction(true);
                        this.setState({share_clip_on_match: true })
                    }}
                    shareClipOnMatch={this.state.share_clip_on_match}
                    cleanClipMatch={()=>{ this.setState({clip_match: undefined, share_clip_on_match: undefined }) } }
                    clipMatch={this.props.state.profile.profile_matches.find(match => match.id === this.state.clip_match)}
                    handleEditFeatureMatchVideoClip={(postId, params)=> this.handleEditVideoClip(postId, null, params, this.props.state.profile.profile.user.username )}
                />

                <ProfilePostVideoClipsOptionsModalComponent
                    isOpen={this.state.optionMenu_video_clip}
                    onClose={()=> this.setState({optionMenu_video_clip: false})}
                    handleEditActionVideoClip={(typeAction)=>{
                        this.props.addProfileVideoClipAction( true );
                        this.setState({type_action_modal_video_clip:typeAction});
                        {this.props.state.profile?.profile_selected_post?.context?.has_paid_stats && this.setState({clip_match: this.props.state.profile?.profile_selected_post.context.id, share_clip_on_match: true})}; //eslint-disable-line
                    }}
                    handleDeleteVideoClip={(postId)=> this.handleDeleteVideoClip(postId, this.props.state.profile.profile.user.username )}
                    type_modal_video_clip={this.state.type_modal_video_clip}
                    post={this.props.state.profile?.profile_selected_post}
                    is_baloa_pro={ this.props.state.profile.loggedin?.user?.subscription_plan !== 'FREE' }//Segun el usuario que esta logueado
                />

                {/**Create edit external stats modal */}
                <ProfileExternalStatsModalComponent
                    isOpen={this.props.state.profile.modals.profile_external_stats}
                    onClose={()=>{ this.props.changeProfileExternalStatsModalStateAction(false); this.setState({selected_stat: undefined, edit_stat: false}); }}
                    errors = { this.props.state.profile.errors.external_stats }
                    onResetError = { ( new_errors ) => this.props.loadProfileErrorsAction( new_errors, 'external_stats' ) }
                    onNewData={ (external_stat)=>{ this.props.addExternalStat(this.props.state.profile.profile?.user?.username, external_stat)}}
                    selected_stat={ this.state.selected_stat }
                    edit_stat={ this.state.edit_stat }
                    onEditData={ (external_stat_id, external_stat)=>{ this.props.updateExternalStat(external_stat_id, external_stat, this.props.state.profile.profile?.user?.username)} } 
                    handleDelete={()=>{ this.handleOnDeleteStat()}}
                    is_baloa_pro={ this.props.state.profile.loggedin?.user?.subscription_plan !== 'FREE' }
                />

                {/** Show more external stats modal */}
                <ProfileMoreExternalStatsModalComponent
                    isOpen={this.props.state.profile.modals.profile_more_external_stats}
                    onClose={()=>{ this.props.changeProfileMoreExternalStatsModalStateAction(false) }}
                    handleOnEditExternalStats = { ( stat )=>{ this.handleOnEditExternalStats(stat) } }
                    external_stats={ this.props.state.profile.profile.external_stats }
                    is_owner={this.props.state.profile.profile.is_owner}
                    external_stats_next_page={ this.props.state.profile.external_stats_next_page }
                    handleOnAddExternalStats = { ()=>{ this.props.changeProfileExternalStatsModalStateAction(true)} }
                    fetchMoreData = { ()=>{ this.props.loadProfileExternalStats( this.props.match.params.username, this.props.state.profile.external_stats_next_page ) }}
                    is_baloa_pro={ this.props.state.profile.loggedin?.user?.subscription_plan !== 'FREE' }
                    username={ this.props.state.profile.profile?.user?.username }
                    handleOnBaloaProSubscription = { ()=>{ this.handleOnBaloaProSubscription(); this.props.changeProfileMoreExternalStatsModalStateAction(false); } }
                    handleOnNoProAddExternalStats={ ()=>{ this.props.changeNoProAddExternalStatsModalStateAction(true)} }
                    handleOnVerifiedExternalStat={ ()=>{ this.props.changeVerifiedExternalStatsInsigniaModalStateAction(true) } }
                />

                {/** Edit external stats info modal */}
                <ProfileEditExternalStatsInfoModalComponent
                    isOpen={this.props.state.profile.modals.edit_external_stats_info}
                    onClose={()=>{ this.props.changeEditExternalStatsInfoModalAction(false) }}
                    handeleOnAccept={ ()=>{ 
                        this.props.changeProfileExternalStatsModalStateAction(true);
                        this.props.changeEditExternalStatsInfoModalAction(false);
                    } }
                    handleOnCancel={ ()=>{
                        this.props.changeEditExternalStatsInfoModalAction(false); 
                        this.setState({selected_stat: undefined, edit_stat: false})}
                    }
                />

                {/** Delete external stats info modal */}
                <ProfileDeleteExternalStatsInfoModalComponent
                    isOpen={this.props.state.profile.modals.delete_external_stats_info}
                    handleOnCancel={ ()=>{this.props.changeDeleteExternalStatsInfoModalAction(false); } }
                    handleOnDelete={ ()=>{  
                        this.props.deleteExternalStat(this.state.selected_stat.id, this.props.match.params.username);
                        this.setState({selected_stat: undefined, edit_stat: false});
                        this.props.changeProfileExternalStatsModalStateAction(false);
                        this.props.changeDeleteExternalStatsInfoModalAction(false);
                    } }
                />

                {/** Profile view more featured clips */}
                <ProfileFeaturedClipsViewMoreModalComponent
                    isOpen={this.props.state.profile.modals.view_more_featured_clips }
                    onClose={()=>{ this.props.changeProfileFeaturedClipsViewMoreModalStateAction(false) }}
                    handleOnVideoChange={(videoObj, typeModal)=> this.handleOnVideoChange(videoObj, typeModal)}
                    profile_featured_clip={this.props.state.profile.profile_featured_clip}
                    profile_presentation_video={this.props.state.profile.profile_presentation_video ?? null}
                    handleProfilePostModal={ ( post, modal_post_type )=>{ this.handleProfilePostModal( post, modal_post_type ) }}
                    isProfileOwner={this.props.state.profile.profile.is_owner}
                    loading_profile_featured_videos={ this.props.state.profile.loading_profile_featured_videos }
                    profile_featured_clip_next_page={ this.props.state.profile.profile_featured_clip_next_page }
                    loading_profile_featured_videos_next={ this.props.state.profile.loading_profile_featured_videos_next }
                    loadMoreFeaturedClips={ ()=>{ this.props.loadMoreProfileVideo(this.props.state.profile.profile_featured_clip_next_page) } }
                    is_baloa_pro={ this.props.state.profile.profile?.user?.subscription_plan !== 'FREE' }
                    handleOnUploadMainVideo={()=>{ this.props.changeNoProUploadMainVideoModalStateAction(true) }}
                    handleOnUploadFeaturedVideos={()=>{ this.props.changeNoProUploadFeaturedVideosModalStateAction(true) }}
                />

                {/** No Pro upload main video modal */}
                <ProfileNoProUploadMainVideoModalComponent
                    isOpen={this.props.state.profile.modals.no_pro_upload_main_video }
                    onClose={()=>{ this.props.changeNoProUploadMainVideoModalStateAction(false) }}
                    handleOnBaloaProSubscription={ ()=> { this.handleOnBaloaProSubscription(); this.props.changeNoProUploadMainVideoModalStateAction(false); }}
                />

                {/** No Pro upload featured videos modal */}
                <ProfileNoProUploadFeaturedVideosModalComponent
                    isOpen={this.props.state.profile.modals.no_pro_upload_featured_videos }
                    onClose={()=>{ this.props.changeNoProUploadFeaturedVideosModalStateAction(false) }}
                    handleOnBaloaProSubscription={ ()=> { this.handleOnBaloaProSubscription(); this.props.changeNoProUploadFeaturedVideosModalStateAction(false); }}
                />

                {/** No Pro add external stats modal */}
                <ProfileNoProAddExternalStatsModalComponent
                    isOpen={this.props.state.profile.modals.no_pro_add_external_stats }
                    onClose={()=>{ this.props.changeNoProAddExternalStatsModalStateAction(false) }}
                    handleOnBaloaProSubscription={ ()=> { this.handleOnBaloaProSubscription(); this.props.changeNoProAddExternalStatsModalStateAction(false); this.props.changeProfileMoreExternalStatsModalStateAction(false); }}
                />

                {/** Verified external stats insignia modal */}
                <ProfileVerifiedExternalStatsInsigniaModalComponent
                    isOpen={this.props.state.profile.modals.verified_external_stats_insignia }
                    onClose={()=>{ this.props.changeVerifiedExternalStatsInsigniaModalStateAction(false) }}
                />


                {/* Profile Detail */}
                
                <div className="profile">

                    {/* {this.props.state.profile.profile.loading
                    ?<div className="profile__left-container">
                        <div className="profile-info profile__left-container__my-pages-info">
                            <Skeleton animation="wave" variant="rect" component="div" className="profile-info__photo_skeleton" />
                            <Skeleton animation="wave" className="profile-info__fullname baloa-headline-5" />
                            <Skeleton animation="wave" className="profile-info__username baloa-subtitle-2" />
                            <SeparatorComponent className="profile-info__separator"/>
                            <div className="profile-info__follows">
                                <Skeleton animation="wave" width="40%"/>&nbsp;&nbsp;&nbsp;
                                <Skeleton animation="wave" width="40%"/>
                            </div>
                        </div>
                        <div className="profile__left-container__my-pages">
                            <p className="home__right-container__subtitle baloa-subtitle-1">
                                {this.props.state.profile.profile.is_owner ? this.props.t('pages.title1') : this.props.t('pages.title2')}
                            </p>
                            <div class="my-pages profile__left-container__my-pages-info">
                            <Skeleton animation="wave" variant="rect" component="div" className="my-pages__suggestion" />
                            <Skeleton animation="wave" variant="rect" component="div" className="my-pages__suggestion" />
                            <Skeleton animation="wave" variant="rect" component="div" className="my-pages__suggestion" />
                            </div>
                        </div>
                    </div>
                    :
                    <div className="profile__left-container">

                        
                        { this.props.state.profile.profile.pages &&
                            <div className="profile__left-container__my-pages">
                                <p className="home__right-container__subtitle baloa-subtitle-1">
                                    {this.props.state.profile.profile.is_owner ? this.props.t('pages.title1') : this.props.t('pages.title2')}
                                </p>
                                <MyPagesComponent
                                    pages = { this.props.state.profile.profile.pages.slice(0, this.number_of_showing_pages) }
                                    className = "profile__left-container__my-pages-info"                                    
                                    loadPage = { ( page ) => { this.props.loadPage( page ) } }
                                    switchProfileToPage = {( page ) => { this.props.switchProfileToPage( page ) } }
                                    is_owner = { this.props.state.profile.profile?.user?.username === this.props.state.user.me?.username }
                                />
                             </div>
                        }
                    </div>
                    } */}

                    <div className="profile__center-container">

                         {/* Profile Info */}
                         <ProfileInformationComponent 
                            className="profile__left-container__info"
                            profile = { this.props.state.profile.profile }
                            is_owner = { this.props.state.profile.profile?.user?.username === this.props.state.user.me?.username }
                            onEditDescription = { () => { this.props.changeProfileDescriptionModalStateAction( true ) } }
                            onFollow = { ( profile ) => { this.props.followProfile( profile ) } }
                            onUnfollow = { ( profile ) => { this.props.unfollowProfile( profile ) } }
                            onNewPhoto={ () => this.props.changeProfileUploadPhotoModalStateAction( true ) }
                            onFollowers={ () => this.props.changeProfileFollowersModalStateAction( true ) }
                            handleOnProfileClick = { ( profile ) => this.handleOnProfileClick( profile ) }
                            handleOnShareProfile={ ()=>{ this.handleOnShareProfileLinkClick() }}
                        />
                        {(this.props.state.profile.profile.profile_type === 'player' && ((!this.props.state.profile.profile.is_owner && this.props.state.profile.profile_featured_clip && this.props.state.profile.profile_featured_clip.length > 0) || (!this.props.state.profile.profile.is_owner && this.props.state.profile.profile_presentation_video) || this.props.state.profile.profile.is_owner )) &&
                            <ProfileFeaturedClips
                                handleOnVideoChange={(videoObj, typeModal)=> this.handleOnVideoChange(videoObj, typeModal)}
                                profile_featured_clip={this.props.state.profile.profile_featured_clip}
                                profile_presentation_video={this.props.state.profile.profile_presentation_video ?? null}
                                handleProfilePostModal={ ( post, modal_post_type )=>{ this.handleProfilePostModal( post, modal_post_type ) }}
                                isProfileOwner={this.props.state.profile.profile.is_owner}
                                loading_profile_featured_videos={this.props.state.profile.loading_profile_featured_videos}
                                view_more_featured_clips={()=>{ this.props.changeProfileFeaturedClipsViewMoreModalStateAction(true) }}
                                is_baloa_pro={ this.props.state.profile.profile?.user?.subscription_plan !== 'FREE' }
                                handleOnUploadMainVideo={()=>{ this.props.changeNoProUploadMainVideoModalStateAction(true) }}
                                handleOnUploadFeaturedVideos={()=>{ this.props.changeNoProUploadFeaturedVideosModalStateAction(true) }}
                                show_baloa_pro_insignia={ ((this.props.state.profile.profile?.user?.username === this.props.state.profile.loggedin?.user?.username) && this.props.state.profile.loggedin?.user?.subscription_plan === 'FREE')}
                            />
                        }

                        {/** Baloa Pro Subscription Widget */}
                        {((this.props.state.profile.profile?.user?.username === this.props.state.profile.loggedin?.user?.username) && this.props.state.profile.loggedin?.user?.subscription_plan === 'FREE') &&
                            <BaloaProSubscriptionWidgetComponent
                                handleOnBaloaProSubscription={ ()=> { this.handleOnBaloaProSubscription() }}
                                is_new_baloa_pro_user={this.props.state.user?.me?.is_new_subscriber}
                            />
                        }                        
                        
                        <Tabs
                            variant="fullWidth"
                            className="profile__center-container__tabs"
                            value={ this.state.tab_selected }
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={ ( ev, new_value ) => { this.setState( { tab_selected: new_value } ) } }
                            aria-label="Selection between "
                        >
                            <Tab label={this.props.t('pages.tabs.tabData')} />
                            <Tab label={this.props.t('pages.tabs.tabPosts')} />
                        </Tabs>                                                                        

                        {/* Profile Data */}
                        <TabPanel 
                            index={ 0 } 
                            value={ this.state.tab_selected } 
                            profile={ this.props.state.profile.profile }


                            // onNewBasicInfoButton = { () => { this.props.changeProfileBasicInfoModalStateAction( true ) } }
                            onNewBasicInfoButton = { () => {this.props.changeProfileBasicInformationModalStateAction( true ) } }
                            onNewTechnicsButton = { () => { this.props.changeProfileDataSheetModalStateAction( true ) } }
                            handleOnPrimaryPosition ={() => {this.props.changeProfileTechnicsPrimaryPositionModalStateAction( true ) }}
                            handleOnSecondaryPosition={() => { this.props.changeProfileTechnicsSecondaryPositionModalStateAction( true ) }}
                            handleOnLaterality={() => { this.props.changeProfileTechnicsLateralityModalStateAction( true ) }}
                            onNewExperiencesButton = { (experience_type) => { 
                                this.setState(
                                    { 
                                        experience_type: experience_type,
                                        edit_experience: false,
                                    });
                            
                                this.props.changeProfileExperiencesModalStateAction( true ) }
                            
                            }
                            // onNewSpecialtiesButton = { () => { this.props.changeProfileSpecialtiesModalStateAction( true ) } }
                            onNewSpecialtiesButton = { () => {this.props.changeProfileBasicInfoModalStateAction( true )} }
                            onEditExperiencesButton = { (experience, experience_type) => { 
                                this.setState(
                                    { 
                                        experience_type: experience_type,
                                        edit_experience: true,
                                    });
                                
                                this.props.editProfileExperiencesAction( experience );
                            } } 
                            onDeleteExperiencesButton = { (experience) => { this.props.deleteProfileExperiencesAction( experience ) } } 
                            onNewAptitudesButton = { () => { this.props.changeProfileAptitudesModalStateAction( true ) } }
                            errors = { this.props.state.profile.errors }
                            handleOnDeletePostClick = { ( post ) => this.props.deletePost( post ) }
                            handleOnPageClick = { ( page ) => this.handleOnPageClick( page ) }
                            onValidateAptitude = { ( aptitude ) => this.props.validateAttribute( aptitude, this.props.state.profile.profile.user.username ) }
                            //onInviteToValidateAptitude = { ( aptitude ) => { this.props.changeInviteToValidateAptitudeModalState( true, aptitude ) } }
                            onInviteToValidateAptitude = { () => { this.props.changeShareProfileLinkModalStateAction(true) } }
                            posts = { this.props.state.profile.posts }
                            //loadProfilePosts = { () => {} }

                            onAddNewTeamsButton={() => { this.props.changeProfileTeamsModalStateAction(true) }}
                            handleOnEditProfileRole={() => {this.props.changeProfileRoleModalStateAction(true)}}

                            handleOnSummaryViewAll={(summary_type) => { this.handleOnSummaryViewAll(summary_type) }} 
                            profile_tournament_paid={this.props.state.profile.profile?.profile_tournament_paid}
                            handleModalUnlockData={() => {this.handleOnBaloaProSubscription()}}
                            profile_matches={this.props.state.profile.profile_matches}
                            handleOnViewAllMatches={(new_state)=>{ this.props.changeProfileTournamentsMatchesModalStateAction(new_state)}}
                            handleProfilePostModal={ ( post, modal_post_type )=>{ this.handleProfilePostModal( post, modal_post_type ) }}
                            handleOnVideoChange={(videoObj, typeModal)=> this.handleOnVideoChange(videoObj, typeModal)}
                            setClipMatch={(match_id)=>{ this.setState({clip_match: match_id})}}
                            has_unbloqued_tournaments={ this.props.state.profile.profile.profile_pay_a_tournament } //Tiene uno o mas torneos desbloqueados
                            has_pending_tournaments={ this.props.state.profile.profile.profile_tournament_paid} //Tiene torneos pendientes por desbloquear
                            user_me={ this.props.state.user.me.id }
                            handleOnMatch ={ (tournament_id,phase_id, match_date_id, match_id)=>{ this.handleOnMatch(tournament_id,phase_id, match_date_id, match_id) } }
                            information_type={this.state.information_type}
                            handleFilterInfo={(filter_type) => {this.setState({information_type: filter_type})}}
                            infoAndStatsTabTitle = {this.props.t('tournamentSummary.infoAndStatsTabTitle')}
                            achievementsAndCareerTabTitle = {this.props.t('profileExperiences.achievementsAndCareerTabTitle')}
                            profile_external_stats={ this.props.state.profile.profile.external_stats }
                            handleOnAddExternalStats = { ()=>{ this.props.changeProfileExternalStatsModalStateAction(true)} }
                            handleOnEditExternalStats = { ( stat )=>{ this.handleOnEditExternalStats(stat) } }
                            handleOnViewMore = { ()=>{ this.props.changeProfileMoreExternalStatsModalStateAction(true)} }
                            handleOnNoProAddExternalStats={ ()=>{ this.props.changeNoProAddExternalStatsModalStateAction(true)} }
                            handleOnBaloaProSubscription={ ()=> { this.handleOnBaloaProSubscription() }}
                            handleOnVerifiedExternalStat={ ()=>{ this.props.changeVerifiedExternalStatsInsigniaModalStateAction(true) } }
                        />

                        {/* Profile Feed */}
                        <TabPanel 
                            index={ 1 } 
                            value={ this.state.tab_selected }    
                            profile={ this.props.state.profile.profile }                         

                            posts = { this.props.state.profile.posts }
                            profile_suggestions = { [] }
                            me_photo = { this.props.state.user.me.photo }

                            loadProfilePosts = { () => this.props.loadProfilePosts( this.username, this.props.state.profile.loading_posts, this.props.state.profile.posts_next_page, this.props.state.profile.posts_previuos_page ) }
                            
                            handleOnProfileClick = { ( profile ) => this.handleOnProfileClick( profile ) }
                            handleOnPageClick = { ( page ) => this.handleOnPageClick( page ) }

                            handleClap = { ( post ) => this.props.clapPost( post ) }
                            onCloseClapsModal={() => { this.props.changePostClapsModalStateAction( true ) }}
                            loadPostClapsById={(post_id) => this.props.loadPostClapsById(post_id)}

                            handleOnMessageIconClick = { ( post ) => this.handleOnMessageIconClick( post ) }
                            handleOnCommentsClick = { ( post ) => this.handleOnCommentsClick( post ) }
                            handleOnComment = { ( post, comment ) => this.props.commentPostById( post.id, comment )}

                            openReportPostModal={() => {this.props.changeReportPostModalStateAction( true )}}
                            handleReportPost={(post_id) => {this.handleReportPost(post_id)}}

                            openBlockPostModal={() => {this.props.changeBlockPostModalStateAction( true )}}
                            handleBlockProfile={(username) => {this.handleBlockProfile(username)}}

                            handleOnDeletePostClick = { ( post ) => this.props.deletePost( post ) }

                            handleOnCopyLinkClick = {(post) => {this.handleOnCopyLinkClick(post)}}
                            
                            deletePost = { ( post ) => this.props.deletePost( post ) }

                            handleOnAuthorClick = { ( author ) => this.handleOnAuthorClickV2( author ) }

                            menu_is_open = {true}
                            profile_post = { this.props.state.profile }
                            user = { this.props.state.user }
                            sendReaction= { (post_id, reaction)=> { this.props.sendReaction(post_id, reaction, 'profile', this.username) }}
                            overrideReaction= { (post_id, reaction, reaction_id)=> { this.props.overrideReaction(post_id, reaction, reaction_id, 'profile', this.username ) }}
                            handleReactionsDetails = { (new_state) => { this.props.changePostReactionsModalStateAction(new_state) } }
                            loadPostReactionsById = { (post_id) => this.props.loadPostReactionsById(post_id) }
                            deleteReaction = { ( post_id, reaction_id )=> { this.props.deleteReaction( post_id, reaction_id, 'profile', this.username ) } }
                            followPostProfile= { ( username, post_id )=> { this.props.followPostProfile( username, post_id, 'profile' ) } }                            
                            unfollowPostProfile= { ( username, post_id )=> { this.props.unfollowPostProfile( username, post_id, 'profile' ) } }                            
                            followPostPage= { ( pagename, post_id )=> { this.props.followPostPage( pagename, post_id ) } }                            
                            unfollowPostPage= { ( pagename, post_id )=> { this.props.unfollowPostPage( pagename, post_id ) } }

                            handleOnClickQuizAnswer={ (post_id, answer_id)=>{ this.props.answerQuiz(post_id, answer_id, 'profile', this.username ) } }
                            handleOnClickPollAnswer={ (post_id, answer_id)=>{ this.props.answerPoll(post_id, answer_id, 'profile', this.username ) } }
                            handleOnQuoteIconClick = { ( post )=>{ this.handleQuoteClick( post ) }}
                            handleProfilePostModal={ ( post, modal_post_type )=>{ this.handleProfilePostModal( post, modal_post_type ) }}
                        />

                    </div>
                    { !isMobile &&
                    <div className="profile__right-container">
                        {/* <p className="home__right-container__title baloa-headline-6">{this.props.t('pages.title3')}</p> */}
                       
                        <ProfileSuggestionsComponent
                            profiles = { this.props.state.home.profile_suggestions?.slice(0, this.number_of_showing_profiles) }
                            className = "home__right-container__profile-suggestions sm"
                            handleFollow = { ( profile ) => { this.props.followProfile( profile ) } }
                            loading_profile_suggestions = { this.props.state.home.loading_profile_suggestions }
                            title = { this.props.t('pages.title3') }
                            go_link={this.props.t('home:follow.suggestions.viewAllBtn')}
                            handleGoLink={()=>{ this.props.changeProfileSuggestionsModalStateAction( true )}}
                        />
                       
                        {/* <p className="home__right-container__subtitle baloa-headline-6">{this.props.t('pages.text')}</p> */}
                        
                        <PageSuggestionsComponent
                            pages = { this.props.state.home.page_suggestions?.slice(0, this.number_of_showing_pages) }
                            className = "home__right-container__pages-suggestions sm"
                            handleFollow = { ( page ) => { this.props.followPage( page ) } }
                            loading_page_suggestions = { this.props.state.home.loading_page_suggestions }
                            title = {this.props.t('pages.text')}
                            go_link={this.props.t('home:follow.suggestions.viewAllBtn')}
                            handleGoLink={()=>{this.props.changePageSuggestionsModalStateAction( true )}}
                        />
                        
                        {/*<div className="home__right-container__cta-pages">
                            <p className="home__right-container__cta-pages__subtitle baloa-subtitle-1">{this.props.t('pages.text2')}</p>
                            <p className="home__right-container__cta-pages__text baloa-body-2">{this.props.t('pages.text3')}</p>
                            <SecondaryButtonComponent 
                            onClick={()=>{ this.props.changePageNewPageTypeModalStateAction( true ) }} 
                            className="home__right-container__cta-pages__new"
                            >
                                <span>{this.props.t('pages.button')}</span>
                            </SecondaryButtonComponent>
                        </div>*/}
                    </div>}

                </div>

            </React.Fragment>
            
        );
    }
}

const mapStateToProps = state => {
    return {
        state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadProfile: ( username ) => {
            dispatch( loadProfile( username ) )
        },
        loadAttributesConstants: () => {
            dispatch( loadAttributesConstants() )
        },       
        loadProfilePosts: ( username, loading = false, next_page, previous_page ) => {
            dispatch( loadProfilePosts( username, loading, next_page, previous_page ) )
        },
        loadPage: ( pagename ) => {
            dispatch( loadPage( pagename ) )
        },
        updateProfileData: ( profile_username, profile_data ) => {
            dispatch( updateProfileData( profile_username, profile_data ) )
        },
        followProfile: ( profile ) => {
            dispatch( followProfile( profile ) )
        },
        unfollowProfile: ( profile ) => {
            dispatch( unfollowProfile( profile ) )
        },
        changeProfileUploadPhotoModalStateAction: ( new_modal_state ) => {
            dispatch( changeProfileUploadPhotoModalStateAction( new_modal_state ) )
        },
        changeProfileBasicInfoModalStateAction: ( new_modal_state ) => {
            dispatch( changeProfileBasicInfoModalStateAction( new_modal_state ) )
        },
        changeProfileNationalityModalStateAction: ( new_modal_state ) => {
            dispatch( changeProfileNationalityModalStateAction( new_modal_state ) )
        },
        changeProfileDescriptionModalStateAction: ( new_modal_state ) => {
            dispatch( changeProfileDescriptionModalStateAction( new_modal_state ) )
        },
        changeProfileDataSheetModalStateAction: ( new_modal_state ) => {
            dispatch( changeProfileDataSheetModalStateAction( new_modal_state ) )
        },
        changeProfileTechnicsPrimaryPositionModalStateAction: ( new_modal_state ) => {
            dispatch( changeProfileTechnicsPrimaryPositionModalStateAction( new_modal_state ) )
        },
        changeProfileTechnicsSecondaryPositionModalStateAction: ( new_modal_state ) => {
            dispatch( changeProfileTechnicsSecondaryPositionModalStateAction( new_modal_state ) )
        },
        changeProfileTechnicsMeassuresModalStateAction: ( new_modal_state ) => {
            dispatch( changeProfileTechnicsMeassuresModalStateAction( new_modal_state ) )
        },
        changeProfileTechnicsLateralityModalStateAction: ( new_modal_state ) => {
            dispatch( changeProfileTechnicsLateralityModalStateAction( new_modal_state ) )
        },
        changeProfileExperiencesModalStateAction: ( new_modal_state ) => {
            dispatch( changeProfileExperiencesModalStateAction( new_modal_state ) )
        },
        changeProfileSpecialtiesModalStateAction: ( new_modal_state ) => {
            dispatch( changeProfileSpecialtiesModalStateAction( new_modal_state ) )
        },
        changeProfileAptitudesModalStateAction: ( new_modal_state ) => {
            dispatch( changeProfileAptitudesModalStateAction( new_modal_state ) )
        },
        changeProfileAptitudesAdminModalStateAction: ( new_modal_state ) => {
            dispatch( changeProfileAptitudesAdminModalStateAction( new_modal_state ) )
        },
        createOrEditAttributeOnProfile: ( profile, new_attribute_data ) => {
            dispatch( createOrEditAttributeOnProfile( profile, new_attribute_data ) )
        },
        loadProfileErrorsAction: ( errors, section ) => {
            dispatch( loadProfileErrorsAction( errors, section ) )
        },
        deleteAttribute: ( profile, attribute_data ) => {
            dispatch( deleteAttribute( profile, attribute_data ) )
        },
        clapPost: ( post ) => {
            return dispatch( clapPost( post ) )
        },
        loadProfileFollowers: ( username ) => {
            return dispatch( loadProfileFollowers( username ) )
        },
        changeProfileFollowersModalStateAction: ( new_modal_state ) => {
            dispatch( changeProfileFollowersModalStateAction( new_modal_state ) )
        },
        loadProfileFollowing: ( username ) => {
            return dispatch( loadProfileFollowing( username ) )
        },
        followProfileByUsername: ( profile_username, username ) => {
            dispatch( followProfileByUsername( profile_username, username ) )
        },
        unfollowProfileByUsername: ( profile_username, username ) => {
            dispatch( unfollowProfileByUsername( profile_username, username) )
        },
        followPageByUsername: ( page_pagename, username ) => {
            dispatch( followPageByUsername( page_pagename, username) )
        },
        unfollowPageByUsername: ( page_pagename, username ) => {
            dispatch( unfollowPageByUsername( page_pagename, username) )
        },
        editProfileExperiencesAction: ( experience ) => {
            dispatch( editProfileExperiencesAction( experience ) )
        },
        deleteProfileExperiencesAction: ( attribute_data ) => {            
            dispatch( deleteProfileExperiencesAction( attribute_data ) )
        },
        deletePost: ( post ) => {
            return dispatch( deletePost( post ) )
        },
        changeHomeEditPostModal: ( new_state, post ) => {
            return dispatch( changeHomeEditPostModal( new_state, post ) )
        },
        changeInviteToValidateAptitudeModalState: ( new_modal_state, aptitude ) => {
            dispatch( changeInviteToValidateAptitudeModalStateAction( new_modal_state, aptitude ) )
        },
        inviteProfileToValidateAttribute: ( attribute, username ) => {
            dispatch( inviteProfileToValidateAttribute( attribute, username ) )
        },
        validateAttribute: ( attribute, username ) => {
            dispatch( validateAttribute( attribute, username ) )
        },
        searchProfileNationality: (new_nationality_search) => {
            return dispatch( searchProfileNationality( new_nationality_search ) )
        },
        searchProfileLocations: ( nationality, new_location_search ) => {
            return dispatch( searchProfileLocations( nationality, new_location_search ) )
        },
        addProfileNationality: ( profile, new_nationality_data ) => {
            return dispatch( addProfileNationality( profile, new_nationality_data ) )
        },
        loadHomeProfileSuggestions: () => {
            return dispatch( loadHomeProfileSuggestions() )
        },
        loadHomePageSuggestions: () => {
            return dispatch( loadHomePageSuggestions() )
        },
        followPage: ( page ) => {
            return dispatch( followPage( page ) )
        },
        showCopyPostMessage: (copymessage) => {
            return dispatch( changeErrorMessageSnackBar( {
                message: copymessage,
                error_message_is_open: true
            } ) );
        },
        changePageNewPageTypeModalStateAction: ( new_modal_state ) => {
            return dispatch( changePageNewPageTypeModalStateAction( new_modal_state ) )
        },
        changePostClapsModalStateAction: ( new_modal_state ) => {
            dispatch( changePostClapsModalStateAction( new_modal_state ) )
        },
        loadPostClapsById: ( post_id ) => {
            dispatch( loadPostClapsById( post_id ))
        },
        changeReportPostModalStateAction: ( new_modal_state ) => {
            dispatch( changeReportPostModalStateAction( new_modal_state ))
        },
        changeBlockPostModalStateAction: (new_modal_state) => {
            dispatch( changeBlockPostModalStateAction(new_modal_state))
        },
        changeAcceptReportPostModalStateAction: (new_modal_state) => {
            dispatch( changeAcceptReportPostModalStateAction(new_modal_state))
        },
        switchProfileToPage: ( page ) => {
            return dispatch( switchProfileToPage( page ) )
        },
        commentPostById: ( post_id, comment ) => {            
            return dispatch( commentPostById( post_id, comment ))
        },
        addEventAction: ( data ) => {
            return dispatch( addEventAction( data ) )
        },
        reportPostById: ( post_id, denunciation ) => {
            dispatch( reportPostById( post_id, denunciation ))
        },
        blockProfile:( username ) => {
            dispatch( blockProfile( username ))
        },
        sendReaction:( post_id, reaction, type, typename ) => {
            dispatch( sendReaction( post_id, reaction, type, typename ))
        },
        overrideReaction:( post_id, reaction, reaction_id, type, typename ) => {
            dispatch( overrideReaction( post_id, reaction, reaction_id, type, typename ))
        },
        changePostReactionsModalStateAction: (new_modal_state) => {
            dispatch( changePostReactionsModalStateAction(new_modal_state))
        },
        loadPostReactionsById: ( post_id ) => {
            dispatch( loadPostReactionsById( post_id ))
        },
        loadPostReactionsNext: (next_page, reaction_type) => {
            dispatch( loadPostReactionsNext(next_page, reaction_type))
        },
        loadPostReactionsByReactionType:( post_id, reaction_type ) => {
            dispatch( loadPostReactionsByReactionType( post_id, reaction_type ))
        },
        deleteReaction:( post_id, reaction_id, type, typename ) => {
            dispatch( deleteReaction( post_id, reaction_id, type, typename ))
        },
        followPostProfile:( username, post_id, type ) => {
            dispatch( followPostProfile( username, post_id, type ))
        }, 
        unfollowPostProfile:( username, post_id, type ) => {
            dispatch( unfollowPostProfile( username, post_id, type ))
        }, 
        followPostPage:( pagename, post_id ) => {
            dispatch( followPostPage( pagename, post_id ))
        }, 
        unfollowPostPage:( pagename, post_id ) => {
            dispatch( unfollowPostPage( pagename, post_id ))
        },
        changePageSuggestionsModalStateAction: (new_modal_state) => {
            dispatch( changePageSuggestionsModalStateAction(new_modal_state))
        },
        changeProfileSuggestionsModalStateAction: (new_modal_state) => {
            dispatch( changeProfileSuggestionsModalStateAction(new_modal_state))
        },
        answerQuiz: ( post_id, answer_id, type, username ) => { //TODO: verificar su uso
            dispatch( answerQuiz( post_id, answer_id, type, username ) );
        },
        answerPoll: ( post_id, answer_id, type, username ) => { //TODO: verificar su uso
            dispatch( answerPoll( post_id, answer_id, type, username ) );
        },
        changeQuotePostModalStateAction: ( new_modal_state ) => {
            dispatch( changeQuotePostModalStateAction( new_modal_state ) )
        },
        postToQuoteAction: ( post ) => {
            dispatch( postToQuoteAction( post ) )
        },
        uploadPost: ( post_data ) => {
            dispatch( uploadPost( post_data,null,true,null) )
        },
        changePostIsFollowingStateAction: (username) => {
            dispatch( changePostIsFollowingStateAction(username))
        },
        changePostIsFollowingStateFromReactionTypeAction: (username, reaction_type) => {
            dispatch( changePostIsFollowingStateFromReactionTypeAction(username, reaction_type))
        },
        changeProfileTeamsModalStateAction: (new_modal_state) => {
            dispatch(changeProfileTeamsModalStateAction(new_modal_state))
        },
        addProfileTeamByCode: (username,teamcode) => {
            dispatch(addProfileTeamByCode(username,teamcode));
        },
        deleteProfileTeam: (username,teamname) => {
            dispatch(deleteProfileTeam(username,teamname));
        },
        changeProfileRoleModalStateAction: (new_modal_state) => {
            dispatch( changeProfileRoleModalStateAction(new_modal_state) );
        },
        changeProfileBasicInformationModalStateAction: (new_state) => {
            dispatch( changeProfileBasicInformationModalStateAction(new_state) );
        },
        changeProfileTournamentsTeamsSummaryModalStateAction: (new_modal_state) => {
            dispatch( changeProfileTournamentsTeamsSummaryModalStateAction(new_modal_state) );
        },
        loadProfileTournamentStats: (username, next_page) => {
            dispatch( loadProfileTournamentStats(username, next_page) );
        },
        loadProfileAchievementsStats: (username, next_page) => {
            dispatch( loadProfileAchievementsStats(username, next_page) );
        },
        clearProfileStatsAction: () => {
            dispatch( clearProfileStatsAction() );
        },
        changeProfileStatisticsPaymentModalStateAction: (new_modal_state) => {
            dispatch( changeProfileStatisticsPaymentModalStateAction(new_modal_state) );
        },
        changeProfileStatisticsPaymentResumeModalStateAction: (new_modal_state) => {
            dispatch( changeProfileStatisticsPaymentResumeModalStateAction(new_modal_state) );
        },
        profileTournamentStatsPayment: (username, payment_data) => {
            dispatch( profileTournamentStatsPayment(username, payment_data) );
        },
        loadProfileTournaments: (username, next_page) => {
            dispatch( loadProfileTournaments(username, next_page) );
        },
        clearProfileTournamentsAction: () => {
            dispatch( clearProfileTournamentsAction() );
        },
        loadProfileTeamStats: (username, next_page) => {
            dispatch( loadProfileTeamStats(username, next_page) );
        },
        resetProfilePostAction: async (data)=>{
            await dispatch(resetProfilePostAction(data))
        },
        setProfileSelectedPostAction: (post) => {
            dispatch( setProfileSelectedPostAction(post) );
        },
        changeProfilePostFormationModalStateAction: (new_modal_state) => {
            dispatch( changeProfilePostFormationModalStateAction(new_modal_state) );
        },
        changeProfilePollQuizPostModalStateAction: (new_modal_state) => {
            dispatch( changeProfilePollQuizPostModalStateAction(new_modal_state) );
        },
        changeProfilePostCommentModalStateAction: (new_modal_state) => {
            dispatch( changeProfilePostCommentModalStateAction(new_modal_state) );
        },
        changeProfilePostMediaModalStateAction: (new_modal_state) => {
            dispatch( changeProfilePostMediaModalStateAction(new_modal_state) );
        },
        changeProfileTournamentsMatchesModalStateAction: (new_modal_state) => {
            dispatch( changeProfileTournamentsMatchesModalStateAction(new_modal_state) );
        },
        addProfileVideoClipAction:(new_modal_state)=>{
            dispatch( addProfileVideoClipAction(new_modal_state))
        },
        uploadVideoProfile: (formData, configUpload, loadPost, username) => {
            return dispatch(uploadVideoProfile(formData, configUpload, loadPost, username));
        },
        changeProfilePostVideoClipModalStateAction: (new_modal_state) => {
            dispatch( changeProfilePostVideoClipModalStateAction(new_modal_state) );
        },
        loadAllProfileVideo:( username )=>{
            dispatch(loadAllProfileVideo( username ));
        },
        loadMoreProfileVideo:( next_page )=>{
            dispatch(loadMoreProfileVideo( next_page ));
        },
        deleteProfileVideo:(postId, username)=>{
            return dispatch(deleteProfileVideo(postId, username));
        },
        updateProfileVideo:(postId, titleVideo, username) =>{
            dispatch(updateProfileVideo(postId, titleVideo, username))
        },
        changeProfileChooseMatchClipModalStateAction: (new_modal_state) => {
            dispatch( changeProfileChooseMatchClipModalStateAction(new_modal_state) );
        },
        loadProfileMatches: ( username, next_page, tournaments_filter, old_matches ) => {
            return dispatch( loadProfileMatches( username, next_page, tournaments_filter, old_matches ) );
        },
        updateProfileFeaturedMatchVideo:(postId, params, username) =>{
            dispatch(updateProfileFeaturedMatchVideo(postId, params, username))
        },
        loadProfileAchievements:( username )=>{
            dispatch(loadProfileAchievements( username ));
        },
        createProfileAchievement:( username, formData )=>{
            dispatch(createProfileAchievement( username, formData ));
        },
        updateProfileAchievement: (profile_achievement_id, formData)=>{
            dispatch(updateProfileAchievement( profile_achievement_id, formData ));
        },
        loadProfileTrajectories:( username )=>{
            dispatch(loadProfileTrajectories( username ));
        },
        createProfileTrajectory:( username, formData )=>{
            dispatch(createProfileTrajectory( username, formData ));
        },
        updateProfileTrajectory: (profile_trajectory_id, formData)=>{
            dispatch(updateProfileTrajectory( profile_trajectory_id, formData ));
        },
        changeShareProfileLinkModalStateAction: (new_modal_state) => {
            dispatch( changeShareProfileLinkModalStateAction(new_modal_state) );
        },
        searchProfileWorldCities:( city_name )=>{
            dispatch(searchProfileWorldCities( city_name ));
        },
        changeProfileExternalStatsModalStateAction: (new_modal_state) => {
            dispatch( changeProfileExternalStatsModalStateAction(new_modal_state) );
        },
        loadProfileExternalStats: async( username, next_page  )=>{
            await dispatch(loadProfileExternalStats( username, next_page ));
        },
        addExternalStat: (username, external_stat)=>{
            dispatch(addExternalStat(username, external_stat));
        },
        changeProfileMoreExternalStatsModalStateAction: (new_modal_state) => {
            dispatch( changeProfileMoreExternalStatsModalStateAction(new_modal_state) );
        },
        updateExternalStat: (external_stat_id, external_stat, username)=>{
            dispatch(updateExternalStat(external_stat_id, external_stat, username));
        },
        changeEditExternalStatsInfoModalAction: (new_modal_state) => {
            dispatch( changeEditExternalStatsInfoModalAction(new_modal_state) );
        },
        changeDeleteExternalStatsInfoModalAction: (new_modal_state) => {
            dispatch( changeDeleteExternalStatsInfoModalAction(new_modal_state) );
        },
        deleteExternalStat: (external_stat_id, username)=>{
            dispatch( deleteExternalStat(external_stat_id, username) );
        },
        changeProfileFeaturedClipsViewMoreModalStateAction: (new_modal_state) => {
            dispatch( changeProfileFeaturedClipsViewMoreModalStateAction(new_modal_state) );
        },
        changeNoProUploadMainVideoModalStateAction: (new_modal_state) => {
            dispatch( changeNoProUploadMainVideoModalStateAction(new_modal_state) );
        },
        changeNoProUploadFeaturedVideosModalStateAction: (new_modal_state) => {
            dispatch( changeNoProUploadFeaturedVideosModalStateAction(new_modal_state) );
        },
        changeNoProAddExternalStatsModalStateAction: (new_modal_state) => {
            dispatch( changeNoProAddExternalStatsModalStateAction(new_modal_state) );
        },
        changeVerifiedExternalStatsInsigniaModalStateAction: (new_modal_state) => {
            dispatch( changeVerifiedExternalStatsInsigniaModalStateAction(new_modal_state) );
        },
    }
}


export default compose(
    withTranslation(["profile","home"]),
    connect(mapStateToProps, mapDispatchToProps)
)(ProfileView);
/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Components
import InputFieldAutocompleteComponent from 'shared/components/input-field-autocomplete/InputFieldAutocompleteComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import LeagueCardsSelectorComponent from 'app/leagues/components/league-cards-selector/LeagueCardsSelectorComponent';
import InputMinuteComponent from 'shared/components/input-minute/InputMinuteComponent';
//import TournamentScorerBoardComponent from '../tournament-scorer-board/TournamentScorerBoardComponent';

// Styles
import './LeagueMatchCardsComponent.styles.css';

//Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

//i18
import { withTranslation } from 'react-i18next';

function LeagueMatchCardsComponent( props ) {

    const { children, value, index, ...other } = props; // eslint-disable-line

    const [localCards, setLocalCards] = React.useState(props.endGameState.cards?.local? props.endGameState.cards.local.length : 0);
    const [visitorCards, setVisitorCards] = React.useState(props.endGameState.cards?.visitant? props.endGameState.cards.visitant.length : 0);
    
    const addLocalCardClick = (event) => {
        setLocalCards(localCards + 1);
    };

    const addVisitorCardClick = (event) => {
        setVisitorCards(visitorCards + 1);
    };

    const removeLocalCardClick = (i) => {
        setLocalCards(localCards - 1);

        const localPlayer =  [...componentValues.local_player];
        localPlayer.splice(i,1);
        setComponentValues({...componentValues, local_player: localPlayer});

        const localCard = [...componentCards.local_cards];
        localCard.splice(i,1);
        setComponentCards({...componentCards, local_cards: localCard});        
        
        const localMinute = [...componentCardsMinute.local_cards_minute];
        localMinute.splice(i,1);
        setComponentCardsMinute({...componentCardsMinute, local_cards_minute: localMinute});   
        
        props.removeFormValue('LOCAL',i);
    };

    const removeVisitorCardClick = (i) => {
        setVisitorCards(visitorCards - 1);

        const visitorPlayer =  [...componentValues.visitor_player];
        visitorPlayer.splice(i,1);
        setComponentValues({...componentValues, visitor_player: visitorPlayer});

        const visitorCard = [...componentCards.visitor_cards];
        visitorCard.splice(i,1);
        setComponentCards({...componentCards, visitor_cards: visitorCard});        
        
        const visitorMinute = [...componentCardsMinute.visitor_cards_minute];
        visitorMinute.splice(i,1);
        setComponentCardsMinute({...componentCardsMinute, visitor_cards_minute: visitorMinute});

        props.removeFormValue('VISITOR',i);
    };

    const [componentValues, setComponentValues] = React.useState({ local_player: [null,null,null,null,null,null,null,null,null,null,null,null], visitor_player: [null,null,null,null,null,null,null,null,null,null,null,null] });
    const [componentCards, setComponentCards] = React.useState({ local_cards: [null,null,null,null,null,null,null,null,null,null,null,null], visitor_cards: [null,null,null,null,null,null,null,null,null,null,null,null] });
    const [componentCardsMinute, setComponentCardsMinute] = React.useState({ local_cards_minute: [null,null,null,null,null,null,null,null,null,null,null,null], visitor_cards_minute: [null,null,null,null,null,null,null,null,null,null,null,null] });

    /* eslint-disable */
    React.useEffect( () => {

        if(Object.entries(props.initialValues).length !== 0){
            setComponentValues(props.initialValues);
            setComponentCardsMinute(props.initialValues);
            let arrCards = setSelectedCards(props.initialValues);
            setComponentCards({...componentCards, local_cards: arrCards.localCards, visitor_cards: arrCards.visitorCards});

        }

    }, [ props.initialValues ] )
    /* eslint-enable */

    function setSelectedCards(formValues ) {

        let maxLocal = Math.max(formValues.local_cards_y.length, formValues.local_cards_dy.length, formValues.local_cards_red.length, formValues.local_cards_blue.length); 
        let maxVisitor = Math.max(formValues.visitor_cards_y.length, formValues.visitor_cards_dy.length, formValues.visitor_cards_red.length, formValues.visitor_cards_blue.length); 
        let selectedCards = {
            localCards: [],
            visitorCards: [],
        }

        for(var i = 0; i < maxLocal; i++){
        
            if(formValues.local_cards_y != undefined && formValues.local_cards_y[i] == true){

                    selectedCards.localCards[i] = 'Yellow_Card';

            }else if(formValues.local_cards_dy != undefined && formValues.local_cards_dy[i] == true){

                    selectedCards.localCards[i] = 'Double_Yellow_Card';

            }else if(formValues.local_cards_red != undefined && formValues.local_cards_red[i] == true){

                    selectedCards.localCards[i] = 'Red_Card';
 
            }else if(formValues.local_cards_blue != undefined && formValues.local_cards_blue[i] == true){

                selectedCards.localCards[i] = 'Blue_Card';

            }
        }

        for(var j = 0; j < maxVisitor; j++){
        
            if(formValues.visitor_cards_y != undefined && formValues.visitor_cards_y[j] == true){
 
                    selectedCards.visitorCards[j] = 'Yellow_Card';

            }else if(formValues.visitor_cards_dy != undefined && formValues.visitor_cards_dy[j] == true){

                    selectedCards.visitorCards[j] = 'Double_Yellow_Card';

            }else if(formValues.visitor_cards_red != undefined && formValues.visitor_cards_red[j] == true){

                    selectedCards.visitorCards[j] = 'Red_Card';
    
            }else if(formValues.visitor_cards_blue != undefined && formValues.visitor_cards_blue[j] == true){

                selectedCards.visitorCards[j] = 'Blue_Card';

            }
        }

        return selectedCards;
    }

    const handleOnChangeLocalPlayer=(value, index)=>{
        const newLPlayer = [...componentValues.local_player];
        newLPlayer[index] = value? value: '';
        setComponentValues({...componentValues, local_player: newLPlayer});
    }

    const handleOnChangeVisitorPlayer=(value, index)=>{
        const newVPlayer = [...componentValues.visitor_player];
        newVPlayer[index] = value? value: '';
        setComponentValues({...componentValues, visitor_player: newVPlayer});
    }

    const handleOnChangeLocalMinute=(e, index)=>{
        const newLMinute = [...componentCardsMinute.local_cards_minute];
        newLMinute[index] = e.target.value? e.target.value: '';
        setComponentCardsMinute({...componentCardsMinute, local_cards_minute: newLMinute});
    }

    const handleOnChangeVisitorMinute=(e, index)=>{
        const newVMinute = [...componentCardsMinute.visitor_cards_minute];
        newVMinute[index] = e.target.value? e.target.value: '';
        setComponentCardsMinute({...componentCardsMinute, visitor_cards_minute: newVMinute});
    }
    
    return (

            <div className="league-match-cards__container">
                {/* <TournamentScorerBoardComponent
                    local_team = { props.local_team }
                    visitor_team = { props.visitor_team }
                    local_score = { props.local_score }
                    visitor_score = {  props.visitor_score }
                /> */}
                <div className="league-match-cards__contianer__info-players">
                    <div className='league-match-cards__cointainer__info-players__column-title baloa-table'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabCards.labelSubTitle')}</div>
                    <div className="league-match-cards__cointainer__info-players__column">
                        <div className='league-match-cards__container_column_inf-team'>
                            <div className='league-match-cards__column_inf-team'>
                                {  props.local_team?.photo
                                    ? <img className="league-match-cards__column_inf-team_photo" src={props.local_team?.photo} alt={'photo_team'} />
                                    : <span className="icon-ball" color={getRandonIconBallColor()} />}
                                <div className='league-match-cards__column_inf-team-name'>
                                    <div className="baloa-table">{ props.local_team?.name}</div>
                                    <div className="baloa-mini">@{ props.local_team?.teamname}</div>
                                </div>
                            </div>
                            {/* <div className='league-match-cards__column_score-team'>
                                {props.local_score}
                            </div> */}
                        </div>
                        <div className="league-match-cards__cointainer__action-btn">
                            <SecondaryButtonComponent 
                                type="button"
                                disabled={false} 
                                onClick={(event)=>{addLocalCardClick(event)}} 
                                className="league-match-cards__actions__add"
                            >
                                <span>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabCards.labelAddButton')}</span>
                            </SecondaryButtonComponent>                            
                        </div>
                        <div className="league-match-cards__cointainer__players scroll">
                            {localCards === 0 &&
                                <div className='league-match-cards__cointainer__info-players__empty-message baloa-table'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabCards.labelEmpty')}</div>
                            }
                            {[...Array(Number(localCards))].map((e, i) =>
                                <div className="league-match-cards__cointainer__cards_input" key={i}>
                                    <div className="league-match-cards__cointainer__input-player">
                                        <div className='league-match-cards__cointainer__input-player_inputautocomplete'>
                                            <InputFieldAutocompleteComponent
                                                key={i}
                                                name={ "local_player[" + i +"]" }
                                                options={ props.local_team_members.length !== 0? props.local_team_members : [{id:i,message:props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScorers.noOpt')}] }
                                                noOptionsText={ props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScorers.noOpt') }
                                                optionLabelField={ "revelationPlayer" }
                                                optionSelectedField={ "username" }
                                                className="league-match-cards-form__form__input"
                                                label={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabCards.labelName')}
                                                placeholder={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabCards.labelNameHint')}
                                                onChange={ ( v ) => { handleOnChangeLocalPlayer( v, i )} }
                                                value = { componentValues.local_player[i] }
                                                freeSolo={true}
                                            />
                                        </div>
                                        <InputMinuteComponent
                                            name={"local_cards_minute[" + i +"]"}
                                            id={"local_cards_minute_" + i }
                                            label={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScorers.labelMinute')}
                                            onChange={ ( e ) => { handleOnChangeLocalMinute( e, i )} }
                                            value = { componentCardsMinute.local_cards_minute[i] }                                            
                                            className = {'league-match-cards-form__form__minute-input'}
                                            index={i}
                                        />
                                        <div className="league-match-cards__remove-card" onClick={() => {removeLocalCardClick(i)}}>
                                            <span className="icon-x"/>
                                        </div>
                                    </div>
                                    <div className="league-match-cards__cointainer__input-card">
                                        <LeagueCardsSelectorComponent
                                            name={"local_cards_"+ i}
                                            card_yellow={ "local_cards_y[" + i +"]" }
                                            card_double_yellow={ "local_cards_dy[" + i +"]" }
                                            card_red={ "local_cards_red[" + i +"]" }
                                            card_blue={ "local_cards_blue[" + i +"]" }
                                            card_yellow_id={ "local_cards_y_" + i }
                                            card_double_yellow_id={ "local_cards_dy_" + i }
                                            card_red_id={ "local_cards_red_" + i }
                                            card_blue_id={ "local_cards_blue_" + i }
                                            endGameStateCards = { componentCards.local_cards[i] }   
                                            reValidateForm={props.reValidateForm}                                     
                                        />
                                        
                                    </div>                                    
                                </div>
                                )
                            }
                        </div>
                    </div>
                    <div className="league-match-cards__cointainer__info-players__column">
                        <div className='league-match-cards__container_column_inf-team'>
                            <div className='league-match-cards__column_inf-team'>
                                {  props.visitor_team?.photo
                                    ? <img className="league-match-cards__column_inf-team_photo" src={props.visitor_team?.photo} alt={'photo_team'} />
                                    : <span className="icon-ball" color={getRandonIconBallColor()} />}
                                <div className='league-match-cards__column_inf-team-name'>
                                    <div className="baloa-table">{ props.visitor_team?.name}</div>
                                    <div className="baloa-mini">@{ props.visitor_team?.teamname}</div>
                                </div>
                            </div>
                            {/* <div className='league-match-cards__column_score-team'>
                                {props.visitor_score}
                            </div> */}
                        </div>
                        <div className="league-match-cards__cointainer__action-btn">
                            <SecondaryButtonComponent 
                                type="button"
                                disabled={false} 
                                onClick={(event)=>{addVisitorCardClick(event)}} 
                                className="league-match-cards__actions__add"
                            >
                                <span>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabCards.labelAddButton')}</span>
                            </SecondaryButtonComponent>
                        </div>
                        <div className="league-match-cards__cointainer__players scroll">
                            {visitorCards === 0 &&
                                <div className='league-match-cards__cointainer__info-players__empty-message baloa-table'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabCards.labelEmpty')}</div>
                            }
                            {[...Array(Number(visitorCards))].map((e, i) => 
                                <div className="league-match-cards__cointainer__cards_input" key={i}>
                                    <div className="league-match-cards__cointainer__input-player">
                                        <div className='league-match-cards__cointainer__input-player_inputautocomplete'>
                                            <InputFieldAutocompleteComponent
                                                key={i}
                                                name={ "visitor_player[" + i +"]" }
                                                options={props.visitor_team_members.length !== 0? props.visitor_team_members : [{id:i,message:props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScorers.noOpt')}]}
                                                noOptionsText={ props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScorers.noOpt') }
                                                optionLabelField={ "revelationPlayer" }
                                                optionSelectedField={ "username" }
                                                className="league-match-cards-form__form__input"
                                                label={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabCards.labelName')}
                                                placeholder={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabCards.labelNameHint')}
                                                onChange={ ( v ) => { handleOnChangeVisitorPlayer( v, i )} }
                                                value = { componentValues.visitor_player[i] }

                                                freeSolo={true}
                                            />
                                        </div>
                                        <InputMinuteComponent
                                            name={"visitor_cards_minute[" + i +"]"}
                                            id={"visitor_cards_minute_" + i }
                                            label={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScorers.labelMinute')}
                                            onChange={ ( e ) => { handleOnChangeVisitorMinute( e, i )} }
                                            value = { componentCardsMinute.visitor_cards_minute[i] }                                            
                                            className = {'league-match-cards-form__form__minute-input'}
                                            index={i}
                                        />
                                        <div className="league-match-cards__remove-card" onClick={() => {removeVisitorCardClick(i)}}>
                                            <span className="icon-x"/>
                                        </div>    
                                    </div>
                                    <div className="league-match-cards__cointainer__input-card">
                                        <LeagueCardsSelectorComponent
                                            name={"visitor_cards_"+ i}
                                            card_yellow={ "visitor_cards_y[" + i +"]" }
                                            card_double_yellow={ "visitor_cards_dy[" + i +"]" }
                                            card_red={ "visitor_cards_red[" + i +"]" }
                                            card_blue={ "visitor_cards_blue[" + i +"]" }
                                            card_yellow_id={ "visitor_cards_y_" + i }
                                            card_double_yellow_id={ "visitor_cards_dy_" + i }
                                            card_red_id={ "visitor_cards_red_" + i }
                                            card_blue_id={ "visitor_cards_blue_" + i }
                                            endGameStateCards = { componentCards.visitor_cards[i] }
                                            reValidateForm={props.reValidateForm}
                                        />                                        
                                    </div>                                                  
                                </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
}

export default withTranslation('league')(LeagueMatchCardsComponent);
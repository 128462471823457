/*eslint eqeqeq: "off"*/
// React
import React from 'react';
//import { styled } from '@mui/material/styles';

// React device detect
//import { isMobile } from 'react-device-detect';

// Styles
import './BaloaPlayMatchPaymentOptionsModalComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

//Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import CurrencyFormat from 'react-currency-format';

//import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

function BaloaPlayMatchPaymentOptionsModalComponent( props ) {

    /* const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 10,
        marginTop: 6,
        [`&.${linearProgressClasses.colorPrimary}`]: {
          backgroundColor: '#D9D9D9',
        },
        [`& .${linearProgressClasses.bar}`]: {
          borderRadius: 10,
          backgroundColor: 'var(--new-green-color)',
        },
    })); */

    return(
        <ModalComponent
            isOpen={props.isOpen}
            onClose={props.onClose}
            className="baloa-play-match-payment-options-modal"
        >
            <div className={`baloa-play-match-payment-options-modal__container `}>
                <div className='baloa-play-match-payment-options-modal__header'>
                    <span className='hubicon-play_circle'/>
                    <div className='baloa-names'>{props.t('paymentTitle')}</div>
                </div>
                <div className='baloa-table subtitle'>{props.t('matchPaymentOptionsModal.subTitle')}</div>
                <div className='baloa-play-match-payment-options-modal__plans scroll'>
                {props.match_plans?.map(plan =>
                    <div className='baloa-play-match-payment-options-modal__option-container' onClick={ ()=>{ props.handleOnMatchPay(plan.generic_wompi_product) }}>
                        <div className='baloa-play-match-payment-options-modal__option-header'>
                            <div className='baloa-play-match-payment-options-modal__option-header__title'>
                                <span className={`hub${plan.icon_class}`}/>
                                <div className='baloa-names'>{plan.name}</div>
                            </div>
                            <CurrencyFormat
                                value={plan.value} 
                                displayType={'text'} 
                                thousandSeparator={'.'} 
                                decimalSeparator={","} 
                                prefix={'$'} 
                                suffix={' ' + `${plan.currency}`}
                                //onValueChange ={}
                                renderText={value => <div className='baloa-names price'>{`${value}`}</div>}
                            />
                        </div>
                        <div className="baloa-table" dangerouslySetInnerHTML={{
                            __html: plan.description
                        }}></div>
                        {plan.features?.map(feature =>
                            <li className="baloa-table lista">{feature}</li>
                        )}
                    </div>
                )}
                </div>
            </div>
        </ModalComponent>
    )
}

export default withTranslation('baloaplay')(BaloaPlayMatchPaymentOptionsModalComponent);
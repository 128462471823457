/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';

// Styles
import './NoProUnlockStatsModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function NoProUnlockStatsModalComponent ( props ) {
    return(
        <ModalComponent
            isOpen={props.isOpen} 
            title={'title'}
            className='no-pro-unlock-stats__container'
        >
            <span className='hubicon-award_star' />
            <div className="no-pro-unlock-stat__title">
                {props.t('viewTournament.inscription.noProUnlockedStatsModal.title')}
            </div>
            <div className="no-pro-unlock-stat__message">
                {props.t('viewTournament.inscription.noProUnlockedStatsModal.message')}
            </div>
            <div className='no-pro-unlock-stats__advanteges'>
                <div className="no-pro-unlock-stat__advantage">
                    <span className='hubicon-check1'/>
                    {props.t('viewTournament.inscription.noProUnlockedStatsModal.adv1')}
                </div>
                <div className="no-pro-unlock-stat__advantage">
                    <span className='hubicon-check1'/>
                    {props.t('viewTournament.inscription.noProUnlockedStatsModal.adv2')}
                </div>
                <div className="no-pro-unlock-stat__advantage">
                    <span className='hubicon-check1'/>
                    {props.t('viewTournament.inscription.noProUnlockedStatsModal.adv3')}
                </div>
                <div className="no-pro-unlock-stat__advantage">
                    <span className='hubicon-check1'/>
                    {props.t('viewTournament.inscription.noProUnlockedStatsModal.adv4')}
                </div>
            </div>
            <div className="no-pro-unlock-stat__bottom-message">
                {props.t('viewTournament.inscription.noProUnlockedStatsModal.message2')}
            </div>
            <PrimaryButtonComponent 
                onClick={() => {props.onButtonClick()}}
                className={'small'}
            >
                <span>{props.t('viewTournament.inscription.noProUnlockedStatsModal.subscribeBtnLbl')}</span>
            </PrimaryButtonComponent>
            <TextButtonComponent
                onClick={() => {props.onClose()}}
                className={'small'}
            >
                <span>{props.t('viewTournament.inscription.noProUnlockedStatsModal.closeBtnLbl')}</span>
            </TextButtonComponent>
        </ModalComponent>
    )
}

export default withTranslation('tournament')(NoProUnlockStatsModalComponent);
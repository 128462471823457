//React
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

//Styles
import './LeagueTeamMembersComponent.styles.css';

// Material UI
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Chip from '@mui/material/Chip';


//i18
import { withTranslation } from 'react-i18next';

// Components
//import RoundedButtonComponent from 'shared/components/rounded-button/RoundedButtonComponent';
//import calendarIcon from '../../../../assets/icons/calendar-tournament-icon.svg';
//import ubicationIcon from '../../../../assets/icons/ubication-tournament-icon.svg';
//import HubTournamentCardComponent from 'app/tournaments/components/hub-tournament-card/HubTournamentCardComponent';
import LeagueTournamentComponent from '../league-tournament-component/LeagueTournamentComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// React device detect
import { isMobile } from 'react-device-detect'

// function that manages the tabs to select the component options
function TabPanel( props ) {

    const { children, value, index, ...other } = props;

    const [listToFilter, setListToFilter] = React.useState(props.team_players);
    const [searchCategory, setSearchCategory] = React.useState('all');


    React.useMemo(() => setListToFilter(props.team_players), [props.team_players]); /* eslint-disable */

    const handleSearchTable = word => {
        const currentFilter = word.toLowerCase();
        setListToFilter(
            props.team_players?.filter(function (member) {
                return member['fullname'].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                    .includes(currentFilter.normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
            }));
    };

    const handleSearchCategory = category => {
        if(category){
            setSearchCategory(category);
            setListToFilter(
                props.team_players?.filter(function (member) {
                    return member.role.team_role.includes(category)
                }));
        }else{
            setSearchCategory('all');
            setListToFilter(props.team_players);
        }
    };

    return(
        <React.Fragment>

            <div
                role="tabpanel"
                hidden={ value !== index }
                id={ `simple-tabpanel-${index}` }
                aria-labelledby={ `simple-tab-${index}` }
                { ...other }
            >
                <div>
                    {/* Members */}
                    { (value === index) && (index === 1) && (                        
                        <div className='teams-members__container '>
                            {!isMobile &&
                                <div className='teams-members__search-bar'>
                                    <span className="icon-search" />
                                    <input className="teams-members__search_input baloa-names"
                                        type="text" placeholder={props.t('team:team.tabs.members.searchPlaceholder')}
                                        onChange={(event) => { handleSearchTable(event.target.value) }}>                        
                                    </input>
                                </div>
                            }
                            {isMobile && props.is_page_admin &&
                                <SecondaryButtonComponent
                                    type={'button'}
                                    className={"teams-list__option-button small"}
                                    onClick={ ()=>{ props.handleOnShareTeamPageInscription() }}
                                >
                                    <span className={`icon hubicon-person_add`}>
                                        <span className="path2"></span>
                                        <span className="path3"></span> 
                                    </span>
                            
                                    <span>{ props.t('team:newTeam.forms.players.multiButton.invitePlayer') }</span>
                                </SecondaryButtonComponent>
                            }
                                <div className='teams-members__search-tags'>
                                    <Chip
                                        className={`teams-members__search-tags__tag ${searchCategory === 'all'? 'selected':''} baloa-table`}
                                        label={props.t('team.tabs.members.allRoles')}
                                        onClick={() => { handleSearchCategory() }}
                                    />
                                    <Chip
                                        className={`teams-members__search-tags__tag ${searchCategory === 'player'? 'selected':''} baloa-table`}
                                        label={props.t('team.tabs.members.player')}
                                        onClick={() => { handleSearchCategory('player') }}
                                    />
                                    <Chip
                                        className={`teams-members__search-tags__tag ${searchCategory === 'coach'? 'selected':''} baloa-table`}
                                        label={props.t('team.tabs.members.coach')}
                                        onClick={() => { handleSearchCategory('coach') }}
                                    />
                                    <Chip
                                        className={`teams-members__search-tags__tag ${searchCategory === 'staff'? 'selected':''} baloa-table`}
                                        label={props.t('team.tabs.members.staff')}
                                        onClick={() => { handleSearchCategory('staff') }}
                                    />
                                </div>
                                {!isMobile &&
                                    <div className='teams-members__container__card__headers'>
                                        <div className='baloa-table-column'>{props.t('teams.teamsNameLabel')}</div>
                                        <div className='baloa-table-column'>{props.t('teams.teamMemberRol')}</div>
                                    </div>
                                }
                                   {listToFilter?.map ( (team_players ) => (

                                        <div  className='teams-members__container__card' key={team_players.id}>
                                            <div className='teams-members__container__card__info'>

                                                    {team_players.photo?
                                                        <img src={team_players.photo} className="teams-members__container__card__info__img" alt={`${team_players.username} img`}/>
                                                        :
                                                        <span className="icon-ball teams-members__container__card__info__img" color={getRandonIconBallColor()} />
                                                    }
                                                {!isMobile
                                                ? <Link to={`/profile/${ team_players.username }/`} className="league-team-members__team-name" onClick={() => props.onClose() }>
                                                    <div className='baloa-names'>
                                                        {`${team_players.fullname}`}
                                                        {team_players.subscription_plan !== 'FREE' && 
                                                            <span className='hubicon-award_star'/>
                                                        }
                                                    </div>
                                                    <div className='baloa-username'>@{team_players.username}</div>
                                                </Link>
                                                : <Link to={`/profile/${ team_players.username }/`} className="league-team-members__team-name" onClick={() => props.onClose() }>
                                                    <div className="league-team-members__team-name">
                                                        <div className='baloa-names'>
                                                            {team_players.fullname}
                                                            {team_players.subscription_plan !== 'FREE' && 
                                                                <span className='hubicon-award_star'/>
                                                            }
                                                        </div>
                                                        <div className='baloa-username'>@{team_players.username}&nbsp;&bull;&nbsp;{ team_players.role?.team_role ? props.t("team.tabs.members." + team_players.role?.team_role) : ''}</div>
                                                    </div>
                                                </Link>
                                                }
                                            </div>
                                            { /* !team_players.is_following
                                                ?
                                                <RoundedButtonComponent
                                                    type="button"
                                                    disabled={false}
                                                    onClick={ () => props.followProfile( { 'user':{'username':team_players.username} } ) }
                                                    className="league-team-members__btn-follow"
                                                >
                                                    <span>{props.t("league:options.tournaments.pages.newTournament.tabs.tabBasicData.btnFollow")}</span>
                                                </RoundedButtonComponent>
                                                :
                                                <RoundedButtonComponent
                                                    type="button"
                                                    disabled={false}
                                                    onClick={ () => props.unfollowProfile( { 'user':{'username':team_players.username} } ) }
                                                    className="league-team-members__btn-follow following"
                                                >
                                                    <span>{props.t("league:options.tournaments.pages.newTournament.tabs.tabBasicData.btnUnfollow")}</span>
                                                </RoundedButtonComponent> */
                                                !isMobile &&
                                                <div className='baloa-names'>{team_players.role?.team_role ? props.t(`team.tabs.members.${team_players.role?.team_role}`) : ''}</div>
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                       /*  </div> */
                    )}
                    {/* Tournaments */}
                    { (value === index) && (index === 0) && (
                        <React.Fragment>
                        {/* <div className='teams-members__container'> */
                            /*<div className="scroll">
                                {
                                    props.tournaments?.map ( (tournaments, index ) => 
                                    <Link to={'/tournament/hub/'+ tournaments.tournament_id}>
                                        {!isMobile
                                        ?
                                        <div className='teams-members__container__card' key={tournaments.id}>
                                            <div className='teams-members__container__card__info'>
                                                <span className="icon-trophy" />
                                                <div className="league-team-members__tournament-team">
                                                    <div className='baloa-names'>{tournaments.tournament}</div>
                                                    <div className='baloa-username'>{tournaments.main_country}, {tournaments.main_city} {tournaments.start_date} - {tournaments.end_date}</div>
                                                    <div className="teams-members__subtitle baloa-username">{tournaments.tournament_tag != null ? props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams." + tournaments.tournament_tag.extra.value) : ""}</div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        /* <div key={index} className="teams-members_tournamentList_card">
                                            {tournaments.tournament_logo
                                                ? <img src={tournaments.tournament_logo} alt={`${''} img`} className={"teams-members_tournamentList_img"} />
                                                : <span className={"icon-ball teams-members_tournamentList_img"} color={getRandonIconBallColor()} />
                                            }
                                            <div className="teams-members_tournamentList_card_inf">
                                                <div className="teams-members_tournamentList_card_text">
                                                    <p className="baloa-names">{tournaments.name}</p>
                                                    <div className="teams-members_tournamentList_card_subText baloa-username">
                                                        <img src={calendarIcon} alt="" />&nbsp;
                                                        <p>{tournaments.start_date}&nbsp;-&nbsp;{tournaments.end_date}</p>
                                                        <img src={ubicationIcon} alt="" />&nbsp;
                                                        <p>{tournaments.main_city}&nbsp;-&nbsp;{tournaments.main_country}</p>
                                                    </div>
                                                </div>
                                                <div className="teams-members_tournamentList_card_tags baloa-username">
                                                    <div className="tag-active">{props.t("tournament:viewTournament.tournamentType.Sport")}</div>
                                                    <div>{props.t(`league:options.tournaments.pages.newTournament.tabs.tabBasicData.${tournaments.tournament_gender}`)}</div>
                                                    <div>{tournaments.category}</div>
                                                </div>
                                            </div>
                                        </div> 
                                        <HubTournamentCardComponent
                                            key={index}
                                            tournament={tournaments}
                                            subsection={undefined}
                                            handleOnShareTournamentLinkClick={props.handleOnShareTournamentLinkClick}
                                            onEnterViewport={() => { }}
                                            is_tournamentList={true}
                                        />
                                        }
                                    </Link>
                                    )
                                }
                            </div>*/}
                            {/* <div>{'componente de torneos- Modal de quipos'}</div> */}
                            <LeagueTournamentComponent // LeagueTournamentGeneralListComponent
                                tournament_tag={props.tournament_tag}
                                league_page={props.pagename}
                                tournamentHub={false}
                                //tournamentTile={props.t('options.tournaments.labelTitle')}
                                filterType={'tournamentList'} // tournamentAll - tournamentSport - tournamentVideoGame
                                onResetError={props.onResetError}
                                errors={props.errors}
                                active_tournaments={props.tournaments?.filter(el => { return el.state.toLowerCase() !== "finalized" })}
                                completed_tournaments={props.tournaments?.filter(el => { return el.state.toLowerCase() === "finalized" })}
                                handleBackButton={props.handleBackButton}
                                handleOnActiveTournamentActionClick={ props.handleOnTournamentSelected}
                                handleOnCompletedTournamentActionClick={props.handleOnTournamentSelected}
                                handleFilterTournament={props.handleFilterTournament}
                                showFilter={false}
                                handleOnShareTournamentLinkClick={props.handleOnShareTournamentLinkClick}
                            />

                        {/* </div> */}
                        </React.Fragment>
                    )}
                </div>

            </div>
        </React.Fragment>
    )

}

// function that brings up the lists of members and tournaments that is called from the page view
function LeagueTeamMembersComponent(props) {

    const [tabSelected, setTabSelected] = useState(0);

    const prepareFullName =(team_players) => {
        team_players.map(team =>{
            team.fullname = `${team.first_name} ${team.last_name}`; 
        }
        )
        return team_players;
    }

    return(
        <div className={`league-team-members ${isMobile? 'scroll' : ''}`}>
            <div className="team-members__header">
                {/* <Link to={`/page/${props.pague}/`}>
                    <span className='icon-x team-members__icon-back'></span>
                </Link> */}
                
                <div className="team-members__team-photo">
                    {props.team.photo?
                    <img src={props.team.photo} alt={`${props.team.name} shield`}/>
                    :
                    <span className="icon-ball" color={getRandonIconBallColor()} />
                    }
                </div>
                <div className="baloa-headline-2">
                    {props.team.name}
                </div>
                <div className="baloa-normal-medium">@{props.team.teamname }</div>
                <div className="baloa-normal-medium">{props.t(`league:options.tournaments.pages.newTournament.tabs.tabBasicData.${props.team.team_gender}`)} &nbsp;&bull;&nbsp; {props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams." + props.team.category)}</div>
                
                    
            </div>
            {/* <div className="team-members__rute">
                <img src={baloa_home_icon} alt="Inicia" className="team-members__rute__img"/>
                <spam className="team-members__rute__pague">{props.pague}</spam>
                <span className="icon-chevron-roght" /> 
                <span className="team-members__rute__pague">{props.t('team.tabs.titles.titleTeam')}</span>
                <span className="icon-chevron-roght" /> 
                <span className="team-members__rute__pague">{props.team.teamname}</span>
            </div> */}
            
            <Tabs
                variant="fullWidth"
                className="new-tournament__center__content__tabs"
                value={ tabSelected }
                indicatorColor="primary"
                textColor="primary"
                onChange={ ( ev, new_value ) => { setTabSelected( new_value ) } }
                aria-label="Selection between "
            >
                <Tab
                    label={props.t('team.tabs.tournaments.tournamentTabLabel')}                    
                />
                <Tab
                    label={props.t('team.tabs.members.memeberTabLabel')}                    
                />                
            </Tabs>
            <TabPanel
                index={ 0 }
                value={ tabSelected }
                tournaments = { props.tournaments }
                t={props.t}
                handleOnTournamentSelected={props.handleOnTournamentSelected}
            />
            <TabPanel
                index={ 1 }
                value={ tabSelected }
                team_players = { prepareFullName(props.team.team_players) }
                handleOnMemberActionClick={ props.handleOnMemberActionClick }
                handleOnAddButtonClick={()=>props.handleOnAddButtonClick(true)}
                t={props.t}
                followProfile={ props.followProfile }
                unfollowProfile={ props.unfollowProfile }
                onClose={ props.onClose }
                is_page_admin={props.is_page_admin}
                handleOnShareTeamPageInscription={props.handleOnShareTeamPageInscription}                
            />

        </div>

    )
}

export default withTranslation(['team', 'league'])(LeagueTeamMembersComponent);
// Axios
import Axios from "config/axiosConfig";

// Globals
import { api_url } from "config/GlobalData";

export const CreateNewTournamentApi = (tournament_data, league_pagename) => {

    //antes ${ api_url }/tournaments/
    return Axios.instance.post(`${api_url}/leagues/${league_pagename}/tournaments/?version=4`,
        tournament_data,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const CreateNewGroupsPhasesApi = (tournament_id, phase_id, teams_clasified_by_groups) => { //TODO: Nueva API

    //return Axios.instance.patch(`${api_url}/tournaments/${tournament_id}/phases/${phase_id}/?version=2`,
    return Axios.instance.patch(`${api_url}/tournaments/${tournament_id}/phases/${phase_id}/`,
        {
            phase_type: teams_clasified_by_groups.phase_type,
            classified_count: teams_clasified_by_groups.classified_count,
            groups_count: teams_clasified_by_groups.groups_count,
            //best_third: teams_clasified_by_groups.best_third,
            //best_third_teams: teams_clasified_by_groups.best_third_teams,
            shuffle: teams_clasified_by_groups.is_shuffle,
            phase_name: teams_clasified_by_groups.phase_name,
            match_duration: teams_clasified_by_groups.match_duration,
            //automatic_calendar: teams_clasified_by_groups.automatic_calendar,
            //roundtrip: teams_clasified_by_groups.roundtrip,
            phase_fair_play_id: teams_clasified_by_groups.phase_fair_play_id
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const NewMatchApi = (tournament_date, new_tournament) => { //TODO: Nueva API

    return Axios.instance.post(`${api_url}/tournament-group-dates/${tournament_date}/matches/?version=2`,
        new_tournament,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const EditMatchApi = (match_id, match) => { //TODO: Nueva API

    return Axios.instance.put(`${api_url}/tournament-matches/${match_id}/?version=2`,
        match,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const GetTournamentPhaseGroupDatesApi = (tournament_group_id) => {

    return Axios.instance.get(`${api_url}/tournament-groups/${tournament_group_id}/dates/?pagination=False`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const GetLeagueTournamentsApi = (league_name) => {

    return Axios.instance.get(`${api_url}/leagues/${league_name}/tournaments/?pagination=False&version=2`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const AddDateToTournamentGroupApi = (tournament_group_id, date_name) => {

    return Axios.instance.post(`${api_url}/tournament-groups/${tournament_group_id}/dates/`,
        {},
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const GetMatchesByDateApi = (id_date, next_url, page_size = 10) => { //TODO: Nueva API

    //return Axios.instance.get(`${api_url}/tournament-group-dates/${id_date}/matches/?pagination=False&version=2`,
    return Axios.instance.get(next_url? next_url : `${api_url}/main-tournament-group-dates/${id_date}/matches/?page_size=${page_size}`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const AddMemberToTournamentApi = (id_tournament, member_username) => {

    return Axios.instance.post(`${api_url}/tournaments/${id_tournament}/members/`,
        { username: member_username },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const DeleteMemberFromTournamentApi = (id_member_staff) => {

    return Axios.instance.delete(`${api_url}/tournament-staff/${id_member_staff}/`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const AddTeamToTournamentApi = (id_tournament, team_name, team_state) => { //TODO: Nueva API
    return Axios.instance.post(`${api_url}/tournaments/${id_tournament}/teams/?version=2`,
        { 
            team: team_name,
            state: team_state
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const AddInviteTeamToTournament = (id_tournament_team, state_invite_team) => { // TODO: New API Send invitation teams
    return Axios.instance.patch(`${api_url}/tournament-teams/${id_tournament_team}/state/?version=2`,
        { state: state_invite_team },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const DeleteTeamToTournamentApi = (id_tournament_team) => { //TODO: Nueva API

    return Axios.instance.delete(`${api_url}/tournament-teams/${id_tournament_team}/?version=2`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetTournamentTeamsApi = (tournament_id, next_page) => { 

    //return Axios.instance.get(`${api_url}/tournaments/${tournament_id}/teams/?version=2&pagination=False`,
    return Axios.instance.get(next_page? next_page :`${api_url}/tournaments/${tournament_id}/teams/`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const GetTournamentMembersApi = (tournament_id) => {

    return Axios.instance.get(`${api_url}/tournaments/${tournament_id}/members/?pagination=False`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const GetTournamentResultsTableApi = (tournament_id) => {

    return Axios.instance.get(`${api_url}/tournaments/${tournament_id}/positions_table/`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const GetTournamentPhasesApi = (tournament_id) => {

    //return Axios.instance.get(`${api_url}/tournaments/${tournament_id}/phases/?version=3`,
    return Axios.instance.get(`${api_url}/tournaments/${tournament_id}/phases/?version=2`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const GetPhaseResultsTableApi = (phase_id) => {

    //return Axios.instance.get(`${api_url}/tournament-phase/${phase_id}/positions_tables/?version=2`,
    return Axios.instance.get(`${api_url}/main-tournaments-phase/${phase_id}/groups/`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const GetGroupPhaseResultsTableApi = (tournament_group_id, page_size=200, next_page) => {

    return Axios.instance.get(next_page? next_page : `${api_url}/main-tournaments-group/${tournament_group_id}/positions_table/?page_size=${page_size}`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const GetTournamentInfoApi = (tournament_id) => { //TODO: Nueva API

    return Axios.instance.get(`${api_url}/tournaments/${tournament_id}/?version=2`,

        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const DeleteMatchGameApi = (match_id) => {

    return Axios.instance.delete(`${api_url}/tournament-matches/${match_id}/`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const FinishTournamentApi = (id_tournament, tournament_data) => {

    return Axios.instance.patch(`${api_url}/tournaments/${id_tournament}/finalize/?version=2`,
        tournament_data,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const SearchWorldCitiesApi = (city) => {
    return Axios.instance.get(`${api_url}/cities/?search_city=${city}`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetCountriesApi = (country) => {
    return Axios.instance.get(`${api_url}/countries/?search=${country}`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const searchCityByCountryApi = (countryId, city) => {
    return Axios.instance.get(`${api_url}/countries/${countryId}/cities/?name=${city}`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetTournamentPlayersApi = (tournament_id) => { //TODO: Nueva API

    return Axios.instance.get(`${api_url}/tournaments/${tournament_id}/players/?version=2`,

        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const AddTournamentPhaseApi = (id_tournament) => {

    //return Axios.instance.post(`${api_url}/tournaments/${id_tournament}/phases/?version=2`,
    return Axios.instance.post(`${api_url}/tournaments/${id_tournament}/phases/`,
        {},
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const AddTeamToGroupApi = (id_group, team_name) => { //TODO: Nueva API

    return Axios.instance.post(`${api_url}/tournament-groups/${id_group}/teams/?version=2`,
        { team: team_name },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const DeleteTeamFromGroupApi = (id_group, id_team) => {

    return Axios.instance.delete(`${api_url}/tournament-groups/${id_group}/teams/${id_team}/`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetTournamentPhaseApi = (phase_id) => {

    return Axios.instance.get(`${api_url}/tournament-phase/${phase_id}/`,

        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const FinishTournamentPhaseApi = (id_phase) => { //TODO: Nueva API
    // console.log('fin', id_phase );
    return Axios.instance.post(`${api_url}/tournament-phase/${id_phase}/finalize/?version=2`,
        {},
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const DeleteTournamentPhaseApi = (id_tournament, id_phase) => {
    // console.log('eli',id_tournament, id_phase );
    return Axios.instance.delete(`${api_url}/tournaments/${id_tournament}/phases/${id_phase}/`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetTournamentClassifiedTeamsByPhaseApi = (id_phase) => { //TODO: Nueva API

    return Axios.instance.get(`${api_url}/tournament-phase/${id_phase}/classified_teams/?version=2&pagination=False`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

/* export const GetTournamentPhaseTeamsApi = (id_phase) => { //TODO: Nueva API

    return Axios.instance.get(`${api_url}/tournament-phase/${id_phase}/teams/?version=2&pagination=False&assignment=not_assigned`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
} */

export const DeleteDateApi = (date_id) => {
    // console.log('eli',date_id );
    return Axios.instance.delete(`${api_url}/tournament-group-dates/${date_id}/`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetTournamentPostsApi = (page_name, tournament_id, state, type) => {
    return Axios.instance.get(
        `${api_url}/pages/${page_name}/posts/?version=2&tournament_id=${tournament_id}&state=${state}&type=${type}&pagination=False`, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });
}

export const SearchBaloaTeamApi = (team) => {
    return Axios.instance.get(`${api_url}/teams-page/?search=${team}&pagination=False`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const UpdateTournamentApi = (tournament_data, tournament_id) => {

    return Axios.instance.patch(`${api_url}/tournaments/${tournament_id}/edit_tournament/?version=2`,
        tournament_data,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const SetFinalFixture = (phase_id, fixture_shuffle, roundtrip) => {

    return Axios.instance.patch(`${api_url}/tournament-phase/${phase_id}/generate_matches/`,
        {
            fixture_shuffle: fixture_shuffle,
            roundtrip: roundtrip,
            automatic_calendar: true,
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetPhaseMatchesByDateApi = (phase_id, calendar_date) => {

    return Axios.instance.get(`${api_url}/tournament-phase/${phase_id}/matches/?pagination=False&version=2&calendar_date=${calendar_date}`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const GetPhaseDatesApi = (phase_id) => {

    return Axios.instance.get(`${api_url}/tournament-phase/${phase_id}/calendar_dates/`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const GetTournamentFootballTypeApi = () => {

    return Axios.instance.get(`${api_url}/football-type/`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const LoadFilterToTournamentApi = (league_pagename, type_filter) => {
    return Axios.instance.get(`${api_url}/leagues/${league_pagename}/tournaments/?version=2${type_filter}`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    )

}

export const SearchBaloaTeamToInvitedApi = (id,team) => {
    return Axios.instance.get(`${api_url}/hub-teams/?tournament_id=${id}&team_name=${team}&pagination=False`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const getFormApi = ( form_id ) => {
    return Axios.instance.get( `${ api_url }/form/${form_id}/`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetCriteriaTounretsApi = (id_phase) => {
    return Axios.instance.get(`${api_url}/tournament-phase/${id_phase}/tiebreaker_criteria/`,
    {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    }
    );
}

export const PutNewCriteriaTounretsApi = (id_phase, array_criteria) => {
    return Axios.instance.put(`${api_url}/tournament-phase/${id_phase}/tiebreaker_criteria/`,
        {
            "tournament_phase_tiebreaker_criteria": array_criteria
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const CreateNewFormApi = (form_data, form_type, caption, tournament_id, form_name) => {

    return Axios.instance.post(`${api_url}/form-template/`,
        {
            form_type: form_type,
            caption: caption,
            owner_object: tournament_id,
            questions: form_data,
            name: form_name
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetFieldsStatusEditingApi = (id_tournament) => {
    return Axios.instance.get(`${api_url}/tournaments/${id_tournament}/editable_fields/`,
    {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    }
    );
}

export const GetFilterPhaseTeamsApi = (id_phase, teams_state) => { //TODO: Nueva API

    return Axios.instance.get(`${api_url}/tournament-phase/${id_phase}/teams/?version=2&pagination=False&assignment=${teams_state}`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}


export const UpdateBestThirdToGroupApi = (status, original_Id,team,groupId) => { //TODO: Nueva API

    return Axios.instance.patch(`${api_url}/tournament-groups/${original_Id}/`,
        { 
            best_third: status,
            team: team ?? null,
            group_id: groupId ?? null
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const EditPhasesApi = (phase_data, phase_id) => { //TODO: Nueva API

    return Axios.instance.patch(`${api_url}/hub-tournament-phase/${phase_id}/`,
        {  
            phase_data
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const TransferTeamPlayerApi = (tournament_player_id, teamname) => {
    return Axios.instance.patch(`${api_url}/hub-tournament-players/${tournament_player_id}/transfer/`,
    {
        team: teamname,
    },
    {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    }
    );
}

export const TransferTeamKnockoutApi = (win_team, lost_team, group_id) =>{
    return Axios.instance.post(`${api_url}/match/create-match/`,
    {
        win_team: win_team,
        lost_team: lost_team,
        group_id:  group_id,
    },
    {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    }
    );
}

export const GetCodeFormTemplateApi = () => {
    return Axios.instance.get(`${api_url}/form-types/`,
    {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    }
);
}

export const GetTournamentStaffApi = (tournament_id) => {

    return Axios.instance.get(`${api_url}/tournaments/${tournament_id}/members/?version=2&pagination=False`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const AddStaffApi = (username, role, staff_state, tournament_id) => {

    return Axios.instance.post(`${api_url}/tournaments/${tournament_id}/staff/`,
        {
            username: username,
            role: role,
            role_state: staff_state
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const UpdateStaffStateApi = (role_id, role_state, role_type) => {
    let body = {}
    if (role_state){
        body={
            role_state: role_state
        }
    }
    if (role_type){
        body={
            role_type: role_type
        }
    }

    return Axios.instance.patch(`${api_url}/role/${role_id}/`,
        body,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const DeleteStaffApi = (role_id) => {

    return Axios.instance.delete(`${api_url}/role/${role_id}/`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetRefereeingPaymentApi = (payment_data, match_id) => {

    return Axios.instance.post(`${api_url}/tournament-matches/${match_id}/referee_checkout/`,
        payment_data,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const RefereePaymentApi = (match_id) => {
    return Axios.instance.post(`${api_url}/tournament-matches/${match_id}/referee_payment/`,
        {},
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const CalculateRefereeFeeApi = (data) => {

    return Axios.instance.post(`${api_url}/payments/calculate-payment/`,
        data,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

/* export const GetTournamentPhaseInterGroupsTeamsApi = (id_phase) => { //Api only call teams for inter_groups_teams reducer state

    return Axios.instance.get(`${api_url}/tournament-phase/${id_phase}/teams/?version=2&pagination=False&assignment=assigned`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
} */

export const DownloadFormDataApi = (id_tournament) => { //Api only call teams for inter_groups_teams reducer state

    return Axios.instance.get(`${api_url}/tournament/${id_tournament}/forms/`,
        {
            responseType: "blob",
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const DownloadPaymentDataApi = (id_tournament) => { //Api only call teams for inter_groups_teams reducer state

    return Axios.instance.get(`${api_url}/tournament/${id_tournament}/payments/`,
        {
            responseType: "blob",
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const UpdateClassifiedCountGroupApi = (id_group, classified_count) => {
    return Axios.instance.patch(`${api_url}/tournament-groups/${id_group}/?version=2`,
        {
            classified_count: classified_count
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const CreateTournamentPhaseGroupApi = (id_phase) => {
    return Axios.instance.post(`${api_url}/tournament-phase/${id_phase}/groups/`,
        {},
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const DeleteGroupFromPhaseApi = (id_group) => {
    return Axios.instance.delete(`${api_url}/tournament-groups/${id_group}/`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const CleanAllGroupOfPhaseApi = (id_phase) => {
    return Axios.instance.delete(`${api_url}/tournament-phase/${id_phase}/group_teams/`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const CreateAutomaticGroupPhasesApi = (id_phase, filter_state)=>{
    return Axios.instance.post(`${api_url}/tournament-phase/${id_phase}/organize/?state=${filter_state}`,
    {},
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const EndTournamentPhaseApi = (id_phase) => {
    return Axios.instance.post(`${api_url}/tournament-phase/${id_phase}/finalized/`,
        {},
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const AddAllTeamsToGroupApi = (id_group, state) => {
    return Axios.instance.post(`${api_url}/tournament-groups/${id_group}/organize/?state=${state}`,
        {},
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const GetTeamsToMatchAssistanceAligmentApi = (matchId) => {
    return Axios.instance.get(`${api_url}/tournament-matches/${matchId}/team_players_in_match/`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const PatchPlayerAssistanceAligmentApi = ( matchId, playerArray) =>{
    return Axios.instance.patch(`${api_url}/tournament-matches/${matchId}/team_players_in_match/`,
    { players: playerArray },
    {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    }
    );
}

export const GetItemsForTournamentTypeApi = () =>{
    return Axios.instance.get(`${api_url}/tournament-type/`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const EnrollRejectMultiplePlayersApi = ( tournament_team_id, players_states ) =>{
    return Axios.instance.patch(`${api_url}/hub-tournament-teams/${tournament_team_id}/statesplayers/`,
        { player_states: players_states },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetListExistingFormApi = (pageName,formTypes) =>{
    return Axios.instance.get(`${api_url}/pages/${pageName}/templates/?form_type=${formTypes}`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const SaveCardIdFormInfoApi = (form_info) => {
    return Axios.instance.post(`${api_url}/tournament-player-card/`,
        {
            form_template: form_info.form_template,
            questions: form_info.questions
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const DeleteFormTemplateApi = (id_template) => {
    return Axios.instance.delete(`${api_url}/form-template/${id_template}/`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetCardIdsDataApi = (tournament_id, teams_list, token) =>{
    return Axios.instance.get(`${api_url}/tournaments/${tournament_id}/cards/?tournament_teams=${teams_list}`,
        {
            
            headers: {
                'Authorization': token? token : localStorage.getItem('token')
            }
        }
    );
}

export const GetPlayerCardIdDataApi = (tournament_player_id, token) =>{
    return Axios.instance.get(`${api_url}/tournament-player-cards/${tournament_player_id}/`,
        {            
            headers: {
                'Authorization': token? token : localStorage.getItem('token')
            }
        }
    );
}

export const GetStaffCardIdDataApi = ( team_profile_id, tournament_team_id, token ) =>{
    return Axios.instance.get(`${api_url}/staff-cards/${team_profile_id}/?tournament_team_id=${tournament_team_id}`,
        {
            
            headers: {
                'Authorization': token? token : localStorage.getItem('token')
            }
        }
    );
}

export const PostCreatePageFieldsdApi = ( form_data ) =>{
    return Axios.instance.post(`${api_url}/fields/`,
        form_data,
        {            
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetPageFieldsdApi = (page_name) =>{
    return Axios.instance.get(`${api_url}/fields/?pagename=${page_name}`,
        {            
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const PatchEditPageFieldsdApi = ( form_data, field_id ) =>{
    return Axios.instance.patch(`${api_url}/fields/${field_id}/`,
        form_data,
        {            
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const DeletePageFielddApi = ( field_id ) =>{
    return Axios.instance.delete(`${api_url}/fields/${field_id}/`,
        {            
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const PostCreateTournamentFieldsdApi = ( fields_array, tournament_id ) =>{
    return Axios.instance.post(`${api_url}/tournaments/${tournament_id}/fields/`,
        {
            fields: fields_array
        },
        {            
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetTournamentFieldsdApi = ( tournament_id ) => {
    return Axios.instance.get(`${api_url}/tournaments/${tournament_id}/fields/`,
        {            
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const DeleteTournamentFieldsdApi = ( tournament_field_id ) =>{
    return Axios.instance.delete(`${api_url}/tournament-field/${tournament_field_id}/`,
        {            
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetPageToTournamentFieldsdApi = ( page_name, tournament_id ) => {
    return Axios.instance.get(`${api_url}/fields/?pagename=${page_name}&tournament_id=${tournament_id}`,
        {            
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

/** Fixtures V2 */
export const SetTournamentFixtureApi = (tournament_id, fixture_config_data) => {

    return Axios.instance.post(`${api_url}/tournaments/${tournament_id}/fixture_config/`,
        {
            number_matches: fixture_config_data.number_matches,
            start_date: fixture_config_data.start_date,
            match_duration: fixture_config_data.match_duration,
            time_between_games: fixture_config_data.time_between_games,
            assign_days: fixture_config_data.assign_days,
            assign_fields: fixture_config_data.assign_fields

        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GenerateFixtureMatchesApi = (tournament_phase_id, fixture_shuffle) => {

    return Axios.instance.patch(`${api_url}/tournament-phase/${tournament_phase_id}/generate_matches/?version=2`,
        {
            fixture_shuffle: fixture_shuffle,
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const SetFixtureAvailableDaysApi = (tournament_id,tournament_days) => {

    return Axios.instance.post(`${api_url}/tournaments/${tournament_id}/tournament_days/`,
        {
            tournament_days
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const DeleteFixtureAvailableDayApi = ( tournament_day_id ) =>{
    return Axios.instance.delete(`${api_url}/tournament-day/${tournament_day_id}/`,
        {            
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetAvailableFixtureDaysApi  = ( tournament_id ) => {
    return Axios.instance.get(`${api_url}/tournaments/${tournament_id}/tournament_days/`,
        {            
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetGroupedAvailableFixtureDaysApi  = ( tournament_id ) => {
    return Axios.instance.get(`${api_url}/tournaments/${tournament_id}/tournament_days/?group=True`,
        {            
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const SetFixtureLockedDaysApi = (tournament_id,tournament_locked_date) => {

    return Axios.instance.post(`${api_url}/tournaments/${tournament_id}/non_playable_dates/`,
        {
            start_date: tournament_locked_date.start_date,
            end_date: tournament_locked_date.end_date? tournament_locked_date.end_date : tournament_locked_date.start_date,
            type: tournament_locked_date.type 

        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const DeleteFixtureLockedDayApi = ( tournament_day_id ) =>{
    return Axios.instance.delete(`${api_url}/tournament-non-playable-date/${tournament_day_id}/`,
        {            
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetFixtureLockedDaysApi  = ( tournament_id ) => {
    return Axios.instance.get(`${api_url}/tournaments/${tournament_id}/non_playable_dates/`,
        {            
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetTournamentMatchesFilterApi = ( tournament_date_id, params ) => {
    //return Axios.instance.get(`${api_url}/tournament-group-dates/${tournament_date_id}/matches/?version=2${params}`,
    return Axios.instance.get(`${api_url}/main-tournament-group-dates/${tournament_date_id}/matches/?${params}`,
        {            
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const PostNewTournamentMatchApi = ( tournament_date_id, form_data ) => {
    return Axios.instance.post(`${api_url}/tournament-group-dates/${tournament_date_id}/matches/?version=2`,
        form_data,
        {            
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetFixtureFieldTeamsApi = ( tournament_field_id ) => {
    return Axios.instance.get(`${api_url}/tournament-field/${tournament_field_id}/tournament_teams/`,
        {            
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const AddFixtureTeamsToFieldApi = ( tournament_field_id, field_teams ) => {
    return Axios.instance.post(`${api_url}/tournament-field/${tournament_field_id}/teams/`,
        {
            field_teams
        },
        {            
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const DeleteFixtureFieldTeamApi = ( tournament_field_team_id ) =>{
    return Axios.instance.delete(`${api_url}/tournament-field-team/${tournament_field_team_id}/`,
        {            
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetFullFixtureConfigApi = ( tournament_id ) => {
    return Axios.instance.get(`${api_url}/tournaments/${tournament_id}/fixture_config/`,
        {            
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}


//Fields Fixture apis
export const SetFixtureFieldAvailableDaysApi = ( tournament_field_id, schedule ) => {

    return Axios.instance.post(`${api_url}/tournament-field/${tournament_field_id}/schedule/`,
        {
            schedule
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetTournamentPhaseGroupDatesFilterApi = (tournament_group_id, params) => {
    return Axios.instance.get(`${api_url}/tournament-groups/${tournament_group_id}/dates/${params}`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const DeleteFixtureFieldAvailableDayApi = ( tournament_field_day_id ) =>{
    return Axios.instance.delete(`${api_url}/tournament-field-schedule/${tournament_field_day_id}/`,
        {            
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetFieldAvailableFixtureDaysApi  = ( tournament_field_id ) => {
    return Axios.instance.get(`${api_url}/tournament-field/${tournament_field_id}/Schedule/`,
        {            
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetGroupedFieldAvailableFixtureDaysApi  = ( tournament_field_id ) => {
    return Axios.instance.get(`${api_url}/tournament-field/${tournament_field_id}/schedule/?group=True`,
        {            
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const SetFixtureFieldLockedDaysApi = ( tournament_field_id, tournament_field_locked_date) => {

    return Axios.instance.post(`${api_url}/tournament-field/${tournament_field_id}/non_playable_dates/`,
        {
            start_date: tournament_field_locked_date.start_date,
            end_date: tournament_field_locked_date.end_date? tournament_field_locked_date.end_date : tournament_field_locked_date.start_date,
            type: tournament_field_locked_date.type 

        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const DeleteFixtureFieldLockedDayApi = ( tournament_field_day_id ) =>{
    return Axios.instance.delete(`${api_url}/tournament-field-non-playable-date/${tournament_field_day_id}/`,
        {            
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetFixtureFieldLockedDaysApi  = ( tournament_field_id ) => {
    return Axios.instance.get(`${api_url}/tournament-field/${tournament_field_id}/non_playable_dates/`,
        {            
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const DeleteFixtureApi = ( tournament_phase_id ) =>{
    return Axios.instance.delete(`${api_url}/tournament-phase/${tournament_phase_id}/matchday/`,
        {            
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const SearchTournamentTeamsAdminApi  = ( tournament_id, tournament_team_name, state, page_size=10, next_url ) => {
    return Axios.instance.get(next_url? next_url : `${api_url}/main-tournaments/${tournament_id}/teams/?tournament_team_name=${tournament_team_name}&state=${state}&page_size=${page_size}`,
        {            
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetTournamentScorersTableApi = (tournament_id, page_size=5, next_page) => {
    return Axios.instance.get(next_page? next_page : `${api_url}/main-tournaments/${tournament_id}/scorers_statistics/?page_size=${page_size}`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetTournamentYellowCardsTableApi = (tournament_id, page_size=5, next_page) => {
    return Axios.instance.get(next_page? next_page : `${api_url}/main-tournaments/${tournament_id}/yellow_cards_statistics/?page_size=${page_size}`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetTournamentRedCardsTableApi = (tournament_id, page_size=5, next_page) => {
    return Axios.instance.get(next_page? next_page : `${api_url}/main-tournaments/${tournament_id}/red_cards_statistics/?page_size=${page_size}`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetTournamentBlueCardsTableApi = (tournament_id, page_size=5, next_page) => {
    return Axios.instance.get(next_page? next_page : `${api_url}/main-tournaments/${tournament_id}/blue_cards_statistics/?page_size=${page_size}`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetTournamentAssistsTableApi = (tournament_id, page_size=5, next_page) => {
    return Axios.instance.get(next_page? next_page : `${api_url}/main-tournaments/${tournament_id}/assistants_statistics/?page_size=${page_size}`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetTournamentLeastDefeatedFenceTableApi = (tournament_id, page_size=5, next_page) => {
    return Axios.instance.get(next_page? next_page : `${api_url}/main-tournaments/${tournament_id}/least_goals_conceded_statistics/?page_size=${page_size}`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetTournamentTeamsMostGoalsTableApi = (tournament_id, page_size=5, next_page) => {
    return Axios.instance.get(next_page? next_page : `${api_url}/main-tournaments/${tournament_id}/most_goals_scored_statistics/?page_size=${page_size}`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetTournamentFairPlayTableApi = (tournament_id, page_size=5, next_page) => {
    return Axios.instance.get(next_page? next_page : `${api_url}/main-tournaments/${tournament_id}/fairplay_statistics/?page_size=${page_size}`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetTournamentSummaryTableApi = (tournament_id) => {
    return Axios.instance.get(`${api_url}/main-tournaments/${tournament_id}/statistics_summary/`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const CreateFairPlaySettingsApi = ( page_pagename, settings ) => {

    return Axios.instance.post(`${api_url}/pages/${page_pagename}/fair_play/`,        
        settings,        
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const LoadFairPlaySettingsApi = ( page_pagename, page_size=6, next_page ) => {
    return Axios.instance.get(next_page? next_page : `${api_url}/pages/${page_pagename}/fair_play/?page_size=${page_size}`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetFairPlaySettingApi = ( fair_play_id ) => {
    return Axios.instance.get(`${api_url}/fair-play/${fair_play_id}/`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const CreatePhaseExtraPenaltyApi = ( tournament_phase_id, sanction ) => {

    return Axios.instance.post(`${api_url}/tournament-phase/${tournament_phase_id}/sport_sanction/`,        
        sanction,        
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const LoadPhaseSanctionsApi = ( tournament_phase_id, page_size=6, sanction_type, next_page ) => {
    return Axios.instance.get( next_page? next_page : `${api_url}/tournament-phase/${tournament_phase_id}/sport_sanction/?page_size=${page_size}&sanction_name=${sanction_type}`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}


export const GetPhaseMatchesByTeamApi = ( tournament_phase_id, tournament_team_id, next_page ) => {

    return Axios.instance.get(next_page? next_page : `${api_url}/tournament-phase/${tournament_phase_id}/matches/?tournament_team_id=${tournament_team_id}`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const DeletePhaseExtraPenaltyApi = ( tournament_phase_id, extra_sanction_id ) =>{
    return Axios.instance.delete(`${api_url}/tournament-phase/${tournament_phase_id}/delete_sport_sanction/${extra_sanction_id}/`,
        {            
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const UpdatePhaseExtraPenaltyApi = (tournament_phase_id, extra_sanction_id, sanction) => {

    return Axios.instance.patch(`${api_url}/tournament-phase/${tournament_phase_id}/edit_sport_sanction/${extra_sanction_id}/`,
        sanction,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetAllTeamPlayersApi = (tournament_team_id, state) => {

    return Axios.instance.get(`${api_url}/main-tournament-teams/${tournament_team_id}/players/?state=${state}&pagination=true&page_size=50`,
    {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const ReOpenPhaseApi = ( tournament_phase_id ) => {

    return Axios.instance.post(`${api_url}/tournament-phase/${tournament_phase_id}/reopen/`,        
        {},      
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// React device detect
import { isMobile } from 'react-device-detect';

// Styles
import './BaloaProAdvantagesComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

function BaloaProAdvantagesComponent( props ) {

    return(
        <div className='baloa-pro-advantages__container'>
            <div className='baloa-pro-advantages__title'>{props.t('baloaProAdvantages.title')}</div>
            <div className='baloa-pro-advantages__advantages'>
                <div className='baloa-pro-advantages__adv'>
                    <span className='hubicon-award_star baloa-pro-advantages__adv-icon'/>
                    <div className='baloa-pro-advantages__adv-title'>{props.t('baloaProAdvantages.advTitle1')}</div>
                    <div className='baloa-username'>{props.t('baloaProAdvantages.advMsg1')}</div>
                </div>
                <div className='baloa-pro-advantages__adv'>
                    <span className='hubicon-video_camera_front baloa-pro-advantages__adv-icon'/>
                    <div className='baloa-pro-advantages__adv-title'>{props.t('baloaProAdvantages.advTitle2')}</div>
                    <div className='baloa-username'>{props.t('baloaProAdvantages.advMsg2')}</div>
                </div>
                <div className='baloa-pro-advantages__adv'>
                    <span className='hubicon-video_library baloa-pro-advantages__adv-icon'/>
                    <div className='baloa-pro-advantages__adv-title'>{props.t('baloaProAdvantages.advTitle3')}</div>
                    <div className='baloa-username'>{props.t('baloaProAdvantages.advMsg3')}</div>
                </div>
                <div className='baloa-pro-advantages__adv'>
                    <span className='hubicon-bar_chart_4_bars baloa-pro-advantages__adv-icon'/>
                    <div className='baloa-pro-advantages__adv-title'>{props.t('baloaProAdvantages.advTitle4')}</div>
                    <div className='baloa-username'>{props.t('baloaProAdvantages.advMsg4')}</div>
                </div>
                <div className='baloa-pro-advantages__adv'>
                    <span className='hubicon-preview baloa-pro-advantages__adv-icon'/>
                    <div className='baloa-pro-advantages__adv-title'>{props.t('baloaProAdvantages.advTitle5')}</div>
                    <div className='baloa-username'>{props.t('baloaProAdvantages.advMsg5')}</div>
                </div>
                <div className='baloa-pro-advantages__adv'>
                    <span className='hubicon-live_tv baloa-pro-advantages__adv-icon'/>
                    <div className='baloa-pro-advantages__adv-title'>{props.t('baloaProAdvantages.advTitle6')}</div>
                    <div className="baloa-username" dangerouslySetInnerHTML={{
                        __html: props.t('baloaProAdvantages.advMsg6')
                    }}></div>
                </div>
                <div className='baloa-pro-advantages__adv'>
                    <span class="iconbal-Balores baloa-pro-advantages__adv-icon">
                        <span class="path1"></span>
                        <span class="path2"></span>
                        <span class="path3"></span>
                    </span>
                    <div className='baloa-pro-advantages__adv-title'>{props.t('baloaProAdvantages.advTitle7')}</div>
                    <div className='baloa-username'>{props.t('baloaProAdvantages.advMsg7')}</div>
                </div>
                <div className='baloa-pro-advantages__adv'>
                    <span className='hubicon-share_reviews baloa-pro-advantages__adv-icon'/>
                    <div className='baloa-pro-advantages__adv-title'>{props.t('baloaProAdvantages.advTitle8')}</div>
                    <div className='baloa-username'>{props.t('baloaProAdvantages.advMsg8')}</div>
                </div>
                <div className='baloa-pro-advantages__adv-clear'></div>
                <div className='baloa-pro-advantages__adv'>
                    <span className='hubicon-link baloa-pro-advantages__adv-icon'/>
                    <div className='baloa-pro-advantages__adv-title'>{props.t('baloaProAdvantages.advTitle9')}</div>
                    <div className='baloa-username'>{props.t('baloaProAdvantages.advMsg9')}</div>
                </div>
                <div className='baloa-pro-advantages__adv'>
                    <span className='hubicon-casino baloa-pro-advantages__adv-icon'/>
                    <div className='baloa-pro-advantages__adv-title'>{props.t('baloaProAdvantages.advTitle10')}</div>
                    <div className='baloa-username'>{props.t('baloaProAdvantages.advMsg10')}</div>
                </div>
                <div className='baloa-pro-advantages__adv-clear'></div>
            </div>
            <div className='baloa-pro-advantages__baloa-play-conditions'>
            <a target={'_blank'} href={'https://www.notion.so/baloa/T-rminos-y-condiciones-Baloa-Play-e5df83a2bca04815bcca5430281c1fd8'}>{props.t('baloaProAdvantages.baloaPlayConditions')}</a>
            </div>
        </div>
    )
}

export default withTranslation('baloapro')(BaloaProAdvantagesComponent);
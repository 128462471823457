import React from 'react';

//Components
import Modal2Component from 'shared/components/modal2/Modal2Component';


import './TournamentPhasesAddAssociateMatchToSanctionModalComponent.styles.css';

// Moment
import * as moment from 'moment';

// Locale
import 'moment/locale/en-au';
import 'moment/locale/es';

//i18
import { withTranslation } from 'react-i18next';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

function TournamentPhasesAddAssociateMatchToSanctionModalComponent(props) {

    return (
        <Modal2Component
            isOpen={props.isOpen}
            onClose={props.onClose}
            title={props.t('options.tournaments.pages.newTournament.tabs.tabPunctuationAndFairPlay.addAssociateMatchModal.title')}
            className='phases-add-associate-match-modal'
        >
            <div className={`league-tournament-report-dates__date-by-group `}>
                {props.phase_matches_by_team.length > 0 && props.phase_matches_by_team?.map((match) => (
                    <div key={match.id} className={"league-tournament-report-dates__match-detail-container_margin"} onClick={() => props.handleOnSelectMatch(match)} >
                        <div className="league-tournament-report-dates__match-info-container">
                            {match.state === "Finalized" ?
                                <div className="league-tournament-report-dates__teams-result-container">
                                    <div className="baloa-table finalized">{props.t('league:ended')}</div>
                                </div>
                                :match.match_date ?
                                    <div className="league-tournament-report-dates__teams-result-container">
                                        <span className='hubicon-finish_date' />
                                        <div className="baloa-table">{moment(match.match_date).format("ddd DD MMM YYYY")}</div> -
                                        <div className="baloa-table">{moment(match.match_time, ["HH.mm"]).format("hh:mm A")}</div>
                                    </div>
                                    :
                                    <div className="league-tournament-report-dates__teams-result-container">
                                        <span className='hubicon-finish_date' />
                                        <div className="baloa-table">N/A</div>
                                    </div>
                            }
                            <div className='league-tournament-report-dates__teams-stadium-container'>
                                <span className='hubicon-stadium' />
                                <div className='baloa-table' >
                                    {match.state !== "Not_scheduled"
                                        ? match.field
                                        : ' N/A'
                                    }

                                </div>
                            </div>
                        </div>
                        <div className="league-tournament-report-dates__teams-names-container">
                            <div className="league-tournament-report-dates__teams_local-container">
                                <div className='league-tournament-report-dates__teams-container '>
                                    <div className="baloa-names">{match.local_team_name}</div>
                                    {match.local_team_photo === null
                                        ?
                                        <span className="icon-ball" color={getRandonIconBallColor()} />
                                        :
                                        <img src={match.local_team_photo} alt={`${match.local_team_name} img`} />
                                    }
                                </div>
                            </div>
                            <div className="league-tournament-report-dates-score__teams-container">
                                {match.state === 'Finalized' && <div className="baloa-normal-medium">{match.local_team_score} {`${match.local_team_penalty_score ? `(${match.local_team_penalty_score})` : ''}`}</div>}
                                {match.state === 'Finalized' ? <div className="baloa-normal-medium"> - </div> : <div className="baloa-normal-medium">VS</div>}
                                {match.state === 'Finalized' && <div className="baloa-normal-medium">{match.visitant_team_score} {`${match.visitant_team_penalty_score ? `(${match.visitant_team_penalty_score})` : ''}`}</div>}
                            </div>
                            <div className="league-tournament-report-dates__teams_visitant-container">
                                <div className="league-tournament-report-dates__teams-container">

                                    {match.visitant_team_photo === null
                                        ?
                                        <span className="icon-ball" color={getRandonIconBallColor()} />
                                        :
                                        <img src={match.visitant_team_photo} alt={`${match.visitant_team_name} img`} />
                                    }
                                    <div className="baloa-names">{match.visitant_team_name}</div>
                                </div>
                            </div>
                        </div>
                    </div>                                                                       
                ))}
                {/* props.phase_matches_by_team.length === 0 && props.loading_phase_date_groups && 
                    [...Array(Number(4))].map((e, i) =>
                        <div className='league-tournament-report-dates__skeleton'>
                            <div className='league-tournament-report-dates__skeleton__match'>
                                <Skeleton animation="wave" component={'div'} width='25%' />
                                <Skeleton animation="wave" component={'div'} width='40%' />
                            </div>
                            <div className='league-tournament-report-dates__skeleton__results'>
                                <Skeleton animation="wave" component={'div'} width='30%' />
                                <Skeleton animation="wave" variant='circular' component={'div'} width='30px' height={'30px'} />
                                <Skeleton animation="wave" component={'div'} width='5%' />
                                <Skeleton animation="wave" variant='circular' component={'div'} width='30px' height={'30px'} />
                                <Skeleton animation="wave" component={'div'} width='30%' />
                            </div>
                        </div>
                    ) */
                }
                {/* props.phase_matches_by_team.length > 0 && props.loading_phase_date_groups && 
                    [...Array(Number(2))].map((e, i) =>
                        <div className='league-tournament-report-dates__skeleton'>
                            <div className='league-tournament-report-dates__skeleton__match'>
                                <Skeleton animation="wave" component={'div'} width='25%' />
                                <Skeleton animation="wave" component={'div'} width='40%' />
                            </div>
                            <div className='league-tournament-report-dates__skeleton__results'>
                                <Skeleton animation="wave" component={'div'} width='30%' />
                                <Skeleton animation="wave" variant='circular' component={'div'} width='30px' height={'30px'} />
                                <Skeleton animation="wave" component={'div'} width='5%' />
                                <Skeleton animation="wave" variant='circular' component={'div'} width='30px' height={'30px'} />
                                <Skeleton animation="wave" component={'div'} width='30%' />
                            </div>
                        </div>
                    ) */
                }
                {props.next_phase_matches_by_team && /* !props.loading_phase_date_groups && */
                    <div className='baloa-names view-more' onClick={()=>{props.handleOnViewMoreMatches(props.next_phase_matches_by_team)}}>{props.t('options.tournaments.pages.newTournament.tabs.tabPunctuationAndFairPlay.addAssociateMatchModal.viewMoreLbl')}</div>
                }
            </div>
        </Modal2Component>
    )
}
export default withTranslation('league')(TournamentPhasesAddAssociateMatchToSanctionModalComponent)
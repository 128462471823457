/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import FormComponent from 'shared/components/form/FormComponent';
import SelectFieldCleanComponent from 'shared/components/select-field/SelectFieldCleanComponent';
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import InputFieldMultilineComponent from 'shared/components/input-field-multiline/InputFieldMultilineComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import InputFieldAutocompleteComponent from 'shared/components/input-field-autocomplete/InputFieldAutocompleteComponent';

//material
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

// Styles
import './TournamentFairPlayAddExtraPenaltyModalComponent.styles.css';

// Validators
import { RequiredValidator, OnlyNumbersRequiredValidator } from 'shared/validators/RequiredValidator';
import { DateFormatValidator } from 'shared/validators/DateValidator';

// Moment
import * as moment from 'moment';

// Locale
import 'moment/locale/en-au';
import 'moment/locale/es';

//i18
import { withTranslation } from 'react-i18next';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

function TournamentFairPlayAddExtraPenaltyModalComponent(props){

    
        
    const formRef = React.createRef();

    const [sanctionType, setSanctionType] = React.useState('Team_Sanction');
    const [typeTitle, setTypeTitle] = React.useState(props.t('options.tournaments.pages.newTournament.tabs.tabPunctuationAndFairPlay.addExtraPenaltyModal.teamLbl'));
    const [selectedTeam, setSelectedTeam] = React.useState("");
    const [teamMembers, setTeamMembers] = React.useState([]);
    const [selectedPlayer, setSelectedPlayer] = React.useState("");

    React.useEffect(() => {
        setTeamMembers(props.team_members);
        if(props.penalty_to_edit && props.penalty_to_edit?.sanction_type === "Member_Sanction" && props.penalty_to_edit?.tournament_team_player_id){
            setSelectedPlayer(props.team_members.find(member => member.username === props.penalty_to_edit?.member_username));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.team_members]);

    React.useEffect(() => {
        if(props.penalty_to_edit){
            setSanctionType(props.penalty_to_edit.sanction_type);
            if(props.penalty_to_edit?.tournament_team_id && selectedTeam === ""){
                
                let selected_team = props.tournament_active_phase_teams.find(team => team.team === props.penalty_to_edit?.team);
                setSelectedTeam(selected_team);
                if(props.penalty_to_edit?.sanction_type === "Member_Sanction"){
                    props.loadTeamPlayers(selected_team.tournament_team_id);
                }                
                if(props.penalty_to_edit?.associated_tournament_match_id){
                    setMatchAssoc(true);
                    props.handleOnAddAssocMatch( selected_team.tournament_team_id, false);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.penalty_to_edit]);

    React.useEffect(() => {
        if(props.penalty_to_edit && props.penalty_to_edit?.associated_tournament_match_id){
            props.setMatch(props.phase_matches_by_team.find(match => match.id === props.penalty_to_edit?.associated_tournament_match_id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.phase_matches_by_team]);
    
    const initialValues={
        sanction_type: props.penalty_to_edit? props.penalty_to_edit.sanction_type : sanctionType,
        sanction_date: props.penalty_to_edit? props.penalty_to_edit.sanction_date : "",
        sanction_matches: props.penalty_to_edit? props.penalty_to_edit.sanction_matches : "",
        sanction_points: props.penalty_to_edit? props.penalty_to_edit.sanction_points : "",
        description: props.penalty_to_edit? props.penalty_to_edit.description : "",

    }

    const [matchAssoc, setMatchAssoc] = React.useState(false);

    const changeMatchAssoc = (event) => {
        setMatchAssoc(event.target.checked);
        if(event.target.checked){
            props.handleOnAddAssocMatch( selectedTeam.tournament_team_id, true);
        }
    };

    const sanction_types = [
        {text: props.t('options.tournaments.pages.newTournament.tabs.tabPunctuationAndFairPlay.addExtraPenaltyModal.teamPenaltyOptionLbl'), value: 'Team_Sanction'}, 
        {text: props.t('options.tournaments.pages.newTournament.tabs.tabPunctuationAndFairPlay.addExtraPenaltyModal.memberPenaltyOptionLbl'), value: 'Member_Sanction'}
    ];

    const handleOnPenaltyTypeChange= ( value ) => {
        if(value && value !== sanctionType ){
            setSanctionType( value );
            setSelectedTeam("");
            setSelectedPlayer("");
            if(value === 'Team_Sanction'){
                setTypeTitle(props.t('options.tournaments.pages.newTournament.tabs.tabPunctuationAndFairPlay.addExtraPenaltyModal.teamLbl'));
            }else{
                setTypeTitle(props.t('options.tournaments.pages.newTournament.tabs.tabPunctuationAndFairPlay.addExtraPenaltyModal.teamMemberLbl'));
            }
        }
    }

    const handleOnChangeTeam = ( v ) => {
        if(v){
            setSelectedTeam(v);
            if(sanctionType === 'Member_Sanction'){
                props.loadTeamPlayers(v.tournament_team_id);
            }            
        }else{
            setSelectedTeam("");
            setSelectedPlayer("");
            setMatchAssoc(false);
            props.reset_players();
        }        
    }

    const handleOnChangePlayer = ( v ) => {
        if(v){
            setSelectedPlayer(v);
        }else{
            setSelectedPlayer("");
        }        
    }

    const create_sanction = (values) => {
        let sanction = {};

        if(sanctionType === 'Team_Sanction'){
            sanction = {
                sanction_type: values.sanction_type,
                team_object: selectedTeam? selectedTeam.tournament_team_id: "",
                //sanction_matches: 0,
                sanction_points: values.sanction_points,
                sanction_date: values.sanction_date,
                description: values.description,
                //associated_tournament_match: props.sanction_associate_match
            }
        }

        if(sanctionType === 'Member_Sanction'){
            sanction = {
                sanction_type: values.sanction_type,
                team_object: selectedTeam? selectedTeam.tournament_team_id: "",
                member_object: selectedPlayer? selectedPlayer.id : "",
                sanction_matches: values.sanction_matches? values.sanction_matches : 0,
                //sanction_points: 0,
                sanction_date: values.sanction_date,
                description: values.description,
                
            }

        }
        if(matchAssoc){
            sanction.associated_tournament_match = props.sanction_associate_match.id
        }
        props.handleOnAdd(sanction);
        closeModal();
    }

    const edit_sanction = (values) => {
        let sanction = {};

        if(sanctionType === 'Team_Sanction'){
            sanction = {
                sanction_type: values.sanction_type,
                team_object: selectedTeam? selectedTeam.tournament_team_id: "",
                sanction_matches: 0,
                member_object: null,
                sanction_points: values.sanction_points,
                sanction_date: values.sanction_date,
                description: values.description,
                //associated_tournament_match: props.sanction_associate_match
            }
        }

        if(sanctionType === 'Member_Sanction'){
            sanction = {
                sanction_type: values.sanction_type,
                team_object: selectedTeam? selectedTeam.tournament_team_id: "",
                member_object: selectedPlayer? selectedPlayer.id : "",
                sanction_matches: values.sanction_matches? values.sanction_matches : 0,
                sanction_points: 0,
                sanction_date: values.sanction_date,
                description: values.description,
                
            }

        }
        if(matchAssoc){
            sanction.associated_tournament_match = props.sanction_associate_match.id
        }else{
            sanction.associated_tournament_match = null
        }

        props.handleOnEdit(props.penalty_to_edit?.id, sanction);
        closeModal();
    }

    const closeModal = () => {
        setSelectedTeam("");
        setSelectedPlayer("");
        setMatchAssoc(false);
        setSanctionType('Team_Sanction');
        props.onClose();
    }

    return(
        <Modal2Component
            isOpen={props.isOpen}
            onClose={()=>{closeModal()}}
            title={props.penalty_to_edit? props.t('options.tournaments.pages.newTournament.tabs.tabPunctuationAndFairPlay.addExtraPenaltyModal.editTitle') : props.t('options.tournaments.pages.newTournament.tabs.tabPunctuationAndFairPlay.addExtraPenaltyModal.title')}
            className='fair-play-add-extra-penalty-modal'
        >
            <FormComponent
                formInitialValues={initialValues} 
                onSubmit={( values, actions ) => {
                    if(props.penalty_to_edit){
                        edit_sanction(values);  
                    }else{
                        create_sanction(values);
                    }                    
                }}
                innerRef={formRef}
                className={"scroll"}
            >
                <div className='baloa-table-column'>{props.t('options.tournaments.pages.newTournament.tabs.tabPunctuationAndFairPlay.addExtraPenaltyModal.message')}</div>
                <SelectFieldCleanComponent
                    field_name={'sanction_type'}
                    label={props.t('options.tournaments.pages.newTournament.tabs.tabPunctuationAndFairPlay.addExtraPenaltyModal.typeLbl')}
                    className=""
                    validationFunction={RequiredValidator}
                    disabled={false}
                    resetError={() => { }}
                    error={{ sanction_type: '' }}
                    message=""
                    placeholder=""
                    options={sanction_types}
                    onChange={ (value) => { handleOnPenaltyTypeChange(value)} }
                    defaultValue={sanctionType}
                />
                <div className='baloa-table-column'>{typeTitle}</div>
                <InputFieldAutocompleteComponent
                    key={'team'}
                    name={ "team" }
                    options={ props.tournament_active_phase_teams }
                    noOptionsText={ props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScorers.noOpt') }
                    optionLabelField={ "teams" }
                    optionSelectedField={ "tournament_team_id" }
                    className=""
                    //label={}
                    placeholder={props.t('options.tournaments.pages.newTournament.tabs.tabPunctuationAndFairPlay.addExtraPenaltyModal.teamPlaceholder')}
                    onChange={ ( v ) => { handleOnChangeTeam( v )} }
                    value = { selectedTeam }
                    freeSolo={true}
                />
                {sanctionType === 'Member_Sanction' &&
                    <React.Fragment>
                        <div className='baloa-table-column'>{props.t('options.tournaments.pages.newTournament.tabs.tabPunctuationAndFairPlay.addExtraPenaltyModal.memberLbl')}</div>
                        <InputFieldAutocompleteComponent
                            key={'member'}
                            name={ "member" }
                            options={ teamMembers }
                            noOptionsText={ props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScorers.noOpt') }
                            optionLabelField={ "members" }
                            optionSelectedField={ "username" }
                            className=""
                            //label={}
                            placeholder={props.t('options.tournaments.pages.newTournament.tabs.tabPunctuationAndFairPlay.addExtraPenaltyModal.memberPlaceholder')}
                            onChange={ ( v ) => { handleOnChangePlayer( v )} }
                            value = { selectedPlayer }
                            freeSolo={true}
                        />
                    </React.Fragment>
                }
                {sanctionType === 'Member_Sanction' &&
                    <InputFieldComponent
                        label={props.t('options.tournaments.pages.newTournament.tabs.tabPunctuationAndFairPlay.addExtraPenaltyModal.matchPenaltyLbl')}
                        input_id=""
                        field_name="sanction_matches"
                        validationFunction={sanctionType === 'Member_Sanction'? OnlyNumbersRequiredValidator : ()=>{}}
                        disabled={false}
                        input_type="text"
                        resetError={() => { }}
                        error={{sanction_matches: ''}}
                        message=""
                        onChange={() => { }}
                        className={'fair-play-add-extra-penalty-modal__short-input'}
                        defaultValue={props.penalty_to_edit? props.penalty_to_edit.sanction_matches : ''}
                    />
                }
                <InputFieldComponent
                    label={props.t('options.tournaments.pages.newTournament.tabs.tabPunctuationAndFairPlay.addExtraPenaltyModal.dateLbl')}
                    input_id=""
                    field_name="sanction_date"
                    validationFunction={DateFormatValidator}
                    disabled={false}
                    input_type="date"
                    resetError={() => { }}
                    error={{sanction_date: ''}}
                    message=""
                    onChange={() => { }}
                    className={'fair-play-add-extra-penalty-modal__short-input'}
                    defaultValue={props.penalty_to_edit? props.penalty_to_edit.sanction_date : ''}
                />
                {sanctionType === 'Team_Sanction' &&
                    <InputFieldComponent
                        label={props.t('options.tournaments.pages.newTournament.tabs.tabPunctuationAndFairPlay.addExtraPenaltyModal.pointsPenaltyLbl')}
                        input_id=""
                        field_name="sanction_points"
                        validationFunction={OnlyNumbersRequiredValidator}
                        disabled={false}
                        input_type="text"
                        resetError={() => { }}
                        error={{sanction_points: ''}}
                        message=""
                        onChange={() => { }}
                        className={'fair-play-add-extra-penalty-modal__short-input'}
                        defaultValue={props.penalty_to_edit? props.penalty_to_edit.sanction_points : ''}
                    />
                }

                <InputFieldMultilineComponent
                    label={props.t('options.tournaments.pages.newTournament.tabs.tabPunctuationAndFairPlay.addExtraPenaltyModal.sanctionReasonLbl')}
                    input_id="description" 
                    field_name="description"
                    validationFunction={ RequiredValidator }
                    disabled={false}
                    className=""
                    input_type="text"
                    resetError={ () => {} }
                    error={ {description: ''} }
                    message=""                        
                    //placeholder={props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.placeholderRules')}
                    rows={ 7 }
                    defaultValue={props.penalty_to_edit? props.penalty_to_edit.description : ""}
                />
                <FormControlLabel
                    label={<div className='baloa-table-column'>{props.t('options.tournaments.pages.newTournament.tabs.tabPunctuationAndFairPlay.addExtraPenaltyModal.matchAssociateLbl')}</div>}
                    control={
                        <Switch
                            checked={ matchAssoc }
                            onChange={ changeMatchAssoc }
                            name="match_asocc"
                            inputProps={{ 'aria-label': 'controlled' }}
                            color="secondary"
                        />
                    }
                    labelPlacement="start"
                    className={'fair-play-add-extra-penalty-modal__switch'}
                    disabled={!selectedTeam}
                />
                {props.sanction_associate_match && matchAssoc &&
                    <div className='fair-play-add-extra-penalty-modal__selected-match'>
                        <div key={props.sanction_associate_match.id} className={"league-tournament-report-dates__match-detail-container_margin"} >
                            <div className="league-tournament-report-dates__match-info-container">
                                {props.sanction_associate_match.state === "Finalized" ?
                                    <div className="league-tournament-report-dates__teams-result-container">
                                        <div className="baloa-table finalized">{props.t('league:ended')}</div>
                                    </div>
                                    :props.sanction_associate_match.match_date ?
                                        <div className="league-tournament-report-dates__teams-result-container">
                                            <span className='hubicon-finish_date' />
                                            <div className="baloa-table">{moment(props.sanction_associate_match.match_date).format("ddd DD MMM YYYY")}</div> -
                                            <div className="baloa-table">{moment(props.sanction_associate_match.match_time, ["HH.mm"]).format("hh:mm A")}</div>
                                        </div>
                                        :
                                        <div className="league-tournament-report-dates__teams-result-container">
                                            <span className='hubicon-finish_date' />
                                            <div className="baloa-table">N/A</div>
                                        </div>
                                }
                                <div className='league-tournament-report-dates__teams-stadium-container'>
                                    <span className='hubicon-stadium' />
                                    <div className='baloa-table' >
                                        {props.sanction_associate_match.state !== "Not_scheduled"
                                            ? props.sanction_associate_match.field
                                            : ' N/A'
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="league-tournament-report-dates__teams-names-container">
                                <div className="league-tournament-report-dates__teams_local-container">
                                    <div className='league-tournament-report-dates__teams-container '>
                                        <div className="baloa-names">{props.sanction_associate_match.local_team_name}</div>
                                        {props.sanction_associate_match.local_team_photo === null
                                            ?
                                            <span className="icon-ball" color={getRandonIconBallColor()} />
                                            :
                                            <img src={props.sanction_associate_match.local_team_photo} alt={`${props.sanction_associate_match.local_team_name} img`} />
                                        }
                                    </div>
                                </div>
                                <div className="league-tournament-report-dates-score__teams-container">
                                    {props.sanction_associate_match.state === 'Finalized' && <div className="baloa-normal-medium">{props.sanction_associate_match.local_team_score} {`${props.sanction_associate_match.local_team_penalty_score ? `(${props.sanction_associate_match.local_team_penalty_score})` : ''}`}</div>}
                                    {props.sanction_associate_match.state === 'Finalized' ? <div className="baloa-normal-medium"> - </div> : <div className="baloa-normal-medium">VS</div>}
                                    {props.sanction_associate_match.state === 'Finalized' && <div className="baloa-normal-medium">{props.sanction_associate_match.visitant_team_score} {`${props.sanction_associate_match.visitant_team_penalty_score ? `(${props.sanction_associate_match.visitant_team_penalty_score})` : ''}`}</div>}
                                </div>
                                <div className="league-tournament-report-dates__teams_visitant-container">
                                    <div className="league-tournament-report-dates__teams-container">

                                        {props.sanction_associate_match.visitant_team_photo === null
                                            ?
                                            <span className="icon-ball" color={getRandonIconBallColor()} />
                                            :
                                            <img src={props.sanction_associate_match.visitant_team_photo} alt={`${props.sanction_associate_match.visitant_team_name} img`} />
                                        }
                                        <div className="baloa-names">{props.sanction_associate_match.visitant_team_name}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </FormComponent>
            
            <div className='fair-play-add-extra-penalty-modal__buttons'>
                <PrimaryButtonComponent
                    type={'submit'}
                    onClick={ ()=>{formRef.current.handleSubmit();} }
                    className={'small'}
                >
                    <span>{props.penalty_to_edit? props.t('options.tournaments.pages.newTournament.tabs.tabPunctuationAndFairPlay.addExtraPenaltyModal.saveBtnLbl') : props.t('options.tournaments.pages.newTournament.tabs.tabPunctuationAndFairPlay.addExtraPenaltyModal.addBtnLbl')}</span>
                </PrimaryButtonComponent>
            </div>

        </Modal2Component>
    )
        
}

export default withTranslation('league')(TournamentFairPlayAddExtraPenaltyModalComponent)
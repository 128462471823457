import React, { useState} from 'react';
import TounamentPhasesConfigSetComponent from 'app/leagues/components/tournament-phases-config-set/TounamentPhasesConfigSetComponent';
import TournamentPhaseTypeComponent from 'app/leagues/components/tournament-phase-type/TournamentPhaseTypeComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
//styles
import './TournamentPhaseEditComponent.styles.css';
//i18
import { withTranslation } from 'react-i18next';

function TournamentPhaseEditComponent(props) {
    const [ submitForm, setSubmitForm ] = useState(false);
    //const VALUE_PHASE = "options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1."
    /* const PHASES_NAME_VALUES = [
        {
            value: "labelPhaseOfGroups",
            content: props.t(VALUE_PHASE + "labelPhaseOfGroups"),
            phase_type: "Groups",
        },
        {
            value: "labelPhaseTriangular",
            content: props.t(VALUE_PHASE + "labelPhaseTriangular"),
            phase_type: "Groups",
        },
        {
            value: "labelPhaseHomeRouns",
            content: props.t(VALUE_PHASE + "labelPhaseHomeRouns"),
            phase_type: "Groups",
        },
        {
            value: "labelPhasePentagonal",
            content: props.t(VALUE_PHASE + "labelPhasePentagonal"),
            phase_type: "Groups",
        },
        {
            value: "labelPhaseHexagonal",
            content: props.t(VALUE_PHASE + "labelPhaseHexagonal"),
            phase_type: "Groups",
        },
        {
            value: "labelPhaseRoundOf32",
            content: props.t(VALUE_PHASE + "labelPhaseRoundOf32"),
            phase_type: "Knockout",
        },
        {
            value: "labelPhaseRoundOf16",
            content: props.t(VALUE_PHASE + "labelPhaseRoundOf16"),
            phase_type: "Knockout",
        },
        {
            value: "labelPhaseQuarterFinal",
            content: props.t(VALUE_PHASE + "labelPhaseQuarterFinal"),
            phase_type: "Knockout",
        },
        {
            value: "labelPhaseSemiFinal",
            content: props.t(VALUE_PHASE + "labelPhaseSemiFinal"),
            phase_type: "Knockout",
        },
        {
            value: "labelPhaseFinal",
            content: props.t(VALUE_PHASE + "labelPhaseFinal"),
            phase_type: "Knockout",
        },
        {
            value: "labelPhaseLeague",
            content: props.t(VALUE_PHASE + "labelPhaseLeague"),
            phase_type: "Free",
        },
    ]; */
    
    const [phaseName, setPhaseName] = useState(''); //eslint-disable-line
    const [groupsCount, setGroupsCount] = useState([]); //eslint-disable-line
    const [classifiedGroup, setClasifiedGroup] = useState(props.selected_phase?.classified_count ?? []); //eslint-disable-line

    const handlePhaseNameSelected = (phase) => {
        setPhaseName(phase?.content);
    };

    const handleNumberGroup = (number_group) => {
        setGroupsCount(number_group);
    };

    const handleClassifiedGroup = (number_classified_group) => {
        setClasifiedGroup(number_classified_group)
    }

    const handleOnSelectTournamentType = (type) => {
        console.log('tyupe',type);
        /*if (type == 'Knockout') checkTeamsNumber();*/
    };

    return (
        <React.Fragment>
            {/* Back button arrow */}
            <div className="tournament-phases__phases__arrow">
                <span className="icon-arrow-left" onClick={() => props.handleReturnPhaseComponent(true)} />
                <p className="baloa-subtitle-1 color-text">{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelEditPhase')}</p>
            </div>
            <div className="tournament-phases_phases_edit_container">
                {/* The last component was TournamentPhasesSummary*/}
                <TournamentPhaseTypeComponent
                    onSelectTournamentType={(type) => { handleOnSelectTournamentType(type) }}
                    pageRoles={props.pageRoles}
                    className={ 'tournament-type' }
                    phaseToEdit={props.selected_phase}
                    edit_tournament={props.edit_tournament}
                />
                <TounamentPhasesConfigSetComponent
                    onResetError={(data) => { props.onResetError(data) }}
                    errors={props.errorsNewGroupTournament}
                    league_page={props.league_page ?? ''}
                    clasified_caption={props.selected_phase?.phase_type === 'Knockout'
                        ? props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.labelKnockoutClasifiedByKey')
                        : props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.labelGroupQualified')
                    }
                    tournament_teams={props.phase_teams}//phase_teams
                    phase_type={props.selected_phase?.phase_type}
                    tournament_phases={props.tournament_phases}
                    edit_tournament={props.edit_tournament}
                    teams_count={props.tournament_phases.length > 1
                        ? (props.tournament_phases[props.tournament_phases.length - 2].classified_count * props.tournament_phases[props.tournament_phases.length - 2].groups_count) + props.tournament_phases[props.tournament_phases.length - 2].best_third_teams
                        : props.tournament_teams
                            ? props.tournament_teams.length
                            : 0
                    }
                    phase_name_values={props.phase_name_values}
                    tournament_criteria_tie_breaking={props.tournament_criteria_tie_breaking}
                    tournament_new_order_criteria_tie_breaking={props.tournament_new_order_criteria_tie_breaking}
                    selectedPhase={props.selected_phase}
                    edit_phase={true}
                    statusRememberPhase={true} //Prop to remember the status of the phases selected to edit
                    disabled_group_number_field={props.phase_teams && props.phase_teams.length < 2} // This validation disables or enables the group number field if there are more than two extemporaneous teams.
                    handle_phase_name_selected={handlePhaseNameSelected}
                    handle_number_group={handleNumberGroup}
                    handle_classified_group={handleClassifiedGroup}
                    handleEditPhase={props.handleEditPhase}
                    submitForm={submitForm}
                    resetButton={ () => {setSubmitForm(false)} }
                    handleOnCreateFairPlaySettings={props.handleOnCreateFairPlaySettings}
                    handleOnEditFairPlaySettings={props.handleOnEditFairPlaySettings}
                    handleOnChangeSetting={props.handleOnChangeSetting}
                    selected_fair_play_setting={props.selected_fair_play_setting}
                />
                <div className='tournament-phases_phases_edit_button'>
                    <PrimaryButtonComponent
                        type="button"
                        disabled={false}
                        onClick={() => setSubmitForm(true)}
                        className="tournament-phases-container__btn small">
                        <span>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelEditSaveBasicDataBtn')}</span>
                    </PrimaryButtonComponent>
                </div>
            </div>
        </React.Fragment>
    );

}

export default withTranslation('league')(TournamentPhaseEditComponent);
import React from "react";

//Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

import "./BaloaPlaySharePurchasedMatchLinkModalComponent.styles.css";

//i18
import { withTranslation } from 'react-i18next';

function BaloaPlaySharePurchasedMatchLinkModalComponent(props) {
    return (
        <ModalComponent
            isOpen={props.isOpen}
            onClose={props.onClose}            
            className='share-purchased-match-link-modal__container'
        >
            <div className={"share-purchased-match-link-modal__content"}>
                <span className='hubicon-groups1' />                
                <div className="baloa-subtitle-2">{props.t('sharePurchasedMatchLinkModal.title')}</div>
                <div className="baloa-names">{props.t('sharePurchasedMatchLinkModal.message')}</div>
            </div>
            <div className="share-purchased-match-link-modal__buttons">
                <div className="share-purchased-match-link-modal__link baloa-table" >{props.matchUrl}</div>
                <PrimaryButtonComponent
                    input_type=''
                    className='share-purchased-match-link-modal__buttons-button'
                    onClick={ ()=>{ props.handleOnCopyShareLink() }}
                >
                    <span>{props.t('sharePurchasedMatchLinkModal.shareLinkBtnLbl')}</span>
                    <span className="icon icon-copy-86"></span>
                </PrimaryButtonComponent>
            </div>
        </ModalComponent>
    )
}

export default withTranslation('baloaplay')(BaloaPlaySharePurchasedMatchLinkModalComponent);
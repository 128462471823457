import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, PDFViewer, Font  } from '@react-pdf/renderer';

//Components
import Modal2Component from 'shared/components/modal2/Modal2Component';

// Moment
import * as moment from 'moment';
// Locale
import 'moment/locale/en-au';
import 'moment/locale/es';

//IsMobile
import { isMobile } from 'react-device-detect';

//assets
import logoBaloa from 'assets/logos/baloa-logo.png';
import yellowCard from 'assets/icons/yellow_card.png';
import redCard from 'assets/icons/red_card.png';
import blueCard from 'assets/icons/blue_card.png';
import blackBall from 'assets/icons/black-ball.png';
import changeIn from 'assets/icons/change_in.png';
import changeOut from 'assets/icons/change_out.png';
import doubleCard from 'assets/icons/double_card.png';
import escudoDefault from 'assets/icons/escudo_default.png';


//Fonts
import robotoRegular from "assets/fonts/Roboto-Regular.ttf";
import robotoMedium from "assets/fonts/Roboto-Medium.ttf";
//import robotoRegular from "https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Me5Q.ttf";
import libreBaskervilleRegular from "assets/fonts/LibreBaskerville-Regular.ttf";
import openSansRegular from "assets/fonts/OpenSans-Regular.ttf";
import poppinsRegular from "assets/fonts/Poppins-Regular.ttf";
import quicksandRegular from "assets/fonts/Quicksand-Regular.ttf";

//Styles
import './TournamentMatchReportPdfModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function TournamentMatchReportPdfModalComponent(props) {
    //Register available fonts
    Font.register({ family: 'Roboto', fonts: [ {src: robotoRegular}, {src: robotoMedium, fontWeight: '500'} ] } );
    //Font.register({ family: 'Roboto', fonts: [ {src: "https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Me5Q.ttf"} ] } );
    //Font.register({ family: 'Roboto', fonts: [ {src: "https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Me5Q.ttf"}, {src: "https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fBBc4AMP6lQ.woff2", fontWeight: 500}, ] } );

    Font.register({ family: 'LibreBaskerville', fonts: [ {src: libreBaskervilleRegular} ] } );
    Font.register({ family: 'OpenSans', fonts: [ {src: openSansRegular} ] } );
    Font.register({ family: 'Poppins', fonts: [ {src: poppinsRegular} ] } );
    Font.register({ family: 'Quicksand', fonts: [ {src: quicksandRegular} ] } );

    const [localeEvents, setLocaleEvents] = React.useState([]);
    const [visitorEvents, setVisitorEvents] = React.useState([]);

    React.useEffect(() => { 
        loadEvents(props.matchData.events, props.matchData.local_team, props.matchData.visitant_team);        
    }, [props.matchData]);// eslint-disable-line

    const stylesReport = StyleSheet.create({
        page: { paddingTop: 16, paddingBottom: 16, paddingLeft: 28, paddingRight: 28 },
        section: { width: '318pt', height: '176.25pt', position: 'relative', marginBottom: 20},
        title: {position: 'absolute', left: 100, fontSize: '14px', fontFamily: 'Roboto', color: '#2A302A', fontWeight: 'medium'},
        team: {fontSize: '14px', fontFamily: 'Roboto', color: '#2A302A', fontWeight: 'medium', marginTop: '8px'},
        matchday: {fontSize: '13px', fontFamily: 'Roboto', color: '#2A302A', fontWeight: 'medium'},
        date: {position: 'relative', fontFamily: 'Roboto', fontSize: '12px', color: '#4D5451', marginTop: '6px'},
        score: {position: 'relative', fontFamily: 'Roboto', fontSize: '22px', color: '#2A302A', marginTop: '8px'},
        subtitle: {position: 'relative', fontFamily: 'Roboto', fontSize: '12px', fontWeight: 'medium', },
        subtitle2: {position: 'relative', fontFamily: 'Roboto', fontSize: '12px', fontWeight: 'medium', marginTop: '16px', textAlign: 'center'},
        subtitle3: {position: 'relative', fontFamily: 'Roboto', fontSize: '12px', fontWeight: 'medium', color: '#2A302A', paddingTop: '8px', paddingBottom: '8px'},
        normal: {fontFamily: 'Roboto', fontSize: '12px', fontWeight: 'normal'},
        normal2: {fontFamily: 'Roboto', fontSize: '12px', fontWeight: 'normal', marginTop: '6px'},
        squaresection: {border: '1px solid #E7E7E7', borderRadius: '4px', width: '48%'},
        emptytext: {color: '#71847B', fontSize: '10px', fontFamily: 'Roboto', padding: '10px', textAlign: 'center', fontWeight: 'medium'},
        contentinfo: {padding: '12px', },
        iconimages: { width: '12px', height: '12px', borderRadius: '50%'},
        cardimages: { width: '9px', height: '12px', },
        assistant: {position: 'relative', fontFamily: 'Roboto', fontSize: '10px', color: '#4D5451', marginLeft: '16px' },
        alignmentSubTitle: { fontSize: '11px', color: '#4D5451'},
        alignmentSubTitle2: { fontSize: '11px', color: '#4D5451', marginTop: '16px'},
        alignment: {fontFamily: 'Roboto', fontSize: '12px', color: '#2A302A', marginTop: '12px', fontWeight: 'medium'},
    });

    /* const getImageData = (imagePath, imageB64Data)=> {
        let imageUrlArray = imagePath?.split(".");
        let imageExtension = imageUrlArray[imageUrlArray?.length -1];
        return 'data:image/'+ imageExtension + ';base64,'+ imageB64Data;
    } */

    function loadEvents(events ,localTeam, visitorTeam){
        if(events?.length > 0){
            let locale = events?.filter(ev => ev.team === localTeam);
            setLocaleEvents( locale );
            let visitore = events?.filter(ev => ev.team === visitorTeam);
            setVisitorEvents(visitore);
        }else {
            setLocaleEvents([]);
            setVisitorEvents([]);
        }
        return true;
    }
    
    const Reporte = () => {
        return (            
            <PDFViewer style={{width: '100%', height: 600}}>
                <Document style={{display: 'flex', flexFlow: 'column'}}>
                    <Page size="LETTER" style={ stylesReport.page} >
                        <View /* style={props.orientation === 'horizontal'? stylesHorizontal.section : stylesVertical.section} wrap={false} key={i} */ style={{marginBottom: '54px'}}>
                            <Image src={ logoBaloa } 
                                style={ {position: 'absolute', width: '78px', height: '22px'}} 
                            />
                            <Text style={ stylesReport.title }>
                                {props.t('pdfMatchReport.matchReportTitle')}
                            </Text>
                        </View>
                        <View style={{/* marginTop: '54px', */ padding: '12 0' , border: '1px solid #E7E7E7', borderRadius: '8px', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}} >
                            <View style={{alignItems: 'center', width: '35%'}}>
                                <Image src={ props.matchData.local_team_photo? /* getImageData(props.matchData.local_team_photo, props.matchData.local_team_photo_base64) */props.matchData.local_team_photo : escudoDefault} 
                                    style={ {/* position: 'absolute', */ width: '45px', height: '45px', borderRadius: '50%'}} 
                                />                                
                                <Text style={ stylesReport.team }>{props.matchData.local_team_name}</Text>
                            </View>
                           
                            <View style={{alignItems: 'center', width: '30%'}}>                            
                                <Text style={ stylesReport.matchday }>{props.t(`options.tournaments.pages.matchRefereesPayment.${props.matchData.date_match}`)}</Text>
                                <Text style={ stylesReport.date }>{props.matchData?.match_date? moment(props.matchData.match_date).format("DD MMM YYYY") : ''} {props.matchData?.match_time? moment(props.matchData.match_time, ["HH:mm:ss"]).format("hh:mm a") : ''}</Text>
                                <Text style={ stylesReport.score }>{props.matchData.local_team_score} - {props.matchData.visitant_team_score}</Text>
                            </View>

                            <View style={{alignItems: 'center', width: '35%'}}>
                                <Image src={ props.matchData.visitant_team_photo? /* getImageData(props.matchData.visitant_team_photo, props.matchData.visitant_team_photo_base64) */props.matchData.visitant_team_photo : escudoDefault } 
                                    style={ {/* position: 'absolute', */ width: '45px', height: '45px', borderRadius: '50%'}} 
                                />
                                <Text style={ stylesReport.team }>{props.matchData.visitant_team_name}</Text>
                            </View>
                        </View>
                        <View style={{marginTop: '16px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <Text style={ stylesReport.subtitle }>{props.t('pdfMatchReport.tournamentLbl')}</Text>
                            <Image src={ props.matchData.tournament_logo ? /* getImageData(props.matchData.tournament_logo, props.matchData.tournament_logo_base64) */props.matchData.tournament_logo : '' } 
                                style={ { width: '20px', height: '20px', borderRadius: '50%', marginLeft: '15px', marginRight: '8px'}} 
                            />
                            <Text style={ stylesReport.normal }>{props.matchData.tournament_name}</Text>
                        </View>
                        <Text>
                            <Text style={ stylesReport.subtitle }>{props.t('pdfMatchReport.categoryLbl')}</Text>  <Text style={ stylesReport.normal }>{props.matchData.tournament_category}</Text>
                        </Text>
                        <Text>
                            <Text style={ stylesReport.subtitle }>{props.t('pdfMatchReport.fieldLbl')}</Text>  <Text style={ stylesReport.normal }>{props.matchData.match_field?.name? props.matchData.match_field.name : props.matchData.field? props.matchData.field : ''}</Text>
                        </Text>



                        <View  wrap={false}>
                            <Text style={ stylesReport.subtitle2 }>{props.t('pdfMatchReport.lineupsLbl')}</Text>
                            <View style={{marginTop: '10px', display: 'flex', justifyContent: 'space-between', gap: '8px', flexDirection: 'row'}}>
                                <View style={ stylesReport.squaresection  }>
                                    <View style={{display: 'flex', flexDirection: 'row', alignItems: 'center', borderBottom: '1px solid #E7E7E7'}}>
                                        <Image src={props.matchData.local_team_photo? /* getImageData(props.matchData.local_team_photo, props.matchData.local_team_photo_base64) */props.matchData.local_team_photo : escudoDefault } 
                                            style={ {width: '20px', height: '20px', borderRadius: '50%', marginLeft: '8px', marginRight: '8px'}} 
                                        />
                                        <Text style={ stylesReport.subtitle3 }>{props.matchData.local_team_name}</Text>
                                    </View>
                                    <View style={ stylesReport.contentinfo }>
                                        {(props.matchAlignments?.local_team?.players?.length > 0 && props.matchAlignments?.local_team?.total_titular_players > 0 && props.matchAlignments?.local_team?.total_substitute_players > 0 ) ?
                                            <View>
                                                <Text style={ stylesReport.alignmentSubTitle }>{`${props.t('pdfMatchReport.titularLbl')} (${props.matchAlignments?.local_team?.total_titular_players})`}</Text>
                                                {props.matchAlignments?.local_team?.players?.filter(p => p.rol_in_match === 'Titular').map( ply =>
                                                    <Text style={ stylesReport.alignment }>{`${ply.number? ply.number : ''}.${ply.player_name}`}</Text>
                                                )}
                                                <Text style={ stylesReport.alignmentSubTitle2 }>{`${props.t('pdfMatchReport.substituteLbl')} (${props.matchAlignments?.local_team?.total_substitute_players})`}</Text>
                                                {props.matchAlignments?.local_team?.players?.filter(p => p.rol_in_match === 'Substitute').map( ply =>
                                                    <Text style={ stylesReport.alignment }>{`${ply.number? ply.number : ''}.${ply.player_name}`}</Text>
                                                )}
                                            </View>
                                        :
                                            <Text style={ stylesReport.emptytext }>
                                                {props.t('pdfMatchReport.notInfoLbl')}
                                            </Text>
                                        }
                                    </View>
                                </View>
                                <View style={stylesReport.squaresection}>                                    
                                    <View style={{display: 'flex', flexDirection: 'row', alignItems: 'center', borderBottom: '1px solid #E7E7E7'}}>
                                        <Image src={props.matchData.visitant_team_photo? /* getImageData(props.matchData.visitant_team_photo, props.matchData.visitant_team_photo_base64) */props.matchData.visitant_team_photo : escudoDefault } 
                                            style={ {width: '20px', height: '20px', borderRadius: '50%', marginLeft: '8px', marginRight: '8px'}} 
                                        />
                                        <Text style={ stylesReport.subtitle3 }>{props.matchData.visitant_team_name}</Text>
                                    </View>
                                    <View style={ stylesReport.contentinfo }>
                                        {(props.matchAlignments?.visitant_team?.players?.length > 0 && props.matchAlignments?.visitant_team?.total_titular_players > 0 && props.matchAlignments?.visitant_team?.total_substitute_players > 0 )?
                                            <View>
                                                <Text style={ stylesReport.alignmentSubTitle }>{`${props.t('pdfMatchReport.titularLbl')} (${props.matchAlignments?.visitant_team?.total_titular_players})`}</Text>
                                                {props.matchAlignments?.visitant_team?.players?.filter(p => p.rol_in_match === 'Titular').map( ply =>
                                                    <Text style={ stylesReport.alignment }>{`${ply.number? ply.number : ''}.${ply.player_name}`}</Text>
                                                )}
                                                <Text style={ stylesReport.alignmentSubTitle2 }>{`${props.t('pdfMatchReport.substituteLbl')} (${props.matchAlignments?.visitant_team?.total_substitute_players})`}</Text>
                                                {props.matchAlignments?.visitant_team?.players?.filter(p => p.rol_in_match === 'Substitute').map( ply =>
                                                    <Text style={ stylesReport.alignment }>{`${ply.number? ply.number : ''}.${ply.player_name}`}</Text>
                                                )}
                                            </View>
                                        :
                                            <Text style={ stylesReport.emptytext }>
                                                {props.t('pdfMatchReport.notInfoLbl')}
                                            </Text>
                                        }
                                    </View>
                                </View>
                            </View>
                        </View>



                        <View  wrap={false}>
                            <Text style={ stylesReport.subtitle2 }>{props.t('pdfMatchReport.eventsLbl')}</Text>
                            <View style={{marginTop: '10px', display: 'flex', justifyContent: 'space-between', gap: '8px', flexDirection: 'row'}}>
                                <View style={ stylesReport.squaresection  }>
                                    <View style={{display: 'flex', flexDirection: 'row', alignItems: 'center', borderBottom: '1px solid #E7E7E7'}}>
                                        <Image src={props.matchData.local_team_photo? /* getImageData(props.matchData.local_team_photo, props.matchData.local_team_photo_base64) */ props.matchData.local_team_photo : escudoDefault } 
                                            style={ {width: '20px', height: '20px', borderRadius: '50%', marginLeft: '8px', marginRight: '8px'}} 
                                        />
                                        <Text style={ stylesReport.subtitle3 }>{props.matchData.local_team_name}</Text>
                                    </View>
                                    <View style={ stylesReport.contentinfo }>
                                        {localeEvents?.length > 0?
                                            localeEvents.map((ev, index) =>
                                                <View key={index} style={{marginBottom: '16px'}}>
                                                    {ev.event === 'Player_Change' &&
                                                        <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '4px'}}>
                                                            <Text style={ stylesReport.normal }>{ev.minute? `${ev.minute}'` : ''}</Text>
                                                            <View>
                                                                <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '4px'}}>
                                                                    <Image style={ stylesReport.iconimages} src={changeIn}></Image>
                                                                    <Text style={ stylesReport.subtitle }>{ev.player_full_name}</Text>
                                                                </View>
                                                                <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '4px'}}>
                                                                    <Image style={ stylesReport.iconimages} src={changeOut}></Image>
                                                                    <Text style={ stylesReport.subtitle }>{ev.player_two_full_name}</Text>
                                                                </View>
                                                            </View>
                                                        </View>
                                                    }
                                                    {ev.event === 'Goal' &&
                                                        <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '4px'}}>
                                                            <Text style={ stylesReport.normal }>{ev.minute? `${ev.minute}'` : ''}</Text>
                                                            <View>
                                                                <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '4px'}}>
                                                                    <Image style={ stylesReport.iconimages} src={blackBall}></Image>
                                                                    <Text style={ stylesReport.subtitle }>{ev.player_full_name}</Text>
                                                                </View>
                                                                {ev.assistant_full_name &&
                                                                    <Text style={ stylesReport.assistant }>{`${props.t('options.tournaments.pages.matchInfo.labelAssistant')} ${ev.assistant_full_name}`}</Text>
                                                                }
                                                            </View>
                                                        </View>
                                                    }
                                                    {(ev.event === 'Yellow_Card' || ev.event === 'Red_Card' || ev.event === 'Double_Yellow_Card' || ev.event === 'Blue_Card') &&
                                                        <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '4px'}}>
                                                            <Text style={ stylesReport.normal }>{ev.minute? `${ev.minute}'` : ''}</Text>
                                                            <View>
                                                                <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '4px'}}>
                                                                    <Image style={ stylesReport.cardimages} src={ev.event === 'Yellow_Card'? yellowCard : ev.event === 'Red_Card'? redCard : ev.event === 'Double_Yellow_Card'? doubleCard : ev.event === 'Blue_Card'? blueCard : ''}></Image>
                                                                    <Text style={ stylesReport.subtitle }>{ev.player_full_name}</Text>
                                                                </View>                                                            
                                                            </View>
                                                        </View>
                                                    }
                                                </View>
                                                )
                                            :
                                            <Text style={ stylesReport.emptytext }>
                                                {props.t('pdfMatchReport.emptyEvents')}
                                            </Text>
                                        }
                                        
                                    </View>
                                </View>
                                <View style={stylesReport.squaresection}>
                                    <View style={{display: 'flex', flexDirection: 'row', alignItems: 'center', borderBottom: '1px solid #E7E7E7'}}>
                                        <Image src={props.matchData.visitant_team_photo? /* getImageData(props.matchData.visitant_team_photo, props.matchData.visitant_team_photo_base64) */props.matchData.visitant_team_photo : escudoDefault } 
                                            style={ {width: '20px', height: '20px', borderRadius: '50%', marginLeft: '8px', marginRight: '8px'}} 
                                        />
                                        <Text style={ stylesReport.subtitle3 }>{props.matchData.visitant_team_name}</Text>
                                    </View>
                                    <View style={ stylesReport.contentinfo }>
                                        {visitorEvents?.length > 0?
                                            visitorEvents.map((ev, index) =>
                                                <View key={index} style={{marginBottom: '16px'}}>
                                                    {ev.event === 'Player_Change' &&
                                                        <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '4px'}}>
                                                            <Text style={ stylesReport.normal }>{ev.minute? `${ev.minute}'` : ''}</Text>
                                                            <View>
                                                                <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '4px'}}>
                                                                    <Image style={ stylesReport.iconimages} src={changeIn}></Image>
                                                                    <Text style={ stylesReport.subtitle }>{ev.player_full_name}</Text>
                                                                </View>
                                                                <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '4px'}}>
                                                                    <Image style={ stylesReport.iconimages} src={changeOut}></Image>
                                                                    <Text style={ stylesReport.subtitle }>{ev.player_two_full_name}</Text>
                                                                </View>
                                                            </View>
                                                        </View>
                                                    }
                                                    {ev.event === 'Goal' &&
                                                        <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '4px'}}>
                                                            <Text style={ stylesReport.normal }>{ev.minute? `${ev.minute}'` : ''}</Text>
                                                            <View>
                                                                <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '4px'}}>
                                                                    <Image style={ stylesReport.iconimages} src={blackBall}></Image>
                                                                    <Text style={ stylesReport.subtitle }>{ev.player_full_name}</Text>
                                                                </View>
                                                                {ev.assistant_full_name &&
                                                                    <Text style={ stylesReport.assistant }>{`${props.t('options.tournaments.pages.matchInfo.labelAssistant')} ${ev.assistant_full_name}`}</Text>
                                                                }
                                                            </View>
                                                        </View>
                                                    }
                                                    {(ev.event === 'Yellow_Card' || ev.event === 'Red_Card' || ev.event === 'Double_Yellow_Card' || ev.event === 'Blue_Card') &&
                                                        <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '4px'}}>
                                                            <Text style={ stylesReport.normal }>{ev.minute? `${ev.minute}'` : ''}</Text>
                                                            <View>
                                                                <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '4px'}}>
                                                                    <Image style={ stylesReport.cardimages} src={ev.event === 'Yellow_Card'? yellowCard : ev.event === 'Red_Card'? redCard : ev.event === 'Double_Yellow_Card'? doubleCard : ev.event === 'Blue_Card'? blueCard : ''}></Image>
                                                                    <Text style={ stylesReport.subtitle }>{ev.player_full_name}</Text>
                                                                </View>                                                            
                                                            </View>
                                                        </View>
                                                    }
                                                </View>
                                                )
                                            :
                                            <Text style={ stylesReport.emptytext }>
                                                {props.t('pdfMatchReport.emptyEvents')}
                                            </Text>
                                        }
                                    </View>
                                </View>
                            </View>
                        </View>
                        <View style={{marginTop: '30px'}}>
                            <Text style={ stylesReport.subtitle }>{props.t('pdfMatchReport.matchPlayerLbl')}</Text>
                            <Text style={ stylesReport.normal2 }>{props.matchData?.player_match_name? props.matchData.player_match_name : props.t('pdfMatchReport.notInfoLbl')}</Text>
                        </View>
                        <View style={{marginTop: '30px'}}>
                            <Text style={ stylesReport.subtitle }>{props.t('pdfMatchReport.commentsLbl')}</Text>
                            <Text style={ stylesReport.normal2 }>{props.matchData?.comments? props.matchData.comments : props.t('pdfMatchReport.emptyComments')}</Text>
                        </View>

                        <Text style={{marginTop: '30px'}}>
                            <Text style={ stylesReport.subtitle }>{props.t('pdfMatchReport.officialRefereeLbl')}</Text>  <Text style={ stylesReport.normal }>{props.matchData.official_referee_name}</Text>
                        </Text>
                        <Text  style={{marginTop: '15px'}}>
                            <Text style={ stylesReport.subtitle }>{props.t('pdfMatchReport.secondAssistantRefereeLbl')}</Text>  <Text style={ stylesReport.normal }>{props.matchData.second_assistant_referee_name}</Text>
                        </Text>
                        <Text  style={{marginTop: '15px'}}>
                            <Text style={ stylesReport.subtitle }>{props.t('pdfMatchReport.thirdAssistantRefereeLbl')}</Text>  <Text style={ stylesReport.normal }>{props.matchData.third_assistant_referee_name}</Text>
                        </Text>
                        <Text  style={{marginTop: '15px'}}>
                            <Text style={ stylesReport.subtitle }>{props.t('pdfMatchReport.fourthOfficialRefereeLbl')}</Text>  <Text style={ stylesReport.normal }>{props.matchData.fourth_official_referee_name}</Text>
                        </Text>
                    </Page>
                </Document>
            </PDFViewer>            
        );
    };

    return (
            <Modal2Component
                isOpen={props.isOpen}
                onClose={()=>{ props.onClose()}}
                title={props.title}
                className='card-id-pdf-generator-modal'
            >                
                {!isMobile &&
                    <Reporte />
                }
                
            </Modal2Component>

        )
}

export default withTranslation('league')(TournamentMatchReportPdfModalComponent);
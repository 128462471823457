export const changeHubTournamentsLoadingAction = ( data ) => {
    return { type: "TOURNAMENT:CHANGE_LOADING_HUB_TOURNAMENTS", data }
}

export const loadHubTournamentsAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_HUB_TOURNAMENTS", data }
}

export const loadSelectedTournamentAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_SELECTED_TOURNAMENT", data }
}

export const loadSelectedTournamentTeamsAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_SELECTED_TOURNAMENT_TEAMS", data }
}

export const loadSelectedTournamentTeamsPayAction = ( data, tournament_team_id ) => {
    return { type: "TOURNAMENT:LOAD_SELECTED_TOURNAMENT_TEAMS_PAY", data, tournament_team_id }
}

export const loadSelectedTournamentTeamsOnlyAction= ( data ) => {
    return { type: "TOURNAMENT:LOAD_SELECTED_TOURNAMENT_TEAMS_ONLY", data }
}

export const loadHubTournamentTeamsAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_HUB_TOURNAMENT_TEAMS", data }
}

export const setHubTournamentHubAction = ( new_state ) => {
    return { type: "TOURNAMENT:SET_TOURNAMENTS_HUB", new_state}
}

export const setHubTournamentInscriptionsAction = ( new_state ) => {
    return { type: "TOURNAMENT:SET_TOURNAMENTS_INSCRIPTIONS", new_state}
}

export const setHubTournamentMyTournamentsAction = ( new_state ) => {
    return { type: "TOURNAMENT:SET_TOURNAMENTS_MY_TOURNAMENTS", new_state}
}

export const setHubTournamentInvitationsAction = ( new_state ) => {
    return { type: "TOURNAMENT:SET_TOURNAMENTS_INVITATIONS", new_state}
}

export const showHubTournamentDetailAction = ( new_state ) => {
    return { type: "TOURNAMENT:SHOW_TOURNAMENT_DETAIL", new_state}
}

export const loadHubTeamsTournamentsAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_HUB_TEAMS_TOURNAMENTS", data}
}

export const loadPlayerTournamentsAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_HUB_PLAYER_TOURNAMENTS", data}
}

export const loadAllManagedTournamentsAction = (data)=>{
    return {type: "TOURNAMENT:LOAD_HUB_ADMIN_TOURNAMENTS", data}
}

export const changeInscriptionsModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_INSCRIPTIONS_MODAL_STATE", new_state}
}

export const loadProfileAdminTeamsAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_PROFILE_ADMIN_TEAMS", data}
}

export const cleanProfileAdminTeamsAction = ( data ) => {
    return { type: "TOURNAMENT:CLEAN_PROFILE_ADMIN_TEAMS", data}
}

export const loadTeamPlayersAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_TEAM_PLAYERS", data }
}

export const cleanTeamPlayersAction = ( data ) => {
    return { type: "TOURNAMENT:CLEAN_TEAM_PLAYERS", data }
}

export const loadPaymentUrlAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_PAYMENT_URL", data}
}

export const loadPaymentObjectAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_PAYMENT_OBJECT", data}
}

export const resetPaymentObjectAction = ( data ) => {
    return { type: "TOURNAMENT:RESET_PAYMENT_OBJECT", data}
}

export const changeNewNamesError = ( data ) => {
    return { type: "TOURNAMENT:CHANGE_NEW_NAMES_ERROR", data }
}

export const loadPaymentTournamentTeamIdAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_PAYMENT_TOURNAMENT_TEAM_ID", data}
}

export const loadPlayerCodePaymentAction= ( data ) => {
    return { type: "TOURNAMENT:LOAD_PLAYER_CODE_PAYMENT", data}
}

export const cleanPlayerTeamTournamentsAction= ( data ) => {
    return { type: "TOURNAMENT:CLEAN_PLAYER_TEAM_TOURNAMETS", data}
}

export const removeTeamFromTournamentAction = ( data ) => {
    return { type: "TOURNAMENT:REMOVE_TEAM_FROM_TOURNAMENT", data }
}

export const loadFilteredHubTournamentsAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_FILTERED_HUB_TOURNAMENTS", data }
}

export const removePlayerFromTournmentAction = ( data ) => {
    return { type: "TOURNAMENT:REMOVE_PLAYER_FROM_TOURNAMENT", data }
}

export const cleanPlayerCodePaymentAction = ( data ) => {
    return { type: "TOURNAMENT:CLEAN_PLAYER_CODE_PAYMENT", data }
}

export const loadTournamentInscriptionFormTemplateAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_TOURNAMENT_INSCRPTION_FORM_TEMPLATE", data }
}

export const changeFormsModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_FORMS_MODAL_STATE", new_state}
}

export const loadTournamentPlayerFormTemplateAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_PLAYER_TOURNAMENT_FORM_TEMPLATE", data }
}

export const changePaymentsModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_PAYMENTS_MODAL_STATE", new_state}
}

export const changeResponsiveHubModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_RESPONSIVE_HUB_MODAL_STATE", new_state }
}

export const changeFlexiInscriptionModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_FLEXI_INSCRIPTION_MODAL_STATE", new_state }
}

export const changeShareInscriptionLinkModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_SHARE_INSCRIPTION_LINK_MODAL_STATE", new_state }
}

/* export const changeRejectPlayerInscriptionModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_REJECT_PLAYER_INSCRIPTION_MODAL_STATE", new_state}
} */

export const changeIsShareLinkStateAction  = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_IS_SHARE_LINK_STATE", new_state  }
}

export const changeVerifyFormPaymentModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_VERIFY_FORM_PAYMENT_MODAL_STATE", new_state }
}

export const changeShareIframeLinkStateAction  = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_SHARE_IFRAME_LINK_MODAL_STATE", new_state  }
}

export const setSelectedTournamentTeamAction = ( team ) => {
    return { type: "TOURNAMENT:SET_SELECTED_TOURNAMENT_TEAM", team }
}

export const loadHubTournamentsSectionsAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_HUB_TOURNAMENTS_SECTIONS", data }
}

export const loadHubTournamentSubSectionAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_HUB_TOURNAMENT_SUBSECTION", data }
}

export const clearHubTournamentSubsectionAction = ( data ) => {
    return { type: "TOURNAMENT:CLEAR_HUB_TOURNAMENT_SUBSECTION", data }
}

export const loadFollowingHubTournamentsSubsectionAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_FOLLOWING_HUB_TOURNAMENTS_SUBSECTION", data }
}

export const loadInformativeTournamentTeamsAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_INFORMATIVE_TOURNAMENT", data }
}

export const changeStaffInvitationModalStateAction  = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_STAFF_INVITATION_MODAL_STATE", new_state  }
}

export const changeStaffAcceptInvitationModalStateAction  = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_STAFF_ACCEPT_INVITATION_MODAL_STATE", new_state  }
}

export const changeInscriptionDeadlineModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_INSCRIPTION_DEADLINE_MODAL_STATE", new_state }
}

export const loadHubTournamentMatchesAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_HUB_TOURNAMENT_MATCHES", data }
}

export const loadHubNextMatchesAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_HUB_NEXT_MATCHES", data }
}

export const loadHubTournamentMatchesDatesAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_HUB_TOURNAMENTS_MATCHES_DATES", data }
}

export const changeUnlockStatsModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_UNLOCK_STATS_MODAL_STATE", new_state }
}

export const loadTeamManagerAndStaffAction = ( data ) => {
    return { type: "TOURNAMENT:TEAM_MANAGER_AND_STAFF", data }
}

export const changeAddMembersWithoutAccountModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_ADD_MEMBERS_WITHOUT_ACCOUNT_MODAL_STATE", new_state }
}

export const changeTournamentCardIdPdfGeneratorModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_TOURNAMENT_CARD_ID_GENERATOR_MODAL_STATE", new_state }
}

export const changeLeaguesAddMemberToLeagueModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_LEAGUES_ADD_MEMBER_TO_LEAGUE_MODAL_STATE", new_state }
}

export const changeAnswerMultipleFormsModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_ANSWER_MULTIPLE_FORMS_MODAL_STATE", new_state }
}

export const loadPhaseCalendarAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_PHASE_CALENDAR", data }
}

export const loadPhaseCalendarMatchdaysAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_PHASE_CALENDAR_MATCHDAYS", data }
}

export const loadPhaseCalendarGroupMatchesAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_PHASE_CALENDAR_GROUP_MATCHES", data }
}

export const changeTeamPlayersLoadingAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_TEAM_PLAYERS_LOADING_STATE", new_state }
}

export const changeTeamStaffLoadingAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_TEAM_STAFF_LOADING_STATE", new_state }
}

export const changeFormsLoadingAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_FORMS_LOADING_STATE", new_state }
}

export const changeHubTournamentsSectionsLoadingAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_HUB_TOURNAMENTS_SECTIONS_LOADING_STATE", new_state }
}

export const loadSelectedShareTournamentAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_SELECTED_SHARE_TOURNAMENT", data }
}

export const setSelectedInvitedTournamentTeamAction = ( team ) => {
    return { type: "TOURNAMENT:SET_SELECTED_INVITED_TOURNAMENT_TEAM", team }
}

export const loadingPhaseCalendarAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_PHASE_CALENDAR_LOADING_STATE", new_state }
}

export const resetCalendarGroupMatchesAction = ( data ) => {
    return { type: "TOURNAMENT:RESET_CALENDAR_GROUP_MATCHES", data }
}

export const loadingPhaseDateGroupsAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_PHASE_DATE_GROUPS_LOADING_STATE", new_state }
}

export const loadPhaseDateGroupsAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_PHASE_DATE_GROUPS", data }
}

export const loadPhaseDateGroupMatchesAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_PHASE_DATE_GROUP_MATCHES", data }
}

export const resetPhaseDateMatchesAction = ( data ) => {
    return { type: "TOURNAMENT:RESET_PHASE_DATE_MATCHES", data }
}

export const changeHubTournamentViewMoreTeamsModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_VIEW_MORE_TEAMS_MODAL_STATE", new_state }
}

export const changeHubTournamentTeamsLoadingAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_TOURNAMENT_TEAMS_LOADING_STATE", new_state }
}

export const loadFrontTournamentTeamAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_FRONT_TOURNAMENT_TEAM", data }
}

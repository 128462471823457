/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Components
import { /* useFormikContext, */ Field } from 'formik';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

//Assests
import doublecard from 'assets/icons/double_yellow.svg';
import yellow_card from 'assets/icons/yellow_card_2.svg';
import red_card from 'assets/icons/red_card_2.svg';
import blue_card from 'assets/icons/blue_card.svg';

// Styles
import './LeagueCardsSelectorComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function LeagueCardsSelectorComponent( props ) {
    //const { errors } = useFormikContext();
    const [state, setState] = React.useState({
        redcard: (props.endGameStateCards === 'Red_Card' || props.endGameStateCards === 'Double_Yellow_Card')? true : false,
        yellowcard: props.endGameStateCards === 'Yellow_Card'? true : false,
        dblyellowcard: props.endGameStateCards === 'Double_Yellow_Card'? true : false,
        bluecard: props.endGameStateCards === 'Blue_Card'? true : false,
    });

    React.useEffect( () => {

        if(props.endGameStateCards != undefined){

            setState({...state, redcard: ((props.endGameStateCards === 'Red_Card' || props.endGameStateCards === 'Double_Yellow_Card')? true : false),
            yellowcard: (props.endGameStateCards === 'Yellow_Card'? true : false),
            dblyellowcard: (props.endGameStateCards === 'Double_Yellow_Card'? true : false),
            bluecard: (props.endGameStateCards === 'Blue_Card'? true : false)
        });

        }

    }, [ props.endGameStateCards ] ) // eslint-disable-line
   
    React.useEffect( () => {
        props.reValidateForm();
    }, [ state ] ) // eslint-disable-line
   

    const handleChange = (event, card) => {

        switch ( card ) {
            case "YC":{
                setState({ ...state, yellowcard: event.target.checked, dblyellowcard: false, redcard: false, bluecard: false });
                break;
            }
            case "DYC":{
                setState({ ...state, redcard: event.target.checked,  dblyellowcard: event.target.checked, yellowcard: false, bluecard: false });
                break;
            }
            case "RC":{
                setState({ ...state, redcard: event.target.checked, yellowcard: false, dblyellowcard: false, bluecard: false  });
                break;
            }

            case "BC":{
                setState({ ...state, redcard: false, yellowcard: false, dblyellowcard: false, bluecard: event.target.checked  });
                break;
            }

            // es-lint warning, switch without defalut case. from here, always at the bottom of docuemnt 
            // no default
        }    
        
    };

    function validateCards(value){
        let error;
        //if (!value && !state.redcard && !state.dblyellowcard && !state.yellowcard) {
        if (!state.redcard && !state.dblyellowcard && !state.yellowcard && !state.bluecard) {
            error = props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScorers.cardError'); 
        } 

        return error;

    }

    return (
        <div className={"league-cards-selector__container"}>
            <Field name={  props.name } validate={ validateCards }>
                {  
                ({  
                    field: {onChange},
                    form: { setFieldValue, validateForm, errors }, 
                }) => (
                    <React.Fragment>    
                        <Field name={  props.card_yellow }/*  validate={ validateCards } */>
                        {                
                            ({
                                form: { setFieldValue }, 
                            }) => (
                                <FormControlLabel
                                    control={<Checkbox 
                                                id={ props.card_yellow}
                                                icon={<img src={yellow_card} alt=""/> } 
                                                checkedIcon={<img src={yellow_card} alt=""/>} 
                                                checked={ state.yellowcard } 
                                                onChange={ (e)=>{
                                                    handleChange(e, "YC")
                                                    setFieldValue( props.card_yellow, e.target.checked )
                                                    setFieldValue( props.card_double_yellow, false )
                                                    setFieldValue( props.card_red, false )
                                                    setFieldValue( props.card_blue, false )
                                                }}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />}
                                    label=""
                                />
                            )
                        }
                        </Field>

                        <Field name={  props.card_double_yellow } /* validate={ validateCards } */>
                        {
                            ({
                                form: { setFieldValue }, 
                            }) => (
                                
                                <FormControlLabel
                                    control={<Checkbox 
                                                id={ props.card_double_yellow}
                                                icon={<img src={doublecard} alt=""/> } 
                                                checkedIcon={<img src={doublecard} alt=""/>} 
                                                checked={ state.dblyellowcard } 
                                                onChange={ (e)=>{
                                                    handleChange(e, "DYC")
                                                    setFieldValue( props.card_yellow, false )
                                                    setFieldValue( props.card_double_yellow, e.target.checked )
                                                    setFieldValue( props.card_red, e.target.checked )
                                                    setFieldValue( props.card_blue, false )
                                                }}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />}
                                    label=""
                                />
                            )
                        }
                        </Field>

                        <Field name={  props.card_red } /* validate={ validateCards } */>
                        {
                            ({
                                form: { setFieldValue }, 
                            }) => (
                                <FormControlLabel
                                    control={<Checkbox 
                                                id={ props.card_red}
                                                icon={<img src={red_card} alt=""/> } 
                                                checkedIcon={<img src={red_card} alt=""/>} 
                                                checked={ state.redcard } 
                                                onChange={ (e)=>{
                                                    handleChange(e, "RC")
                                                    setFieldValue( props.card_yellow, false )
                                                    setFieldValue( props.card_double_yellow, false )
                                                    setFieldValue( props.card_red, e.target.checked )
                                                    setFieldValue( props.card_blue, false )
                                                }}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />}
                                    label=""
                                />
                            )
                        }
                        </Field>
                        <Field name={  props.card_blue } /* validate={ validateCards } */>
                        {
                            ({
                                form: { setFieldValue }, 
                            }) => (
                                <FormControlLabel
                                    control={<Checkbox 
                                                id={ props.card_blue}
                                                icon={<img src={blue_card} alt=""/> } 
                                                checkedIcon={<img src={blue_card} alt=""/>} 
                                                checked={ state.bluecard } 
                                                onChange={ (e)=>{
                                                    handleChange(e, "BC")
                                                    setFieldValue( props.card_yellow, false )
                                                    setFieldValue( props.card_double_yellow, false )
                                                    setFieldValue( props.card_red,false )
                                                    setFieldValue( props.card_blue, e.target.checked )
                                                }}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />}
                                    label=""
                                />
                            )
                        }
                        </Field>
                        <div className={"league-cards-selector__message "+ ( errors !== undefined? 'error' : '' )}>
                            {errors && errors[props.name]? errors[props.name] : ''}
                        </div>
                    </React.Fragment>    
                    )
                }
            
            </Field>
           
        </div>
    )
}

export default withTranslation('league')(LeagueCardsSelectorComponent);
/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// React device detect
import { isMobile } from 'react-device-detect';

// Styles
import './BaloaPlayInvalidLinkModalComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

//Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';

function BaloaPlayInvalidLinkModalComponent( props ) {

    return(
        <ModalComponent
            isOpen={props.isOpen}
            onClose={props.onClose}
            className="baloa-play-invalid-link-modal"
        >
            <div className={`baloa-play-invalid-link-modal__container `}>
                <span className='hubicon-link_off' />
                <div className='baloa-normal-medium'>{props.t('invalidLinkModal.title')}</div>
                <div className='baloa-names'>{props.t('invalidLinkModal.message')}</div>
               
                <div>
                    <PrimaryButtonComponent
                        input_type={'button'}
                        onClick={()=>{props.handleOnPayMatch()}}
                    >
                        <span>{props.t('invalidLinkModal.buyBtnLbl')}</span>
                    </PrimaryButtonComponent>
                    <TextButtonComponent
                        input_type={'button'}
                        onClick={()=>{props.onClose()}}
                    >
                        <span>{props.t('invalidLinkModal.closeBtnLbl')}</span>
                    </TextButtonComponent>
                </div>
            </div>
        </ModalComponent>
    )
}

export default withTranslation('baloaplay')(BaloaPlayInvalidLinkModalComponent);
/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import FormComponent from 'shared/components/form/FormComponent';
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

// Styles
import './TournamentPhasesCreateEditFairPlaySettingsModalComponent.styles.css';

// Validators
import { RequiredValidator, OnlyNumbersRequiredValidator, MinNumberValidation } from 'shared/validators/RequiredValidator';

// Material
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel  from '@mui/material/FormControlLabel';

//Assets
import yellowCard from 'assets/icons/yellow_card.png';
import redCard from 'assets/icons/red_card.png';
import doubleCard from 'assets/icons/double_card.png';
import blueCard from 'assets/icons/blue_card.png';

//i18
import { withTranslation } from 'react-i18next';
import { number } from 'prop-types';

function TournamentPhasesCreateEditFairPlaySettingsModalComponent(props){

    const formRef = React.createRef();

    const initialValues = {
        name: '',
        points_win: props.fp_setting? props.fp_setting.points_win : '',
        points_draw: props.fp_setting? props.fp_setting.points_draw : '',
        points_loss: props.fp_setting? props.fp_setting.points_loss : '',
        points_win_penalty: props.fp_setting? props.fp_setting.points_win_penalty : '',
        points_loss_penalty: props.fp_setting? props.fp_setting.points_loss_penalty : '',
        //fair_play_initial_points: props.fp_setting? props.fp_setting.fair_play_initial_points : '',
        //fair_play_most_points_wins: props.fp_setting?.fair_play_most_points_wins? 'true' : 'false',
        points_yellow_card: props.fp_setting? props.fp_setting.points_yellow_card : '',
        points_double_yellow_card: props.fp_setting? props.fp_setting.points_double_yellow_card : '',
        points_red_card: props.fp_setting? props.fp_setting.points_red_card : '',
        points_blue_card: props.fp_setting? props.fp_setting.points_blue_card : '',
        sanction_yellow_card: props.fp_setting? props.fp_setting.sanction_yellow_card : '',
        sanction_double_yellow_card: props.fp_setting? props.fp_setting.sanction_double_yellow_card : '',
        sanction_red_card: props.fp_setting? props.fp_setting.sanction_red_card : '',
        sanction_blue_card: props.fp_setting? props.fp_setting.sanction_blue_card : '',
        sanction_match_yellow_card: props.fp_setting? props.fp_setting.sanction_match_yellow_card : '',
        sanction_match_double_yellow_card: props.fp_setting? props.fp_setting.sanction_match_double_yellow_card : '',
        sanction_match_red_card: props.fp_setting? props.fp_setting.sanction_match_red_card : '',
        sanction_match_blue_card: props.fp_setting? props.fp_setting.sanction_match_blue_card : '',
    }

    return(
        <Modal2Component
            isOpen={props.isOpen}
            onClose={props.onClose}
            title={props.edit? props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySettingsModal.titleEdit') : props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySettingsModal.title')}
            className='phases-create-edit-fair-play-settings-modal'
        >
            <FormComponent
                formInitialValues={initialValues} 
                onSubmit={( values, actions ) => {
                    //console.log("values: ",values);
                    //values.fair_play_most_points_wins = values.fair_play_most_points_wins === 'true'? true : false;
                    props.handleOnSave(values);
                }}
                innerRef={formRef}
                className={"scroll"}
            >
                <div className='phases-create-edit-fair-play-settings-modal__row'>
                    <InputFieldComponent
                        label={props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySettingsModal.settingsNameLbl')}
                        input_id=""
                        field_name="name"
                        validationFunction={RequiredValidator}
                        disabled={false}
                        input_type="text"
                        resetError={() => { }}
                        error={{name: ''}}
                        message=""
                        onChange={() => { }}
                        defaultValue={''}
                    />
                </div>
                <div className='phases-create-edit-fair-play-settings-modal__columns'>
                    <div className='phases-create-edit-fair-play-settings-modal__columns__column'>
                        <div className='baloa-names'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySettingsModal.matchPointsSubtitle')}</div>
                        <div className='baloa-table-column'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySettingsModal.matchPointsText')}</div>
                        <div className='phases-create-edit-fair-play-settings-modal__columns__column__row-items'>
                            <InputFieldComponent
                                label={props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySettingsModal.matchPointsWinLbl')}
                                input_id=""
                                field_name="points_win"
                                validationFunction={OnlyNumbersRequiredValidator}
                                disabled={false}
                                input_type="text"
                                resetError={() => { }}
                                error={{points_win: ''}}
                                message=""
                                onChange={() => { }}
                                defaultValue={props.fp_setting? props.fp_setting.points_win :''}
                            />
                            <InputFieldComponent
                                label={props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySettingsModal.matchPointsTieLbl')}
                                input_id=""
                                field_name="points_draw"
                                validationFunction={OnlyNumbersRequiredValidator}
                                disabled={false}
                                input_type="text"
                                resetError={() => { }}
                                error={{points_draw: ''}}
                                message=""
                                onChange={() => { }}
                                defaultValue={props.fp_setting? props.fp_setting.points_draw :''}
                            />
                            <InputFieldComponent
                                label={props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySettingsModal.matchPointsLoseLbl')}
                                input_id=""
                                field_name="points_loss"
                                validationFunction={OnlyNumbersRequiredValidator}
                                disabled={false}
                                input_type="text"
                                resetError={() => { }}
                                error={{points_loss: ''}}
                                message=""
                                onChange={() => { }}
                                defaultValue={props.fp_setting? props.fp_setting.points_loss :''}
                            />
                        </div>
                    </div>
                    <div className='phases-create-edit-fair-play-settings-modal__columns__column'>
                        <div className='baloa-names'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySettingsModal.penaltyPointsSubtitle')}</div>
                        <div className='baloa-table-column'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySettingsModal.penaltyPointsText')}</div>
                        <div className='phases-create-edit-fair-play-settings-modal__columns__column__row-items'>
                            <InputFieldComponent
                                label={props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySettingsModal.penaltyPointsWin')}
                                input_id=""
                                field_name="points_win_penalty"
                                validationFunction={OnlyNumbersRequiredValidator}
                                disabled={false}
                                input_type="text"
                                resetError={() => { }}
                                error={{points_win_penalty: ''}}
                                message=""
                                onChange={() => { }}
                                defaultValue={props.fp_setting? props.fp_setting.points_win_penalty :''}
                                className={'long_label'}
                            />
                                
                            <InputFieldComponent
                                label={props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySettingsModal.penaltyPointsLose')}
                                input_id=""
                                field_name="points_loss_penalty"
                                validationFunction={OnlyNumbersRequiredValidator}
                                disabled={false}
                                input_type="text"
                                resetError={() => { }}
                                error={{points_loss_penalty: ''}}
                                message=""
                                onChange={() => { }}
                                defaultValue={props.fp_setting? props.fp_setting.points_loss_penalty :''}
                                className={'long_label'}
                            />
                        </div>
                    </div>
                </div>
                <div className='phases-create-edit-fair-play-settings-modal__columns high-top-margin'>
                    <div className='phases-create-edit-fair-play-settings-modal__columns__column'>
                        <div className='baloa-names'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySettingsModal.fairPlayCardsSubtitle')}</div>
                        {/* <div className='baloa-table-column label'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySettingsModal.fairPlayPointsText')}</div>
                        <RadioGroup aria-labelledby="fair_play_most_points_wins" name="fair_play_most_points_wins"  defaultValue={props.fp_setting? props.fp_setting?.fair_play_most_points_wins? 'true' : 'false' : "true"} /*value={ mostPointsWins } onClick={ handleMostPointsWins }*/ /*className="phases-create-edit-fair-play-settings-modal__radio-options" >
                            <FormControlLabel value="true" control={<Radio />} label={<div className='' dangerouslySetInnerHTML={{ __html: props.t("options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySettingsModal.fairPlayPointsAddLbl")}}></div>} />
                            <FormControlLabel value="false" control={<Radio />} label={<div className='' dangerouslySetInnerHTML={{ __html: props.t("options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySettingsModal.fairPlayPointsSubstrLbl")}}></div>} />
                        </RadioGroup>
                        <InputFieldComponent
                            label={props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySettingsModal.fairPlayPointsStartLbl')}
                            input_id=""
                            field_name="fair_play_initial_points"
                            validationFunction={OnlyNumbersRequiredValidator}
                            disabled={false}
                            input_type="text"
                            resetError={() => { }}
                            error={{fair_play_initial_points: ''}}
                            message=""
                            onChange={() => { }}
                            defaultValue={props.fp_setting? props.fp_setting.fair_play_initial_points :''}
                        /> */}
                        <div className='phases-create-edit-fair-play-settings-modal__cards-points'>
                            <div className='baloa-table-column'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySettingsModal.fairPlayPointsSettingCardLbl')}</div>
                            <div className='baloa-table-column infolbl'><span className='hubicon-info_v2'/>{props.tournament_fair_play_most_points_wins? props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySettingsModal.fairPlayPointsSubsInfoLbl') : props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySettingsModal.fairPlayPointsAddInfoLbl')}</div>
                            <div className='phases-create-edit-fair-play-settings-modal__cards-points__card'>
                                <img src={yellowCard} alt=""/>
                                <div className='baloa-table-column label'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySettingsModal.yellowCardLbl')}</div>
                                <InputFieldComponent
                                    //label={}
                                    input_id=""
                                    field_name="points_yellow_card"
                                    validationFunction={OnlyNumbersRequiredValidator}
                                    disabled={false}
                                    input_type="text"
                                    resetError={() => { }}
                                    error={{points_yellow_card: ''}}
                                    message=""
                                    onChange={() => { }}
                                    defaultValue={props.fp_setting? props.fp_setting.points_yellow_card :''}
                                />
                            </div>
                            <div className='phases-create-edit-fair-play-settings-modal__cards-points__card'>
                                <img src={doubleCard} alt=""/>
                                <div className='baloa-table-column label'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySettingsModal.doubleYellowCardLbl')}</div>
                                <InputFieldComponent
                                    //label={}
                                    input_id=""
                                    field_name="points_double_yellow_card"
                                    validationFunction={OnlyNumbersRequiredValidator}
                                    disabled={false}
                                    input_type="text"
                                    resetError={() => { }}
                                    error={{points_double_yellow_card: ''}}
                                    message=""
                                    onChange={() => { }}
                                    defaultValue={props.fp_setting? props.fp_setting.points_double_yellow_card :''}
                                />
                            </div>
                            <div className='phases-create-edit-fair-play-settings-modal__cards-points__card'>
                                <img src={redCard} alt=""/>
                                <div className='baloa-table-column label'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySettingsModal.redCardLbl')}</div>
                                <InputFieldComponent
                                    //label={}
                                    input_id=""
                                    field_name="points_red_card"
                                    validationFunction={OnlyNumbersRequiredValidator}
                                    disabled={false}
                                    input_type="text"
                                    resetError={() => { }}
                                    error={{points_red_card: ''}}
                                    message=""
                                    onChange={() => { }}
                                    defaultValue={props.fp_setting? props.fp_setting.points_red_card :''}
                                />
                            </div>
                            <div className='phases-create-edit-fair-play-settings-modal__cards-points__card'>
                                <img src={blueCard} alt=""/>
                                <div className='baloa-table-column label'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySettingsModal.blueCardLbl')}</div>
                                <InputFieldComponent
                                    //label={}
                                    input_id=""
                                    field_name="points_blue_card"
                                    validationFunction={OnlyNumbersRequiredValidator}
                                    disabled={false}
                                    input_type="text"
                                    resetError={() => { }}
                                    error={{points_blue_card: ''}}
                                    message=""
                                    onChange={() => { }}
                                    defaultValue={props.fp_setting? props.fp_setting.points_blue_card :''}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='phases-create-edit-fair-play-settings-modal__columns__column'>
                        <div className='baloa-names'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySettingsModal.sanctionSubtitle')}</div>
                        <div className='baloa-table-column'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySettingsModal.sanctionText')}</div>
                        <div className='phases-create-edit-fair-play-settings-modal__cards-points__card start-row'>
                            <InputFieldComponent
                                //label={}
                                input_id=""
                                field_name="sanction_yellow_card"
                                validationFunction={(val) => MinNumberValidation(val, 1, props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySettingsModal.yellowCardLbl'))}
                                disabled={false}
                                input_type="number"
                                min={1}
                                resetError={() => { }}
                                error={{sanction_yellow_card: ''}}
                                message=""
                                onChange={() => { }}
                                defaultValue={props.fp_setting? props.fp_setting.sanction_yellow_card :''}
                            />
                            <img src={yellowCard} alt=""/>
                            <div className='baloa-table-column label'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySettingsModal.yellowCardLbl')}</div>
                            <InputFieldComponent
                                //label={}
                                input_id=""
                                field_name="sanction_match_yellow_card"
                                validationFunction={OnlyNumbersRequiredValidator}
                                disabled={false}
                                input_type="text"
                                resetError={() => { }}
                                error={{sanction_match_yellow_card: ''}}
                                message=""
                                onChange={() => { }}
                                defaultValue={props.fp_setting? props.fp_setting.sanction_match_yellow_card :''}
                            />
                            <div className='baloa-table-column label'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySettingsModal.matcSanctionLbl')}</div>
                        </div>
                        <div className='phases-create-edit-fair-play-settings-modal__cards-points__card'>
                            <InputFieldComponent
                                //label={}
                                input_id=""
                                field_name="sanction_double_yellow_card"
                                validationFunction={(val) => MinNumberValidation(val, 1, props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySettingsModal.doubleYellowCardLbl'))}
                                disabled={false}
                                input_type="number"
                                min={1}
                                resetError={() => { }}
                                error={{sanction_double_yellow_card: ''}}
                                message=""
                                onChange={() => { }}
                                defaultValue={props.fp_setting? props.fp_setting.sanction_double_yellow_card :''}
                            />
                            <img src={doubleCard} alt=""/>
                            <div className='baloa-table-column label'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySettingsModal.doubleYellowCardLbl')}</div>
                            <InputFieldComponent
                                //label={}
                                input_id=""
                                field_name="sanction_match_double_yellow_card"
                                validationFunction={OnlyNumbersRequiredValidator}
                                disabled={false}
                                input_type="text"
                                resetError={() => { }}
                                error={{sanction_match_double_yellow_card: ''}}
                                message=""
                                onChange={() => { }}
                                defaultValue={props.fp_setting? props.fp_setting.sanction_match_double_yellow_card :''}
                            />
                            <div className='baloa-table-column label'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySettingsModal.matcSanctionLbl')}</div>
                        </div>
                        <div className='phases-create-edit-fair-play-settings-modal__cards-points__card'>
                            <InputFieldComponent
                                //label={}
                                input_id=""
                                field_name="sanction_red_card"
                                validationFunction={(val) => MinNumberValidation(val, 1, props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySettingsModal.redCardLbl'))}
                                disabled={false}
                                input_type="number"
                                min={1}
                                resetError={() => { }}
                                error={{sanction_red_card: ''}}
                                message=""
                                onChange={() => { }}
                                defaultValue={props.fp_setting? props.fp_setting.sanction_red_card :''}
                            />
                            <img src={redCard} alt=""/>
                            <div className='baloa-table-column label'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySettingsModal.redCardLbl')}</div>
                            <InputFieldComponent
                                //label={}
                                input_id=""
                                field_name="sanction_match_red_card"
                                validationFunction={OnlyNumbersRequiredValidator}
                                disabled={false}
                                input_type="text"
                                resetError={() => { }}
                                error={{sanction_match_red_card: ''}}
                                message=""
                                onChange={() => { }}
                                defaultValue={props.fp_setting? props.fp_setting.sanction_match_red_card :''}
                            />
                            <div className='baloa-table-column label'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySettingsModal.matcSanctionLbl')}</div>
                        </div>
                        <div className='phases-create-edit-fair-play-settings-modal__cards-points__card'>
                            <InputFieldComponent
                                //label={}
                                input_id=""
                                field_name="sanction_blue_card"
                                validationFunction={(val) => MinNumberValidation(val, 1, props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySettingsModal.blueCardLbl'))}
                                disabled={false}
                                input_type="number"
                                min={1}
                                resetError={() => { }}
                                error={{sanction_blue_card: ''}}
                                message=""
                                onChange={() => { }}
                                defaultValue={props.fp_setting? props.fp_setting.sanction_blue_card :''}
                            />
                            <img src={blueCard} alt=""/>
                            <div className='baloa-table-column label'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySettingsModal.blueCardLbl')}</div>
                            <InputFieldComponent
                                //label={}
                                input_id=""
                                field_name="sanction_match_blue_card"
                                validationFunction={OnlyNumbersRequiredValidator}
                                disabled={false}
                                input_type="text"
                                resetError={() => { }}
                                error={{sanction_match_blue_card: ''}}
                                message=""
                                onChange={() => { }}
                                defaultValue={props.fp_setting? props.fp_setting.sanction_match_blue_card :''}
                            />
                            <div className='baloa-table-column label'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySettingsModal.matcSanctionLbl')}</div>
                        </div>
                    </div>
                </div>
            </FormComponent>
            <div className='phases-create-edit-fair-play-settings-modal__buttons'>
                <PrimaryButtonComponent
                    type={'button'}
                    onClick={ ()=>{ formRef.current.submitForm()}}
                    className={'small'}
                >
                    <span>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySettingsModal.saveBtnLbl')}</span>
                </PrimaryButtonComponent>
            </div>
        </Modal2Component>
    )
        
}

export default withTranslation('league')(TournamentPhasesCreateEditFairPlaySettingsModalComponent)



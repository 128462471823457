// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

// React Infinite Scroll Component
import InfiniteScroll from "react-infinite-scroll-component";

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

//Assets
import loading_ball from 'assets/images/loading_ball.gif';

// Styles
import './ProfileTournamentsTeamsSummaryModalComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';


function ProfileTournamentsTeamsSummaryModalComponent( props ) {

    const [loadMore, setLoadMore] = React.useState(false);

    React.useEffect(() => {
        if(props.profile_stats_next_page){
            setLoadMore(true);
        } else {
            setLoadMore(false);
        }
    }, [props.profile_stats_next_page]); // eslint-disable-line

    function handleModalTitle() {
        switch (props.summary_type) {
            case 'tournaments_summary':
                return props.t('tournamentSummary.labelTournamentsSummary');
            case 'teams_summary':
                return props.t('tournamentSummary.labelTeamsSummary');
            case 'victories_summary':
                return props.t('tournamentSummary.labelAchievements');
            //no default
        }
    }

    function validateLockedData () {
        for(let key in props.profile_data) {
            if(props.profile_data[key] === 'ND') {
                return true;
            }
        }
        return false;
    }

    return (        
        <Modal2Component 
            isOpen={props.isOpen} 
            onClose={props.onClose}
            title={handleModalTitle()}
            className='profile-tournaments-teams-summary-modal__container scroll-parent'
        >    
            <div className="profile-tournaments-teams-summary-modal__table-container">
                { (props.summary_type === 'tournaments_summary' || props.summary_type === 'teams_summary') &&   
                    <div className='profile-tournaments-teams-summary-modal__table' >
                        <div className='profile-tournaments-teams-summary-modal__table-header'>
                            <div className='profile-tournaments-teams-summary-modal__table-header-col baloa-normal-medium'>{props.summary_type === 'tournaments_summary' ? props.t('tournamentSummary.labelTournament') : props.t('tournamentSummary.labelTeam')}</div>
                            <div className='profile-tournaments-teams-summary-modal__table-header-col'>
                                <div className='baloa-normal-medium played_games'>{props.t('tournamentSummary.labelPlayedGames')}<span class="profile-tournaments-teams-summary-modal__tooltip">{props.t('tournamentSummary.playedGamesTooltip')}</span></div>
                                <span class="hubicon-titular"><span class="path1"></span><span class="path2"></span><span class="profile-tournaments-teams-summary-modal__tooltip">{props.t('tournamentSummary.titularTooltip')}</span></span>
                                <span className='hubicon-timer'><span class="profile-tournaments-teams-summary-modal__tooltip">{props.t('tournamentSummary.timerTooltip')}</span></span>
                                <span className='icon-ball'><span class="profile-tournaments-teams-summary-modal__tooltip">{props.t('tournamentSummary.goalsTooltip')}</span></span>
                                <span class="hubicon-assists">
                                    <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span>
                                    <span class="profile-tournaments-teams-summary-modal__tooltip">{props.t('tournamentSummary.assistancesTooltip')}</span>
                                </span>
                                <span className='hubicon-goalkeeper'><span class="profile-tournaments-teams-summary-modal__tooltip">{props.t('tournamentSummary.undefeatedFencesTooltip')}</span></span>
                                {/* <div className='baloa-normal-medium goals'>{props.t('tournamentSummary.labelGoalPerGame')}<span class="profile-tournaments-teams-summary-modal__tooltip">{props.t('tournamentSummary.goalsPerGameTooltip')}</span></div> */}
                                <div className='profile-tournaments-table__table-header-col__yellow-card'><span class="profile-tournaments-teams-summary-modal__tooltip">{props.t('tournamentSummary.yellowCardTooltip')}</span></div>
                                <div className='profile-tournaments-table__table-header-col__red-card'><span class="profile-tournaments-teams-summary-modal__tooltip">{props.t('tournamentSummary.redCardTooltip')}</span></div>
                                <div className='profile-tournaments-table__table-header-col__blue-card'><span class="profile-tournaments-teams-summary-modal__tooltip">{props.t('tournamentSummary.blueCardTooltip')}</span></div>
                            </div>
                        </div>
                        <div className='profile-tournaments-teams-summary-modal__table-body'>
                            <InfiniteScroll
                                dataLength={props.profile_stats.length}
                                next={props.fetchMoreData}
                                hasMore={loadMore}
                                loader={<div className='profile-tournaments-teams-summary-modal__loader'><img src={loading_ball} alt="loader" /></div>}
                                height={'90vh'}
                                endMessage={''}
                            >
                                { props.profile_stats?.map((element, index) => (
                                    <div key={index} className='profile-tournaments-teams-summary-modal__table-body__container'>
                                        <div className='profile-tournaments-teams-summary-modal__table-body-col summary-info'>
                                            <div className='profile-tournaments-teams-summary-modal__table-body-col__img'>
                                                { props.summary_type === 'tournaments_summary' &&
                                                    <div className='profile-tournaments-teams-summary-modal__table-body-col__img-container'>
                                                        { element?.tournament_logo
                                                            ? <img src={element?.tournament_logo} alt={`${element?.tournament_name} logo`} />
                                                            : <span className='icon-ball' color={getRandonIconBallColor()} />
                                                        }
                                                    </div>
                                                }
                                                { props.summary_type === 'teams_summary' &&
                                                    <div className='profile-tournaments-teams-summary-modal__table-body-col__img-container'>
                                                        { element?.team_logo
                                                            ? <img src={element?.team_logo} alt={`${element?.teamname} logo`} />
                                                            : <span className='icon-ball' color={getRandonIconBallColor()} />
                                                        }
                                                    </div>
                                                }                                            
                                            </div>
                                            { props.summary_type === 'tournaments_summary' &&
                                                <div className='profile-tournaments-teams-summary-modal__table-body-col__info'>
                                                    <div className='baloa-normal-medium' >{element?.tournament_name}</div>
                                                    <div className='profile-tournaments-teams-summary-modal__table-body-col__info__labels'>
                                                        <div className='baloa-table-column'>{props.t(`tournamentSummary.footballType.${element?.tournament_football_type}`)}</div>
                                                        <div className='baloa-table-column'>{props.t(`tournamentSummary.gender.${element?.tournament_gender}`)}</div>
                                                        <div className='baloa-table-column'>{element?.tournament_category}</div>
                                                    </div>
                                                </div>
                                            }
                                            { props.summary_type === 'teams_summary' &&
                                                <div className='profile-tournaments-teams-summary-modal__table-body-col__info'>
                                                    <div className='baloa-normal-medium' >{element?.team}</div>
                                                    <div className='profile-tournaments-teams-summary-modal__table-body-col__info__labels'>
                                                        <div className='baloa-table-column'>{props.t(`tournamentSummary.gender.${element?.team_gender}`)}</div>
                                                        <div className='baloa-table-column'>{element?.team_category}</div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className='profile-tournaments-teams-summary-modal__table-body-col summary-values'>
                                            <div className={`baloa-normal-medium c1 ${!element?.player_has_paid ? 'locked_values' : ''}`} >{element?.games_played}</div>
                                            <div className={`baloa-normal-medium c2 ${!element?.player_has_paid ? 'locked_values' : ''}`} >{element?.played_as_titular}</div>
                                            <div className={`baloa-normal-medium c3 ${!element?.player_has_paid ? 'locked_values' : ''}`} >{element?.minutes_played}</div>
                                            <div className={`baloa-normal-medium c4 ${!element?.player_has_paid ? 'locked_values' : ''}`} >{element?.goals}</div>                                        
                                            <div className={`baloa-normal-medium c5 ${!element.player_has_paid ? 'locked_values' : ''}`} >{element.assistance}</div>
                                            <div className={`baloa-normal-medium c6 ${!element.player_has_paid ? 'locked_values' : ''}`} >{element.unbeaten_games}</div>
                                            {/* <div className={`baloa-normal-medium ${!element?.player_has_paid ? 'locked_values' : ''}`} >{element?.goals_match}</div> */}
                                            <div className={`baloa-normal-medium c7 ${!element?.player_has_paid ? 'locked_values' : ''}`} >{element?.yellow_cards}</div>
                                            <div className={`baloa-normal-medium c8 ${!element?.player_has_paid ? 'locked_values' : ''}`} >{element?.red_cards}</div>
                                            <div className={`baloa-normal-medium c9 ${!element?.player_has_paid ? 'locked_values' : ''}`} >{element?.blue_cards}</div>
                                        </div>
                                    </div>                                           
                                ))}

                            </InfiniteScroll>
                            
                        </div>
                    </div>
                }   
                { props.summary_type === 'victories_summary' &&
                    <div className='profile-tournaments-teams-summary-modal__victories__table' >
                        <div className='profile-tournaments-teams-summary-modal__victories__table-header'>
                            <div className='baloa-normal-medium'>{props.t('tournamentSummary.labelTournament')}</div>
                            <div className='baloa-normal-medium'>{props.t('tournamentSummary.labelPosition')}</div>                    
                        </div>
                        <div className='profile-tournaments-teams-summary-modal__victories__table-body'>
                            <InfiniteScroll
                                dataLength={props.profile_stats.length}
                                next={props.fetchMoreData}
                                hasMore={loadMore}
                                loader={<div className='profile-tournaments-teams-summary-modal__loader'><img src={loading_ball} alt="loader" /></div>}
                                height={'90vh'}
                                endMessage={''}
                            >
                                { props.profile_stats?.map((tournament, index) => (                                                               
                                    <div key={`${tournament.tournament_id}_${index}`} className='profile-tournaments-teams-summary-modal__victories__table-body__container'>
                                        <div className='profile-tournaments-teams-summary-modal__victories__table-body-col'>
                                            <div className='profile-tournaments-teams-summary-modal__victories__table-body-col__img'>
                                                { tournament.tournament_logo
                                                    ? <img src={tournament.tournament_logo} alt={`${tournament.tournament_name} logo`} />
                                                    : <span className='icon-ball' color={getRandonIconBallColor()} />
                                                }
                                            </div>
                                            <div className='profile-tournaments-teams-summary-modal__victories__table-body-col__info'>
                                                <div className='baloa-normal-medium' >{tournament.tournament_name}</div>
                                                <div className='profile-tournaments-teams-summary-modal__victories__table-body-col__info__labels'>
                                                    <div className='baloa-table-column'>{props.t(`tournamentSummary.footballType.${tournament.tournament_football_type}`)}</div>
                                                    <div className='baloa-table-column'>{props.t(`tournamentSummary.gender.${tournament.tournament_gender}`)}</div>
                                                    <div className='baloa-table-column'>{tournament.tournament_category}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`profile-tournaments-teams-summary-modal__victories__table-body-col baloa-normal-medium ${!tournament.player_has_paid ? 'locked_values' : ''}`} >{tournament.position === 'ND' ? tournament.position : `${props.t(`tournamentSummary.labelsTournamentsAchievements.${tournament.position}`)} (${tournament.tournament_year})`}</div>       
                                    </div>
                                    ))
                                }
                            </InfiniteScroll>
                        </div>
                    </div>
                }
            </div>          
            { props.profile_tournament_paid &&
                <div className='profile-tournaments-teams-summary-modal__unlock-values__action'>
                    <div className='profile-tournaments-teams-summary-modal__unlock-values__text'>
                        
                        { props.is_owner && props.summary_type !== 'teams_summary' && 
                            <div className='baloa-normal-medium'>
                                <span className='hubicon-award_star'/>
                                {/* props.t('tournamentSummary.blockedText') */}
                                {props.t('tournamentSummary.subscribeLbl')}
                            </div> 
                        }
                        { props.is_owner && props.summary_type === 'teams_summary' && 
                            <div className='baloa-normal-medium'>
                                <span className='hubicon-award_star'/>
                                { /* validateLockedData() 
                                    ? props.t('tournamentSummary.blockedText') 
                                    : props.t('tournamentSummary.alternativeblockedText')  */
                                }
                                {props.t('tournamentSummary.subscribeLbl')}
                            </div> 
                        }
                        { !props.is_owner  && props.summary_type !== 'teams_summary' && 
                            <div className='baloa-normal-medium'>
                                <span className='hubicon-info' />
                                {props.t('tournamentSummary.visitantBlockedText', {user_name: props.username})}
                            </div> 
                        }
                        { !props.is_owner  && props.summary_type === 'teams_summary' && 
                            <div className='baloa-normal-medium'>
                                <span className='hubicon-info' />
                                { validateLockedData() 
                                    ? props.t('tournamentSummary.visitantBlockedText', {user_name: props.username}) 
                                    : props.t('tournamentSummary.alternativeVisitantBlockedTex', {user_name: props.username}) 
                                }
                            </div> 
                        }
                    </div>
                    { props.is_owner && <PrimaryButtonComponent onClick={() => {props.handleModalUnlockData()}} >{props.t('tournamentSummary.subscribeBtnLbl')}</PrimaryButtonComponent> }
                </div>
            }
        </Modal2Component>
        )
    }
    
    export default withTranslation('profile')(ProfileTournamentsTeamsSummaryModalComponent);
// React
import React from 'react';
import { Link } from 'react-router-dom';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import TournamentPlayerInfoComponent from 'app/leagues/components/tournament-player-info/TournamentPlayerInfoComponent';
import TournamentTeamInfoComponent from 'app/leagues/components/tournament-team-info/TournamentTeamInfoComponent';
import InfiniteScroll from "react-infinite-scroll-component";
import TournamentStatisticsSkeletonComponent from 'app/leagues/components/tournament-statistics-skeleton/TournamentStatisticsSkeletonComponent';

// Styles
import './TournamentViewMoreStatisticsModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

// React device detect
import { isMobile } from 'react-device-detect';

function TournamentViewMoreStatisticsModalComponent( props ) {

    const [loadMore, setLoadMore] = React.useState(false);

    React.useEffect(() => {
        if(props.statistics_next_page){
            setLoadMore(true);
        } else {
            setLoadMore(false);
        }
    }, [props.statistics_next_page]); // eslint-disable-line

    return(
        <Modal2Component
            isOpen={props.isOpen}
            onClose={props.onClose }
            title={
                props.card_type === 'scorer'?
                    props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.scorersTable.scorersLabel')
                :
                    props.card_type === 'yellow-card'?
                        props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.booksTable.labelCardYellow')
                        :
                        props.card_type === 'red-card'?
                            props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.booksTable.labelCardRed')
                            :
                            props.card_type === 'blue-card'?
                                props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.booksTable.labelCardBlue')
                                :
                                props.card_type === 'assist'?
                                    props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.assistsTable.title')
                                    :
                                    props.card_type === 'ldf'?
                                        props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.leastDefeatedFenceTable.title')
                                    :
                                    props.card_type === 'tmg'?
                                        props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.teamsMostGoalsTable.title')
                                        :
                                        props.card_type === 'fairplay'?
                                            props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.fairPlayTable.title')
                                            :
                                            ''
            }
            className={`tournament-view-more-statistics-modal ${props.card_type === 'fairplay'? 'fairplay' : ''}`}
        >
            {props.card_type === 'scorer' &&
                <div className='baloa-subtitle-2'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.scorersTable.columns.labelGoals')}</div>
            }
            {props.card_type === 'fairplay' &&
                <div className='tournament-view-more-statistics-modal__fairplay-header'>
                    <div className='yellow-card'><span className='tournament-view-more-statistics-modal__tooltip'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.fairPlayTable.yellowCardTp')}</span></div>
                    <div className='red-card'><span className='tournament-view-more-statistics-modal__tooltip'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.fairPlayTable.redCardTp')}</span></div>
                    <div className='blue-card'><span className='tournament-view-more-statistics-modal__tooltip'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.fairPlayTable.blueCardTp')}</span></div>
                    <div className='icon'><span className='hubicon-gavel'/><span className='tournament-view-more-statistics-modal__tooltip'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.fairPlayTable.penaltyPointsTp')}</span></div>
                    <div className='baloa-subtitle-2'>{'Pts'}<span className='tournament-view-more-statistics-modal__tooltip'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.fairPlayTable.fairPlayPointsTp')}</span></div>
                </div>
            }
            <InfiniteScroll
                dataLength={props.statistic_data.length}
                next={()=>{ props.loadMoreStatistics(props.statistics_next_page) }}
                hasMore={loadMore}
                loader={
                    <TournamentStatisticsSkeletonComponent/>
                }
                height={isMobile? props.card_type === 'fairplay'? 'calc(100vh - 175px)' : 'calc(100vh - 90px)' : '600px'}
                endMessage={''}
                className={`${props.card_type === 'fairplay'? 'tournament-view-more-statistics-modal__fairplay-content' : ''}`}
            >
                {props.statistic_data?.map( (data, index) => 
                    <div className='tournament-statistics__scorers-row' key={index}>
                        <div className='tournament-statistics__scorers-row__number baloa-normal-medium'>{index + 1}</div>
                        {(props.card_type === 'ldf' || props.card_type === 'tmg' || props.card_type === 'fairplay') ?
                            <div><TournamentTeamInfoComponent name={`${data.name}`} photo={data.team_photo} teamname={data.teamname} /></div>
                            :
                            <div><Link to={`/profile/${data.name}/`}><TournamentPlayerInfoComponent full_name={`${data.first_name} ${data.last_name}`} photo={data.photo} team_name={data.team} /></Link></div>
                        }
                        
                        {(props.card_type === 'scorer' || props.card_type === 'ldf' || props.card_type === 'tmg') &&
                            <div className='tournament-statistics__scorers-row__goals baloa-normal-medium'>{data.goals}</div>                        
                        }
                        {(props.card_type === 'assist') &&
                            <div className='tournament-statistics__scorers-row__goals baloa-normal-medium'>{data.assists}</div>                        
                        }
                        {(props.card_type === 'yellow-card' || props.card_type === 'red-card' || props.card_type === 'blue-card') &&
                            <React.Fragment>
                                <div className='tournament-statistics__scorers-row__goals baloa-normal-medium'>{data.cards}</div>
                                <div className={props.card_type}/>
                            </React.Fragment>
                        }
                        {props.card_type === 'fairplay' &&
                            <div className='tournament-view-more-statistics-modal__fairplay-data'>
                                <div className='tournament-statistics__scorers-row__goals baloa-normal-medium'>{`${data.yellow_cards}/${data.yellow_cards_point}`}</div>   
                                {/* <div className='tournament-statistics__scorers-row__goals baloa-normal-medium'>{`${data.double_yellow_cards}/${data.double_yellow_cards_point}`}</div>  */}  
                                <div className='tournament-statistics__scorers-row__goals baloa-normal-medium'>{`${data.red_cards}/${data.red_cards_point}`}</div>   
                                <div className='tournament-statistics__scorers-row__goals baloa-normal-medium'>{`${data.blue_cards}/${data.blue_cards_point}`}</div>   
                                <div className='tournament-statistics__scorers-row__goals baloa-normal-medium'>{data.extrasports_fairplay_points}</div>  
                                <div className='tournament-statistics__scorers-row__goals baloa-normal-medium'>{data.fairplay_points}</div>
                            </div>
                        }
                        
                    </div>
                )}
            </InfiniteScroll>
            {props.card_type === 'fairplay' &&
                <div className='tournament-view-more-statistics-modal__fairplay-footer'>
                    <div className=''>
                        <div className="baloa-names" dangerouslySetInnerHTML={{
                            __html: props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.fairPlayTable.cardFormatLbl')
                        }}></div>
                    </div>
                    {props.fair_play_most_points_wins &&
                        <div className='baloa-names infolbl'><span className='hubicon-info_v2'/>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.fairPlayTable.fairPlayPointsSubsInfoLbl')}</div>
                    }
                    {!props.fair_play_most_points_wins &&
                        <div className='baloa-names infolbl'><span className='hubicon-info_v2'/>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.fairPlayTable.fairPlayPointsAddInfoLbl')}</div>
                    }
                </div>
            }
        </Modal2Component>
    )
}

export default withTranslation(['league', 'tournament'])(TournamentViewMoreStatisticsModalComponent);
/*eslint eqeqeq: "off"*/
// React
import React, { useState } from 'react';
// Components
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import InputFieldAutocompleteWithSearchComponent from 'shared/components/input-field-autocomplete-with-search/InputFieldAutocompleteWithSearchComponent';
import InputFieldMultilineComponent from 'shared/components/input-field-multiline/InputFieldMultilineComponent';
import SelectFieldCleanComponent from 'shared/components/select-field/SelectFieldCleanComponent';
import LeagueTeamUploadImageModalComponent from 'app/leagues/components/league-team-upload-image-modal/LeagueTeamUploadImageModalComponent';
//import ToggleButton from '@mui/material/ToggleButton';
//import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

// Material
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel  from '@mui/material/FormControlLabel';

//i18
import { withTranslation } from 'react-i18next';
// Validators
import { RequiredValidator, OnlyNumbersRequiredValidator } from 'shared/validators/RequiredValidator';
import { DateFormatValidator } from 'shared/validators/DateValidator';
import { EndDateValidator } from 'shared/validators/EndDateValidation';
// Styles
//import { styled } from "@mui/styles";
import help_icon from 'assets/icons/help-resume-icon.svg';
import './TournamentBasicDFormEditComponent.styles.css';


function TournamentBasicDFormEditComponent(props) {

    const VALUES_TO_EDITING = props.valuestoEditForm;
    const FIELDS_STATUS_EDITING = props.fieldStatusEditing;
    const [start_date, setStateStartDate] = useState('');
    const [end_date, setStateEndDate] = useState('');
    const [state, setState] = useState({
        upload_photo_modal: false,
        upload_logo_modal: false,
    });
    //const [formats, setFormats] = useState(props.valuestoEditForm?.tournament_day.map((day) => day.day) ?? []);
    //const [gameDay, setGameDay] = useState(props.valuestoEditForm?.tournament_timeday.map((game_day) => game_day.timeday) ?? []);

    const [name, setName] = useState('');

    /* const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
        '& .MuiToggleButtonGroup-grouped': {
            margin: theme.spacing(1),
            border: 0,
            '&.Mui-disabled': {
                border: 0,
            },
            '&:not(:first-of-type)': {
                borderRadius: theme.shape.borderRadius,
            },
            '&:first-of-type': {
                borderRadius: theme.shape.borderRadius,
            },
        },
    })); */

    /* eslint-disable */
    handleChangeStartDate = handleChangeStartDate.bind(this);
    handleChangeEndDate = handleChangeEndDate.bind(this);
    handleChangeName = handleChangeName.bind(this);
    /* eslint-enable */

    const tournament_gender = [
        { value: 'Female', content: props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.Female') },
        { value: 'Male', content: props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.Male') },
        { value: 'Free', content: props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.Free') },
    ];

    /* const DAYS_OF_WEEK = [
        { value: 'Monday', content: props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.Monday') },
        { value: 'Tuesday', content: props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.Tuesday') },
        { value: 'Wednesday', content: props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.Wednesday') },
        { value: 'Thursday', content: props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.Thursday') },
        { value: 'Friday', content: props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.Friday') },
        { value: 'Saturday', content: props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.Saturday') },
        { value: 'Sunday', content: props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.Sunday') },

    ];

    const SECTIONS_DAYS = [
        { value: 'Morning', content: props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.Morning') },
        { value: 'Afternoon', content: props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.Afternoon') },
        { value: 'Evening', content: props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.Evening') }
    ]; */

    function todayDate() {
        let date = new Date();
        let today = '';

        if (date.getMonth() + 1 < 10 && date.getDate() < 10) {
            today = date.getFullYear() + "-" + 0 + (date.getMonth() + 1) + "-" + 0 + date.getDate();
        } else if (date.getMonth() + 1 < 10) {
            today = date.getFullYear() + "-" + 0 + (date.getMonth() + 1) + "-" + date.getDate();
        } else if (date.getDate() < 10) {
            today = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + 0 + date.getDate();
        } else {
            today = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
        }

        return today;
    }

    function handleChangeStartDate(data) {
        setStateStartDate(data);
    }

    function handleChangeEndDate(data) {
        setStateEndDate(data);
    }

    const handleTournamentPhoto = (new_img, imageurl, img_b64) => {
        props.handleTournamentPhotoB64(img_b64, imageurl);
    };

    const handleTournamentLogo = (new_img, imageurl, img_b64) => {
        props.handleTournamentLogoB64(img_b64, imageurl);
    };

    const handleUploadPhotoModal = (new_state) => {
        setState({ upload_photo_modal: new_state });
    };

    const handleUploadLogoModal = (new_state) => {
        setState({ upload_logo_modal: new_state });
    };

    function handleChangeName(data) {
        setName(data);
    };

    const chooseFooballType = (value) => {
        props.setFootbalTypeMinPlayers(value);
    };

    /* const handleFormat = (event, newFormats) => {
        setFormats(newFormats);
        props.handleSelectDays(newFormats);
    };

    const handleGameDayFormat = (event, newDayFormats) => {
        setGameDay(newDayFormats);
        props.handleSelectDayTime(newDayFormats);
    }; */

    const validationDisabledFields = (field_name) => {
        for (let i in FIELDS_STATUS_EDITING) {
            if (i === field_name) return !FIELDS_STATUS_EDITING[i];
        }
    };

    const toolTipInputFiled = (input_type) => {
        return (
            <div className="basic-data__form_edit_content__input_grid-tooltip">
                <div className="tooltip">
                    <img src={help_icon} alt="" />
                    <span className="tooltiptext">
                        <div className="tooltiptext_grid_title">
                            <span className='hubicon-info' />
                            <p className="baloa-table">{props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.noEditField')}</p>
                        </div>
                        <p className="baloa-username">{props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.noEditcurrentState')}</p>
                    </span>
                </div>
            </div>
        );
    };

    return (
        <div className="basic-data-form-edit">
            <div className="basic-data__form_edit__content">
                <div className='baloa-subtitle-2'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelBasicDataTitle')}</div>
                <div className='tournament-basic-data_form_edit_tournament-logo'>
                    <div className='baloa-username'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelTournamentPhoto')}</div>
                    <div className='tournament-basic-data_form_edit_tournament-photo'
                        onClick={() => {
                            if (!validationDisabledFields('tournament_photo')) {
                                handleUploadLogoModal(true);
                            }
                        }
                        } >
                        {!props.tournament_logo_url
                            ? <span className='icon-image-70' />
                            : <img src={props.tournament_logo_url} alt="" />
                        }
                        <div className='baloa-username'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelUploadImg')}</div>
                    </div>
                    {props.isTournamentLogo === false && <div className='tournament-basic-data__logo__required-label baloa-username'>
                        {props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.requiredTournamentImg')}
                    </div>}
                </div>

                <LeagueTeamUploadImageModalComponent
                    isOpen={state.upload_logo_modal}
                    onClose={() => handleUploadLogoModal(false)}
                    setTeamImage={(new_img, imageurl, img_b64) => handleTournamentLogo(new_img, imageurl, img_b64)}
                    aspect={1}
                    cropImage={true}
                />
                <div className="basic-data__form_edit_content__input_grid-tooltip">
                    <InputFieldComponent
                        label={props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelTournamentName')}
                        input_id="tournament-name"
                        field_name="name"
                        validationFunction={RequiredValidator}
                        className="basic-data__form_edit_content__input name"
                        input_type="text"
                        resetError={() => props.onResetError({ 'name': "" })}
                        error={props.errors}
                        placeholde={props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelTournamentName')}
                        value={name}
                        onChange={handleChangeName}
                        disabled={validationDisabledFields('name')}
                    />
                    {validationDisabledFields('name') &&
                        <div className="basic-data__form_edit_content_name_input_grid-tooltip">
                            {toolTipInputFiled('name')}
                        </div>
                    }
                </div>
                <div className="basic-data__form__content_edit_category grid-content">
                    <div className="basic-data__form_edit_content__football-type__input">
                        <SelectFieldCleanComponent //TODO: fix footbal type
                            label={props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelFootballType')}
                            field_name="football_type"
                            validationFunction={RequiredValidator}
                            disabled={validationDisabledFields('football_type')}
                            resetError={() => props.onResetError({ 'football_type': "" })}
                            error={props.errors}
                            message=""
                            placeholder=""
                            options={props.tournament_football_type}
                            onChange={(v) => { chooseFooballType(v) }}
                            defaultValue={VALUES_TO_EDITING.football_type}
                        />
                    </div>
                    {validationDisabledFields('football_type') &&
                        <div className="basic-data__form_edit_content__football-type__input_grid-tooltip">
                            {toolTipInputFiled('football_type')}
                        </div>
                    }
                    <div className="basic-data__form_edit_content__tournament-type__input">
                        <SelectFieldCleanComponent //TODO: fix tournament type
                            label={props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelTournamentType')}
                            field_name="tournament_type"
                            validationFunction={ RequiredValidator }
                            resetError={ () => props.onResetError( { 'tournament_type': "" } ) }
                            error={ props.errors }
                            message=""
                            placeholder={props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.placeholderTournamentType')}
                            options={ props.list_tournament_types}
                            defaultValue={VALUES_TO_EDITING.tournament_type}
                            disabled={validationDisabledFields('tournament_type')}
                        />
                    </div>
                    {validationDisabledFields('tournament_type') &&
                        <div className="basic-data__form_edit_content__football-type__input_grid-tooltip">
                            {toolTipInputFiled('tournament_type')}
                        </div>
                    }
                    <div className="basic-data__form_edit_content__category__input">
                        <SelectFieldCleanComponent
                            label={props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelCategory')}
                            field_name="category"
                            validationFunction={RequiredValidator}
                            disabled={validationDisabledFields('category')}
                            resetError={() => props.onResetError({ 'category': "" })}
                            error={props.errors}
                            message=""
                            placeholder=""
                            options={props.tournament_tag}
                            defaultValue={VALUES_TO_EDITING.category}
                        />
                    </div>
                    <div className="basic-data__form__content_edit_gender__input">
                        <SelectFieldCleanComponent
                            label={props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelGender')}
                            field_name="tournament_gender"
                            validationFunction={RequiredValidator}
                            disabled={validationDisabledFields('tournament_gender')}
                            resetError={() => props.onResetError({ 'gender': "" })}
                            error={props.errors}
                            message=""
                            placeholder=""
                            options={tournament_gender}
                            defaultValue={VALUES_TO_EDITING.tournament_gender}
                        />
                    </div>
                    {validationDisabledFields('tournament_gender') &&
                        <div className="basic-data__form_edit_content_tournament_gender_input_grid-tooltip football_type">
                            {toolTipInputFiled('tournament_gender')}
                        </div>
                    }
                </div>
                <div className="basic-data__form_edit_content__citys grid-content">
                    <div className="basic-data__form_edit_content__citys-custom">
                        <InputFieldAutocompleteWithSearchComponent
                            options={props.countries ? props.countries : null}
                            noOptionsText={props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.noOpt')}
                            optionLabelField={"name"}
                            optionSelectedField={"id"}
                            lettercount={"2"}
                            handleSearchWord={(newCountryValue) => props.handleSearchNationality(newCountryValue)}
                            className="basic-data__form_edit_content__country__input"
                            label={props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelCountry')}
                            placeholder=""
                            id="country_autocomplete_new_tournament"
                            field_name="main_country"
                            validationFunction={RequiredValidator}
                            resetError={() => { }}
                            error={props.errors}
                            value={props.countries ? props.countries[0] : ''}
                            disabled={validationDisabledFields('main_country')}
                        />
                    </div>
                    {validationDisabledFields('main_country') &&
                        <div className="basic-data__form_edit_content__main_country__input_grid-tooltip">
                            {toolTipInputFiled('main_country')}
                        </div>
                    }
                    <div className="basic-data__form_edit_content__citys-custom">
                        <InputFieldAutocompleteWithSearchComponent
                            options={props.cities ? props.cities : null}
                            noOptionsText={props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.noOpt')}
                            optionLabelField={"name"}
                            optionSelectedField={"id"}
                            lettercount={"2"}
                            handleSearchWord={(newCityValue) => props.handleSearchWorldCities(newCityValue)}
                            className="basic-data__form_edit_content__city__input"
                            label={props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelCity')}
                            placeholder=""
                            id="city_autocomplete_new_tournament"
                            field_name="main_city"
                            validationFunction={RequiredValidator}
                            resetError={() => { }}
                            error={props.errors}
                            disabled={props.disableCities || validationDisabledFields('main_city')}
                            value={props.cities ? props.cities[0] : ''}
                        />
                    </div>
                    {validationDisabledFields('main_city') &&
                        <div className="basic-data__form_edit_content__main_city__input_grid-tooltip">
                            {toolTipInputFiled('main_city')}
                        </div>
                    }
                </div>

                <div className="basic-data__form_edit_content__date grid-content">
                    <InputFieldComponent
                        label={props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelStartDate')}
                        input_id="tournament-start_date"
                        field_name="start_date"
                        validationFunction={DateFormatValidator}
                        className="basic-data__form_edit_content__date__input-start"
                        input_type="date"
                        resetError={() => { }}
                        error={props.errors}
                        disabled={validationDisabledFields('start_date')}
                        min={!props.disableFormValues ? todayDate() : ''}
                        onChange={handleChangeStartDate}
                        value={start_date ? start_date : null} //TODO check
                    />
                    <InputFieldComponent
                        label={props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelEndDate')}
                        input_id="tournament-end_date"
                        field_name="end_date"
                        validationFunction={() => {
                            if (!props.disableFormValues) {
                                EndDateValidator(end_date, start_date);
                            }
                        }}
                        className="basic-data__form_edit_content__date__input-end"
                        input_type="date"
                        resetError={() => { }}
                        error={props.errors}
                        disabled={validationDisabledFields('send_date')}
                        min={start_date === '' ? todayDate() : start_date}
                        onChange={handleChangeEndDate}
                        value={end_date ? end_date : null} //TODO check
                    />
                    {validationDisabledFields('start_date') &&
                        <div className="basic-data__form_edit_content__start_date__input_grid-tooltip">
                            {toolTipInputFiled('start_date')}
                        </div>
                    }
                </div>

                <div className='basic-data__fair-play-container'>
                    <div className='baloa-names'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySettingsModal.fairPlayPointsSubtitle')}</div>
                    <div className='baloa-table-column'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySettingsModal.fairPlayPointsText')}</div>
                    <RadioGroup aria-labelledby="fair_play_most_points_wins" name="fair_play_most_points_wins"  defaultValue={VALUES_TO_EDITING.fair_play_most_points_wins === "true"? 'true' : VALUES_TO_EDITING.fair_play_most_points_wins === "false"? 'false' : 'true' } /*value={ mostPointsWins } onClick={ handleMostPointsWins }*/ className="phases-create-edit-fair-play-settings-modal__radio-options" >
                        <FormControlLabel value="true" control={<Radio />} label={<div className='baloa-table-column' dangerouslySetInnerHTML={{ __html: props.t("options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySettingsModal.fairPlayPointsAddLbl")}}></div>} disabled={validationDisabledFields('fair_play_most_points_wins')}/>
                        <FormControlLabel value="false" control={<Radio />} label={<div className='baloa-table-column' dangerouslySetInnerHTML={{ __html: props.t("options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySettingsModal.fairPlayPointsSubstrLbl")}}></div>} disabled={validationDisabledFields('fair_play_most_points_wins')}/>
                    </RadioGroup>
                    <InputFieldComponent
                        label={props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.phaseFairPlaySettingsModal.fairPlayPointsStartLbl')}
                        input_id=""
                        field_name="fair_play_initial_points"
                        validationFunction={OnlyNumbersRequiredValidator}
                        disabled={validationDisabledFields('fair_play_initial_points')}
                        input_type="text"
                        resetError={() => { }}
                        error={{fair_play_initial_points: ''}}
                        message=""
                        onChange={() => { }}
                        defaultValue={VALUES_TO_EDITING.fair_play_initial_points}
                    />
                </div>
                <div className="basic-data__form_edit_content__grid-content_description_tooltip">
                    <InputFieldMultilineComponent
                        label={props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelDescription')}
                        input_id="tournament-description"
                        field_name="description"
                        validationFunction={RequiredValidator}
                        disabled={validationDisabledFields('description')}
                        className="basic-data_input-description"
                        input_type="text"
                        resetError={() => props.onResetError({ 'description': "" })}
                        error={props.errors}
                        message=""
                        placeholder=""
                        rows={5}
                        value={VALUES_TO_EDITING.description}
                    />
                    {validationDisabledFields('description') &&
                        <div className="basic-data__form_edit_content__description__input_grid-tooltip">
                            {toolTipInputFiled('description')}
                        </div>
                    }
                </div>
                <div className="basic-data__form_edit_content__grid-content_description_tooltip">
                    <InputFieldMultilineComponent
                        label={props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelRules')}
                        input_id="tournament-rules"
                        field_name="rules"
                        validationFunction={()=>{}}
                        disabled={validationDisabledFields('rules')}
                        className="basic-data__input-rules"
                        input_type="text"
                        resetError={() => props.onResetError({ 'rules': "" })}
                        error={props.errors}
                        message=""
                        placeholder=""
                        rows={5}
                    />
                    {validationDisabledFields('rules') &&
                        <div className="basic-data__form_edit_content__rules__input_grid-tooltip">
                            {toolTipInputFiled('rules')}
                        </div>
                    }
                </div>

                {/* VALUES_TO_EDITING?.tournament_day &&
                    <React.Fragment>
                        <div className="basic-data__form_edit_content__button-group">
                            <p className="baloa-names">{props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.possible_days')}</p>
                            <div className="basic-data__form_edit_content__grid-content_tournament_timeday_tooltip">
                                <StyledToggleButtonGroup
                                    color="primary"
                                    value={formats}
                                    onChange={handleFormat}
                                >
                                    {DAYS_OF_WEEK.map((day, index) => (
                                        <ToggleButton key={index} value={day.value}
                                            disabled={validationDisabledFields('tournament_day')}>
                                            {day.content}
                                        </ToggleButton>
                                    ))}
                                </StyledToggleButtonGroup>
                                {validationDisabledFields('tournament_day') &&
                                    <div className="basic-data__form_edit_content_tournament_day_input_grid-tooltip">
                                        {toolTipInputFiled('tournament_day')}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="basic-data__form_edit_content__button-group">
                            <p className="baloa-names">{props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.possible_game_days')}</p>
                            <div className="basic-data__form_edit_content__grid-content_tournament_timeday_tooltip">
                                <StyledToggleButtonGroup
                                    color="primary"
                                    value={gameDay}
                                    onChange={handleGameDayFormat}
                                    aria-label="text formatting"
                                >
                                    {SECTIONS_DAYS.map((section, index) => (
                                        <ToggleButton key={index} value={section.value}
                                            disabled={validationDisabledFields('tournament_timeday')}>
                                            {section.content}
                                        </ToggleButton>
                                    ))}

                                </StyledToggleButtonGroup>
                                {validationDisabledFields('tournament_timeday') &&
                                    <div className="basic-data__form_edit_content_tournament_day_input_grid-tooltip">
                                        {toolTipInputFiled('tournament_timeday')}
                                    </div>
                                }
                            </div>
                        </div>
                    </React.Fragment> */
                }
                <div className="basic-data__form_edit_content__container-img">
                    <p className="baloa-names">{props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.tournament_cover')}</p>
                    <div className='basic-data_edit_tournament-cover'
                        onClick={() => {
                            if (!validationDisabledFields('tournament_logo')) {
                                handleUploadPhotoModal(true)
                            }
                        }
                        }>
                        {!props.tournament_photo_url
                            ? <div className='basic-data_tournament-cover'>
                                <span className='icon-image-70' />
                                <div className='baloa-username'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelUploadImg')}</div>
                            </div>
                            : <div className='basic-data_img-container'>
                                <img src={props.tournament_photo_url} alt="" />
                            </div>
                        }
                    </div>
                </div>

                {props.isTournamentPhoto === false &&
                    <div className='tournament-basic-data_edit_photo__required-label baloa-username'>
                        {props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.requiredTournamentImg')}
                    </div>}
                <LeagueTeamUploadImageModalComponent
                    isOpen={state.upload_photo_modal}
                    onClose={() => handleUploadPhotoModal(false)}
                    setTeamImage={(new_img, imageurl, img_b64) => handleTournamentPhoto(new_img, imageurl, img_b64)}
                    aspect={2 / 1}
                    className='basic-data__fomr_edit_tournament-cover__modal'
                    cropImage={true}
                />
            </div>
        </div>
    )
}

export default withTranslation('league')(TournamentBasicDFormEditComponent)